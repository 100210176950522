"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignUpSteps = void 0;
var SignUpSteps;
(function (SignUpSteps) {
    SignUpSteps["UserInfo"] = "user-info";
    SignUpSteps["UsedDomain"] = "used-domain";
    SignUpSteps["UsedEmail"] = "used-email";
    SignUpSteps["JoinExistingWorkspace"] = "join-existing-workspace";
    SignUpSteps["BusinessAttributesSelector"] = "business-attributes-selector";
    SignUpSteps["MobileNumber"] = "mobile-number";
    SignUpSteps["HumanVerification"] = "human-verification";
    SignUpSteps["CompanyBranding"] = "company-branding";
    SignUpSteps["Signature"] = "signature";
    SignUpSteps["IntroVideo"] = "intro-video";
})(SignUpSteps = exports.SignUpSteps || (exports.SignUpSteps = {}));
