"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventNotifierService = void 0;
const lodash_1 = require("lodash");
class EventNotifierService {
    /**
     * Notifies the event to all the provided AbstractEventNotifier implementors.
     * @param event - the event to notify
     * @param eventNotifiers - a single entry or array of AbstractEventNotifier
     * @returns an array of event notification promises
     */
    static notify(event, eventNotifiers) {
        const localEventNotifiers = Array.isArray(eventNotifiers) ? eventNotifiers : [eventNotifiers];
        // Cloning deep the event object to avoid potential immutability problems
        const allNotificationPromises = localEventNotifiers.map((eventNotifier) => {
            const localEvent = (0, lodash_1.cloneDeep)(event);
            return eventNotifier.send(localEvent);
        });
        return Promise.all(allNotificationPromises);
    }
}
exports.EventNotifierService = EventNotifierService;
