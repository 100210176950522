import { Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cc-register-header',
  templateUrl: './register-header.component.html',
  styleUrls: ['./register-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegisterHeaderComponent {
  @Input() selected = false;
  @Input() displaySelectionCheckbox = true;
  @Output() selectAll = new EventEmitter();
}
