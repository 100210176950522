<div
  [class.landscape]="isLandscapeMode"
  [class.portrait]="!isLandscapeMode"
  class="d-flex table-display-buttons"
  data-test="table-hover-area"
>
  <div *ngIf="hasLeftActions" class="table-left-actions"></div>
  <table class="table table-bordered {{ model.kind | lowercase }}-kind" ccFormTableNavigation>
    <thead>
      <tr>
        <th class="action-cell" *ngIf="!model.readOnly">
          <div ngbDropdown container="body">
            <button
              type="button"
              class="btn dropdown-toggle dropdown-toggle--no-arrow sm-flex sm-items-center sm-justify-center"
              ngbDropdownToggle
              data-test="table-dropdown-menu-toggle"
              title="Table actions"
            >
              <i class="cc-icon cc-icon-ellipsis-horizontal" aria-hidden="true"></i>
            </button>
            <ul class="dropdown-menu" ngbDropdownMenu data-test="table-dropdown-menu">
              <li>
                <button
                  type="button"
                  (click)="actionResolver.clearAllRows()"
                  [disabled]="!actionResolver.canClearAll"
                  data-test="table-clear-all-rows"
                  class="dropdown-item sm-cursor-pointer"
                  [ngClass]="actionResolver.canClearAll ? 'sm-cursor-pointer' : 'sm-cursor-not-allowed'"
                  ngbDropdownItem
                >
                  <i class="cc-icon cc-icon-erase mr-1"></i>
                  <span i18n>Clear all rows</span>
                </button>
              </li>
              <li *ngIf="!isPrefilledTable">
                <button
                  type="button"
                  (click)="actionResolver.deleteAllRows()"
                  [disabled]="!actionResolver.canDeleteAll"
                  data-test="table-delete-all-rows"
                  class="dropdown-item sm-cursor-pointer"
                  [ngClass]="actionResolver.canDeleteAll ? 'sm-cursor-pointer' : 'sm-cursor-not-allowed'"
                  ngbDropdownItem
                >
                  <i class="cc-icon cc-icon-delete mr-1 sm-text-default-red"></i>
                  <span i18n>Delete all rows</span>
                </button>
              </li>
            </ul>
          </div>
        </th>
        <th *ngFor="let column of model.columns; let index = index">
          <div class="sm-flex sm-flex-row sm-items-center sm-gap-x-1">
            <em
              *ngIf="column.kind === TableCellKinds.Formula && !isPrefilledTable"
              aria-hidden="true"
              ngbPopover="Formula {{ column.formula }}"
              triggers="mouseenter:mouseleave"
              container="body"
              class="cc-icon cc-icon-formula sm-text-secondary-text"
            >
            </em>
            <em
              *ngIf="column.kind === TableCellKinds.ListProperty"
              aria-hidden="true"
              ngbPopover="This list property cell contains data that can only be updated by editing the list."
              triggers="mouseenter:mouseleave"
              container="body"
              class="cc-icon cc-icon-zap sm-text-secondary-text"
            ></em>
            <span>{{ column.heading }}</span>
          </div>

          <div
            *ngIf="column.isRequired"
            [class.sm-text-default-red]="column._invalid"
            [class.text-muted]="!column._invalid"
          >
            <small>(Required)</small>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let row of model.rows; let rowIndex = index"
        (mouseenter)="setHoverOnRow(rowIndex)"
        data-test="table-row"
        class="icon-container"
        [class.blue-line]="hoverButton && hoverRow === rowIndex"
      >
        <td *ngIf="!model.readOnly" class="td-remove-row icon-container action-cell line-between-rows">
          <div ngbDropdown autoClose="outside" container="body">
            <button
              type="button"
              class="btn d-flex align-items-center justify-content-center dropdown-toggle dropdown-toggle--no-arrow"
              ngbDropdownToggle
            >
              <i class="cc-icon cc-icon-ellipsis-horizontal"></i>
            </button>
            <ul class="dropdown-menu" ngbDropdownMenu data-test="row-dropdown-menu">
              <li *ngIf="!isPrefilledTable">
                <button
                  type="button"
                  class="dropdown-item sm-cursor-pointer"
                  [ngClass]="
                    actionResolver.rowActionFlags[rowIndex]?.has('moveUp')
                      ? 'sm-cursor-pointer'
                      : 'sm-cursor-not-allowed'
                  "
                  (click)="actionResolver.moveRowUp(rowIndex)"
                  [disabled]="!actionResolver.rowActionFlags[rowIndex]?.has('moveUp')"
                  ngbDropdownItem
                >
                  <i class="cc-icon cc-icon-arrow-up mr-1"></i>
                  <span i18n>Move row up</span>
                </button>
              </li>
              <li *ngIf="!isPrefilledTable">
                <button
                  type="button"
                  class="dropdown-item sm-cursor-pointer"
                  [ngClass]="
                    actionResolver.rowActionFlags[rowIndex]?.has('moveDown')
                      ? 'sm-cursor-pointer'
                      : 'sm-cursor-not-allowed'
                  "
                  (click)="actionResolver.moveRowDown(rowIndex)"
                  [disabled]="!actionResolver.rowActionFlags[rowIndex]?.has('moveDown')"
                  ngbDropdownItem
                >
                  <i class="cc-icon cc-icon-arrow-down mr-1"></i>
                  <span i18n>Move row down</span>
                </button>
              </li>
              <li *ngIf="!isPrefilledTable">
                <button
                  type="button"
                  class="dropdown-item sm-cursor-pointer"
                  [ngClass]="
                    actionResolver.rowActionFlags[rowIndex]?.has('clone')
                      ? 'sm-cursor-pointer'
                      : 'sm-cursor-not-allowed'
                  "
                  (click)="actionResolver.cloneRow(rowIndex)"
                  [disabled]="!actionResolver.rowActionFlags[rowIndex]?.has('clone')"
                  ngbDropdownItem
                >
                  <i class="cc-icon cc-icon-clone mr-1"></i>
                  <span i18n>Clone row and insert below</span>
                </button>
              </li>
              <li>
                <button
                  type="button"
                  class="dropdown-item"
                  [ngClass]="
                    actionResolver.rowActionFlags[rowIndex]?.has('clear')
                      ? 'sm-cursor-pointer'
                      : 'sm-cursor-not-allowed'
                  "
                  (click)="actionResolver.clearRow(rowIndex)"
                  [disabled]="!actionResolver.rowActionFlags[rowIndex]?.has('clear')"
                  ngbDropdownItem
                >
                  <i class="cc-icon cc-icon-erase mr-1"></i>
                  <span i18n>Clear row</span>
                </button>
              </li>
              <li *ngIf="!isPrefilledTable">
                <button
                  type="button"
                  class="dropdown-item sm-cursor-pointer"
                  [ngClass]="
                    actionResolver.rowActionFlags[rowIndex]?.has('delete')
                      ? 'sm-cursor-pointer'
                      : 'sm-cursor-not-allowed'
                  "
                  (click)="actionResolver.removeRow(rowIndex)"
                  [disabled]="!actionResolver.rowActionFlags[rowIndex]?.has('delete')"
                  ngbDropdownItem
                >
                  <i class="cc-icon cc-icon-delete mr-1 sm-text-default-red"></i>
                  <span i18n>Delete row</span>
                </button>
              </li>
            </ul>
          </div>
          <button
            type="button"
            *ngIf="!model.readOnly && !isPrefilledTable"
            class="btn add-row-plus dot-btn btn-hover-between-rows"
            (click)="actionResolver.addRowMidway(rowIndex)"
            (mouseenter)="hoverOnButton()"
            (mouseleave)="hoverOnButton()"
          >
            <i class="cc-icon cc-icon-plus"></i>
          </button>
          <div class="line-start"></div>
          <div class="add-row-line"></div>
        </td>
        <td
          *ngFor="let rowColumn of row.columns; let columnIndex = index"
          [class.td-input]="!model.readOnly"
          [class.prefilled-text-cell]="
            getColumnKind(rowIndex, columnIndex) === controlType.columnType.prefilledText
          "
          class="icon-container column-{{ model?.columns[columnIndex]?.width || tableColumnWidths.Legacy }}"
          [ngClass]="getListItemColor(rowIndex, columnIndex)"
        >
          <cc-form-table-column
            [column]="rowColumn"
            [kind]="getColumnKind(rowIndex, columnIndex)"
            [columnDescription]="getColumnDesc(rowIndex, columnIndex)"
            [columnIndex]="columnIndex"
            [rowIndex]="rowIndex"
            [model]="model"
            [template]="template"
            [formPath]="formPath"
            [formId]="formId"
            [isPreview]="isPreview"
            [parentId]="parentId"
            [hasListProperty]="hasListProperty"
            (invalid)="invalid.emit($event)"
            (onChangeTableField)="handleFieldChange(); this.onFieldChange(rowColumn, rowIndex)"
            (onChangePotentialFormulaField)="handleFieldUpdate($event)"
            [ccDefaultTableContentPaste]="model"
            [dataIndex]="{ row: rowIndex, column: columnIndex }"
          >
          </cc-form-table-column>
          <div class="add-row-line"></div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
