<img
  *ngIf="model.img"
  [src]="model.img"
  class="sketch-img"
  [style.background-image]="getBackgroundUrl()"
  [style.background-repeat]="model.background ? 'no-repeat' : 'repeat'"
  [style.background-size]="model.background ? 'contain' : 'auto'"
/>
<div *ngIf="!model.img">
  <div
    [style.background-image]="getBackgroundUrl()"
    [style.background-repeat]="model.background ? 'no-repeat' : 'repeat'"
    [style.background-size]="model.background ? 'contain' : 'auto'"
    class="fake-img"
  ></div>
</div>
