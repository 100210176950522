import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  DashpivotEvent,
  EventNotifierService,
  EventTypes,
  FieldKinds,
  TableCellData,
  TableColumn,
  TableCellValidator,
  TableCellValidatorFactory,
  ITemplateWithHierarchy,
} from '@site-mate/dashpivot-shared-library';

import { FormPhotoModalComponent } from 'app/form/form-components/form-photo-modal.component';
import { FormTableCellComponent } from 'app/form-fields/table/edit-cells/table-cell.component';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { PhotoModalService } from 'app/photo/photo-modal.service';
import { PhotoService } from 'app/photo/photo.service';
import { SegmentService } from 'app/segment/segment.service';
import { IFrameUrlKey } from 'app/shared/model/iframe-url-key.enum';
import { AppUtilService } from 'app/shared/service/app-util.service';
import { ConfirmService } from 'app/shared/service/confirm.service';
import { ToastrService } from 'app/shared/service/toastr.service';

@Component({
  templateUrl: './photo.component.html',
  styleUrls: [
    './photo.component.scss',
    '../table-cell.component.scss',
    '../../edit/form-table.component.scss',
  ],
  selector: 'cc-table-cell-item-photo',
})
export class FormTablePhotoCellComponent implements FormTableCellComponent, OnInit {
  @Input() tableKind: FieldKinds;
  @Input() columnDescription: string;
  @Input() cellData: TableCellData;
  @Input() headerColumn: TableColumn;
  @Input() template: ITemplateWithHierarchy;
  @Input() readOnly: boolean;
  @Input() isPreview: boolean;
  @Output() onChange = new EventEmitter();
  isInsideLibraryApps = false;
  validator: TableCellValidator;
  unsub = new Subject<void>();
  kmlFile;

  constructor(
    protected readonly appUtilService: AppUtilService,
    protected readonly photoService: PhotoService,
    protected readonly photoModal: PhotoModalService,
    protected readonly modal: NgbModal,
    protected readonly i18nService: TmpI18NService,
    protected readonly confirmService: ConfirmService,
    protected readonly toastrService: ToastrService,
    private readonly segmentService: SegmentService,
    protected readonly router: Router,
  ) {}

  ngOnInit() {
    this.setUpKmlFile();
    this.isInsideLibraryApps = this.router.url.includes(`/${IFrameUrlKey.LibraryApps}`);
    this.validator = TableCellValidatorFactory.getValidator(this.cellData, this.headerColumn);
  }

  handleOnChange() {
    const context = this.tableKind === FieldKinds.PrefilledTable ? 'Prefilled' : 'Default';
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.FormTableCellDataEntered, { Context: context }),
      this.segmentService,
    );
    this.onChange.emit();
  }

  setUpKmlFile() {
    this.photoService.kmlFileEvent.pipe(takeUntil(this.unsub)).subscribe((kmlFile) => {
      this.kmlFile = kmlFile;
    });
  }

  onAddPhotoClick() {
    if (this.isPreview) {
      this.toastrService.warnByKey('formPreviewWarningAddPhoto');
      return;
    }

    const modalRef = this.modal.open(FormPhotoModalComponent, {
      windowClass: 'form-photo-modal',
      keyboard: false,
      container: '.form-modal-container',
    });
    const componentInstance = modalRef.componentInstance as FormPhotoModalComponent;
    componentInstance.hierarchy = this.template;
    componentInstance.formRef = true;
    void modalRef.result.then(
      (selectedPhotos) => {
        this.cellData.photos = (this.cellData.photos || []).concat(selectedPhotos);
        this.handleOnChange();
        // eslint-disable-next-line consistent-return
        return true;
      },
      () => {
        // no-op
      },
    );
  }

  onViewPhoto({ item, index }) {
    const ref = this.photoModal.open({
      timeLineIndex: 0,
      timeLines: [{ photos: item.photos }],
      photoIndex: index,
      kmlFile: this.kmlFile,
      hideDeleteButton: true,
      teamId: String(this.template.team._id),
      projectId: String(this.template.project._id),
    });

    void ref.result.then(
      () => {
        return true;
        // no-op
      },
      () => {
        // no-op
      },
    );
  }

  onRemovePhoto({ index }) {
    void this.confirmService
      .confirmDelete('removePhoto', {
        confirmButtonText: this.i18nService.getMessage('confirmRemove'),
      })
      .then(
        () => {
          this.cellData.photos.splice(index, 1);
          this.handleOnChange();
          // eslint-disable-next-line consistent-return
          return true;
        },
        () => {
          // no-op
        },
      );
  }
}
