import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { PhotoTagGroup } from '@site-mate/dashpivot-shared-library';

import { HttpClientService } from 'app/shared/service/http-client.service';

@Injectable({
  providedIn: 'root',
})
export class MetaDataService {
  metadataListSubject = new BehaviorSubject<any>([]);
  metadataListEvent = this.metadataListSubject.asObservable();

  constructor(private http: HttpClientService) {}

  getProjectTagGroups(projectId: string) {
    return this.http.get<PhotoTagGroup[]>(`projects/${projectId}/tag-groups`);
  }

  getTeamTagGroups(teamId: string) {
    return this.http.get<PhotoTagGroup[]>(`teams/${teamId}/tag-groups`);
  }

  saveProjectTagGroup(projectId, tagGroup) {
    return this.http.post(`projects/${projectId}/tag-groups`, tagGroup);
  }

  saveTeamTagGroup(teamId, tagGroup) {
    return this.http.post(`teams/${teamId}/tag-groups`, tagGroup);
  }

  removeProjectTagGroup(projectId, tagGroupId) {
    return this.http.remove(`projects/${projectId}/tag-groups/${tagGroupId}`);
  }

  removeTeamTagGroup(teamId, tagGroupId) {
    return this.http.remove(`teams/${teamId}/tag-groups/${tagGroupId}`);
  }

  updateProjectTagGroup(projectId, tagGroupId, tagGroup) {
    return this.http.put(`projects/${projectId}/tag-groups/${tagGroupId}`, tagGroup);
  }

  updateTeamTagGroup(teamId, tagGroupId, tagGroup) {
    return this.http.put(`teams/${teamId}/tag-groups/${tagGroupId}`, tagGroup);
  }

  uploadKML(projectId, formData) {
    return this.http.upload(`projects/${projectId}/kml`, formData);
  }

  getKML(projectId) {
    return this.http.get(`projects/${projectId}/kml`);
  }

  removeKML(projectId) {
    return this.http.remove(`projects/${projectId}/kml`);
  }

  uploadLogo(companyId, formData) {
    return this.http.upload(`companies/${companyId}/`, formData);
  }

  updateLogoColorCode(companyId: string, colorCode: string, logo: string) {
    return this.http.put(`companies/${companyId}/`, { colorCode, logo });
  }

  updateColorCode(companyId, colorCode) {
    return this.http.put(`companies/${companyId}/`, { colorCode });
  }

  saveTemplateTag(companyId, templateTag) {
    return this.http.post(`companies/${companyId}/template-tags`, templateTag);
  }

  getTemplateTags(companyId) {
    return this.http.get(`companies/${companyId}`).pipe(map((c) => c.templateTags));
  }

  updateTemplateTag(companyId, templateTag) {
    return this.http.put(`companies/${companyId}/template-tags/${templateTag.id}`, templateTag);
  }

  updateTemplateTagsOrder(companyId, tags) {
    return this.http.put(`companies/${companyId}/template-tags`, { tags });
  }

  deleteTemplateTag(companyId, tagId) {
    return this.http.remove(`companies/${companyId}/template-tags/${tagId}`);
  }
}
