/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import batchPromises from 'batch-promises';
import { BehaviorSubject, Observable, Subject, forkJoin, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  CategorySourceTypes,
  DashpivotEvent,
  EventNotifierService,
  EventTypes,
  FieldKinds,
  FormAttachment,
  IUser,
  PathService,
  TableCellData,
  TableCellKinds,
  TableRow,
  Form as IForm,
  FormUtils,
} from '@site-mate/dashpivot-shared-library';

import { TemplatesService } from 'app/apps/templates.service';
import { SegmentService } from 'app/segment/segment.service';
import { ExportResponse } from 'app/shared/model/export-response.model';
import { FormWeb } from 'app/shared/model/form.model';
import { AcceptableFilesEnforcer } from 'app/shared/model/utils/acceptable-files-enforcer.model';
import { AppUtilService } from 'app/shared/service/app-util.service';
import { CompanyService } from 'app/shared/service/company.service';
import { HttpClientService } from 'app/shared/service/http-client.service';
import { ProjectService } from 'app/shared/service/project.service';
import { TeamService } from 'app/shared/service/team.service';
import { ToastrService } from 'app/shared/service/toastr.service';

import { IPreviewCompanyFormParams, IPreviewFormParams } from './form-preview.model';
import { FormUtil } from './form-util.service';

type IFormCreate = Omit<FormWeb, 'createdBy' | 'template'>;

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private listFormsSubject = new BehaviorSubject<boolean>(false);
  public listFormsEvent = this.listFormsSubject.asObservable();

  resetRegisterViewFilterSubject = new Subject<void>();
  onFieldChanged = new Subject<Record<string, any> | void>();

  public resetRegisterViewFilterEvent = this.resetRegisterViewFilterSubject.asObservable();

  pubListForms() {
    this.listFormsSubject.next(true);
  }

  constructor(
    private readonly appUtilService: AppUtilService,
    private readonly http: HttpClientService,
    private readonly segmentService: SegmentService,
    private readonly templatesService: TemplatesService,
    private readonly companyService: CompanyService,
    private readonly projectService: ProjectService,
    private readonly teamService: TeamService,
    private readonly pathService: PathService,
    protected readonly toastr: ToastrService,
  ) {}

  private trackAction(action: string) {
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.FormActionApplied, {
        Context: action,
      }),
      this.segmentService,
    );
  }

  listTemplateForms(templateId: string, params: Record<string, any>) {
    return this.http.get(`v1/apps/${templateId}/forms`, params);
  }

  getBlankForm(templateId: string, currentUser: IUser): Observable<Omit<FormWeb, 'createdBy'>> {
    return this.templatesService.byId(templateId).pipe(
      switchMap((template) => {
        const [companyId, projectId, teamId] = this.pathService.extractFolderIds(template.path);

        const company$ = this.companyService.getCompany(companyId);
        const project$ = projectId ? this.projectService.getProject(projectId) : of(undefined);
        const team$ = teamId ? this.teamService.getTeam(teamId) : of(undefined);

        return forkJoin({
          company: company$,
          project: project$,
          team: team$,
          template: of(template),
        });
      }),
      map((result) => {
        const { template, project, team, company } = result;
        const newForm: IForm = FormUtils.getBlankForm(template);
        const form: FormWeb = {
          ...newForm,
          template: {
            ...template,
            ...(project ? { project } : {}),
            ...(team ? { team } : {}),
            company,
          },
          createdBy: currentUser,
        };
        return form;
      }),
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  }

  create(appId: string, form: IFormCreate) {
    return this.http.post(`apps/${appId}/forms`, { ...form, source: 'web' });
  }

  previewForm(previewParams: IPreviewFormParams) {
    return this.http.post(`apps/preview/template`, previewParams);
  }

  previewCompanyForm(previewParams: IPreviewCompanyFormParams) {
    const { templateId } = previewParams;
    return this.http.post(`apps/${templateId}/preview/companies`, previewParams);
  }

  uploadLogo(formId, logo) {
    return this.http.upload(`forms/${formId}/logo`, logo);
  }

  // Temporary metadata, as dynamicAttachments will be moved to be part on the items soon
  updateItems(
    formId: string,
    items,
    metadata?: { dynamicAttachments: FormAttachment[] },
  ): Observable<{ formVersion: number }> {
    this.trackAction('Save');
    return this.http.put(`forms/${formId}/items`, { items, metadata });
  }

  byId(formId: string) {
    return this.http.get<FormWeb>(`forms/${formId}`);
  }

  remove(formId) {
    this.trackAction('Delete');
    return this.http.remove(`forms/${formId}`);
  }

  sign(formId, itemId) {
    return this.http.put(`forms/${formId}/items/${itemId}/sign`);
  }

  manualSign(formId, itemId, data) {
    const formData = new FormData();
    const signatureFile = this.appUtilService.dataUrlToFile(data.signatureData, 'signature');
    formData.append('file', signatureFile);
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('companyName', data.companyName);

    return this.http.putUpload(`forms/${formId}/items/${itemId}/sign`, formData);
  }

  deleteItemEntry(formId, itemId, entryId) {
    return this.http.remove(`forms/${formId}/items/${itemId}/${entryId}`);
  }

  uploadFile(files) {
    return this.http.upload<FormAttachment>(`forms/attachments`, files);
  }

  removeAttachment(formId, fileId) {
    return this.http.remove(`forms/${formId}/attachments/${fileId}`);
  }

  listAttachments(formId) {
    return this.http.get(`forms/${formId}/attachments}`);
  }

  clone(formId) {
    this.trackAction('Clone');
    return this.http.get(`forms/${formId}/clone`);
  }

  getHistories(formId) {
    return this.http.get(`forms/${formId}/comments`);
  }

  byVersion(formId, version) {
    return this.http.get(`forms/${formId}/versions/${version}`);
  }

  resetSignature(formId: string, columnIdToReset: string) {
    return this.http.post(`forms/${formId}/reject`, { columnIdToReset });
  }

  generateSignature(params: Record<string, string | number>) {
    return this.http.put(`forms/sign`, params);
  }

  manualSignColumn(formId, itemId, data) {
    const formData = new FormData();
    const signatureFile = this.appUtilService.dataUrlToFile(data.signatureData, 'signature');
    formData.append('file', signatureFile);
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('companyName', data.companyName);
    if (formId) {
      formData.append('formId', formId);
    }
    if (itemId) {
      formData.append('itemId', itemId);
    }

    return this.http.putUpload(`forms/manual-sign`, formData);
  }

  getRegisterView(appId, limit, offset, criteria: Record<string, any>[]) {
    const url = `apps/${appId}/register-view/?limit=${limit}&offset=${offset}${FormUtil.getRegisterViewSearchStr(
      criteria,
    )}`;
    return this.http.get(url);
  }

  downloadAsSinglePDF(templateId, params) {
    return this.http.get<ExportResponse>(`v1/apps/${templateId}/forms/single-pdf`, params);
  }

  downloadAsCSV(templateId, params) {
    return this.http.get<ExportResponse>(`v1/apps/${templateId}/forms/csv`, params);
  }

  downloadAsPDF(templateId, params) {
    return this.http.get<ExportResponse>(`v1/apps/${templateId}/forms/pdf`, params);
  }

  downloadAsRegisterOnlyPDF(templateId, params) {
    return this.http.get<ExportResponse>(`v1/apps/${templateId}/forms/register-only-pdf`, params);
  }

  sendFormAsPDF(templateId, emails, params) {
    this.trackAction('Send As PDF');
    return this.http.post(`v1/apps/${templateId}/forms/pdf/email`, { emails }, params);
  }

  getFormUsers(templateId) {
    return this.http.get(`apps/${templateId}/forms/users`);
  }

  getFormProjects(appId) {
    return this.http.get(`apps/${appId}/forms/projects`);
  }

  getFormItemValues(appId, itemId) {
    return this.http.get(`apps/${appId}/forms/items/${itemId}/values`);
  }

  getFormItemTableColumnValues(templateId, itemId, columnId) {
    return this.http.get(`apps/${templateId}/forms/items/${itemId}/columns/${columnId}/values`);
  }

  getLatestFormId(templateId: string) {
    return this.http.get(`apps/${templateId}/forms/latest-id`);
  }

  resetRegisterFilter() {
    this.resetRegisterViewFilterSubject.next();
  }

  async handleAttachmentUpload({ uploadBatchSize, target, uploader }) {
    const fileList = target.files;
    const acceptedTypes = target.accept;
    const processedFiles = AcceptableFilesEnforcer.enforce(fileList, acceptedTypes);
    target.value = null;
    const { accepted, rejected } = processedFiles;
    if (accepted.length > 0) {
      this.toastr.successByKey('filesUploadInProgress');
      await this.batchUploadFiles({
        batchSize: uploadBatchSize,
        files: processedFiles.accepted,
        uploader,
      });
    }

    if (rejected.length > 0) {
      this.toastr.warn(`Skipped ${rejected.length} file(s) with unexpected format.`);
    }
  }

  private batchUploadFiles<T = any>(configuration: {
    files: File[];
    uploader: (fakeId: number, formData: FormData) => Observable<T>;
    batchSize: number;
  }): Promise<[]> {
    return batchPromises(configuration.batchSize, configuration.files, (file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      const fakeId = Date.now();
      return configuration
        .uploader(fakeId, formData)
        .pipe(
          map((response) => ({
            response,
            fakeId,
          })),
        )
        .toPromise();
    });
  }

  getFormListDependencies(form: FormWeb): string[] {
    const items = form.items || [];
    const formListDependencies = [];

    const dependencyHandlers = {
      [FieldKinds.Category]: (item) => [item.reference],
      [FieldKinds.Table]: (item) => this.getTableListReferences(item.columns),
      [FieldKinds.PrefilledTable]: (item) => this.getPrefilledTableListReferences(item.rows || []),
    };

    items.forEach((item) => {
      const handler = dependencyHandlers[item.kind];
      if (handler) {
        const dependencies = handler(item);
        formListDependencies.push(...dependencies);
      }
    });

    return [...new Set(formListDependencies.filter(Boolean))];
  }

  private getPrefilledTableListReferences(rows: TableRow[]): string[] {
    const tableListReferences = [];
    rows.forEach((row) => {
      const references = this.getTableListReferences(row.columns);
      tableListReferences.push(...references);
    });

    return tableListReferences;
  }

  private getTableListReferences(columns: TableCellData[]): string[] {
    return columns
      .filter(
        (column) => column.kind === TableCellKinds.List && column.listSource === CategorySourceTypes.List,
      )
      .map((column) => column.reference);
  }
}
