import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

import {
  EventTypes,
  ILocationTableCell,
  TableColumnWidths,
  UserSnapshot,
} from '@site-mate/dashpivot-shared-library';

import { MapModalComponent } from 'app/form-fields/signon-table/location/map-modal/map-modal.component';
import { IModalService, IModalServiceToken } from 'app/shared/interface/modal/modal.interface';
import { ConfirmService } from 'app/shared/service/confirm.service';
import { EventsService } from 'app/shared/service/events/events.service';
import { GeolocationService } from 'app/shared/service/geolocation/geolocation.service';

@Component({
  selector: 'cc-form-table-location-cell',
  templateUrl: './form-table-location-cell.component.html',
  styleUrl: './form-table-location-cell.component.scss',
})
export class FormTableLocationCellComponent {
  readonly tableColumnWidths = TableColumnWidths;
  @Input() cellData: ILocationTableCell;
  @Input() width: TableColumnWidths;
  @Input() readOnly = false;
  locationData: ILocationTableCell['item']['data'];
  isGeoAPILoaded$: Observable<boolean> = this.geolocationService.isGeoAPILoaded$;
  user: UserSnapshot;
  @Output() onChange = new EventEmitter<void>();

  constructor(
    private readonly datePipe: DatePipe,
    private readonly geolocationService: GeolocationService,
    private readonly confirmService: ConfirmService,
    private readonly eventsService: EventsService,
    @Inject(IModalServiceToken) private readonly modalService: IModalService,
  ) {}

  ngOnInit() {
    if (this.cellData.item) {
      this.locationData = this.cellData.item.data;
      this.user = this.locationData.user as UserSnapshot;
    }
  }

  open(htmlEvent: Event) {
    htmlEvent.stopPropagation();
    this.eventsService.trackEvent(EventTypes.ViewMapClicked, { Context: 'Location Field Table Cell' });
    this.modalService.open(MapModalComponent, {
      data: {
        disableClose: true,
        GPSLocation: {
          lat: this.locationData.location.latitude,
          lng: this.locationData.location.longitude,
        },
        fullName: `${this.user.firstName} ${this.user.lastName}`,
        date: this.datePipe.transform(this.locationData.capturedAt, 'MMM dd, YYYY hh:mm a'),
        event: {
          type: EventTypes.GoolgeMapsRequestFromLocationField,
          metadata: {
            Context: 'Table Cell',
          },
        },
      },
    });
  }

  async onDeleteLocation() {
    try {
      await this.confirmService.confirmDelete('deleteLocationCell');
      this.cellData.item = null;
      this.onChange.emit();
      this.eventsService.trackEvent(EventTypes.LocationRemoved, { Context: 'Table Cell' });
    } catch (e) {
      // no-op
    }
  }
}
