"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldKinds = void 0;
var FieldKinds;
(function (FieldKinds) {
    FieldKinds["Attachment"] = "attachment";
    FieldKinds["Category"] = "category";
    FieldKinds["Date"] = "date";
    FieldKinds["DatePlain"] = "datePlain";
    FieldKinds["DateRangePlain"] = "dateRangePlain";
    FieldKinds["MultiLineInput"] = "multiLineInput";
    FieldKinds["PageBreak"] = "pageBreak";
    FieldKinds["Person"] = "person";
    FieldKinds["Photo"] = "photo";
    FieldKinds["PrefilledTable"] = "prefilledTable";
    FieldKinds["PreFilledText"] = "preFilledText";
    FieldKinds["Signature"] = "signature";
    FieldKinds["SignatureArray"] = "signatureArray";
    FieldKinds["SignonTable"] = "signonTable";
    FieldKinds["SingleLineInput"] = "singleLineInput";
    FieldKinds["Sketch"] = "sketch";
    FieldKinds["Table"] = "table";
    FieldKinds["YesNoCheckbox"] = "yesNoCheckbox";
})(FieldKinds = exports.FieldKinds || (exports.FieldKinds = {}));
