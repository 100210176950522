export enum BannerKeys {
  SelectAndArchiveFolders = 'select-and-archive-folders',
  DragAndDropFields = 'drag-and-drop-fields',
  FormAvailableInMobile = 'form-available-in-mobile',
  FormTableActions = 'form-table-actions',
  ArchiveLists = 'archive-lists',
  ExpiryDateInTimelineTemplates = 'expiry-date-in-timeline-templates',
  ExtendCellTypeToRows = 'extend-cell-type-to-rows',
  PersonField = 'person-field',
  FlowsiteXeroTimesheets = 'flowsite-xero-timesheets',
  FlowsiteXeroDockets = 'flowsite-xero-dockets',
  FlowsitePowerBiExcel = 'flowsite-power-bi-excel',
  FlowsitePowerBiExcelTemplates = 'flowsite-power-bi-excel-templates',
  FlowsiteQuickBooksDockets = 'flowsite-quickbooks-dockets',
  FlowsiteCSVExports = 'flowsite-csv-exports',
  FlowsitePDFExports = 'flowsite-pdf-exports',
  HeaderFooterClearRevertButtons = 'header-footer-clear-revert-buttons',
  ListPropertyTableCell = 'list-property-table-cell',
  CsvAutofill = 'csv-autofill',
}
