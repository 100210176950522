"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumberTableCellValidator = void 0;
class NumberTableCellValidator {
    constructor(cellData, headerColumn) {
        this.cellData = cellData;
        this.headerColumn = headerColumn;
    }
    hasAnyData() {
        return this.hasCompleteData();
    }
    hasCompleteData() {
        return !!this.cellData.value || this.cellData.value === 0;
    }
    isValid() {
        if (this.headerColumn && this.headerColumn.isRequired && !this.hasCompleteData()) {
            return false;
        }
        return !this.cellData.value || this.isNumber(this.cellData.value);
    }
    isNumber(value) {
        if (String(value).includes(' ')) {
            return false;
        }
        return !Number.isNaN(Number(value));
    }
    invalidDescription() {
        if (this.headerColumn && this.headerColumn.isRequired && !this.hasCompleteData()) {
            return 'required';
        }
        if (!this.isValid()) {
            return 'invalid';
        }
        return '';
    }
}
exports.NumberTableCellValidator = NumberTableCellValidator;
