import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PhotoTagGroup } from '@site-mate/dashpivot-shared-library';

import { PhotoTagGroupWithDecoratedTags } from 'app/shared/model/photo-tag-group.model';
import { PhotoTag } from 'app/shared/model/photo-tag.model';
import { MetaDataService } from 'app/shared/service/meta-data.service';

@Injectable({
  providedIn: 'root',
})
export class PhotoTagsService {
  teamTagGroups$: Observable<any>;
  projectTagGroups$: Observable<any>;

  constructor(private readonly metadataService: MetaDataService) {}

  getTeamTagGroups(teamId): Observable<PhotoTagGroupWithDecoratedTags[]> {
    return this.metadataService.getTeamTagGroups(teamId).pipe(map(this.decorateWithTags.bind(this)));
  }

  getProjectTagGroups(projectId): Observable<PhotoTagGroupWithDecoratedTags[]> {
    return this.metadataService.getProjectTagGroups(projectId).pipe(map(this.decorateWithTags.bind(this)));
  }

  getTagsFormattedForSelection(tagGroups: PhotoTagGroupWithDecoratedTags[]): PhotoTag[] {
    const tags = [];
    tagGroups.forEach((group) => {
      const formattedList = group.tags.map((tag) => {
        return {
          label: `${group.name}: ${tag.name}`,
          value: tag.name,
          groupId: group.id,
          groupName: group.name,
        };
      });
      tags.push(...formattedList);
    });

    return tags;
  }

  private decorateWithTags(tagGroups: PhotoTagGroup[]): PhotoTagGroupWithDecoratedTags[] {
    const now = Date.now();
    tagGroups.forEach((tagGroup) => {
      const localTagGroup = tagGroup as unknown as PhotoTagGroupWithDecoratedTags;
      localTagGroup.tags = tagGroup.tags.map((tag, index) => {
        return { id: now + index, name: tag };
      });
    });
    return tagGroups as unknown as PhotoTagGroupWithDecoratedTags[];
  }
}
