import { FlowsiteTemplateReference } from './flowsite-template-reference.model';

type ReferralEventContext = 'Xero' | 'PowerBI or Excel' | 'QuickBooks' | 'SharePoint';

export const FlowsiteReferralEventContext: Record<FlowsiteTemplateReference, ReferralEventContext> = {
  [FlowsiteTemplateReference.DashpivotXeroTimesheets]: 'Xero',
  [FlowsiteTemplateReference.DashpivotXeroDockets]: 'Xero',
  [FlowsiteTemplateReference.DashpivotPowerBiExcel]: 'PowerBI or Excel',
  [FlowsiteTemplateReference.DashpivotPowerBiExcelSiteDiary]: 'PowerBI or Excel',
  [FlowsiteTemplateReference.DashpivotPowerBiExcelIncidentReport]: 'PowerBI or Excel',
  [FlowsiteTemplateReference.DashpivotPowerBiExcelAuditForm]: 'PowerBI or Excel',
  [FlowsiteTemplateReference.DashpivotPowerBiExcelSwmsRams]: 'PowerBI or Excel',
  [FlowsiteTemplateReference.DashpivotPowerBiExcelJsa]: 'PowerBI or Excel',
  [FlowsiteTemplateReference.DashpivotPowerBiExcelMachinePrestart]: 'PowerBI or Excel',
  [FlowsiteTemplateReference.DashpivotPowerBiExcelAttendanceRecord]: 'PowerBI or Excel',
  [FlowsiteTemplateReference.DashpivotPowerBiExcelNonConformanceDefectReport]: 'PowerBI or Excel',
  [FlowsiteTemplateReference.DashpivotPowerBiExcelCostDayworkReport]: 'PowerBI or Excel',
  [FlowsiteTemplateReference.DashpivotQuickBooksDockets]: 'QuickBooks',
  [FlowsiteTemplateReference.DashpivotCSVExports]: 'PowerBI or Excel',
  [FlowsiteTemplateReference.DashpivotPDFExports]: 'SharePoint',
} as const;
