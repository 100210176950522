import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, NG_ASYNC_VALIDATORS, NgModel, Validator } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { GlobalApiService } from 'app/global-api/global-api.service';
import { UserService } from 'app/user/user.service';

import { UniqueEmailState } from './unique-email-state.enum';

@Directive({
  selector: '[ccUniqueEmail]',
  // eslint-disable-next-line no-use-before-define
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: UniqueEmailDirective, multi: true }],
})
export class UniqueEmailDirective implements AfterViewInit, OnDestroy, Validator {
  @Input() expectedPattern: RegExp;
  @Input() applyValidationStyleAndIcons = true;
  @Input() ngModel: NgModel;

  @Output() loading = new EventEmitter<{ isLoading: boolean; isUsedEmail?: boolean }>();

  private readonly requestsManager = new Subject<void>();

  constructor(
    private readonly userService: UserService,
    private readonly globalApiService: GlobalApiService,
    private readonly elementRef: ElementRef,
    private readonly renderer2: Renderer2,
  ) {}

  ngAfterViewInit() {
    if (this.applyValidationStyleAndIcons) {
      this.elementRef.nativeElement.insertAdjacentHTML(
        'afterend',
        [
          '<span class="unique-email-states">',
          '<i class="cc-icon cc-icon-spinner-lg state unique-email-pending"></i>',
          '<i class="cc-icon cc-icon-check state unique-email-valid"></i>',
          '<i class="cc-icon cc-icon-close state unique-email-invalid"></i>',
          '</span>',
        ].join(''),
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ngModel && !changes.ngModel.currentValue) {
      this.loading.emit({ isLoading: false });
    }
  }

  ngOnDestroy() {
    this.requestsManager.next();
    this.requestsManager.complete();
  }

  validate(control: AbstractControl) {
    const { nativeElement } = this.elementRef;
    const email = String(control.value);

    this.cleanStates(nativeElement);
    this.requestsManager.next();
    this.addClass(nativeElement, UniqueEmailState.Pending);
    this.loading.emit({ isLoading: true });

    return of(!!email.match(this.expectedPattern)).pipe(
      switchMap((valid) =>
        valid
          ? this.globalApiService.validateUserEmail(email).pipe(
              takeUntil(this.requestsManager),
              map(() => false),
            )
          : of(true),
      ),
      map((invalidFormat) => this.handleRecordFound(invalidFormat)),
      catchError(() => this.handleRecordNotFound()),
    );
  }

  private handleRecordFound(invalidFormat) {
    const { nativeElement } = this.elementRef;
    this.cleanStates(nativeElement);
    this.addClass(nativeElement, UniqueEmailState.Invalid);
    this.loading.emit({ isLoading: false, isUsedEmail: true });
    return { uniqueEmail: { invalidFormat } };
  }

  private handleRecordNotFound() {
    const { nativeElement } = this.elementRef;

    this.cleanStates(nativeElement);
    this.addClass(nativeElement, UniqueEmailState.Valid);
    this.loading.emit({ isLoading: false, isUsedEmail: false });

    return of(null);
  }

  private addClass(element, className) {
    if (this.applyValidationStyleAndIcons) {
      this.renderer2.addClass(element, className);
    }
  }

  private cleanStates(element) {
    if (this.applyValidationStyleAndIcons) {
      Object.keys(UniqueEmailState).forEach((key) =>
        this.renderer2.removeClass(element, UniqueEmailState[key]),
      );
    }
  }
}
