"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FINANCIAL_FORMULAS = exports.FINANCIAL_NOT_SUPPORTED_FORMULAS = exports.FINANCIAL_SUPPORTED_FORMULAS = exports.FINANCIAL_SUPPORTED_FORMULAS_WITH_INFO = void 0;
exports.FINANCIAL_SUPPORTED_FORMULAS_WITH_INFO = new Map([
    [
        'DOLLARDE',
        {
            syntax: 'DOLLARDE(Price, Fraction)',
            description: 'Converts a price entered with a special notation to a price displayed as a decimal number.',
        },
    ],
    [
        'DOLLARFR',
        {
            syntax: 'DOLLARFR(Price, Fraction)',
            description: 'Converts a price displayed as a decimal number to a price entered with a special notation.',
        },
    ],
    [
        'ISPMT',
        {
            syntax: 'ISPMT(Rate, Per, Nper, Value)',
            description: 'Returns the interest paid for a given period of an investment with equal principal payments.',
        },
    ],
    [
        'NOMINAL',
        {
            syntax: 'NOMINAL(Effect_rate, Npery)',
            description: 'Returns the nominal interest rate.',
        },
    ],
    [
        'NPER',
        {
            syntax: 'NPER(Rate, Pmt, Pv[, Fv[, Type]])',
            description: 'Returns the number of periods for an investment assuming periodic, constant payments and a constant interest rate.',
        },
    ],
    [
        'NPV',
        {
            syntax: 'NPV(Rate, Value1, ..., Value30)',
            description: 'Returns net present value.',
        },
    ],
    [
        'PDURATION',
        {
            syntax: 'PDURATION(Rate, Pv, Fv)',
            description: 'Returns number of periods to reach specific value.',
        },
    ],
    [
        'SLN',
        {
            syntax: 'SLN(Cost, Salvage, Life)',
            description: 'Returns the depreciation of an asset for one period, based on a straight-line method.',
        },
    ],
    [
        'XNPV',
        {
            syntax: 'XNPV(Rate, Payments, Dates)',
            description: 'Returns net present value.',
        },
    ],
]);
exports.FINANCIAL_SUPPORTED_FORMULAS = [...exports.FINANCIAL_SUPPORTED_FORMULAS_WITH_INFO.keys()];
exports.FINANCIAL_NOT_SUPPORTED_FORMULAS = [
    'CUMIPMT',
    'CUMPRINC',
    'DB',
    'DDB',
    'EFFECT',
    'FV',
    'FVSCHEDULE',
    'IPMT',
    'MIRR',
    'PMT',
    'PPMT',
    'PV',
    'RATE',
    'RRI',
    'SYD',
    'TBILLEQ',
    'TBILLPRICE',
    'TBILLYIELD',
];
exports.FINANCIAL_FORMULAS = exports.FINANCIAL_SUPPORTED_FORMULAS.concat(exports.FINANCIAL_NOT_SUPPORTED_FORMULAS);
