<div class="sm-flex sm-flex-col sm-gap-4">
  <header>
    <h1 class="sm-text-xl sm-font-medium sm-text-default-text">{{ title }}</h1>
    <button type="button" (click)="modal.dismiss()" class="close-modal-button" aria-label="Close modal">
      <i class="cc-icon cc-icon-close"></i>
    </button>
  </header>
  <div class="sm-flex sm-items-center">
    <p>Before continuing, please note the following warnings:</p>
    <div class="warning-learn-more sm-ml-auto">
      <a
        href="https://intercom.help/dashpivot/en/articles/5278836-template-links"
        target="_blank"
        class="btn btn-sm cc-btn-subtle-gray"
      >
        <i class="mr-1 cc-icon cc-icon-question sm-font-medium"></i>
        <span class="sm-font-medium">Learn more about Sitemate Link Errors</span>
      </a>
    </div>
  </div>
  <div class="warning-info-table">
    <div class="warning-info-item" *ngFor="let warning of warnings">
      <div class="sm-flex sm-items-center sm-gap-2">
        <i class="cc-icon cc-icon-warning sm-text-default-orange"></i>
        <div data-test="warning-title" class="sm-font-bold">{{ warning.title }}</div>
      </div>
      <div data-test="warning-text">
        <strong>{{ warning.templateName }}</strong> {{ warning.description }}
      </div>
    </div>
  </div>
  <div class="mt-2 text-center">
    <button type="button" class="btn btn-md cc-btn-subtle-blue-helper acknowledge" (click)="onAcknowledge()">
      Ok, I understand
    </button>
  </div>
</div>
