<div class="sm-flex sm-w-full sm-flex-col">
  <cc-photo-tool-bar
    [selected]="selected"
    (remove)="onRemove()"
    (download)="onDownload()"
    (exportPhoto)="onExport()"
    [users]="users"
    [teamId]="teamId"
    [downloadingPDF]="downloadingPDF"
    [downloadingZip]="downloadingZip"
    (resetAllFilters)="onResetAllFilters()"
    (searchSiteNote)="onSearchBySiteNote($event)"
    (searchUsers)="onSearchByUsers($event)"
    (searchDate)="onSearchByDate($event)"
    (move)="onMove()"
    (copy)="onCopy()"
    (addPhotoTags)="onAddPhotoTags()"
    [formRef]="formRef"
    [allSearchCriteria]="searchCriteria"
    [isLoadingUsers]="isLoadingUsers"
    [uploaderLabels]="uploaderLabels"
    [acceptedFiles]="acceptedFiles"
  ></cc-photo-tool-bar>

  <div class="sm-flex sm-h-full sm-overflow-scroll">
    <cc-tag-filter
      (filterTags)="onFilterTags($event)"
      [resetTagFilter]="resetTagFilter"
      [hierarchy]="hierarchy"
    >
    </cc-tag-filter>
    <div class="section-divider"></div>
    <div
      class="sm-srink"
      [class.photo-scroll-container]="!formRef"
      [class.photo-scroll-container-form-ref]="formRef"
      infiniteScroll
      (scrolled)="onScroll()"
      [infiniteScrollDistance]="2"
      [scrollWindow]="isScrollWindow()"
      [infiniteScrollThrottle]="0"
      #scroll
    >
      <div *ngFor="let timeLine of timeLines; let timeLineIndex = index">
        <div class="timeline sm-sticky sm-top-0 sm-z-10">
          <span class="abc-checkbox abc-checkbox-primary">
            <input
              [id]="timeLine.time"
              class="styled"
              type="checkbox"
              (change)="toggleSelectTimeLine(timeLine)"
              [(ngModel)]="timeLine.selected"
            />
            <label class="default-label checkbox-label" [for]="timeLine.time"> </label>
          </span>
          <strong
            >{{ timeLine.time | amDateFormat: 'ddd' }}
            {{ timeLine.time | amDateFormat: 'll' }}
          </strong>
          <span class="text-muted">({{ timeLine.photos.length }})</span>
        </div>
        <ul class="flex-wrap d-flex time-line-container">
          <li *ngFor="let photo of timeLine.photos; let photoIndex = index">
            <div
              class="item sm-group sm-cursor-pointer"
              [class.selected]="photo.selected"
              [class.item-form-ref]="formRef"
            >
              <img
                *ngIf="!isScrollWindow()"
                [lazyLoad]="photo.url.thumbnail"
                src="./assets/images/dashpivot-icon.png"
                [alt]="photo.filename"
                class="thumbnail"
                [scrollTarget]="scroll"
              />
              <img
                *ngIf="isScrollWindow()"
                [lazyLoad]="photo.url.thumbnail"
                src="./assets/images/dashpivot-icon.png"
                [alt]="photo.filename"
                class="thumbnail"
              />
              <div
                *ngIf="photo.isVideo"
                class="video-backdrop sm-flex sm-items-center sm-justify-center sm-bg-black sm-opacity-40 group-hover:sm-opacity-60"
              >
                <i class="fa fa-play fa-3x sm-text-white group-hover:sm-opacity-0" aria-hidden="true"></i>
              </div>
              <div class="backdrop-container">
                <div class="backdrop"></div>
                <div
                  class="author thumbnail-container sm-flex sm-items-center sm-justify-center group-hover:sm-opacity-100"
                  (click)="onThumbnailClick($event, photo, timeLineIndex, photoIndex)"
                >
                  <div *ngIf="photo.createdBy" class="d-flex flex-column align-items-center">
                    <strong>{{ photo.createdBy.firstName }}</strong>
                    <strong>{{ photo.createdBy.lastName }}</strong>
                  </div>
                </div>
                <div class="checkbox abc-checkbox abc-checkbox-primary">
                  <input
                    [id]="photo.id"
                    class="styled"
                    type="checkbox"
                    [(ngModel)]="photo.selected"
                    (change)="$event.stopPropagation(); toggle(photo)"
                  />
                  <label class="default-label checkbox-label" [for]="photo.id"> </label>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div
        *ngIf="!loading && isEmptySearchCriteria() && timeLines && timeLines.length === 0"
        class="sm-flex sm-h-full sm-justify-center sm-bg-grey-1"
      >
        <div class="photo-empty-state">
          <img class="photos-image" src="/assets/images/photos-image.svg" alt="photos-image" />
          <h3 class="sm-mt-2 sm-text-xl sm-font-medium" i18n>No photos have been uploaded yet</h3>
          <h5 class="sm-mt-4 sm-text-sm sm-text-grey-5" i18n>
            You can upload from your computer, or download and install the Dashpivot mobile app to take photos
            and videos on your phone or tablet
          </h5>
          <div class="sm-mt-4 sm-flex sm-flex-row sm-space-x-2">
            <button
              type="button"
              class="btn btn-success"
              data-test="upload-button"
              (click)="uploadPhoto()"
              i18n
            >
              Upload photos
            </button>
            <a
              href="https://intercom.help/dashpivot/en/articles/2563237-photos-overview"
              target="_blank"
              class="btn cc-btn-subtle-dark-gray"
              rel="noopener noreferrer"
            >
              <em class="cc-icon cc-icon-question"></em> <span>Learn more</span>
            </a>
          </div>
        </div>
        <div
          class="empty-state-arrow"
          [class.arrow-photo-section]="!formRef"
          [class.arrow-form-modal]="formRef"
        >
          <em aria-hidden="true" class="fa fa-arrow-up fa-2x text-success"></em>
        </div>
      </div>
      <div
        *ngIf="!loading && !isEmptySearchCriteria() && timeLines && timeLines.length === 0"
        class="mt-5 text-center"
      >
        <h5 class="h5" i18n>No results found</h5>
      </div>
      <div *ngIf="loading" class="mt-5 text-center">
        <i class="cc-icon cc-icon-spinner-2x"></i>
      </div>
    </div>
  </div>
</div>
