"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListEntryTypes = void 0;
var ListEntryTypes;
(function (ListEntryTypes) {
    ListEntryTypes["Attachment"] = "attachment";
    ListEntryTypes["Text"] = "text";
    ListEntryTypes["Number"] = "number";
    ListEntryTypes["Date"] = "date";
    ListEntryTypes["DateExpiry"] = "date-expiry";
})(ListEntryTypes = exports.ListEntryTypes || (exports.ListEntryTypes = {}));
