<div class="sm-flex sm-gap-0.4">
  <ng-container *ngFor="let fieldSettingName of orderedFieldSettingsNames">
    <cc-tool-tip
      attr.data-test="{{ fieldSettingName }}"
      placement="bottom"
      contentText="{{ getTooltipText(fieldSettingName) }}"
      variant="compact"
    >
      <i
        class="cc-icon cc-icon-{{ fieldSettingsIndicatorMap[fieldSettingName].icon }}"
        [ngClass]="!!fieldSettings[fieldSettingName] ? 'sm-text-grey-8' : 'sm-text-grey-4'"
      ></i>
    </cc-tool-tip>
  </ng-container>
</div>
