"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogicRulesService = void 0;
const field_kinds_enum_1 = require("../models/fields/field-kinds.enum");
const logic_rules_model_1 = require("../models/fields/logic-rules.model");
class LogicRulesService {
    /**
     * Loop each rule in a field and evaluate, updating the rule's isTrue property
     */
    evaluate(field) {
        var _a;
        if (!field.logicRules || field.logicRules.length === 0) {
            return [];
        }
        return (_a = field.logicRules) === null || _a === void 0 ? void 0 : _a.map((rule) => (Object.assign(Object.assign({}, rule), { isTrue: this.evaluateRule(field, rule) })));
    }
    evaluateRule(field, rule) {
        if (field.kind === field_kinds_enum_1.FieldKinds.YesNoCheckbox) {
            return this.evaluateYesNoRule(field.checked, rule);
        }
        if (field.kind === field_kinds_enum_1.FieldKinds.Category) {
            return this.evaluateCategoryRule(field.selectedItems, rule);
        }
        return false;
    }
    isLogicField(field) {
        return (field.kind === field_kinds_enum_1.FieldKinds.YesNoCheckbox ||
            field.kind === field_kinds_enum_1.FieldKinds.Category ||
            field.isApprovalSignature === true);
    }
    isDependentField(field, fields) {
        return (!!field.dependsOn &&
            fields.some((currentField) => {
                var _a, _b;
                return currentField.id === ((_a = field.dependsOn) === null || _a === void 0 ? void 0 : _a.fieldId) &&
                    ((_b = currentField.logicRules) === null || _b === void 0 ? void 0 : _b.some((rule) => { var _a; return rule.id === ((_a = field.dependsOn) === null || _a === void 0 ? void 0 : _a.logicRuleId); }));
            }));
    }
    isValid(field, allFields) {
        var _a;
        const DEFAULT_INVALID_MESSAGE = 'Logic Rule is invalid';
        const EMPTY_LOGIC_RULE_FIELDS = 'Logic Rule requires at least one field';
        const EMPTY_LOGIC_RULE_VALUE = 'Logic Rule requires a trigger';
        const DUPLICATED_LOGIC_RULE_VALUE = 'Logic Rules cannot have the same trigger as each other';
        const INVALID_PAGE_BREAK_INSIDE_LOGIC = 'Logic Rule cannot contain a page break field';
        if (this.isLogicField(field) && ((_a = field.logicRules) === null || _a === void 0 ? void 0 : _a.length)) {
            field.logicRules.forEach((rule) => {
                var _a, _b;
                if (!((_a = rule.values) === null || _a === void 0 ? void 0 : _a.length)) {
                    rule._invalid = true;
                    rule._invalidMessage = EMPTY_LOGIC_RULE_VALUE;
                    return;
                }
                const fieldsToShow = allFields.filter((f) => { var _a; return ((_a = f.dependsOn) === null || _a === void 0 ? void 0 : _a.logicRuleId) === rule.id; });
                if (rule.logicAction === logic_rules_model_1.LogicActions.ShowField && fieldsToShow.length === 0) {
                    rule._invalid = true;
                    rule._invalidMessage = EMPTY_LOGIC_RULE_FIELDS;
                    return;
                }
                const hasPageBreak = fieldsToShow.some((logicField) => logicField.kind === field_kinds_enum_1.FieldKinds.PageBreak);
                if (hasPageBreak) {
                    rule._invalid = true;
                    rule._invalidMessage = INVALID_PAGE_BREAK_INSIDE_LOGIC;
                    return;
                }
                const hasApprovalSignature = fieldsToShow.some((logicField) => logicField.kind === field_kinds_enum_1.FieldKinds.Signature);
                if (hasApprovalSignature) {
                    rule._invalid = true;
                    rule._invalidMessage = DEFAULT_INVALID_MESSAGE;
                    return;
                }
                const hasDuplicateRuleValue = (_b = field.logicRules) === null || _b === void 0 ? void 0 : _b.some((otherRule) => otherRule.id !== rule.id &&
                    otherRule.logicOperator === rule.logicOperator &&
                    this.hasIntersection(rule.values, otherRule.values));
                if (hasDuplicateRuleValue) {
                    rule._invalid = true;
                    rule._invalidMessage = DUPLICATED_LOGIC_RULE_VALUE;
                    return;
                }
                rule._invalid =
                    field.kind === field_kinds_enum_1.FieldKinds.YesNoCheckbox
                        ? !this.validateYesNoRule(rule)
                        : !this.validateCategoryRule(rule);
                if (rule._invalid) {
                    rule._invalidMessage = DEFAULT_INVALID_MESSAGE;
                }
            });
            return !field.logicRules.some((rule) => rule._invalid);
        }
        return true;
    }
    hasIntersection(array1, array2) {
        return array1.some((value) => array2.includes(value));
    }
    evaluateYesNoRule(checked, rule) {
        var _a;
        if ((rule === null || rule === void 0 ? void 0 : rule.logicOperator) === logic_rules_model_1.LogicOperators.Equals) {
            return (_a = rule.values) === null || _a === void 0 ? void 0 : _a.includes(checked || '');
        }
        return false;
    }
    evaluateCategoryRule(selectedItems, rule) {
        var _a;
        if ((rule === null || rule === void 0 ? void 0 : rule.logicOperator) === logic_rules_model_1.LogicOperators.Equals) {
            return (_a = rule.values) === null || _a === void 0 ? void 0 : _a.some((id) => selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems.some((item) => item.id === id));
        }
        return false;
    }
    validateYesNoRule(rule) {
        if (rule.logicOperator !== logic_rules_model_1.LogicOperators.Equals) {
            return false;
        }
        return true;
    }
    validateCategoryRule(rule) {
        if (!rule.logicOperator ||
            !(rule.logicOperator === logic_rules_model_1.LogicOperators.Equals || rule.logicOperator === logic_rules_model_1.LogicOperators.DoesNotEqual)) {
            return false;
        }
        return true;
    }
}
exports.LogicRulesService = LogicRulesService;
