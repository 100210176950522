import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textEmphasis',
})
export class TextEmphasisPipe implements PipeTransform {
  readonly transformTypes = { bold: 'b', italic: 'i', underline: 'u', strikethrough: 's' };

  transform(value = '', regex: string, emphasisType: string): string {
    const emphasis = this.transformTypes[emphasisType];
    const regEx = new RegExp(regex, 'gi');

    if (regex) {
      return value.replace(regEx, `<${emphasis}>$&</${emphasis}>`);
    }
    return value;
  }
}
