import { EventEmitter } from '@angular/core';

export enum FieldSettingsNames {
  IsRequired = 'isRequired',
  IsHiddenInFormPDFExports = 'isHiddenInFormPDFExports',
  isExcludedInFormCloning = 'isExcludedInFormCloning',
  IsPhotoDescriptionVisible = 'isPhotoDescriptionVisible',
  IsPhotoTagsVisible = 'isPhotoTagsVisible',
}

export interface IFieldSetting {
  icon: string;
  fieldName: FieldSettingsNames;
}

export type FieldSettingsRenderAttributes = IFieldSetting & {
  tooltipText: (value: boolean) => string;
};

export interface IFieldSettingsAction extends IFieldSetting {
  label: string;
  isVisible: boolean;
  onUpdate: EventEmitter<any>;
}
