import { Component, Input, ViewEncapsulation } from '@angular/core';

import { SitemateImageUtils } from 'app/shared/service/sitemate-image-utils';

@Component({
  selector: 'cc-signon-table-user',
  templateUrl: './signon-table-user.component.html',
  styleUrls: ['./signon-table-user.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SignonTableUserComponent {
  @Input() user;

  constructor(private readonly sitemateImageUtils: SitemateImageUtils) {}

  getAvatarImage(url) {
    return this.sitemateImageUtils.getImageVariantUrl(url, 'small');
  }
}
