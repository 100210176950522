import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import { BannerKeys } from '../model/banner-keys.enum';
import { BannerService } from '../service/banner.service';
import { TeamService } from '../service/team.service';

@Directive({
  selector: '[ccBadgeLogic]',
})
export class BadgeLogicDirective implements OnInit {
  @Input() featureKey: BannerKeys;

  constructor(
    private element: ElementRef,
    private bannerService: BannerService,
    private readonly teamService: TeamService,
  ) {}

  ngOnInit(): void {
    const status = this.teamService.getCurrentSubscriptionStatus();

    const isBadgeVisible = this.bannerService.isVisibleBasedOnBannerTypeAndSubscription(
      this.featureKey,
      status,
      true,
    );
    this.element.nativeElement.hidden = !isBadgeVisible;
  }
}
