import { FlowsiteTemplateReference } from './flowsite-template-reference.model';

export const FlowsiteBannerPopoverMetadata: Readonly<
  Record<FlowsiteTemplateReference, { imgSrc: string; imgAlt: string; text: string; useCases?: string[] }>
> = {
  [FlowsiteTemplateReference.DashpivotXeroTimesheets]: {
    imgSrc: '/assets/images/flowsite/xero-timesheet.svg',
    imgAlt: 'Xero Timesheets',
    text: '<span class="sm-font-bold">Flowsite</span> is a separate integrations platform that can automate timesheet information between Dashpivot and <span class="sm-font-bold">Xero</span>.',
  },
  [FlowsiteTemplateReference.DashpivotXeroDockets]: {
    imgSrc: '/assets/images/flowsite/xero-invoice.svg',
    imgAlt: 'Xero Invoice',
    text: '<span class="sm-font-bold">Flowsite</span> is a separate integrations platform that can automatically create and populate Dashpivot docket data straight into <span class="sm-font-bold">Xero</span> invoices.',
  },
  [FlowsiteTemplateReference.DashpivotPowerBiExcel]: {
    imgSrc: '/assets/images/flowsite/power-bi-excel.svg',
    imgAlt: 'Power BI Excel Templates',
    text: '<span class="sm-font-bold">Flowsite</span> is a separate integrations platform that can automate sending form data from Dashpivot to <span class="sm-font-bold">Excel</span> or <span class="sm-font-bold">Power BI</span>.',
  },
  [FlowsiteTemplateReference.DashpivotPowerBiExcelSiteDiary]: {
    imgSrc: '/assets/images/flowsite/power-bi-site-diary.svg',
    imgAlt: 'Power BI Excel Site Diary',
    text: '<span class="sm-font-bold">Flowsite</span> is a separate integrations platform that can automate Site Diary form data from Dashpivot to <span class="sm-font-bold">Excel</span> or <span class="sm-font-bold">Power BI</span>.',
    useCases: [
      '<span>Automatically generate a timesheet in Excel for workforce sign ons</span>',
      '<span>Real time data on staff and subcontractor hours in Power BI</span>',
    ],
  },
  [FlowsiteTemplateReference.DashpivotPowerBiExcelIncidentReport]: {
    imgSrc: '/assets/images/flowsite/power-bi-incident-report.svg',
    imgAlt: 'Power BI Excel Incident Report',
    text: '<span class="sm-font-bold">Flowsite</span> is a separate integrations platform that can automate Incident Report form data from Dashpivot to <span class="sm-font-bold">Excel</span> or <span class="sm-font-bold">Power BI</span>.',
    useCases: [
      '<span>See real time data and trends on health and safety stats in Power BI</span>',
      '<span>Assess whether organisation is meeting quality KPIs</span>',
    ],
  },
  [FlowsiteTemplateReference.DashpivotPowerBiExcelAuditForm]: {
    imgSrc: '/assets/images/flowsite/power-bi-audit.svg',
    imgAlt: 'Power BI Excel Audit Form',
    text: '<span class="sm-font-bold">Flowsite</span> is a separate integrations platform that can automate Audit form data from Dashpivot to <span class="sm-font-bold">Excel</span> or <span class="sm-font-bold">Power BI</span>.',
    useCases: [
      '<span>See real time data and trends on health and safety stats in Power BI</span>',
      '<span>Assess whether organisation is meeting quality KPIs</span>',
    ],
  },
  [FlowsiteTemplateReference.DashpivotPowerBiExcelSwmsRams]: {
    imgSrc: '/assets/images/flowsite/power-bi-swms-rams.svg',
    imgAlt: 'Power BI Excel SWMS RAMS',
    text: '<span class="sm-font-bold">Flowsite</span> is a separate integrations platform that can automate SWMS/RAMS form data from Dashpivot to <span class="sm-font-bold">Excel</span> or <span class="sm-font-bold">Power BI</span>.',
    useCases: [
      '<span>See real time data and trends on health and safety stats in Power BI</span>',
      '<span>Assess whether organisation is meeting quality KPIs</span>',
    ],
  },
  [FlowsiteTemplateReference.DashpivotPowerBiExcelJsa]: {
    imgSrc: '/assets/images/flowsite/power-bi-jsa.svg',
    imgAlt: 'Power BI Excel JSA',
    text: '<span class="sm-font-bold">Flowsite</span> is a separate integrations platform that can automate JSA form data from Dashpivot to <span class="sm-font-bold">Excel</span> or <span class="sm-font-bold">Power BI</span>.',
    useCases: [
      '<span>See real time data and trends on health and safety stats in Power BI</span>',
      '<span>Assess whether organisation is meeting quality KPIs</span>',
    ],
  },
  [FlowsiteTemplateReference.DashpivotPowerBiExcelMachinePrestart]: {
    imgSrc: '/assets/images/flowsite/power-bi-pre-start.svg',
    imgAlt: 'Power BI Excel Pre Start',
    text: '<span class="sm-font-bold">Flowsite</span> is a separate integrations platform that can automate Machinery Prestart form data from Dashpivot to <span class="sm-font-bold">Excel</span> or <span class="sm-font-bold">Power BI</span>.',
    useCases: [
      '<span>Quickly make decisions on whether a piece of plant is due for service</span>',
      '<span>Easily see global view of all your machines across all project sites</span>',
    ],
  },
  [FlowsiteTemplateReference.DashpivotPowerBiExcelAttendanceRecord]: {
    imgSrc: '/assets/images/flowsite/power-bi-attendance-record.svg',
    imgAlt: 'Power BI Excel Attendance Record',
    text: '<span class="sm-font-bold">Flowsite</span> is a separate integrations platform that can automate Attendance Record form data from Dashpivot to <span class="sm-font-bold">Excel</span> or <span class="sm-font-bold">Power BI</span>.',
    useCases: ['<span>Automatically generate a timesheet in Excel for workforce sign ons</span>'],
  },
  [FlowsiteTemplateReference.DashpivotPowerBiExcelNonConformanceDefectReport]: {
    imgSrc: '/assets/images/flowsite/power-bi-non-conformance-defect-report.svg',
    imgAlt: 'Power BI Excel Non Conformance Defect Report',
    text: '<span class="sm-font-bold">Flowsite</span> is a separate integrations platform that can automate Defect Report or Non-Conformance form data from Dashpivot to <span class="sm-font-bold">Excel</span> or <span class="sm-font-bold">Power BI</span>.',
    useCases: [
      '<span>See defects or non-conformances across all your projects from a single dashboard</span>',
      '<span>Assess whether organisation is meeting quality KPIs</span>',
    ],
  },
  [FlowsiteTemplateReference.DashpivotPowerBiExcelCostDayworkReport]: {
    imgSrc: '/assets/images/flowsite/power-bi-cost-daywork-report.svg',
    imgAlt: 'Power BI Excel Cost Daywork Report',
    text: '<span class="sm-font-bold">Flowsite</span> is a separate integrations platform that can automate Cost or Daywork form data from Dashpivot to <span class="sm-font-bold">Excel</span> or <span class="sm-font-bold">Power BI</span>.',
    useCases: [
      '<span>See real time difference of total cost vs budgeted cost for your projects</span>',
      '<span>See real time difference of estimated production quantities vs actuals so can see if project is running to schedule</span>',
    ],
  },
  [FlowsiteTemplateReference.DashpivotQuickBooksDockets]: {
    imgSrc: '/assets/images/flowsite/quickbooks-docket.svg',
    imgAlt: 'QuickBooks Docket',
    text: '<span class="sm-font-bold">Flowsite</span> is a separate integrations platform that can automatically create and populate Dashpivot docket data straight into <span class="sm-font-bold">QuickBooks</span> invoices.',
  },
  [FlowsiteTemplateReference.DashpivotCSVExports]: {
    imgSrc: '/assets/images/flowsite/csv-export.svg',
    imgAlt: 'Power BI Excel CSV Exports',
    text: '<span class="sm-font-bold">Flowsite</span> is a separate integrations platform that can automate CSV data into <span class="sm-font-bold">Excel</span> or <span class="sm-font-bold">Power BI</span>. By automatically exporting CSV data, you can create dynamic reports, dashboards, and visualizations, saving you time and ensuring data accuracy.',
  },
  [FlowsiteTemplateReference.DashpivotPDFExports]: {
    imgSrc: '/assets/images/flowsite/pdf-sharepoint.svg',
    imgAlt: 'SharePoint',
    text: '<span class="sm-font-bold">Flowsite</span> is a separate integrations platform that can automate PDF forms into <span class="sm-font-bold">SharePoint</span>. It streamlines the process of capturing and storing information, to improve collaboration and compliance with document management protocols.',
  },
};
