import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Subject, distinctUntilChanged, lastValueFrom, takeUntil } from 'rxjs';

import { EventTypes, ExportOutputFormats, IUser, PathService } from '@site-mate/dashpivot-shared-library';

import { BannerKeys } from 'app/shared/model/banner-keys.enum';
import { BannerMetadata } from 'app/shared/model/banner-metadata.model';
import { AccountService } from 'app/shared/service/account.service';
import { BannerService } from 'app/shared/service/banner.service';
import { CompanyService } from 'app/shared/service/company.service';
import { DownloaderService } from 'app/shared/service/downloader.service';
import { EventsService } from 'app/shared/service/events/events.service';
import { LocalStorageService } from 'app/shared/service/local-storage.service';
import { UserService } from 'app/user/user.service';
import { environment } from 'environments/environment';

import { FlowsiteBannerKeyByTemplateReference } from './flowsite-banner-key-by-template-reference.model';
import { FlowsiteBannerMetadataList } from './flowsite-banner-metadata.model';
import { FlowsiteBannerPopoverMetadata } from './flowsite-banner-popover-metadata.model';
import { FlowsiteReferralEventContext } from './flowsite-referral-event-context.model';
import { FlowsiteTemplateReference } from './flowsite-template-reference.model';

@Component({
  selector: 'cc-flowsite-banner',
  templateUrl: './flowsite-banner.component.html',
  styleUrls: ['./flowsite-banner.component.scss'],
})
export class FlowsiteBannerComponent implements OnInit {
  private readonly baseLink = `${environment.flowsite.uiUrl}/signup?reference=`;
  private destroy$ = new Subject<void>();

  private accountId: string;

  protected readonly isEnabled = environment.featureToggles.flowsiteReferralBanner;

  readonly flowsiteReferenceTypes = FlowsiteTemplateReference;
  readonly flowsiteBannerPopoverMetadata = FlowsiteBannerPopoverMetadata;
  readonly bannerLogoAndTools = {
    [FlowsiteTemplateReference.DashpivotXeroTimesheets]: {
      logo: '/assets/images/flowsite/xero-logo.svg',
      tool: 'Xero',
      linkTool: 'Timesheets',
    },
    [FlowsiteTemplateReference.DashpivotXeroDockets]: {
      logo: '/assets/images/flowsite/xero-logo.svg',
      tool: 'Xero',
      linkTool: 'Dockets',
    },
    [FlowsiteTemplateReference.DashpivotQuickBooksDockets]: {
      logo: '/assets/images/flowsite/quickbooks-logo.svg',
      tool: 'QuickBooks',
      linkTool: 'Dockets',
    },
  };
  readonly xeroBannersOldLocalStorageKeys = [
    'flowsite-xero-dockets-banner',
    'flowsite-xero-timesheets-banner',
  ];

  bannerKey: BannerKeys;
  localStorageKey: string;
  flowsiteReferenceType: FlowsiteTemplateReference;
  referenceLink: string;
  isTimesheetOrDocketBanner: boolean;
  isPowerBiOrExcelBanner: boolean;
  isCSVExportBanner: boolean;
  isPDFExportBanner: boolean;
  shouldShowBanner: boolean = false;
  user = {} as IUser;
  isContributor: boolean;
  hasFlowsiteWorkspaces: boolean;
  minExportsPerPeriod = { [ExportOutputFormats.Csv]: 5, [ExportOutputFormats.Pdf]: 11 };

  @Input({ required: true }) templateName!: string;
  @Input() templatePath: string;
  @Input() companyId: string;

  @Input() priority: number;
  @Input() parentComponent: string;

  constructor(
    private readonly eventsService: EventsService,
    private readonly pathService: PathService,
    private readonly companyService: CompanyService,
    private readonly userService: UserService,
    private readonly accountService: AccountService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly localStorageService: LocalStorageService,
    private readonly bannerService: BannerService,
    private readonly downloaderService: DownloaderService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.user = this.userService.getCurrentUser();

    if (!this.companyId) {
      const [companyId] = this.pathService.extractFolderIds(this.templatePath);
      this.companyId = companyId;
    }

    if (!this.companyId) {
      return;
    }

    await this.setAccountId();

    this.isContributor = this.isUserAContributor();
    this.hasFlowsiteWorkspaces = (await this.getFlowsiteWorkspaces())?.length > 0;
    this.flowsiteReferenceType = this.getBannerType();

    if (this.flowsiteReferenceType) {
      this.bannerKey = FlowsiteBannerKeyByTemplateReference[this.flowsiteReferenceType];
      this.localStorageKey = BannerMetadata[this.bannerKey].localStorageKey;

      this.isCSVExportBanner = this.flowsiteReferenceType.includes('csv-exports');
      this.isPDFExportBanner = this.flowsiteReferenceType.includes('pdf-exports');
      this.isTimesheetOrDocketBanner = Object.keys(this.bannerLogoAndTools).includes(
        this.flowsiteReferenceType,
      );
      this.isPowerBiOrExcelBanner = this.flowsiteReferenceType.includes('power-bi-excel');

      await this.setBannerVisibility();
      this.setBannerPropertiesBasedOnBannerType();
    }
  }

  private async setBannerVisibility() {
    if (this.isCSVExportBanner || this.isPDFExportBanner) {
      this.bannerService.numberOfExports$
        .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
        .subscribe(() => {
          this.setExportsBannerVisibility();
        });

      await this.getUserExportCount();
    } else {
      this.shouldShowBanner = this.isEnabled && this.isContributor && !this.hasFlowsiteWorkspaces;
    }
  }

  trackFlowsiteReferral() {
    this.eventsService.trackEvent(EventTypes.FlowsiteReferralClicked, {
      Context: FlowsiteReferralEventContext[this.flowsiteReferenceType],
    });
  }

  private getBannerType() {
    const templateName = this.templateName.toLowerCase();
    const bannerType = FlowsiteBannerMetadataList.find((metadata) => {
      const isTemplateNameAMatch = metadata.regex.test(templateName);

      if (metadata.continents?.exclude) {
        return !metadata.continents.exclude.includes(this.user.continent) && isTemplateNameAMatch;
      }

      if (metadata.continents?.include) {
        return metadata.continents.include.includes(this.user.continent) && isTemplateNameAMatch;
      }

      return isTemplateNameAMatch;
    });

    return bannerType?.reference || null;
  }

  private setBannerPropertiesBasedOnBannerType() {
    this.bannerKey = FlowsiteBannerKeyByTemplateReference[this.flowsiteReferenceType];
    this.localStorageKey = BannerMetadata[this.bannerKey].localStorageKey;

    if ([BannerKeys.FlowsiteXeroTimesheets, BannerKeys.FlowsiteXeroDockets].includes(this.bannerKey)) {
      this.hideXeroBannerBasedOnOldLocalStorageKey();
    }

    this.setReferenceLink();

    this.changeDetectorRef.detectChanges();
  }

  private isUserAContributor() {
    const isUserLoggedIn = Boolean(this.user._id); // Handles embed template on IFrame pages
    const isUserAVisitorOfCompany = this.user.visitorOf?.includes(this.companyId);

    return this.user.isGlobalAdmin || (isUserLoggedIn && !isUserAVisitorOfCompany);
  }

  private async setAccountId() {
    const company = await lastValueFrom(this.companyService.getCompany(this.companyId));

    this.accountId = company?.accountId;
  }

  private async getFlowsiteWorkspaces() {
    return lastValueFrom(this.accountService.getFlowsiteWorkspaces(this.accountId));
  }

  private setReferenceLink() {
    const { baseLink, flowsiteReferenceType, companyId, accountId } = this;

    this.referenceLink = `${baseLink}${flowsiteReferenceType}&sitemateStartAccountId=${accountId}&workspaceId=${companyId}`;
  }

  private hideXeroBannerBasedOnOldLocalStorageKey() {
    const hasOldBannerBeenDismissed = this.xeroBannersOldLocalStorageKeys.some(
      (key) => this.localStorageService.getItem(`${key}-visible`) === 'false',
    );

    if (hasOldBannerBeenDismissed) {
      this.shouldShowBanner = false;
    }
  }

  private async getUserExportCount() {
    const csvCount = await lastValueFrom(
      this.downloaderService.getExportSessionCountByUser({ type: ExportOutputFormats.Csv, periodInDays: 14 }),
    );
    const pdfCount = await lastValueFrom(
      this.downloaderService.getExportSessionCountByUser({ type: ExportOutputFormats.Pdf, periodInDays: 28 }),
    );
    this.bannerService.setNumberOfExports({ csv: csvCount, pdf: pdfCount });
  }

  private setExportsBannerVisibility() {
    const exportType = this.isCSVExportBanner ? ExportOutputFormats.Csv : ExportOutputFormats.Pdf;
    const shouldShowExportBanner =
      this.bannerService.getNumberOfExports()[exportType] > this.minExportsPerPeriod[exportType];

    if (shouldShowExportBanner) {
      this.shouldShowBanner = this.isEnabled && this.isContributor && !this.hasFlowsiteWorkspaces;
    } else {
      this.bannerService.removeBannerFromPriorityList(this.bannerKey, this.parentComponent);
      this.shouldShowBanner = false;
    }

    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
