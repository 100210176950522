/* eslint-disable max-lines */
/* eslint-disable no-param-reassign  */
import { Injectable } from '@angular/core';
import { cloneDeep, isEqual } from 'lodash-es';

import {
  Field,
  FieldKinds,
  TableCellData,
  TableCellKinds,
  TableRow,
} from '@site-mate/dashpivot-shared-library';

@Injectable()
export class FormUtil {
  static removeListContentFromColumns(columns: TableCellData[]): void {
    columns.forEach((column) => {
      if (column.kind === TableCellKinds.List) {
        delete column.listContent;
      }
    });
  }

  private static processTableItem = (item: Field) => {
    FormUtil.removeListContentFromColumns(item.columns);
    FormUtil.removeColumnsInternalProperties(item.rows);
  };

  private static processPrefilledTableItem = (item) => {
    item.rows.forEach((row) => {
      FormUtil.removeListContentFromColumns(row.columns);
    });
    FormUtil.removeColumnsInternalProperties(item.rows);
  };

  private static processSketchItem = (item, originalItem) => {
    if (item.canvasObj) {
      const { canvasObj } = originalItem;
      item.canvas = JSON.stringify(canvasObj.toJSON());
      item.img = canvasObj.toDataURL('png');
      delete item.canvasObj;
    }
  };

  static undecorateItems(items: Field[]) {
    const cloned = cloneDeep(items);
    const itemProcessors = {
      [FieldKinds.Table]: FormUtil.processTableItem,
      [FieldKinds.PrefilledTable]: FormUtil.processPrefilledTableItem,
      [FieldKinds.Sketch]: FormUtil.processSketchItem,
    };

    cloned.forEach((item: Field, index: number) => {
      delete item._invalid;
      delete item._dirty;

      const processItem = itemProcessors[item.kind];

      if (processItem && item.kind === FieldKinds.Sketch) {
        processItem(item, items[index]);
      } else if (processItem) {
        processItem(item);
      }
    });

    return cloned;
  }

  static removeColumnsInternalProperties(rows: TableRow[] = []) {
    rows.forEach(({ columns }) => {
      if (columns?.length) {
        columns.forEach((column) => {
          delete column._invalid;

          if (column.kind === TableCellKinds.Date) {
            delete column._date;
          } else if (column.kind === TableCellKinds.Time) {
            delete column._time;
          }
        });
      }
    });
  }

  // TODO: This method is for a 7 year old backwards compatibility issue, we should remove it if possible
  static decorateTableColumnItems(items: Field[]) {
    items.forEach((i) => {
      i.id = i.id || i._id; // TODO: We should change all the places where fields are referenced by `id` to `_id`
      switch (i.kind) {
        case FieldKinds.Table:
          if (i.rows) {
            i.rows.forEach((r) => {
              if (r.columns) {
                if (r.columns.every((c) => typeof c === 'string')) {
                  // old flat data
                  r.columns = r.columns.map((c) => {
                    return { value: c };
                  });
                }
              }
            });
          }
          break;

        default:
        // do nothing
      }
    });
  }

  static getRegisterViewSearchStr(criteria) {
    let url = '';
    if (criteria.length > 0) {
      criteria.forEach((c) => {
        Object.keys(c).forEach((k) => {
          url += `&${k}=${c[k]}`;
        });
      });
    }
    return url;
  }

  static detectAttachmentChange(oldAttachments, newAttachments) {
    return !isEqual(oldAttachments, newAttachments);
  }
}
