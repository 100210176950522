import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { TmpI18NService } from 'app/i18n/tmp-i18n.service';

@Component({
  selector: 'cc-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss'],
})
export class WarningModalComponent implements OnInit {
  @Input() titleKey: string;
  @Input() messageKey: string;
  @Input() continueLabel: string;
  title: string;
  body: string;

  constructor(
    public modal: NgbActiveModal,
    private i18nService: TmpI18NService,
  ) {}

  ngOnInit(): void {
    this.title = this.i18nService.getMessage(this.titleKey);
    this.body = this.i18nService.getMessage(this.messageKey);
  }
}
