import { Injectable } from '@angular/core';
import { merge } from 'lodash-es';
import { Subject } from 'rxjs';

import { UploaderParams } from 'app/shared/model/uploader-params.model';

import { UuidService } from './uuid/uuid.service';

@Injectable({ providedIn: 'root' })
export class FileUploaderModalService {
  private definedInstances$ = [] as UploaderParams[];
  public allUploadsFinished$ = new Subject<{ url: string; data: any }>();
  public closeUploader$ = new Subject<{ _id: string; uploadedFiles: any[] }>();

  public get currentInstances() {
    return this.definedInstances$.slice();
  }

  constructor(private readonly uuidService: UuidService) {}

  add(params: Partial<UploaderParams>) {
    const { currentInstances } = this;
    const existingInstanceIndex = currentInstances.findIndex((instance) => instance.url === params.url);
    const defaultId = this.uuidService.getUuid();
    const mergedParams = merge(
      {
        _id: defaultId,
        maxFileSize: 200,
        timeout: 3600000,
        acceptedFiles: 'image/jpg, image/bmp, image/jpeg, image/png, image/tiff, video/mp4, .mov',
        state: { minimized: false, visible: true, uploaded: 0, total: 0, failed: 0 },
      } as UploaderParams,
      params,
    );
    if (existingInstanceIndex !== -1) {
      const mergedExistingParams = merge({}, currentInstances[existingInstanceIndex], params);
      currentInstances.splice(existingInstanceIndex, 1, mergedExistingParams);
    } else {
      currentInstances.push(mergedParams);
    }
    const currentValue = currentInstances.map((entry, index) => {
      const showCriteria =
        existingInstanceIndex === -1
          ? index === currentInstances.length - 1
          : index === existingInstanceIndex;
      entry.state.visible = showCriteria;
      entry.state.minimized = !showCriteria;
      return entry;
    });
    this.definedInstances$ = currentValue;
    return currentValue[existingInstanceIndex] || currentValue[currentValue.length - 1];
  }

  update(target: UploaderParams, data: Partial<UploaderParams>) {
    const currentValues = this.currentInstances;
    const valueIndex = currentValues.findIndex((entry) => entry === target);
    if (valueIndex !== -1) {
      merge(currentValues[valueIndex], data);
      return currentValues[valueIndex];
    }
    return null;
  }

  get(targetId: UploaderParams['_id']): UploaderParams {
    return this.currentInstances.find((entry) => entry._id === targetId);
  }

  show(instance: UploaderParams) {
    this.currentInstances.forEach((entry) => {
      const showCriteria = entry === instance;
      entry.state.visible = showCriteria;
      entry.state.minimized = !showCriteria;
    });
  }

  minimizeAll() {
    this.currentInstances.forEach((entry) => {
      entry.state.visible = false;
      entry.state.minimized = true;
    });
  }

  remove(instance: UploaderParams) {
    const filteredValues = this.currentInstances.filter((entry) => entry !== instance);
    this.definedInstances$ = filteredValues;
  }

  notifyUploaderClosed(_id: string) {
    const { uploadedFiles } = this.get(_id);
    this.closeUploader$.next({ _id, uploadedFiles });
  }
}
