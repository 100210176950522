<ng-select
  class="dropdown-selector custom-multi-select {{ customClass }}"
  [class.ng-invalid]="invalid"
  [class.loading]="loading"
  [ngStyle]="{ 'min-width': minWidth, 'max-width': maxWidth }"
  [name]="name"
  [placeholder]="placeholder"
  [items]="items"
  [(ngModel)]="selected"
  [groupBy]="groupBy"
  [multiple]="multiple"
  [maxSelectedItems]="maxSelectedItems"
  [notFoundText]="notFoundText"
  [clearable]="clearable"
  [searchable]="searchable"
  [disabled]="disabled"
  [closeOnSelect]="closeOnSelect"
  [selectOnTab]="true"
  (change)="onChange()"
  (open)="onSelectOpen()"
  [loading]="loading"
>
  <!-- Selected Item -->
  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    <!-- Template: Custom -->
    <ng-container *ngIf="customLabelTemplate; else defaultLabelTemplate">
      <ng-container
        *ngTemplateOutlet="customLabelTemplate; context: { $implicit: item, clear: clear }"
      ></ng-container>
    </ng-container>
    <!-- Template: Default -->
    <ng-template #defaultLabelTemplate>
      <span class="ng-value-label">
        <i *ngIf="item.icon" class="cc-icon {{ item.icon }} sm-align-middle"></i>{{ item.label }}
      </span>
      <span class="ng-value-icon" (click)="clear(item)" aria-hidden="true">
        <i class="cc-icon cc-icon-close"></i>
      </span>
    </ng-template>
  </ng-template>

  <!-- Options -->
  <ng-template
    ng-option-tmp
    let-item="item"
    let-index="index"
    let-search="searchTerm"
    data-test="option-template"
  >
    <!-- Template: Custom -->
    <ng-container
      *ngTemplateOutlet="customOptionTemplate; context: { $implicit: item }"
      data-test="custom-option-template"
    ></ng-container>

    <!-- Template: Default -->
    <span class="sm-flex" *ngIf="!customOptionTemplate" data-test="default-option-template">
      <i *ngIf="item.icon" class="cc-icon {{ item.icon }} sm-align-middle"></i>
      {{ item.label }}
      <cc-badge *ngIf="item.badgeText" [text]="item.badgeText" class="sm-ml-1"></cc-badge>
    </span>
  </ng-template>

  <!-- Loading Spinner -->
  <ng-template ng-loadingspinner-tmp>
    <i class="cc-icon cc-icon-spinner-sm" data-test="default-spinner"></i>
  </ng-template>

  <!-- Loading (Dropdown) -->
  <ng-template ng-loadingtext-tmp let-searchTerm>
    <!-- Template: Custom -->
    <ng-container
      *ngTemplateOutlet="customLoadingTemplate; context: { $implicit: searchTerm }"
      data-test="custom-loading-template"
    ></ng-container>

    <!-- Template: Default -->
    <div
      class="ng-option-loading sm-flex sm-p-2"
      *ngIf="!customLoadingTemplate"
      data-test="default-loading-template"
    >
      <i class="cc-icon cc-icon-spinner-sm"></i>
      <span class="sm-ml-1 sm-align-top sm-text-secondary-text">{{
        searchTerm ? 'Searching...' : 'Loading...'
      }}</span>
    </div>
  </ng-template>
</ng-select>
