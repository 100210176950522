import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';

import { Company, ItemStates } from '@site-mate/dashpivot-shared-library';

import { GlobalApiService } from 'app/global-api/global-api.service';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { AddWorkspaceComponent } from 'app/org/add-workspace.component';
import { OrgService } from 'app/org/org.service';
import { ProjectWeb } from 'app/shared/model/project.model';
import { TeamWeb } from 'app/shared/model/team.model';
import { AppUtilService } from 'app/shared/service/app-util.service';
import { ConfirmService } from 'app/shared/service/confirm.service';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { HttpClientService } from 'app/shared/service/http-client.service';

import { CompanyWeb } from '../../shared/model/company.model';
import { ToastrService } from '../../shared/service/toastr.service';

@Injectable({ providedIn: 'root' })
export class FolderNavBarService {
  public readonly searchValue = new BehaviorSubject('');

  constructor(
    private readonly i18n: TmpI18NService,
    private readonly modal: NgbModal,
    private readonly appUtilService: AppUtilService,
    private readonly orgService: OrgService,
    private readonly errorHandler: ErrorHandler,
    private readonly confirmService: ConfirmService,
    private readonly toastrService: ToastrService,
    private readonly httpClientService: HttpClientService,
    private readonly globalApiService: GlobalApiService,
  ) {}

  getCompanyTree(companyId: string, state = ItemStates.Active) {
    const url = `companies/${companyId}/projects/teams?state=${state}`;

    return this.httpClientService.get<ProjectWeb[]>(url);
  }

  getUserCompanies() {
    return this.httpClientService.get<CompanyWeb[]>('companies/projects/teams/all');
  }
  // Currently feature flagged https://sitemate.atlassian.net/browse/INF-933
  getAccessibleWorkspaces() {
    return this.globalApiService.getAccessibleWorkspaces();
  }

  superUserCompanySearch(searchTerm: string) {
    return this.httpClientService.get<Company[]>(`companies/search?searchTerm=${searchTerm}`);
  }

  // Currently feature flagged https://sitemate.atlassian.net/browse/INF-857
  globalSuperUserWorkspaceSearch(searchTerm: string) {
    return this.globalApiService.searchWorkspace(searchTerm);
  }

  sortCompanyTree(projects: ProjectWeb[]) {
    projects.sort((a, b) => a.name.localeCompare(b.name));
    projects.forEach((project) => {
      project.teams.sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  async deleteTeam(team: TeamWeb): Promise<void> {
    await this.confirmService.confirmDelete('deleteTeam');
    this.orgService.removeTeam(team._id).subscribe(() => {
      this.toastrService.successByKey('teamDeleted');
    }, this.errorHandler.handle.bind(this.errorHandler));
  }

  addCompany(): Promise<{ company: CompanyWeb }> {
    const modalRef = this.modal.open(AddWorkspaceComponent, {
      windowClass: 'modal-500',
      modalDialogClass: 'modal-dialog-top',
    });
    const componentInstance = modalRef.componentInstance as AddWorkspaceComponent;
    componentInstance.title = this.i18n.getMessage('addCompany');
    return modalRef.result;
  }

  async deleteProject(project: ProjectWeb): Promise<void> {
    await this.confirmService.confirmDelete('deleteProject');
    await this.orgService.removeProject(project._id).toPromise();
    this.toastrService.successByKey('projectDeleted');
  }
}
