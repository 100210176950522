"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Products = void 0;
var Products;
(function (Products) {
    Products["Sitemate"] = "Sitemate";
    Products["Dashpivot"] = "Dashpivot";
    Products["Infrastructure"] = "Infrastructure";
    Products["SitemateStart"] = "Sitemate Start";
    Products["Sam"] = "Sam";
})(Products = exports.Products || (exports.Products = {}));
