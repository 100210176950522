import { Component, EventEmitter, Input, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';

import { FormDateUtil } from 'app/form/form-date-util.service';
import { TimeFormatter } from 'app/shared/model/utils/time-formatter.model';
import { AppUtilService } from 'app/shared/service/app-util.service';

@Component({
  selector: 'cc-time-picker',
  templateUrl: 'time-picker.component.html',
  styleUrls: ['time-picker.component.scss'],
  providers: [
    // eslint-disable-next-line no-use-before-define
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TimePickerComponent), multi: true },
  ],
})
export class TimePickerComponent implements OnInit, ControlValueAccessor {
  readonly clearOption = 'Clear';
  invalid: boolean;
  time: string;
  times = Array.from({ length: 24 }, (_value, index) => {
    const paddedHour = this.appUtilService.padDate(String(index));
    return [`${paddedHour}:00`, `${paddedHour}:30`];
  }).reduce((carry, entries) => carry.concat(entries), []);

  @Input() isInTable: boolean;
  @Input() name: string;
  @Input() model: NgbTimeStruct;
  @Input() container: 'body' | '' = 'body';
  @Input() placeholder: string;
  @Output() changeValue = new EventEmitter<NgbTimeStruct>();
  @ViewChild(NgSelectComponent) selector: NgSelectComponent;

  constructor(private readonly appUtilService: AppUtilService) {}

  get isSearchTermInsertable() {
    return !!this.selector && !!TimeFormatter.format(this.selector.searchTerm);
  }

  ngOnInit(): void {
    if (this.model) {
      const hour = this.appUtilService.padDate(`${this.model.hour}`);
      const minute = this.appUtilService.padDate(`${this.model.minute}`);
      this.time = `${hour}:${minute}`;
    }
  }

  getTime(event) {
    const { value } = event.target.attributes.getNamedItem('data-value');
    this.time = value;
    this.emit(value);
  }

  writeValue(value) {
    this.emit(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {};

  onChange(timeInput: string | { label: string }) {
    if (this.isClearEvent(timeInput)) {
      this.time = null;
      this.emit(null);
      return;
    }

    const timeStringValue = typeof timeInput === 'string' ? timeInput : timeInput.label;

    this.emit(timeStringValue);
  }

  private isClearEvent(timeInput: string | { label: string }) {
    return timeInput === this.clearOption || timeInput === null;
  }

  emit(value: string) {
    this.invalid = false;
    const timeSpacer = ':';

    if (!value) {
      this.changeValue.emit(null);
      return;
    }

    if (!value.includes(timeSpacer)) {
      this.invalid = true;
      return;
    }

    this.emitValidTime(value, timeSpacer);
  }

  closeDropDown() {
    this.selector.close();
  }

  private emitValidTime(value: string, timeSpacer: string) {
    const [hour, minute, second] = [...value.split(timeSpacer), 0];
    const time = {
      hour: Number(hour),
      minute: Number(minute),
      second: Number(second),
    };

    if (FormDateUtil.isValidTimeObj(time)) {
      this.changeValue.emit(time);
    } else {
      this.invalid = true;
    }
  }
}
