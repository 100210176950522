<form #form="ngForm" novalidate (submit)="form.valid && onSubmit()">
  <div class="modal-header">
    <h4 class="h4 modal-title">
      <span i18n>Select target team</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body email-modal-body">
    <div class="form-group row">
      <label i18n for="team" class="default-label col-form-label col-sm-2">Team</label>
      <div [ngClass]="{ 'has-danger': form.submitted && teamRef.invalid }" class="col-sm-10">
        <select id="team" #teamRef="ngModel" required name="team" [(ngModel)]="teamId" class="form-control">
          <option *ngFor="let team of teams" [value]="team._id">{{ team.name }}</option>
        </select>
        <div class="sm-text-default-red" *ngIf="form.submitted && teamRef.invalid" i18n>Team is required</div>
      </div>
    </div>
    <div class="form-group text-center">
      <button type="submit" class="btn btn-success btn-block">{{ actionTitle }}</button>
    </div>
  </div>
</form>
