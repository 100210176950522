<div>
  <cc-date-picker
    ccScrollInputFocus=".form-modal"
    [(ngModel)]="cellData._date"
    (ngModelChange)="handleOnChange($event)"
    name="{{ 'column-' + cellData.id }}"
    container="body"
    class="table-date-field field-w-{{ headerColumn.width || tableColumnWidths.Legacy }}"
    icon="calendar-clock"
  >
  </cc-date-picker>
</div>
