"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LOGICAL_SUPPORTED_FORMULAS = exports.LOGICAL_SUPPORTED_FORMULAS_WITH_INFO = void 0;
exports.LOGICAL_SUPPORTED_FORMULAS_WITH_INFO = new Map([
    [
        'AND',
        {
            syntax: 'AND(Logicalvalue1, Logicalvalue2 ...Logicalvalue30)',
            description: 'Returns TRUE if all arguments are TRUE.',
        },
    ],
    [
        'FALSE',
        {
            syntax: 'FALSE()',
            description: 'Returns the logical value FALSE.',
        },
    ],
    [
        'IF',
        {
            syntax: 'IF(Test, Then value, Otherwisevalue)',
            description: 'Specifies a logical test to be performed.',
        },
    ],
    [
        'IFS',
        {
            syntax: 'IFS(Condition1, Value1[, Condition2, Value2[..., Condition_n, Value_n]])',
            description: 'Evaluates multiple logical tests and returns a value that corresponds to the first true condition.',
        },
    ],
    [
        'IFNA',
        {
            syntax: 'IFNA(Value, Alternate_value)',
            description: 'Returns the value if the cell does not contain the #N/A (value not available) error value, or the alternative value if it does.',
        },
    ],
    [
        'IFERROR',
        {
            syntax: 'IFERROR(Value, Alternate_value)',
            description: 'Returns the value if the cell does not contain an error value, or the alternative value if it does.',
        },
    ],
    [
        'NOT',
        {
            syntax: 'NOT(Logicalvalue)',
            description: 'Complements (inverts) a logical value.',
        },
    ],
    [
        'SWITCH',
        {
            syntax: 'SWITCH(Expression1, Value1[, Expression2, Value2[..., Expression_n, Value_n]])',
            description: 'Evaluates a list of arguments, consisting of an expression followed by a value.',
        },
    ],
    [
        'OR',
        {
            syntax: 'OR(Logicalvalue1, Logicalvalue2 ...Logicalvalue30)',
            description: 'Returns TRUE if at least one argument is TRUE.',
        },
    ],
    [
        'TRUE',
        {
            syntax: 'TRUE()',
            description: 'The logical value is set to TRUE.',
        },
    ],
    [
        'XOR',
        {
            syntax: 'XOR(Logicalvalue1, Logicalvalue2 ...Logicalvalue30)',
            description: 'Returns true if an odd number of arguments evaluates to TRUE.',
        },
    ],
]);
exports.LOGICAL_SUPPORTED_FORMULAS = [...exports.LOGICAL_SUPPORTED_FORMULAS_WITH_INFO.keys()];
