"use strict";
/* eslint-disable @typescript-eslint/no-unused-vars */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrefilledTextFieldValidator = void 0;
class PrefilledTextFieldValidator {
    hasAnyData(field) {
        return false; // always want to return false for prefilled text fields
    }
    hasCompleteData(field) {
        return false; // always want to return false for prefilled text fields
    }
    isValid(field) {
        return true;
    }
    invalidDescription(field) {
        return '';
    }
}
exports.PrefilledTextFieldValidator = PrefilledTextFieldValidator;
