"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableCellDatePlainCloner = void 0;
const table_cell_repository_1 = require("./table-cell-repository");
const date_plain_util_1 = require("../../../utils/date-plain-util");
class TableCellDatePlainCloner extends table_cell_repository_1.TableCellGenericCloner {
    merge(field, templateCol, templateCell, formCol, formCell) {
        const _super = Object.create(null, {
            merge: { get: () => super.merge }
        });
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const updatedCell = (yield _super.merge.call(this, field, templateCol, templateCell, formCol, formCell));
            if (!date_plain_util_1.DatePlainUtil.isValidDate((_b = (_a = updatedCell.item) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.date)) {
                updatedCell.item = null;
            }
            return updatedCell;
        });
    }
}
exports.TableCellDatePlainCloner = TableCellDatePlainCloner;
