/* eslint-disable max-lines */
import { PlatformLocation } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, finalize } from 'rxjs';

import { EventTypes, User } from '@site-mate/dashpivot-shared-library';

import { PhotoService } from 'app/photo/photo.service';
import { ModalBaseEscComponent } from 'app/shared/component/modal-base-esc.component';
import { WINDOW } from 'app/shared/factory/window.factory';
import { PhotoTimeLine } from 'app/shared/model/photo-timeline.model';
import { PhotoWeb } from 'app/shared/model/photo-web.model';
import { ConfirmService } from 'app/shared/service/confirm.service';
import { DataCompressionService } from 'app/shared/service/data-compression/data-compression.service';
import { DownloaderService } from 'app/shared/service/downloader.service';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { EventsService } from 'app/shared/service/events/events.service';
import { GeolocationService } from 'app/shared/service/geolocation/geolocation.service';
import { LocalStorageService } from 'app/shared/service/local-storage.service';
import { ToastrService } from 'app/shared/service/toastr.service';
import { UserService } from 'app/user/user.service';

@Component({
  selector: 'cc-photo-modal',
  templateUrl: 'photo-modal.component.html',
  styleUrls: ['photo-modal.component.scss'],
})
export class PhotoModalComponent extends ModalBaseEscComponent implements OnInit {
  canEdit = true;
  canDownload = true;
  photo: PhotoWeb;
  timeLines: PhotoTimeLine[];
  timeLineIndex: number;
  photoIndex: number;
  kmlFile: string;
  downloadingPDF: boolean;
  comments = [];
  magnify: boolean;
  imageWidth: number;
  imageHeight: number;
  addingComment: boolean;
  lastPhotoUrl: string;
  keyboardDisabled: boolean;
  @ViewChild('largeImage') largeImage: ElementRef<HTMLDivElement>;
  @ViewChild('smallImage') smallImage: ElementRef<HTMLImageElement>;
  @ViewChild('videoSource') videoSource: ElementRef<HTMLSourceElement>;
  @ViewChild('videoPlayer') videoPlayer: ElementRef<HTMLVideoElement>;

  fullScreen: boolean;
  magnifyWidth = 250;
  magnifyHeight = 250;
  isLoading: boolean;
  tagsToSelect = [];
  user: User;
  hideDeleteButton: boolean;
  editingDesc;
  editDescModel;
  teamId: string;
  staticMapUrl: string;
  useInteractiveMap = false;
  isGeoAPILoaded$: Observable<boolean> = this.geolocationService.isGeoAPILoaded$;
  GPSLocation: { lat: number; lng: number } | null = null;
  onDescriptionTagsChanged = () => {};

  constructor(
    activeModal: NgbActiveModal,
    private readonly confirmService: ConfirmService,
    private readonly errorHandler: ErrorHandler,
    private readonly photoService: PhotoService,
    private readonly userService: UserService,
    private readonly toastr: ToastrService,
    @Inject(WINDOW) private readonly window: Window,
    location: PlatformLocation,
    private readonly dataCompressionService: DataCompressionService,
    private readonly downloaderService: DownloaderService,
    private readonly localStorageService: LocalStorageService,
    private readonly eventsService: EventsService,
    private readonly route: ActivatedRoute,
    private readonly geolocationService: GeolocationService,
  ) {
    super(location, activeModal);
  }

  ngOnInit(): void {
    this.loadPhoto();

    this.eventsService.trackEvent(EventTypes.PhotoSingleViewOpened);
  }

  private loadPhoto() {
    this.photo = this.timeLines[this.timeLineIndex].photos[this.photoIndex];

    this.setDefaultId();
    this.loadGPSLocation();
    this.setLastPhotoURL();
    this.loadStaticMapUrl();
    this.loadVideoSource();
    this.loadPermissions();
  }

  private setDefaultId() {
    if (!this.photo._id) {
      this.photo._id = this.photo.id;
    }
  }

  private loadGPSLocation() {
    this.GPSLocation = this.geolocationService.validateGPSCoordinates(
      this.photo.latitude,
      this.photo.longitude,
    );
  }

  private setLastPhotoURL() {
    if (!this.photo.isVideo && this.lastPhotoUrl !== this.photo.url.compressedFile) {
      this.isLoading = true;
      this.lastPhotoUrl = this.photo.url.compressedFile;
    }
  }

  private loadStaticMapUrl() {
    if (this.photo.localImage) {
      return;
    }

    const defaultDimension = { height: 1208, width: 600 };

    this.photoService
      .getPhotoMapUrl(this.teamId, this.photo._id, defaultDimension)
      .subscribe((staticMapUrl) => {
        this.staticMapUrl = staticMapUrl;
        this.useInteractiveMap = false;
      });
  }

  private loadVideoSource() {
    if (this.photo.isVideo && this.videoSource) {
      this.videoSource.nativeElement.src = this.photo.url.originalFile;
      this.videoPlayer.nativeElement.load();
      this.eventsService.trackEvent(EventTypes.PhotoVideoViewed);
    }
  }

  private loadPermissions() {
    this.userService.currentUser.subscribe((user) => {
      this.user = user;
      this.canEdit = this.photo.createdBy.id === this.user.id;
      this.loadComments();
    });
  }

  private loadComments() {
    if (!this.canEdit || this.photo.localImage) {
      this.comments = this.photo.comments;
      return;
    }

    this.photoService.getPhotoComments(this.photo._id).subscribe({
      next: (comments) => {
        this.comments = comments;
      },
      error: (error) => this.errorHandler.handle(error),
    });
  }

  logGoogleMapsUsage() {
    this.eventsService.trackEvent(EventTypes.GoogleMapsRequestFromPhotoModal);
  }

  switchToInteractiveMap() {
    this.useInteractiveMap = true;

    this.eventsService.trackEvent(EventTypes.InteractiveMapOpened, { Context: 'Photo Modal' });
  }

  onImgLoad() {
    this.loadDimension();
    this.isLoading = false;
  }

  onImgLoadError(photo: PhotoWeb) {
    // fallback to original file if there is a problem with the compressed one
    photo.url.compressedFile = photo.url.originalFile;
  }

  disableKeyboard() {
    this.keyboardDisabled = true;
    this.disableListener();
  }

  enableKeyboard() {
    this.keyboardDisabled = false;
    this.enableListener();
  }

  onRemove() {
    this.disableKeyboard();
    this.confirmService
      .confirmDelete('deletePhoto')
      .then(() => {
        this.enableKeyboard();
        return this.photoService
          .remove(this.photo._id)
          .pipe(finalize(() => this.enableKeyboard()))
          .subscribe({
            next: () => {
              this.toastr.successByKey('photoRemoved');
              this.eventsService.trackEvent(EventTypes.PhotoSingleActionApplied, { Context: 'Deleted' });
              this.photoService.deletePhotoSubject.next(this.photo._id);
              const { photos } = this.timeLines[this.timeLineIndex];
              if (this.photoIndex < photos.length) {
                this.loadPhoto();
              } else if (this.timeLineIndex < this.timeLines.length - 1) {
                this.timeLineIndex += 1;
                this.photoIndex = 0;
                this.loadPhoto();
              } else if (!this.prev()) {
                this.dismissModal();
              }
            },
            error: (error) => this.errorHandler.handle(error),
          });
      })
      .catch(() => this.enableKeyboard());
  }

  onExportPDF() {
    if (!this.canDownload) {
      return;
    }

    const url = this.downloaderService.getDownloadSinglePhotosUrl({
      teamId: this.teamId,
      queryParams: this.getPhotosRequestParams([this.photo._id]),
    });

    this.window.open(`${url}`, '_blank');
  }

  onExportOriginal() {
    if (!this.canDownload) {
      return;
    }
    const keyStorage = this.downloaderService.getRandomDownloadId();
    const value = JSON.stringify({
      ids: this.dataCompressionService.compress(this.getPhotosRequestParams([this.photo._id]).ids),
      timestamp: Date.now(),
      type: this.downloaderService.downloaderType,
    });
    this.localStorageService.setItem(keyStorage, value);

    const url = this.downloaderService.getDownloadOriginalPhotosUrl({
      teamId: this.teamId,
      queryParams: { ...this.route.snapshot.queryParams, downloadId: keyStorage },
    });

    this.window.open(url.toString(), '_blank');
  }

  private getPhotosRequestParams(photoIds: string[]) {
    const params = { ...this.route.snapshot.queryParams };
    params.ids = JSON.stringify(photoIds);

    return params;
  }

  prev() {
    this.eventsService.trackEvent(EventTypes.PhotoSingleViewChanged, { Context: 'Left' });

    if (this.photoIndex > 0) {
      this.photoIndex -= 1;
      this.loadPhoto();
      return true;
    }
    if (this.timeLineIndex > 0) {
      this.timeLineIndex -= 1;
      this.photoIndex = this.timeLines[this.timeLineIndex].photos.length - 1;
      this.loadPhoto();
      return true;
    }
    return false;
  }

  next() {
    this.eventsService.trackEvent(EventTypes.PhotoSingleViewChanged, { Context: 'Right' });

    const { photos } = this.timeLines[this.timeLineIndex];
    if (this.photoIndex < photos.length - 1) {
      this.photoIndex += 1;
      this.loadPhoto();
      return true;
    }
    if (this.timeLineIndex < this.timeLines.length - 1) {
      this.timeLineIndex += 1;
      this.photoIndex = 0;
      this.loadPhoto();
      return true;
    }
    return false;
  }

  loadDimension() {
    if (this.smallImage && this.smallImage.nativeElement) {
      this.imageWidth = this.smallImage.nativeElement.naturalWidth;
      this.imageHeight = this.smallImage.nativeElement.naturalHeight;
    }
  }

  isFirst() {
    return this.timeLineIndex === 0 && this.photoIndex === 0;
  }

  isLast() {
    return (
      this.timeLineIndex === this.timeLines.length - 1 &&
      this.photoIndex === this.timeLines[this.timeLineIndex].photos.length - 1
    );
  }

  @HostListener('document:keyup', ['$event'])
  onKeyup(event): void {
    const [rightKey, leftKey, escKey] = [39, 37, 27];
    if (event.target.classList.contains('form-control')) {
      return;
    }
    if (event.keyCode === rightKey && !this.keyboardDisabled) {
      this.next();
    }
    if (event.keyCode === leftKey && !this.keyboardDisabled) {
      this.prev();
    }
    if (event.keyCode === escKey && this.fullScreen) {
      this.onRestore();
    }
  }

  onMagnify(event: { magnify: boolean }) {
    this.magnify = event.magnify;
    this.eventsService.trackEvent(EventTypes.PhotoSingleActionApplied, { Context: 'Magnify' });
  }

  updateMagnify(event: MouseEvent) {
    const mx = event.pageX - this.smallImage.nativeElement.getBoundingClientRect().left;
    const my = event.pageY - this.smallImage.nativeElement.getBoundingClientRect().top;
    if (
      my >= this.smallImage.nativeElement.height - 1 ||
      my <= 1 ||
      mx >= this.smallImage.nativeElement.width - 1 ||
      mx <= 1
    ) {
      this.largeImage.nativeElement.style.display = 'none';
    } else {
      this.largeImage.nativeElement.style.display = 'block';
    }

    const rx =
      Math.round((mx / this.smallImage.nativeElement.width) * this.imageWidth - this.magnifyWidth / 2) * -1;
    const ry =
      Math.round((my / this.smallImage.nativeElement.height) * this.imageHeight - this.magnifyHeight / 2) *
      -1;
    const bgp = `${rx}px ${ry}px`;
    const px = mx - this.magnifyWidth / 2;
    const py = my - this.magnifyWidth / 2 + this.smallImage.nativeElement.offsetTop;
    this.largeImage.nativeElement.style.left = `${px}px`;
    this.largeImage.nativeElement.style.top = `${py}px`;
    this.largeImage.nativeElement.style.backgroundPosition = bgp;
  }

  onFullScreen() {
    this.disableListener();
    this.fullScreen = true;

    this.eventsService.trackEvent(EventTypes.PhotoSingleViewChanged, { Context: 'Fullscreen' });
  }

  onRestore() {
    this.fullScreen = false;
    setTimeout(() => this.enableListener(), 100);
  }

  addComment(providedComment) {
    const comment = providedComment;
    if (comment.value) {
      this.addingComment = true;
      this.photoService.addComment(this.photo._id, comment.value).subscribe({
        next: (c) => {
          this.eventsService.trackEvent(EventTypes.PhotoCommentActionApplied, { Context: 'Added' });
          this.addingComment = false;
          this.comments.push({
            id: c.id,
            comment: comment.value,
            createdAt: new Date(),
            createdBy: { fullName: this.user.fullName, id: this.user.id },
          });
          comment.value = '';
        },
        error: (error) => {
          this.errorHandler.handle(error);
          this.addingComment = false;
        },
      });
    }
  }

  onTagSelect(event) {
    let added: boolean;
    const { groupName, groupId } = event;
    const tag = event.value;

    this.photo.tagGroups = this.photo.tagGroups || [];
    const groupToChange = this.photo.tagGroups.find((group) => group.id === groupId);

    if (groupToChange) {
      if (!groupToChange.tags.includes(tag)) {
        added = true;
        groupToChange.tags.push(tag);
      }
    } else {
      this.photo.tagGroups.push({ id: groupId, name: groupName, tags: [tag] });
      added = true;
    }
    if (added) {
      this.eventsService.trackEvent(EventTypes.PhotoTagApplied, {
        photoId: this.photo._id,
        tagGroups: this.photo.tagGroups,
        Context: 'Single Photo View',
      });

      this.photoService.updatePhotoTagGroups(this.photo._id, this.photo.tagGroups).subscribe({
        next: () => {
          this.onDescriptionTagsChanged?.();
        },
        error: (error) => this.errorHandler.handle(error),
      });
    }
  }

  removeTag(groupIndex: number, tagIndex: number) {
    this.disableKeyboard();
    this.confirmService
      .confirmDelete('deletePhotoTag')
      .then(() => {
        this.photo.tagGroups[groupIndex].tags.splice(tagIndex, 1);
        if (this.photo.tagGroups[groupIndex].tags.length === 0) {
          this.photo.tagGroups.splice(groupIndex, 1);
        }
        this.photoService
          .updatePhotoTagGroups(this.photo._id, this.photo.tagGroups)
          .pipe(finalize(() => this.enableKeyboard()))
          .subscribe({
            next: () => {
              this.toastr.successByKey('photoTagRemoved');
              this.onDescriptionTagsChanged?.();
            },
            error: (error) => this.errorHandler.handle(error),
          });
      })
      .catch(() => this.enableKeyboard());
  }

  startEditComment(providedComment) {
    const comment = providedComment;
    comment.editModel = comment.comment;
    comment.editing = true;
  }

  saveEditedComment(providedComment) {
    const comment = providedComment;
    this.photoService.updateComment(this.photo._id, comment.id, comment.editModel).subscribe({
      next: () => {
        comment.comment = comment.editModel;
        comment.editing = false;
        this.eventsService.trackEvent(EventTypes.PhotoCommentActionApplied, { Context: 'Edited' });
      },

      error: (error) => {
        comment.editing = false;
        this.errorHandler.handle(error);
      },
    });
  }

  canEditComment(comment) {
    return !comment.editing && comment.createdBy.id === this.user.id;
  }

  startEditDesc() {
    this.editDescModel = this.photo.description;
    this.editingDesc = true;
  }

  saveEditedDesc() {
    this.photoService.updateDescription(this.photo._id, this.editDescModel).subscribe({
      next: () => {
        this.photo.description = this.editDescModel;
        this.editingDesc = false;
        this.onDescriptionTagsChanged?.();
      },
      error: (error) => {
        this.editingDesc = false;
        this.errorHandler.handle(error);
      },
    });
  }

  deleteComment(comment) {
    this.disableKeyboard();
    this.confirmService
      .confirmDelete('deletePhotoComment')
      .then(() => {
        return this.photoService
          .deleteComment(this.photo._id, comment.id)
          .pipe(finalize(() => this.enableKeyboard()))
          .subscribe({
            next: () => {
              this.comments = this.comments.filter((c) => c.id !== comment.id);
              this.toastr.successByKey('photoCommentDeleted');
              this.eventsService.trackEvent(EventTypes.PhotoCommentActionApplied, { Context: 'Deleted' });
            },
            error: (error) => this.errorHandler.handle(error),
          });
      })
      .catch(() => {
        this.enableKeyboard();
      });
  }

  deleteDesc() {
    this.disableKeyboard();
    this.confirmService
      .confirmDelete('deletePhotoDesc')
      .then(() => {
        return this.photoService
          .deleteDescription(this.photo._id)
          .pipe(finalize(() => this.enableKeyboard()))
          .subscribe({
            next: () => {
              this.photo.description = undefined;
              this.toastr.successByKey('photoDescDeleted');
              this.onDescriptionTagsChanged?.();
            },
            error: (error) => this.errorHandler.handle(error),
          });
      })
      .catch(() => this.enableKeyboard());
  }

  get photoId() {
    return this.photo._id || this.photo.id;
  }
}
/* eslint-enable max-lines */
