<ng-container *ngIf="mode === 'minimal'">
  <button
    type="button"
    class="color-picker color-choice color-choice-item"
    [class.disabled]="disabled"
    [ngbPopover]="colorPickerPopTemplate"
    [disablePopover]="disabled"
    popoverClass="color-picker-popover"
    #popRef="ngbPopover"
    [ngClass]="style || defaultStyle"
    placement="bottom"
  >
    <i class="fa fa-caret-down color-picker-arrow"></i>
  </button>
</ng-container>

<ng-container *ngIf="mode === 'select'">
  <ng-select
    [items]="colorPickerOptions"
    class="sm-min-h-full sm-flex-1 sm-rounded-none sm-border-0"
    placeholder="Select"
    i18n-placeholder="Select"
    [ngModel]="selectedOption"
    (ngModelChange)="setStyle($event)"
    ngDefaultControl
  >
    <ng-template ng-label-tmp let-item="item">
      <div class="sm-flex">
        <div class="category-dropdown selected-dropdown {{ item.value }}"></div>
        <span>{{ item.label }}</span>
      </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <div class="sm-flex">
        <div class="category-dropdown {{ item.value }}"></div>
        <span>{{ item.label }}</span>
      </div>
    </ng-template>
  </ng-select>
</ng-container>

<ng-template #colorPickerPopTemplate>
  <div *ngFor="let style of colorPickerOptions">
    <button
      type="button"
      class="color-choice color-choice-item {{ style.value }} item-{{ style.value }}"
      (click)="setStyle(style)"
    ></button>
  </div>
</ng-template>
