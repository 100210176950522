<header>
  <div class="sm-flex sm-items-center sm-justify-between sm-pb-4">
    <span>
      <h5 class="h5 header-title d-inline" i18n>{{ title }}</h5>
      <small class="links-count text-muted" *ngIf="linksCount">({{ linksCount }})</small>
    </span>
    <div class="text-center" *ngIf="learnMoreLink">
      <a
        [href]="learnMoreLink"
        target="_blank"
        class="px-2 py-1 rounded btn btn-sm cc-btn-subtle-gray help-text"
      >
        <i class="mr-1 cc-icon cc-icon-question"></i><span i18n>Learn more</span>
      </a>
    </div>
  </div>
  <ng-content></ng-content>
  <span class="header-subtitle" i18n *ngIf="subtitle">{{ subtitle }}</span>
</header>
