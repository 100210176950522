"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotSupportedFormulas = void 0;
const lodash_1 = require("lodash");
const array_manipulation_formulas_1 = require("./formula-list/array-manipulation-formulas");
const date_and_time_formulas_1 = require("./formula-list/date-and-time-formulas");
const engineering_formulas_1 = require("./formula-list/engineering-formulas");
const financial_formulas_1 = require("./formula-list/financial-formulas");
const information_formulas_1 = require("./formula-list/information-formulas");
const lookup_and_ref_formulas_1 = require("./formula-list/lookup-and-ref-formulas");
const math_formulas_1 = require("./formula-list/math-formulas");
const matrix_formulas_1 = require("./formula-list/matrix-formulas");
const operator_formulas_1 = require("./formula-list/operator-formulas");
const statistical_formulas_1 = require("./formula-list/statistical-formulas");
const text_formulas_1 = require("./formula-list/text-formulas");
exports.NotSupportedFormulas = (0, lodash_1.flatten)([
    array_manipulation_formulas_1.ARRAY_NOT_SUPPORTED_FORMULAS,
    date_and_time_formulas_1.DATE_AND_TIME_NOT_SUPPORTED_FORMULAS,
    engineering_formulas_1.ENGINEERING_NOT_SUPPORTED_FORMULAS,
    financial_formulas_1.FINANCIAL_NOT_SUPPORTED_FORMULAS,
    information_formulas_1.INFORMATION_NOT_SUPPORTED_FORMULAS,
    lookup_and_ref_formulas_1.LOOKUP_AND_REF_NOT_SUPPORTED_FORMULAS,
    math_formulas_1.MATH_NOT_SUPPORTED_FORMULAS,
    matrix_formulas_1.MATRIX_NOT_SUPPORTED_FORMULAS,
    operator_formulas_1.OPERATOR_NOT_SUPPORTED_FORMULAS,
    statistical_formulas_1.STATISTICAL_NOT_SUPPORTED_FORMULAS,
    text_formulas_1.TEXT_NOT_SUPPORTED_FORMULAS,
]);
