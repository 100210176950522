import { QueryStates } from './query-states.enum';
import { EmptyQuery, ErrorQuery, FetchedQuery, LoadingQuery, Query } from './query.model';

export const isFetchedQuery = <T>(query: Query<T>): query is FetchedQuery<T> => {
  return query.state === QueryStates.Fetched;
};

export const isLoadingQuery = <T>(query: Query<T>): query is LoadingQuery => {
  return query.state === QueryStates.Loading;
};

export const isEmptyQuery = <T>(query: Query<T>): query is EmptyQuery => {
  return query.state === QueryStates.Empty;
};

export const isErrorQuery = <T>(query: Query<T>): query is ErrorQuery => {
  return query.state === QueryStates.Error;
};
