import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'environments/environment';

import { ErrorHandler } from '../error-handler.service';

@Injectable({ providedIn: 'root' })
export class GeolocationService {
  private isGeoAPILoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isGeoAPILoaded$: Observable<boolean> = this.isGeoAPILoaded.asObservable();

  constructor(
    private readonly standardHttpClient: HttpClient,
    private readonly errorHandler: ErrorHandler,
  ) {
    this.loadGoogleMapsScript();
  }

  private loadGoogleMapsScript() {
    const URL = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsKey}`;

    this.standardHttpClient.jsonp(URL, 'callback').subscribe({
      next: () => this.isGeoAPILoaded.next(true),
      error: (err) => {
        this.errorHandler.handle(err, { message: 'Failed to load Google Maps script' });
        this.isGeoAPILoaded.next(false);
      },
    });
  }

  validateGPSCoordinates(rawLatitude: string, rawLongitude: string): { lat: number; lng: number } | null {
    const lat = parseFloat(rawLatitude);
    const lng = parseFloat(rawLongitude);

    const hasValidLatitude = !Number.isNaN(lat) && lat !== 0;
    const hasValidLongitude = !Number.isNaN(lng) && lng !== 0;

    if (hasValidLatitude && hasValidLongitude) {
      return { lat, lng };
    }
    return null;
  }
}
