import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';

import { Photo, PhotoThumbnailSizes } from '@site-mate/dashpivot-shared-library';

import { FieldWeb } from 'app/shared/model/item.model';

@Component({
  selector: 'cc-form-photo-list',
  templateUrl: 'form-photo-list.component.html',
  styleUrls: ['form-photo-list.component.scss'],
})
export class FormPhotoListComponent implements OnInit {
  showAll: boolean;
  kmlFile;
  isLargeOrExtraLarge: boolean;
  thumbnailSize: PhotoThumbnailSizes;
  isPhotoDescriptionVisible: boolean;
  isPhotoTagsVisible: boolean;

  @Input() photos: Photo[] = [];
  @Input() readOnly: boolean;
  @Input() item: FieldWeb;
  @Input() maxDisplay: number;
  @Input() displayThumbnailSize = false;
  @Input() isLandscapeMode = false;

  @Output() readonly remove = new EventEmitter();
  @Output() readonly viewPhoto = new EventEmitter();

  readonly photoThumbnailSizes = PhotoThumbnailSizes;

  ngOnInit() {
    this.thumbnailSize = this.item?.photoThumbnailSize ?? PhotoThumbnailSizes.Small;

    this.isLargeOrExtraLarge =
      this.item?.photoThumbnailSize === PhotoThumbnailSizes.Large ||
      this.item?.photoThumbnailSize === PhotoThumbnailSizes.ExtraLarge;

    this.isPhotoDescriptionVisible = this.item?.isPhotoDescriptionVisible || false;
    this.isPhotoTagsVisible = this.item?.isPhotoTagsVisible || false;
  }

  getTooltipText(isVideo: boolean): string {
    const mediaType = isVideo ? 'video' : 'photo';
    return `This ${mediaType} has not finished uploading. Please wait for the mobile app to finish uploading the ${mediaType}.`;
  }

  onClick(data): void {
    this.viewPhoto.emit(data);
  }

  getPhotoDescription(photo: Photo): string {
    if (photo.description?.length) {
      return photo.description;
    }
    return null;
  }

  getPhotoTags(photo: Photo): string {
    if (!photo.tagGroups || photo.tagGroups.length === 0) {
      return null;
    }

    const formattedTags = photo.tagGroups.map((group) => {
      return group.tags.join(', ');
    });

    return formattedTags.join(', ');
  }
}
