import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { TmpI18NService } from '../../i18n/tmp-i18n.service';

@Component({
  selector: 'cc-modal-with-warnings',
  templateUrl: './modal-with-warnings.component.html',
  styleUrls: ['./modal-with-warnings.component.scss'],
})
export class ModalWithWarningsComponent {
  @Input() title: string;
  @Input() warnings = [];
  @Output() readonly acknowledge = new EventEmitter<void>();
  @Output() readonly closeModal = new EventEmitter<void>();

  constructor(
    public modal: NgbActiveModal,
    private readonly i18nService: TmpI18NService,
  ) {}

  onAcknowledge(): void {
    this.acknowledge.emit();
  }

  onCloseModal(): void {
    this.closeModal.emit();
  }
}
