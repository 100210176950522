"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationPeriods = void 0;
var NotificationPeriods;
(function (NotificationPeriods) {
    NotificationPeriods["ThirtyDaysBefore"] = "ThirtyDaysBefore";
    NotificationPeriods["SevenDaysBefore"] = "SevenDaysBefore";
    NotificationPeriods["OneDayBefore"] = "OneDayBefore";
    NotificationPeriods["Overdue"] = "Overdue";
    NotificationPeriods["OverdueSevenDays"] = "OverdueSevenDays";
    NotificationPeriods["OverdueThirtyDays"] = "OverdueThirtyDays";
})(NotificationPeriods = exports.NotificationPeriods || (exports.NotificationPeriods = {}));
