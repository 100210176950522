"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SitemateEvent = void 0;
const event_model_1 = require("./event.model");
const products_enum_1 = require("./products.enum");
class SitemateEvent extends event_model_1.Event {
    constructor(eventType, metadata) {
        super(eventType, Object.assign(Object.assign({}, metadata), { Product: products_enum_1.Products.Sitemate }));
    }
}
exports.SitemateEvent = SitemateEvent;
