export const appControlType = {
  preFilledText: 'preFilledText',
  singleLineInput: 'singleLineInput',
  multiLineInput: 'multiLineInput',
  yesNoButton: 'yesNoCheckbox',
  yesNoButtonType: {
    yesNo: 'yesNo',
    yesNoNA: 'yesNoNA',
    yesNoText: 'yesNoText',
    yesNoNAText: 'yesNoNAText',
  },
  date: 'date',
  table: 'table',
  prefilledTable: 'prefilledTable',
  signonTable: 'signonTable',
  text: 'text',
  dateTimeRange: 'dateTimeRange',
  dateRange: 'dateRange',
  singleDateTime: 'singleDateTime',
  singleDate: 'singleDate',
  expiryDate: 'expiryDate',
  columnType: {
    text: 'text',
    prefilledText: 'prefilledText',
    number: 'number',
    date: 'date',
    time: 'time',
    list: 'list',
    signature: 'signature',
    manualSignature: 'manualSignature',
    attachment: 'attachment',
    photo: 'photo',
    formula: 'formula',
    listSource: {
      manual: 'manual',
      list: 'list',
    },
  },
  photo: 'photo',
  sketch: 'sketch',
  category: 'category',
  categorySource: {
    manual: 'manual',
    list: 'list',
  },
  categoryType: {
    inline: 'inline',
    dropdown: 'dropdown',
  },
  pageBreak: 'pageBreak',
  person: 'person',
};

export const AppType = {
  list: 'list',
  workflow: 'workflow',
};
