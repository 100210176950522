import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';

import { QueryStates } from 'app/shared/model/query/query-states.enum';
import { Query } from 'app/shared/model/query/query.model';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { HttpClientService } from 'app/shared/service/http-client.service';

@Injectable({ providedIn: 'root' })
export class ListQueryCacheService {
  constructor(
    private httpClientService: HttpClientService,
    private errorHandler: ErrorHandler,
  ) {}

  getCachedData<T>(
    entityId: string,
    endpoint: string,
    cacheMap: Map<string, BehaviorSubject<Query<T>>>,
  ): Observable<Query<T>> {
    if (!entityId) {
      return of({ state: QueryStates.Empty } as Query<T>);
    }

    if (cacheMap.has(entityId)) {
      return cacheMap.get(entityId).asObservable();
    }

    const subject = new BehaviorSubject<Query<T>>({ state: QueryStates.Loading });
    cacheMap.set(entityId, subject);

    this.httpClientService
      .get<T>(endpoint)
      .pipe(take(1))
      .subscribe({
        next: (data: T) => {
          if (!data || (Array.isArray(data) && data.length === 0)) {
            subject.next({ state: QueryStates.Empty });
          } else {
            subject.next({ state: QueryStates.Fetched, data });
          }
        },
        error: (error) => {
          this.errorHandler.handle(error);
          subject.next({ state: QueryStates.Error, error });
        },
      });

    return subject.asObservable();
  }
}
