"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableFieldCloner = void 0;
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
const table_cell_repository_factory_1 = require("./cloning-table-cells/table-cell-repository.factory");
const generic_field_1 = require("./generic-field");
class TableFieldCloner extends generic_field_1.GenericFieldCloner {
    constructor() {
        super(...arguments);
        this.tableCellCloner = new table_cell_repository_factory_1.TableCellClonerFactory(this.getListItems);
    }
    mergeField(templateField, formField) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!formField.rows || formField.rows.length === 0) {
                return templateField;
            }
            // create blank rows & cols
            templateField.rows = formField.rows.map(() => ({
                columns: new Array(templateField.columns.length),
            }));
            // loop each column & copy across each row's data
            for (const [templateColIndex, templateCol] of templateField.columns.entries()) {
                const formColIndex = formField.columns.findIndex((formCol) => (formCol.id || String(formCol._id)) === (templateCol.id || String(templateCol._id)));
                if (formColIndex === -1) {
                    this.insertBlankColumn(templateField, templateColIndex, templateCol, formField);
                }
                else {
                    yield this.mergeColumn(templateField, templateColIndex, templateCol, formField, formColIndex);
                }
            }
            return templateField;
        });
    }
    insertBlankColumn(templateField, templateColIndex, templateCol, formField) {
        const cloner = this.tableCellCloner.getCloner(templateCol.kind, formField);
        // this column doesn't exist in the form, insert a blank column in each row
        formField.rows.forEach((_fRow, rowIndex) => {
            templateField.rows[rowIndex].columns[templateColIndex] = cloner.getBlankCell(templateCol);
        });
    }
    mergeColumn(templateField, templateColIndex, templateCol, formField, formColIndex) {
        return __awaiter(this, void 0, void 0, function* () {
            for (const [formRowIndex, formRow] of formField.rows.entries()) {
                const formCol = formField.columns[formColIndex];
                const formCell = formRow.columns[formColIndex];
                const templateCell = templateField.rows[formRowIndex].columns[templateColIndex];
                const tableCellMerger = this.tableCellCloner.getCloner(templateCol.kind, formField);
                templateField.rows[formRowIndex].columns[templateColIndex] = yield tableCellMerger.merge(templateField, templateCol, templateCell, formCol, formCell);
            }
        });
    }
}
exports.TableFieldCloner = TableFieldCloner;
