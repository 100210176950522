"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableCellTimeCloner = void 0;
const table_cell_repository_1 = require("./table-cell-repository");
const __1 = require("../../..");
const table_cell_kinds_enum_1 = require("../../../models/tables/table-cell-kinds.enum");
class TableCellTimeCloner extends table_cell_repository_1.TableCellGenericCloner {
    merge(field, templateCol, templateCell, formCol, formCell) {
        const _super = Object.create(null, {
            merge: { get: () => super.merge }
        });
        return __awaiter(this, void 0, void 0, function* () {
            const updatedCell = yield _super.merge.call(this, field, templateCol, templateCell, formCol, formCell);
            const formCellKind = field.kind === __1.FieldKinds.Table ? formCol.kind : formCell.kind;
            // times are complex as they're relative to a point in time, very unlikely any non time cells have valid times in them
            updatedCell.value = formCellKind === table_cell_kinds_enum_1.TableCellKinds.Time ? updatedCell.value : undefined;
            return updatedCell;
        });
    }
}
exports.TableCellTimeCloner = TableCellTimeCloner;
