<div class="with-dark-popover">
  <div class="form-activity-header">
    <span i18n>Activity</span>
    <a [href]="learnMoreLink" target="_blank" class="btn btn-sm cc-btn-subtle-dark-gray ml-2">
      <i class="fa fa-question-circle-o"></i> <span i18n>Learn more</span>
    </a>
  </div>
  <hr class="hr" />
  <div class="form-activity-body">
    <div class="activity" *ngFor="let entry of comments">
      <div class="activity-name-time">
        <div class="activity-name">
          <ng-container *ngIf="entry.createdBy; else systemUser">
            <cc-user-avatar [user]="entry.createdBy"></cc-user-avatar>
            <strong class="activity-name-full">
              {{ entry.createdBy?.fullName }}
            </strong>
          </ng-container>
        </div>
        <div class="activity-time">
          <small placement="left" [ngbTooltip]="entry.updatedAt | amDateFormat: 'lll'">
            {{ entry.updatedAt | amTimeAgo }}
          </small>
        </div>
      </div>

      <div class="activity-content">
        <div class="activity-actions">
          <small class="sm-break-anywhere">
            <em
              *ngIf="entry.kind === 'comment'"
              class="comment-content"
              [innerHTML]="entry.comment | parseMarkdownLinks | safeHtml: { forceTargetBlank: true }"
            ></em>
            <a
              *ngIf="entry.kind === 'version'"
              class="activity-action-link"
              (click)="handleVersionClick($event, entry)"
            >
              <em
                class="comment-content"
                [innerHTML]="entry.comment | parseMarkdownLinks | safeHtml: { forceTargetBlank: true }"
              ></em>
            </a>
          </small>
        </div>
      </div>
    </div>
    <div *ngIf="message" class="message alert alert-info">
      {{ message }}
    </div>
  </div>
</div>

<ng-template #systemUser>
  <div class="badge"><img src="./assets/icons/multi-colored-icons/dashpivot.svg" alt="System User" /></div>
  <strong class="activity-name-full">System Update</strong>
</ng-template>
