<ng-container *ngIf="GPSLocation; else noLocation">
  <button
    *ngIf="isGeoAPILoaded$ | async"
    gw-button
    color="grey-2"
    size="small"
    type="button"
    text="View Map"
    (click)="open($event)"
    data-test="google-maps-btn"
  ></button>
</ng-container>

<ng-template #noLocation>
  <span data-test="no-location"> No location data </span>
</ng-template>
