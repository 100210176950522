import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'cc-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule],
})
export class TextInputComponent implements AfterViewInit {
  @Input() value: string;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() disabled = false;
  @Input() hasError = false;
  @Input() infoLink: string;
  @Input() placeholder = '';
  @Input() selected = false;
  @Input() autofocus = false;
  @Input() name: string;
  @Input() id: string;
  @Input() size: 'default' | 'small' = 'default';
  @Input() width: string = 'full';
  @Input() readonly = false;
  @Output() onFocus = new EventEmitter<void>();
  @Output() onBlur = new EventEmitter<void>();
  @Output() onValueChanged = new EventEmitter<string>();
  @Output() onKeyDown = new EventEmitter<KeyboardEvent>();
  @ViewChild('textInput') textInput: ElementRef;

  public focus() {
    this.textInput.nativeElement.focus();
  }

  onInputFocus() {
    this.onFocus.emit();
  }

  onInputValueChanged() {
    const { value } = this.textInput.nativeElement;
    this.onValueChanged.emit(value);
    this.valueChange.emit(this.value);
  }

  onInputKeyDown(event: KeyboardEvent) {
    this.onKeyDown.emit(event);
  }

  onInputBlur() {
    this.onBlur.emit();
  }

  ngAfterViewInit() {
    if (this.autofocus) {
      this.focus();
    }
  }

  @HostBinding('class') get hostClass() {
    return `size-${this.size}`;
  }

  @HostBinding('style.width') get hostWidth() {
    return this.width === 'full' ? '100%' : this.width;
  }
}
