<div>
  <div [class.sm-hidden]="!params.state.visible">
    <div class="modal-backdrop fade show"></div>
    <div role="dialog" tabindex="-1" class="modal fade show d-block">
      <div role="document" class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="import-modal">
            <div class="sm-flex sm-flex-col sm-gap-2">
              <div class="import-modal-header">
                <h1 i18n class="sm-text-xl sm-font-medium">
                  {{ params?.labels?.title || 'Upload files from your computer' }}
                </h1>
                <button
                  *ngIf="areAllUploadsFinished"
                  type="button"
                  class="sm-ml-auto hover:sm-cursor-pointer"
                  aria-label="Close"
                  (click)="closeOrMinimize()"
                >
                  <i class="cc-icon cc-icon-close sm-align-middle" aria-hidden="true"></i>
                </button>
                <button
                  *ngIf="!areAllUploadsFinished"
                  type="button"
                  class="sm-ml-auto hover:sm-cursor-pointer"
                  aria-label="Minimize"
                  (click)="closeOrMinimize()"
                >
                  <i class="cc-icon cc-icon-minus sm-align-middle" aria-hidden="true"></i>
                </button>
              </div>
              <p
                *ngIf="params?.labels?.subtitle"
                data-test="subtitle"
                class="sm-flex sm-items-center sm-gap-1"
              >
                <img
                  *ngIf="params?.labels?.subtitleImage"
                  [src]="params?.labels?.subtitleImage"
                  class="sm-inline-block"
                />{{ params.labels.subtitle }}
              </p>
            </div>
            <div class="import-modal-body">
              <div class="sm-flex sm-flex-col sm-gap-2">
                <div class="sm-flex sm-items-center">
                  <span class="sm-text-secondary-text" i18n>Select files from your computer</span>
                  <span #chooseFiles class="sm-ml-auto">
                    <button
                      gw-button
                      type="button"
                      text="Choose Files"
                      color="green"
                      size="medium"
                      data-test="choose-file-button"
                    ></button>
                  </span>
                </div>
                <div
                  #droppableArea
                  class="dropzone dropzone-area sm-flex sm-w-full sm-flex-wrap"
                  [ngClass]="['dropzone-' + uniqueIdentifier]"
                  [class.sm-justify-center]="params.state.total === 0"
                >
                  <div class="dz-message placeholder sm-flex sm-flex-col sm-items-center sm-justify-center">
                    <i class="mb-3 cc-icon cc-icon-plus !sm-text-5xl sm-text-grey-5" aria-hidden="true"></i>
                    <div i18n class="sm-text-grey-5">Drag'n drop files here to upload</div>
                  </div>
                </div>
              </div>

              <div class="import-modal-details sm-text-xs sm-font-normal">
                <span>Accepted file types: {{ validTypes }}</span>
                <span>Individual file size limit: {{ params.maxFileSize }} MB</span>
              </div>
            </div>
            <footer class="import-modal-footer">
              <button
                gw-button
                type="button"
                [text]="params?.labels?.finishButton || 'Done'"
                color="blue"
                size="medium"
                [disabled]="!areAllUploadsFinished || params.state.uploaded === 0"
                (click)="closeOrMinimize()"
              ></button>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="upload-sprite sm-cursor-pointer sm-p-2" *ngIf="params.state.minimized" (click)="showModal()">
    <div class="">
      <div i18n *ngIf="!areAllUploadsFinished" class="message-box bg-warning sm-rounded">
        <i class="cc-icon cc-icon-spinner-fw"></i
        ><span>Uploading {{ params.state.uploaded }}/{{ params.state.total }} </span>
      </div>

      <div i18n class="rounded message-box bg-success" *ngIf="areAllUploadsFinished">
        <i class="fa fa-check fa-fw"></i><span>Upload is finished.</span>
      </div>
    </div>
  </div>
</div>
