export class AcceptableFilesEnforcer {
  static enforce(fileList: FileList, acceptableTypes: string | undefined) {
    const files = Array.from(fileList);
    const digestedTypes = this.digestTypes(acceptableTypes);
    return files.reduce(
      (carry, file) => {
        const acceptedFormat = digestedTypes.some((digester) => digester(file));
        if (acceptedFormat) {
          carry.accepted.push(file);
        } else {
          carry.rejected.push(file);
        }
        return carry;
      },
      { accepted: [] as File[], rejected: [] as File[] },
    );
  }

  private static digestTypes(acceptableTypes: string | undefined) {
    return acceptableTypes
      ? acceptableTypes.split(',').map((type) => {
          const processedType = type.trim().toLowerCase();
          return processedType.endsWith('*')
            ? (file: File) => file.type.toLowerCase().startsWith(processedType.slice(0, -1))
            : (file: File) => file.type.toLowerCase() === processedType;
        })
      : // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [(file: File) => true];
  }
}
