import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Placement } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cc-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CounterComponent implements OnInit, OnChanges {
  @Input() total = 0;
  @Input() count = 0;

  @Input() title = 'Total Fields';

  @Input() isPopover = false;
  @Input() popoverPlacement: Placement = 'right';
  @Input() stylingChangesOnHover = false;

  counterClass = 'default';
  showPercentageWarnings = false;
  displayWarningIcon = false;

  ngOnInit() {
    this.setIconsVisibility();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.total || changes.count) {
      const { percentage } = this;
      this.updateStyling(percentage);
      this.setIconsVisibility();
    }
  }

  setIconsVisibility() {
    const reachedPlanTypeLimit = this.percentage >= 100;
    const displayWarningOnHover = this.showPercentageWarnings && reachedPlanTypeLimit;
    const displayWarningByDefault = !this.stylingChangesOnHover && reachedPlanTypeLimit;

    this.displayWarningIcon = displayWarningOnHover || displayWarningByDefault;
  }

  updateStyling(percentage: number) {
    if (percentage < 90) {
      this.counterClass = 'default';
    }

    if (percentage >= 90 && percentage < 100) {
      this.counterClass = 'warning';
    }

    if (percentage >= 100) {
      this.counterClass = 'alert';
    }
  }

  triggerPercentageWarnings(showPercentageWarnings: boolean): void {
    this.showPercentageWarnings = showPercentageWarnings;
    this.setIconsVisibility();
  }

  get percentage() {
    return this.total ? (this.count / this.total) * 100 : 0;
  }

  get counter() {
    return `${this.count ?? 0}/${this.total}`;
  }
}
