import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DragulaModule } from 'ng2-dragula';
import { ColorPickerModule } from 'ngx-color-picker';
import { MomentModule } from 'ngx-moment';
import { QuillModule } from 'ngx-quill';

import { ButtonComponent } from '@site-mate/global-web-ui';

import { PermissionDeniedComponent } from 'app/shared/component/403.component';
import { PageNotFoundComponent } from 'app/shared/component/404.component';
import { DatePickerComponent } from 'app/shared/component/date-picker/date-picker.component';
import { DocumentComponent } from 'app/shared/component/document/document.component';
import { FileUploaderModalComponent } from 'app/shared/component/file-uploader-modal/file-uploader-modal.component';
import { RegisterCellComponent } from 'app/shared/component/register/register-cell/register-cell.component';
import { RegisterHeaderComponent } from 'app/shared/component/register/register-header/register-header.component';
import { RegisterRowComponent } from 'app/shared/component/register/register-row/register-row.component';
import { RegisterComponent } from 'app/shared/component/register/register.component';
import { RichEditorComponent } from 'app/shared/component/rich-editor/rich-editor.component';
import { CCSignaturePadComponent } from 'app/shared/component/signature-pad/signature-pad.component';
import { TimePickerComponent } from 'app/shared/component/time-picker/time-picker.component';
import { UserAvatarComponent } from 'app/shared/component/user-avatar/user-avatar.component';
import { BusyButtonDirective } from 'app/shared/directive/busy-button.directive';
import { ContenteditableModelDirective } from 'app/shared/directive/content-editable-model.directive';
import { FallbackSrcDirective } from 'app/shared/directive/fallback-src.directive';
import { FocusDirective } from 'app/shared/directive/focus.directive';
import { HideIntercomDirective } from 'app/shared/directive/hide-intercom.directive';
import { NgSelectFirstFocusedDirective } from 'app/shared/directive/ng-select-first-focused.directive';
import { PositiveNumberInputDirective } from 'app/shared/directive/positive-number-input.directive';
import { ScrollInputFocusDirective } from 'app/shared/directive/scroll-input-focus.directive';
import { ScrollTabDirective } from 'app/shared/directive/scroll-tab.directive';
import { StickyPopoverDirective } from 'app/shared/directive/sticky-popover.directive';
import { UniqueEmailDirective } from 'app/shared/directive/unique-email.directive';
import { ParseMarkdownLinksPipe } from 'app/shared/pipe/parse-markdown-links.pipe';
import { SafeHtmlPipe } from 'app/shared/pipe/safe-html.pipe';
import { SafeUrlPipe } from 'app/shared/pipe/safe-url.pipe';
import { TruncStrPipe } from 'app/shared/pipe/trunc-str.pipe';
import { UIComponentsModule } from 'app/ui-components/ui-components.module';
import { UserRedirectionModalComponent } from 'app/user/user-redirection-modal/user-redirection-modal.component';

import { ActivityBarComponent } from './component/activity-bar/activity-bar.component';
import { DigitalFontSignatureComponent } from './component/digital-font-signature/digital-font-signature.component';
import { EditModalLogoComponent } from './component/edit-modal/edit-modal-logo/logo.component';
import { EditModalPageComponent } from './component/edit-modal/edit-modal-page/edit-modal-page.component';
import { EditModalComponent } from './component/edit-modal/edit-modal.component';
import { FlowsiteBannerPopoverComponent } from './component/flowsite-banner/flowsite-banner-popover/flowsite-banner-popover.component';
import { FlowsiteBannerComponent } from './component/flowsite-banner/flowsite-banner.component';
import { LimitsCounterComponent } from './component/limits-counter/limits-counter.component';
import { PanelWithLinksHeaderComponent } from './component/panel-with-links/panel-with-links-header/panel-with-links-header.component';
import { PanelWithLinksTabsComponent } from './component/panel-with-links/panel-with-links-tabs/panel-with-links-tabs.component';
import { PanelWithLinksComponent } from './component/panel-with-links/panel-with-links.component';
import { BadgeLogicDirective } from './directive/badge-logic.directive';
import { BannerLogicDirective } from './directive/banner-logic.directive';
import { DefaultTableContentPasteDirective } from './directive/default-table-content-paste.directive';
import { DynamicSizeDirective } from './directive/dynamic-size.directive';
import { HorizontalScrollTrackerDirective } from './directive/horizontal-scroll-tracker.directive';
import { HolidayBannerComponent } from './holiday-banner/holiday-banner.component';
import { TextEmphasisPipe } from './pipe/text-emphasis.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    MomentModule,
    UIComponentsModule,
    ColorPickerModule,
    RouterModule.forChild([]),
    DragulaModule.forRoot(),
    QuillModule.forRoot(),
    ButtonComponent,
  ],
  declarations: [
    ActivityBarComponent,
    BadgeLogicDirective,
    BannerLogicDirective,
    PageNotFoundComponent,
    PermissionDeniedComponent,
    FocusDirective,
    ScrollTabDirective,
    HorizontalScrollTrackerDirective,
    SafeUrlPipe,
    TextEmphasisPipe,
    FallbackSrcDirective,
    FileUploaderModalComponent,
    TruncStrPipe,
    ContenteditableModelDirective,
    DefaultTableContentPasteDirective,
    BusyButtonDirective,
    ScrollInputFocusDirective,
    UniqueEmailDirective,
    StickyPopoverDirective,
    SafeHtmlPipe,
    ParseMarkdownLinksPipe,
    RichEditorComponent,
    HideIntercomDirective,
    NgSelectFirstFocusedDirective,
    CCSignaturePadComponent,
    UserAvatarComponent,
    DocumentComponent,
    DatePickerComponent,
    TimePickerComponent,
    RegisterComponent,
    RegisterRowComponent,
    RegisterHeaderComponent,
    RegisterCellComponent,
    EditModalComponent,
    EditModalPageComponent,
    EditModalLogoComponent,
    PanelWithLinksComponent,
    PanelWithLinksHeaderComponent,
    PanelWithLinksTabsComponent,
    HolidayBannerComponent,
    UserRedirectionModalComponent,
    DynamicSizeDirective,
    PositiveNumberInputDirective,
    LimitsCounterComponent,
    DigitalFontSignatureComponent,
    FlowsiteBannerComponent,
    FlowsiteBannerPopoverComponent,
  ],
  exports: [
    ActivityBarComponent,
    BadgeLogicDirective,
    BannerLogicDirective,
    PageNotFoundComponent,
    PermissionDeniedComponent,
    SafeUrlPipe,
    TextEmphasisPipe,
    FallbackSrcDirective,
    FocusDirective,
    ScrollTabDirective,
    HorizontalScrollTrackerDirective,
    FileUploaderModalComponent,
    TruncStrPipe,
    ContenteditableModelDirective,
    DefaultTableContentPasteDirective,
    BusyButtonDirective,
    ScrollInputFocusDirective,
    UniqueEmailDirective,
    UserAvatarComponent,
    SafeHtmlPipe,
    StickyPopoverDirective,
    RichEditorComponent,
    HideIntercomDirective,
    NgSelectFirstFocusedDirective,
    CCSignaturePadComponent,
    DocumentComponent,
    DatePickerComponent,
    TimePickerComponent,
    RegisterComponent,
    RegisterHeaderComponent,
    RegisterRowComponent,
    RegisterCellComponent,
    EditModalComponent,
    EditModalPageComponent,
    EditModalLogoComponent,
    PanelWithLinksComponent,
    PanelWithLinksHeaderComponent,
    PanelWithLinksTabsComponent,
    HolidayBannerComponent,
    UserRedirectionModalComponent,
    DynamicSizeDirective,
    PositiveNumberInputDirective,
    LimitsCounterComponent,
    DigitalFontSignatureComponent,
    FlowsiteBannerComponent,
  ],
})
export class SharedModule {}
