"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ARRAY_FORMULAS = exports.ARRAY_NOT_SUPPORTED_FORMULAS = exports.ARRAY_SUPPORTED_FORMULAS = exports.ARRAY_SUPPORTED_FORMULAS_WITH_INFO = void 0;
exports.ARRAY_SUPPORTED_FORMULAS_WITH_INFO = new Map([
    [
        'FILTER',
        {
            syntax: 'FILTER(SourceArray, BoolArray1[, BoolArray2[, ...]])',
            description: 'Filters an array, based on multiple conditions (boolean arrays).',
        },
    ],
]);
exports.ARRAY_SUPPORTED_FORMULAS = [...exports.ARRAY_SUPPORTED_FORMULAS_WITH_INFO.keys()];
exports.ARRAY_NOT_SUPPORTED_FORMULAS = ['ARRAYFORMULA', 'ARRAY_CONSTRAIN'];
exports.ARRAY_FORMULAS = exports.ARRAY_SUPPORTED_FORMULAS.concat(exports.ARRAY_NOT_SUPPORTED_FORMULAS);
