import { Injectable } from '@angular/core';
import LZString from 'lz-string';

@Injectable()
export class DataCompressionService {
  compress(stringToCompress: string): string {
    return LZString.compress(stringToCompress);
  }

  decompress(stringToDecompress: string): string {
    return LZString.decompress(stringToDecompress);
  }
}
