import { Component, Input } from '@angular/core';

@Component({
  selector: 'cc-template-table-location-cell',
  templateUrl: './template-table-location-cell.component.html',
  styleUrls: ['./template-table-location-cell.component.scss'],
})
export class TemplateTableLocationCellComponent {
  readonly locationTableCellHelpUrl =
    'https://app.intercom.com/a/apps/mif72555/articles/articles/9387900/show';
  @Input() showTooltip = true;
}
