import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

import {
  DashpivotEvent,
  EventNotifierService,
  EventTypes,
  FieldKinds,
  TableCellData,
  TableCellValidator,
  TableCellValidatorFactory,
  TableColumn,
} from '@site-mate/dashpivot-shared-library';

import { FormTableCellComponent } from 'app/form-fields/table/edit-cells/table-cell.component';
import { SegmentService } from 'app/segment/segment.service';

@Component({
  templateUrl: './text.component.html',
  styleUrls: ['../table-cell.component.scss', '../../edit/form-table.component.scss'],
  selector: 'cc-table-cell-item-text',
})
export class FormTableTextCellComponent implements FormTableCellComponent, OnInit {
  @Input() tableKind: FieldKinds;
  @Input() columnDescription: string;
  @Input() cellData: TableCellData;
  @Input() headerColumn: TableColumn;
  @Input() cellName: string;
  @Output() onChange = new EventEmitter();

  validator: TableCellValidator;

  constructor(private readonly segmentService: SegmentService) {}

  ngOnInit() {
    this.validator = TableCellValidatorFactory.getValidator(this.cellData, this.headerColumn);
  }

  public onBlur() {
    const context = this.tableKind === FieldKinds.PrefilledTable ? 'Prefilled' : 'Default';
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.FormTableCellDataEntered, { Context: context }),
      this.segmentService,
    );
  }

  handleOnChange() {
    this.onChange.emit();
  }
}
