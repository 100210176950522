"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DomainUtils = void 0;
const normalizeSubdomain = (subdomain) => {
    let normalizedSubDomain = subdomain.replace(/[^a-zA-Z0-9]+/g, '-');
    normalizedSubDomain = normalizedSubDomain.replace(/-+/g, '-');
    while (normalizedSubDomain.startsWith('-')) {
        normalizedSubDomain = normalizedSubDomain.substring(1);
    }
    while (normalizedSubDomain.endsWith('-')) {
        normalizedSubDomain = normalizedSubDomain.substring(0, normalizedSubDomain.length - 1);
    }
    return normalizedSubDomain.toLowerCase();
};
exports.DomainUtils = {
    normalizeSubdomain,
};
