import { Directive, Input, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { BannerComponent } from 'app/ui-components/banner/banner.component';

import { BannerKeys } from '../model/banner-keys.enum';
import { TeamWeb } from '../model/team.model';
import { BannerService } from '../service/banner.service';
import { TeamService } from '../service/team.service';

@Directive({
  selector: '[ccBannerLogic]',
})
export class BannerLogicDirective implements OnInit {
  private unSubscribe = new Subject<void>();
  isVisible: boolean;
  isPrioritized: boolean;

  @Input() bannerKey: BannerKeys;
  @Input() priority?: number;
  @Input() parentComponent?: string;

  constructor(
    private readonly bannerComponent: BannerComponent,
    private readonly bannerService: BannerService,
    private readonly teamService: TeamService,
  ) {}

  ngOnInit(): void {
    this.isPrioritized = Boolean(this.priority && this.parentComponent);
    if (this.isPrioritized) {
      this.bannerService.addBannerToPriorityList(this.priority, this.parentComponent, this.bannerKey);
    }

    this.setBannerVisibilityBasedOnPriority();
  }

  ngOnDestroy(): void {
    this.bannerService.removeBannerFromPriorityList(this.bannerKey, this.parentComponent);

    this.unSubscribe.next();
    this.unSubscribe.complete();
  }

  setBannerVisibilityBasedOnPriority() {
    this.teamService.currentTeam.pipe(takeUntil(this.unSubscribe)).subscribe((team: TeamWeb) => {
      const subscriptionStatus = team?.companyMetadata?.billing?.subscription.status;

      if (!subscriptionStatus) {
        return;
      }

      this.isVisible = this.bannerService.isVisibleBasedOnBannerTypeAndSubscription(
        this.bannerKey,
        subscriptionStatus,
        false,
      );

      if (this.isPrioritized) {
        this.bannerService.priorityBannerKey$.pipe(takeUntil(this.unSubscribe)).subscribe((key) => {
          this.isVisible = this.isVisible && key === this.bannerKey;
          this.bannerComponent.updateVisibilityByBannerLogicDirective(this.isVisible);
        });
      } else {
        this.bannerComponent.updateVisibilityByBannerLogicDirective(this.isVisible);
      }
    });
  }
}
