"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListSyncCompare = exports.MismatchPathRoot = exports.ListSyncMismatchKinds = exports.GlobalItemListObject = void 0;
const style_variants_enum_1 = require("../../models/fields/style-variants.enum");
const global_item_models_1 = require("../../models/global-items/global-item-models");
const item_states_enum_1 = require("../../models/global-items/item-states.enum");
const list_entry_types_enum_1 = require("../../models/lists/list-entry-types.enum");
const notification_periods_enum_1 = require("../../models/lists/notification-periods.enum");
var GlobalItemListObject;
(function (GlobalItemListObject) {
    GlobalItemListObject[GlobalItemListObject["List"] = 0] = "List";
    GlobalItemListObject[GlobalItemListObject["Row"] = 1] = "Row";
    GlobalItemListObject[GlobalItemListObject["Cell"] = 2] = "Cell";
})(GlobalItemListObject = exports.GlobalItemListObject || (exports.GlobalItemListObject = {}));
var ListSyncMismatchKinds;
(function (ListSyncMismatchKinds) {
    ListSyncMismatchKinds["InexistentGlobalItemList"] = "InexistentGlobalItemList";
    ListSyncMismatchKinds["ListStates"] = "ListStates";
    ListSyncMismatchKinds["RowState"] = "RowState";
    ListSyncMismatchKinds["CellState"] = "CellState";
    ListSyncMismatchKinds["LegacyValueEmptyButCellIsNot"] = "LegacyValueEmptyButCellIsNot";
    ListSyncMismatchKinds["ListName"] = "ListName";
    ListSyncMismatchKinds["ListDefaultStyle"] = "ListDefaultStyle";
    ListSyncMismatchKinds["ListParents"] = "ListParents";
    ListSyncMismatchKinds["LegacyPropertiesNotAnArray"] = "LegacyPropertiesNotAnArray";
    ListSyncMismatchKinds["ListPropertyCount"] = "ListPropertyCount";
    ListSyncMismatchKinds["ListPropertyName"] = "ListPropertyName";
    ListSyncMismatchKinds["ListPropertyModel"] = "ListPropertyModel";
    ListSyncMismatchKinds["InvalidLegacyPropertySubscribedUsers"] = "InvalidLegacyPropertySubscribedUsers";
    ListSyncMismatchKinds["ListPropertySubscribedUsersPeriods"] = "ListPropertySubscribedUsersPeriods";
    ListSyncMismatchKinds["ListPropertySubscribedUsersIds"] = "ListPropertySubscribedUsersIds";
    ListSyncMismatchKinds["LegacyCellHasNoMatchingLegacyProperty"] = "LegacyCellHasNoMatchingLegacyProperty";
    ListSyncMismatchKinds["LegacyListItemsNotAnArray"] = "LegacyListItemsNotAnArray";
    ListSyncMismatchKinds["LegacyListItemPropertiesNotAnArray"] = "LegacyListItemPropertiesNotAnArray";
    ListSyncMismatchKinds["RowCount"] = "RowCount";
    ListSyncMismatchKinds["MissingLegacyRow"] = "MissingLegacyRow";
    ListSyncMismatchKinds["RowValue"] = "RowValue";
    ListSyncMismatchKinds["RowStyle"] = "RowStyle";
    ListSyncMismatchKinds["CellModel"] = "CellModel";
    ListSyncMismatchKinds["CellValue"] = "CellValue";
    ListSyncMismatchKinds["MissingCell"] = "MissingCell";
    ListSyncMismatchKinds["InvalidDateLegacy"] = "InvalidDateLegacy";
    ListSyncMismatchKinds["InvalidDateGlobalItem"] = "InvalidDateGlobalItem";
    ListSyncMismatchKinds["InvalidAttachmentLegacy"] = "InvalidAttachmentLegacy";
    ListSyncMismatchKinds["InvalidAttachmentGlobalItem"] = "InvalidAttachmentGlobalItem";
    ListSyncMismatchKinds["MissingDeployedParentsProperty"] = "MissingDeployedParentsProperty";
    ListSyncMismatchKinds["DeletedLegacyParents"] = "DeletedLegacyParents";
    ListSyncMismatchKinds["RowDeployedParents"] = "RowDeployedParents";
    ListSyncMismatchKinds["ListDeployedParents"] = "ListDeployedParents";
})(ListSyncMismatchKinds = exports.ListSyncMismatchKinds || (exports.ListSyncMismatchKinds = {}));
exports.MismatchPathRoot = 'ROOT';
class ListSyncCompare {
    /* eslint-disable-next-line no-useless-constructor */
    constructor(parentCache, fetchGlobalItemListByLegacyId, fetchGlobalItemsByRegex) {
        this.parentCache = parentCache;
        this.fetchGlobalItemListByLegacyId = fetchGlobalItemListByLegacyId;
        this.fetchGlobalItemsByRegex = fetchGlobalItemsByRegex;
        this.childCaptureGroup = '([/][\\w]+)';
        this.kindToModelMap = new Map([
            [list_entry_types_enum_1.ListEntryTypes.Text, global_item_models_1.GlobalItemModels.TextSimple],
            [list_entry_types_enum_1.ListEntryTypes.Date, global_item_models_1.GlobalItemModels.DateSimple],
            [list_entry_types_enum_1.ListEntryTypes.DateExpiry, global_item_models_1.GlobalItemModels.DateExpiry],
            [list_entry_types_enum_1.ListEntryTypes.Attachment, global_item_models_1.GlobalItemModels.AttachmentSimple],
            [list_entry_types_enum_1.ListEntryTypes.Number, global_item_models_1.GlobalItemModels.NumberSimple],
        ]);
        this.validNotificationPeriods = new Set(Object.values(notification_periods_enum_1.NotificationPeriods));
        this.mismatchMap = new Map();
    }
    compare(legacyList, parent) {
        return __awaiter(this, void 0, void 0, function* () {
            this.mismatchMap.set(String(legacyList._id), []);
            const globalItemList = yield this.fetchGlobalItemListByLegacyId(legacyList._id);
            if (!globalItemList) {
                return [
                    {
                        kind: ListSyncMismatchKinds.InexistentGlobalItemList,
                        legacyList,
                        legacyPath: [exports.MismatchPathRoot],
                        legacyValue: legacyList,
                        globalItemId: null,
                        globalItemObject: GlobalItemListObject.List,
                        globalItemPath: null,
                        globalItemValue: null,
                    },
                ];
            }
            const shouldFetchDeleted = legacyList.state === item_states_enum_1.ItemStates.Deleted;
            const [globalItemRows, globalItemCells] = yield Promise.all([
                this.getGlobalItemListRows(globalItemList._id, shouldFetchDeleted),
                this.getGlobalItemListCells(globalItemList._id, shouldFetchDeleted),
            ]);
            yield this.compareListObjects(legacyList, globalItemList, parent);
            yield this.compareRows(legacyList, globalItemRows);
            yield this.compareCells(legacyList, globalItemList, globalItemRows, globalItemCells);
            const mismatches = this.mismatchMap.get(String(legacyList._id));
            this.mismatchMap.delete(String(legacyList._id));
            return mismatches;
        });
    }
    /**
     * Retrieves the value at a given path within a nested object. If the path is not fully
     * reachable (i.e., if any key along the path is undefined), the function returns a
     * default value instead.
     *
     * @param object - The object from which to retrieve the value.
     * @param path - An array of keys representing the path to the desired value
     *                       within the object.
     * @param [defaultValue=null] - The default value to return if the full path
     *                                       is not reachable.
     * @returns The value from the specified path in the object or the default value
     *              if the path is not fully reachable.
     *
     * @example
     * // Define an object with nested structure
     * const obj = {
     *   user: {
     *     profile: {
     *       name: "Alice",
     *       address: {
     *         street: "123 Main St",
     *         city: "Metropolis"
     *       }
     *     }
     *   }
     * };
     *
     * // Example of retrieving an existing nested value
     * const name = getIn(obj, ['user', 'profile', 'name']); // Returns "Alice"
     *
     * // Example of attempting to retrieve a non-existent nested value with a default
     * const zipcode = getIn(obj, ['user', 'profile', 'address', 'zipcode'], 'No Zipcode'); // Returns "No Zipcode"
     */
    static getIn(object, path, defaultValue = null) {
        return path.reduce((acc, key) => (acc && acc[key] != null ? acc[key] : defaultValue), object);
    }
    compareListObjects(legacyList, globalItemList, parent) {
        return __awaiter(this, void 0, void 0, function* () {
            if (legacyList.name !== globalItemList.data.name) {
                this.mismatchMap.get(String(legacyList._id)).push({
                    kind: ListSyncMismatchKinds.ListName,
                    legacyList,
                    legacyPath: ['name'],
                    legacyValue: legacyList.name,
                    globalItemId: globalItemList._id,
                    globalItemObject: GlobalItemListObject.List,
                    globalItemPath: ['data', 'name'],
                    globalItemValue: globalItemList.data.name,
                });
            }
            const defaultStylesMatch = legacyList.defaultStyle === globalItemList.data.defaultStyle;
            const defaultStyleAutomaticallySet = !legacyList.defaultStyle &&
                [style_variants_enum_1.StyleVariants.Sky, style_variants_enum_1.StyleVariants.None].includes(globalItemList.data.defaultStyle);
            if (!defaultStylesMatch && !defaultStyleAutomaticallySet) {
                this.mismatchMap.get(String(legacyList._id)).push({
                    kind: ListSyncMismatchKinds.ListDefaultStyle,
                    legacyList,
                    legacyPath: ['defaultStyle'],
                    legacyValue: legacyList.defaultStyle,
                    globalItemId: globalItemList._id,
                    globalItemObject: GlobalItemListObject.List,
                    globalItemPath: ['data', 'defaultStyle'],
                    globalItemValue: globalItemList.data.defaultStyle,
                });
            }
            if (legacyList.state !== globalItemList.state) {
                this.mismatchMap.get(String(legacyList._id)).push({
                    kind: ListSyncMismatchKinds.ListStates,
                    legacyList,
                    legacyPath: ['state'],
                    legacyValue: legacyList.state,
                    globalItemId: globalItemList._id,
                    globalItemObject: GlobalItemListObject.List,
                    globalItemPath: ['state'],
                    globalItemValue: globalItemList.state,
                });
            }
            yield this.compareParent(legacyList, globalItemList, parent);
            yield this.compareProperties(legacyList, globalItemList);
            yield this.compareDeployedParents(legacyList.deployedParents, globalItemList.metadata.deployedParents, legacyList, globalItemList);
        });
    }
    getGlobalItemListRows(listId, shouldFetchDeleted) {
        const states = [item_states_enum_1.ItemStates.Active, item_states_enum_1.ItemStates.Archived];
        if (shouldFetchDeleted) {
            states.push(item_states_enum_1.ItemStates.Deleted);
        }
        const matcher = `^${listId}${this.childCaptureGroup}{1}$`;
        return this.fetchGlobalItemsByRegex(matcher, states);
    }
    getGlobalItemListCells(listId, shouldFetchDeleted) {
        const states = [item_states_enum_1.ItemStates.Active, item_states_enum_1.ItemStates.Archived];
        if (shouldFetchDeleted) {
            states.push(item_states_enum_1.ItemStates.Deleted);
        }
        const matcher = `^${listId}${this.childCaptureGroup}{3}$`;
        return this.fetchGlobalItemsByRegex(matcher, states);
    }
    compareParent(legacyList, globalItemList, parent) {
        return __awaiter(this, void 0, void 0, function* () {
            const parentItemIds = this.getParentItemIdsFromPath(globalItemList._id);
            const globalItemParentItemId = parentItemIds[parentItemIds.length - 1];
            const parentsMatch = parent.itemId === globalItemParentItemId;
            if (!parentsMatch) {
                this.mismatchMap.get(String(legacyList._id)).push({
                    kind: ListSyncMismatchKinds.ListParents,
                    legacyList,
                    legacyPath: ['parents', 0],
                    legacyValue: legacyList.parents[0],
                    globalItemId: globalItemList._id,
                    globalItemObject: GlobalItemListObject.List,
                    globalItemPath: ['_id'],
                    globalItemValue: globalItemList._id,
                });
            }
        });
    }
    getParentItemIdsFromPath(flakePath) {
        return flakePath.split('/').filter(Boolean).slice(0, -1);
    }
    compareProperties(legacyList, globalItemList) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return __awaiter(this, void 0, void 0, function* () {
            if (!Array.isArray(legacyList.properties)) {
                this.mismatchMap.get(String(legacyList._id)).push({
                    kind: ListSyncMismatchKinds.LegacyPropertiesNotAnArray,
                    legacyList,
                    legacyPath: ['properties'],
                    legacyValue: legacyList.properties,
                    globalItemId: globalItemList._id,
                    globalItemObject: GlobalItemListObject.List,
                    globalItemPath: ['data', 'properties'],
                    globalItemValue: globalItemList.data.properties,
                });
                return;
            }
            if (((_a = legacyList.properties) === null || _a === void 0 ? void 0 : _a.length) !== globalItemList.data.properties.length) {
                this.mismatchMap.get(String(legacyList._id)).push({
                    kind: ListSyncMismatchKinds.ListPropertyCount,
                    legacyList,
                    legacyPath: ['properties'],
                    legacyValue: legacyList.properties,
                    globalItemId: globalItemList._id,
                    globalItemObject: GlobalItemListObject.List,
                    globalItemPath: ['data', 'properties'],
                    globalItemValue: globalItemList.data.properties,
                });
                return;
            }
            for (let index = 0; index < legacyList.properties.length; index += 1) {
                const legacyProperty = legacyList.properties[index];
                const globalItemProperty = globalItemList.data.properties[index];
                const namesMatch = legacyProperty.name === globalItemProperty.name;
                if (!namesMatch) {
                    this.mismatchMap.get(String(legacyList._id)).push({
                        kind: ListSyncMismatchKinds.ListPropertyName,
                        legacyList,
                        legacyPath: ['properties', index, 'name'],
                        legacyValue: legacyProperty.name,
                        globalItemId: globalItemList._id,
                        globalItemObject: GlobalItemListObject.List,
                        globalItemPath: ['data', 'properties', index, 'name'],
                        globalItemValue: globalItemProperty.name,
                    });
                }
                const modelsMatch = this.kindToModelMap.get(legacyProperty.kind) === globalItemProperty.model;
                if (!modelsMatch) {
                    this.mismatchMap.get(String(legacyList._id)).push({
                        kind: ListSyncMismatchKinds.ListPropertyModel,
                        legacyList,
                        legacyPath: ['properties', index, 'kind'],
                        legacyValue: legacyProperty.kind,
                        globalItemId: globalItemList._id,
                        globalItemObject: GlobalItemListObject.List,
                        globalItemPath: ['data', 'properties', index, 'model'],
                        globalItemValue: globalItemProperty.model,
                    });
                }
                const isLegacySubscribedUsersValid = Array.isArray((_b = legacyProperty.subscribedUsers) === null || _b === void 0 ? void 0 : _b.notificationPeriods) &&
                    Array.isArray((_c = legacyProperty.subscribedUsers) === null || _c === void 0 ? void 0 : _c.userIds) &&
                    ((_d = legacyProperty.subscribedUsers) === null || _d === void 0 ? void 0 : _d.notificationPeriods.every((period) => this.validNotificationPeriods.has(period)));
                if (!isLegacySubscribedUsersValid) {
                    this.mismatchMap.get(String(legacyList._id)).push({
                        kind: ListSyncMismatchKinds.InvalidLegacyPropertySubscribedUsers,
                        legacyList,
                        legacyPath: ['properties', index, 'subscribedUsers'],
                        legacyValue: legacyProperty.subscribedUsers,
                        globalItemId: globalItemList._id,
                        globalItemObject: GlobalItemListObject.List,
                        globalItemPath: ['data', 'properties', index, 'subscribedUsers'],
                        globalItemValue: globalItemProperty.subscribedUsers,
                    });
                    return;
                }
                const subscribedUserPeriodsMatch = this.areStringArraysEqual((_e = legacyProperty.subscribedUsers) === null || _e === void 0 ? void 0 : _e.notificationPeriods, globalItemProperty.subscribedUsers.notificationPeriods);
                if (!subscribedUserPeriodsMatch) {
                    this.mismatchMap.get(String(legacyList._id)).push({
                        kind: ListSyncMismatchKinds.ListPropertySubscribedUsersPeriods,
                        legacyList,
                        legacyPath: ['properties', index, 'subscribedUsers', 'notificationPeriods'],
                        legacyValue: (_f = legacyProperty.subscribedUsers) === null || _f === void 0 ? void 0 : _f.notificationPeriods,
                        globalItemId: globalItemList._id,
                        globalItemObject: GlobalItemListObject.List,
                        globalItemPath: ['data', 'properties', index, 'subscribedUsers', 'notificationPeriods'],
                        globalItemValue: globalItemProperty.subscribedUsers.notificationPeriods,
                    });
                }
                const subscribedUserIdsMatch = this.areStringArraysEqual((_g = legacyProperty.subscribedUsers) === null || _g === void 0 ? void 0 : _g.userIds, globalItemProperty.subscribedUsers.userIds);
                if (!subscribedUserIdsMatch) {
                    this.mismatchMap.get(String(legacyList._id)).push({
                        kind: ListSyncMismatchKinds.ListPropertySubscribedUsersIds,
                        legacyList,
                        legacyPath: ['properties', index, 'subscribedUsers', 'userIds'],
                        legacyValue: (_h = legacyProperty.subscribedUsers) === null || _h === void 0 ? void 0 : _h.userIds,
                        globalItemId: globalItemList._id,
                        globalItemObject: GlobalItemListObject.List,
                        globalItemPath: ['data', 'properties', index, 'subscribedUsers', 'userIds'],
                        globalItemValue: globalItemProperty.subscribedUsers.userIds,
                    });
                }
            }
        });
    }
    areStringArraysEqual(first, second) {
        const compareFunction = (previous, next) => previous.localeCompare(next);
        return first && second && String(first.sort(compareFunction)) === String(second.sort(compareFunction));
    }
    compareRows(legacyList, globalItemRows) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (!Array.isArray(legacyList.items)) {
                this.mismatchMap.get(String(legacyList._id)).push({
                    kind: ListSyncMismatchKinds.LegacyListItemsNotAnArray,
                    legacyList,
                    legacyPath: ['items'],
                    legacyValue: legacyList.items,
                    globalItemId: null,
                    globalItemObject: GlobalItemListObject.Row,
                    globalItemPath: null,
                    globalItemValue: null,
                });
                return;
            }
            if (legacyList.state !== item_states_enum_1.ItemStates.Deleted && ((_a = legacyList.items) === null || _a === void 0 ? void 0 : _a.length) !== globalItemRows.length) {
                this.mismatchMap.get(String(legacyList._id)).push({
                    kind: ListSyncMismatchKinds.RowCount,
                    legacyList,
                    legacyPath: ['items'],
                    legacyValue: legacyList.items,
                    globalItemId: null,
                    globalItemObject: GlobalItemListObject.Row,
                    globalItemPath: null,
                    globalItemValue: globalItemRows,
                });
                return;
            }
            const legacyListRows = new Map(legacyList.items.map((item) => [String(item._id), item]));
            for (let index = 0; index < globalItemRows.length; index += 1) {
                const globalItemRow = globalItemRows[index];
                const legacyRow = legacyListRows.get(String(globalItemRow.metadata.legacyId));
                if (!legacyRow) {
                    if (legacyList.state !== item_states_enum_1.ItemStates.Deleted) {
                        this.mismatchMap.get(String(legacyList._id)).push({
                            kind: ListSyncMismatchKinds.MissingLegacyRow,
                            legacyList,
                            legacyPath: ['items'],
                            legacyValue: legacyList.items,
                            globalItemId: globalItemRow._id,
                            globalItemObject: GlobalItemListObject.Row,
                            globalItemPath: [exports.MismatchPathRoot],
                            globalItemValue: globalItemRow,
                        });
                    }
                    /* eslint-disable-next-line no-continue */
                    continue;
                }
                yield this.compareRowStyle(legacyList, legacyRow, index, globalItemRow);
                yield this.compareRowValue(legacyList, legacyRow, index, globalItemRow);
                if (globalItemRow.state !== legacyList.state) {
                    this.mismatchMap.get(String(legacyList._id)).push({
                        kind: ListSyncMismatchKinds.RowState,
                        legacyList,
                        legacyPath: ['state'],
                        legacyValue: legacyList,
                        globalItemId: globalItemRow._id,
                        globalItemObject: GlobalItemListObject.Row,
                        globalItemPath: ['state'],
                        globalItemValue: globalItemRow.state,
                    });
                }
                yield this.compareDeployedParents(legacyRow.deployedParents, globalItemRow.metadata.deployedParents, legacyList, globalItemRow, index);
            }
        });
    }
    compareRowValue(legacyList, legacyRow, index, globalItemRow) {
        return __awaiter(this, void 0, void 0, function* () {
            if (legacyRow.value !== globalItemRow.data.value) {
                this.mismatchMap.get(String(legacyList._id)).push({
                    kind: ListSyncMismatchKinds.RowValue,
                    legacyList,
                    legacyPath: ['items', index, 'value'],
                    legacyValue: legacyRow.value,
                    globalItemId: globalItemRow._id,
                    globalItemObject: GlobalItemListObject.Row,
                    globalItemPath: ['data', 'value'],
                    globalItemValue: globalItemRow.data.value,
                });
            }
        });
    }
    compareRowStyle(legacyList, legacyRow, index, globalItemRow) {
        return __awaiter(this, void 0, void 0, function* () {
            const stylesMatch = legacyRow.style === globalItemRow.data.style;
            const styleAutomaticallySet = !legacyRow.style && [style_variants_enum_1.StyleVariants.Sky, style_variants_enum_1.StyleVariants.None].includes(globalItemRow.data.style);
            if (!stylesMatch && !styleAutomaticallySet) {
                this.mismatchMap.get(String(legacyList._id)).push({
                    kind: ListSyncMismatchKinds.RowStyle,
                    legacyList,
                    legacyPath: ['items', index, 'style'],
                    legacyValue: legacyRow.style,
                    globalItemId: globalItemRow._id,
                    globalItemObject: GlobalItemListObject.Row,
                    globalItemPath: ['data', 'style'],
                    globalItemValue: globalItemRow.data.style,
                });
            }
        });
    }
    compareCells(legacyList, globalItemList, globalItemRows, globalItemCells) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!Array.isArray(legacyList.items)) {
                return;
            }
            const lookupHelper = this.composeLookupHelper(legacyList, globalItemList, globalItemRows, globalItemCells);
            const valueComparisonHelper = this.getValueComparisonHelper(legacyList);
            for (let rowIndex = 0; rowIndex < legacyList.items.length; rowIndex += 1) {
                const legacyRow = legacyList.items[rowIndex];
                if (!legacyRow) {
                    continue;
                }
                if (!legacyRow.properties || !Array.isArray(legacyRow.properties)) {
                    this.mismatchMap.get(String(legacyList._id)).push({
                        kind: ListSyncMismatchKinds.LegacyListItemPropertiesNotAnArray,
                        legacyList,
                        legacyPath: ['items', rowIndex, 'properties'],
                        legacyValue: legacyRow.properties,
                        globalItemId: null,
                        globalItemObject: GlobalItemListObject.Cell,
                        globalItemPath: null,
                        globalItemValue: null,
                    });
                    continue;
                }
                for (let propertyIndex = 0; propertyIndex < legacyRow.properties.length; propertyIndex += 1) {
                    const legacyCell = legacyRow.properties[propertyIndex];
                    if (!legacyCell) {
                        continue;
                    }
                    const cell = yield lookupHelper.getCell(String(legacyRow._id), String(legacyCell._id));
                    if (this.isLegacyValueEmpty(legacyCell === null || legacyCell === void 0 ? void 0 : legacyCell.value)) {
                        // If the legacy value is empty and the cell is empty too, it's fine
                        if (this.isCellValueEmpty(cell)) {
                            continue;
                        }
                        else {
                            // We only log if the legacy value is empty AND the cell is not
                            this.mismatchMap.get(String(legacyList._id)).push({
                                kind: ListSyncMismatchKinds.LegacyValueEmptyButCellIsNot,
                                legacyList,
                                legacyPath: ['items', rowIndex, 'properties', propertyIndex, 'value'],
                                legacyValue: legacyCell.value,
                                globalItemId: null,
                                globalItemObject: GlobalItemListObject.Cell,
                                globalItemPath: [exports.MismatchPathRoot],
                                globalItemValue: (cell === null || cell === void 0 ? void 0 : cell.model) === global_item_models_1.GlobalItemModels.AttachmentSimple ? cell.data.attachments : cell === null || cell === void 0 ? void 0 : cell.data.value,
                            });
                            continue;
                        }
                    }
                    if (this.isLegacyValueInvalid(legacyList, legacyCell, rowIndex, propertyIndex)) {
                        continue;
                    }
                    if (!cell) {
                        continue;
                    }
                    const modelsMatch = this.kindToModelMap.get(legacyCell.kind) === cell.model;
                    if (!modelsMatch) {
                        this.mismatchMap.get(String(legacyList._id)).push({
                            kind: ListSyncMismatchKinds.CellModel,
                            legacyList,
                            legacyPath: ['items', rowIndex, 'properties', propertyIndex, 'kind'],
                            legacyValue: legacyCell.kind,
                            globalItemId: cell._id,
                            globalItemObject: GlobalItemListObject.Cell,
                            globalItemPath: ['model'],
                            globalItemValue: cell.model,
                        });
                        continue;
                    }
                    if (cell.state !== legacyList.state) {
                        this.mismatchMap.get(String(legacyList._id)).push({
                            kind: ListSyncMismatchKinds.CellState,
                            legacyList,
                            legacyPath: ['state'],
                            legacyValue: legacyList,
                            globalItemId: cell._id,
                            globalItemObject: GlobalItemListObject.Cell,
                            globalItemPath: ['state'],
                            globalItemValue: cell.state,
                        });
                    }
                    yield valueComparisonHelper.compare(legacyCell, cell, { rowIndex, propertyIndex });
                }
            }
        });
    }
    isCellValueEmpty(cell) {
        if (cell === null) {
            return true;
        }
        const isValueNull = cell.model === global_item_models_1.GlobalItemModels.AttachmentSimple
            ? cell.data.attachments === null
            : cell.data.value === null;
        if (isValueNull) {
            return true;
        }
        const isEmptyStringAndText = cell.model === global_item_models_1.GlobalItemModels.TextSimple && cell.data.value === '';
        const isEmptyAttachmentArray = cell.model === global_item_models_1.GlobalItemModels.AttachmentSimple && cell.data.attachments.length === 0;
        return isEmptyStringAndText || isEmptyAttachmentArray;
    }
    composeLookupHelper(legacyList, globalItemList, globalItemRows, globalItemCells) {
        var _a;
        const legacyListPropertyIds = new Set((_a = legacyList.properties) === null || _a === void 0 ? void 0 : _a.map(({ _id }) => String(_id)));
        const globalPropertyMap = new Map(globalItemList.data.properties.map((property) => [String(property.legacyId), property]));
        const globalRowMap = new Map(globalItemRows.map((row) => [String(row.metadata.legacyId), row]));
        const globalCompositeKeyMap = new Map(globalItemCells.map((cell) => {
            const parentIds = this.getParentItemIdsFromPath(cell._id);
            const [rowId, propertyId] = parentIds.slice(-2);
            return [`/${rowId}/${propertyId}`, cell];
        }));
        const { mismatchMap, isLegacyValueEmpty } = this;
        return {
            getCell(legacyRowId, legacyPropertyId) {
                return __awaiter(this, void 0, void 0, function* () {
                    const row = globalRowMap.get(legacyRowId);
                    const property = globalPropertyMap.get(legacyPropertyId);
                    const rowIndex = legacyList.items.findIndex(({ _id }) => String(_id) === legacyRowId);
                    const propertyIndex = legacyList.items[rowIndex].properties.findIndex(({ _id }) => String(_id) === legacyPropertyId);
                    if (!legacyListPropertyIds.has(legacyPropertyId)) {
                        mismatchMap.get(String(legacyList._id)).push({
                            kind: ListSyncMismatchKinds.LegacyCellHasNoMatchingLegacyProperty,
                            legacyList,
                            legacyPath: ['items'],
                            legacyValue: legacyList.items,
                            globalItemId: null,
                            globalItemObject: GlobalItemListObject.Cell,
                            globalItemPath: null,
                            globalItemValue: null,
                        });
                        return null;
                    }
                    const legacyCellIsEmpty = isLegacyValueEmpty(legacyList.items[rowIndex].properties[propertyIndex].value);
                    if (!row || !property) {
                        if (!legacyCellIsEmpty) {
                            mismatchMap.get(String(legacyList._id)).push({
                                kind: ListSyncMismatchKinds.MissingCell,
                                legacyList,
                                legacyPath: ['items', rowIndex, 'properties', propertyIndex],
                                legacyValue: legacyList.items[rowIndex].properties[propertyIndex],
                                globalItemId: null,
                                globalItemObject: GlobalItemListObject.Cell,
                                globalItemPath: null,
                                globalItemValue: null,
                            });
                        }
                        return null;
                    }
                    const compositeKey = `/${row.metadata.itemId}/${property._id}`;
                    const cell = globalCompositeKeyMap.get(compositeKey);
                    if (!cell && !legacyCellIsEmpty) {
                        mismatchMap.get(String(legacyList._id)).push({
                            kind: ListSyncMismatchKinds.MissingCell,
                            legacyList,
                            legacyPath: ['items', rowIndex, 'properties', propertyIndex],
                            legacyValue: legacyList.items,
                            globalItemId: null,
                            globalItemObject: GlobalItemListObject.Cell,
                            globalItemPath: null,
                            globalItemValue: null,
                        });
                        return null;
                    }
                    return cell !== null && cell !== void 0 ? cell : null;
                });
            },
        };
    }
    isLegacyValueEmpty(value) {
        return (value === '' || value === undefined || value === null || (Array.isArray(value) && value.length === 0));
    }
    // The below works because .getTime() on invalid dates return NaN, and NaN is never equal to itself
    isDateValid(date) {
        /* eslint-disable-next-line no-self-compare */
        return date.getTime() === date.getTime();
    }
    isLegacyValueInvalid(legacyList, cell, rowIndex, propertyIndex) {
        if (cell.kind === list_entry_types_enum_1.ListEntryTypes.Number && Number.isNaN(Number(cell.value))) {
            return true;
        }
        if ([list_entry_types_enum_1.ListEntryTypes.Date, list_entry_types_enum_1.ListEntryTypes.DateExpiry].includes(cell.kind) &&
            !this.isDateValid(new Date(cell.value))) {
            this.mismatchMap.get(String(legacyList._id)).push({
                kind: ListSyncMismatchKinds.InvalidDateLegacy,
                legacyList,
                legacyPath: ['items', rowIndex, 'properties', propertyIndex],
                legacyValue: cell.value,
                globalItemId: null,
                globalItemObject: GlobalItemListObject.Cell,
                globalItemPath: null,
                globalItemValue: null,
            });
            return true;
        }
        if (cell.kind === list_entry_types_enum_1.ListEntryTypes.Attachment && !Array.isArray(cell.value)) {
            this.mismatchMap.get(String(legacyList._id)).push({
                kind: ListSyncMismatchKinds.InvalidAttachmentLegacy,
                legacyList,
                legacyPath: ['items', rowIndex, 'properties', propertyIndex],
                legacyValue: cell.value,
                globalItemId: null,
                globalItemObject: GlobalItemListObject.Cell,
                globalItemPath: null,
                globalItemValue: null,
            });
            return true;
        }
        return false;
    }
    getValueComparisonHelper(legacyList) {
        const { mismatchMap } = this;
        const dateComparator = (legacyCell, globalItemCell, { propertyIndex, rowIndex }) => __awaiter(this, void 0, void 0, function* () {
            const legacyDate = new Date(legacyCell.value);
            const globalItemDate = new Date(globalItemCell.data.value);
            if (!this.isDateValid(legacyDate)) {
                this.mismatchMap.get(String(legacyList._id)).push({
                    kind: ListSyncMismatchKinds.InvalidDateLegacy,
                    legacyList,
                    legacyPath: ['items', rowIndex, 'properties', propertyIndex, 'value'],
                    legacyValue: legacyList.items[rowIndex].properties[propertyIndex].value,
                    globalItemId: globalItemCell._id,
                    globalItemObject: GlobalItemListObject.Cell,
                    globalItemPath: ['data', 'value'],
                    globalItemValue: globalItemCell.data.value,
                });
                return true;
            }
            if (!this.isDateValid(globalItemDate)) {
                this.mismatchMap.get(String(legacyList._id)).push({
                    kind: ListSyncMismatchKinds.InvalidDateGlobalItem,
                    legacyList,
                    legacyPath: ['items', rowIndex, 'properties', propertyIndex, 'value'],
                    legacyValue: legacyList.items[rowIndex].properties[propertyIndex].value,
                    globalItemId: globalItemCell._id,
                    globalItemObject: GlobalItemListObject.Cell,
                    globalItemPath: ['data', 'value'],
                    globalItemValue: globalItemCell.data.value,
                });
                return true;
            }
            return String(legacyDate) === String(globalItemDate);
        });
        const attachmentComparator = (legacyCell, globalItemCell, { propertyIndex, rowIndex }) => __awaiter(this, void 0, void 0, function* () {
            const cell = globalItemCell;
            const legacyAttachments = legacyCell.value;
            const globalItemAttachments = cell.data.attachments;
            if (!Array.isArray(legacyAttachments)) {
                this.mismatchMap.get(String(legacyList._id)).push({
                    kind: ListSyncMismatchKinds.InvalidAttachmentLegacy,
                    legacyList,
                    legacyPath: ['items', rowIndex, 'properties', propertyIndex, 'value'],
                    legacyValue: legacyList.items[rowIndex].properties[propertyIndex].value,
                    globalItemId: globalItemCell._id,
                    globalItemObject: GlobalItemListObject.Cell,
                    globalItemPath: ['data', 'attachments'],
                    globalItemValue: globalItemCell.data.attachments,
                });
                return true;
            }
            if (!Array.isArray(globalItemAttachments)) {
                this.mismatchMap.get(String(legacyList._id)).push({
                    kind: ListSyncMismatchKinds.InvalidAttachmentGlobalItem,
                    legacyList,
                    legacyPath: ['items', rowIndex, 'properties', propertyIndex, 'value'],
                    legacyValue: legacyList.items[rowIndex].properties[propertyIndex].value,
                    globalItemId: globalItemCell._id,
                    globalItemObject: GlobalItemListObject.Cell,
                    globalItemPath: ['data', 'attachments'],
                    globalItemValue: globalItemCell.data.attachments,
                });
                return true;
            }
            if ((globalItemAttachments === null || globalItemAttachments === void 0 ? void 0 : globalItemAttachments.length) !== (legacyAttachments === null || legacyAttachments === void 0 ? void 0 : legacyAttachments.length)) {
                return false;
            }
            /* eslint-disable-next-line no-restricted-syntax */
            for (const legacyAttachment of legacyAttachments) {
                if (!legacyAttachment) {
                    continue;
                }
                const globalItemAttachment = globalItemAttachments
                    .filter(Boolean)
                    .find((attachment) => attachment.fullUrl === legacyAttachment.fullUrl);
                if (!globalItemAttachment) {
                    return false;
                }
                const filenamesMatch = legacyAttachment.filename === globalItemAttachment.filename;
                const thumbnailUrlsMatch = legacyAttachment.thumbnailUrl === globalItemAttachment.thumbnailUrl;
                if (!filenamesMatch || !thumbnailUrlsMatch) {
                    return false;
                }
            }
            return true;
        });
        const comparisonMap = {
            [global_item_models_1.GlobalItemModels.AttachmentSimple]: attachmentComparator,
            [global_item_models_1.GlobalItemModels.Container]: () => __awaiter(this, void 0, void 0, function* () { return false; }),
            [global_item_models_1.GlobalItemModels.Location]: () => __awaiter(this, void 0, void 0, function* () { return false; }),
            [global_item_models_1.GlobalItemModels.DatePlain]: () => __awaiter(this, void 0, void 0, function* () { return false; }),
            [global_item_models_1.GlobalItemModels.DateRangePlain]: () => __awaiter(this, void 0, void 0, function* () { return false; }),
            [global_item_models_1.GlobalItemModels.DateExpiry]: dateComparator,
            [global_item_models_1.GlobalItemModels.DateSimple]: dateComparator,
            [global_item_models_1.GlobalItemModels.NumberSimple]: (legacyCell, globalItemCell) => __awaiter(this, void 0, void 0, function* () { return Number(legacyCell.value) === globalItemCell.data.value; }),
            [global_item_models_1.GlobalItemModels.TextSimple]: (legacyCell, globalItemCell) => __awaiter(this, void 0, void 0, function* () { return legacyCell.value === globalItemCell.data.value; }),
            [global_item_models_1.GlobalItemModels.TimePlain]: () => __awaiter(this, void 0, void 0, function* () { return false; }),
        };
        return {
            compare(legacyCell, globalItemCell, { rowIndex, propertyIndex }) {
                return __awaiter(this, void 0, void 0, function* () {
                    const compareFunction = comparisonMap[globalItemCell.model];
                    if (!compareFunction) {
                        return false;
                    }
                    const isEqual = yield compareFunction(legacyCell, globalItemCell, { rowIndex, propertyIndex });
                    if (!isEqual) {
                        const isAttachment = globalItemCell.model === global_item_models_1.GlobalItemModels.AttachmentSimple;
                        const globalItemPath = ['data'];
                        let globalItemValue;
                        if (isAttachment) {
                            globalItemPath.push('attachments');
                            globalItemValue = globalItemCell.data.attachments;
                        }
                        else {
                            globalItemPath.push('value');
                            globalItemValue = globalItemCell.data.value;
                        }
                        mismatchMap.get(String(legacyList._id)).push({
                            kind: ListSyncMismatchKinds.CellValue,
                            legacyList,
                            legacyPath: ['items', rowIndex, 'properties', propertyIndex],
                            legacyValue: legacyList.items[rowIndex].properties[propertyIndex].value,
                            globalItemId: globalItemCell._id,
                            globalItemObject: GlobalItemListObject.Cell,
                            globalItemPath,
                            globalItemValue,
                        });
                    }
                    return isEqual;
                });
            },
        };
    }
    compareDeployedParents(legacyDeployedParentIds, globalItemDeployedParentIds, legacyList, globalItem, rowIndex) {
        return __awaiter(this, void 0, void 0, function* () {
            const legacyPath = ['deployedParents'];
            let globalItemObject = GlobalItemListObject.List;
            const globalItemPath = ['metadata', 'deployedParents'];
            if (rowIndex !== undefined) {
                legacyPath.unshift(...['items', rowIndex]);
                globalItemObject = GlobalItemListObject.Row;
            }
            if (legacyDeployedParentIds === undefined || legacyDeployedParentIds === null) {
                this.mismatchMap.get(String(legacyList._id)).push({
                    kind: ListSyncMismatchKinds.MissingDeployedParentsProperty,
                    legacyList,
                    legacyPath,
                    legacyValue: ListSyncCompare.getIn(legacyList, legacyPath),
                    globalItemId: null,
                    globalItemObject: GlobalItemListObject.Row,
                    globalItemPath: null,
                    globalItemValue: null,
                });
                return;
            }
            const legacyDeployedParents = yield this.parentCache.getByIds(legacyDeployedParentIds);
            const uniqueGlobalItemDeployedParentIds = new Set(globalItemDeployedParentIds);
            let matchingParentCount = 0;
            const deletedParentIds = new Set();
            /* eslint-disable-next-line no-restricted-syntax */
            for (const [legacyParentId, legacyDeployedParent] of legacyDeployedParents.entries()) {
                if (legacyDeployedParent === null) {
                    deletedParentIds.add(legacyParentId);
                }
                else if (uniqueGlobalItemDeployedParentIds.has(legacyDeployedParent.itemId)) {
                    matchingParentCount += 1;
                }
            }
            const matchingDiffers = matchingParentCount !== legacyDeployedParents.size - deletedParentIds.size;
            const countDiffers = uniqueGlobalItemDeployedParentIds.size !== legacyDeployedParents.size - deletedParentIds.size;
            if (matchingDiffers || countDiffers) {
                const kind = rowIndex !== undefined
                    ? ListSyncMismatchKinds.RowDeployedParents
                    : ListSyncMismatchKinds.ListDeployedParents;
                this.mismatchMap.get(String(legacyList._id)).push({
                    kind,
                    legacyList,
                    legacyPath,
                    legacyValue: ListSyncCompare.getIn(legacyList, legacyPath),
                    globalItemId: globalItem._id,
                    globalItemObject,
                    globalItemPath,
                    globalItemValue: ListSyncCompare.getIn(globalItem, globalItemPath),
                });
            }
            if (deletedParentIds.size !== 0) {
                this.mismatchMap.get(String(legacyList._id)).push({
                    kind: ListSyncMismatchKinds.DeletedLegacyParents,
                    extraData: { deletedParents: [...deletedParentIds] },
                    legacyList,
                    legacyPath,
                    legacyValue: ListSyncCompare.getIn(legacyList, legacyPath),
                    globalItemId: globalItem._id,
                    globalItemObject,
                    globalItemPath,
                    globalItemValue: ListSyncCompare.getIn(globalItem, globalItemPath),
                });
            }
        });
    }
}
exports.ListSyncCompare = ListSyncCompare;
