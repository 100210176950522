import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

import { UserService } from 'app/user/user.service';

import { dayjs } from '../dayjs';

@Injectable({ providedIn: 'root' })
export class DateFormatService {
  timezone: string;

  // see: https://timezonedb.com/time-zones
  private monthDayYearTimezones = [
    'America/Adak',
    'America/Anchorage',
    'America/Boise',
    'America/Chicago',
    'America/Denver',
    'America/Detroit',
    'America/Indiana/Indianapolis',
    'America/Indiana/Knox',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Tell_City',
    'America/Indiana/Vevay',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Juneau',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Los_Angeles',
    'America/Menominee',
    'America/Metlakatla',
    'America/New_York',
    'America/Nome',
    'America/North_Dakota/Beulah',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/Phoenix',
    'America/Sitka',
    'America/Yakutat',
    'Pacific/Honolulu',
  ];

  constructor(private readonly userService: UserService) {
    this.userService.currentUser.pipe(filter((user) => !!user)).subscribe((user) => {
      this.timezone = user.timezone || dayjs.tz.guess();
    });
  }

  isTimezoneMonthDayYear(): boolean {
    return this.monthDayYearTimezones.indexOf(this.timezone) > -1;
  }

  getDateFormat(): string {
    return this.isTimezoneMonthDayYear() ? 'MM/DD/YYYY' : 'DD/MM/YYYY';
  }
}
