import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

import { IPasswordValidation } from 'app/shared/model/password-validation.model';

@Component({
  selector: 'cc-password-validation-list',
  templateUrl: './password-validation-list.component.html',
  styleUrls: ['./password-validation-list.component.scss'],
})
export class PasswordValidationListComponent implements OnChanges {
  @Input() passwordValidation: IPasswordValidation;

  passwordValidations: { message: string; valid: boolean }[] = [];

  @HostBinding('class.has-error')
  get hasError(): boolean {
    const currentValidation = this.passwordValidation || {};
    return Object.values(currentValidation).some((value) => !value);
  }

  validationMessages: Record<keyof IPasswordValidation, string> = {
    hasLowercase: 'One lowercase character',
    hasUppercase: 'One uppercase character',
    hasNumber: 'One number',
    hasMinLength: '10 characters minimum',
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.passwordValidation && this.passwordValidation) {
      this.passwordValidations = Object.keys(this.passwordValidation).map((key) => ({
        message: this.validationMessages[key as keyof IPasswordValidation],
        valid: this.passwordValidation[key as keyof IPasswordValidation],
      }));
    }
  }
}
