"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoryFieldCloner = void 0;
const generic_field_1 = require("./generic-field");
class CategoryFieldCloner extends generic_field_1.GenericFieldCloner {
    mergeField(templateField, formField, clearLogicFields = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const list = templateField.categorySource === 'list' && templateField.reference
                ? yield this.getListItems(templateField.reference) // get latest list items from the caller (eg. from db or store)
                : templateField.items; // manual list items
            // filter out any selected items that don't exist in the new template manual or referenced list of items
            const selectedItems = (formField.selectedItems || []).filter((item) => list === null || list === void 0 ? void 0 : list.some((i) => i.id === item.id));
            const logicRules = this.mergeLogicRules(templateField.logicRules, formField.logicRules, clearLogicFields);
            // NOTE: if we calculate logicRules & selectedItems in this returned object it breaks (not all fields are set correctly)
            // not sure why atm. Likely to do with the interplay b/w getListItems, TS & this being a shared library
            return Object.assign(Object.assign(Object.assign({}, templateField), formField), { description: templateField.description, isRequired: templateField.isRequired, categoryType: templateField.categoryType, items: templateField.items, reference: templateField.reference, dependsOn: templateField.dependsOn, logicRules,
                selectedItems });
        });
    }
}
exports.CategoryFieldCloner = CategoryFieldCloner;
