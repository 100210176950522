@if (!cellData.item) {
  <cc-template-table-location-cell *ngIf="!readOnly"></cc-template-table-location-cell>
} @else {
  <div
    *ngIf="!readOnly; else locationPanel"
    class="sm-relative sm-rounded sm-border sm-border-grey-3"
    data-test="location-panel-border"
  >
    <ng-container *ngTemplateOutlet="locationPanel"></ng-container>
  </div>
  <ng-template #locationPanel>
    <div
      class="sm-flex sm-flex-col sm-gap-1 sm-break-words sm-p-1 sm-pr-0.4 sm-text-secondary-text"
      [ngClass]="{ 'sm-max-w-[68px]': width === tableColumnWidths.Small }"
      data-test="location-data"
    >
      <span class="sm-text-default-text"
        >{{ locationData.user.firstName }} {{ locationData.user.lastName }}</span
      >
      <span>{{ locationData.capturedAt | date: 'MMM dd, yyyy' }}</span>
      <span>{{ locationData.capturedAt | date: 'hh:mm a' }}</span>
      <span>Lat: {{ locationData.location.latitude }}</span>
      <span>Long: {{ locationData.location.longitude }}</span>
    </div>
    <div class="sm-p-1 sm-pt-0">
      <button
        *ngIf="isGeoAPILoaded$ | async"
        gw-button
        color="grey-3"
        size="small"
        type="button"
        text="View Map"
        (click)="open($event)"
        data-test="view-map-button"
      ></button>
    </div>
    <button
      *ngIf="!readOnly"
      type="button"
      class="sm-absolute -sm-right-px -sm-top-px sm-h-4 sm-max-h-4 sm-w-4 sm-rounded sm-bg-grey-4 hover:sm-bg-grey-5"
      data-test="delete-location-button"
      (click)="onDeleteLocation()"
    >
      <i class="cc-icon cc-icon-close-small sm-block sm-text-white"></i>
    </button>
  </ng-template>
}
