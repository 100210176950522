"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LOOKUP_AND_REF_FORMULAS = exports.LOOKUP_AND_REF_NOT_SUPPORTED_FORMULAS = exports.LOOKUP_AND_REF_SUPPORTED_FORMULAS = exports.LOOKUP_AND_REF_SUPPORTED_FORMULAS_WITH_INFO = void 0;
exports.LOOKUP_AND_REF_SUPPORTED_FORMULAS_WITH_INFO = new Map([
    [
        'CHOOSE',
        {
            syntax: 'CHOOSE(Index, Value1, ..., Value30)',
            description: 'Uses an index to return a value from a list of up to 30 values.',
        },
    ],
    [
        'COLUMN',
        {
            syntax: 'COLUMNS([Reference])',
            description: 'Returns column number of a given reference or formula reference if argument not provided.',
        },
    ],
    [
        'COLUMNS',
        {
            syntax: 'COLUMNS(Array)',
            description: 'Returns the number of columns in the given reference.',
        },
    ],
    [
        'FORMULATEXT',
        {
            syntax: 'FORMULATEXT(Reference)',
            description: 'Returns a formula in a given cell as a string.',
        },
    ],
    [
        'HLOOKUP',
        {
            syntax: 'HLOOKUP(Search_Criterion, Array, Index, Sort_Order)',
            description: 'Searches horizontally with reference to adjacent cells to the bottom.',
        },
    ],
    [
        'INDEX',
        {
            syntax: 'INDEX(Range, Row [, Column])',
            description: 'Returns the contents of a cell specified by row and column number. The column number is optional and defaults to 1.',
        },
    ],
    [
        'MATCH',
        {
            syntax: 'MATCH(Searchcriterion, Lookuparray, Type)',
            description: 'Returns the relative position of an item in an array that matches a specified value.',
        },
    ],
    [
        'OFFSET',
        {
            syntax: 'OFFSET(Reference, Rows, Columns, Height, Width)',
            description: 'Returns the value of a cell offset by a certain number of rows and columns from a given reference point.',
        },
    ],
    [
        'ROW',
        {
            syntax: 'ROW([Reference])',
            description: 'Returns row number of a given reference or formula reference if argument not provided.',
        },
    ],
    [
        'ROWS',
        {
            syntax: 'ROWS(Array)',
            description: 'Returns the number of rows in the given reference.',
        },
    ],
    [
        'VLOOKUP',
        {
            syntax: 'VLOOKUP(Search_Criterion, Array, Index, Sort_Order)',
            description: 'Searches vertically with reference to adjacent cells to the right.',
        },
    ],
]);
exports.LOOKUP_AND_REF_SUPPORTED_FORMULAS = [...exports.LOOKUP_AND_REF_SUPPORTED_FORMULAS_WITH_INFO.keys()];
exports.LOOKUP_AND_REF_NOT_SUPPORTED_FORMULAS = [];
exports.LOOKUP_AND_REF_FORMULAS = exports.LOOKUP_AND_REF_SUPPORTED_FORMULAS.concat(exports.LOOKUP_AND_REF_NOT_SUPPORTED_FORMULAS);
