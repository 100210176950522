import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { ExportResponse } from 'app/shared/model/export-response.model';
import { PhotoTag } from 'app/shared/model/photo-tag.model';
import { PhotoWeb } from 'app/shared/model/photo-web.model';
import { HttpClientService } from 'app/shared/service/http-client.service';

type GetPhotosByTeamResponse = Observable<Array<PhotoWeb>>;

@Injectable({
  providedIn: 'root',
})
export class PhotoService {
  private reloadSubject = new Subject<void>();
  public reloadEvent = this.reloadSubject.asObservable();
  deletePhotoSubject = new Subject();
  deletePhotoEvent = this.deletePhotoSubject.asObservable();
  kmlFileSubject = new Subject<string>();
  kmlFileEvent = this.kmlFileSubject.asObservable();

  constructor(private readonly http: HttpClientService) {}

  getPhotosByTeam(id: string, limit: number, offset: number, searchCriteria?): GetPhotosByTeamResponse {
    let url = `teams/${id}/photos?limit=${limit}&offset=${offset}`;
    if (searchCriteria) {
      if (searchCriteria.fullText) {
        url += `&fulltext=${searchCriteria.fullText}`;
      }
      if (searchCriteria.userIds) {
        searchCriteria.userIds.forEach((userId) => {
          url += `&createdBy=${userId}`;
        });
      }

      if (searchCriteria.date) {
        if (searchCriteria.date.startDate) {
          url += `&startDate=${searchCriteria.date.startDate}`;
        }

        if (searchCriteria.date.endDate) {
          url += `&endDate=${searchCriteria.date.endDate}`;
        }
      }

      if (searchCriteria.tags) {
        url += `&tags=${searchCriteria.tags}`;
      }
    }
    return this.http.get(url);
  }

  getPhotoUsersForTeam(teamId: string) {
    return this.http.get(`teams/${teamId}/photos/users`);
  }

  remove(id: string) {
    return this.http.remove(`v1/photos/${id}`);
  }

  getKml(teamId: string) {
    return this.http.get(`teams/${teamId}/kml`);
  }

  reload() {
    this.reloadSubject.next();
  }

  getPhotoComments(photoId) {
    return this.http.get(`v1/photos/${photoId}/comments`);
  }

  getPhotoMapUrl(teamId: string, photoId: string, dimension: { height: number; width: number }) {
    return this.http.get(
      `teams/${teamId}/photos/${photoId}/static-map?height=${dimension.height}&width=${dimension.width}`,
    );
  }

  addComment(photoId, comment) {
    return this.http.post(`v1/photos/${photoId}/comments`, { comment });
  }

  export(ids: string[], teamId: string) {
    return this.http.post<ExportResponse>(`v1/teams/${teamId}/photos/download/pdf`, { ids });
  }

  downloadZip(ids: string[], teamId: string) {
    return this.http.post<ExportResponse>(`v1/teams/${teamId}/photos/download/zip`, { ids });
  }

  downloadOriginal(photosIds: string[], teamId: string) {
    return this.http.post<ExportResponse>(`teams/${teamId}/photos/download/original`, { photosIds });
  }

  move(photoIds, teamId) {
    return this.http.post(`teams/${teamId}/photos/move`, { photoIds });
  }

  copy(photoIds, teamId) {
    return this.http.post(`teams/${teamId}/photos/copy`, { photoIds });
  }

  updateTeamPhotosTags(teamId: string, photosIds: string[], tags: PhotoTag[]) {
    return this.http.put<PhotoWeb[]>(`teams/${teamId}/photos/tags`, { photosIds, tags });
  }

  isEmptySearchCriteria(searchCriteria) {
    return (
      !searchCriteria.date.endDate &&
      !searchCriteria.date.startDate &&
      searchCriteria.fullText === '' &&
      searchCriteria.userIds.length === 0 &&
      !searchCriteria.tags
    );
  }

  updatePhotoTagGroups(photoId, tagGroups) {
    return this.http.put(`v1/photos/${photoId}/tag-groups`, { tagGroups });
  }

  updateComment(photoId, commentId, comment) {
    return this.http.put(`v1/photos/${photoId}/comments/${commentId}`, { comment });
  }

  deleteComment(photoId, commentId) {
    return this.http.remove(`v1/photos/${photoId}/comments/${commentId}`);
  }

  updateDescription(photoId, description) {
    return this.http.put(`v1/photos/${photoId}/description`, { description });
  }

  deleteDescription(photoId) {
    return this.http.remove(`v1/photos/${photoId}/description`);
  }
}
