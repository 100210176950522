"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogicActions = exports.LogicOperators = void 0;
var LogicOperators;
(function (LogicOperators) {
    LogicOperators["Equals"] = "Equals";
    LogicOperators["DoesNotEqual"] = "Does not equal";
    LogicOperators["EqualsToOrHigherThan"] = "Equals to or higher than";
})(LogicOperators = exports.LogicOperators || (exports.LogicOperators = {}));
var LogicActions;
(function (LogicActions) {
    LogicActions["ShowField"] = "Show Fields";
    LogicActions["StopFormBeingSaved"] = "Stop form being saved";
})(LogicActions = exports.LogicActions || (exports.LogicActions = {}));
