import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'cc-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
  @Input() text = '';

  @Input() type: 'info' | 'warning' | 'error' = 'info';

  @Input() bold = false;

  @Input() theme: 'light' | 'dark' = 'light';

  @Input() icon: string;

  @Input() textSize: string;

  @Input() disabled: boolean;

  @Input() size: 'sm' | 'md' = 'md';

  @Input() customClasses = '';

  @Input()
  @HostBinding('class.inline-badge')
  isInlineBadge = false;
}
