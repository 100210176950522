"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormCloneService = void 0;
/* eslint-disable @typescript-eslint/no-non-null-assertion */
const bson_1 = require("bson");
const field_clone_factory_1 = require("./cloning/field-clone.factory");
const form_signature_service_1 = require("./form-signature.service");
const formulas_versions_enum_1 = require("../models/fields/formulas-versions.enum");
class FormCloneService {
    constructor(getListItems) {
        this.getListItems = getListItems;
    }
    mergeFormFields(templateItems, formFields) {
        return __awaiter(this, void 0, void 0, function* () {
            const fieldCloneFactory = new field_clone_factory_1.FormFieldCloneFactory(this.getListItems);
            // we use the template as the master, then copy over whatever fields match the template
            // if no fields match the template, then no data is copied over, the cloned form just has
            // the new template structure
            return Promise.all(templateItems.map((templateField) => {
                const index = formFields.findIndex((field) => String(field._id) === String(templateField._id));
                const isFieldFound = index !== -1;
                const isExcludedFromCloning = templateField.isExcludedInFormCloning;
                const parentField = formFields.find((field) => { var _a; return String(field._id) === ((_a = templateField.dependsOn) === null || _a === void 0 ? void 0 : _a.fieldId); });
                const isLogicExcludedInFormCloningByParent = templateField.dependsOn && (parentField === null || parentField === void 0 ? void 0 : parentField.isExcludedInFormCloning);
                if (!isFieldFound || isLogicExcludedInFormCloningByParent || isExcludedFromCloning) {
                    return templateField;
                }
                const fieldCloner = fieldCloneFactory.getFieldCloner(templateField.kind);
                return fieldCloner.mergeField(templateField, formFields[index], isExcludedFromCloning);
            }));
        });
    }
    mergeForm(newForm, formToClone, template) {
        return __awaiter(this, void 0, void 0, function* () {
            newForm.name = template.name;
            newForm.columns = template.columns;
            newForm.counterIsActivated = template.counterIsActivated;
            newForm.appVersion = template.appVersion;
            newForm.parents = formToClone.parents;
            newForm.expiresOn = formToClone.expiresOn;
            newForm.createdAt = formToClone.createdAt;
            newForm.items = yield this.mergeFormFields(template.items, formToClone.items);
            newForm.staticAttachments = formToClone.staticAttachments;
            newForm.dynamicAttachments = formToClone.dynamicAttachments || [];
            newForm.formulasVersion = template.formulasVersion ? template.formulasVersion : formulas_versions_enum_1.FormulasVersions.V1;
            if (template.organisationTemplateId) {
                newForm.organisationTemplateId = template.organisationTemplateId;
            }
        });
    }
    cloneForm(newForm, formToClone, template) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.mergeForm(newForm, formToClone, template);
            newForm.createdAt = new Date();
            if (newForm.dynamicAttachments) {
                newForm.dynamicAttachments.forEach((attachment) => {
                    attachment.id = new bson_1.ObjectId().toHexString();
                });
            }
            const formSignature = new form_signature_service_1.FormSignatureService();
            formSignature.resetSignatures(newForm);
        });
    }
}
exports.FormCloneService = FormCloneService;
