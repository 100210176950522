import { Injectable } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';

import { Company, CompanyBilling, User, UserTypes } from '@site-mate/dashpivot-shared-library';
import { CompanyPlanTypes } from '@site-mate/sitemate-global-shared';

import { ICompanyHomeSettings } from 'app/home/company-home-settings.model';
import { HttpClientService } from 'app/shared/service/http-client.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private dataChanged: Subject<any> = new Subject<any>();
  public dataChanged$ = this.dataChanged.asObservable();

  constructor(private http: HttpClientService) {}

  getCompany(id) {
    return this.http.get<Company>(`companies/${id}`);
  }

  getUsers(companyId, userType?: UserTypes) {
    const userTypeQueryParam = userType ? `?userType=${userType}` : '';

    return this.http.get<User[]>(`companies/${companyId}/users${userTypeQueryParam}`);
  }

  updateCompanyBilling(id: string, billing: CompanyBilling): Observable<any> {
    return this.http.put(`companies/${id}/billing`, billing);
  }

  addMember(
    companyId: string,
    email: string,
    optionalParams: {
      isTestUser?: boolean;
      userType?: UserTypes;
    },
  ) {
    this.dataChanged.next(true);
    return this.http.post(`companies/${companyId}/users`, {
      email,
      isTestUser: optionalParams.isTestUser,
      userType: optionalParams.userType,
    });
  }

  removeCompanyControllerRole(companyId, email) {
    return this.http.post(`companies/${companyId}/users`, {
      email,
      isCompanyController: false,
    });
  }

  updateCompanyUserPermissions(
    companyId: string,
    email: string,
    isCompanyController: boolean,
    userType: UserTypes,
  ) {
    return this.http.post(`companies/${companyId}/users/permission`, {
      email,
      isCompanyController,
      userType,
    });
  }

  removeCompanyUser(companyId: string, userId: string) {
    this.dataChanged.next(true);
    return this.http.remove(`companies/${companyId}/users/${userId}`);
  }

  validatePublicDomains(domains: string[]) {
    return this.http.post(`companies/public-domains`, { domains });
  }

  isSSODomain(email: string) {
    const domain = email.split('@')[1];
    const encodedDomain = encodeURIComponent(domain);

    return this.http.get(`companies/sso-domain/${encodedDomain}`);
  }

  getFormattedDomainInput(domains = ''): string[] {
    return domains.split(', ').map((domain) => domain.trim().toLowerCase());
  }

  getCompanyModel(company: Company): ICompanyHomeSettings {
    const {
      _id,
      id,
      workspaceId,
      accountId,
      name,
      domains,
      jointVenture,
      jointVentureDomains,
      billing,
      businessAttributes,
      smcDemo,
    } = company;

    return {
      _id,
      id,
      workspaceId,
      accountId,
      name,
      domains,
      jointVenture,
      jointVentureDomains,
      businessAttributes,
      smcDemo,
      trialExpiration: this.convertToNgbDate(billing.trialExpiration),
      planType: billing.planType ?? CompanyPlanTypes.Legacy,
      subscriptionStatus: billing.subscription.status,
      subscriptionInitialDate: this.convertToNgbDate(billing.subscription.initialDate),
      subscriptionTerm: billing.subscription.term,
      legacyRestrictions: billing.legacyRestrictions ?? false,
      billingCycle: billing.billingCycle,
      paymentTermContext: billing.payment.termContext,
      paymentTermDay: billing.payment.termDay,
      paymentMethod: billing.payment.method,
      paymentCurrency: billing.payment.currency,
      groupBilling: billing.groupBilling,
      contributorsLicenses: billing.licenses.dashpivotContributors,
      sitematePaidUsersLicenses: billing.licenses.sitematePaidUsers,
    };
  }

  private convertToNgbDate(trialDate: Date): NgbDate {
    const date = new Date(trialDate);
    return new NgbDate(date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate());
  }
}
