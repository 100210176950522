import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SitemateImageUtils {
  getImageVariantUrl(imageUrl: string, size: 'small' | 'medium' | 'large'): string {
    const url = new URL(imageUrl);
    const { pathname } = url;
    const dotIndex = pathname.lastIndexOf('.');

    if (dotIndex <= 0) {
      return imageUrl;
    }

    const head = pathname.substring(0, dotIndex);
    const tail = pathname.substring(dotIndex);

    url.pathname = `${head}-${size}${tail}`;
    return url.href;
  }
}
