"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultFieldValidator = void 0;
const lodash_1 = require("lodash");
class DefaultFieldValidator {
    hasAnyData(field) {
        return this.hasCompleteData(field);
    }
    hasCompleteData(field) {
        return (!!field.content || !(0, lodash_1.isEmpty)(field.photos) || !(0, lodash_1.isEmpty)(field.signatures) || !(0, lodash_1.isEmpty)(field.selectedItems));
    }
    isValid(field) {
        if (field.isRequired && !this.hasCompleteData(field)) {
            return false;
        }
        return true;
    }
    invalidDescription(field) {
        if (field.isRequired && !this.hasCompleteData(field)) {
            return 'Required field missing';
        }
        if (!this.isValid(field)) {
            return 'invalid';
        }
        return '';
    }
}
exports.DefaultFieldValidator = DefaultFieldValidator;
