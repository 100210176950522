import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import {
  DashpivotEvent,
  EventNotifierService,
  EventTypes,
  IHierarchy,
} from '@site-mate/dashpivot-shared-library';

import { SegmentService } from 'app/segment/segment.service';
import { UploaderParams } from 'app/shared/model/uploader-params.model';

@Component({
  selector: 'cc-form-photo-modal',
  templateUrl: 'form-photo-modal.component.html',
  styleUrls: ['form-photo-modal.component.scss'],
})
export class FormPhotoModalComponent {
  @Input() hierarchy: IHierarchy;
  @Input() formRef: boolean;
  @Input() uploaderLabels: UploaderParams['labels'];
  @Input() acceptedFiles: UploaderParams['acceptedFiles'];

  selected = [];

  constructor(
    private readonly modal: NgbActiveModal,
    private readonly segmentService: SegmentService,
  ) {}

  onPhotoTick(selected) {
    this.selected = selected;
  }

  select() {
    void EventNotifierService.notify(new DashpivotEvent(EventTypes.FormPhotoAdded), this.segmentService);
    this.modal.close(this.selected);
  }

  close(): void {
    this.modal.dismiss();
  }
}
