<i class="cc-icon cc-icon-search"></i>
<input
  [(ngModel)]="value"
  (ngModelChange)="onInputValueChange($event)"
  class="sm-flex-1 sm-bg-transparent sm-text-white focus:sm-outline-none"
  [placeholder]="placeholder || 'Search...'"
  type="text"
  #searchInput
  ngbAutoFocus
/>
<button
  type="button"
  *ngIf="value"
  class="clear-button sm-flex sm-cursor-pointer sm-items-center sm-justify-center"
  (click)="clearInput()"
  tabindex="0"
>
  <i class="cc-icon cc-icon-close-circle-solid"></i>
</button>
