import { BannerKeys } from 'app/shared/model/banner-keys.enum';

import { FlowsiteTemplateReference } from './flowsite-template-reference.model';

type FlowsiteBannerMetadata = {
  regex: RegExp;
  reference: FlowsiteTemplateReference;
  bannerKey: BannerKeys;
  continents?: { exclude?: string[]; include?: string[] };
};

export const FlowsiteBannerMetadataList: Readonly<Readonly<FlowsiteBannerMetadata>[]> = [
  {
    regex: /timesheet/i,
    reference: FlowsiteTemplateReference.DashpivotXeroTimesheets,
    bannerKey: BannerKeys.FlowsiteXeroTimesheets,
    continents: { exclude: ['NA'] },
  },
  {
    regex: /docket/i,
    reference: FlowsiteTemplateReference.DashpivotXeroDockets,
    bannerKey: BannerKeys.FlowsiteXeroDockets,
    continents: { exclude: ['NA'] },
  },
  {
    regex: /docket/i,
    reference: FlowsiteTemplateReference.DashpivotQuickBooksDockets,
    bannerKey: BannerKeys.FlowsiteQuickBooksDockets,
    continents: { include: ['NA'] },
  },
  {
    regex: /power-bi-and-excel-for-analytics-tab/i,
    reference: FlowsiteTemplateReference.DashpivotPowerBiExcel,
    bannerKey: BannerKeys.FlowsitePowerBiExcel,
  },
  {
    regex: /site.*diary|diary.*site/i,
    reference: FlowsiteTemplateReference.DashpivotPowerBiExcelSiteDiary,
    bannerKey: BannerKeys.FlowsitePowerBiExcelTemplates,
  },
  {
    regex: /incident/i,
    reference: FlowsiteTemplateReference.DashpivotPowerBiExcelIncidentReport,
    bannerKey: BannerKeys.FlowsitePowerBiExcelTemplates,
  },
  {
    regex: /audit/i,
    reference: FlowsiteTemplateReference.DashpivotPowerBiExcelAuditForm,
    bannerKey: BannerKeys.FlowsitePowerBiExcelTemplates,
  },
  {
    regex: /swms|rams/i,
    reference: FlowsiteTemplateReference.DashpivotPowerBiExcelSwmsRams,
    bannerKey: BannerKeys.FlowsitePowerBiExcelTemplates,
  },
  {
    regex: /jsa/i,
    reference: FlowsiteTemplateReference.DashpivotPowerBiExcelJsa,
    bannerKey: BannerKeys.FlowsitePowerBiExcelTemplates,
  },
  {
    regex: /pre.*start/i,
    reference: FlowsiteTemplateReference.DashpivotPowerBiExcelMachinePrestart,
    bannerKey: BannerKeys.FlowsitePowerBiExcelTemplates,
  },
  {
    regex: /attendance/i,
    reference: FlowsiteTemplateReference.DashpivotPowerBiExcelAttendanceRecord,
    bannerKey: BannerKeys.FlowsitePowerBiExcelTemplates,
  },
  {
    regex: /non.*conformance|defect/i,
    reference: FlowsiteTemplateReference.DashpivotPowerBiExcelNonConformanceDefectReport,
    bannerKey: BannerKeys.FlowsitePowerBiExcelTemplates,
  },
  {
    regex: /cost|daywork/i,
    reference: FlowsiteTemplateReference.DashpivotPowerBiExcelCostDayworkReport,
    bannerKey: BannerKeys.FlowsitePowerBiExcelTemplates,
  },
  {
    regex: /csv-exports-for-flowsite-referral/i,
    reference: FlowsiteTemplateReference.DashpivotCSVExports,
    bannerKey: BannerKeys.FlowsiteCSVExports,
  },
  {
    regex: /pdf-exports-for-flowsite-referral/i,
    reference: FlowsiteTemplateReference.DashpivotPDFExports,
    bannerKey: BannerKeys.FlowsitePDFExports,
  },
];
