import { Directive, ElementRef, HostListener, AfterViewChecked } from '@angular/core';
import { debounce } from 'lodash-es';

@Directive({
  selector: '[ccScrollTab]',
  exportAs: 'ccScrollTab',
})
export class ScrollTabDirective implements AfterViewChecked {
  scrollUnit = 0;
  isOverflow = false;
  canScrollStart = false;
  canScrollEnd = false;
  prevScrollWidth = 0;
  debCalculateOverflow: () => void;

  constructor(private readonly elementRef: ElementRef) {
    this.debCalculateOverflow = debounce(this.calculateOverflow, 50);
  }

  private get element() {
    return this.elementRef.nativeElement;
  }

  ngAfterViewChecked() {
    if (this.prevScrollWidth !== this.element.scrollWidth) {
      this.prevScrollWidth = this.element.scrollWidth;
      this.debCalculateOverflow();
    }
  }

  calculateOverflow() {
    const scrollLeft = Math.ceil(this.element.scrollLeft);
    this.scrollUnit = this.element.clientWidth / 1.8;
    this.isOverflow = this.element.scrollWidth > this.element.clientWidth;
    this.canScrollStart = scrollLeft > 0;
    this.canScrollEnd = this.element.scrollWidth > scrollLeft + this.element.clientWidth;
  }

  scroll(direction: number) {
    this.element.scrollLeft += this.scrollUnit * direction;
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.debCalculateOverflow();
  }

  @HostListener('scroll', ['$event'])
  onElementScroll() {
    this.debCalculateOverflow();
  }
}
