import json from './config.json';

export const environment = {
  stage: json['env:NODE_ENV'],
  production: json['env:NODE_ENV'] === 'production',
  environmentIdentifier:
    json['env:NODE_ENV'] !== 'production'
      ? `${json['env:NODE_ENV'].charAt(0).toUpperCase()}${json['env:NODE_ENV'].substring(1)} - `
      : '',
  apiUrl: json['env:API_BASE_URL'].replace(/\/$/, '').concat('/'), // standardizes trailing slash
  uiUrl: json['env:UI_BASE_URL'],
  apiClientToken: json['secret:api-client-token'],
  googleMapsKey: json['secret:google-maps-api-key'],
  reCaptchaSiteKey: json['secret:recaptcha-site-key'],
  reCaptchaV3SiteKey: json['secret:recaptcha-site-key-v3'],
  bugsnagKey: json['secret:bugsnag-web-key'],
  bugsnagReleaseStage: json['env:NODE_ENV'],
  mixPanelToken: json['secret:mixpanel-token'],
  intercomAppId: json['secret:intercom-app-id'],
  region: json['env:AWS_REGION'],
  vitally: {
    key: json['secret:vitally-key'],
    productName: 'Dashpivot',
    nps: true,
    delay: 1000,
  },
  segment: {
    key: json['secret:segment-web-key'],
    logActionsOnConsole: json['env:NODE_ENV'] === 'local',
    trackPages: false,
  },
  chargebee: {
    link: 'https://sitemate-test.chargebee.com/hosted_pages/plans/',
    plans: {
      starterMonthlyUSD: 'starter-monthly-usd',
      starterAnnualUSD: 'starter-annual-usd',
      standardMonthlyUSD: 'standard-monthly-usd',
      standardAnnualUSD: 'standard-annual-usd',
    },
  },
  sitemate: {
    apiUrl: json['secret:sitemate-api-url'],
  },
  flowsite: {
    uiUrl: json['env:FLOWSITE_UI_URL'],
  },
  global: {
    apiUrl: json['secret:global-api-url'],
  },
  fusionAuth: {
    authority: json.fusionAuthAuthority,
    clientId: json['secret:fusionauth-dp-application-id'],
    logLevel: json['env:NODE_ENV'] === 'local' ? 0 : 3, // None: 0, Debug: 1, Warn: 2, Error: 3
  },
  featureToggles: {
    tableInversion: true,
    reCaptchaV2: true,
    reCaptchaV3: true,
    loginWithFusionAuth: true,
    approvalSignatureLogic: true,
    subdomainRedirectionAfterSignup: false,
    newListImport: json['env:NODE_ENV'] !== 'production',
    complementarySignatureLogic: true,
    flowsiteReferralBanner: true,
    nestedComplementarySignatureLogic: true,
    locationTableCell: json['env:NODE_ENV'] !== 'production',
  },
  launchDarklyClientId: json['secret:launch-darkly-client-id'],
  googleTagManagerId: json['secret:google-tag-manager-id'],
  survicate: {
    key: json['secret:survicate-key'],
  },
};
