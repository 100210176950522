import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

import { AppUtilService } from 'app/shared/service/app-util.service';

@Directive({
  selector: '[ccScrollInputFocus]',
})
export class ScrollInputFocusDirective implements OnInit {
  @Input()
  ccScrollInputFocus;

  parent;
  ele;
  OFF_SET = 105;

  constructor(
    private readonly appUtilService: AppUtilService,
    private readonly eleRef: ElementRef,
  ) {
    this.ele = this.eleRef.nativeElement;
  }

  ngOnInit(): void {
    this.parent = document.querySelector(this.ccScrollInputFocus);
  }

  @HostListener('focus')
  onFocus() {
    try {
      if (this.appUtilService.isMobile && !this.appUtilService.isIOS && this.appUtilService.isSmallWindow) {
        const inputOffsetTop = this.ele.getBoundingClientRect().top;
        this.parent.scrollTop = this.parent.scrollTop + inputOffsetTop - this.OFF_SET;
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
}
