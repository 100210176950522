/* eslint-disable prettier/prettier */
import {
  FormRoles,
  SignatureLogicTypes,
  LogicOperators,
  UserTypes,
  SignatureLogicActions,
  UserPermissionTypes,
  IField,
  FieldKinds,
} from '@site-mate/dashpivot-shared-library';
import { CompanyPlanTypes } from '@site-mate/sitemate-global-shared';

export interface DropdownOptionData {
  label: string;
  value: string | { fieldId: string } | { formRole: FormRoles };
  disabled?: boolean;
  badgeText?: string;
  icon?: string;
}

export enum DropdownNames {
  Type = 'type',
  Operator = 'operator',
  Values = 'values',
  Action = 'action',
}

export enum ComplementaryDropdownNames {
  Type = 'type',
  Operator = 'operator',
  Values = 'values',
}

export const LogicTypeToDropdownMap: Readonly<Record<SignatureLogicTypes, DropdownOptionData>> = {
  [SignatureLogicTypes.Permission]: {
    label: 'Permission Type',
    value: SignatureLogicTypes.Permission,
  },
  [SignatureLogicTypes.Person]: {
    label: 'Person',
    value: SignatureLogicTypes.Person,
  },
  [SignatureLogicTypes.User]: {
    label: 'User Type',
    value: SignatureLogicTypes.User,
  },
};

export const TypesDropdowns: ReadonlyArray<Readonly<DropdownOptionData>> = [
  LogicTypeToDropdownMap[SignatureLogicTypes.Permission],
  LogicTypeToDropdownMap[SignatureLogicTypes.Person],
  LogicTypeToDropdownMap[SignatureLogicTypes.User],
];

export const LogicOperatorToDropdownMap: Readonly<Record<LogicOperators, DropdownOptionData>> = {
  [LogicOperators.Equals]: {
    label: 'Equal to',
    value: LogicOperators.Equals,
  },
  [LogicOperators.EqualsToOrHigherThan]: {
    label: 'Equals to or higher than',
    value: LogicOperators.EqualsToOrHigherThan,
  },
  [LogicOperators.DoesNotEqual]: {
    label: 'Does not equal',
    value: LogicOperators.DoesNotEqual,
  },
};

export const UserTypeOperatorsDropdown: ReadonlyArray<Readonly<DropdownOptionData>> = [
  LogicOperatorToDropdownMap[LogicOperators.Equals],
];

export const UserPermissionTypeOperatorsDropdown: ReadonlyArray<Readonly<DropdownOptionData>> = [
  LogicOperatorToDropdownMap[LogicOperators.Equals],
  LogicOperatorToDropdownMap[LogicOperators.EqualsToOrHigherThan],
];

export const UserTypeValuesToDropdownMap: Readonly<Record<UserTypes, DropdownOptionData>> = {
  [UserTypes.Contributor]: {
    label: 'Contributor',
    value: UserTypes.Contributor,
  },
  [UserTypes.Visitor]: {
    label: 'Visitor',
    value: UserTypes.Visitor,
  },
  [UserTypes.Viewer]: undefined,
};

export const UserTypeValuesDropdown: ReadonlyArray<Readonly<DropdownOptionData>> = [
  UserTypeValuesToDropdownMap[UserTypes.Contributor],
  UserTypeValuesToDropdownMap[UserTypes.Visitor],
];

export const UserPermissionValuesToDropdownMap: Readonly<Record<UserPermissionTypes, DropdownOptionData>> = {
  [UserPermissionTypes.TeamMember]: {
    label: 'Team member',
    value: UserPermissionTypes.TeamMember,
  },
  [UserPermissionTypes.TeamController]: {
    label: 'Team controller',
    value: UserPermissionTypes.TeamController,
  },
  [UserPermissionTypes.ProjectMember]: {
    label: 'Project member',
    value: UserPermissionTypes.ProjectMember,
  },
  [UserPermissionTypes.ProjectController]: {
    label: 'Project controller',
    value: UserPermissionTypes.ProjectController,
  },
  [UserPermissionTypes.CompanyMember]: {
    label: 'Organisation member',
    value: UserPermissionTypes.CompanyMember,
  },
  [UserPermissionTypes.CompanyController]: {
    label: 'Organisation controller',
    value: UserPermissionTypes.CompanyController,
  },
  [UserPermissionTypes.GlobalAdmin]: undefined,
};

export const UserPermissionValuesDropdown: ReadonlyArray<Readonly<DropdownOptionData>> = [
  UserPermissionValuesToDropdownMap[UserPermissionTypes.TeamMember],
  UserPermissionValuesToDropdownMap[UserPermissionTypes.TeamController],
  UserPermissionValuesToDropdownMap[UserPermissionTypes.ProjectMember],
  UserPermissionValuesToDropdownMap[UserPermissionTypes.ProjectController],
  UserPermissionValuesToDropdownMap[UserPermissionTypes.CompanyMember],
  UserPermissionValuesToDropdownMap[UserPermissionTypes.CompanyController],
];

export const PersonValuesToDropdownMap: Readonly<Record<FormRoles, DropdownOptionData>> = {
  [FormRoles.Creator]: {
    label: 'Creator',
    value: FormRoles.Creator,
    icon: 'cc-icon-zap',
  },
};

export const PersonValuesDropdown: ReadonlyArray<Readonly<DropdownOptionData>> = [
  PersonValuesToDropdownMap[FormRoles.Creator],
];

type DropdownOptionKeys<T extends SignatureLogicTypes> = T extends SignatureLogicTypes.User
  ? UserTypes
  : T extends SignatureLogicTypes.Person
    ? FormRoles
    : T extends SignatureLogicTypes.Permission
      ? UserPermissionTypes
      : never;

export const TypesToValuesMap: {
  [K in SignatureLogicTypes]: Record<DropdownOptionKeys<K>, DropdownOptionData>;
} = {
  [SignatureLogicTypes.User]: UserTypeValuesToDropdownMap,
  [SignatureLogicTypes.Person]: PersonValuesToDropdownMap,
  [SignatureLogicTypes.Permission]: UserPermissionValuesToDropdownMap,
};

export const ActionsDropdown: ReadonlyArray<Readonly<DropdownOptionData>> = [
  {
    label: 'Approval Sign',
    value: SignatureLogicActions.ApprovalSignature,
  },
  {
    label: 'Workflow Reset',
    value: SignatureLogicActions.ResetWorkflow,
  },
  {
    label: 'Approval Sign and Workflow Reset',
    value: SignatureLogicActions.ApprovalSignatureAndResetWorkflow,
  },
];

export const DefaultPlanMaxComplementaryLogicRules = 3;
export const PremiumPlanMaxComplementaryLogicRules = 6;
export const MaximumPlanMaxComplementaryLogicRules = 12;

export const PlanMaxComplementaryLogicRules: Readonly<Record<CompanyPlanTypes, number>> = {
  [CompanyPlanTypes.Pro]: DefaultPlanMaxComplementaryLogicRules,
  [CompanyPlanTypes.Legacy]: DefaultPlanMaxComplementaryLogicRules,
  [CompanyPlanTypes.Standard]: DefaultPlanMaxComplementaryLogicRules,
  [CompanyPlanTypes.Premium]: PremiumPlanMaxComplementaryLogicRules,
  [CompanyPlanTypes.Platinum]: MaximumPlanMaxComplementaryLogicRules,
  [CompanyPlanTypes.Enterprise]: MaximumPlanMaxComplementaryLogicRules,
  [CompanyPlanTypes.FreeTrial]: MaximumPlanMaxComplementaryLogicRules,
};

function getPersonFieldOptions(fields: IField[]) {
  return fields
    .filter((field) => field.kind === FieldKinds.Person)
    .map((field) => ({
      label: field.description,
      value: { fieldId: field._id },
      icon: 'cc-icon-user',
    }));
}

export function getTypesToOperatorAndValuesMap(fields: IField[]): Readonly<{
  [key in SignatureLogicTypes]: {
    readonly operators: ReadonlyArray<Readonly<DropdownOptionData>>;
    readonly values: ReadonlyArray<Readonly<DropdownOptionData>>;
  };
}> {
  const personFieldOptions = getPersonFieldOptions(fields);
  const personValuesDropdown = [
    {
      label: 'Creator',
      value: FormRoles.Creator,
      icon: 'cc-icon-zap',
    },
    ...personFieldOptions,
  ];

  return {
    [SignatureLogicTypes.User]: {
      operators: UserTypeOperatorsDropdown,
      values: UserTypeValuesDropdown,
    },
    [SignatureLogicTypes.Person]: {
      operators: UserTypeOperatorsDropdown,
      values: personValuesDropdown,
    },
    [SignatureLogicTypes.Permission]: {
      operators: UserPermissionTypeOperatorsDropdown,
      values: UserPermissionValuesDropdown,
    },
  };
}

function filterOptions(option: DropdownOptionData, controls: IField[]) {
  if (option.value === 'creator') {
    return true;
  }
  if (typeof option.value === 'object' && 'fieldId' in option.value) {
    const value = option.value.fieldId;
    return controls.some((field) => field._id === value);
  }
  return true;
}

function mapOptions(
  option: DropdownOptionData,
  complementaryValuesDropdown: readonly Readonly<DropdownOptionData>[],
) {
  const mappedItem = complementaryValuesDropdown.find((item) => {
    if (
      typeof option.value === 'object' &&
      typeof item.value === 'object' &&
      'fieldId' in item.value &&
      'fieldId' in option.value
    ) {
      return item.value.fieldId === option.value.fieldId;
    }
    return option.value === item.value;
  });
  return {
    label: mappedItem?.label,
    value: option.value,
  };
}

export function updateSignatureValues(
  complementaryValues: DropdownOptionData | DropdownOptionData[],
  complementaryValuesDropdown: readonly Readonly<DropdownOptionData>[],
  controls: IField[],
) {
  let returnedValues = complementaryValues;
  if (Array.isArray(complementaryValues)) {
    returnedValues = complementaryValues
      .filter((option) => filterOptions(option, controls))
      .map((option) => mapOptions(option, complementaryValuesDropdown));
  }

  return returnedValues;
}

export const LimitUpgradablePlans = [CompanyPlanTypes.Pro, CompanyPlanTypes.Premium];
