/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { Parser } from 'hot-formula-parser';
import moment from 'moment-timezone';

import {
  DashpivotEvent,
  EventNotifierService,
  EventTypes,
  Field,
  FieldKinds,
  TableCellKinds,
  TableRow,
} from '@site-mate/dashpivot-shared-library';

import { FormDateUtil } from 'app/form/form-date-util.service';
import { SegmentService } from 'app/segment/segment.service';
import { FormulaResponse } from 'app/shared/model/formula.model';
import { FieldWeb } from 'app/shared/model/item.model';
import { FormulaVerificationService } from 'app/shared/service/formula-verification.service';

import { FormulaEngineService } from './formula-engine.service';
import { Positions } from '../model/positions.enum';

interface Cell {
  label: string;
  column: { index: number };
  row: { index: number };
}

@Injectable({
  providedIn: 'root',
})
export class HotFormulaParserEngineService implements FormulaEngineService {
  constructor(
    private readonly formulaVerificationService: FormulaVerificationService,
    private readonly segmentService: SegmentService,
  ) {}

  calculateSheetContent({ table, tableHasFormulas }: { table: FieldWeb; tableHasFormulas?: boolean }) {
    if (!tableHasFormulas) {
      return;
    }

    if (table.kind === FieldKinds.PrefilledTable) {
      this.calculatePrefilledTable(table);
    } else if (table.kind === FieldKinds.Table) {
      this.calculateTable(table);
    }
  }

  initSheetContent({ table, tableHasFormulas }: { table: FieldWeb; tableHasFormulas?: boolean }) {
    this.calculateSheetContent({ table, tableHasFormulas });
  }

  setCellContent({ table, tableHasFormulas }: { table: FieldWeb; tableHasFormulas?: boolean }) {
    this.calculateSheetContent({ table, tableHasFormulas });
  }

  // Irrelevant method, just to comply to interface
  setValuesUpdateListener() {}

  // Irrelevant method, just to comply to interface
  destroy(): void {}

  // Irrelevant method, just to comply to interface
  init() {}

  // Irrelevant method, just to comply to interface
  addSheet(): void {}

  // Irrelevant method, just to comply to interface
  getSheet(): number {
    return 1;
  }

  // Irrelevant method, just to comply to interface
  clearSheet(): void {}

  // Irrelevant method, just to comply to interface
  setEmptySheetContent(): void {}

  // ! Code below is mostly legacy - tread carefully

  isFormulaKind(kind: TableCellKinds | FieldKinds) {
    return [
      TableCellKinds.Time,
      TableCellKinds.Date,
      TableCellKinds.Number,
      TableCellKinds.Formula,
      FieldKinds.Table,
      FieldKinds.PrefilledTable,
    ].includes(kind);
  }

  hasFormulaColumn(model: FieldWeb): boolean {
    const isPrefilledTable = model.kind === FieldKinds.PrefilledTable;

    return this.columnIncludes(model, isPrefilledTable, (col) => col.kind === TableCellKinds.Formula);
  }

  hasFormulaInputColumn(model: FieldWeb, isPrefilledTable = false): boolean {
    return this.columnIncludes(model, isPrefilledTable, (col) => this.isFormulaKind(col.kind));
  }

  updateFormulasAndFieldLabels(fields: Field[]) {
    let formulaRowNumber = 1;
    fields.forEach((field) => {
      formulaRowNumber = this.updateCellReferencesAndLabelField(field, formulaRowNumber);
    });
  }

  verify(model: FieldWeb[]) {
    model.forEach((item) => {
      this.verifyItem(item);
    });
  }

  verifyItem(model: FieldWeb) {
    if (model.kind === FieldKinds.Table) {
      this.verifyTable(model);
    } else if (model.kind === FieldKinds.PrefilledTable) {
      this.verifyPrefilledTable(model);
    }
  }

  calculateTable(model: FieldWeb) {
    // default tables - formula definition are on table column header
    model.columns
      .filter((column) => column.kind === TableCellKinds.Formula)
      .forEach((formulaColumn) => {
        model.rows.forEach((row) => {
          const formulaCell = row.columns.find((r) => r.headerColumnId === formulaColumn.id);
          if (formulaCell) {
            formulaCell.value = this.calculateFormula(
              formulaColumn.formula,
              formulaCell.value,
              [row],
              model.formulaRowNumber,
            );
          }
        });
      });
  }

  calculatePrefilledTable(model: FieldWeb) {
    // prefilled tables - formula definitions are on each row's columns
    model.rows.forEach((row) => {
      row.columns
        .filter((column) => column.kind === TableCellKinds.Formula)
        .forEach((formulaColumn) => {
          formulaColumn.value = this.calculateFormula(
            formulaColumn.formula,
            formulaColumn.value,
            model.rows,
            model.formulaRowNumber,
          );
        });
    });
  }

  updateFormulasOnColumnMove(field: Field, position: Positions.Left | Positions.Right, columnIndex: number) {
    const column1 = position === Positions.Left ? columnIndex - 1 : columnIndex;
    const column2 = position === Positions.Left ? columnIndex : columnIndex + 1;

    const charArray = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const col1Prefix = charArray[column1];
    const col2Prefix = charArray[column2];
    const tempColumnPrefix = 'ZZZZ';

    if (field.kind === FieldKinds.Table) {
      this.moveColumnCellReferences(field.columns, col1Prefix, tempColumnPrefix);
      this.moveColumnCellReferences(field.columns, col2Prefix, col1Prefix);
      this.moveColumnCellReferences(field.columns, tempColumnPrefix, col2Prefix);
    } else {
      field.rows.forEach((row) => {
        this.moveColumnCellReferences(row.columns, col1Prefix, tempColumnPrefix);
        this.moveColumnCellReferences(row.columns, col2Prefix, col1Prefix);
        this.moveColumnCellReferences(row.columns, tempColumnPrefix, col2Prefix);
      });
    }
  }

  updateFormulasOnRowMove(field: Field, position: Positions.Up | Positions.Down, columnIndex: number) {
    const row1 = position === Positions.Up ? columnIndex - 1 : columnIndex;
    const row2 = position === Positions.Up ? columnIndex : columnIndex + 1;

    const row1Number = row1 + field.formulaRowNumber;
    const row2Number = row2 + field.formulaRowNumber;
    const tempRowNumber = 999;

    field.rows.forEach((row) => {
      this.moveRowCellReferences(row.columns, row1Number, tempRowNumber);
      this.moveRowCellReferences(row.columns, row2Number, row1Number);
      this.moveRowCellReferences(row.columns, tempRowNumber, row2Number);
    });
  }

  private updateCellReferencesAndLabelField(field: Field, formulaRowNumber: number) {
    let localFormulaRowNumber = formulaRowNumber;

    if (this.isFormulaKind(field.kind) && this.hasFormulaColumn(field)) {
      this.updateTableCellReferences(field, field.formulaRowNumber, localFormulaRowNumber);

      field.formulaRowNumber = localFormulaRowNumber;

      if (field.kind === FieldKinds.PrefilledTable && field.rows?.length) {
        field.rows.forEach((row) => {
          row.formulaRowNumber = localFormulaRowNumber;
          localFormulaRowNumber += 1;
        });
      } else {
        localFormulaRowNumber += 1;
      }

      return localFormulaRowNumber;
    }

    delete field.formulaRowNumber;
    return formulaRowNumber;
  }

  private moveColumnCellReferences(columns: { formula?: string }[], col1Prefix: string, col2Prefix: string) {
    columns
      .filter((column) => !!column.formula)
      .forEach((column) => {
        // find all references of AXX and rename to BXX
        const cellReferences = column.formula.match(new RegExp(`\\b${col1Prefix}[0-9]{1,3}\\b`, 'gi'));
        cellReferences?.forEach((reference) => {
          column.formula = this.renameCellReferencePrefix(column.formula, reference, col2Prefix);
        });
      });
  }

  private renameCellReferencePrefix(formula: string, cellReference: string, newPrefix: string) {
    const newCellReference = cellReference.replace(/[A-Z]{1,4}/gi, newPrefix);

    return formula.replace(cellReference, newCellReference);
  }

  private moveRowCellReferences(columns: { formula?: string }[], row1: number, row2: number) {
    columns
      .filter((column) => !!column.formula)
      .forEach((column) => {
        // find all references of X1 and rename to X2
        const cellReferences = column.formula.match(new RegExp(`\\b[a-zA-Z]{1,2}${row1}\\b`, 'gi'));
        cellReferences?.forEach((reference) => {
          column.formula = this.renameCellReferenceRowNumber(column.formula, reference, row2);
        });
      });
  }

  private renameCellReferenceRowNumber(formula: string, cellReference: string, newRow: number) {
    // replace the numeric row number with the newRow - eg: A2 > A3, B9 > B10, etc.
    const newCellReference = cellReference.replace(/[0-9]{1,3}/gi, String(newRow));

    return formula.replace(cellReference, newCellReference);
  }

  private updateTableCellReferences(field: Field, formulaRowNumberOld: number, formulaRowNumberNew: number) {
    if (!formulaRowNumberOld || formulaRowNumberNew === formulaRowNumberOld) {
      return;
    }

    const formulaDiff = formulaRowNumberNew - formulaRowNumberOld;

    if (field.kind === FieldKinds.PrefilledTable) {
      field.rows.forEach((row) => {
        this.updateCellReferencePrefix(row.columns, formulaDiff);
      });
    } else {
      this.updateCellReferencePrefix(field.columns, formulaDiff);
    }
  }

  private updateCellReferencePrefix(columns: { formula?: string }[], formulaDiff: number) {
    columns
      .filter((column) => !!column.formula)
      .forEach((column) => {
        // find all cell references inside a formula - eg. A1, B5, C9 etc.
        const cellReferences = column.formula.match(/\b[a-zA-Z]{1,2}[0-9]{1,3}\b/gi);
        cellReferences?.forEach((reference) => {
          column.formula = this.renameCellReferenceNumber(column.formula, reference, formulaDiff);
        });
      });
  }

  private renameCellReferenceNumber(formula: string, cellReference: string, formulaDiff: number) {
    // remove the number from the cell reference - eg. A1 > A, B4 > B etc.
    const formulaRowNumberOld = cellReference.replace(/^\D+/gi, '');
    const formulaRowNumberNew = Number(formulaRowNumberOld) + formulaDiff;

    // replace the cell reference with the new value - eg. A1 > A2, B4 > B1
    return formulaRowNumberNew > 0
      ? formula.replace(
          cellReference,
          cellReference.replace(String(formulaRowNumberOld), String(formulaRowNumberNew)),
        )
      : formula;
  }

  private verifyTable(model: FieldWeb) {
    const blankRow = [
      {
        columns: model.columns.map((c) => {
          return { value: '', headerColumnId: c.id };
        }),
      },
    ];

    model.columns
      .filter((column) => column.kind === TableCellKinds.Formula)
      .forEach((formulaColumn) => {
        formulaColumn.formulaError = this.formulaVerificationService.verify(
          formulaColumn.formula,
          blankRow,
          model.formulaRowNumber,
          this.formulaFullColumnHeader({
            column: formulaColumn,
            columns: model.columns,
            formulaRowNumber: model.formulaRowNumber,
          }),
        );
      });
  }

  private verifyPrefilledTable(model: FieldWeb) {
    if (model.rows) {
      model.rows.forEach((row) => {
        row.columns
          .filter((column) => column.kind === TableCellKinds.Formula)
          .forEach((formulaColumn) => {
            formulaColumn.formulaError = this.formulaVerificationService.verify(
              formulaColumn.formula,
              model.rows,
              model.formulaRowNumber,
              this.formulaFullColumnHeader({
                column: formulaColumn,
                columns: row.columns,
                formulaRowNumber: row.formulaRowNumber,
              }),
            );
          });
      });
    }
  }

  private formulaFullColumnHeader({ column, columns, formulaRowNumber }) {
    const colNumber = columns.findIndex((col) => col.id === column.id && col.heading === column.heading);

    return `${this.getColumnLabel(colNumber)}${formulaRowNumber}`;
  }

  getColumnLabel(colNumber: number): string {
    let columnValue = colNumber;
    let columnLabel = '';
    const alphabetLength = 26;
    while (columnValue >= 0) {
      const remaining = columnValue % alphabetLength;
      const currentChar = String.fromCharCode(65 + remaining);
      columnLabel = currentChar + columnLabel;
      columnValue = Math.floor(columnValue / alphabetLength) - 1;
    }
    return columnLabel;
  }

  // return true if any column in this model passes the testCallback
  private columnIncludes(
    model: FieldWeb,
    isPrefilledTable = false,
    testCallback: (column) => boolean,
  ): boolean {
    if (isPrefilledTable) {
      return model.rows && model.rows.some((row) => row.columns.find((col) => testCallback(col)));
    }

    return model.columns && model.columns.some((col) => testCallback(col));
  }

  private calculateFormula(formula: string, formulaValue: any, rows: TableRow[], formulaRowNumber: number) {
    if (!formula) {
      return '';
    }

    const { result, error } = this.parseRows(formula, rows, formulaRowNumber);
    const value = !error ? result : error; // this would be an ideal use of the new ?? ts feature - as a successful result can be 0 or ''

    if (value !== formulaValue) {
      void EventNotifierService.notify(
        new DashpivotEvent(EventTypes.FormulaCalculationTriggered, { Context: 'Legacy Engine' }),
        this.segmentService,
      );
    }

    return value;
  }

  /**
   * Parse a formula with variables - eg. `A1 * B1 + (C1 / 2)`
   * @param formula formula string
   * @param rows array of values
   */
  private parseRows(formula: string, rows: TableRow[], formulaRowNumber = 1): FormulaResponse {
    const parser = new Parser();
    this.addParserHooks(parser, rows, formulaRowNumber);

    const formulaCleaned =
      formula && formula.trimLeft().startsWith('=') ? formula.substr(formula.indexOf('=') + 1) : formula;

    const { result, error } = parser.parse(formulaCleaned);
    return { result: this.formatResult(result), error: this.formatError(error) };
  }

  /**
   * Parse a formula with embedded values - eg. `10 * 5 + (6 / 2)`
   */
  private parse(formula: string): FormulaResponse {
    return this.parseRows(formula, [], 1);
  }

  private hoursDiff(timeStart: any, timeEnd: any): FormulaResponse {
    // only return a result when both values exist
    if (!timeStart || !timeEnd) {
      return { result: '0' };
    }

    const start = moment(this.parseTime(timeStart));
    const end = moment(this.parseTime(timeEnd));

    // eg. shift starts at 8pm ends at 4am
    if (start > end) {
      start.subtract(1, 'day');
    }

    const hours = moment(end).diff(start, 'hours', true);
    return { result: Math.abs(hours).toFixed(2) };
  }

  private daysDiff(timeStart: any, timeEnd: any): FormulaResponse {
    // only return a result when both values exist
    if (!timeStart || !timeEnd) {
      return { result: '0' };
    }

    const start = moment(this.parseTime(timeStart));
    const end = moment(this.parseTime(timeEnd));

    const days = moment(end).diff(start, 'days', true);
    return { result: Math.abs(days).toFixed(0) };
  }

  private addParserHooks(parser: Parser, rows: TableRow[], formulaRowNumber: number) {
    parser.on('callCellValue', (cell: Cell, done) => {
      // adjust the row index relative to formulaRowNumber - as formulas are relative
      // to the whole template, but rows are only relative to the current table
      // eg. formula=A5*10, if formulaRowNumber=5, then A5 should be A1 for this table
      const rowIndex = cell.row.index - formulaRowNumber + 1; // rows are 1 based, index is 0 based
      const colIndex = cell.column.index;

      if (rows && rowIndex >= 0 && rowIndex < rows.length) {
        if (colIndex < rows[rowIndex].columns.length) {
          if (rows[rowIndex].columns[colIndex]) {
            const { value } = rows[rowIndex].columns[colIndex];
            // either return the input value or return 0 to indicate this cell exists, just doesn't have a value in it yet
            done(this.parseValue(value));
          }
        } else {
          throw Error('#VALUE!');
        }
      } else {
        throw Error('#VALUE!');
      }
    });

    parser.on('callFunction', (name: string, params: any[], done) => {
      if (name.toUpperCase() === 'TIMEDIF' && params.length > 1) {
        const { result } = this.hoursDiff(params[0], params[1]);
        done(result);
      } else if (name.toUpperCase() === 'DATEDIF' && params.length > 1) {
        const { result } = this.daysDiff(params[0], params[1]);
        done(result);
      }
    });
  }

  private parseValue(value: string): number | string {
    const numberValue = Number(value);
    return Number.isFinite(numberValue) ? numberValue : value || 0;
  }

  private parseTime(time: string): string {
    return time || FormDateUtil.convertTimeToUTCString({ hour: 0, minute: 0, second: 0 });
  }

  private formatResult(result: number | null): string | null {
    if (result === null) {
      return '';
    }
    if (typeof result === 'number') {
      return result.toFixed(2);
    }
    return result;
  }

  private formatError(error: string): string {
    if (!error) {
      return null;
    }

    // TODO: not sure what error detail we should pass back to users yet...
    // #DIV/0! Divide by zero error;
    // #N/A Indicates that a value is not available to a formula;
    // #NUM! Occurs when formula encounters an invalid number;

    switch (error) {
      case '#NAME?': // #NAME? Not recognised function name or variable name;
        return '#NAME? Unknown formula or cell';
      case '#ERROR!': // #ERROR! General error;
        return '#ERROR!';
      case '#VALUE!': // #VALUE! Occurs when one of formula arguments is of the wrong type.
        // We get this error whenever there isn't a value (which might be nothing in the cell or an invalid cell)
        return '#VALUE!';
      default:
        return error;
    }
  }
}
