<form #form="ngForm" novalidate (submit)="form.valid && onSubmit()">
  <div class="modal-header hidden-xs-down">
    <h4 class="sm-mb-0 sm-text-xl sm-font-medium">
      <span i18n>Sign manually</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label i18n for="first-name" class="default-label col-form-label col-sm-4 hidden-xs-down"
        >First name</label
      >
      <div [ngClass]="{ 'has-danger': form.submitted && f.invalid }" class="col-sm-8">
        <input
          type="text"
          placeholder="Type first name"
          i18n-placeholder="Type first name"
          class="form-control"
          name="first-name"
          id="first-name"
          required
          #f="ngModel"
          [(ngModel)]="firstName"
          ccFocus
        />
        <div class="sm-text-default-red" *ngIf="form.submitted && f.invalid" i18n>First name is required</div>
      </div>
    </div>

    <div class="form-group row">
      <label i18n for="last-name" class="default-label col-form-label col-sm-4 hidden-xs-down"
        >Last name</label
      >
      <div [ngClass]="{ 'has-danger': form.submitted && l.invalid }" class="col-sm-8">
        <input
          type="text"
          placeholder="Type last name"
          i18n-placeholder="Type last name"
          class="form-control"
          name="last-name"
          id="last-name"
          required
          #l="ngModel"
          [(ngModel)]="lastName"
        />
        <div class="sm-text-default-red" *ngIf="form.submitted && l.invalid" i18n>Last name is required</div>
      </div>
    </div>

    <div class="form-group row">
      <label i18n for="company-name" class="default-label col-form-label col-sm-4 hidden-xs-down"
        >Company name</label
      >
      <div [ngClass]="{ 'has-danger': form.submitted && c.invalid }" class="col-sm-8">
        <input
          type="text"
          placeholder="Type company name"
          i18n-placeholder="Type company name"
          class="form-control"
          name="company-name"
          id="company-name"
          required
          #c="ngModel"
          [(ngModel)]="companyName"
        />
        <div class="sm-text-default-red" *ngIf="form.submitted && c.invalid" i18n>
          Company name is required
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label i18n class="default-label form-control-label col-sm-4 hidden-xs-down">Signature</label>
      <div [ngClass]="{ 'has-danger': form.submitted && sig.invalid }" class="col-sm-8">
        <input
          type="hidden"
          name="signature"
          [(ngModel)]="signatureData"
          required
          name="signature"
          #sig="ngModel"
        />
        <div class="manual-sign-pad-wrapper">
          <cc-signature-pad [options]="signaturePadOptions" (end)="drawComplete($event)"></cc-signature-pad>
          <button
            type="button"
            class="clear-btn btn btn-secondary btn-sm"
            title="Clear"
            i18n-title="Clear"
            (click)="clearSignature()"
          >
            <i class="fa fa-undo"></i>
          </button>
        </div>
        <div class="sm-text-default-red" i18n *ngIf="form.submitted && sig && sig.invalid">
          Signature is required
        </div>
      </div>
    </div>

    <div class="form-group text-center">
      <button type="submit" class="btn btn-success" i18n>Sign</button>
      <a class="text-link hidden-sm-up ml-legacy-2.4" i18n (click)="modal.dismiss()">Cancel</a>
    </div>
  </div>
</form>
