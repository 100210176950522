import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { IntercomService } from 'app/intercom/intercom.service';

@Component({
  selector: 'cc-account-upgrade-limit',
  templateUrl: './account-upgrade-limit.component.html',
})
export class AccountUpgradeLimitComponent {
  title: string;
  subtitle: string;
  imageSrc: string;
  imageAlt: string;
  hasInnerHtmlSubtitle: boolean;
  showLoadingSpinner: boolean;
  placeHolderHeight: number;

  constructor(
    private readonly intercomService: IntercomService,
    public readonly activeModal: NgbActiveModal,
  ) {}

  openLiveChat() {
    this.intercomService.open();
  }
}
