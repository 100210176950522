import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, combineLatest, first, forkJoin, map, of, throwError, Subject } from 'rxjs';

import { FolderKinds, IListRow, ItemStates, List, PathService } from '@site-mate/dashpivot-shared-library';

import { AppHierarchy } from 'app/shared/model/app-hierarchy.enum';
import { ExportResponse } from 'app/shared/model/export-response.model';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { HttpClientService } from 'app/shared/service/http-client.service';
import { RouteParamsService } from 'app/shared/service/route-params.service';
import { UserService } from 'app/user/user.service';

export interface IListDownloadParams {
  hierarchy: AppHierarchy | string;
  [key: string]: string | string[];
}

export enum ListFormat {
  Thumbnail = 'thumbnail',
}

@Injectable({ providedIn: 'root' })
export class ListsService {
  private refreshListSubject = new Subject<void>();
  refreshList$ = this.refreshListSubject.asObservable();

  constructor(
    private readonly httpClientService: HttpClientService,
    private readonly pathService: PathService,
    private readonly errorHandler: ErrorHandler,
    private readonly routeParamsService: RouteParamsService,
    private readonly userService: UserService,
    private readonly router: Router,
  ) {}

  getList(listId: string) {
    return this.httpClientService.get<List>(`v1/lists/${listId}`);
  }

  getMetaDataList(folder: FolderKinds, id, state = ItemStates.Active, view?: ListFormat) {
    const folderMapping = {
      [FolderKinds.Company]: 'companies',
      [FolderKinds.Project]: 'projects',
    };

    const url = `v1/${folderMapping[folder]}/${id}/lists?`;

    return this.httpClientService.get<List[]>(url, { state, view });
  }

  getCompanyListNames(companyId: string) {
    return this.httpClientService.get<List[]>(`v1/companies/${companyId}/lists?onlyNames=true`);
  }

  getProjectListNames(projectId: string) {
    return this.httpClientService.get<List[]>(`v1/projects/${projectId}/lists?onlyNames=true`);
  }

  downloadAsCSV(listId: string, params: IListDownloadParams) {
    const folderMapping = { [AppHierarchy.Organisation]: 'companies', [AppHierarchy.Project]: 'projects' };
    const { hierarchy, parentId, ids } = params;
    const folderLevel = folderMapping[hierarchy];
    const URL = `${folderLevel}/${parentId}/lists/${listId}/export-csv`;
    return this.httpClientService.post<ExportResponse>(URL, { itemIds: ids });
  }

  getListsByIds(listId: string[]) {
    return forkJoin(
      listId.map((id) => this.getList(id).pipe(catchError((error) => this.manageListByIdsError(error)))),
    ).pipe(map((lists) => lists.filter(Boolean)));
  }

  private manageListByIdsError(error) {
    const NOT_FOUND_ERROR = 404;
    const UNAUTHORIZED_ERROR = 401;

    if ([NOT_FOUND_ERROR, UNAUTHORIZED_ERROR].includes(error.status)) {
      return of(null);
    }

    return throwError(() => new Error(error.message));
  }

  // We want to move towards the unified vision of global items and folders, and away from the notion of "projects" and "teams"
  // So, whenever possible, we want to expose a single point where we still talk with the old model, so it's easier to migrate
  saveFolderList(path: string, listName: string) {
    const folderIds = this.pathService.extractFolderIds(path);
    const newList = { name: listName, items: [] };

    if (!folderIds[0]) {
      this.debugContext(path, folderIds);
    }

    if (folderIds.length === 1) {
      return this.saveCompanyList(folderIds[0], newList);
    }

    if (!folderIds[folderIds.length - 1]) {
      this.debugContext(path, folderIds);
    }

    return this.saveProjectList(folderIds[folderIds.length - 1], newList);
  }

  private saveProjectList(projectId, list) {
    return this.httpClientService.post(`v1/projects/${projectId}/lists`, list);
  }

  private saveCompanyList(companyId, list) {
    return this.httpClientService.post(`v1/companies/${companyId}/lists`, list);
  }

  private debugContext(path: string, folderIds: string[]) {
    combineLatest([
      this.routeParamsService.get('company'),
      this.routeParamsService.get('project'),
      this.routeParamsService.get('team'),
      this.userService.currentUser,
    ])
      .pipe(first())
      .subscribe(([company, project, team, user]) => {
        this.errorHandler.handleForDebug(new Error('Missing projectId for creating new list'), {
          routeParamsCompany: company,
          routeParamsProject: project,
          routeParamsTeam: team,
          userServiceCurrentUser: user,
          currentUrl: this.router.url,
          path,
          folderIds,
        });
      });
  }

  updateProjectList(projectId, listId, list) {
    return this.httpClientService.put(`v1/projects/${projectId}/lists/${listId}`, list);
  }

  updateCompanyList(companyId, listId, list) {
    return this.httpClientService.put(`v1/companies/${companyId}/lists/${listId}`, list);
  }

  updateGlobalItemProjectList(projectId, listId, updateValues) {
    return this.httpClientService.put(`projects/${projectId}/lists/${listId}`, updateValues);
  }

  updateGlobalItemCompanyList(companyId, listId, updateValues) {
    return this.httpClientService.put(`companies/${companyId}/lists/${listId}`, updateValues);
  }

  updateList(folder, listId, updateValues): Observable<List> {
    const folderMapping = {
      [AppHierarchy.Organisation]: () =>
        this.updateGlobalItemCompanyList(folder.company.id, listId, updateValues),
      [AppHierarchy.Project]: () => this.updateGlobalItemProjectList(folder.project.id, listId, updateValues),
    };

    return folderMapping[folder.hierarchy]();
  }

  archiveLists(parentId: string, listIds: string[], folderKind: FolderKinds) {
    const hierarchy = folderKind === FolderKinds.Company ? AppHierarchy.Organisation : AppHierarchy.Project;

    const observableUpdates = listIds.map((listId) =>
      this.updateList({ [folderKind]: { id: parentId }, hierarchy }, listId, { state: ItemStates.Archived }),
    );

    return forkJoin(observableUpdates);
  }

  restoreLists(parentId: string, listIds: string[], folderKind: FolderKinds) {
    const hierarchy = folderKind === FolderKinds.Company ? AppHierarchy.Organisation : AppHierarchy.Project;

    const observableUpdates = listIds.map((listId) =>
      this.updateList({ [folderKind]: { id: parentId }, hierarchy }, listId, { state: ItemStates.Active }),
    );

    return forkJoin(observableUpdates);
  }

  deleteLists(parentId: string, listIds: string[], folderKind: FolderKinds) {
    const hierarchy = folderKind === FolderKinds.Company ? AppHierarchy.Organisation : AppHierarchy.Project;

    const observableUpdates = listIds.map((listId) =>
      this.updateList({ [folderKind]: { id: parentId }, hierarchy }, listId, { state: ItemStates.Deleted }),
    );

    return forkJoin(observableUpdates);
  }

  uploadUpdateProjectList(projectId, listId, formData) {
    return this.httpClientService.putUpload(
      `projects/${projectId}/lists/${listId}/import-csv-file`,
      formData,
    );
  }

  uploadUpdateCompanyList(companyId: string, listId: string, formData) {
    return this.httpClientService.putUpload(
      `companies/${companyId}/lists/${listId}/import-csv-file`,
      formData,
    );
  }

  getListForLibraryTemplate(templateId: string) {
    return this.httpClientService.get(`template-libraries/${templateId}/meta-lists`);
  }

  refreshList() {
    this.refreshListSubject.next(null);
  }

  sortListRows(firstListRow: IListRow, secondListRow: IListRow) {
    return firstListRow?.data?.value ? firstListRow.data.value.localeCompare(secondListRow.data?.value) : 0;
  }
}
