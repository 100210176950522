<cc-counter
  [total]="totalLimit"
  [count]="itemsCount"
  [isPopover]="true"
  [stylingChangesOnHover]="stylingChangesOnHover"
  [title]="title"
  popoverPlacement="bottom-left"
>
  <ng-container *ngTemplateOutlet="isUpgradeAvailable ? upgradePlan : maxPlan"></ng-container>
</cc-counter>

<ng-template #upgradePlan i18n>
  The total number of {{ modalParam }} you can add into your {{ context }} is based on your subscription plan.
  <button type="button" class="upgrade-button" (click)="openUpgradeModal()">Upgrade your plan</button>
  to unlock more.
</ng-template>

<ng-template #maxPlan i18n>
  The total number of {{ modalParam }} you can add into your {{ context }} is based on your subscription plan.
  Your plan supports the maximum number of {{ modalParam }} in Dashpivot.
</ng-template>
