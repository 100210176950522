"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OPERATOR_FORMULAS = exports.OPERATOR_NOT_SUPPORTED_FORMULAS = exports.OPERATOR_SUPPORTED_FORMULAS = exports.OPERATOR_SUPPORTED_FORMULAS_WITH_INFO = void 0;
exports.OPERATOR_SUPPORTED_FORMULAS_WITH_INFO = new Map([
    ['HF.ADD', { syntax: 'HF.ADD(Number, Number)', description: 'Adds two values.' }],
    ['HF.CONCAT', { syntax: 'HF.CONCAT(String, String)', description: 'Concatenates two strings.' }],
    ['HF.DIVIDE', { syntax: 'HF.DIVIDE(Number, Number)', description: 'Divides two values.' }],
    ['HF.EQ', { syntax: 'HF.EQ(Value, Value)', description: 'Tests two values for equality.' }],
    ['HF.LTE', { syntax: 'HF.LEQ(Value, Value)', description: 'Tests two values for less-equal relation.' }],
    ['HF.LT', { syntax: 'HF.LT(Value, Value)', description: 'Tests two values for less-than relation.' }],
    ['HF.GTE', { syntax: 'HF.GEQ(Value, Value)', description: 'Tests two values for greater-equal relation.' }],
    ['HF.GT', { syntax: 'HF.GT(Value, Value)', description: 'Tests two values for greater-than relation.' }],
    ['HF.MINUS', { syntax: 'HF.MINUS(Number, Number)', description: 'Subtracts two values.' }],
    ['HF.MULTIPLY', { syntax: 'HF.MULTIPLY(Number, Number)', description: 'Multiplies two values.' }],
    ['HF.NE', { syntax: 'HF.NE(Value, Value)', description: 'Tests two values for inequality.' }],
    ['HF.POW', { syntax: 'HF.POW(Number, Number)', description: 'Computes power of two values.' }],
    ['HF.UMINUS', { syntax: 'HF.UMINUS(Number)', description: 'Negates the value.' }],
    ['HF.UNARY_PERCENT', { syntax: 'HF.UNARY_PERCENT(Number)', description: 'Applies percent operator.' }],
    ['HF.UPLUS', { syntax: 'HF.UPLUS(Number)', description: 'Applies unary plus.' }],
]);
exports.OPERATOR_SUPPORTED_FORMULAS = [...exports.OPERATOR_SUPPORTED_FORMULAS_WITH_INFO.keys()];
exports.OPERATOR_NOT_SUPPORTED_FORMULAS = [];
exports.OPERATOR_FORMULAS = exports.OPERATOR_SUPPORTED_FORMULAS.concat(exports.OPERATOR_NOT_SUPPORTED_FORMULAS);
