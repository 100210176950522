import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { UserService } from './user.service';
import { TmpI18NService } from '../i18n/tmp-i18n.service';
import { ConfirmService } from '../shared/service/confirm.service';

@Injectable()
export class ProfileService {
  constructor(
    private modal: NgbModal,
    private userService: UserService,
    private confirmService: ConfirmService,
    private i18nService: TmpI18NService,
  ) {}

  hasProfileSignature() {
    const user = this.userService.getCurrentUser();
    if (!user.signature) {
      this.confirmService
        .confirm('setupSignatureProfile', {
          confirmButtonText: this.i18nService.getMessage('confirmYes'),
          cancelButtonText: this.i18nService.getMessage('confirmNo'),
        })
        .then(() => {
          void this.showEditProfile();
          return true;
        })
        .catch(() => {});
      return false;
    }
    return true;
  }

  showEditProfile() {
    const modalRef = this.modal.open(EditProfileComponent, {
      size: 'lg',
      backdrop: true,
    });
    return modalRef.result;
  }
}
