import { EventEmitter, Input, OnChanges, Output, SimpleChanges, Directive } from '@angular/core';

@Directive()
// tslint:disable-next-line: directive-class-suffix
export class PhotoModalToolBaseComponent implements OnChanges {
  @Input()
  photo;

  @Output()
  magnifyEvent = new EventEmitter();

  @Input()
  magnify;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.photo &&
      changes.photo.previousValue &&
      !changes.photo.previousValue.isVideo &&
      changes.photo.currentValue &&
      changes.photo.currentValue.isVideo
    ) {
      if (this.magnify) {
        this.magnify = false;
      }
    }
  }

  toggleMagnify() {
    if (!this.photo.isVideo) {
      this.magnify = !this.magnify;
      this.magnifyEvent.emit({ magnify: this.magnify });
    }
  }
}
