import { Directive, HostListener, Inject } from '@angular/core';

import { DashpivotEvent, EventNotifierService, EventTypes } from '@site-mate/dashpivot-shared-library';

import { SegmentService } from 'app/segment/segment.service';
import { WINDOW } from 'app/shared/factory/window.factory';

enum ArrowDirection {
  Up = 'Up',
  Left = 'Left',
  Right = 'Right',
  Down = 'Down',
}
enum FocusAbleFieldType {
  Editable = '[contenteditable=true]',
  Input = 'input',
  Select = 'select',
  Button = 'button',
}
export enum KeyboardEventKey {
  ArrowUp = 'Arrow Up',
  ArrowDown = 'Arrow Down',
  ArrowLeft = 'Arrow Left',
  ArrowRight = 'Arrow Right',
  Tab = 'Tab',
  ShiftTab = 'Shift Tab',
}

const getAllFocusAbleField = () => {
  return Object.keys(FocusAbleFieldType).map((key) => FocusAbleFieldType[key]);
};
@Directive({
  selector: '[ccFormTableNavigation]',
})
export class FormTableNavigationDirective {
  constructor(
    @Inject(WINDOW) private readonly window: Window,
    private readonly segmentService: SegmentService,
  ) {}

  @HostListener('keydown.arrowright', ['$event'])
  arrowRightListener(event: KeyboardEvent) {
    this.onArrowRight(event);
    this.tableKeyboardShortcutUsedEvent(KeyboardEventKey.ArrowRight);
  }

  @HostListener('keydown.arrowleft', ['$event'])
  arrowLeftListener(event: KeyboardEvent) {
    this.onArrowLeft(event);
    this.tableKeyboardShortcutUsedEvent(KeyboardEventKey.ArrowLeft);
  }

  @HostListener('keydown.arrowup', ['$event'])
  arrowUpListener(event: KeyboardEvent) {
    this.onArrowUp(event);
    this.tableKeyboardShortcutUsedEvent(KeyboardEventKey.ArrowUp);
  }

  @HostListener('keydown.arrowdown', ['$event'])
  arrowDownListener(event: KeyboardEvent) {
    this.onArrowDown(event);
    this.tableKeyboardShortcutUsedEvent(KeyboardEventKey.ArrowDown);
  }

  @HostListener('keydown.tab', ['$event'])
  tabKeyListener() {
    this.tableKeyboardShortcutUsedEvent(KeyboardEventKey.Tab);
  }

  @HostListener('keydown.shift.tab', ['$event'])
  shiftTabListener() {
    this.tableKeyboardShortcutUsedEvent(KeyboardEventKey.ShiftTab);
  }

  onArrowLeft(event) {
    const element = event.target as HTMLElement;
    const nextElement = this.getNextElementHorizontally(element, ArrowDirection.Left);

    if (nextElement && !this.isSameElement(element, nextElement)) {
      event.preventDefault();

      nextElement.focus();
    }
  }

  onArrowRight(event: KeyboardEvent) {
    const element = event.target as HTMLElement;
    const nextElement = this.getNextElementHorizontally(element, ArrowDirection.Right);
    if (nextElement && !this.isSameElement(element, nextElement)) {
      event.preventDefault();

      nextElement.focus();
    }
  }

  onArrowUp(event: KeyboardEvent) {
    const element = event.target as HTMLElement;
    const canMove = this.canMoveToNextRow(element);
    if (!canMove) {
      return;
    }

    const nextElement = this.getNextElementVertically(element, ArrowDirection.Up);

    if (nextElement && !this.isSameElement(element, nextElement)) {
      event.preventDefault();
      nextElement.focus();
    }
  }

  onArrowDown(event: KeyboardEvent) {
    const element = event.target as HTMLElement;
    const canMove = this.canMoveToNextRow(element);
    if (!canMove) {
      return;
    }

    const nextElement = this.getNextElementVertically(element, ArrowDirection.Down);
    if (nextElement && !this.isSameElement(element, nextElement)) {
      event.preventDefault();
      nextElement.focus();
    }
  }

  private isSameElement(element: HTMLElement, nextElement: HTMLElement) {
    return element.isSameNode(nextElement);
  }

  private getNextElementHorizontally(
    element: HTMLElement,
    arrowDirection: ArrowDirection.Left | ArrowDirection.Right,
  ) {
    const { index, focusableList } = this.getElementColumnIndex(element);
    const canMove = this.canMoveToPrevOrNextField(element, arrowDirection);
    const nextElement = {
      [ArrowDirection.Right]: canMove ? focusableList[index + 1] : element,
      [ArrowDirection.Left]: canMove ? focusableList[index - 1] : element,
    };

    return nextElement[arrowDirection];
  }

  private getNextElementVertically(
    element: HTMLElement,
    arrowDirection: ArrowDirection.Up | ArrowDirection.Down,
  ) {
    const elementRow = element.closest('tr');

    const { index: columnIndex } = this.getElementColumnIndex(element);
    const nextRow = this.getNextRow(elementRow, arrowDirection);
    if (this.isSameElement(elementRow, nextRow)) {
      return element;
    }
    const focusableElementInRow = this.getFocusableElementsInRow(nextRow);
    return focusableElementInRow[columnIndex];
  }

  private getNextRow(
    elementRow: HTMLTableRowElement,
    arrowDirection: ArrowDirection.Up | ArrowDirection.Down,
  ) {
    const { rowIndex, focusableRows } = this.getCurrentRowIndex(elementRow);
    const nextRow = {
      [ArrowDirection.Up]: focusableRows[rowIndex - 1],
      [ArrowDirection.Down]: focusableRows[rowIndex + 1],
    };
    return nextRow[arrowDirection] || elementRow;
  }

  private canMoveToPrevOrNextField(
    element: HTMLElement,
    arrowDirection: ArrowDirection.Left | ArrowDirection.Right,
  ) {
    const elementHasContent = element.matches(`${FocusAbleFieldType.Editable}, ${FocusAbleFieldType.Input}`);

    if (!elementHasContent) {
      return true;
    }

    const tagName = element.tagName.toLowerCase() as FocusAbleFieldType;
    const elementCursorPositionFn =
      tagName === FocusAbleFieldType.Input
        ? this.cursorPositionInInputElement.bind(this)
        : this.cursorPositionInEditableElement.bind(this);

    const { cursorAtEndOfContent, cursorAtStartOfContent } = this.contentCursorStatus(
      element,
      elementCursorPositionFn,
    );
    return arrowDirection === ArrowDirection.Left ? cursorAtStartOfContent : cursorAtEndOfContent;
  }

  private canMoveToNextRow(element: HTMLElement) {
    const ngSelectElement = element.closest('.ng-select');
    if (!ngSelectElement) {
      return true;
    }
    const dropdownIsOpen = ngSelectElement.matches('.ng-select-opened');
    return !dropdownIsOpen;
  }

  private contentCursorStatus(
    element: HTMLElement,
    getCursorPositionFn,
  ): {
    cursorAtStartOfContent: boolean;
    cursorAtEndOfContent: boolean;
  } {
    const { cursorPosition, cellInputValue } = getCursorPositionFn(element as HTMLInputElement);

    return {
      cursorAtStartOfContent: cursorPosition === 0,
      cursorAtEndOfContent: cursorPosition === cellInputValue.length,
    };
  }

  private getElementColumnIndex(element: HTMLElement) {
    const focusableList = this.getFocusableSiblingInRow(element);
    const index = focusableList.indexOf(element);
    return { index, focusableList };
  }

  private getCurrentRowIndex(elementRow: HTMLTableRowElement) {
    const focusableRows = this.getFocusAbleRows(elementRow);
    const rowIndex = focusableRows.indexOf(elementRow);
    return { rowIndex, focusableRows };
  }

  private getFocusableSiblingInRow(element: HTMLElement): HTMLElement[] {
    const elementRow = element.closest('tr');

    const focusableElementsInRow = this.getFocusableElementsInRow(elementRow);
    return focusableElementsInRow;
  }

  private getFocusableElementsInRow(elementRow: HTMLTableRowElement) {
    const focusableElementsInRow = elementRow
      ? Array.from(elementRow.querySelectorAll(`${getAllFocusAbleField()}`))
      : [];
    return focusableElementsInRow.filter(this.isElementVisible) as HTMLElement[];
  }

  private getFocusAbleRows(element: HTMLTableRowElement): HTMLTableRowElement[] {
    const tableElement = element.closest('tbody');
    const focusableElementsRows = tableElement ? Array.from(tableElement.querySelectorAll('tr')) : [];
    return focusableElementsRows;
  }

  private isElementVisible(element: HTMLElement) {
    return !!element.offsetParent;
  }

  private cursorPositionInInputElement(element: HTMLInputElement) {
    const { value, selectionEnd } = element;
    return { cursorPosition: selectionEnd, cellInputValue: value };
  }

  private cursorPositionInEditableElement(element: HTMLElement) {
    const { focusOffset } = this.window.getSelection();
    const { textContent } = element;
    return { cursorPosition: focusOffset, cellInputValue: textContent };
  }

  private tableKeyboardShortcutUsedEvent(keyboardEvent: KeyboardEventKey) {
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.FormTableKeyboardShortcutUsed, {
        Context: keyboardEvent,
      }),
      this.segmentService,
    );
  }
}
