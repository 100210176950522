import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FolderNavBarService } from 'app/layout/folder-navbar/folder-navbar.service';
import { TeamService } from 'app/shared/service/team.service';

@Component({
  selector: 'cc-team-select',
  templateUrl: 'team-select.component.html',
})
export class TeamSelectComponent implements OnInit {
  private unSubscribe = new Subject<void>();
  teamId;
  teams = [];
  actionTitle;

  constructor(
    public modal: NgbActiveModal,
    private folderNavbarService: FolderNavBarService,
    protected readonly teamService: TeamService,
  ) {}

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { companyId, projectId, _id } = this.teamService.getCurrentTeam();
    this.teamId = _id;
    this.folderNavbarService
      .getCompanyTree(companyId)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((company) => {
        const projects = company.filter((project) => project._id === projectId);
        if (projects.length > 0) {
          this.teams = projects[0].teams.filter((team) => team._id !== _id);
        }
      });
  }

  onSubmit() {
    this.modal.close(this.teams.filter((team) => team._id === this.teamId)[0]);
  }
}
