import { ExportedCellChange } from '@site-mate/dashpivot-shared-library';

import { FieldWeb } from '../model/item.model';

export enum FormulasVersion {
  v1 = 1,
  v2 = 2,
}

export interface FormulaEngineService {
  init(): void;

  getSheet(sheetName: string): number;

  addSheet(sheetName: string): void;

  setValuesUpdateListener(handler: (changes: ExportedCellChange[]) => void): void;

  initSheetContent(params: { sheet: number; table: FieldWeb; tableHasFormulas?: boolean }): void;

  calculateSheetContent(params: { sheet: number; table: FieldWeb; tableHasFormulas?: boolean }): void;

  setCellContent(params: {
    table: FieldWeb;
    colIndex: number;
    rowIndex: number;
    sheet: number;
    tableHasFormulas?: boolean;
  }): void;

  destroy(): void;

  clearSheet(sheetName: string): void;

  setEmptySheetContent(sheetName: string): void;
}
