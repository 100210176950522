import { Component, Input } from '@angular/core';

import { Company } from '@site-mate/dashpivot-shared-library';

@Component({
  selector: 'cc-edit-modal-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class EditModalLogoComponent {
  @Input() company: Company;
}
