import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
  Signature,
  FieldKinds,
  TableCellKinds,
  PathService,
  User,
} from '@site-mate/dashpivot-shared-library';

import { FormService } from 'app/form/form.service';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { SignatureWeb } from 'app/shared/model/item.model';
import { ConfirmService, confirmExitOperations } from 'app/shared/service/confirm.service';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { TeamService } from 'app/shared/service/team.service';
import { ToastrService } from 'app/shared/service/toastr.service';
import { UserService } from 'app/user/user.service';

@Component({
  selector: 'cc-form-signature-list',
  templateUrl: 'form-signature-list.component.html',
  styleUrls: [
    'form-signature-list.component.scss',
    '../../../shared/component/signature-pad/signature-pad.component.scss',
  ],
})
export class FormSignatureListComponent {
  readonly pathService = new PathService();

  @Input() signatures: SignatureWeb[];
  @Input() kind: FieldKinds | TableCellKinds;
  @Input() isManualSignature?: boolean;
  @Input() formPath: string;
  @Input() isReadOnly: boolean;

  currentUser: User;
  messageType: string;
  userPermissionContainer: Map<string, Observable<boolean>> = new Map();

  constructor(
    private readonly confirmService: ConfirmService,
    private readonly i18nService: TmpI18NService,
    private readonly formsService: FormService,
    private readonly errorHandler: ErrorHandler,
    private readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly toastr: ToastrService,
    private readonly userService: UserService,
    private readonly teamService: TeamService,
  ) {}

  ngOnInit() {
    this.currentUser = this.userService.getCurrentUser();
  }

  limitPointerEvents() {
    return false;
  }

  shouldHaveDeleteButton(signature): Observable<boolean> {
    if (this.userPermissionContainer.has(signature.userId)) {
      return this.userPermissionContainer.get(signature.userId);
    }

    this.userPermissionContainer.set(signature.userId, this.canDeleteSignature(signature));
    return this.userPermissionContainer.get(signature.userId);
  }

  canDeleteSignature(signature: SignatureWeb): Observable<boolean> {
    const isFieldKindSignature = this.kind === FieldKinds.Signature;
    const isFieldKindSignatureArray = this.kind === FieldKinds.SignatureArray;
    const isFieldKindManualSignature = this.kind === TableCellKinds.ManualSignature;
    const haveDescriptionProperty = !!signature.description;

    const isDefaultTableSignature = isFieldKindSignature && !haveDescriptionProperty;
    const isManualSignature = this.isManualSignature || isFieldKindManualSignature;
    const isDefaultFieldSignature = isFieldKindSignatureArray && !isManualSignature;

    if (this.isReadOnly) {
      return of(false);
    }

    if (isDefaultTableSignature || isDefaultFieldSignature) {
      return this.canDeleteDefaultSignature(signature);
    }

    if (isManualSignature) {
      return this.canDeleteManualSignature();
    }

    return of(false);
  }

  canDeleteDefaultSignature(signature: SignatureWeb): Observable<boolean> {
    const isSameUser = this.currentUser._id === signature.userId;

    return of(isSameUser);
  }

  canDeleteManualSignature(): Observable<boolean> {
    if (this.currentUser.isGlobalAdmin) {
      return of(true);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
    const [_companyId, _projectId, teamId] = this.pathService.extractFolderIds(this.formPath);

    return this.teamService.getUsers(teamId).pipe(
      switchMap((users) => {
        return of(users.some((user) => user._id === this.currentUser._id));
      }),
    );
  }

  getDeleteMessaging(signature: Signature) {
    if (
      this.currentUser._id !== signature.userId ||
      this.isManualSignature ||
      this.kind === TableCellKinds.ManualSignature
    ) {
      return 'deleteSignature';
    }
    return 'deleteYourSignature';
  }

  async onDeleteEntry(signature: Signature, index: number) {
    try {
      this.messageType = this.getDeleteMessaging(signature);

      await this.confirmService.confirmDelete(this.messageType, {
        confirmButtonText: this.i18nService.getMessage('confirmRemove'),
      });
      this.signatures.splice(index, 1);
      this.changeDetectorRef.detectChanges();

      this.formsService.onFieldChanged.next();
    } catch (error) {
      if (!confirmExitOperations.includes(error as string)) {
        this.errorHandler.handle(error);
      }
    }
  }
}
