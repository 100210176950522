"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyleVariants = exports.LegacyStyleVariants = void 0;
var LegacyStyleVariants;
(function (LegacyStyleVariants) {
    LegacyStyleVariants["Info"] = "info";
    LegacyStyleVariants["Success"] = "success";
    LegacyStyleVariants["Danger"] = "danger";
    LegacyStyleVariants["Warning"] = "warning";
    LegacyStyleVariants["None"] = "none";
})(LegacyStyleVariants = exports.LegacyStyleVariants || (exports.LegacyStyleVariants = {}));
var StyleVariants;
(function (StyleVariants) {
    StyleVariants["Red"] = "red";
    StyleVariants["Orange"] = "orange";
    StyleVariants["Yellow"] = "yellow";
    StyleVariants["Lime"] = "lime";
    StyleVariants["Green"] = "green";
    StyleVariants["Sky"] = "sky";
    StyleVariants["Blue"] = "blue";
    StyleVariants["Violet"] = "violet";
    StyleVariants["Pink"] = "pink";
    StyleVariants["Grey"] = "grey";
    StyleVariants["Black"] = "black";
    StyleVariants["None"] = "none";
})(StyleVariants = exports.StyleVariants || (exports.StyleVariants = {}));
