<div *ngIf="model.kind === controlType.photo" class="form-group row" [class.invalid-field]="model._invalid">
  <div class="row col-12">
    <cc-form-field-label
      [model]="model"
      class="col-3 form-label photo-description"
      [label]="model.description"
    />
    <div
      class="col-12 sm-flex"
      [class.sm-flex-col]="model.photos && model.photos.length > 0"
      [class.sm-items-start]="model.photos && model.photos.length > 0"
      [class.sm-items-center]="!model.photos"
    >
      <div class="form-control-static photo-list-component" *ngIf="model.photos && model.photos.length > 0">
        <cc-form-photo-list
          [item]="model"
          [readOnly]="model.readOnly"
          [photos]="model.photos"
          (remove)="onRemovePhoto($event)"
          (viewPhoto)="onViewPhoto($event)"
          displayThumbnailSize="true"
          [isLandscapeMode]="isLandscapeMode"
        ></cc-form-photo-list>
      </div>
      <div class="sm-flex sm-flex-row sm-items-center sm-space-x-2">
        <div class="form-control-static">
          <button
            *ngIf="!model.readOnly"
            [disabled]="isInsideLibraryApps"
            type="button"
            class="btn btn-primary"
            data-test="btn-add-photo"
            i18n
            (click)="onAddPhotoClick()"
          >
            Add photos<i aria-hidden="true" class="cc-icon cc-icon-photo sm-pl-1"></i>
          </button>

          <button
            *ngIf="!model.readOnly && model.photos.length > 1"
            [disabled]="isInsideLibraryApps"
            type="button"
            class="btn sm-ml-1 sm-bg-grey-1 hover:sm-bg-grey-2 focus:sm-shadow-none"
            data-test="btn-remove-all-photos"
            i18n
            (click)="onRemoveAllPhotos()"
          >
            <i aria-hidden="true" class="cc-icon cc-icon-close sm-text-default-red"></i>
            <span class="sm-font-medium">Remove all photos</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
