"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INFORMATION_FORMULAS = exports.INFORMATION_NOT_SUPPORTED_FORMULAS = exports.INFORMATION_SUPPORTED_FORMULAS = exports.INFORMATION_SUPPORTED_FORMULAS_WITH_INFO = void 0;
exports.INFORMATION_SUPPORTED_FORMULAS_WITH_INFO = new Map([
    [
        'ISBLANK',
        {
            syntax: 'ISBLANK(Value)',
            description: 'Returns TRUE if the reference to a cell is blank.',
        },
    ],
    [
        'ISERR',
        {
            syntax: 'ISERR(Value)',
            description: 'Returns TRUE if the value is error value except #N/A!.',
        },
    ],
    [
        'ISERROR',
        {
            syntax: 'ISERROR(Value)',
            description: 'Returns TRUE if the value is general error value.',
        },
    ],
    [
        'ISEVEN',
        {
            syntax: 'ISEVEN(Value)',
            description: 'Returns TRUE if the value is an even integer, or FALSE if the value is odd.',
        },
    ],
    [
        'ISFORMULA',
        {
            syntax: 'ISFORMULA(Value)',
            description: 'Checks whether referenced cell is a formula.',
        },
    ],
    [
        'ISLOGICAL',
        {
            syntax: 'ISLOGICAL(Value)',
            description: 'Tests for a logical value (TRUE or FALSE).',
        },
    ],
    [
        'ISNA',
        {
            syntax: 'ISNA(Value)',
            description: 'Returns TRUE if the value is #N/A! error.',
        },
    ],
    [
        'ISNONTEXT',
        {
            syntax: 'ISNONTEXT(Value)',
            description: 'Tests if the cell contents are text or numbers, and returns FALSE if the contents are text.',
        },
    ],
    [
        'ISNUMBER',
        {
            syntax: 'ISNUMBER(Value)',
            description: 'Returns TRUE if the value refers to a number.',
        },
    ],
    [
        'ISODD',
        {
            syntax: 'ISODD(Value)',
            description: 'Returns TRUE if the value is odd, or FALSE if the number is even.',
        },
    ],
    [
        'ISREF',
        {
            syntax: 'ISREF(Value)',
            description: 'Returns TRUE if provided value is #REF! error.',
        },
    ],
    [
        'ISTEXT',
        {
            syntax: 'ISTEXT(Value)',
            description: 'Returns TRUE if the cell contents refer to text.',
        },
    ],
    [
        'NA',
        {
            syntax: 'NA(Value)',
            description: 'Returns #N/A! error value.',
        },
    ],
]);
exports.INFORMATION_SUPPORTED_FORMULAS = [...exports.INFORMATION_SUPPORTED_FORMULAS_WITH_INFO.keys()];
exports.INFORMATION_NOT_SUPPORTED_FORMULAS = ['ISBINARY', 'SHEET', 'SHEETS'];
exports.INFORMATION_FORMULAS = exports.INFORMATION_SUPPORTED_FORMULAS.concat(exports.INFORMATION_NOT_SUPPORTED_FORMULAS);
