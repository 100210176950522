import { Directive, ElementRef, HostListener, AfterViewInit, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[ccHorizontalScrollTracker]',
  exportAs: 'ccHorizontalScrollTracker',
})
export class HorizontalScrollTrackerDirective implements AfterViewInit, OnDestroy {
  @Input() elementToUpdate: ElementRef;

  scrollClasses = {
    START: 'horizontal-scroll-start',
    MIDDLE: 'horizontal-scroll-middle',
    END: 'horizontal-scroll-end',
  };

  isHostScrollable = false;
  isHostScrolled = false;
  isHostScrolledToTheEnd = false;
  observer: MutationObserver;

  constructor(private readonly elementRef: ElementRef) {
    this.observer = new MutationObserver(this.updateClassesBasedOnScroll.bind(this));
  }

  get element() {
    return this.elementRef.nativeElement;
  }

  get elementToUpdateClass() {
    return this.elementToUpdate ? this.elementToUpdate : this.elementRef.nativeElement;
  }

  ngAfterViewInit() {
    this.observer.observe(this.element, { childList: true });
    this.updateClassesBasedOnScroll();
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }

  private updateClassesBasedOnScroll(): void {
    this.isHostScrollable = this.element.scrollWidth > this.element.clientWidth;

    if (!this.isHostScrollable) {
      this.elementToUpdateClass.classList.remove(...Object.values(this.scrollClasses));
      this.element.scrollLeft = 0;
      return;
    }

    if (this.isHostScrollable) {
      this.updateHostClasses();
    }
  }

  private updateHostClasses(): void {
    const scrollLeft = Math.ceil(this.element.scrollLeft);
    this.isHostScrolled = scrollLeft > 0;
    this.isHostScrolledToTheEnd = !(this.element.scrollWidth > scrollLeft + this.element.clientWidth);

    if (!this.isHostScrolled) {
      this.elementToUpdateClass.classList.add(this.scrollClasses.START);
      this.elementToUpdateClass.classList.remove(this.scrollClasses.MIDDLE, this.scrollClasses.END);
      return;
    }

    if (this.isHostScrolledToTheEnd) {
      this.elementToUpdateClass.classList.add(this.scrollClasses.END);
      this.elementToUpdateClass.classList.remove(this.scrollClasses.START, this.scrollClasses.MIDDLE);
      return;
    }

    this.elementToUpdateClass.classList.add(this.scrollClasses.MIDDLE);
    this.elementToUpdateClass.classList.remove(this.scrollClasses.START, this.scrollClasses.END);
  }

  @HostListener('scroll', ['$event'])
  onElementScroll() {
    this.updateClassesBasedOnScroll();
  }
}
