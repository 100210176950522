import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import {
  DashpivotEvent,
  EventNotifierService,
  EventTypes,
  TableCellData,
  TableColumn,
  TableCellValidator,
  TableCellValidatorFactory,
  TableColumnWidths,
} from '@site-mate/dashpivot-shared-library';

import { FormDateUtil } from 'app/form/form-date-util.service';
import { FormTableCellComponent } from 'app/form-fields/table/edit-cells/table-cell.component';
import { SegmentService } from 'app/segment/segment.service';

@Component({
  templateUrl: './date.component.html',
  styleUrls: ['../table-cell.component.scss'],
  selector: 'cc-table-cell-item-date',
})
export class FormTableDateCellComponent implements FormTableCellComponent, OnInit {
  @Input() cellData: TableCellData;
  @Input() headerColumn: TableColumn;
  @Output() onChange = new EventEmitter();

  validator: TableCellValidator;
  readonly tableColumnWidths = TableColumnWidths;

  constructor(private readonly segmentService: SegmentService) {}

  ngOnInit() {
    this.validator = TableCellValidatorFactory.getValidator(this.cellData, this.headerColumn);
    this.cellData._date = FormDateUtil.convertToDateStruct(this.cellData.value) as NgbDateStruct;
  }

  handleOnChange(value) {
    this.cellData.value = FormDateUtil.convertDateToUTCString(value);
    this.onChange.emit();
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.FormTableCellDataEntered),
      this.segmentService,
    );
  }
}
