<div class="input-group">
  <input
    class="form-control"
    [placeholder]="placeholder"
    (click)="dateInput.toggle()"
    ngbDatepicker
    autocomplete="off"
    #dateInput="ngbDatepicker"
    [(ngModel)]="model"
    (ngModelChange)="onChange($event)"
    [name]="name"
    [container]="container"
    [footerTemplate]="footerTemplate"
  />
  <span class="input-group-btn">
    <button
      #dateButton
      class="btn btn-secondary date-selector-icon d-flex justify-content-center"
      type="button"
      (click)="dateInput.toggle()"
    >
      <i class="cc-icon cc-icon-{{ icon }}"></i>
    </button>
  </span>
</div>

<ng-template #footerTemplate>
  <hr class="my-0 hr" />
  <div class="text-center">
    <button
      type="button"
      class="m-2 btn btn-primary btn-sm"
      (click)="model = today; onChange(today); dateInput.close()"
    >
      Today
    </button>
    <button
      type="button"
      class="m-2 btn btn-secondary btn-sm"
      (click)="model = {}; onChange(model); dateInput.close()"
    >
      Clear
    </button>
    <button type="button" class="m-2 btn btn-secondary btn-sm" (click)="dateInput.close()">Close</button>
  </div>
</ng-template>
