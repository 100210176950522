"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Event = void 0;
class Event {
    constructor(eventType, metadata) {
        this.eventType = eventType;
        this.metadata = Object.assign({}, metadata);
    }
}
exports.Event = Event;
