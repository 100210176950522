import { Component, Inject, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { EventTypes } from '@site-mate/dashpivot-shared-library';

import { IModalRef, IModalService, IModalServiceToken } from 'app/shared/interface/modal/modal.interface';
import { EventsService } from 'app/shared/service/events/events.service';
import { GeolocationService } from 'app/shared/service/geolocation/geolocation.service';

import { MapModalComponent } from './map-modal/map-modal.component';

@Component({
  selector: 'cc-signon-table-location',
  templateUrl: './signon-table-location.component.html',
})
export class SignonTableLocationComponent {
  @Input() point: { coordinates: [number, number] } | null = null;
  @Input() fullName: string;
  @Input() date: string;

  isGeoAPILoaded$: Observable<boolean> = this.geolocationService.isGeoAPILoaded$;
  GPSLocation: { lat: number; lng: number } | null = null;
  modalRef: IModalRef;

  constructor(
    private readonly geolocationService: GeolocationService,
    private readonly eventsService: EventsService,
    @Inject(IModalServiceToken) private readonly modalService: IModalService,
  ) {}

  ngOnInit() {
    this.setGPSLocation();
  }

  private setGPSLocation() {
    if (!this.point) {
      this.GPSLocation = null;
    } else {
      this.GPSLocation = { lat: this.point.coordinates[0], lng: this.point.coordinates[1] };
    }
  }

  open(event: MouseEvent) {
    this.eventsService.trackEvent(EventTypes.ViewMapClicked, { Context: 'Sitemate Scan ID' });
    event.stopPropagation();

    this.modalRef = this.modalService.open(MapModalComponent, {
      data: {
        disableClose: true,
        GPSLocation: this.GPSLocation,
        fullName: this.fullName,
        date: this.date,
        event: {
          type: EventTypes.GoogleMapsRequestFromSignonTableLocation,
        },
      },
    });
  }

  closeModal() {
    this.modalRef.close();
  }
}
