<span
  class="tool-tip-container"
  role="alert"
  triggers="mouseenter:mouseleave"
  [popoverClass]="popoverClass"
  container="body"
  [ngbPopover]="popover"
  [placement]="placement"
  [popperOptions]="popperOptions"
>
  <div [class]="containerClass" #ref><ng-content></ng-content></div>

  <ng-container *ngIf="!ref.hasChildNodes()">
    <em class="cc-icon cc-icon-question"></em>
  </ng-container>
</span>

<ng-template #popover>
  {{ contentText }}
</ng-template>
