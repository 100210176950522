import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  DashpivotEvent,
  EventNotifierService,
  EventTypes,
  FormFieldValidatorFactory,
  IHierarchy,
} from '@site-mate/dashpivot-shared-library';

import { FormBaseComponent } from 'app/form/form-components/form-base.component';
import { FormPhotoModalComponent } from 'app/form/form-components/form-photo-modal.component';
import { FormService } from 'app/form/form.service';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { PhotoModalService } from 'app/photo/photo-modal.service';
import { PhotoService } from 'app/photo/photo.service';
import { SegmentService } from 'app/segment/segment.service';
import { IFrameUrlKey } from 'app/shared/model/iframe-url-key.enum';
import { FieldWeb } from 'app/shared/model/item.model';
import { AppUtilService } from 'app/shared/service/app-util.service';
import { ConfirmService } from 'app/shared/service/confirm.service';
import { ToastrService } from 'app/shared/service/toastr.service';

@Component({
  selector: 'cc-form-photo',
  templateUrl: 'form-photo.component.html',
  styleUrls: ['../../../form/form-components/form-component.scss', 'form-photo-list.component.scss'],
})
export class FormPhotoComponent extends FormBaseComponent<FieldWeb> implements OnInit {
  private readonly destroy$ = new Subject<void>();
  @Input() hierarchy: IHierarchy;
  @Input() isLandscapeMode: boolean;
  kmlFile: string;
  isInsideLibraryApps = false;

  constructor(
    protected readonly appUtilService: AppUtilService,
    protected readonly formService: FormService,
    protected readonly photoService: PhotoService,
    protected readonly photoModal: PhotoModalService,
    protected readonly modal: NgbModal,
    protected readonly toastrService: ToastrService,
    protected readonly i18nService: TmpI18NService,
    protected readonly confirmService: ConfirmService,
    protected readonly segmentService: SegmentService,
    protected readonly router: Router,
  ) {
    super(appUtilService, formService);
  }

  ngOnInit() {
    this.setUpKmlFile();
    this.validator = FormFieldValidatorFactory.getValidator(this.model);
    this.isInsideLibraryApps = this.router.url.includes(`/${IFrameUrlKey.LibraryApps}`);
  }

  setUpKmlFile() {
    this.photoService.kmlFileEvent.pipe(takeUntil(this.destroy$)).subscribe((kmlFile) => {
      this.kmlFile = kmlFile;
    });
  }

  onAddPhotoClick() {
    if (this.isPreview) {
      this.toastrService.warnByKey('formPreviewWarningAddPhoto');
      return;
    }

    const containerOptions = this.isInsideLibraryApps ? {} : { container: '.form-modal-container' };
    const modalRef = this.modal.open(FormPhotoModalComponent, {
      windowClass: 'form-photo-modal',
      keyboard: false,
      ...containerOptions,
    });
    const componentInstance = modalRef.componentInstance as FormPhotoModalComponent;
    componentInstance.hierarchy = this.hierarchy;
    componentInstance.formRef = true;
    // eslint-disable-next-line promise/catch-or-return
    modalRef.result.then(
      (selectedPhotos) => {
        // eslint-disable-next-line promise/always-return
        this.model.photos = (this.model.photos || []).concat(selectedPhotos);
        this.model._invalid = !this.validator.isValid(this.model);
        this.model._dirty = true;
        this.onFieldChange();
      },
      () => {
        // no-op
      },
    );
  }

  onDescriptionTagsChanged() {
    this.onFieldChange();
  }

  onViewPhoto({ item, index }) {
    const ref = this.photoModal.open({
      timeLineIndex: 0,
      timeLines: [{ photos: item.photos }],
      photoIndex: index,
      kmlFile: this.kmlFile,
      hideDeleteButton: true,
      teamId: String(this.hierarchy.team._id),
      projectId: String(this.hierarchy.project._id),
      onDescriptionTagsChanged: this.onDescriptionTagsChanged.bind(this),
    });

    // eslint-disable-next-line promise/catch-or-return
    ref.result.then(
      // eslint-disable-next-line promise/always-return
      () => {
        // no-op
      },
      () => {
        // no-op
      },
    );
  }

  onRemovePhoto({ index }) {
    // eslint-disable-next-line promise/catch-or-return
    this.confirmService
      .confirmDelete('removePhoto', {
        confirmButtonText: this.i18nService.getMessage('confirmRemove'),
      })
      .then(
        // eslint-disable-next-line promise/always-return
        () => {
          this.model.photos.splice(index, 1);
          this.model._invalid = !this.validator.isValid(this.model);
          this.model._dirty = true;

          void EventNotifierService.notify(
            new DashpivotEvent(EventTypes.PhotoRemovedFromForm, { Context: 'Single' }),
            this.segmentService,
          );

          this.onFieldChange();
          // eslint-disable-next-line consistent-return
          return true;
        },
        () => {
          // no-op
        },
      );
  }

  onRemoveAllPhotos() {
    this.confirmService
      .confirmDelete('removeAllPhotos', {
        confirmButtonText: this.i18nService.getMessage('confirmRemove'),
      })
      .then(() => {
        this.model.photos = [];
        this.model._invalid = !this.validator.isValid(this.model);
        this.model._dirty = true;

        void EventNotifierService.notify(
          new DashpivotEvent(EventTypes.PhotoRemovedFromForm, { Context: 'Bulk' }),
          this.segmentService,
        );

        this.onFieldChange();
        // eslint-disable-next-line consistent-return
        return true;
      })
      .catch(() => {
        // no-op
      });
  }
}
