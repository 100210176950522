import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[ccFallbackSrc]',
})
export class FallbackSrcDirective implements OnDestroy, OnInit {
  @Input() ccFallbackSrc: string;
  private ele: HTMLElement;
  private ON_ERROR = 'error';

  constructor(el: ElementRef) {
    this.ele = el.nativeElement;
  }

  ngOnInit(): void {
    this.ele.addEventListener(this.ON_ERROR, this.onError.bind(this));
    this.ele.setAttribute('src', this.ccFallbackSrc);
  }

  private onError() {
    this.removeEvents();
    this.ele.style.display = 'none';
  }

  private removeEvents() {
    this.ele.removeEventListener(this.ON_ERROR, this.onError);
  }

  ngOnDestroy() {
    this.removeEvents();
  }
}
