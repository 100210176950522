import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'cc-state-toggle-item',
  templateUrl: './state-toggle-item.component.html',
  styleUrls: ['./state-toggle-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StateToggleItemComponent {
  @Input() value: string;

  @HostBinding('class.disabled')
  @Input()
  disabled = false;

  @HostBinding('class.selected') selected = false;
  @HostBinding('tabindex') tabIndex = 0;

  @Output() select = new EventEmitter<string>();

  @HostListener('keydown', ['$event'])
  handleKeyPress(event: KeyboardEvent) {
    const { key } = event;
    const enterKey = 'Enter';
    const spaceKey = ' ';

    if ([enterKey, spaceKey].includes(key)) {
      event.preventDefault();
      this.selectState.bind(this)();
      return false;
    }
    return true;
  }

  @HostListener('click')
  selectState() {
    if (!this.disabled) {
      this.select.emit(this.value);
    }
  }
}
