"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SketchFormFieldValidator = void 0;
const lodash_1 = require("lodash");
class SketchFormFieldValidator {
    hasAnyData(field) {
        return !!field.canvas;
    }
    hasCompleteData(field) {
        if (field.canvas) {
            const canvas = JSON.parse(field.canvas);
            return !(0, lodash_1.isEmpty)(canvas.objects);
        }
        return false;
    }
    isValid(field) {
        if (field.isRequired && !this.hasCompleteData(field)) {
            return false;
        }
        return true;
    }
    invalidDescription(field) {
        if (field.isRequired && !this.hasCompleteData(field)) {
            return '';
        }
        if (!this.isValid(field)) {
            return 'invalid';
        }
        return '';
    }
}
exports.SketchFormFieldValidator = SketchFormFieldValidator;
