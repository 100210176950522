"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignonTableFieldValidator = void 0;
const lodash_1 = require("lodash");
class SignonTableFieldValidator {
    hasAnyData(field) {
        return this.hasCompleteData(field);
    }
    hasCompleteData(field) {
        return !!(field.entries && !(0, lodash_1.isEmpty)(field.entries));
    }
    isValid(field) {
        if (field.isRequired && !this.hasCompleteData(field)) {
            return false;
        }
        return true;
    }
    invalidDescription(field) {
        if (field.isRequired && !this.hasCompleteData(field)) {
            return 'Required field missing';
        }
        if (!this.isValid(field)) {
            return 'invalid';
        }
        return '';
    }
}
exports.SignonTableFieldValidator = SignonTableFieldValidator;
