"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportOutputFormats = void 0;
var ExportOutputFormats;
(function (ExportOutputFormats) {
    ExportOutputFormats["Pdf"] = "pdf";
    ExportOutputFormats["Zip"] = "zip";
    ExportOutputFormats["Original"] = "original";
    ExportOutputFormats["Csv"] = "csv";
})(ExportOutputFormats = exports.ExportOutputFormats || (exports.ExportOutputFormats = {}));
