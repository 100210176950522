import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { DropdownItemComponent } from './dropdown-item/dropdown-item.component';

declare type Placement =
  | 'auto'
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right'
  | 'left-top'
  | 'left-bottom'
  | 'right-top'
  | 'right-bottom';
declare type PlacementArray = Array<Placement> | string;

@Component({
  selector: 'cc-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements AfterContentInit, OnDestroy {
  @Input() container: 'body' | null;
  @Input() placement: PlacementArray = 'bottom-start bottom-end';
  @Input() autoClose: boolean | 'outside' | 'inside' = true;
  @Input() size: 'default' | 'lg' = 'default';
  @Input() id: string;
  @Input() focusOnOpen: 'toggle' | 'first-item' | 'none' = 'toggle';
  @Input() toggleButtonClass: string = 'dropdown-toggle-button';
  @Input() dropdownMenuClass: string;
  @Input() disabled = false;
  @Input() dropdownClass: string;
  @ViewChild(NgbDropdown)
  private dropdown: NgbDropdown;
  private unsubscribe = new Subject<void>();
  dropdownPlacementClass: string = '';

  @ViewChild('dropdownToggle') dropdownToggle: ElementRef<HTMLButtonElement>;
  @ContentChildren(DropdownItemComponent) dropdownItems: QueryList<DropdownItemComponent>;

  @Output()
  stateChange = new EventEmitter<boolean>();

  constructor(private readonly zone: NgZone) {}

  ngOnInit() {
    this.dropdownPlacementClass = this.size;

    if (this.placement.includes('bottom')) {
      this.dropdownPlacementClass += '-nudge-top';
    }
  }

  ngAfterContentInit() {
    this.zone.onStable.pipe(first()).subscribe(() => {
      this.subscribeToChildrenEvents();
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private subscribeToChildrenEvents() {
    this.dropdownItems.forEach((dropdownItem, index, itemArray) => {
      this.subscribeToChildSelectEvents(dropdownItem);
      this.subscribeToChildMoveEvents(dropdownItem, index, itemArray);
    });
  }

  private subscribeToChildSelectEvents(dropdownItem: DropdownItemComponent) {
    dropdownItem.select.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      if (this.autoClose === 'outside') {
        this.zone.onStable.pipe(first()).subscribe(() => {
          dropdownItem.focus();
        });
      } else {
        this.close();
      }
    });
  }

  private subscribeToChildMoveEvents(
    dropdownItem: DropdownItemComponent,
    index: number,
    itemArray: DropdownItemComponent[],
  ) {
    dropdownItem.focusNextSibling.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      if (index !== itemArray.length - 1) {
        itemArray[index + 1].focus();
      } else {
        this.dropdownToggle.nativeElement.focus();
      }
    });
    dropdownItem.focusPreviousSibling.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      if (index !== 0) {
        itemArray[index - 1].focus();
      } else {
        this.dropdownToggle.nativeElement.focus();
      }
    });
  }

  onDropdownToggleKeyDown(event: KeyboardEvent) {
    event.preventDefault();
    if (event.key === 'ArrowDown') {
      this.dropdownItems.first.focus();
    } else if (event.key === 'ArrowUp') {
      this.dropdownItems.last.focus();
    }
  }

  onStateChange(status: boolean) {
    this.stateChange.emit(status);
  }

  open() {
    this.dropdown.open();

    const focusHandlers = {
      toggle: this.handleFocusToggle,
      'first-item': this.handleFocusFirstItem,
      none: this.handleNone,
    };

    focusHandlers[this.focusOnOpen].call(this);
  }

  private handleFocusToggle() {
    this.dropdownToggle.nativeElement.focus();
  }

  private handleFocusFirstItem() {
    this.zone.onStable.pipe(first()).subscribe(() => {
      this.dropdownItems.first.focus();
    });
  }

  private handleNone() {
    (document.activeElement as HTMLElement).blur();
  }

  close() {
    this.dropdown.close();
  }
}
