<form *ngIf="form; else loadingBlock" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <h4 class="h4 modal-title">
      <span i18n>Edit profile</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="cc-icon cc-icon-close"></i></span>
    </button>
  </div>
  <div *ngIf="user" class="modal-body animate-fade-in">
    <div class="form-group row">
      <label class="default-label col-sm-4" i18n>Email</label>
      <div class="col-sm-8">
        <input formControlName="email" class="form-control" />
      </div>
    </div>
    <div class="form-group row" [ngClass]="{ 'has-danger': firstName.invalid }">
      <label class="default-label col-sm-4" i18n>First name</label>
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control"
          formControlName="firstName"
          placeholder="First name"
          i18n-placeholder="First name"
        />
        <div class="sm-text-default-red" i18n *ngIf="firstName.invalid">First name is required.</div>
      </div>
    </div>
    <div class="form-group row" [ngClass]="{ 'has-danger': lastName.invalid }">
      <label class="default-label col-sm-4" i18n>Last name</label>
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control"
          formControlName="lastName"
          placeholder="Last name"
          i18n-placeholder="Last name"
        />
        <div class="sm-text-default-red" i18n *ngIf="lastName.invalid">Last name is required</div>
      </div>
    </div>
    <div class="form-group row" [ngClass]="{ 'has-danger': mobile.invalid }">
      <label class="default-label col-sm-4" i18n for="mobile">Mobile Number</label>
      <div class="col-sm-8">
        <div class="mobile-container form-control">
          <input
            type="text"
            class="mobile-number-input"
            [class.hidden-mobile]="!showMobileNumber"
            formControlName="mobile"
            placeholder="Mobile"
            i18n-placeholder="Mobile"
          />
          <i
            class="cc-icon cc-icon-eyeball-slash mobile-number-toggle"
            [class.cc-icon-eyeball-slash]="!showMobileNumber"
            [class.cc-icon-eyeball]="showMobileNumber"
            (click)="toggleMobileNumberVisibility()"
          ></i>
        </div>

        <div class="sm-text-default-red" *ngIf="mobile.invalid && mobile.dirty">
          <div *ngIf="mobile.errors?.required">Mobile is required</div>
          <div *ngIf="mobile.errors?.pattern">Invalid mobile number</div>
        </div>
      </div>
    </div>
    <div class="form-group row" [class.has-danger]="!form.controls.signature.value">
      <label i18n class="default-label col-sm-4">Signature</label>
      <div class="col-sm-8">
        <div
          class="sm-flex sm-gap-4"
          *ngIf="
            form.controls.signature.pristine && form.controls.signature.value;
            else isAdmin ? null : editSignatureSection
          "
        >
          <cc-signature-pad
            [src]="form.controls.signature.value"
            [height]="signaturePadOptions.canvasHeight"
            [width]="signaturePadOptions.canvasWidth"
          ></cc-signature-pad>

          <div>
            <button
              type="button"
              class="button-regular cc-btn-subtle-gray edit-profile-button"
              *ngIf="!isAdmin && !form.controls.signature.dirty && form.controls.signature.value"
              (click)="confirmSignatureEdit()"
            >
              <i class="cc-icon cc-icon-pencil" aria-hidden="true"></i>
              <span class="sm-text-sm sm-text-black">Edit Signature</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="default-label col-sm-4" i18n for="timezone">Time Zone</label>
      <div class="col-sm-8">
        <ng-select
          formControlName="timezone"
          [items]="timezones"
          i18n-placeholder="Select timezone"
          placeholder="Select timezone"
          bindValue="value"
        >
        </ng-select>
      </div>
    </div>
  </div>
  <div class="modal-action">
    <button
      type="submit"
      class="btn btn-success"
      [disabled]="!form.dirty || form.invalid"
      [ccBusyButton]="working"
      i18n
    >
      Save changes
    </button>
  </div>
</form>

<ng-template #loadingBlock>
  <div class="loading-spinner" *ngIf="loading">
    <div class="cc-icon cc-icon-spinner-3x"></div>
  </div>
</ng-template>

<ng-template #editSignatureSection>
  <cc-edit-signature [form]="form" [signaturePadOptions]="signaturePadOptions"></cc-edit-signature>
</ng-template>
