import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { TemplateDropdownRadioInputComponent } from 'app/apps/template-edit/template-dropdown-radio-input/template-dropdown-radio-input.component';
import { SegmentModule } from 'app/segment/segment.module';
import { WarningModalComponent } from 'app/shared/component/warning-modal/warning-modal.component';

import { AccountUpgradeLimitUIComponent } from './account-upgrade-limit/account-upgrade-limit-ui.component';
import { BadgeComponent } from './badge/badge.component';
import { BannerComponent } from './banner/banner.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CounterComponent } from './counter/counter.component';
import { DropdownItemComponent } from './dropdown/dropdown-item/dropdown-item.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { FieldActionComponent } from './field-action/field-action.component';
import { InputToggleComponent } from './input-toggle/input-toggle.component';
import { ModalWithActionComponent } from './modal-with-action/modal-with-action.component';
import { ModalWithWarningsComponent } from './modal-with-warnings/modal-with-warnings.component';
import { PasswordValidationListComponent } from './password-validation-list/password-validation-list.component';
import { PopoverComponent } from './popover/popover.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { SearchAndSelectComponent } from './search-and-select/search-and-select.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { StateToggleComponent } from './state-toggle/state-toggle.component';
import { StateToggleItemComponent } from './state-toggle-item/state-toggle-item.component';
import { StyleColorPicker } from './style-color-picker/style-color-picker.component';
import { TextInputComponent } from './text-input/text-input.component';
import { ToolTipComponent } from './tool-tip/tool-tip.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    NgbDropdownModule,
    NgSelectModule,
    NgbPopoverModule,
    NgSelectModule,
    SegmentModule,
    ReactiveFormsModule,
    TextInputComponent,
  ],
  declarations: [
    AccountUpgradeLimitUIComponent,
    BadgeComponent,
    BannerComponent,
    CheckboxComponent,
    CounterComponent,
    DropdownComponent,
    DropdownItemComponent,
    FieldActionComponent,
    InputToggleComponent,
    ModalWithActionComponent,
    ModalWithWarningsComponent,
    PopoverComponent,
    ProgressBarComponent,
    SearchInputComponent,
    SearchAndSelectComponent,
    StateToggleComponent,
    StateToggleItemComponent,
    StyleColorPicker,
    TemplateDropdownRadioInputComponent,
    ToolTipComponent,
    PasswordValidationListComponent,
    WarningModalComponent,
  ],
  exports: [
    AccountUpgradeLimitUIComponent,
    BadgeComponent,
    BannerComponent,
    CheckboxComponent,
    CounterComponent,
    DropdownComponent,
    DropdownItemComponent,
    FieldActionComponent,
    InputToggleComponent,
    ModalWithActionComponent,
    ModalWithWarningsComponent,
    PopoverComponent,
    ProgressBarComponent,
    SearchInputComponent,
    SearchAndSelectComponent,
    StateToggleComponent,
    StateToggleItemComponent,
    StyleColorPicker,
    TemplateDropdownRadioInputComponent,
    TextInputComponent,
    ToolTipComponent,
    PasswordValidationListComponent,
  ],
})
export class UIComponentsModule {}
