import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit,
  EventEmitter,
  Output,
  DestroyRef,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl } from '@angular/forms';
import { BehaviorSubject, filter } from 'rxjs';

import {
  CategorySourceTypes,
  EventTypes,
  FieldKinds,
  IListRow,
  StyleVariants,
  TableCellData,
  TableCellValidator,
  TableCellValidatorFactory,
  TableColumn,
  TableManualListItem,
} from '@site-mate/dashpivot-shared-library';

import { FormTableCellComponent } from 'app/form-fields/table/edit-cells/table-cell.component';
import { ListsService } from 'app/lists/lists.service';
import { isFetchedQuery } from 'app/shared/model/query/query-predicates';
import { QueryStates } from 'app/shared/model/query/query-states.enum';
import { Query } from 'app/shared/model/query/query.model';
import { EventsService } from 'app/shared/service/events/events.service';

import { ListPropertyFormService } from '../list-property-form.service';

@Component({
  templateUrl: './form-table-list-cell.component.html',
  styleUrls: ['../table-cell.component.scss', './form-table-list-cell.component.scss'],
  selector: 'cc-table-cell-item-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormTableListCellComponent implements FormTableCellComponent, OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly eventsService = inject(EventsService);
  private readonly fb = inject(FormBuilder);
  private readonly listPropertyFormService = inject(ListPropertyFormService);
  protected readonly QueryStates = QueryStates;
  readonly listRows$ = new BehaviorSubject<Query<IListRow[]>>({
    state: QueryStates.Loading,
  });
  protected readonly StyleVariants = StyleVariants;
  readonly virtualScrollerThreshold = 30;

  @Input() cellData: TableCellData;
  @Input() headerColumn: TableColumn;
  @Input() rowId: string;
  @Input() hasListProperty: boolean;
  @Output() onChange = new EventEmitter();
  @Input() tableId: string;
  @Input() tableType: FieldKinds.Table | FieldKinds.PrefilledTable;
  validator: TableCellValidator;

  isManualList: boolean;
  form = this.fb.group({ selectedItem: new FormControl<IListRow>(null) });

  constructor(private readonly listsService: ListsService) {}

  ngOnInit(): void {
    this.validator = TableCellValidatorFactory.getValidator(this.cellData, this.headerColumn);

    if (this.cellData.model.value) {
      this.initializeWithModelValue();
    } else {
      this.fetchListRows();
    }

    this.form
      .get('selectedItem')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((newValue) => this.onSelectItem(newValue));
  }

  private fetchListRows() {
    const listSourceMapping = new Map<CategorySourceTypes, () => void>([
      [CategorySourceTypes.Manual, this.loadManualItems.bind(this)],
      [CategorySourceTypes.List, this.getGlobalListRows.bind(this)],
    ]);

    listSourceMapping.get(this.listSource)();
  }

  private get listSource() {
    const listSourceMap = new Map<FieldKinds, CategorySourceTypes>([
      [FieldKinds.Table, this.headerColumn.listSource],
      [FieldKinds.PrefilledTable, this.cellData.model.listSource],
    ]);

    return listSourceMap.get(this.tableType);
  }

  private get listId() {
    const listReferenceMap = new Map<FieldKinds, string>([
      [FieldKinds.Table, this.headerColumn.reference],
      [FieldKinds.PrefilledTable, this.cellData.model.reference],
    ]);

    return listReferenceMap.get(this.tableType);
  }

  private loadManualItems() {
    const itemsMap = new Map<string, TableManualListItem[]>([
      [FieldKinds.Table, this.headerColumn.manualListItems],
      [FieldKinds.PrefilledTable, this.cellData.model.manualListItems],
    ]);

    const data = itemsMap.get(this.tableType).map(({ value, style }) => {
      return { data: { value, style: style ?? StyleVariants.None } } as IListRow;
    });

    this.listRows$.next({ state: QueryStates.Fetched, data });
  }

  private initializeWithModelValue() {
    this.form.get('selectedItem').setValue(this.cellData.model.value, { emitEvent: false });

    this.listRows$.next({ state: QueryStates.Fetched, data: [] });
    this.listPropertyFormService.setSelectedRow(this.tableId, this.rowId, true);
  }

  onOpen() {
    if (isFetchedQuery(this.listRows$.value) && !this.listRows$.value.data.length) {
      this.fetchListRows();
    }
    this.eventsService.trackEvent(EventTypes.FormTableCellDataEntered);
  }

  private getGlobalListRows() {
    this.listPropertyFormService
      .getListRows(this.listId)
      .pipe(filter(Boolean), takeUntilDestroyed(this.destroyRef))
      .subscribe((listRows: Query<IListRow[]>) => {
        if (listRows.state === QueryStates.Fetched) {
          listRows.data.sort(this.listsService.sortListRows);
        }
        this.listRows$.next(listRows);
      });
  }

  onSelectItem(row: IListRow) {
    const { style = StyleVariants.None, value = '' } = row?.data ?? {};

    this.cellData.model.value = value;
    this.cellData.model.style = style;

    if (this.listSource === CategorySourceTypes.Manual || !this.hasListProperty) {
      return this.onChange.emit();
    }

    return this.fetchRowCells(row?._id);
  }

  private fetchRowCells(listRowId: string) {
    const { tableId, rowId, destroyRef, headerColumn } = this;
    const rowAddress = { tableId, rowId, referenceTableColumnId: headerColumn._id };

    this.listPropertyFormService
      .fetchRowCells(listRowId, rowAddress)
      .pipe(takeUntilDestroyed(destroyRef))
      .subscribe((query) => {
        this.listPropertyFormService.setSelectedRow(tableId, rowId, true);
        if (isFetchedQuery(query)) {
          this.eventsService.trackEvent(EventTypes.ListPropertyAppliedToForm, { Context: 'Table Cell' });
        }
        this.onChange.emit();
      });
  }

  clearSelection() {
    this.form.get('selectedItem').setValue(null);
    this.listPropertyFormService.setSelectedRow(this.tableId, this.rowId, false);
    this.onChange.emit();
  }
}
