"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListSyncParentCache = void 0;
class ListSyncParentCache {
    /* eslint-disable-next-line no-useless-constructor */
    constructor(fetchParents, cacheLimit = 3000) {
        this.fetchParents = fetchParents;
        this.cacheLimit = cacheLimit;
        this.parentsMap = new Map();
    }
    getById(parentId) {
        return __awaiter(this, void 0, void 0, function* () {
            const parsedParentId = String(parentId);
            if (this.parentsMap.has(parsedParentId)) {
                return this.parentsMap.get(parsedParentId);
            }
            const [parent] = yield this.fetchParents([parsedParentId]);
            this.parentsMap.set(parsedParentId, parent !== null && parent !== void 0 ? parent : null);
            if (this.parentsMap.size > this.cacheLimit) {
                this.dropOldest();
            }
            return this.parentsMap.get(parsedParentId);
        });
    }
    getByIds(parentIds) {
        return __awaiter(this, void 0, void 0, function* () {
            const results = new Map();
            const toFetch = new Set();
            parentIds.forEach((parentId) => {
                const parsedParentId = String(parentId);
                if (this.parentsMap.has(parsedParentId)) {
                    results.set(parsedParentId, this.parentsMap.get(parsedParentId));
                }
                else {
                    toFetch.add(parsedParentId);
                }
            });
            const fetched = yield this.fetchParents([...toFetch]);
            fetched.forEach((parent) => {
                const parentId = String(parent._id);
                toFetch.delete(parentId);
                results.set(parentId, parent);
                this.parentsMap.set(parentId, parent);
            });
            toFetch.forEach((deletedParentId) => {
                results.set(deletedParentId, null);
                this.parentsMap.set(deletedParentId, null);
            });
            if (this.parentsMap.size > this.cacheLimit) {
                this.dropOldest(this.parentsMap.size - this.cacheLimit);
            }
            return results;
        });
    }
    dropOldest(amount = 1) {
        const keysIterator = this.parentsMap.keys();
        for (let keyResult = keysIterator.next(), i = 0; keyResult.done === false && i < amount; keyResult = keysIterator.next(), i += 1) {
            this.parentsMap.delete(keyResult.value);
        }
    }
    clear() {
        this.parentsMap.clear();
    }
}
exports.ListSyncParentCache = ListSyncParentCache;
