import { PlatformLocation } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { concat, defer, from, lastValueFrom } from 'rxjs';

import { DashpivotEvent, EventNotifierService, EventTypes, IUser } from '@site-mate/dashpivot-shared-library';

import { GlobalApiService } from 'app/global-api/global-api.service';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { SegmentService } from 'app/segment/segment.service';
import { ModalBaseEscComponent } from 'app/shared/component/modal-base-esc.component';
import { timezones, dayjs } from 'app/shared/dayjs';
import { AppUtilService } from 'app/shared/service/app-util.service';
import { ConfirmService } from 'app/shared/service/confirm.service';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { ToastrService } from 'app/shared/service/toastr.service';

import { defaultSignaturePadOptions } from './default-signature-pad-options.model';
import { UserService } from '../user.service';

@Component({
  selector: 'cc-edit-profile',
  templateUrl: 'edit-profile.component.html',
  styleUrls: ['edit-profile.component.scss', '../../../styles/components/modal.scss'],
})
export class EditProfileComponent extends ModalBaseEscComponent implements OnInit {
  @Input() isAdmin: boolean;
  @Input() user: IUser;

  signaturePadOptions = defaultSignaturePadOptions;

  form: FormGroup;
  firstName: FormControl;
  lastName: FormControl;
  mobile: FormControl;

  working: boolean;
  uploading: boolean;
  timezones: { value: string; label: string }[];
  showMobileNumber: boolean;

  loading = true;

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly toastrService: ToastrService,
    private readonly userService: UserService,
    private readonly segmentService: SegmentService,
    private readonly errorHandler: ErrorHandler,
    private readonly confirmService: ConfirmService,
    private readonly i18nService: TmpI18NService,
    private readonly location: PlatformLocation,
    private readonly globalApiService: GlobalApiService,
    private readonly appUtilService: AppUtilService,
    private readonly formBuilder: FormBuilder,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super(location, activeModal);
  }

  async ngOnInit() {
    this.timezones = timezones.map((name) => {
      const offset = dayjs.tz(dayjs(), name).utcOffset();
      const label = `${name} (${this.formatOffset(offset)})`;
      return { value: name, label };
    });

    await this.refreshProfile();
    this.user.timezone = this.user?.timezone || dayjs.tz.guess();

    this.setFirstNameControl();
    this.setLastNameControl();
    this.setMobileControl();

    this.initForm();
  }

  private formatOffset(minutes: number) {
    const sign = minutes >= 0 ? '+' : '-';
    const absMinutes = Math.abs(minutes);
    const hours = Math.floor(absMinutes / 60);
    const mins = absMinutes % 60;
    return `${sign}${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
  }

  async onSubmit() {
    if (this.form.invalid) {
      this.toastrService.error('Form is invalid. Please check the form and try again.');
      return;
    }

    try {
      this.working = true;
      const { firstName, lastName, mobile, timezone } = this.form.value;

      const data: Partial<IUser> = {
        mobile,
        firstName,
        lastName,
        timezone,
      };

      if (this.form.controls.signature.dirty && this.form.controls.signature.value) {
        const signatureFile = this.appUtilService.dataUrlToFile(
          this.form.controls.signature.value,
          'signature',
        );

        const { url } = await lastValueFrom(
          this.globalApiService.uploadSignature<{ url: string }>(
            signatureFile,
            this.form.controls.signature.value,
          ),
        );

        if (url) {
          data.signature = url;
        }
      }

      const operations = [
        this.isAdmin ? this.userService.updateUser(this.user.id, data) : this.userService.updateProfile(data),
      ];

      operations.push(defer(() => from(this.done())));

      await lastValueFrom(concat(...operations));
    } catch (error) {
      this.working = false;
    }
  }

  private setFirstNameControl() {
    this.firstName = new FormControl({ value: this.user.firstName ?? '', disabled: !this.isAdmin }, [
      Validators.required,
    ]);
  }

  private setLastNameControl() {
    this.lastName = new FormControl({ value: this.user.lastName ?? '', disabled: !this.isAdmin }, [
      Validators.required,
    ]);
  }

  private setMobileControl() {
    this.mobile = new FormControl(this.user.mobile ?? '', [
      Validators.required,
      Validators.pattern(/\+?\d+/),
    ]);
  }

  private initForm() {
    this.form = this.formBuilder.group({
      email: new FormControl({ value: this.user?.email ?? '', disabled: true }),
      firstName: this.firstName,
      lastName: this.lastName,
      mobile: this.mobile,
      timezone: new FormControl(this.user.timezone ?? '', Validators.required),
      signature: new FormControl(this.user.signature ?? '', Validators.required),
    });

    this.loading = false;

    this.changeDetectorRef.detectChanges();
  }

  private async done() {
    this.working = false;
    this.toastrService.successByKey('profileSaved');
    this.closeModal();
    return this.refreshProfile();
  }

  private async refreshProfile() {
    try {
      this.user = this.isAdmin
        ? await this.userService.byId(this.user.id).toPromise()
        : await this.userService.updateCurrentUser().toPromise();
    } catch (error) {
      this.errorHandler.handle(error);
    }
  }

  async confirmSignatureEdit() {
    try {
      await this.confirmService.confirm('editYourSignature', {
        confirmButtonText: this.i18nService.getMessage('confirmEditSignature'),
      });

      this.form.controls.signature.setValue('');
      this.form.controls.signature.markAsDirty();
      this.changeDetectorRef.detectChanges();
    } catch {
      // no-op
    }
  }

  async removeSignature() {
    try {
      await this.confirmService.confirmDelete('removeSignature', {
        confirmButtonText: this.i18nService.getMessage('confirmRemove'),
      });

      this.form.controls.signature.setValue('');
      this.form.controls.signature.markAsDirty();
    } catch {
      // no-op
    }
  }

  trackUnsubscribeEvent(unsubscribedSnapshot, unsubscribeReason) {
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.EmailUnsubscribeSnapshotReportLinkClicked, {
        unsubscribedSnapshot,
        unsubscribeReason,
      }),
      this.segmentService,
    );
  }

  trackSubscribeEvent(subscribedSnapshot) {
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.EmailSubscribeSnapshotReportLinkClicked, {
        subscribedSnapshot,
      }),
      this.segmentService,
    );
  }

  enableEsc() {
    this.canEsc = true;
  }

  toggleMobileNumberVisibility() {
    this.showMobileNumber = !this.showMobileNumber;
  }
}
