<ul class="nav mr-auto level-2" *ngIf="selected.length === 0 || formRef">
  <li class="sm-flex sm-items-center">
    <p class="sm-p-2 sm-font-medium" data-test="photos-title" i18n>Photos</p>
  </li>

  <div class="sm-py-3 sm-flex sm-items-center">
    <cc-state-toggle #stateToggle class="sm-pointer-events-none sm-bg-grey-3">
      <cc-state-toggle-item>
        <div class="sm-flex sm-items-center sm-space-x-1" data-test="timeline-toggle">
          <i class="cc-icon cc-icon-timeline" aria-hidden="true"></i>
          <span i18n>Timeline</span>
        </div>
      </cc-state-toggle-item>
    </cc-state-toggle>
  </div>
  <li class="sm-hidden md:sm-flex">
    <div class="input-group search-text-container">
      <cc-search-input
        class="sm-border-grey-3"
        [eventDebounceInMilliseconds]="searchDebounce"
        placeholder="Search site notes..."
        theme="white"
        (valueChanged)="searchBySiteNotes($event)"
      ></cc-search-input>
    </div>
  </li>
  <li class="sm-hidden md:sm-inline">
    <div
      data-test="user-filter"
      class="sm-inline-block sm-h-full"
      placement="bottom"
      (document:mouseup)="userFilterPop.close()"
      [ngbPopover]="userFilter"
      #userFilterPop="ngbPopover"
    >
      <a class="photo-link" [class.filter-active]="isUserFilterOn">
        <i class="cc-icon cc-icon-user"></i
        ><span [class.photos-nav-bar-btn]="!formRef" [class.form-modal-nav-btn]="formRef" i18n
          >User Filter</span
        >
        <i class="cc-icon cc-icon-arrow-down-small"></i
      ></a>
    </div>
  </li>

  <li class="sm-hidden md:sm-inline">
    <div
      class="sm-inline-block sm-h-full"
      placement="bottom"
      (document:mouseup)="dateFilterPop.close()"
      [ngbPopover]="dateFilter"
      #dateFilterPop="ngbPopover"
    >
      <a class="photo-link" [class.filter-active]="isDateFilterOn">
        <i class="cc-icon cc-icon-calendar"></i
        ><span [class.photos-nav-bar-btn]="!formRef" [class.form-modal-nav-btn]="formRef" i18n
          >Date Filter</span
        >
        <i class="cc-icon cc-icon-arrow-down-small"></i
      ></a>
    </div>
  </li>

  <li class="sm-hidden md:sm-inline">
    <div class="sm-inline-block sm-h-full">
      <a class="photo-link hover:sm-bg-grey-3" (click)="resetFilter()" *ngIf="hasSearchCriteria">
        <i class="cc-icon cc-icon-undo"></i>
        <span i18n [class.photos-nav-bar-btn]="!formRef" [class.form-modal-nav-btn]="formRef"
          >Remove Filter</span
        >
      </a>
    </div>
  </li>

  <button
    type="button"
    class="ml-auto photo-upload-button btn btn-success rounded-0 border-top-0 border-bottom-0"
    (click)="uploadPhoto()"
    data-test="upload-button"
    i18n
  >
    Upload
  </button>
</ul>
<ul class="nav mr-auto level-2" *ngIf="selected.length > 0 && !formRef">
  <li class="sm-hidden md:sm-inline">
    <a class="photo-link" (click)="onExportToZip()">
      <i class="cc-icon cc-icon-download"></i><span i18n>Download as ZIP</span>
    </a>
  </li>

  <li class="sm-hidden md:sm-inline">
    <a class="photo-link" (click)="onExportToPDF()">
      <i class="cc-icon cc-icon-download-pdf"></i><span i18n>Download as PDF</span>
    </a>
  </li>

  <li class="sm-hidden md:sm-inline">
    <a data-test="add-photo-tags" class="photo-link" (click)="addPhotoTags.emit()">
      <i class="cc-icon cc-icon-tag"></i><span i18n>Add Photo Tags</span>
    </a>
  </li>

  <li class="sm-hidden md:sm-inline" *ngIf="canMove()">
    <a class="photo-link" (click)="move.emit()">
      <i class="cc-icon cc-icon-move"></i><span i18n>Move</span>
    </a>
  </li>

  <li class="sm-hidden md:sm-inline" *ngIf="canCopy()">
    <a class="photo-link" (click)="copy.emit()">
      <i class="cc-icon cc-icon-clone"></i><span i18n>Copy</span>
    </a>
  </li>

  <li class="sm-hidden md:sm-inline" *ngIf="canDelete()">
    <a class="photo-link" (click)="onRemove()">
      <i class="cc-icon cc-icon-delete sm-text-default-red"></i><span i18n>Delete</span>
    </a>
  </li>
</ul>

<ng-template #userFilter>
  <div (mouseup)="$event.stopPropagation()" class="photo-tool-bar-user-filter-popover">
    <div class="form-group">
      <ng-select
        name="users"
        [items]="copiedUsers"
        placeholder="Select or search"
        i18n-placeholder="Select or search"
        [multiple]="true"
        [(ngModel)]="selectedUsers"
        bindValue="value"
        [loading]="isLoadingUsers"
      >
      </ng-select>
    </div>
    <div class="sm-pb-4">
      <div
        class="float-left photo-filter-button sm-flex sm-items-center"
        (click)="resetUserFilter()"
        [class.photo-filter-ready]="selectedUsers.length > 0"
      >
        <i class="cc-icon cc-icon-undo"></i>
        <span i18n>Remove Filter</span>
      </div>
      <div
        class="float-right photo-filter-button sm-flex sm-items-center"
        (click)="searchByUser()"
        [class.photo-filter-ready]="selectedUsers.length > 0"
      >
        <i class="cc-icon cc-icon-filter"></i>
        <span i18n>Apply Filter</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #dateFilter>
  <div (mouseup)="$event.stopPropagation()" class="photo-tool-bar-date-filter-popover">
    <div class="form-group">
      <label class="default-label form-control-label" i18n>From</label>
      <cc-date-picker [(ngModel)]="startDate" name="startDate"> </cc-date-picker>
    </div>
    <div class="form-group">
      <label class="default-label form-control-label" i18n>To</label>
      <cc-date-picker [(ngModel)]="endDate" name="endDate"> </cc-date-picker>
    </div>

    <div>
      <div
        class="float-left photo-filter-button sm-flex sm-items-center"
        (click)="resetDateFilter()"
        [class.photo-filter-ready]="startDate || endDate"
      >
        <i class="cc-icon cc-icon-undo"></i>
        <span i18n>Remove Filter</span>
      </div>
      <div
        class="float-right photo-filter-button sm-flex sm-items-center"
        (click)="searchByDate()"
        [class.photo-filter-ready]="startDate || endDate"
      >
        <i class="cc-icon cc-icon-filter"></i>
        <span i18n>Apply Filter</span>
      </div>
      <span class="clearfix"></span>
    </div>
  </div>
</ng-template>
