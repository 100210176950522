import { Component, Input, OnInit } from '@angular/core';

import { TmpI18NService } from 'app/i18n/tmp-i18n.service';

@Component({
  selector: 'cc-panel-with-links-tabs',
  templateUrl: './panel-with-links-tabs.component.html',
  styleUrls: ['./panel-with-links-tabs.component.scss'],
})
export class PanelWithLinksTabsComponent implements OnInit {
  @Input() panelTabs = [];

  i18nTitles = {};

  constructor(private readonly i18nService: TmpI18NService) {}

  ngOnInit(): void {
    this.getAllTitles();
  }

  getAllTitles() {
    this.i18nTitles = this.panelTabs.reduce((currentI18N, nextI18N) => {
      const i18NTitle = this.i18nService.getMessage(nextI18N.titleCode);
      currentI18N[nextI18N.titleCode] = i18NTitle;

      return currentI18N;
    }, {});
  }
}
