"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableCellValidator = void 0;
const lodash_1 = require("lodash");
const table_cell_kinds_enum_1 = require("../../models/tables/table-cell-kinds.enum");
class TableCellValidator {
    constructor(cellData, headerColumn) {
        // all tables should have cell kinds, although historically kind was only on the header column for default tables
        this.cellKind = () => this.cellData.kind || this.headerColumn.kind;
        // TODO: hacks to deal with types - these need to move to GI validators
        this.hasGITextData = () => { var _a, _b; return !!((_b = (_a = this.cellData.item) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.value); };
        this.hasGIDatePlainData = () => { var _a, _b; return !!((_b = (_a = this.cellData.item) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.date); };
        this.hasGITimePlainData = () => { var _a, _b; return !!((_b = (_a = this.cellData.item) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.time); };
        this.cellData = cellData;
        this.headerColumn = headerColumn;
    }
    hasAnyData() {
        return (!!this.cellData.value ||
            this.hasGITextData() ||
            this.hasGIDatePlainData() ||
            this.hasGITimePlainData() ||
            !(0, lodash_1.isEmpty)(this.cellData.photos) ||
            !(0, lodash_1.isEmpty)(this.cellData.attachments) ||
            !(0, lodash_1.isEmpty)(this.cellData.signatures));
    }
    hasCompleteData() {
        return this.hasAnyData() || this.cellKind() === table_cell_kinds_enum_1.TableCellKinds.PrefilledText;
    }
    isValid() {
        if (this.headerColumn.isRequired && !this.hasCompleteData()) {
            return false;
        }
        return true;
    }
    invalidDescription() {
        if (this.headerColumn.isRequired && !this.hasCompleteData()) {
            return 'required';
        }
        if (!this.isValid()) {
            return 'invalid';
        }
        return '';
    }
}
exports.TableCellValidator = TableCellValidator;
