import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cc-input-toggle',
  templateUrl: './input-toggle.component.html',
  styleUrls: ['./input-toggle.component.scss'],
})
export class InputToggleComponent {
  @Input() modelValue: boolean;
  @Input() popoverContent?: string;
  @Input() isDisabled: boolean;

  @Output() onUpdate = new EventEmitter<boolean>();

  onToggleClicked() {
    this.onUpdate.emit(this.modelValue);
  }
}
