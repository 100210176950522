import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { debounce } from 'lodash-es';

import { UIComponentsTheme } from '../ui-components-theme.enum';

@Component({
  selector: 'cc-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements OnInit, AfterViewInit {
  @Output() valueChanged: EventEmitter<string> = new EventEmitter();
  @HostBinding('class') hostClass: string;
  @Input() defaultValue: string;
  @Input() eventDebounceInMilliseconds: number;
  @Input() placeholder: string;
  @Input() theme: UIComponentsTheme;
  @ViewChild('searchInput') inputElement: ElementRef<HTMLInputElement>;
  value = '';

  ngOnInit(): void {
    if (this.eventDebounceInMilliseconds) {
      this.emitValueChange = debounce(this.emitValueChange.bind(this), this.eventDebounceInMilliseconds);
    }
    this.hostClass = `theme-${this.theme}`;
    this.value = this.defaultValue;
  }

  ngAfterViewInit(): void {
    if (this.defaultValue) {
      this.inputElement.nativeElement.value = this.defaultValue;
    }
  }

  onInputValueChange(currentValue: string) {
    this.emitValueChange(currentValue);
  }

  emitValueChange(currentValue: string) {
    this.valueChanged.emit(currentValue);
  }

  clearInput() {
    this.value = '';
    this.onInputValueChange('');
  }

  focusInput() {
    this.inputElement.nativeElement.focus();
  }
}
