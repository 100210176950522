"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupportedFormulasWithInfo = exports.SupportedFormulas = void 0;
const lodash_1 = require("lodash");
const array_manipulation_formulas_1 = require("./formula-list/array-manipulation-formulas");
const date_and_time_formulas_1 = require("./formula-list/date-and-time-formulas");
const engineering_formulas_1 = require("./formula-list/engineering-formulas");
const financial_formulas_1 = require("./formula-list/financial-formulas");
const information_formulas_1 = require("./formula-list/information-formulas");
const logical_formulas_1 = require("./formula-list/logical-formulas");
const lookup_and_ref_formulas_1 = require("./formula-list/lookup-and-ref-formulas");
const math_formulas_1 = require("./formula-list/math-formulas");
const matrix_formulas_1 = require("./formula-list/matrix-formulas");
const operator_formulas_1 = require("./formula-list/operator-formulas");
const statistical_formulas_1 = require("./formula-list/statistical-formulas");
const text_formulas_1 = require("./formula-list/text-formulas");
exports.SupportedFormulas = (0, lodash_1.flatten)([
    array_manipulation_formulas_1.ARRAY_SUPPORTED_FORMULAS,
    date_and_time_formulas_1.DATE_AND_TIME_SUPPORTED_FORMULAS,
    engineering_formulas_1.ENGINEERING_SUPPORTED_FORMULAS,
    financial_formulas_1.FINANCIAL_SUPPORTED_FORMULAS,
    information_formulas_1.INFORMATION_SUPPORTED_FORMULAS,
    logical_formulas_1.LOGICAL_SUPPORTED_FORMULAS,
    lookup_and_ref_formulas_1.LOOKUP_AND_REF_SUPPORTED_FORMULAS,
    math_formulas_1.MATH_SUPPORTED_FORMULAS,
    operator_formulas_1.OPERATOR_SUPPORTED_FORMULAS,
    statistical_formulas_1.STATISTICAL_SUPPORTED_FORMULAS,
    text_formulas_1.TEXT_SUPPORTED_FORMULAS,
    matrix_formulas_1.MATRIX_SUPPORTED_FORMULAS,
]);
exports.SupportedFormulasWithInfo = new Map([
    ...array_manipulation_formulas_1.ARRAY_SUPPORTED_FORMULAS_WITH_INFO,
    ...date_and_time_formulas_1.DATE_AND_TIME_SUPPORTED_FORMULAS_WITH_INFO,
    ...engineering_formulas_1.ENGINEERING_SUPPORTED_FORMULAS_WITH_INFO,
    ...financial_formulas_1.FINANCIAL_SUPPORTED_FORMULAS_WITH_INFO,
    ...information_formulas_1.INFORMATION_SUPPORTED_FORMULAS_WITH_INFO,
    ...logical_formulas_1.LOGICAL_SUPPORTED_FORMULAS_WITH_INFO,
    ...lookup_and_ref_formulas_1.LOOKUP_AND_REF_SUPPORTED_FORMULAS_WITH_INFO,
    ...math_formulas_1.MATH_SUPPORTED_FORMULAS_WITH_INFO,
    ...operator_formulas_1.OPERATOR_SUPPORTED_FORMULAS_WITH_INFO,
    ...statistical_formulas_1.STATISTICAL_SUPPORTED_FORMULAS_WITH_INFO,
    ...text_formulas_1.TEXT_SUPPORTED_FORMULAS_WITH_INFO,
    ...matrix_formulas_1.MATRIX_SUPPORTED_FORMULAS_WITH_INFO,
]);
