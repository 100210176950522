import { BannerBadgeTypes } from './banner-badge-types.enum';
import { BannerKeys } from './banner-keys.enum';

export type BannerMetadataType = {
  [key in BannerKeys]: {
    releaseDate: string;
    type: BannerBadgeTypes;
    localStorageKey: string;
    durationInDays?: number;
  };
};

export const BannerMetadata: BannerMetadataType = {
  [BannerKeys.SelectAndArchiveFolders]: {
    releaseDate: '2023-01-01',
    type: BannerBadgeTypes.Tip,
    localStorageKey: 'select-and-archive-folders-banner',
  },
  [BannerKeys.DragAndDropFields]: {
    releaseDate: '2023-01-01',
    type: BannerBadgeTypes.Tip,
    localStorageKey: 'template-editor-drag-and-drop-fields',
  },
  [BannerKeys.FormAvailableInMobile]: {
    releaseDate: '2023-01-01',
    type: BannerBadgeTypes.Tip,
    localStorageKey: 'form-mobile-alert',
  },
  [BannerKeys.FormTableActions]: {
    releaseDate: '2023-01-01',
    type: BannerBadgeTypes.Tip,
    localStorageKey: 'form-table-actions-tip-banner',
  },
  [BannerKeys.ArchiveLists]: {
    releaseDate: '2023-11-20', // 2024-01-04 -> end of new banner - Not removed for testing purposes
    type: BannerBadgeTypes.New,
    localStorageKey: 'archive-lists-banner',
  },
  [BannerKeys.ExpiryDateInTimelineTemplates]: {
    releaseDate: '2023-11-30', // 2024-01-14 -> end of new banner - Not removed for testing purposes
    type: BannerBadgeTypes.New,
    localStorageKey: 'expiry-date-in-timeline-templates',
    durationInDays: 46, // added for testing purposes, but won't change the banner`s behaviour
  },
  [BannerKeys.ExtendCellTypeToRows]: {
    releaseDate: '2024-01-29', // 2024-03-14 -> end of new banner
    type: BannerBadgeTypes.New,
    localStorageKey: 'extend-cell-type-to-rows-banner',
  },
  [BannerKeys.PersonField]: {
    releaseDate: '2024-04-30', // 2024-06-14 -> end of new banner
    type: BannerBadgeTypes.New,
    localStorageKey: 'person-field-banner',
  },
  [BannerKeys.FlowsiteXeroTimesheets]: {
    releaseDate: '2024-03-21', // 2026-03-21 -> end of new banner
    type: BannerBadgeTypes.New,
    localStorageKey: 'flowsite-xero-templates-banner', // old banner key 'flowsite-xero-timesheets-banner'
    durationInDays: 730,
  },
  [BannerKeys.FlowsiteXeroDockets]: {
    releaseDate: '2024-03-21', // 2026-03-21 -> end of new banner
    type: BannerBadgeTypes.New,
    localStorageKey: 'flowsite-xero-templates-banner', // old banner key 'flowsite-xero-dockets-banner'
    durationInDays: 730,
  },
  [BannerKeys.FlowsitePowerBiExcel]: {
    releaseDate: '2024-03-21', // 2026-03-21 -> end of new banner
    type: BannerBadgeTypes.New,
    localStorageKey: 'flowsite-power-bi-excel-banner',
    durationInDays: 730,
  },
  [BannerKeys.FlowsitePowerBiExcelTemplates]: {
    releaseDate: '2024-05-02', // 2026-05-02 -> end of new banner
    type: BannerBadgeTypes.New,
    localStorageKey: 'flowsite-power-bi-excel-templates-banner',
    durationInDays: 730,
  },
  [BannerKeys.FlowsiteQuickBooksDockets]: {
    releaseDate: '2024-05-02', // 2026-05-02 -> end of new banner
    type: BannerBadgeTypes.New,
    localStorageKey: 'flowsite-quickbooks-templates-banner',
    durationInDays: 730,
  },
  [BannerKeys.HeaderFooterClearRevertButtons]: {
    releaseDate: '2024-05-01', // 2024-06-15 -> end of new banner
    type: BannerBadgeTypes.New,
    localStorageKey: 'header-footer-clear-revert-buttons-banner',
  },
  [BannerKeys.ListPropertyTableCell]: {
    releaseDate: '2024-07-10',
    type: BannerBadgeTypes.New,
    localStorageKey: 'list-property-table-cell-banner',
  },
  [BannerKeys.FlowsiteCSVExports]: {
    releaseDate: '2024-07-02', // 2026-07-02 -> end of new banner
    type: BannerBadgeTypes.New,
    localStorageKey: 'flowsite-power-bi-excel-templates-banner',
    durationInDays: 730,
  },
  [BannerKeys.CsvAutofill]: {
    releaseDate: '2024-07-08', // 2024-07-23 -> end of new banner
    type: BannerBadgeTypes.New,
    localStorageKey: 'csv-autofill-banner',
    durationInDays: 15,
  },
  [BannerKeys.FlowsitePDFExports]: {
    releaseDate: '2024-07-02', // 2026-07-02 -> end of new banner
    type: BannerBadgeTypes.New,
    localStorageKey: 'flowsite-sharepoint-banner',
    durationInDays: 730,
  },
};
