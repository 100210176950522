<cc-banner
  *ngIf="shouldShowBanner && flowsiteReferenceType"
  class="sm-cursor-default"
  dismissible="true"
  placement="bottom"
  size="sm"
  triggers="mouseenter:mouseleave"
  popoverClass="tooltip-popover center-aligned"
  [ngbPopover]="popoverTemplate"
  ccBannerLogic
  [localStorageKey]="localStorageKey"
  [bannerKey]="bannerKey"
  [priority]="priority"
  [parentComponent]="parentComponent"
>
  <div *ngIf="isTimesheetOrDocketBanner; else powerBiExcelBannerText" class="banner-custom-content">
    <img
      [src]="bannerLogoAndTools[flowsiteReferenceType].logo"
      [alt]="bannerLogoAndTools[flowsiteReferenceType].tool"
      class="sm-h-4 sm-w-4"
    />
    <span i18n data-test="banner-text"
      >Link {{ bannerLogoAndTools[flowsiteReferenceType].linkTool }} to
      <span class="sm-font-medium">{{ bannerLogoAndTools[flowsiteReferenceType].tool }}</span> with Flowsite.
      <a
        [href]="referenceLink"
        class="reference-link"
        rel="noopener"
        target="_blank"
        (click)="trackFlowsiteReferral()"
      >
        Try it for free
      </a>
    </span>
  </div>
</cc-banner>

<ng-template #popoverTemplate data-test="popover-template">
  <cc-flowsite-banner-popover
    *ngIf="flowsiteReferenceType"
    [flowsiteReferenceType]="flowsiteReferenceType"
  ></cc-flowsite-banner-popover>
</ng-template>

<ng-template #powerBiExcelBannerText>
  <div
    *ngIf="isPowerBiOrExcelBanner && !isCSVExportBanner; else csvExportsBannerText"
    class="banner-custom-content"
  >
    <img src="/assets/images/flowsite/power-bi-logo.svg" alt="power bi" />
    <img src="/assets/images/flowsite/excel-logo.svg" alt="power bi" />

    <span i18n data-test="banner-text">
      Automate form data to <span class="sm-font-medium">Power BI</span> or
      <span class="sm-font-medium">Excel</span> with no code.
      <a
        [href]="referenceLink"
        class="reference-link"
        rel="noopener"
        target="_blank"
        (click)="trackFlowsiteReferral()"
      >
        Try
        {{ flowsiteReferenceType === flowsiteReferenceTypes.DashpivotPowerBiExcel ? 'it' : 'Flowsite' }} for
        free
      </a>
    </span>
  </div>
</ng-template>

<ng-template #csvExportsBannerText>
  <div
    class="banner-custom-content"
    *ngIf="isCSVExportBanner && !isPDFExportBanner; else pdfExportsBannerText"
  >
    <img src="/assets/images/flowsite/power-bi-logo.svg" alt="power bi" />
    <img src="/assets/images/flowsite/excel-logo.svg" alt="excel" />

    <span i18n data-test="banner-text">
      Automate CSV exports into <span class="sm-font-medium">Excel/PowerBI</span> with
      <span class="sm-font-medium">Flowsite</span>.
      <a
        [href]="referenceLink"
        class="reference-link"
        rel="noopener"
        target="_blank"
        (click)="trackFlowsiteReferral()"
      >
        Try it for free
      </a>
    </span>
  </div>
</ng-template>

<ng-template #pdfExportsBannerText>
  <div class="banner-custom-content">
    <img src="/assets/images/flowsite/sharepoint-logo.svg" alt="sharepoint" />

    <span i18n data-test="banner-text">
      Automate PDF exports into <span class="sm-font-medium">SharePoint</span> with
      <span class="sm-font-medium">Flowsite</span>.
      <a
        [href]="referenceLink"
        class="reference-link"
        rel="noopener"
        target="_blank"
        (click)="trackFlowsiteReferral()"
      >
        Try it for free
      </a>
    </span>
  </div>
</ng-template>
