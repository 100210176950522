import { Component, EventEmitter, Input, OnInit, Output, Inject, ViewEncapsulation } from '@angular/core';

import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { WINDOW } from 'app/shared/factory/window.factory';

@Component({
  selector: 'cc-rich-editor',
  templateUrl: 'rich-editor.component.html',
  styleUrls: ['rich-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RichEditorComponent implements OnInit {
  @Input() model;
  @Input() editorToolbarOption;
  @Input() formats: Array<string>;
  @Input() autoFocus = false;
  @Output() blur = new EventEmitter();
  @Output() change = new EventEmitter();
  placeholder: string;
  blurTimeout;

  constructor(
    @Inject(WINDOW) private readonly window: Window,
    private i18nService: TmpI18NService,
  ) {}

  ngOnInit() {
    this.placeholder = this.i18nService.getMessage('placeHolderText');
  }

  onEditorCreated(editor) {
    if (this.autoFocus) {
      this.window.setTimeout(() => {
        editor.focus();
      });
    }
  }

  onBlur() {
    this.blurTimeout = this.window.setTimeout(() => {
      this.blur.emit();
    });
  }

  onClick() {
    // cancel bluring if it was caused by clicking on this component
    this.window.clearTimeout(this.blurTimeout);
  }

  onChange() {
    this.change.emit();
  }
}
