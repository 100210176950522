import { inject, Injectable } from '@angular/core';
import { flatMap } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

import {
  FieldKinds,
  IField,
  StyleVariants,
  TableCellKinds,
  TableColumn,
} from '@site-mate/dashpivot-shared-library';
import { ITableField } from '@site-mate/dashpivot-shared-library/dist/models/fields/table-field.model';

import { FieldWeb } from 'app/shared/model/item.model';
import { ConfirmService } from 'app/shared/service/confirm.service';

@Injectable({ providedIn: 'root' })
export class TemplateListPropertyService {
  private readonly confirmService = inject(ConfirmService);
  readonly listPropertyTableCellCounter$ = new BehaviorSubject<number>(0);
  readonly maxListPropertyTableCells = 3;
  constructor() {}

  incrementListPropertyTableCellCounter(amount = 1): void {
    this.listPropertyTableCellCounter$.next(this.LPTCCountValue + amount);
  }

  decrementListPropertyTableCellCounter(amount = 1): void {
    this.listPropertyTableCellCounter$.next(this.LPTCCountValue - amount);
  }

  countListPropertyTableColumns(fields: FieldWeb[] | IField[]) {
    let listPropertyClonedCount = 0;
    fields.forEach((field) => {
      if (field.kind === FieldKinds.Table) {
        field.columns.forEach((column) => {
          if (column.kind === TableCellKinds.ListProperty) {
            listPropertyClonedCount += 1;
          }
        });
      }
    });
    return listPropertyClonedCount;
  }

  /**
   * Decrements the listPropertyTableCell counter based on the number of list property table columns in the given fields.
   * @param fields The fields to check for list property table columns.
   */

  decrementTableCellCounter(fields: FieldWeb[]) {
    const LTPColumnCount = this.countListPropertyTableColumns(fields);
    if (LTPColumnCount > 0) {
      this.decrementListPropertyTableCellCounter(LTPColumnCount);
    }
  }

  get LPTCCountValue(): number {
    return this.listPropertyTableCellCounter$.value;
  }

  get isLPTCCountExceeded(): boolean {
    return this.LPTCCountValue >= this.maxListPropertyTableCells;
  }

  async enforceListPropertyTableCellLimit(fields: FieldWeb[]): Promise<boolean> {
    const clonedCounter = this.countListPropertyTableColumns(fields);
    const isCellLimitExceeded = this.LPTCCountValue + clonedCounter > this.maxListPropertyTableCells;

    const LPTColumns = flatMap(
      (fields as ITableField[])
        .filter((field) => field.kind === FieldKinds.Table)
        .map((table) => table.columns.filter(({ kind }) => kind === TableCellKinds.ListProperty)),
    ) as TableColumn[];

    let shouldContinue = true;

    if (LPTColumns.length) {
      if (isCellLimitExceeded) {
        await this.confirmService
          .confirm('listPropertyLimitReached', { confirmButtonText: 'Continue' })
          .then(() => {
            const fieldsToReplace = this.LPTCCountValue + clonedCounter - this.maxListPropertyTableCells;
            const remainingCount = clonedCounter - fieldsToReplace;

            // Replace the aboveLimit amount of LPTC with Text Cells from last to first
            for (let i = LPTColumns.length - 1; i >= LPTColumns.length - fieldsToReplace; i--) {
              this.transformToTextColumn(LPTColumns[i]);
            }

            this.incrementListPropertyTableCellCounter(remainingCount);
          })
          .catch(() => {
            shouldContinue = false;
          });
      } else {
        this.incrementListPropertyTableCellCounter(clonedCounter);
      }
    }

    return shouldContinue;
  }

  transformToTextColumn = (column: TableColumn) => {
    const propertiesToKeep = new Set(['heading', '_id', 'id', 'width']);

    // Remove all properties except the ones in propertiesToKeep
    Object.keys(column).forEach((key) => {
      if (!propertiesToKeep.has(key)) {
        delete column[key];
      }
    });

    column.kind = TableCellKinds.Text;
    column.defaultColor = StyleVariants.Sky;
  };

  clearMetadata() {
    this.listPropertyTableCellCounter$.next(null);
  }
}
