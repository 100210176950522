import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TeamService } from '../service/team.service';

@Component({
  selector: 'cc-404',
  templateUrl: '404.component.html',
  styleUrls: ['404.component.scss'],
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
  team;
  unSubscribe = new Subject<void>();

  constructor(private teamService: TeamService) {}

  ngOnInit(): void {
    this.teamService.currentTeam.pipe(takeUntil(this.unSubscribe)).subscribe((team) => {
      this.team = team;
    });
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }
}
