"use strict";
/* eslint-disable @typescript-eslint/no-unused-vars */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonFieldValidator = void 0;
const lodash_1 = require("lodash");
class PersonFieldValidator {
    hasAnyData(field) {
        return this.hasCompleteData(field); // since it's a selector any data == complete data
    }
    hasCompleteData(field) {
        var _a;
        const userIds = (_a = field.data) === null || _a === void 0 ? void 0 : _a.userIds;
        if (userIds) {
            return !(0, lodash_1.isEmpty)(userIds);
        }
        return false;
    }
    isValid(field) {
        if (field.isRequired && !this.hasCompleteData(field)) {
            return false;
        }
        return true;
    }
    invalidDescription(field) {
        if (field.isRequired && !this.hasCompleteData(field)) {
            return '';
        }
        if (!this.isValid(field)) {
            return 'invalid';
        }
        return '';
    }
}
exports.PersonFieldValidator = PersonFieldValidator;
