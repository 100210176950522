import { AfterViewInit, ChangeDetectorRef, Directive, OnDestroy } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[ccNgSelectFirstFocused]',
})
export class NgSelectFirstFocusedDirective implements AfterViewInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly ngSelectComponent: NgSelectComponent,
  ) {}

  ngAfterViewInit() {
    this.ngSelectComponent.ngOptions.changes
      .pipe(startWith(this.ngSelectComponent.ngOptions), takeUntil(this.destroy$))
      .subscribe(() => {
        const firstActiveOption = this.ngSelectComponent.itemsList.filteredItems.find(
          (item) => !item.disabled,
        );
        this.ngSelectComponent.itemsList.markItem(firstActiveOption);
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
