/* eslint-disable max-lines */
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Subject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  first,
  map,
  of,
  switchMap,
  takeUntil,
} from 'rxjs';

import {
  Countries,
  CountryCodePerAwsRegion,
  IWorkspaceCreate,
  SitemateRegions,
} from '@site-mate/dashpivot-shared-library';

import { GlobalApiService } from 'app/global-api/global-api.service';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { OrgService } from 'app/org/org.service';
import { LocalStorageKeys } from 'app/shared/model/local-storage-keys.enum';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { LocalStorageService } from 'app/shared/service/local-storage.service';
import { TeamService } from 'app/shared/service/team.service';
import { ToastrService } from 'app/shared/service/toastr.service';
import { UserService } from 'app/user/user.service';
import { environment } from 'environments/environment';

type Field = 'accountName' | 'workspaceName' | 'region' | 'subdomain';
type SubdomainValidateResponse = {
  subdomainExists?: boolean;
  isValidSubdomain?: boolean;
  message?: 'Subdomain is already taken';
};
export enum WorkspaceCreationType {
  ExistingSmsAccount,
  NewSmsAccount,
  CurrentSmsAccount,
}

@Component({
  selector: 'cc-add-workspace',
  templateUrl: './add-workspace.component.html',
  styleUrls: ['./add-workspace.component.scss'],
})
export class AddWorkspaceComponent implements OnInit, OnDestroy {
  readonly defaultRegion: (typeof Countries)[0];
  readonly regions: typeof Countries;
  readonly workspaceCreationOptions: { label: string; value: WorkspaceCreationType }[] = [
    {
      label: this.i18nService.getMessage('addToExistingAccount'),
      value: WorkspaceCreationType.ExistingSmsAccount,
    },
    {
      label: this.i18nService.getMessage('createNewAccount'),
      value: WorkspaceCreationType.NewSmsAccount,
    },
  ];
  readonly subdomainValidators = [];
  readonly workspaceCreationTypeValues = WorkspaceCreationType;

  selectedWorkspaceCreationType = WorkspaceCreationType.ExistingSmsAccount;
  title: string;
  loading: boolean;
  debounceTime = 500;
  accountForm: FormGroup;
  accountId: string;
  accountName: string;

  unSubscribe = new Subject<void>();

  constructor(
    private readonly orgService: OrgService,
    public readonly modal: NgbActiveModal,
    private readonly toastrService: ToastrService,
    private readonly errorHandler: ErrorHandler,
    private readonly globalApiService: GlobalApiService,
    private readonly formBuilder: FormBuilder,
    private readonly userService: UserService,
    private readonly teamService: TeamService,
    private readonly i18nService: TmpI18NService,
    private readonly localStorageService: LocalStorageService,
  ) {
    const defaultCountryCode = CountryCodePerAwsRegion[environment.region];
    const [defaultCountry] = Countries.filter((country) => country.ccode === defaultCountryCode);
    this.defaultRegion = { ...defaultCountry, value: SitemateRegions[environment.region] };
    this.regions = [this.defaultRegion];
  }

  ngOnInit() {
    const { companyMetadata } = this.teamService.getCurrentTeam();
    const { accountId } = companyMetadata;
    const workspaceCreationOption = this.userService.isAdmin()
      ? WorkspaceCreationType.ExistingSmsAccount
      : WorkspaceCreationType.CurrentSmsAccount;
    this.accountId = accountId;

    this.initForm();

    this.accountForm.controls.accountId.setValue(accountId);
    this.accountForm.controls.workspaceCreationOption.setValue(workspaceCreationOption);
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }

  private initForm() {
    this.accountForm = this.formBuilder.group({
      workspaceName: new FormControl('', Validators.required),
      accountName: new FormControl({ value: '', disabled: true }, Validators.required),
      accountId: new FormControl(''),
      region: new FormControl(this.defaultRegion.value, Validators.required),
      subdomain: new FormControl(''),
      workspaceCreationOption: new FormControl(WorkspaceCreationType.ExistingSmsAccount, Validators.required),
    });

    this.setListeners();
  }

  private setListeners() {
    this.accountForm
      .get('accountId')
      .statusChanges.pipe(takeUntil(this.unSubscribe))
      .subscribe((status) => {
        if (status === 'INVALID') {
          this.accountForm.controls.accountName.setValue('', { emitEvent: false });
        }
      });

    this.accountForm
      .get('workspaceName')
      .valueChanges.pipe(takeUntil(this.unSubscribe))
      .subscribe((value) => {
        const createOnNewAccount =
          this.accountForm.controls.workspaceCreationOption.value === WorkspaceCreationType.NewSmsAccount;
        if (createOnNewAccount) {
          this.accountForm.controls.accountName.setValue(value, { emitEvent: false });
        }
      });

    this.accountForm
      .get('workspaceCreationOption')
      .valueChanges.pipe(takeUntil(this.unSubscribe))
      .subscribe((value) => {
        this.onWorkspaceCreationOptionChange(value);
      });
  }

  private getSubdomainSyncValidators() {
    return [
      Validators.required,
      this.cannotContainSpace,
      this.noUpperCase,
      this.noSpecialCharacters,
      this.notMoreThanFiftyCharacters,
      this.noInvalidHyphens,
    ];
  }

  private onWorkspaceCreationOptionChange(value: WorkspaceCreationType) {
    this.selectedWorkspaceCreationType = value;

    if (value === WorkspaceCreationType.ExistingSmsAccount) {
      this.accountForm.controls.accountId.setValidators([Validators.required, this.cannotContainSpace]);
      this.accountForm.controls.accountId.setAsyncValidators(this.asyncValidateAccountId());
      this.accountForm.controls.region.clearValidators();
      this.accountForm.controls.subdomain.clearValidators();
      this.accountForm.controls.subdomain.clearAsyncValidators();
      this.accountForm.controls.subdomain.setValue('', { emitEvent: false });
      this.accountForm.controls.accountName.setValue('', { emitEvent: false });
    }

    if (value === WorkspaceCreationType.NewSmsAccount) {
      this.accountForm.controls.accountId.clearValidators();
      this.accountForm.controls.accountId.clearAsyncValidators();
      this.accountForm.controls.accountId.setValue('', { emitEvent: false });
      this.accountForm.controls.accountName.setValue('', { emitEvent: false });
      this.accountForm.controls.region.setValidators(Validators.required);
      this.accountForm.controls.subdomain.setValidators(this.getSubdomainSyncValidators());
      this.accountForm.controls.subdomain.setAsyncValidators(this.asyncValidateSubdomain());
    }

    this.accountForm.updateValueAndValidity();
  }

  private asyncValidateAccountId(): AsyncValidatorFn {
    return (control: AbstractControl) =>
      of(control.value).pipe(
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        switchMap((value) => this.globalApiService.getAccount(value)),
        map((account) => {
          this.accountForm.controls.accountName.setValue(account.name, { emitEvent: false });

          return null;
        }),
        catchError((error: any) => {
          switch (error.status) {
            case 400:
            case 404:
              return of({ accountNotFound: true });
            default:
              this.errorHandler.handle(error);
              return of({ failedValidation: true });
          }
        }),
        first(),
      );
  }

  private asyncValidateSubdomain(): AsyncValidatorFn {
    return (control: AbstractControl) =>
      of(control.value).pipe(
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        switchMap((value) => this.globalApiService.validateSubdomain<SubdomainValidateResponse>(value)),
        map(() => null),
        catchError((error: { error: SubdomainValidateResponse }) => {
          const { subdomainExists, isValidSubdomain } = error.error;

          if (!isValidSubdomain && subdomainExists) {
            return of({ subdomainExists: true });
          }
          if (isValidSubdomain === false) {
            return of({ subdomainNotValid: true });
          }

          this.errorHandler.handle(error);
          return of({ failedValidation: true });
        }),
        first(),
      );
  }

  private cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if ((control.value as string).indexOf(' ') >= 0) {
      return { cannotContainSpace: true };
    }

    return null;
  }

  private noUpperCase(control: AbstractControl): ValidationErrors | null {
    if (control.value.match(/[A-Z]/)) {
      return { noUpperCase: true };
    }

    return null;
  }

  private noSpecialCharacters(control: AbstractControl): ValidationErrors | null {
    if (control.value.match(/^[a-zA-Z0-9- ]*$/)) {
      return null;
    }

    return { noSpecialCharacters: true };
  }

  private notMoreThanFiftyCharacters(control: AbstractControl): ValidationErrors | null {
    if (control.value.length > 50) {
      return { notMoreThanFiftyCharacters: true };
    }

    return null;
  }

  private noInvalidHyphens(control: AbstractControl): ValidationErrors | null {
    const validSubdomainRegex = /^[a-z0-9]+(-[a-z0-9]+)*$/;

    if (!control.value || control.value.match(validSubdomainRegex)) {
      return null;
    }

    return { noInvalidHyphens: true };
  }

  private requiredError(controlName: Field) {
    return this.accountForm.get(controlName).hasError('required');
  }

  private cannotContainSpaceError(controlName: Field) {
    return this.accountForm.get(controlName).hasError('cannotContainSpace');
  }

  private noSpecialCharactersError(controlName: Field) {
    return this.accountForm.get(controlName).hasError('noSpecialCharacters');
  }

  private noUpperCaseError(controlName: Field) {
    return this.accountForm.get(controlName).hasError('noUpperCase');
  }

  private limitExceededError(controlName: Field) {
    return this.accountForm.get(controlName).hasError('notMoreThanFiftyCharacters');
  }

  private subdomainExistsError(controlName: Field) {
    return this.accountForm.get(controlName).hasError('subdomainExists');
  }

  private subdomainNotValidError(controlName: Field) {
    return this.accountForm.get(controlName).hasError('subdomainNotValid');
  }

  private accountNotFoundError(controlName: Field) {
    return this.accountForm.get(controlName).hasError('accountNotFound');
  }

  private failedValidation(controlName: Field) {
    return this.accountForm.get(controlName).hasError('failedValidation');
  }

  private noInvalidHyphensError(controlName: Field) {
    return this.accountForm.get(controlName).hasError('noInvalidHyphens');
  }

  hasError(controlName: string) {
    return this.accountForm.get(controlName).touched && !!this.accountForm.get(controlName).errors;
  }

  errorMessageContent(controlName: Field) {
    if (this.requiredError(controlName)) {
      return 'Required';
    }
    if (this.cannotContainSpaceError(controlName)) {
      return 'Spaces are not allowed';
    }
    if (this.noSpecialCharactersError(controlName)) {
      return 'Special characters are not allowed';
    }
    if (this.noUpperCaseError(controlName)) {
      return 'Uppercase characters are not allowed';
    }
    if (this.limitExceededError(controlName)) {
      return 'Must not exceed 50 characters';
    }
    if (this.subdomainExistsError(controlName)) {
      return 'Must be unique';
    }
    if (this.subdomainNotValidError(controlName)) {
      return 'Invalid';
    }
    if (this.accountNotFoundError(controlName)) {
      return 'Sitemate Start Account could not be found with this ID.';
    }
    if (this.failedValidation(controlName)) {
      return 'Failed to validate';
    }

    if (this.noInvalidHyphensError(controlName)) {
      return 'Domains must not start/end with hyphens, or contain consecutive hyphens.';
    }

    return '';
  }

  dismiss() {
    this.modal.dismiss();
  }

  onSubmit() {
    this.accountForm.markAllAsTouched();
    this.accountForm.updateValueAndValidity({ onlySelf: true, emitEvent: false });

    if (this.accountForm.invalid || this.loading) {
      return;
    }

    const { workspaceId, _id: companyId } = this.teamService.getCurrentTeam().companyMetadata;
    const workspaceName = this.accountForm.value.workspaceName.trim();
    let workspaceCreation: IWorkspaceCreate;

    if (
      this.selectedWorkspaceCreationType === WorkspaceCreationType.ExistingSmsAccount ||
      this.selectedWorkspaceCreationType === WorkspaceCreationType.CurrentSmsAccount
    ) {
      workspaceCreation = {
        workspaceName,
        parentAccountId: this.accountForm.value.accountId,
        createdByOrgController:
          this.selectedWorkspaceCreationType === WorkspaceCreationType.CurrentSmsAccount,
        createdByWorkspaceSwitcher: true,
        originWorkspaceId: workspaceId,
      };
    } else {
      workspaceCreation = {
        workspaceName,
        accountName: workspaceName,
        region: this.accountForm.value.region,
        subdomain: this.accountForm.value.subdomain,
        createdByWorkspaceSwitcher: true,
      };
    }

    this.loading = true;

    this.addWorkspace(workspaceCreation, companyId);
  }

  private addWorkspace(workspaceCreation: IWorkspaceCreate, companyId: string) {
    if (this.selectedWorkspaceCreationType === WorkspaceCreationType.CurrentSmsAccount) {
      this.orgService
        .addWorkspaceOrgController(workspaceCreation, companyId)
        .pipe(takeUntil(this.unSubscribe))
        .subscribe(
          (company) => {
            this.toastrService.successByKey('workspaceCreated');
            company.isNew = true;
            this.loading = false;
            this.localStorageService.setItem(LocalStorageKeys.CreatingWorkspaceForOrgController, 'true');
            this.modal.close({ company });
          },
          (error) => {
            this.errorHandler.handle(error);
            this.loading = false;
          },
        );
    } else {
      this.orgService
        .addWorkspace(workspaceCreation)
        .pipe(takeUntil(this.unSubscribe))
        .subscribe(
          (company) => {
            this.toastrService.successByKey('workspaceCreated');
            company.isNew = true;
            this.loading = false;
            this.modal.close({ company });
          },
          (error) => {
            this.errorHandler.handle(error);
            this.loading = false;
          },
        );
    }
  }
}
