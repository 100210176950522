import { Component, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { EditSignatureTypes } from 'app/shared/model/edit-signature.model';
import { ToastrService } from 'app/shared/service/toastr.service';
import { UIComponentsTheme } from 'app/ui-components/ui-components-theme.enum';

import { LocalSignatureData } from './local-signature-data.model';
import { CCSignaturePadComponent } from '../../../shared/component/signature-pad/signature-pad.component';
import { defaultSignaturePadOptions } from '../default-signature-pad-options.model';

@Component({
  selector: 'cc-edit-signature',
  templateUrl: 'edit-signature.component.html',
  styleUrls: ['../edit-profile.component.scss'],
})
export class EditSignatureComponent {
  @ViewChild(CCSignaturePadComponent) signaturePad: CCSignaturePadComponent;
  @Input() form: FormGroup;
  @Input() signaturePadOptions: Record<string, any> = defaultSignaturePadOptions;

  readonly editSignatureTypes = EditSignatureTypes;
  readonly stateToggleTheme = UIComponentsTheme.Grey1;

  editSignatureType: EditSignatureTypes = EditSignatureTypes.Default;

  digitalFontSignature: FormGroup;
  drawSignatureData: LocalSignatureData = { value: '', hasEdited: false };
  uploadSignatureData: LocalSignatureData = { value: '', hasEdited: false };

  uploading = false;

  constructor(private readonly toastrService: ToastrService) {}

  ngOnInit() {
    const { signature, firstName, lastName } = this.form.controls;

    this.digitalFontSignature = new FormGroup({
      signature: new FormControl(signature.value ?? '', Validators.required),
      name: new FormControl(`${firstName.value} ${lastName.value}`, Validators.required),
    });
  }

  handleSignatureEditModeChange(editSignatureType: EditSignatureTypes) {
    this.editSignatureType = editSignatureType;

    const src = this.getLatestSignatureSrcValue()[editSignatureType];
    this.form.patchValue({ signature: src });
  }

  digitalSignatureChange() {
    this.form.patchValue({ signature: this.digitalFontSignature.controls.signature.value });
    this.form.controls.signature.markAsDirty();
  }

  drawComplete(event) {
    this.drawSignatureData.value = event.toDataURL();
    this.drawSignatureData.hasEdited = true;

    this.setFormSignatureValueAndMarkAsTouched(this.drawSignatureData.value);
  }

  clearSignature() {
    const pad = this.signaturePad.signaturePadCanvas.nativeElement;
    const context = pad.getContext('2d');
    context.clearRect(0, 0, pad.width, pad.height);

    this.drawSignatureData.value = '';
    this.form.patchValue({ signature: this.drawSignatureData.value });
  }

  uploadSignature(event) {
    this.uploading = true;
    this.uploadSignatureData.hasEdited = true;
    const [file] = event.target.files;

    if (file) {
      try {
        const reader = new FileReader();
        reader.onload = ({ target: { result } }) => {
          this.uploadSignatureData.value = result as string;

          this.setFormSignatureValueAndMarkAsTouched(this.uploadSignatureData.value);
          this.uploading = false;
        };

        reader.readAsDataURL(file);
      } catch (e) {
        this.toastrService.errorByKey('uploadSignatureError');
      }
    }
  }

  removeSignature() {
    this.uploadSignatureData.value = '';
    this.form.patchValue({ signature: this.uploadSignatureData.value });
  }

  private setFormSignatureValueAndMarkAsTouched(value: string) {
    this.form.patchValue({ signature: value });
    this.form.controls.signature.markAsTouched();
  }

  private getLatestSignatureSrcValue() {
    return {
      [EditSignatureTypes.Default]: this.digitalFontSignature.controls.signature.value,
      [EditSignatureTypes.Draw]: this.drawSignatureData.value,
      [EditSignatureTypes.Upload]: this.uploadSignatureData.value,
    };
  }
}
