import { Platform } from '@angular/cdk/platform';
import { Inject, Injectable } from '@angular/core';
import { defaultTo } from 'lodash-es';

import { EmailRegex } from '@site-mate/dashpivot-shared-library';

import { WINDOW } from 'app/shared/factory/window.factory';

@Injectable({ providedIn: 'root' })
export class AppUtilService {
  // regex used here matches the one in the backend
  public emailRegex = EmailRegex;

  constructor(
    @Inject(WINDOW) private readonly window: Window,
    private readonly platform: Platform,
  ) {}

  public get windowSize() {
    return Math.max(this.window.document.documentElement.clientWidth, defaultTo(this.window.innerWidth, 0));
  }

  public get isMobile() {
    return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  public get isSmallWindow() {
    const threshold = 576;
    return this.windowSize < threshold;
  }

  public get isTablet() {
    // Tablets are any mobile devices with a larger screen
    // MSStream doesn't exist on window
    const threshold = 1024;
    return this.windowSize >= threshold && this.isMobile && !('MSStream' in this.window);
  }

  public get isIOS() {
    return this.platform.IOS;
  }

  public clone<T>(input: T): T {
    return JSON.parse(JSON.stringify(input));
  }

  public dataUrlToFile(dataURL, filename) {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const integerArray = new Uint8Array(arrayBuffer);
    for (let index = 0; index < byteString.length; index++) {
      integerArray[index] = byteString.charCodeAt(index);
    }

    return new File([arrayBuffer], filename, { type: mimeString });
  }

  public padDate(value: string) {
    if (value) {
      return value.length === 1 ? `0${value}` : value;
    }
    return '00';
  }

  // determine whether the value is a number
  // https://github.com/airbnb/javascript#standard-library--isnan
  public isNumber(value: any) {
    return !Number.isNaN(Number(value));
  }
}
