"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhotoThumbnailSizes = void 0;
var PhotoThumbnailSizes;
(function (PhotoThumbnailSizes) {
    PhotoThumbnailSizes["ExtraSmall"] = "extra-small";
    PhotoThumbnailSizes["Small"] = "small";
    PhotoThumbnailSizes["Medium"] = "medium";
    PhotoThumbnailSizes["Large"] = "large";
    PhotoThumbnailSizes["ExtraLarge"] = "extra-large";
})(PhotoThumbnailSizes = exports.PhotoThumbnailSizes || (exports.PhotoThumbnailSizes = {}));
