import { concat } from 'lodash-es';

import {
  ComplementarySignatureRuleLogic,
  SignatureLogicTypes,
  SignatureRuleLogic,
  SignatureLogicPersonValues,
  UserPermissionTypes,
  SignatureLogicUserTypes,
} from '@site-mate/dashpivot-shared-library';

const UserTypesNumberKeys = {
  [SignatureLogicUserTypes.Contributor]: 1,
  [SignatureLogicUserTypes.Visitor]: 2,
  [SignatureLogicUserTypes.Any]: 128,
};

const UserPermissionTypesNumberKeys: Record<UserPermissionTypes, number> = {
  [UserPermissionTypes.GlobalAdmin]: 0,
  [UserPermissionTypes.TeamMember]: 1,
  [UserPermissionTypes.TeamController]: 2,
  [UserPermissionTypes.ProjectMember]: 4,
  [UserPermissionTypes.ProjectController]: 8,
  [UserPermissionTypes.CompanyMember]: 16,
  [UserPermissionTypes.CompanyController]: 32,
};

const ruleTypeToNumberKeysMap = {
  [SignatureLogicTypes.User]: UserTypesNumberKeys,
  [SignatureLogicTypes.Permission]: UserPermissionTypesNumberKeys,
};

/**
 * This function converts an array of values to a number while ignoring the order of the values in the array.
 * This means a values array and its equivalent will return the same number.
 * Example: values of ['contributor', 'visitor'] and ['visitor', 'contributor'] will return the same number
 * We can then use this number as a key to keep track of the number of times a values array or its equivalent has been seen.
 *
 * @param type type of the rule, determines which number keys to use
 * @param values array of values to be converted to a number key
 * @returns number representing the values array
 */
export function getRuleValuesNumberKey(
  type: SignatureLogicTypes,
  values: (SignatureLogicUserTypes | UserPermissionTypes)[],
): number {
  return values.reduce((acc, value) => acc + ruleTypeToNumberKeysMap[type][value], 0);
}

function mapPersonValueToNumber(personValue: SignatureLogicPersonValues) {
  const PersonValueNumberMap = {
    string: '1',
    fieldId: '2',
    userId: '3',
    formRole: '4',
    default: '0',
  };

  if (typeof personValue === 'string') {
    return PersonValueNumberMap.string;
  }
  if (typeof personValue === 'object') {
    if ('fieldId' in personValue) {
      return PersonValueNumberMap.fieldId;
    }
    if ('userId' in personValue) {
      return PersonValueNumberMap.userId;
    }
    if ('formRole' in personValue) {
      return PersonValueNumberMap.formRole;
    }
  }
  return PersonValueNumberMap.default;
}

function compareValues(
  firstPersonValue: SignatureLogicPersonValues,
  secondPersonValue: SignatureLogicPersonValues,
) {
  let result = 0;
  if (typeof firstPersonValue === 'string' && typeof secondPersonValue === 'string') {
    return firstPersonValue.localeCompare(secondPersonValue);
  }
  if (typeof firstPersonValue === 'object' && typeof secondPersonValue === 'object') {
    const keys = ['fieldId', 'userId', 'formRole'];
    keys.forEach((key) => {
      if (key in firstPersonValue && key in secondPersonValue) {
        result = firstPersonValue[key].localeCompare(secondPersonValue[key]);
      }
    });
  } else {
    result = mapPersonValueToNumber(firstPersonValue).localeCompare(
      mapPersonValueToNumber(secondPersonValue),
    );
  }

  return result;
}

export function getRuleValuesKeyPerson(values: SignatureLogicPersonValues[]): string {
  const sortedValues = values.sort(compareValues);
  return JSON.stringify(sortedValues);
}

export function flattenRuleTree(
  rule: SignatureRuleLogic | ComplementarySignatureRuleLogic,
): (SignatureRuleLogic | ComplementarySignatureRuleLogic)[] {
  const flattenedNestedRules =
    rule.complementaryRules?.map((complementaryRule) => flattenRuleTree(complementaryRule)) ?? [];

  return concat([rule], flattenedNestedRules).flat();
}

export function getRuleKey(rule: SignatureRuleLogic | ComplementarySignatureRuleLogic): string {
  const valuesNumberKey =
    rule.type === SignatureLogicTypes.Person
      ? getRuleValuesKeyPerson(rule.values)
      : getRuleValuesNumberKey(rule.type, rule.values as (SignatureLogicUserTypes | UserPermissionTypes)[]);
  return `${rule.type}-${rule.operator}-${valuesNumberKey}`;
}
