import { Injectable } from '@angular/core';
import { unescape } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class TmpI18NService {
  private readonly bundle: Record<string, string> = {};

  addMessage(id: string, message: string) {
    this.bundle[id] = message;
  }

  getMessage(id: string) {
    const bundleValue = this.bundle[id];
    return bundleValue ? unescape(bundleValue) : undefined;
  }
}
