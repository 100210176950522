<div class="table-column-number" [class.has-danger]="numberInput.invalid">
  <small class="text-muted column-label" *ngIf="columnDescription">{{ columnDescription }}</small>
  <input
    class="form-control text-number-input table-input"
    type="text"
    [pattern]="numberWithPlusOrMinusSign"
    ccScrollInputFocus=".form-modal"
    [(ngModel)]="cellData.value"
    [name]="cellName"
    #numberInput="ngModel"
    (blur)="onBlur()"
    (ngModelChange)="handleOnChange()"
    [placeholder]="placeholder"
  />
  <div class="sm-text-default-red" *ngIf="numberInput.invalid && numberInput.errors.pattern" i18n>
    Value must be a number
  </div>
</div>
