import { Injectable } from '@angular/core';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { isEmpty } from 'lodash-es';
import { BehaviorSubject, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { DashpivotEvent, EventNotifierService, EventTypes, User } from '@site-mate/dashpivot-shared-library';

import { LocalStorageService } from './local-storage.service';
import { environment } from '../../../environments/environment';
import { SegmentService } from '../../segment/segment.service';
import { UserService } from '../../user/user.service';
import { FeatureFlagKeys } from '../model/feature-flag.enum';
import { LocalStorageKeys } from '../model/local-storage-keys.enum';

@Injectable({ providedIn: 'root' })
export class FeatureFlagService {
  private flags = {} as Record<FeatureFlagKeys, BehaviorSubject<LaunchDarkly.LDFlagValue>>;
  private client: LaunchDarkly.LDClient;

  constructor(
    private readonly segmentService: SegmentService,
    private readonly userService: UserService,
    private readonly localStorageService: LocalStorageService,
  ) {}

  /**
   * Returns an observable value related to the flag requested.
   * @param flag - the {@link FeatureFlagKeys}
   * @returns - Observable<BehaviorSubject<LCValue>>.
   */
  getFlag(flag: FeatureFlagKeys) {
    return this.whenClientIsReady().pipe(switchMap(() => this.initializeFeatureFlag(flag)));
  }

  resetFeatureFlag() {
    this.client = null;
    this.flags = {} as Record<FeatureFlagKeys, BehaviorSubject<LaunchDarkly.LDFlagValue>>;
  }

  getLocalStorageFlag(flag: LocalStorageKeys) {
    return this.localStorageService.getItem(flag);
  }

  private initializeFeatureFlag(flag: FeatureFlagKeys) {
    if (!this.flags[flag]) {
      const variation = this.client.variation(flag);
      this.flags[flag] = new BehaviorSubject(variation);
      this.trackEvent(flag, variation);
    }
    return this.flags[flag];
  }

  private getClient() {
    return this.client
      ? of(this.client)
      : this.userService.currentUser.pipe(
          filter((user) => !isEmpty(user)),
          map(this.initClient.bind(this)),
        );
  }

  private initClient(user: User) {
    const { launchDarklyClientId } = environment;
    const { launchDarklyUser, hash } = this.getUserAttributes(user);
    this.client = LaunchDarkly.initialize(launchDarklyClientId, launchDarklyUser, { hash });
    this.client.on('change', this.applyChangeset.bind(this));
    return this.client;
  }

  private whenClientIsReady() {
    return this.getClient().pipe(
      switchMap(() => {
        return this.client.waitForInitialization();
      }),
    );
  }

  private getUserAttributes(user: User) {
    const launchDarklyUser: LaunchDarkly.LDUser = {
      name: user.fullName,
      key: user._id,
      email: user.email,
      custom: {
        isGlobalAdmin: user.isGlobalAdmin,
        timezone: user.timezone,
      },
      privateAttributeNames: ['name', 'email', 'custom.timezone'],
    };
    return { launchDarklyUser, hash: user.hashedIds.launchDarkly };
  }

  private applyChangeset(flags: LaunchDarkly.LDFlagChangeset): void {
    Object.entries(flags).forEach(([key, value]) => {
      if (this.flags[key]) {
        this.flags[key].next(value.current);
      }
    });
  }

  private trackEvent(flag: FeatureFlagKeys, variation: LaunchDarkly.LDFlagValue): void {
    const flagName = `Feature Flag: ${flag}` as unknown as EventTypes;
    void EventNotifierService.notify(
      new DashpivotEvent(flagName, { Context: variation }),
      this.segmentService,
    );
  }
}
