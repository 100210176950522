"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Countries = void 0;
// dataset used to match countries to continent: https://datahub.io/JohnSnowLabs/country-and-continent-codes-list
exports.Countries = [
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'US',
        label: 'USA',
        name: 'USA',
        value: '1',
        mcode: '+1',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'GB',
        label: 'United Kingdom (UK)',
        name: 'United Kingdom (UK)',
        value: '44',
        mcode: '+44',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'AF',
        name: 'Afghanistan',
        label: 'Afghanistan',
        value: '93',
        mcode: '+93',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'AL',
        name: 'Albania',
        label: 'Albania',
        value: '355',
        mcode: '+355',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'DZ',
        label: 'Algeria',
        name: 'Algeria',
        value: '213',
        mcode: '+213',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'AD',
        label: 'Andorra',
        name: 'Andorra',
        value: '376',
        mcode: '+376',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'AO',
        label: 'Angola',
        name: 'Angola',
        value: '244',
        mcode: '+244',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'AI',
        label: 'Anguilla',
        name: 'Anguilla',
        value: '1264',
        mcode: '+1264',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'AG',
        label: 'Antigua & Barbuda',
        name: 'Antigua & Barbuda',
        value: '1268',
        mcode: '+1268',
    },
    {
        continentCode: 'SA',
        continentName: 'South America',
        ccode: 'AR',
        label: 'Argentina',
        name: 'Argentina',
        value: '54',
        mcode: '+54',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'AM',
        label: 'Armenia',
        name: 'Armenia',
        value: '374',
        mcode: '+374',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'AW',
        label: 'Aruba',
        name: 'Aruba',
        value: '297',
        mcode: '+297',
    },
    {
        continentCode: 'OC',
        continentName: 'Oceania',
        ccode: 'AU',
        label: 'Australia',
        name: 'Australia',
        value: '61',
        mcode: '+61',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'AT',
        label: 'Austria',
        name: 'Austria',
        value: '43',
        mcode: '+43',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'AZ',
        label: 'Azerbaijan',
        name: 'Azerbaijan',
        value: '994',
        mcode: '+994',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'BS',
        label: 'Bahamas',
        name: 'Bahamas',
        value: '1242',
        mcode: '+1242',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'BH',
        label: 'Bahrain',
        name: 'Bahrain',
        value: '973',
        mcode: '+973',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'BD',
        label: 'Bangladesh',
        name: 'Bangladesh',
        value: '880',
        mcode: '+880',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'BB',
        label: 'Barbados',
        name: 'Barbados',
        value: '1246',
        mcode: '+1246',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'BY',
        label: 'Belarus',
        name: 'Belarus',
        value: '375',
        mcode: '+375',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'BE',
        label: 'Belgium',
        name: 'Belgium',
        value: '32',
        mcode: '+32',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'BZ',
        label: 'Belize',
        name: 'Belize',
        value: '501',
        mcode: '+501',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'BJ',
        label: 'Benin',
        name: 'Benin',
        value: '229',
        mcode: '+229',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'BM',
        label: 'Bermuda',
        name: 'Bermuda',
        value: '1441',
        mcode: '+1441',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'BT',
        label: 'Bhutan',
        name: 'Bhutan',
        value: '975',
        mcode: '+975',
    },
    {
        continentCode: 'SA',
        continentName: 'South America',
        ccode: 'BO',
        label: 'Bolivia',
        name: 'Bolivia',
        value: '591',
        mcode: '+591',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'BA',
        label: 'Bosnia Herzegovina',
        name: 'Bosnia Herzegovina',
        value: '387',
        mcode: '+387',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'BW',
        label: 'Botswana',
        name: 'Botswana',
        value: '267',
        mcode: '+267',
    },
    {
        continentCode: 'SA',
        continentName: 'South America',
        ccode: 'BR',
        label: 'Brazil',
        name: 'Brazil',
        value: '55',
        mcode: '+55',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'BN',
        label: 'Brunei',
        name: 'Brunei',
        value: '673',
        mcode: '+673',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'BG',
        label: 'Bulgaria',
        name: 'Bulgaria',
        value: '359',
        mcode: '+359',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'BF',
        label: 'Burkina Faso',
        name: 'Burkina Faso',
        value: '226',
        mcode: '+226',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'BI',
        label: 'Burundi',
        name: 'Burundi',
        value: '257',
        mcode: '+257',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'KH',
        label: 'Cambodia',
        name: 'Cambodia',
        value: '855',
        mcode: '+855',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'CM',
        label: 'Cameroon',
        name: 'Cameroon',
        value: '237',
        mcode: '+237',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'CA',
        label: 'Canada',
        name: 'Canada',
        value: '1',
        mcode: '+1',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'CV',
        label: 'Cape Verde Islands',
        name: 'Cape Verde Islands',
        value: '238',
        mcode: '+238',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'KY',
        label: 'Cayman Islands',
        name: 'Cayman Islands',
        value: '1345',
        mcode: '+1345',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'CF',
        label: 'Central African Republic',
        name: 'Central African Republic',
        value: '236',
        mcode: '+236',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'TD',
        name: 'Chad',
        label: 'Chad',
        value: '235',
        mcode: '+235',
    },
    {
        continentCode: 'SA',
        continentName: 'South America',
        ccode: 'CL',
        label: 'Chile',
        name: 'Chile',
        value: '56',
        mcode: '+56',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'CN',
        label: 'China',
        name: 'China',
        value: '86',
        mcode: '+86',
    },
    {
        continentCode: 'SA',
        continentName: 'South America',
        ccode: 'CO',
        label: 'Colombia',
        name: 'Colombia',
        value: '57',
        mcode: '+57',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'KM',
        label: 'Comoros',
        name: 'Comoros',
        value: '269',
        mcode: '+269',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'CG',
        label: 'Congo',
        name: 'Congo',
        value: '242',
        mcode: '+242',
    },
    {
        continentCode: 'OC',
        continentName: 'Oceania',
        ccode: 'CK',
        label: 'Cook Islands',
        name: 'Cook Islands',
        value: '682',
        mcode: '+682',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'CR',
        label: 'Costa Rica',
        name: 'Costa Rica',
        value: '506',
        mcode: '+506',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'CI',
        name: 'Côte d’Ivoire',
        label: 'Côte d’Ivoire',
        value: '225',
        mcode: '+225',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'HR',
        label: 'Croatia',
        name: 'Croatia',
        value: '385',
        mcode: '+385',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'CU',
        label: 'Cuba',
        name: 'Cuba',
        value: '53',
        mcode: '+53',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'CY',
        label: 'Cyprus - North',
        name: 'Cyprus - North',
        value: '90',
        mcode: '+90',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'CY',
        label: 'Cyprus - South',
        name: 'Cyprus - South',
        value: '357',
        mcode: '+357',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'CZ',
        label: 'Czech Republic',
        name: 'Czech Republic',
        value: '420',
        mcode: '+420',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'KP',
        name: 'Democratic People’s Republic of Korea',
        label: 'Democratic People’s Republic of Korea',
        value: '850',
        mcode: '+850',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'CD',
        name: 'Democratic Republic of the Congo',
        label: 'Democratic Republic of the Congo',
        value: '243',
        mcode: '+243',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'DK',
        label: 'Denmark',
        name: 'Denmark',
        value: '45',
        mcode: '+45',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'DJ',
        label: 'Djibouti',
        name: 'Djibouti',
        value: '253',
        mcode: '+253',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'DM',
        label: 'Dominica',
        name: 'Dominica',
        value: '1809',
        mcode: '+1809',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'DO',
        label: 'Dominican Republic',
        name: 'Dominican Republic',
        value: '1809',
        mcode: '+1809',
    },
    {
        continentCode: 'SA',
        continentName: 'South America',
        ccode: 'EC',
        label: 'Ecuador',
        name: 'Ecuador',
        value: '593',
        mcode: '+593',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'EG',
        label: 'Egypt',
        name: 'Egypt',
        value: '20',
        mcode: '+20',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'SV',
        label: 'El Salvador',
        name: 'El Salvador',
        value: '503',
        mcode: '+503',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'GQ',
        label: 'Equatorial Guinea',
        name: 'Equatorial Guinea',
        value: '240',
        mcode: '+240',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'ER',
        label: 'Eritrea',
        name: 'Eritrea',
        value: '291',
        mcode: '+291',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'EE',
        label: 'Estonia',
        name: 'Estonia',
        value: '372',
        mcode: '+372',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'ET',
        label: 'Ethiopia',
        name: 'Ethiopia',
        value: '251',
        mcode: '+251',
    },
    {
        continentCode: 'SA',
        continentName: 'South America',
        ccode: 'FK',
        label: 'Falkland Islands',
        name: 'Falkland Islands',
        value: '500',
        mcode: '+500',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'FO',
        label: 'Faroe Islands',
        name: 'Faroe Islands',
        value: '298',
        mcode: '+298',
    },
    {
        continentCode: 'OC',
        continentName: 'Oceania',
        ccode: 'FJ',
        label: 'Fiji',
        name: 'Fiji',
        value: '679',
        mcode: '+679',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'FI',
        label: 'Finland',
        name: 'Finland',
        value: '358',
        mcode: '+358',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'FR',
        label: 'France',
        name: 'France',
        value: '33',
        mcode: '+33',
    },
    {
        continentCode: 'SA',
        continentName: 'South America',
        ccode: 'GF',
        label: 'French Guiana',
        name: 'French Guiana',
        value: '594',
        mcode: '+594',
    },
    {
        continentCode: 'OC',
        continentName: 'Oceania',
        ccode: 'PF',
        label: 'French Polynesia',
        name: 'French Polynesia',
        value: '689',
        mcode: '+689',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'GA',
        label: 'Gabon',
        name: 'Gabon',
        value: '241',
        mcode: '+241',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'GM',
        label: 'Gambia',
        name: 'Gambia',
        value: '220',
        mcode: '+220',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'GE',
        label: 'Georgia',
        name: 'Georgia',
        value: '7880',
        mcode: '+7880',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'DE',
        label: 'Germany',
        name: 'Germany',
        value: '49',
        mcode: '+49',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'GH',
        label: 'Ghana',
        name: 'Ghana',
        value: '233',
        mcode: '+233',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'GI',
        label: 'Gibraltar',
        name: 'Gibraltar',
        value: '350',
        mcode: '+350',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'GR',
        label: 'Greece',
        name: 'Greece',
        value: '30',
        mcode: '+30',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'GL',
        label: 'Greenland',
        name: 'Greenland',
        value: '299',
        mcode: '+299',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'GD',
        label: 'Grenada',
        name: 'Grenada',
        value: '1473',
        mcode: '+1473',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'GP',
        label: 'Guadeloupe',
        name: 'Guadeloupe',
        value: '590',
        mcode: '+590',
    },
    {
        continentCode: 'OC',
        continentName: 'Oceania',
        ccode: 'GU',
        label: 'Guam',
        name: 'Guam',
        value: '671',
        mcode: '+671',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'GT',
        label: 'Guatemala',
        name: 'Guatemala',
        value: '502',
        mcode: '+502',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'GN',
        label: 'Guinea',
        name: 'Guinea',
        value: '224',
        mcode: '+224',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'GW',
        label: 'Guinea - Bissau',
        name: 'Guinea - Bissau',
        value: '245',
        mcode: '+245',
    },
    {
        continentCode: 'SA',
        continentName: 'South America',
        ccode: 'GY',
        label: 'Guyana',
        name: 'Guyana',
        value: '592',
        mcode: '+592',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'HT',
        label: 'Haiti',
        name: 'Haiti',
        value: '509',
        mcode: '+509',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'HN',
        label: 'Honduras',
        name: 'Honduras',
        value: '504',
        mcode: '+504',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'HK',
        label: 'Hong Kong',
        name: 'Hong Kong',
        value: '852',
        mcode: '+852',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'HU',
        label: 'Hungary',
        name: 'Hungary',
        value: '36',
        mcode: '+36',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'IS',
        label: 'Iceland',
        name: 'Iceland',
        value: '354',
        mcode: '+354',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'IN',
        label: 'India',
        name: 'India',
        value: '91',
        mcode: '+91',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'ID',
        label: 'Indonesia',
        name: 'Indonesia',
        value: '62',
        mcode: '+62',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'IQ',
        label: 'Iraq',
        name: 'Iraq',
        value: '964',
        mcode: '+964',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'IR',
        label: 'Iran',
        name: 'Iran',
        value: '98',
        mcode: '+98',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'IE',
        label: 'Ireland',
        name: 'Ireland',
        value: '353',
        mcode: '+353',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'IL',
        label: 'Israel',
        name: 'Israel',
        value: '972',
        mcode: '+972',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'IT',
        label: 'Italy',
        name: 'Italy',
        value: '39',
        mcode: '+39',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'JM',
        label: 'Jamaica',
        name: 'Jamaica',
        value: '1876',
        mcode: '+1876',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'JP',
        label: 'Japan',
        name: 'Japan',
        value: '81',
        mcode: '+81',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'JO',
        label: 'Jordan',
        name: 'Jordan',
        value: '962',
        mcode: '+962',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'KZ',
        label: 'Kazakhstan',
        name: 'Kazakhstan',
        value: '7',
        mcode: '+7',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'KE',
        label: 'Kenya',
        name: 'Kenya',
        value: '254',
        mcode: '+254',
    },
    {
        continentCode: 'OC',
        continentName: 'Oceania',
        ccode: 'KI',
        label: 'Kiribati',
        name: 'Kiribati',
        value: '686',
        mcode: '+686',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'KW',
        label: 'Kuwait',
        name: 'Kuwait',
        value: '965',
        mcode: '+965',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'KG',
        label: 'Kyrgyzstan',
        name: 'Kyrgyzstan',
        value: '996',
        mcode: '+996',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'LA',
        label: 'Laos',
        name: 'Laos',
        value: '856',
        mcode: '+856',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'LV',
        label: 'Latvia',
        name: 'Latvia',
        value: '371',
        mcode: '+371',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'LB',
        label: 'Lebanon',
        name: 'Lebanon',
        value: '961',
        mcode: '+961',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'LS',
        label: 'Lesotho',
        name: 'Lesotho',
        value: '266',
        mcode: '+266',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'LR',
        label: 'Liberia',
        name: 'Liberia',
        value: '231',
        mcode: '+231',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'LY',
        label: 'Libya',
        name: 'Libya',
        value: '218',
        mcode: '+218',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'LI',
        label: 'Liechtenstein',
        name: 'Liechtenstein',
        value: '417',
        mcode: '+417',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'LT',
        label: 'Lithuania',
        name: 'Lithuania',
        value: '370',
        mcode: '+370',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'LU',
        label: 'Luxembourg',
        name: 'Luxembourg',
        value: '352',
        mcode: '+352',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'MO',
        label: 'Macao',
        name: 'Macao',
        value: '853',
        mcode: '+853',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'MK',
        label: 'Macedonia',
        name: 'Macedonia',
        value: '389',
        mcode: '+389',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'MG',
        label: 'Madagascar',
        name: 'Madagascar',
        value: '261',
        mcode: '+261',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'MW',
        label: 'Malawi',
        name: 'Malawi',
        value: '265',
        mcode: '+265',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'MY',
        label: 'Malaysia',
        name: 'Malaysia',
        value: '60',
        mcode: '+60',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'MV',
        label: 'Maldives',
        name: 'Maldives',
        value: '960',
        mcode: '+960',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'ML',
        label: 'Mali',
        name: 'Mali',
        value: '223',
        mcode: '+223',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'MT',
        label: 'Malta',
        name: 'Malta',
        value: '356',
        mcode: '+356',
    },
    {
        continentCode: 'OC',
        continentName: 'Oceania',
        ccode: 'MH',
        label: 'Marshall Islands',
        name: 'Marshall Islands',
        value: '692',
        mcode: '+692',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'MQ',
        label: 'Martinique',
        name: 'Martinique',
        value: '596',
        mcode: '+596',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'MR',
        label: 'Mauritania',
        name: 'Mauritania',
        value: '222',
        mcode: '+222',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'MU',
        name: 'Mauritius',
        label: 'Mauritius',
        value: '230',
        mcode: '+230',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'YT',
        label: 'Mayotte',
        name: 'Mayotte',
        value: '269',
        mcode: '+269',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'MX',
        label: 'Mexico',
        name: 'Mexico',
        value: '52',
        mcode: '+52',
    },
    {
        continentCode: 'OC',
        continentName: 'Oceania',
        ccode: 'FM',
        label: 'Micronesia',
        name: 'Micronesia',
        value: '691',
        mcode: '+691',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'MD',
        label: 'Moldova',
        name: 'Moldova',
        value: '373',
        mcode: '+373',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'MC',
        label: 'Monaco',
        name: 'Monaco',
        value: '377',
        mcode: '+377',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'MN',
        label: 'Mongolia',
        name: 'Mongolia',
        value: '976',
        mcode: '+976',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'ME',
        name: 'Montenegro',
        label: 'Montenegro',
        value: '382',
        mcode: '+382',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'MS',
        label: 'Montserrat',
        name: 'Montserrat',
        value: '1664',
        mcode: '+1664',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'MA',
        label: 'Morocco',
        name: 'Morocco',
        value: '212',
        mcode: '+212',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'MZ',
        label: 'Mozambique',
        name: 'Mozambique',
        value: '258',
        mcode: '+258',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'MN',
        label: 'Myanmar',
        name: 'Myanmar',
        value: '95',
        mcode: '+95',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'NA',
        label: 'Namibia',
        name: 'Namibia',
        value: '264',
        mcode: '+264',
    },
    {
        continentCode: 'OC',
        continentName: 'Oceania',
        ccode: 'NR',
        label: 'Nauru',
        name: 'Nauru',
        value: '674',
        mcode: '+674',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'NP',
        label: 'Nepal',
        name: 'Nepal',
        value: '977',
        mcode: '+977',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'NL',
        label: 'Netherlands',
        name: 'Netherlands',
        value: '31',
        mcode: '+31',
    },
    {
        continentCode: 'OC',
        continentName: 'Oceania',
        ccode: 'NC',
        label: 'New Caledonia',
        name: 'New Caledonia',
        value: '687',
        mcode: '+687',
    },
    {
        continentCode: 'OC',
        continentName: 'Oceania',
        ccode: 'NZ',
        label: 'New Zealand',
        name: 'New Zealand',
        value: '64',
        mcode: '+64',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'NI',
        label: 'Nicaragua',
        name: 'Nicaragua',
        value: '505',
        mcode: '+505',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'NE',
        label: 'Niger',
        name: 'Niger',
        value: '227',
        mcode: '+227',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'NG',
        label: 'Nigeria',
        name: 'Nigeria',
        value: '234',
        mcode: '+234',
    },
    {
        continentCode: 'OC',
        continentName: 'Oceania',
        ccode: 'NU',
        label: 'Niue',
        name: 'Niue',
        value: '683',
        mcode: '+683',
    },
    {
        continentCode: 'OC',
        continentName: 'Oceania',
        ccode: 'NF',
        label: 'Norfolk Islands',
        name: 'Norfolk Islands',
        value: '672',
        mcode: '+672',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'NP',
        label: 'Northern Marianas',
        name: 'Northern Marianas',
        value: '670',
        mcode: '+670',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'NO',
        label: 'Norway',
        name: 'Norway',
        value: '47',
        mcode: '+47',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'OM',
        label: 'Oman',
        name: 'Oman',
        value: '968',
        mcode: '+968',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'PK',
        label: 'Pakistan',
        name: 'Pakistan',
        value: '92',
        mcode: '+92',
    },
    {
        continentCode: 'OC',
        continentName: 'Oceania',
        ccode: 'PW',
        label: 'Palau',
        name: 'Palau',
        value: '680',
        mcode: '+680',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'PA',
        label: 'Panama',
        name: 'Panama',
        value: '507',
        mcode: '+507',
    },
    {
        continentCode: 'OC',
        continentName: 'Oceania',
        ccode: 'PG',
        label: 'Papua New Guinea',
        name: 'Papua New Guinea',
        value: '675',
        mcode: '+675',
    },
    {
        continentCode: 'SA',
        continentName: 'South America',
        ccode: 'PY',
        label: 'Paraguay',
        name: 'Paraguay',
        value: '595',
        mcode: '+595',
    },
    {
        continentCode: 'SA',
        continentName: 'South America',
        ccode: 'PE',
        label: 'Peru',
        name: 'Peru',
        value: '51',
        mcode: '+51',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'PH',
        label: 'Philippines',
        name: 'Philippines',
        value: '63',
        mcode: '+63',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'PL',
        label: 'Poland',
        name: 'Poland',
        value: '48',
        mcode: '+48',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'PT',
        label: 'Portugal',
        name: 'Portugal',
        value: '351',
        mcode: '+351',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'PR',
        label: 'Puerto Rico',
        name: 'Puerto Rico',
        value: '1787',
        mcode: '+1787',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'QA',
        label: 'Qatar',
        name: 'Qatar',
        value: '974',
        mcode: '+974',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'KR',
        label: 'Republic of Korea',
        name: 'Republic of Korea',
        value: '82',
        mcode: '+82',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'RE',
        label: 'Reunion',
        name: 'Reunion',
        value: '262',
        mcode: '+262',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'RO',
        label: 'Romania',
        name: 'Romania',
        value: '40',
        mcode: '+40',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'RU',
        label: 'Russia',
        name: 'Russia',
        value: '7',
        mcode: '+7',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'RW',
        label: 'Rwanda',
        name: 'Rwanda',
        value: '250',
        mcode: '+250',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'VC',
        name: 'Saint Vincent and the Grenadines',
        label: 'Saint Vincent and the Grenadines',
        value: '784',
        mcode: '+784',
    },
    {
        continentCode: 'OC',
        continentName: 'Oceania',
        ccode: 'WS',
        name: 'Samoa',
        label: 'Samoa',
        value: '685',
        mcode: '+685',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'SM',
        label: 'San Marino',
        name: 'San Marino',
        value: '378',
        mcode: '+378',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'ST',
        label: 'Sao Tome & Principe',
        name: 'Sao Tome & Principe',
        value: '239',
        mcode: '+239',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'SA',
        label: 'Saudi Arabia',
        name: 'Saudi Arabia',
        value: '966',
        mcode: '+966',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'SN',
        label: 'Senegal',
        name: 'Senegal',
        value: '221',
        mcode: '+221',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'RS',
        label: 'Serbia',
        name: 'Serbia',
        value: '381',
        mcode: '+381',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'SC',
        label: 'Seychelles',
        name: 'Seychelles',
        value: '248',
        mcode: '+248',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'SL',
        label: 'Sierra Leone',
        name: 'Sierra Leone',
        value: '232',
        mcode: '+232',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'SG',
        label: 'Singapore',
        name: 'Singapore',
        value: '65',
        mcode: '+65',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'SK',
        label: 'Slovak Republic',
        name: 'Slovak Republic',
        value: '421',
        mcode: '+421',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'SI',
        label: 'Slovenia',
        name: 'Slovenia',
        value: '386',
        mcode: '+386',
    },
    {
        continentCode: 'OC',
        continentName: 'Oceania',
        ccode: 'SB',
        label: 'Solomon Islands',
        name: 'Solomon Islands',
        value: '677',
        mcode: '+677',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'SO',
        label: 'Somalia',
        name: 'Somalia',
        value: '252',
        mcode: '+252',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'ZA',
        label: 'South Africa',
        name: 'South Africa',
        value: '27',
        mcode: '+27',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'SS',
        name: 'South Sudan',
        label: 'South Sudan',
        value: '211',
        mcode: '+211',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'ES',
        label: 'Spain',
        name: 'Spain',
        value: '34',
        mcode: '+34',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'LK',
        label: 'Sri Lanka',
        name: 'Sri Lanka',
        value: '94',
        mcode: '+94',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'SH',
        label: 'St. Helena',
        name: 'St. Helena',
        value: '290',
        mcode: '+290',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'KN',
        label: 'St. Kitts',
        name: 'St. Kitts',
        value: '1869',
        mcode: '+1869',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'SC',
        label: 'St. Lucia',
        name: 'St. Lucia',
        value: '1758',
        mcode: '+1758',
    },
    {
        continentCode: 'SA',
        continentName: 'South America',
        ccode: 'SR',
        label: 'Suriname',
        name: 'Suriname',
        value: '597',
        mcode: '+597',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'SD',
        label: 'Sudan',
        name: 'Sudan',
        value: '249',
        mcode: '+249',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'SZ',
        label: 'Swaziland',
        name: 'Swaziland',
        value: '268',
        mcode: '+268',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'SE',
        label: 'Sweden',
        name: 'Sweden',
        value: '46',
        mcode: '+46',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'CH',
        label: 'Switzerland',
        name: 'Switzerland',
        value: '41',
        mcode: '+41',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'SY',
        label: 'Syria',
        name: 'Syria',
        value: '963',
        mcode: '+963',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'TW',
        label: 'Taiwan',
        name: 'Taiwan',
        value: '886',
        mcode: '+886',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'TJ',
        label: 'Tajikistan',
        name: 'Tajikistan',
        value: '992',
        mcode: '+992',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'TH',
        label: 'Thailand',
        name: 'Thailand',
        value: '66',
        mcode: '+66',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'TL',
        name: 'Timor Leste',
        label: 'Timor Leste',
        value: '670',
        mcode: '+670',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'TG',
        label: 'Togo',
        name: 'Togo',
        value: '228',
        mcode: '+228',
    },
    {
        continentCode: 'OC',
        continentName: 'Oceania',
        ccode: 'TO',
        label: 'Tonga',
        name: 'Tonga',
        value: '676',
        mcode: '+676',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'TT',
        label: 'Trinidad & Tobago',
        name: 'Trinidad & Tobago',
        value: '1868',
        mcode: '+1868',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'TN',
        label: 'Tunisia',
        name: 'Tunisia',
        value: '216',
        mcode: '+216',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'TR',
        label: 'Turkey',
        name: 'Turkey',
        value: '90',
        mcode: '+90',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'TM',
        label: 'Turkmenistan',
        name: 'Turkmenistan',
        value: '993',
        mcode: '+993',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'TC',
        label: 'Turks & Caicos Islands',
        name: 'Turks & Caicos Islands',
        value: '1649',
        mcode: '+1649',
    },
    {
        continentCode: 'OC',
        continentName: 'Oceania',
        ccode: 'TV',
        label: 'Tuvalu',
        name: 'Tuvalu',
        value: '688',
        mcode: '+688',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'UG',
        label: 'Uganda',
        name: 'Uganda',
        value: '256',
        mcode: '+256',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'UA',
        label: 'Ukraine',
        name: 'Ukraine',
        value: '380',
        mcode: '+380',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'AE',
        label: 'United Arab Emirates',
        name: 'United Arab Emirates',
        value: '971',
        mcode: '+971',
    },
    {
        continentCode: 'SA',
        continentName: 'South America',
        ccode: 'UY',
        label: 'Uruguay',
        name: 'Uruguay',
        value: '598',
        mcode: '+598',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'UZ',
        label: 'Uzbekistan',
        name: 'Uzbekistan',
        value: '998',
        mcode: '+998',
    },
    {
        continentCode: 'OC',
        continentName: 'Oceania',
        ccode: 'VU',
        label: 'Vanuatu',
        name: 'Vanuatu',
        value: '678',
        mcode: '+678',
    },
    {
        continentCode: 'EU',
        continentName: 'Europe',
        ccode: 'VA',
        label: 'Vatican City',
        name: 'Vatican City',
        value: '379',
        mcode: '+379',
    },
    {
        continentCode: 'SA',
        continentName: 'South America',
        ccode: 'VE',
        label: 'Venezuela',
        name: 'Venezuela',
        value: '58',
        mcode: '+58',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'VN',
        label: 'Vietnam',
        name: 'Vietnam',
        value: '84',
        mcode: '+84',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'VG',
        label: 'Virgin Islands - British',
        name: 'Virgin Islands - British',
        value: '1',
        mcode: '+1',
    },
    {
        continentCode: 'NA',
        continentName: 'North America',
        ccode: 'VI',
        label: 'Virgin Islands - US',
        name: 'Virgin Islands - US',
        value: '1',
        mcode: '+1',
    },
    {
        continentCode: 'OC',
        continentName: 'Oceania',
        ccode: 'WF',
        label: 'Wallis & Futuna',
        name: 'Wallis & Futuna',
        value: '681',
        mcode: '+681',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'YE',
        label: 'Yemen (North)',
        name: 'Yemen (North)',
        value: '969',
        mcode: '+969',
    },
    {
        continentCode: 'AS',
        continentName: 'Asia',
        ccode: 'YE',
        label: 'Yemen (South)',
        name: 'Yemen (South)',
        value: '967',
        mcode: '+967',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'ZM',
        label: 'Zambia',
        name: 'Zambia',
        value: '260',
        mcode: '+260',
    },
    {
        continentCode: 'AF',
        continentName: 'Africa',
        ccode: 'ZW',
        label: 'Zimbabwe',
        name: 'Zimbabwe',
        value: '263',
        mcode: '+263',
    },
];
