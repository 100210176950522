import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

import { LocalStorageService } from 'app/shared/service/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  private readonly tokenKey = 'jwtToken';

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly oidcSecurityService: OidcSecurityService,
  ) {}

  async getToken(): Promise<string> {
    const isAuthenticated = await this.oidcSecurityService.isAuthenticated().toPromise();

    if (!isAuthenticated) {
      return this.localStorageService.getItem(this.tokenKey);
    }

    return this.oidcSecurityService.getAccessToken().toPromise();
  }

  destroyToken() {
    this.localStorageService.removeItem(this.tokenKey);
  }
}
