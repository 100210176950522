import { Component } from '@angular/core';

@Component({
  selector: 'cc-workspace-loader',
  templateUrl: './workspace-loader.component.html',
  styleUrls: ['../../../styles/components/modal.scss'],
})
export class WorkspaceLoaderComponent {
  constructor() {}
}
