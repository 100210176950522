/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { ObjectId } from 'bson';

import {
  FieldKinds,
  HeaderFooterFieldKinds,
  PhotoThumbnailSizes,
  TableCellKinds,
  TableColumnWidths,
} from '@site-mate/dashpivot-shared-library';

import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { BannerKeys } from 'app/shared/model/banner-keys.enum';
import { BannerMetadata } from 'app/shared/model/banner-metadata.model';
import { BannerService } from 'app/shared/service/banner.service';
import { TeamService } from 'app/shared/service/team.service';

import { IFieldGroup } from './field-group.model';
import { IFieldGroupList } from './template-default-field.model';
import { TemplateFieldSizes } from './template-field-sizes.enum';
import { TemplateFieldTypes } from './template-field-types.enum';
import { appControlType, AppType } from '../app-control-type.model';
import { TemplateFieldGroupTypes } from '../template-edit/template-field-group/template-field-group-types.enum';
import { TemplateView } from '../template-view.model';

@Injectable({
  providedIn: 'root',
})
export class TemplateDefaultFieldTypeService {
  constructor(
    private i18nService: TmpI18NService,
    private bannersService: BannerService,
    private readonly teamService: TeamService,
  ) {}

  getTextFields() {
    const defaultText = {
      content: '',
      icon: 'text',
    };

    const text = {
      kind: FieldKinds.SingleLineInput,
      name: this.i18nService.getMessage('templateFieldSingleLineText'),
      description: this.i18nService.getMessage('templateFieldText'),
      ...defaultText,
    };

    const multiLineText = {
      kind: FieldKinds.MultiLineInput,
      name: this.i18nService.getMessage('templateFieldMultiLineText'),
      description: this.i18nService.getMessage('templateFieldText'),
      ...defaultText,
    };

    const preFilledText = {
      kind: FieldKinds.PreFilledText,
      name: this.i18nService.getMessage('templateFieldPreFilledText'),
      description: this.i18nService.getMessage('templateFieldText'),
      ...defaultText,
    };

    return [text, multiLineText, preFilledText];
  }

  getDateFields(isWorkflow: boolean) {
    const defaultDate = {
      kind: FieldKinds.Date,
      icon: 'calendar-clock',
      startDate: '',
      endDate: '',
    };

    const dateTimeRange = {
      name: this.i18nService.getMessage('templateFieldDateTimeRange'),
      description: this.i18nService.getMessage('templateFieldDate'),
      isExpiryDate: false,
      isSingleDate: false,
      isDateAndTime: true,
      ...defaultDate,
    };

    const dateRange = {
      name: this.i18nService.getMessage('templateFieldDateRange'),
      description: this.i18nService.getMessage('templateFieldDate'),
      isExpiryDate: false,
      isSingleDate: false,
      isDateAndTime: false,
      ...defaultDate,
    };

    const singleDateTime = {
      name: this.i18nService.getMessage('templateFieldSingleDateTime'),
      description: this.i18nService.getMessage('templateFieldDate'),
      isExpiryDate: false,
      isSingleDate: true,
      isDateAndTime: true,
      ...defaultDate,
    };

    const singleDate = {
      name: this.i18nService.getMessage('templateFieldSingleDate'),
      description: this.i18nService.getMessage('templateFieldDate'),
      isExpiryDate: false,
      isSingleDate: true,
      isDateAndTime: false,
      ...defaultDate,
    };

    const expiryDate = {
      name: this.i18nService.getMessage('templateFieldExpiryDate'),
      description: this.i18nService.getMessage('templateFieldDate'),
      isExpiryDate: true,
      isSingleDate: true,
      isDateAndTime: false,
      isNew: false,
      ...defaultDate,
    };

    if (!isWorkflow) {
      const subscriptionStatus = this.teamService.getCurrentSubscriptionStatus();

      expiryDate.isNew = this.bannersService.isVisibleBasedOnBannerTypeAndSubscription(
        BannerKeys.ExpiryDateInTimelineTemplates,
        subscriptionStatus,
        false,
      );
    }

    return [dateTimeRange, dateRange, singleDateTime, singleDate, expiryDate];
  }

  getCheckboxFields() {
    const defaultCheckbox = {
      kind: FieldKinds.YesNoCheckbox,
      icon: 'yes-no',
      description: '',
    };

    const YesNoCheckBox = {
      name: this.i18nService.getMessage('templateFieldYesNoCheckBox'),
      content: this.i18nService.getMessage('templateFieldYesNoCheckBox'),
      ...defaultCheckbox,
    };

    const YesNoNaCheckBox = {
      name: this.i18nService.getMessage('templateFieldYesNoNaCheckBox'),
      content: this.i18nService.getMessage('templateFieldYesNoCheckBox'),
      hasNaButton: true,
      ...defaultCheckbox,
    };

    const YesNoCheckBoxWithText = {
      name: this.i18nService.getMessage('templateFieldYesNoCheckBoxWithText'),
      content: this.i18nService.getMessage('templateFieldYesNoCheckBox'),
      hasTextInput: true,
      ...defaultCheckbox,
    };

    const YesNoNaCheckBoxWithText = {
      name: this.i18nService.getMessage('templateFieldYesNoNaCheckBoxWithText'),
      content: this.i18nService.getMessage('templateFieldYesNoCheckBox'),
      hasNaButton: true,
      hasTextInput: true,
      ...defaultCheckbox,
    };

    return [YesNoCheckBox, YesNoNaCheckBox, YesNoCheckBoxWithText, YesNoNaCheckBoxWithText];
  }

  getListFields() {
    const defaultList = {
      kind: FieldKinds.Category,
      icon: 'list',
      categorySource: appControlType.categorySource.manual,
      items: [],
    };

    const multipleChoice = {
      name: this.i18nService.getMessage('templateFieldMultipleChoice'),
      description: this.i18nService.getMessage('templateFieldList'),
      categoryType: appControlType.categoryType.inline,
      ...defaultList,
    };

    const dropdown = {
      name: this.i18nService.getMessage('templateFieldDropdown'),
      description: this.i18nService.getMessage('templateFieldList'),
      categoryType: appControlType.categoryType.dropdown,
      ...defaultList,
    };

    return [multipleChoice, dropdown];
  }

  getTableFields() {
    const columns = [
      {
        id: new ObjectId().toHexString(),
        heading: this.i18nService.getMessage('Column1'),
        kind: TableCellKinds.Text,
        width: TableColumnWidths.Small,
      },
      {
        id: new ObjectId().toHexString(),
        heading: this.i18nService.getMessage('Column2'),
        kind: TableCellKinds.Text,
        width: TableColumnWidths.Medium,
      },
      {
        id: new ObjectId().toHexString(),
        heading: this.i18nService.getMessage('Column3'),
        kind: TableCellKinds.Text,
        width: TableColumnWidths.Large,
      },
      {
        id: new ObjectId().toHexString(),
        heading: this.i18nService.getMessage('Column4'),
        kind: TableCellKinds.Text,
        width: TableColumnWidths.ExtraLarge,
      },
    ];

    const columnsWithIds = columns.map((column) => ({ _id: column.id, ...column }));

    const defaultTable = {
      icon: 'table',
      columns,
    };

    const table = {
      kind: FieldKinds.Table,
      name: this.i18nService.getMessage('templateFieldDefaultTable'),
      description: this.i18nService.getMessage('templateFieldTableDesc'),
      rows: [],
      ...defaultTable,
    };

    const preFilledTable = {
      kind: FieldKinds.PrefilledTable,
      name: this.i18nService.getMessage('templateFieldPrefilledTable'),
      description: this.i18nService.getMessage('templateFieldTableDesc'),
      rows: [{ columns: columnsWithIds }],
      ...defaultTable,
    };

    return [table, preFilledTable];
  }

  getSignatureFields(isWorkflow: boolean) {
    const defaultSignature = {
      icon: 'signature',
    };

    const signature = {
      kind: FieldKinds.SignatureArray,
      name: this.i18nService.getMessage('templateFieldSignature'),
      description: this.i18nService.getMessage('templateFieldSignature'),
      isManualSignature: false,
      ...defaultSignature,
    };

    const manualSignature = {
      kind: FieldKinds.SignatureArray,
      name: this.i18nService.getMessage('templateFieldManualSignature'),
      description: this.i18nService.getMessage('templateFieldSignature'),
      isManualSignature: true,
      isApprovalSignature: false,
      ...defaultSignature,
    };

    const approvalSignature = {
      kind: FieldKinds.Signature,
      name: this.i18nService.getMessage('templateFieldApprovalSignature'),
      description: this.i18nService.getMessage('templateFieldSignature'),
      isManualSignature: false,
      isApprovalSignature: true,
      ...defaultSignature,
    };

    const signatureFields = [signature, manualSignature];
    if (isWorkflow) {
      signatureFields.push(approvalSignature);
    }

    return signatureFields;
  }

  getSitemateFields() {
    const scanSitemateId = {
      kind: FieldKinds.SignonTable,
      name: this.i18nService.getMessage('templateFieldSignonTable'),
      description: this.i18nService.getMessage('templateFieldSignonTable'),
      rows: [],
      icon: 'sitemate',
    };
    return [scanSitemateId];
  }

  getPhotoFields() {
    const defaultPhoto = {
      kind: FieldKinds.Photo,
      icon: 'photo',
      description: this.i18nService.getMessage('templateFieldPhoto'),
    };

    return [
      {
        ...defaultPhoto,
        name: this.i18nService.getMessage('templateFieldPhotoExtraSmall'),
        photoThumbnailSize: PhotoThumbnailSizes.ExtraSmall,
        isNew: false,
      },
      {
        ...defaultPhoto,
        name: this.i18nService.getMessage('templateFieldPhotoSmall'),
        photoThumbnailSize: PhotoThumbnailSizes.Small,
        isNew: false,
      },
      {
        ...defaultPhoto,
        name: this.i18nService.getMessage('templateFieldPhotoMedium'),
        photoThumbnailSize: PhotoThumbnailSizes.Medium,
        isNew: false,
      },
      {
        ...defaultPhoto,
        name: this.i18nService.getMessage('templateFieldPhotoLarge'),
        photoThumbnailSize: PhotoThumbnailSizes.Large,
        isNew: false,
      },
      {
        ...defaultPhoto,
        name: this.i18nService.getMessage('templateFieldPhotoExtraLarge'),
        photoThumbnailSize: PhotoThumbnailSizes.ExtraLarge,
        isNew: false,
      },
    ];
  }

  getSketchField() {
    return [
      {
        kind: FieldKinds.Sketch,
        name: this.i18nService.getMessage('templateFieldSketch'),
        description: this.i18nService.getMessage('templateFieldSketch'),
        icon: 'sketch',
      },
    ];
  }

  getPageBreakField() {
    return [
      {
        kind: FieldKinds.PageBreak,
        name: this.i18nService.getMessage('templateFieldPageBreak'),
        description: this.i18nService.getMessage('templateFieldPageBreak'),
        icon: 'page-break',
      },
    ];
  }

  getPersonField() {
    return [
      {
        kind: FieldKinds.Person,
        name: this.i18nService.getMessage('templateFieldSinglePerson'),
        description: this.i18nService.getMessage('templateFieldPerson'),
        icon: 'user',
        isNew: this.bannersService.isVisibleBasedOnBannerTypeAndSubscription(
          BannerKeys.PersonField,
          this.teamService.getCurrentSubscriptionStatus(),
          false,
        ),
      },
    ];
  }

  getFieldGroups(templateType: string = AppType.list): IFieldGroup[] {
    const isWorkflow = templateType === AppType.workflow;
    return [
      { title: TemplateFieldTypes.Text, fields: this.getTextFields() },
      {
        title: TemplateFieldTypes.Date,
        fields: this.getDateFields(isWorkflow),
        fieldLocalStorageKey: BannerMetadata[BannerKeys.ExpiryDateInTimelineTemplates].localStorageKey,
      },
      { title: TemplateFieldTypes.YesNo, fields: this.getCheckboxFields() },
      { title: TemplateFieldTypes.List, fields: this.getListFields() },
      { title: TemplateFieldTypes.Table, fields: this.getTableFields() },
      { title: TemplateFieldTypes.SitemateIntegration, fields: this.getSitemateFields() },
      { title: TemplateFieldTypes.Signature, fields: this.getSignatureFields(isWorkflow) },
      { title: TemplateFieldTypes.Photos, fields: this.getPhotoFields() },
      {
        title: TemplateFieldTypes.Person,
        fields: this.getPersonField(),
        fieldLocalStorageKey: BannerMetadata[BannerKeys.PersonField].localStorageKey,
      },
      { title: TemplateFieldTypes.Sketch, fields: this.getSketchField() },
      { title: TemplateFieldTypes.PageBreak, fields: this.getPageBreakField() },
    ];
  }

  getHeaderFooterFieldObject(fieldKind: HeaderFooterFieldKinds) {
    const fieldKindProps = {
      [HeaderFooterFieldKinds.Logo]: {
        name: this.i18nService.getMessage('templateFieldLogo'),
        kind: HeaderFooterFieldKinds.Logo,
        size: TemplateFieldSizes.Large,
      },
      [HeaderFooterFieldKinds.Filepath]: {
        name: this.i18nService.getMessage('templateFieldFilePath'),
        kind: HeaderFooterFieldKinds.Filepath,
        size: TemplateFieldSizes.Large,
        contentPreviewText: this.i18nService.getMessage('templateFieldFilePathPreviewText'),
      },
      [HeaderFooterFieldKinds.TemplateId]: {
        name: this.i18nService.getMessage('templateFieldTemplateID'),
        kind: HeaderFooterFieldKinds.TemplateId,
        contentPreviewText: this.i18nService.getMessage('templateFieldTemplateID'),
      },
      [HeaderFooterFieldKinds.TemplateVersion]: {
        name: this.i18nService.getMessage('templateFieldTemplateVersion'),
        kind: HeaderFooterFieldKinds.TemplateVersion,
        contentPreviewText: '##',
      },
      [HeaderFooterFieldKinds.FormVersion]: {
        name: this.i18nService.getMessage('templateFieldFormVersion'),
        kind: HeaderFooterFieldKinds.FormVersion,
        contentPreviewText: '##',
      },
      [HeaderFooterFieldKinds.PdfCreationTimeAndDate]: {
        name: this.i18nService.getMessage('templateFieldPDFCreationTimeAndDate'),
        kind: HeaderFooterFieldKinds.PdfCreationTimeAndDate,
        size: TemplateFieldSizes.Medium,
        contentPreviewText: `[${this.i18nService.getMessage('templateFieldPDFCreationTimeAndDate')}]`,
      },
      [HeaderFooterFieldKinds.PageNumbers]: {
        name: this.i18nService.getMessage('templateFieldPDFPageNumber'),
        kind: HeaderFooterFieldKinds.PageNumbers,
        contentPreviewText: `[${this.i18nService.getMessage('templateFieldPDFPageNumber')}]`,
      },
      [HeaderFooterFieldKinds.UncontrolledVersionDisclaimer]: {
        name: this.i18nService.getMessage('templateFieldPDFExportDisclaimer'),
        kind: HeaderFooterFieldKinds.UncontrolledVersionDisclaimer,
        contentPreviewText: `[${this.i18nService.getMessage('templateFieldPDFExportDisclaimer')}]`,
      },
      [HeaderFooterFieldKinds.PrefilledText]: {
        name: this.i18nService.getMessage('templateFieldPreFilledText'),
        kind: HeaderFooterFieldKinds.PrefilledText,
      },
    };

    return fieldKindProps[fieldKind];
  }

  getBrandingFields = () => [this.getHeaderFooterFieldObject(HeaderFooterFieldKinds.Logo)];

  getFormMetadataFields = () => [
    this.getHeaderFooterFieldObject(HeaderFooterFieldKinds.Filepath),
    this.getHeaderFooterFieldObject(HeaderFooterFieldKinds.TemplateId),
    this.getHeaderFooterFieldObject(HeaderFooterFieldKinds.TemplateVersion),
    this.getHeaderFooterFieldObject(HeaderFooterFieldKinds.FormVersion),
  ];

  getPDFExportMetaData = () => [
    this.getHeaderFooterFieldObject(HeaderFooterFieldKinds.PdfCreationTimeAndDate),
    this.getHeaderFooterFieldObject(HeaderFooterFieldKinds.PageNumbers),
  ];

  getOtherFields = () => [
    this.getHeaderFooterFieldObject(HeaderFooterFieldKinds.UncontrolledVersionDisclaimer),
    this.getHeaderFooterFieldObject(HeaderFooterFieldKinds.PrefilledText),
  ];

  getHeaderFooterFields(): IFieldGroup[] {
    return [
      {
        title: TemplateFieldTypes.Branding,
        fields: this.getBrandingFields(),
        fieldToolTip: this.i18nService.getMessage('brandingToolTip'),
      },
      {
        title: TemplateFieldTypes.FormMetaData,
        fields: this.getFormMetadataFields(),
        fieldToolTip: this.i18nService.getMessage('formMetaDataToolTip'),
      },
      {
        title: TemplateFieldTypes.PDFExportMetaData,
        fields: this.getPDFExportMetaData(),
        fieldToolTip: this.i18nService.getMessage('pdfExportMetaDataToolTip'),
      },
      {
        title: TemplateFieldTypes.Other,
        fields: this.getOtherFields(),
        fieldToolTip: this.i18nService.getMessage('otherFieldMetadata'),
      },
    ];
  }

  getFieldGroupLists(templateView: TemplateView, templateType: string = AppType.list): IFieldGroupList {
    const fieldGroups =
      templateView === TemplateView.HeaderFooter
        ? this.getHeaderFooterFields()
        : this.getFieldGroups(templateType);

    const { newFieldGroups, defaultFieldGroups } = this.getNewAndDefaultFieldGroups(fieldGroups);

    return {
      [TemplateFieldGroupTypes.New]: newFieldGroups,
      [TemplateFieldGroupTypes.Default]: defaultFieldGroups,
    };
  }

  getNewAndDefaultFieldGroups(fieldGroups: IFieldGroup[]) {
    const defaultFieldGroups = fieldGroups
      .map((fieldGroup) => {
        const fields = fieldGroup.fields.filter((field) => !field.isNew && field.isEnabled !== false);
        return {
          title: fieldGroup.title,
          fields,
          fieldToolTip: fieldGroup.fieldToolTip,
          fieldLocalStorageKey: fieldGroup.fieldLocalStorageKey,
        };
      })
      .filter((fieldGroup) => fieldGroup.fields.length > 0);

    const newFieldGroups = fieldGroups
      .map((fieldGroup) => {
        const fields = fieldGroup.fields.filter((field) => field.isNew && field.isEnabled !== false);
        return { title: fieldGroup.title, fields, fieldLocalStorageKey: fieldGroup.fieldLocalStorageKey };
      })
      .filter((fieldGroup) => fieldGroup.fields.length > 0);

    return { newFieldGroups, defaultFieldGroups };
  }
}
