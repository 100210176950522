import { Directive, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

import { IntercomService } from '../../intercom/intercom.service';

@Directive({
  selector: '[ccHideIntercom]',
})
export class HideIntercomDirective implements OnInit, OnDestroy, OnChanges {
  @Input()
  ccHideIntercom;

  constructor(private intercomService: IntercomService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.ccHideIntercom) {
      const hide = changes.ccHideIntercom.currentValue;
      if (hide || hide === undefined) {
        this.intercomService.hide();
      } else if (hide === false) {
        this.intercomService.show();
      }
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.intercomService.show();
  }
}
