/* eslint max-lines: ["error", 302] */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { Company, FolderKinds, ItemStates, Project, UserTypes } from '@site-mate/dashpivot-shared-library';
import { CompanyPlanTypes, SubscriptionStatuses } from '@site-mate/sitemate-global-shared';

import { TeamWeb } from 'app/shared/model/team.model';
import { CompanyService } from 'app/shared/service/company.service';
import { HttpClientService } from 'app/shared/service/http-client.service';
import { ProjectService } from 'app/shared/service/project.service';

export interface IFolderNavigatedState {
  _id?: string;
  kind?: FolderKinds;
}

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  private dataChanged: Subject<any> = new Subject<any>();
  public dataChanged$ = this.dataChanged.asObservable();

  private currentTeamSubject = new BehaviorSubject<any>({} as TeamWeb);
  public currentTeam = this.currentTeamSubject.asObservable();

  private folderNavigatedSubject = new BehaviorSubject<IFolderNavigatedState>({});
  public navigatedFolder$ = this.folderNavigatedSubject.asObservable();

  private unSubscribe = new Subject<void>();

  private getFolderKind(team: TeamWeb): FolderKinds | null {
    if (team.isProject) {
      return FolderKinds.Project;
    }
    if (team.isCompany || team.isTemplateLibrary) {
      return FolderKinds.Company;
    }
    if (team.isTeam) {
      return FolderKinds.Team;
    }
    return null;
  }

  constructor(
    private readonly http: HttpClientService,
    private readonly projectService: ProjectService,
    private readonly companyService: CompanyService,
  ) {
    this.currentTeam.pipe(takeUntil(this.unSubscribe)).subscribe((team: TeamWeb) => {
      this.folderNavigatedSubject.next({ _id: team._id, kind: this.getFolderKind(team) });
    });
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }

  async setCurrentTeam(team: TeamWeb, providedProject?: Project, providedCompany?: Company) {
    let projectMetadata = providedProject;
    if (!projectMetadata) {
      const projectId = team.parents[0];
      projectMetadata = await this.projectService.getProject(projectId).toPromise();
    }

    let companyMetadata = providedCompany;
    const companyBillingPlan = companyMetadata?.billing?.planType;

    if (!companyMetadata || !companyBillingPlan) {
      const companyId = projectMetadata.parents[0];
      companyMetadata = await this.companyService.getCompany(companyId).toPromise();
    }

    this.currentTeamSubject.next({
      isTeam: true,
      _id: team._id,
      id: team._id,
      name: team.name,
      path: team.path,
      state: team.state,
      project: projectMetadata.name,
      projectId: projectMetadata._id,
      company: companyMetadata.name,
      companyId: companyMetadata._id,
      companyMetadata,
      projectMetadata,
      isTemplateLibrary: companyMetadata.isTemplateLibrary,
    });
  }

  getCurrentTeam(): TeamWeb {
    return this.currentTeamSubject.getValue();
  }

  clearCurrentTeam() {
    this.currentTeamSubject.next({} as TeamWeb);
  }

  setNavigatedFolder(folderId, kind: FolderKinds) {
    this.clearCurrentTeam();
    this.folderNavigatedSubject.next({ _id: folderId, kind });
  }

  getCurrentTeam$() {
    return this.currentTeamSubject.asObservable();
  }

  setCurrentCompany(companyMetadata: Company) {
    this.currentTeamSubject.next({
      companyMetadata,
      _id: companyMetadata._id,
      id: companyMetadata._id,
      name: companyMetadata.name,
      isCompany: true,
      isTemplateLibrary: companyMetadata.isTemplateLibrary,
    });
  }

  async setCurrentProject(project: Project) {
    const companyMetadata = await this.companyService.getCompany(project.parents[0]).toPromise();
    const projectMetadata = project;

    this.currentTeamSubject.next({
      _id: projectMetadata._id,
      id: projectMetadata._id,
      name: projectMetadata.name,
      state: projectMetadata.state,
      isProject: true,
      project: projectMetadata.name,
      projectId: projectMetadata._id,
      company: companyMetadata.name,
      companyId: companyMetadata._id,
      companyMetadata,
      projectMetadata,
      isTemplateLibrary: companyMetadata.isTemplateLibrary,
    });
  }

  getCompanyId(teamId: string) {
    return this.getTeam(teamId).pipe(
      switchMap((team) => this.projectService.getProject(team.parents[0])),
      switchMap((project) => of(project.parents[0])),
    );
  }

  updateCurrentEntityName(name: string) {
    const currentEntry = this.getCurrentTeam();
    this.currentTeamSubject.next({
      ...currentEntry,
      name,
    });
  }

  getUsers(teamId: string, userType?: UserTypes) {
    const userTypeQueryParam = userType ? `?userType=${userType}` : '';

    return this.http
      .get(`teams/${teamId}/users${userTypeQueryParam}`)
      .pipe(map((users) => users.filter((user) => !user.isTestUser)));
  }

  getTeam(id: string) {
    return this.http.get<TeamWeb>(`teams/${id}`);
  }

  getProjectByTeamId(id: string) {
    return this.http.get<Project>(`teams/${id}/project`);
  }

  getCompanyByTeamId(id: string) {
    return this.http.get<Company>(`teams/${id}/company`);
  }

  isTeamRoute(url: string) {
    return url.includes('/teams');
  }

  isCompanyRoute(url: string) {
    return url.includes('/companies');
  }

  isOrgRoute(url: string) {
    return url.includes('/companies');
  }

  isOrg(team: TeamWeb) {
    return team.isCompany;
  }

  isTeam(team: TeamWeb) {
    return !team.isCompany && !team.isProject;
  }

  isProject(team: TeamWeb) {
    return team.isProject;
  }

  isCompany(team: TeamWeb) {
    return team.isCompany;
  }

  removeFromTeam(teamId: string, userId: string) {
    this.dataChanged.next(true);
    return this.http.remove(`teams/${teamId}/users/${userId}`);
  }

  getCurrentPlanType(): CompanyPlanTypes {
    return this.getCurrentTeam().companyMetadata?.billing?.planType || CompanyPlanTypes.Legacy;
  }

  getCurrentSubscriptionStatus(): SubscriptionStatuses {
    return this.getCurrentTeam().companyMetadata?.billing?.subscription.status;
  }

  addMember(
    teamId: string,
    email: string,
    optionalParams: {
      isTestUser?: boolean;
      isTeamController?: boolean;
      userType?: UserTypes;
    },
  ) {
    this.dataChanged.next(true);
    return this.http.post(`v1/teams/${teamId}/users`, {
      email,
      isTestUser: optionalParams.isTestUser,
      isTeamController: optionalParams.isTeamController,
      userType: optionalParams.userType,
    });
  }

  setActiveFolderState(state: ItemStates) {
    const currentEntry = this.getCurrentTeam();
    this.currentTeamSubject.next({
      ...currentEntry,
      state,
    });
  }

  getCurrentState() {
    return this.currentTeamSubject.pipe(
      map((internalState: any) => {
        const state: any = {};

        if (internalState.isTeam) {
          state.team = {
            name: internalState.name,
            _id: internalState._id,
          };

          state.project = {
            name: internalState.project,
            _id: internalState.projectId,
          };

          state.company = {
            name: internalState.company,
            _id: internalState.companyId,
          };
        } else if (internalState.isProject) {
          state.project = {
            name: internalState.name,
            _id: internalState._id,
          };

          state.company = {
            name: internalState.company,
            _id: internalState.companyId,
          };
        } else if (internalState.isCompany) {
          state.company = {
            name: internalState.name,
            _id: internalState._id,
          };
        }

        return state;
      }),
    );
  }

  getPath(team: TeamWeb): string {
    let path: string[] = [];

    if (team.isTeam) {
      path = [team.companyId, team.projectId, team._id];
    } else if (team.isProject) {
      path = [team.companyId, team._id];
    } else if (team.isCompany) {
      path = [team._id];
    }

    return `/${path
      .filter(Boolean)
      .map((folder) => `folder:${folder}`)
      .join('/')}`;
  }
}
