import {
  AfterViewInit,
  Attribute,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';

import { StateToggleItemComponent } from '../state-toggle-item/state-toggle-item.component';
import { UIComponentsTheme } from '../ui-components-theme.enum';

@Component({
  selector: 'cc-state-toggle',
  templateUrl: './state-toggle.component.html',
  styleUrls: ['./state-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StateToggleComponent implements AfterViewInit, OnInit {
  @Input() theme: UIComponentsTheme;
  @Input() defaultValue: string;
  @ContentChildren(StateToggleItemComponent) items: QueryList<StateToggleItemComponent>;

  @Output() change = new EventEmitter<string>();
  @HostBinding('class') hostClass: string;

  private width: 'full' | 'fit';

  constructor(@Attribute('width') width: 'full' | 'fit' = 'fit') {
    this.width = width;
  }

  ngOnInit(): void {
    this.hostClass = `theme-${this.theme} ${this.width}-width`;
  }

  ngAfterViewInit(): void {
    this.items.forEach((item) => {
      item.select.subscribe(this.handleStateSelected.bind(this));
    });

    if (this.defaultValue) {
      // Push this to the next update cycle: https://angular.io/errors/NG0100
      setTimeout(() => this.selectState(this.defaultValue), 0);
    }
  }

  handleStateSelected(newState: string) {
    this.selectState(newState);

    this.change.emit(newState);
  }

  selectState(newState: string) {
    this.items.forEach((item) => {
      item.selected = item.value === newState;
    });
  }
}
