"use strict";
/* eslint-disable no-console */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Logger = exports.SupportedNodeEnv = exports.LogLevel = void 0;
var LogLevel;
(function (LogLevel) {
    LogLevel[LogLevel["DEV"] = 1] = "DEV";
    LogLevel[LogLevel["DEBUG"] = 2] = "DEBUG";
    LogLevel[LogLevel["INFO"] = 3] = "INFO";
    LogLevel[LogLevel["LOG"] = 4] = "LOG";
    LogLevel[LogLevel["WARN"] = 5] = "WARN";
    LogLevel[LogLevel["ERROR"] = 6] = "ERROR";
})(LogLevel = exports.LogLevel || (exports.LogLevel = {}));
var SupportedNodeEnv;
(function (SupportedNodeEnv) {
    SupportedNodeEnv["Development"] = "development";
    SupportedNodeEnv["Qa"] = "qa";
    SupportedNodeEnv["Production"] = "production";
})(SupportedNodeEnv = exports.SupportedNodeEnv || (exports.SupportedNodeEnv = {}));
const getNodeEnv = () => {
    if (process.env.NODE_ENV !== SupportedNodeEnv.Qa && process.env.NODE_ENV !== SupportedNodeEnv.Production) {
        return SupportedNodeEnv.Development;
    }
    return process.env.NODE_ENV;
};
const getLogLevel = () => {
    let MIN_LOG_LEVEL = LogLevel.DEBUG;
    const nodeEnv = getNodeEnv();
    if (nodeEnv === SupportedNodeEnv.Development) {
        MIN_LOG_LEVEL = LogLevel.DEBUG;
    }
    if (process.env.IS_LOCAL_DEVELOPMENT === 'true') {
        MIN_LOG_LEVEL = LogLevel.DEV;
    }
    if (process.env.LOG_LEVEL) {
        const overrideLogLevel = LogLevel[process.env.LOG_LEVEL];
        MIN_LOG_LEVEL = overrideLogLevel || MIN_LOG_LEVEL;
    }
    return MIN_LOG_LEVEL;
};
exports.Logger = (() => ({
    dev: (message, ...optionalParams) => {
        if (getLogLevel() <= LogLevel.DEV) {
            console.log(message, ...optionalParams);
        }
    },
    debug: (message, ...optionalParams) => {
        if (getLogLevel() <= LogLevel.DEBUG) {
            console.debug(message, ...optionalParams);
        }
    },
    info: (message, ...optionalParams) => {
        if (getLogLevel() <= LogLevel.INFO) {
            console.info(message, ...optionalParams);
        }
    },
    log: (message, ...optionalParams) => {
        if (getLogLevel() <= LogLevel.LOG) {
            console.log(message, ...optionalParams);
        }
    },
    warn: (message, ...optionalParams) => {
        if (getLogLevel() <= LogLevel.WARN) {
            console.warn(message, ...optionalParams);
        }
    },
    error: (message, ...optionalParams) => {
        console.error(message, ...optionalParams); // Error should always log.
    },
}))();
