import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import {
  DashpivotEvent,
  EventNotifierService,
  EventTypes,
  FieldKinds,
  TableCellData,
  TableCellValidator,
  TableCellValidatorFactory,
  TableColumn,
  TableColumnWidths,
} from '@site-mate/dashpivot-shared-library';

import { FormTableCellComponent } from 'app/form-fields/table/edit-cells/table-cell.component';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { SegmentService } from 'app/segment/segment.service';

@Component({
  templateUrl: './number.component.html',
  styleUrls: ['../table-cell.component.scss', '../../edit/form-table.component.scss'],
  selector: 'cc-table-cell-item-number',
})
export class FormTableNumberCellComponent implements FormTableCellComponent, OnInit {
  // eslint-disable-next-line prefer-regex-literals
  public readonly numberWithPlusOrMinusSign = new RegExp('^[-+]?\\d+\\.?\\d*$');

  @Input() tableKind: FieldKinds;
  @Input() columnDescription: string;
  @Input() cellData: TableCellData;
  @Input() headerColumn: TableColumn;
  @Input() cellName: string;

  @Output() invalid = new EventEmitter();
  @Output() onChange = new EventEmitter();

  placeholder: string;

  validator: TableCellValidator;

  constructor(
    private readonly segmentService: SegmentService,
    private readonly i18nService: TmpI18NService,
  ) {}

  ngOnInit() {
    this.validator = TableCellValidatorFactory.getValidator(this.cellData, this.headerColumn);

    this.placeholder =
      this.headerColumn?.width === TableColumnWidths.Small
        ? this.i18nService.getMessage('formNumberColumnPlaceholderTruncated')
        : this.i18nService.getMessage('formNumberColumnPlaceholder');
  }

  handleOnChange() {
    this.invalid.emit(this.validator.isValid());
    this.onChange.emit();
  }

  public onBlur() {
    this.invalid.emit(this.validator.isValid());

    const context = this.tableKind === FieldKinds.PrefilledTable ? 'Prefilled' : 'Default';
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.FormTableCellDataEntered, { Context: context }),
      this.segmentService,
    );
  }
}
