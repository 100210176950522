"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MATH_FORMULAS = exports.MATH_NOT_SUPPORTED_FORMULAS = exports.MATH_SUPPORTED_FORMULAS = exports.MATH_SUPPORTED_FORMULAS_WITH_INFO = void 0;
exports.MATH_SUPPORTED_FORMULAS_WITH_INFO = new Map([
    [
        'ABS',
        {
            syntax: 'ABS(Number)',
            description: 'Returns the absolute value of a number.',
        },
    ],
    [
        'ARABIC',
        {
            syntax: 'ARABIC(String)',
            description: 'Converts number from roman form.',
        },
    ],
    [
        'BASE',
        {
            syntax: 'BASE(Number, Radix, [Minimumlength])',
            description: 'Converts a positive integer to a specified base into a text from the numbering system.',
        },
    ],
    [
        'CEILING',
        {
            syntax: 'CEILING(Number, Significance)',
            description: 'Rounds a number up to the nearest multiple of Significance.',
        },
    ],
    [
        'CEILING.MATH',
        {
            syntax: 'CEILING.MATH(Number[, Significance[, Mode]])',
            description: 'Rounds a number up to the nearest multiple of Significance.',
        },
    ],
    [
        'CEILING.PRECISE',
        {
            syntax: 'CEILING.PRECISE(Number[, Significance])',
            description: 'Rounds a number up to the nearest multiple of Significance.',
        },
    ],
    [
        'COMBIN',
        {
            syntax: 'COMBIN(Number, Number)',
            description: 'Returns number of combinations (without repetitions).',
        },
    ],
    [
        'COMBINA',
        {
            syntax: 'COMBINA(Number, Number)',
            description: 'Returns number of combinations (with repetitions).',
        },
    ],
    [
        'COS',
        {
            syntax: 'COS(Number)',
            description: 'Returns the cosine of the given angle (in radians).',
        },
    ],
    [
        'COT',
        {
            syntax: 'COT(Number)',
            description: 'Returns the cotangent of the given angle (in radians).',
        },
    ],
    [
        'COUNTUNIQUE',
        {
            syntax: 'COUNTUNIQUE(Value1, [Value2, ...])',
            description: 'Counts the number of unique values in a list of specified values and ranges.',
        },
    ],
    [
        'CSC',
        {
            syntax: 'CSC(Number)',
            description: 'Returns the cosecans of the given angle (in radians).',
        },
    ],
    [
        'CSCH',
        {
            syntax: 'CSCH(Number)',
            description: 'Returns the hyperbolic cosecant of the given value.',
        },
    ],
    [
        'DECIMAL',
        {
            syntax: 'DECIMAL("Text", Radix)',
            description: 'Converts text with characters from a number system to a positive integer in the base radix given.',
        },
    ],
    [
        'DEGREES',
        {
            syntax: 'DEGREES(Number)',
            description: 'Converts radians into degrees.',
        },
    ],
    [
        'EVEN',
        {
            syntax: 'EVEN(Number)',
            description: 'Rounds a positive number up to the next even integer and a negative number down to the next even integer.',
        },
    ],
    [
        'FLOOR',
        {
            syntax: 'FLOOR(Number, Significance)',
            description: 'Rounds a number down to the nearest multiple of Significance.',
        },
    ],
    [
        'FLOOR.MATH',
        {
            syntax: 'FLOOR.MATH(Number[, Significance[, Mode]])',
            description: 'Rounds a number down to the nearest multiple of Significance.',
        },
    ],
    [
        'FLOOR.PRECISE',
        {
            syntax: 'FLOOR.PRECISE(Number[, Significance])',
            description: 'Rounds a number down to the nearest multiple of Significance.',
        },
    ],
    [
        'GCD',
        {
            syntax: 'GCD(Number1, Number2, ...)',
            description: 'Computes the greatest common divisor of numbers.',
        },
    ],
    [
        'INT',
        {
            syntax: 'INT(Number)',
            description: 'Rounds a number down to the nearest integer.',
        },
    ],
    [
        'ISO.CEILING',
        {
            syntax: 'ISO.CEILING(Number[, Significance])',
            description: 'Rounds a number up to the nearest multiple of Significance.',
        },
    ],
    [
        'LCM',
        {
            syntax: 'LCM(Number1, Number2, ...)',
            description: 'Computes the least common multiple of numbers.',
        },
    ],
    [
        'LN',
        {
            syntax: 'LN(Number)',
            description: 'Returns the natural logarithm based on the constant e of a number.',
        },
    ],
    [
        'LOG',
        {
            syntax: 'LOG(Number, Base)',
            description: 'Returns the logarithm of a number to the specified base.',
        },
    ],
    [
        'LOG10',
        {
            syntax: 'LOG10(Number)',
            description: 'Returns the base-10 logarithm of a number.',
        },
    ],
    [
        'MOD',
        {
            syntax: 'MOD(Dividend, Divisor)',
            description: 'Returns the remainder when one integer is divided by another.',
        },
    ],
    [
        'MROUND',
        {
            syntax: 'MROUND(Number, Base)',
            description: 'Rounds a number to the nearest multiple of Base.',
        },
    ],
    [
        'ODD',
        {
            syntax: 'ODD(Number)',
            description: 'Rounds a positive number up to the nearest odd integer and a negative number down to the nearest odd integer.',
        },
    ],
    [
        'PI',
        {
            syntax: 'PI()',
            description: 'Returns 3.14159265358979, the value of the mathematical constant PI to 14 decimal places.',
        },
    ],
    [
        'POWER',
        {
            syntax: 'POWER(Base, Exponent)',
            description: 'Returns a number raised to another number.',
        },
    ],
    [
        'PRODUCT',
        {
            syntax: 'PRODUCT(Number1, Number2, ..., Number30)',
            description: 'Returns product of numbers.',
        },
    ],
    [
        'QUOTIENT',
        {
            syntax: 'QUOTIENT(Dividend, Divisor)',
            description: 'Returns integer part of a division.',
        },
    ],
    [
        'RADIANS',
        {
            syntax: 'RADIANS(Number)',
            description: 'Converts degrees to radians.',
        },
    ],
    [
        'RAND',
        {
            syntax: 'RAND()',
            description: 'Returns a random number between 0 and 1.',
        },
    ],
    [
        'RANDBETWEEN',
        {
            syntax: 'RAND(Lowerbound, Upperbound)',
            description: 'Returns a random integer between two numbers.',
        },
    ],
    [
        'ROUND',
        {
            syntax: 'ROUND(Number, Count)',
            description: 'Rounds a number to a certain number of decimal places.',
        },
    ],
    [
        'ROUNDDOWN',
        {
            syntax: 'ROUNDDOWN(Number, Count)',
            description: 'Rounds a number down, toward zero, to a certain precision.',
        },
    ],
    [
        'ROUNDUP',
        {
            syntax: 'ROUNDUP(Number, Count)',
            description: 'Rounds a number up, away from zero, to a certain precision.',
        },
    ],
    [
        'SEC',
        {
            syntax: 'SEC(Number)',
            description: 'Returns the secant of the given angle (in radians).',
        },
    ],
    [
        'SECH',
        {
            syntax: 'SEC(Number)',
            description: 'Returns the hyperbolic secant of the given angle (in radians).',
        },
    ],
    [
        'SERIESSUM',
        {
            syntax: 'SERIESSUM(Number, Number, Number, Coefficients)',
            description: 'Evaluates series at a point.',
        },
    ],
    [
        'SIN',
        {
            syntax: 'SIN(Number)',
            description: 'Returns the sine of the given angle (in radians).',
        },
    ],
    [
        'SINH',
        {
            syntax: 'SINH(Number)',
            description: 'Returns the hyperbolic sine of the given value.',
        },
    ],
    [
        'SIGN',
        {
            syntax: 'SIGN(Number)',
            description: 'Returns sign of a number.',
        },
    ],
    [
        'SQRT',
        {
            syntax: 'SQRT(Number)',
            description: 'Returns the positive square root of a number.',
        },
    ],
    [
        'SQRTPI',
        {
            syntax: 'SQRTPI(Number)',
            description: 'Returns sqrt of number times pi.',
        },
    ],
    [
        'SUBTOTAL',
        {
            syntax: 'SUBTOTAL(Function, Number1, Number2, ... Number30)',
            description: 'Computes aggregation using function specified by number.',
        },
    ],
    [
        'SUM',
        {
            syntax: 'SUM(Number1, Number2, ..., Number30)',
            description: 'Sums up the values of the specified cells.',
        },
    ],
    [
        'SUMIF',
        {
            syntax: 'SUMIF(Range, Criteria, Sumrange)',
            description: 'Sums up the values of cells that belong to the specified range and meet the specified condition.',
        },
    ],
    [
        'SUMIFS',
        {
            syntax: 'SUMIFS(Sum_Range , Criterion_range1 , Criterion1 [ , Criterion_range2 , Criterion2 [,...]])',
            description: 'Sums up the values of cells that belong to the specified range and meet the specified sets of conditions.',
        },
    ],
    [
        'SUMPRODUCT',
        {
            syntax: 'SUMPRODUCT(Array1, Array2...Array30)',
            description: 'Multiplies corresponding elements in the given arrays, and returns the sum of those products.',
        },
    ],
    [
        'SUMSQ',
        {
            syntax: 'SUMSQ(Number1, Number2, ..., Number30)',
            description: 'Returns the sum of the squares of the arguments',
        },
    ],
    [
        'TAN',
        {
            syntax: 'TAN(Number)',
            description: 'Returns the tangent of the given angle (in radians).',
        },
    ],
    [
        'TANH',
        {
            syntax: 'TANH(Number)',
            description: 'Returns the hyperbolic tangent of the given value.',
        },
    ],
    [
        'TRUNC',
        {
            syntax: 'TRUNC(Number, Count)',
            description: 'Truncates a number by removing decimal places.',
        },
    ],
]);
exports.MATH_SUPPORTED_FORMULAS = [...exports.MATH_SUPPORTED_FORMULAS_WITH_INFO.keys()];
exports.MATH_NOT_SUPPORTED_FORMULAS = [
    'ACOS',
    'ACOSH',
    'ACOT',
    'ACOTH',
    'ASIN',
    'ASINH',
    'ATAN',
    'ATAN2',
    'ATANH',
    'COSH',
    'COTH',
    'EXP',
    'FACT',
    'FACTDOUBLE',
    'MULTINOMIAL',
    'ROMAN',
    'SUMX2MY2',
    'SUMX2PY2',
    'SUMXMY2',
];
exports.MATH_FORMULAS = exports.MATH_SUPPORTED_FORMULAS.concat(exports.MATH_NOT_SUPPORTED_FORMULAS);
