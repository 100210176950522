import { Injectable } from '@angular/core';
import { ObjectId } from 'bson';
import { cloneDeep, isEmpty } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

import {
  Field,
  FieldKinds,
  FormFieldValidatorFactory,
  LogicActions,
  LogicOperators,
  LogicRulesService,
} from '@site-mate/dashpivot-shared-library';

import { FormulaEngineService } from './formula-engine.service';
import { FieldWeb, LogicRuleWeb } from '../model/item.model';

@Injectable({ providedIn: 'root' })
export class LogicRuleService {
  public sharedService = new LogicRulesService();
  private formLogicRuleSource = new BehaviorSubject<any>({});
  formLogicRule = this.formLogicRuleSource.asObservable();

  addLogicRule(field: Field): LogicRuleWeb[] {
    return [
      ...(field.logicRules || []),
      {
        id: new ObjectId().toHexString(),
        logicOperator: LogicOperators.Equals,
        logicAction: LogicActions.ShowField,
        values: [],
        isOpen: true,
      },
    ];
  }

  haveFieldsDifferentLogicRules(currentField: FieldWeb, otherField: FieldWeb): boolean {
    return currentField.dependsOn.logicRuleId !== otherField?.dependsOn?.logicRuleId;
  }

  haveFieldsDifferentLogicFieldId(currentField: FieldWeb, otherField: FieldWeb): boolean {
    return currentField.dependsOn.fieldId !== otherField?.dependsOn?.fieldId;
  }

  dependsOnAnotherField(field: FieldWeb): boolean {
    return !isEmpty(field?.dependsOn);
  }

  hasLogicRules(field: FieldWeb): boolean {
    return !!field.logicRules?.length;
  }

  dependsOnRule(field: FieldWeb, rule: LogicRuleWeb): boolean {
    return field.dependsOn?.logicRuleId === rule._id;
  }

  evaluateLogicRules(formFields: FieldWeb[]): FieldWeb[] {
    return formFields.map((field) => {
      if (this.hasLogicRules(field)) {
        field.logicRules = this.sharedService.evaluate(field);
      }

      return field;
    });
  }

  logicRulesSubFieldsHaveData(logicRules: LogicRuleWeb[]): boolean {
    return logicRules.some(({ fields = [] }) => fields.some((subField) => this.hasFieldAnyData(subField)));
  }

  filterLogicRuleWhichAreGoingToClose(field: FieldWeb): LogicRuleWeb[] {
    const activeLogicRules = field.logicRules.filter((rule) => rule.isTrue);

    const fieldCopy = cloneDeep(field);
    const newLogicRules = this.sharedService.evaluate(fieldCopy);

    return activeLogicRules.filter((activeRule) =>
      newLogicRules.find((newRule) => newRule.id === activeRule.id && !newRule?.isTrue),
    );
  }

  resetFields({
    fieldsToReset,
    templateFields,
    formItems,
    formulaEngineService,
  }: {
    fieldsToReset: FieldWeb[];
    templateFields: FieldWeb[];
    formItems: FieldWeb[];
    formulaEngineService: FormulaEngineService;
  }) {
    if (!fieldsToReset.length) {
      return formItems;
    }

    const fieldsWithAnyData = this.filterFieldsToReset(fieldsToReset);

    fieldsWithAnyData.forEach((fieldWithData) => {
      const templateField = templateFields.find((tempField) => tempField._id === fieldWithData._id);
      const formItemIndex = formItems.findIndex((formField) => formField._id === fieldWithData._id);

      formItems[formItemIndex] = {
        ...cloneDeep(templateField),
        id: templateField._id,
        _dirty: false,
        _invalid: false,
      };

      if ([FieldKinds.Table, FieldKinds.PrefilledTable].includes(templateField.kind)) {
        formulaEngineService.clearSheet(formItems[formItemIndex].tableReference);
      }
    });

    return formItems;
  }

  getVisibleFields(formFields: FieldWeb[]): FieldWeb[] {
    return formFields.filter((field) => this.isFieldVisible(field, formFields));
  }

  isFieldVisible(field: FieldWeb, formFields: FieldWeb[]): boolean {
    if (!this.dependsOnAnotherField(field)) {
      return true;
    }

    const { fieldId, logicRuleId } = field.dependsOn;
    const ruleFound = formFields
      .find((fField) => fField.id === fieldId)
      .logicRules.find((rule) => rule.id === logicRuleId);

    return !!ruleFound?.isTrue;
  }

  private filterFieldsToReset(fieldsToReset: FieldWeb[]) {
    return fieldsToReset.filter(
      (field) =>
        field._invalid || field._dirty || this.hasFieldAnyData(field) || field.kind === FieldKinds.Table,
    );
  }

  private hasFieldAnyData(field: FieldWeb): boolean {
    const logicRuleFieldValidator = FormFieldValidatorFactory.getValidator(field);
    return logicRuleFieldValidator.hasAnyData(field);
  }
}
