<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
    <i class="cc-icon cc-icon-close sm-align-middle"></i>
  </button>
</div>
<h4 class="h4 modal-title">
  <span i18n>{{ title }}</span>
</h4>
<div class="modal-body px-5">
  <div>
    {{ body }}
  </div>
</div>
<div class="modal-footer justify-content-center">
  <button type="button" class="btn cancel-btn" i18n (click)="modal.dismiss()">Cancel</button>
  <button type="button" class="btn overwrite-btn" i18n (click)="modal.close()">{{ continueLabel }}</button>
</div>
