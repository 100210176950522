import { Component, EventEmitter, Output, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import {
  DashpivotEvent,
  EventNotifierService,
  EventTypes,
  IHierarchy,
} from '@site-mate/dashpivot-shared-library';

import { PhotoTagsService } from 'app/photo/photo-tags.service';
import { SegmentService } from 'app/segment/segment.service';
import { DecoratedPhotoTag, PhotoTagGroupWithDecoratedTags } from 'app/shared/model/photo-tag-group.model';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { UserService } from 'app/user/user.service';

@Component({
  selector: 'cc-tag-filter',
  templateUrl: 'tag-filter.component.html',
  styleUrls: ['tag-filter.component.scss'],
})
export class TagFilterComponent implements OnChanges, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  @Input()
  resetTagFilter;

  @Input() hierarchy: IHierarchy;

  @Output()
  filterTags = new EventEmitter<PhotoTagGroupWithDecoratedTags[]>();

  teamTagGroups: PhotoTagGroupWithDecoratedTags[];
  projectTagGroups: PhotoTagGroupWithDecoratedTags[];
  loadingTeamTags = true;
  loadingProjectTags = true;
  tagGroups: PhotoTagGroupWithDecoratedTags[] = [];

  constructor(
    private readonly userService: UserService,
    private readonly segmentService: SegmentService,
    private readonly photoTagsService: PhotoTagsService,
    private readonly errorHandler: ErrorHandler,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.resetTagFilter && changes.resetTagFilter.currentValue) {
      if (changes.resetTagFilter.currentValue) {
        this.resetUI();
      }
    }

    this.loadingTeamTags = true;
    const teamId = String(this.hierarchy.team._id);
    this.photoTagsService
      .getTeamTagGroups(teamId)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.loadingTeamTags = false;
        }),
      )
      .subscribe((teamTagGroups) => {
        this.teamTagGroups = teamTagGroups;
        this.tagGroups.push(...teamTagGroups);
      }, this.errorHandler.handle.bind(this.errorHandler));

    this.loadingProjectTags = true;
    const projectId = String(this.hierarchy.project._id);
    this.photoTagsService
      .getProjectTagGroups(projectId)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.loadingProjectTags = false;
        }),
      )
      .subscribe((projectTagGroups) => {
        this.projectTagGroups = projectTagGroups;
        this.tagGroups.push(...projectTagGroups);
      }, this.errorHandler.handle.bind(this.errorHandler));
  }

  toggleTag(tag: PhotoTagGroupWithDecoratedTags['tags'][0]) {
    tag.selected = !tag.selected;
    if (tag.selected) {
      void EventNotifierService.notify(
        new DashpivotEvent(EventTypes.PhotoFilterApplied, { Context: 'Photo Tag' }),
        this.segmentService,
      );
    }
    this.filterTags.emit(this.tagGroups);
  }

  resetSelection() {
    this.resetUI();
    this.filterTags.emit(this.tagGroups);
  }

  isProjectControllerOrAbove(): boolean {
    return this.userService.isProjectControllerOrAbove();
  }

  isTeamControllerOrAbove(): boolean {
    return this.userService.isTeamControllerOrAbove();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  trackEditProjectTagEvent() {}

  trackEditTeamTagEvent() {}

  private resetUI() {
    this.tagGroups
      .reduce((allTags, tagGroup) => [...allTags, ...tagGroup.tags], [] as DecoratedPhotoTag[])
      .forEach((tag) => {
        tag.selected = false;
      });
  }
}
