import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment-timezone';

@Injectable()
export class FormDateUtil {
  static isValidDate(value: any) {
    const date = new Date(value);
    return FormDateUtil.isNumber(date.getTime());
  }

  static isValidDateObj(d: NgbDateStruct) {
    return (
      d &&
      d.day &&
      FormDateUtil.isNumber(d.day) &&
      FormDateUtil.isNumber(d.month) &&
      d.year &&
      FormDateUtil.isNumber(d.year)
    );
  }

  static isValidTimeObj(t: NgbTimeStruct) {
    return (
      t &&
      FormDateUtil.isNumber(t.hour) &&
      t.hour >= 0 &&
      t.hour <= 23 &&
      FormDateUtil.isNumber(t.minute) &&
      t.minute >= 0 &&
      t.minute <= 59
    );
  }

  static isDateEqual(newValue?: Date | string, previousValue?: Date | string) {
    const newDateObj = FormDateUtil.convertToDateStruct(newValue);
    const previousDateObj = FormDateUtil.convertToDateStruct(previousValue);

    return FormDateUtil.isDateObjEqual(newDateObj, previousDateObj);
  }

  static isDateObjEqual(
    newValue: Partial<NgbDateStruct> | undefined,
    previousValue: Partial<NgbDateStruct> | undefined,
  ) {
    const newObjectHasKeys = Object.keys({ ...newValue }).length > 0;
    if (newValue === previousValue || (!newObjectHasKeys && !previousValue)) {
      return true;
    }
    if (newValue && previousValue) {
      return (
        newValue.year === previousValue.year &&
        newValue.month === previousValue.month &&
        newValue.day === previousValue.day
      );
    }
    return false;
  }

  static isTimeEqual(newValue?: Date | string, previousValue?: Date | string) {
    const newTimeObj = FormDateUtil.convertToTimeStruct(newValue);
    const previousTimeObj = FormDateUtil.convertToTimeStruct(previousValue);

    return FormDateUtil.isTimeObjEqual(newTimeObj, previousTimeObj);
  }

  static isTimeObjEqual(n: NgbTimeStruct | undefined, o: NgbTimeStruct | undefined) {
    if (n && o) {
      return n.hour === o.hour && n.minute === o.minute;
    }
    return !o;
  }

  static convertDateToUTCString(d: NgbDateStruct, t?: NgbTimeStruct): string {
    if (FormDateUtil.isEmpty(d) && FormDateUtil.isEmpty(t)) {
      return null;
    }

    if (FormDateUtil.isDateObjEmpty(d) && (!t || FormDateUtil.isTimeObjEmpty(t))) {
      return null;
    }

    const date = moment({ h: 0, m: 0, ms: 0 });

    // set date
    if (FormDateUtil.isValidDateObj(d)) {
      date.month(d.month - 1);
      date.date(d.day);
      date.year(d.year);
    }

    // set time
    if (FormDateUtil.isValidTimeObj(t)) {
      date.hour(t.hour);
      date.minutes(t.minute);
    }

    // return this date as UTC
    return date.toISOString();
  }

  static convertTimeToUTCString(t: NgbTimeStruct): string {
    if (FormDateUtil.isEmpty(t)) {
      return null;
    }

    // we're storing time in date object against a fixed date (2000-01-01)
    // we were using unix epoch (moment(0)), but it's not converted consistently across devices/browsers
    // due to different JS engines calculating daylight savings differently
    const date = moment('2000-01-01T00:00Z');

    // set time
    if (FormDateUtil.isValidTimeObj(t)) {
      date.hour(t.hour);
      date.minutes(t.minute);
    }

    // return this time as UTC
    return date.toISOString();
  }

  static convertToDateStruct(d: Date | string): any {
    if (FormDateUtil.isEmpty(d) || !FormDateUtil.isValidDate(d)) {
      return {};
    }

    const date = new Date(d);
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }

  static convertToTimeStruct(d: Date | string): NgbTimeStruct {
    if (FormDateUtil.isEmpty(d) || !FormDateUtil.isValidDate(d)) {
      return { hour: 0, minute: 0, second: 0 };
    }

    const date = new Date(d);
    return { hour: date.getHours(), minute: date.getMinutes(), second: 0 };
  }

  private static isEmpty(d: Date | string | NgbDateStruct | NgbTimeStruct) {
    return !d || d === '';
  }

  private static isDateObjEmpty(d: NgbDateStruct) {
    return !d || (!d.day && !d.month && !d.year);
  }

  private static isTimeObjEmpty(t: NgbTimeStruct) {
    return !t || (!t.hour && !t.minute);
  }

  private static isNumber(value: any) {
    return !Number.isNaN(Number(value));
  }
}
