import { Component, Input } from '@angular/core';

import { FlowsiteBannerPopoverMetadata } from '../flowsite-banner-popover-metadata.model';
import { FlowsiteTemplateReference } from '../flowsite-template-reference.model';

@Component({
  selector: 'cc-flowsite-banner-popover',
  templateUrl: './flowsite-banner-popover.component.html',
  styleUrls: ['../flowsite-banner.component.scss'],
})
export class FlowsiteBannerPopoverComponent {
  readonly flowsiteBannerPopoverMetadata = FlowsiteBannerPopoverMetadata;

  @Input({ required: true }) flowsiteReferenceType: FlowsiteTemplateReference;
}
