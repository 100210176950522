import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/browser';
import { Observable, catchError, from, retry, switchMap, throwError } from 'rxjs';

import {
  IAccount,
  IUpdateDashpivotBillingSettingsRequestDto,
  IUpdateAccountBillingSettingsRequestDto,
  Products,
  IGetSuperuserWorkspaceSwitcherResponseDto,
  IGetWorkspaceSwitcherResponseDto,
} from '@site-mate/sitemate-global-shared';

import { JwtService } from 'app/auth/jwt.service';
import { ITaskExecution } from 'app/sam/model/task-execution.model';
import { ITask } from 'app/sam/model/task.model';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GlobalApiService {
  private readonly UNKNOWN_ERROR = 0;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly jwtService: JwtService,
    private readonly errorHandler: ErrorHandler,
  ) {}

  validateSubdomain<T>(subdomain: string): Observable<T> {
    const url = `${environment.global.apiUrl}/accounts/validate/subdomain?subdomain=${subdomain}`;

    return this.httpClient.get<T>(url).pipe(
      catchError((error) => {
        if (error?.status === this.UNKNOWN_ERROR) {
          this.pingGlobalApiAndAddExtraInfoOnBugsnag(error, { isRequestingGlobalApi: true });

          this.errorHandler.handle(error, { isRequestingGlobalApi: true });
        } else {
          error.hideToastr = true;
        }

        return throwError(() => error);
      }),
    );
  }

  getAccount(accountId: string): Observable<IAccount> {
    const url = `/accounts/${accountId}`;

    return this.getWithRetry<IAccount>(url);
  }

  updateAccount(accountId: string, account: Partial<IAccount>): Observable<IAccount> {
    const url = `/accounts/${accountId}`;

    return this.patchWithRetry<IAccount>(url, account, { accountId });
  }

  getUserInfo(userIds: string[]): Observable<any> {
    const sitemateUserIdsString = userIds.join(',');
    const url = `/user-summary?userIds=${sitemateUserIdsString}`;

    return this.getWithRetry(url);
  }

  checkWorkspaceMigrationEligibility(fromAccountId: string, toAccountId: string): Observable<boolean> {
    const url = `/workspaces/validate/account-change-eligibility`;

    return this.getWithRetry<boolean>(url, { fromAccountId, toAccountId });
  }

  updateDashpivotBillingSettings(
    accountId: string,
    settings: IUpdateDashpivotBillingSettingsRequestDto,
  ): Observable<any> {
    const url = `/accounts/${accountId}/billing/dashpivot`;

    return this.patchWithRetry(url, settings);
  }

  updateAccountBillingSettings(
    accountId: string,
    settings: IUpdateAccountBillingSettingsRequestDto,
  ): Observable<any> {
    const url = `/accounts/${accountId}/billing`;

    return this.patchWithRetry(url, settings);
  }

  searchWorkspace(searchTerm: string): Observable<IGetSuperuserWorkspaceSwitcherResponseDto[]> {
    const params = new HttpParams({ fromObject: { name: searchTerm, product: Products.Dashpivot } });
    const url = `/workspaces/search`;
    return this.getWithRetry<IGetSuperuserWorkspaceSwitcherResponseDto[]>(url, params);
  }

  getAccessibleWorkspaces(): Observable<IGetWorkspaceSwitcherResponseDto[]> {
    const url = `/workspaces/access`;
    const params = new HttpParams({ fromObject: { product: Products.Dashpivot } });

    return this.getWithRetry<IGetWorkspaceSwitcherResponseDto[]>(url, params);
  }

  uploadSignature<T>(file: File, dataUrl?: string): Observable<T> {
    const formData = new FormData();
    formData.append('type', 'signature');
    formData.append('files', file);

    const context: Record<string, any> = { name: file.name, size: file.size };
    context.isSignatureUpload = true;

    if (dataUrl) {
      context.dataUrl = dataUrl;
    }

    return this.postWithRetry({ path: '/user/upload', body: formData, context });
  }

  listSamTasks(appId: string): Observable<ITask[]> {
    const url = `/sam/tasks?appId=${appId}`;
    return this.getWithRetry(url);
  }

  executeSamTask(taskId: string, inputs: Record<string, any>): Observable<ITaskExecution> {
    const url = `/sam/tasks/${taskId}/executions`;
    return this.postWithRetry({ path: url, body: inputs });
  }

  validateUserEmail(email: string): Observable<{ email: string }> {
    const url = `/users/email`;

    return this.postWithRetry<{ email: string }>({
      path: url,
      body: { email },
      includeAuth: false,
      handleErrors: false,
    });
  }

  isBusinessDomainInUse(domain: string): Observable<{ isBusinessDomainInUse: boolean }> {
    const url = `/workspaces/business-domain/${domain}`;

    return this.getWithRetry<{ isBusinessDomainInUse: boolean }>(url, {}, false);
  }

  private async getJwtHeaders(): Promise<{ Authorization: string; Authtype: string }> {
    const token = await this.jwtService.getToken();

    return {
      Authorization: token.trim(),
      Authtype: 'fusionauth',
    };
  }

  private getWithRetry<T>(path: string, params = {}, includeAuth = true): Observable<T> {
    const headers$ = includeAuth ? from(this.getJwtHeaders()) : from(Promise.resolve({}));

    return headers$.pipe(
      switchMap((headers) =>
        this.httpClient.get<T>(`${environment.global.apiUrl}${path}`, { headers, params }).pipe(
          retry({ count: 1, delay: 500 }),
          catchError((error) => {
            if (error?.status === this.UNKNOWN_ERROR) {
              this.pingGlobalApiAndAddExtraInfoOnBugsnag(error, { isRequestingGlobalApi: true });
            }

            error.hideToastr = true;
            this.errorHandler.handle(error, { isRequestingGlobalApi: true });
            return throwError(() => error);
          }),
        ),
      ),
    );
  }

  private postWithRetry<T>({
    path,
    body = {},
    context = {},
    includeAuth = true,
    handleErrors = true,
  }: {
    path: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- body can have any format.
    body?: Record<string, any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- context can have any format.
    context?: Record<string, any>;
    includeAuth?: boolean;
    handleErrors?: boolean;
  }): Observable<T> {
    const headers$ = includeAuth ? from(this.getJwtHeaders()) : from(Promise.resolve({}));

    return headers$.pipe(
      switchMap((headers) => {
        return this.httpClient.post<T>(`${environment.global.apiUrl}${path}`, body, { headers }).pipe(
          retry({ count: 1, delay: 500 }),
          catchError((error) => {
            if (error?.status === this.UNKNOWN_ERROR) {
              this.pingGlobalApiAndAddExtraInfoOnBugsnag(error, {
                ...context,
                path,
                isRequestingGlobalApi: true,
              });

              if (context?.isSignatureUpload) {
                error.toastKey = 'signatureUploadError';
              }
            }
            if (handleErrors) {
              this.errorHandler.handle(error, { ...context, isRequestingGlobalApi: true });
            }
            return throwError(() => error);
          }),
        );
      }),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Body can have any format.
  private patchWithRetry<T>(path: string, body: Record<string, any> = {}, context = {}) {
    const headers$ = from(this.getJwtHeaders());

    return headers$.pipe(
      switchMap((headers) =>
        this.httpClient.patch<T>(`${environment.global.apiUrl}${path}`, body, { headers }).pipe(
          retry({ count: 1, delay: 500 }),
          catchError((error) => {
            if (error?.status === this.UNKNOWN_ERROR) {
              this.pingGlobalApiAndAddExtraInfoOnBugsnag(error, {
                ...context,
                path,
                isRequestingGlobalApi: true,
              });
            }

            error.hideToastr = true;
            this.errorHandler.handle(error, { ...context, isRequestingGlobalApi: true });
            return throwError(() => error);
          }),
        ),
      ),
    );
  }

  private pingGlobalApiAndAddExtraInfoOnBugsnag(error, context?) {
    Bugsnag.leaveBreadcrumb('Error on Global API Service', { context });

    this.httpClient.get(`${environment.global.apiUrl}/user/server-ping`, { responseType: 'text' }).subscribe({
      next: () => this.errorHandler.addMetadataToBugsnag(error, context),
      error: (rxjsError) => this.errorHandler.addMetadataToBugsnagForRxjsError(error, rxjsError, context),
    });
  }
}
