"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableCellGenericCloner = void 0;
const __1 = require("../../..");
class TableCellGenericCloner {
    constructor(getListItems, formField) {
        this.getListItems = getListItems;
        this.formField = formField;
    }
    merge(field, templateCol, templateCell, _formCol, formCell) {
        return __awaiter(this, void 0, void 0, function* () {
            const updatedCell = 'kind' in field && field.kind === __1.FieldKinds.Table
                ? {
                    id: formCell.id,
                    kind: templateCol.kind,
                    headerColumnId: templateCol.id,
                    value: formCell.value,
                    item: formCell.item,
                }
                : Object.assign(Object.assign({}, templateCell), { value: formCell.value });
            return updatedCell;
        });
    }
    getBlankCell(templateCol) {
        return { value: '', headerColumnId: templateCol.id || String(templateCol._id) };
    }
}
exports.TableCellGenericCloner = TableCellGenericCloner;
