"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STATISTICAL_FORMULAS = exports.STATISTICAL_NOT_SUPPORTED_FORMULAS = exports.STATISTICAL_SUPPORTED_FORMULAS = exports.STATISTICAL_SUPPORTED_FORMULAS_WITH_INFO = void 0;
exports.STATISTICAL_SUPPORTED_FORMULAS_WITH_INFO = new Map([
    [
        'AVEDEV',
        {
            description: 'Returns the average deviation of the arguments.',
            syntax: 'AVEDEV(Number1, Number2, ...Number30)',
        },
    ],
    [
        'AVERAGE',
        {
            description: 'Returns the average of the arguments.',
            syntax: 'AVERAGE(Number1, Number2, ...Number30)',
        },
    ],
    [
        'AVERAGEA',
        { description: 'Returns the average of the arguments.', syntax: 'AVERAGEA(Value1, Value2, ... Value30)' },
    ],
    [
        'AVERAGEIF',
        {
            description: 'Returns the arithmetic mean of all cells in a range that satisfy a given condition.',
            syntax: 'AVERAGEIF(Range, Criterion [, Average_Range ])',
        },
    ],
    [
        'CORREL',
        {
            description: 'Returns the correlation coefficient between two data sets.',
            syntax: 'CORREL(Data1, Data2)',
        },
    ],
    [
        'COUNT',
        {
            description: 'Counts how many numbers are in the list of arguments.',
            syntax: 'COUNT(Value1, Value2, ... Value30)',
        },
    ],
    [
        'COUNTA',
        {
            description: 'Counts how many values are in the list of arguments.',
            syntax: 'COUNTA(Value1, Value2, ... Value30)',
        },
    ],
    ['COUNTBLANK', { description: 'Returns the number of empty cells.', syntax: 'COUNTBLANK(Range)' }],
    [
        'COUNTIF',
        {
            description: 'Returns the number of cells that meet with certain criteria within a cell range.',
            syntax: 'COUNTIF(Range, Criteria)',
        },
    ],
    [
        'COUNTIFS',
        {
            description: 'Returns the count of rows or columns that meet criteria in multiple ranges.',
            syntax: 'COUNTIFS(Range1, Criterion1 [, Range2, Criterion2 [, ...]])',
        },
    ],
    [
        'COVAR',
        {
            description: 'Returns the covariance between two data sets, population normalized.',
            syntax: 'COVAR(Data1, Data2)',
        },
    ],
    [
        'COVARIANCE.P',
        {
            description: 'Returns the covariance between two data sets, population normalized.',
            syntax: 'COVARIANCE.P(Data1, Data2)',
        },
    ],
    [
        'COVARIANCEP',
        {
            description: 'Returns the covariance between two data sets, population normalized.',
            syntax: 'COVARIANCEP(Data1, Data2)',
        },
    ],
    [
        'COVARIANCE.S',
        {
            description: 'Returns the covariance between two data sets, sample normalized.',
            syntax: 'COVARIANCE.S(Data1, Data2)',
        },
    ],
    [
        'COVARIANCES',
        {
            description: 'Returns the covariance between two data sets, sample normalized.',
            syntax: 'COVARIANCES(Data1, Data2)',
        },
    ],
    [
        'DEVSQ',
        { description: 'Returns sum of squared deviations.', syntax: 'DEVSQ(Number1, Number2, ...Number30)' },
    ],
    [
        'EXPON.DIST',
        {
            description: 'Returns density of a exponential distribution.',
            syntax: 'EXPON.DIST(Number1, Number2, Boolean)',
        },
    ],
    [
        'EXPONDIST',
        {
            description: 'Returns density of a exponential distribution.',
            syntax: 'EXPONDIST(Number1, Number2, Boolean)',
        },
    ],
    [
        'GAUSS',
        {
            description: 'Returns the probability of gaussian variable fall more than this many times standard deviation from mean.',
            syntax: 'GAUSS(Number)',
        },
    ],
    [
        'GEOMEAN',
        { description: 'Returns the geometric average.', syntax: 'GEOMEAN(Number1, Number2, ...Number30)' },
    ],
    [
        'HARMEAN',
        { description: 'Returns the harmonic average.', syntax: 'HARMEAN(Number1, Number2, ...Number30)' },
    ],
    ['LARGE', { description: 'Returns k-th largest value in a range.', syntax: 'LARGE(Range, K)' }],
    [
        'MAX',
        {
            description: 'Returns the maximum value in a list of arguments.',
            syntax: 'MAX(Number1, Number2, ...Number30)',
        },
    ],
    [
        'MAXA',
        {
            description: 'Returns the maximum value in a list of arguments.',
            syntax: 'MAXA(Value1, Value2, ... Value30)',
        },
    ],
    [
        'MEDIAN',
        {
            description: 'Returns the median of a set of numbers.',
            syntax: 'MEDIAN(Number1, Number2, ...Number30)',
        },
    ],
    [
        'MIN',
        {
            description: 'Returns the minimum value in a list of arguments.',
            syntax: 'MIN(Number1, Number2, ...Number30)',
        },
    ],
    [
        'MINA',
        {
            description: 'Returns the minimum value in a list of arguments.',
            syntax: 'MINA(Value1, Value2, ... Value30)',
        },
    ],
    ['SKEW', { description: 'Returns skeweness of a sample.', syntax: 'SKEW(Number1, Number2, ...Number30)' }],
    [
        'SKEW.P',
        { description: 'Returns skeweness of a population.', syntax: 'SKEW.P(Number1, Number2, ...Number30)' },
    ],
    [
        'SKEWP',
        {
            description: 'Returns skeweness of a population.',
            syntax: 'SKEWP(Number1, Number2, ...Number30)',
        },
    ],
    [
        'SLOPE',
        { description: 'Returns the slope of a linear regression line.', syntax: 'SLOPE(Array1, Array2)' },
    ],
    ['SMALL', { description: 'Returns k-th smallest value in a range.', syntax: 'SMALL(Range, K)' }],
    [
        'STDEV',
        { description: 'Returns standard deviation of a sample.', syntax: 'STDEV(Value1, Value2, ... Value30)' },
    ],
    [
        'STDEVA',
        { description: 'Returns standard deviation of a sample.', syntax: 'STDEVA(Value1, Value2, ... Value30)' },
    ],
    [
        'STDEVP',
        {
            description: 'Returns standard deviation of a population.',
            syntax: 'STDEVP(Value1, Value2, ... Value30)',
        },
    ],
    [
        'STDEV.P',
        {
            description: 'Returns standard deviation of a population.',
            syntax: 'STDEV.P(Value1, Value2, ... Value30)',
        },
    ],
    [
        'STDEVPA',
        {
            description: 'Returns standard deviation of a population.',
            syntax: 'STDEVPA(Value1, Value2, ... Value30)',
        },
    ],
    [
        'STDEV.S',
        {
            description: 'Returns standard deviation of a sample.',
            syntax: 'STDEV.S(Value1, Value2, ... Value30)',
        },
    ],
    [
        'STDEVS',
        {
            description: 'Returns standard deviation of a sample.',
            syntax: 'STDEVS(Value1, Value2, ... Value30)',
        },
    ],
    ['VAR', { description: 'Returns variance of a sample.', syntax: 'VAR(Value1, Value2, ... Value30)' }],
    ['VARA', { description: 'Returns variance of a sample.', syntax: 'VARA(Value1, Value2, ... Value30)' }],
    ['VARP', { description: 'Returns variance of a population.', syntax: 'VARP(Value1, Value2, ... Value30)' }],
    [
        'VAR.P',
        { description: 'Returns variance of a population.', syntax: 'VAR.P(Value1, Value2, ... Value30)' },
    ],
    [
        'VARPA',
        { description: 'Returns variance of a population.', syntax: 'VARPA(Value1, Value2, ... Value30)' },
    ],
    ['VAR.S', { description: 'Returns variance of a sample.', syntax: 'VAR.S(Value1, Value2, ... Value30)' }],
    [
        'VARS',
        {
            description: 'Returns variance of a sample.',
            syntax: 'VARS(Value1, Value2, ... Value30)',
        },
    ],
]);
exports.STATISTICAL_SUPPORTED_FORMULAS = [...exports.STATISTICAL_SUPPORTED_FORMULAS_WITH_INFO.keys()];
exports.STATISTICAL_NOT_SUPPORTED_FORMULAS = [
    'BESSELI',
    'BESSELJ',
    'BESSELK',
    'BESSELY',
    'BETA.DIST',
    'BETADIST',
    'BETA.INV',
    'BETAINV',
    'BINOM.DIST',
    'BINOMDIST',
    'BINOM.INV',
    'CHIDIST',
    'CHIINV',
    'CHIINVRT',
    'CHISQ.DIST',
    'CHIDISTRT',
    'CHISQ.DIST.RT',
    'CHISQ.INV',
    'CHISQ.INV.RT',
    'CHISQ.TEST',
    'CHITEST',
    'CONFIDENCE',
    'CONFIDENCE.NORM',
    'CONFIDENCE.T',
    'CRITBINOM',
    'FDIST',
    'FINV',
    'F.DIST',
    'F.DIST.RT',
    'FDISTRT',
    'F.INV',
    'F.INV.RT',
    'FINVRT',
    'FISHER',
    'FISHERINV',
    'F.TEST',
    'FTEST',
    'GAMMA',
    'GAMMA.DIST',
    'GAMMADIST',
    'GAMMALN',
    'GAMMALN.PRECISE',
    'GAMMA.INV',
    'GAMMAINV',
    'HYPGEOMDIST',
    'HYPGEOM.DIST',
    'LOGNORM.DIST',
    'LOGNORMDIST',
    'LOGNORM.INV',
    'LOGNORMINV',
    'LOGINV',
    'NEGBINOM.DIST',
    'NEGBINOMDIST',
    'NORM.DIST',
    'NORMDIST',
    'NORM.S.DIST',
    'NORMDIST',
    'NORM.INV',
    'NORMINV',
    'NORM.S.INV',
    'NORMSINV',
    'PEARSON',
    'PHI',
    'POISSON',
    'POISSON.DIST',
    'POISSONDIST',
    'RSQ',
    'STANDARDIZE',
    'STEYX',
    'TDIST',
    'T.DIST',
    'T.DIST.2T',
    'TDIST2T',
    'T.DIST.RT',
    'TDISTRT',
    'TINV',
    'T.INV',
    'T.INV.2T',
    'TINV2T',
    'TTEST',
    'T.TEST',
    'WEIBULL',
    'WEIBULL.DIST',
    'WEIBULLDIST',
    'Z.TEST',
    'ZTEST',
];
exports.STATISTICAL_FORMULAS = exports.STATISTICAL_SUPPORTED_FORMULAS.concat(exports.STATISTICAL_NOT_SUPPORTED_FORMULAS);
