<div
  class="main-view"
  [ngbPopover]="popover"
  container="body"
  triggers="mouseenter:mouseleave"
  placement="right-top"
  popoverClass="user-popover"
>
  <img class="avatar rounded-circle" [src]="getAvatarImage(user.avatarUrl)" alt="" />
  <div>
    <span>{{ user.firstName }}</span>
    <span>{{ user.lastName }}</span>
  </div>
</div>

<ng-template #popover>
  <img class="avatar rounded-circle" [src]="getAvatarImage(user.avatarUrl)" alt="" />
  <div class="name">{{ user.firstName }} {{ user.lastName }}</div>
  <div class="company">{{ user.companyName }}</div>
</ng-template>
