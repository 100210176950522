import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Renderer2,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { DateFormatService } from 'app/shared/service/date-format.service';

@Component({
  selector: 'cc-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss', './date-picker-within-table.component.scss'],
  providers: [
    // eslint-disable-next-line no-use-before-define
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatePickerComponent), multi: true },
  ],
})
export class DatePickerComponent implements ControlValueAccessor {
  today = this.calendar.getToday();
  @Input() name: string;
  @Input() model: NgbDateStruct;
  @Input() container: 'body' | '';
  @Input() icon = 'calendar';
  @Output() changeValue = new EventEmitter<NgbDateStruct>();
  @ViewChild('dateInput', { read: ElementRef }) dateInput: ElementRef;
  @ViewChild('dateButton', { read: ElementRef }) dateButton: ElementRef;

  placeholder: string;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly calendar: NgbCalendar,
    private readonly dateFormatService: DateFormatService,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.placeholder = this.dateFormatService.getDateFormat().toLowerCase();
  }

  setDisabledState(disabled: boolean) {
    if (this.dateInput) {
      this.renderer.setProperty(this.dateInput.nativeElement, 'disabled', disabled);
    }
    if (this.dateButton) {
      this.renderer.setProperty(this.dateButton.nativeElement, 'disabled', disabled);
    }
  }

  writeValue(value: NgbDateStruct) {
    this.model = value;
    this.changeDetectorRef.markForCheck();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onChange(value: NgbDateStruct) {
    this.changeValue.emit(value);
  }

  open() {
    this.dateInput.nativeElement.click();
  }

  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {};
}
