/* eslint-disable max-lines */
import { inject, Injectable } from '@angular/core';
import { ObjectId } from 'bson';
import { cloneDeep, flatten, remove } from 'lodash-es';
import { IndividualConfig } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import {
  CategorySourceTypes,
  Comment,
  PhotoThumbnailSizes,
  FieldKinds,
  ItemStates,
  HeaderFooterDefaultFields,
  HeaderFooterField,
  HeaderFooterFieldKinds,
  TableCellKinds,
  TableColumn,
  Template,
  TemplateOrientations,
  EventTypes,
  ILegacyList,
  Modules,
  IField,
} from '@site-mate/dashpivot-shared-library';
import { Flake } from '@site-mate/sitemate-global-shared/lib';

import { BusinessAttributeKind } from 'app/business-attributes/business-attribute-kind';
import { IBusinessAttribute } from 'app/business-attributes/business-attribute.model';
import {
  HeaderFooterControls,
  HeaderFooterEnum,
} from 'app/form-fields/header-footer/header-footer-controls.model';
import { PrefilledTextFieldCharacterLimit } from 'app/form-fields/header-footer/header-footer-field-item/header-footer-prefilled-text-field/prefilled-text-field-character-limit';
import { ApprovalSignatureService } from 'app/form-fields/signature/template/approval-signature.service';
import { AppHierarchy } from 'app/shared/model/app-hierarchy.enum';
import { ExportResponse } from 'app/shared/model/export-response.model';
import { FieldWeb } from 'app/shared/model/item.model';
import { TemplateFormsCount } from 'app/shared/model/template-forms-count.model';
import { Tab } from 'app/shared/model/template-tab.model';
import { TemplateWeb } from 'app/shared/model/template.model';
import { doNotAcceptEmptyStringOrSlashes } from 'app/shared/regex-helper';
import { EventsService } from 'app/shared/service/events/events.service';
import { HttpClientService } from 'app/shared/service/http-client.service';
import { LogicRuleService } from 'app/shared/service/logic-rules.service';
import { TeamService } from 'app/shared/service/team.service';
import { ToastrService } from 'app/shared/service/toastr.service';

import { TemplateDefaultFieldTypeService } from './template-default-fields/template-default-field-type.service';
import { TemplateListPropertyService } from './template-list-property.service';
import { TemplateView } from './template-view.model';

type LibraryTemplateFilters = { verticals?: string[]; roles?: string[]; functions?: string[] };

@Injectable({ providedIn: 'root' })
export class TemplatesService {
  private readonly templateListPropertyService = inject(TemplateListPropertyService);
  private readonly eventsService = inject(EventsService);
  private activeHeaderFooterFields: HeaderFooterFieldKinds[] = [];
  private headerFooterUpdatedSubject = new Subject<HeaderFooterFieldKinds[]>();
  private registerViewSettingSubject = new Subject<void>();
  private templateUpdatedSubject = new Subject<void>();

  public headerFooterUpdatedEvent = this.headerFooterUpdatedSubject.asObservable();
  public registerViewSettingEvent = this.registerViewSettingSubject.asObservable();
  public currentOpenTemplateFormulasVersion = new BehaviorSubject<number>(null);
  public templateUpdatedEvent = this.templateUpdatedSubject.asObservable(); // Triggered when template is updated

  constructor(
    private readonly http: HttpClientService,
    private readonly teamService: TeamService,
    private readonly toastr: ToastrService,
    private readonly logicRuleService: LogicRuleService,
    private readonly templateDefaultFieldTypeService: TemplateDefaultFieldTypeService,
    private readonly approvalSignatureService: ApprovalSignatureService,
  ) {}

  onRegisterViewSettingChange() {
    this.registerViewSettingSubject.next();
  }

  onTemplateUpdateChange() {
    this.templateUpdatedSubject.next();
  }

  onAddHeaderFooterField(newField: HeaderFooterFieldKinds) {
    this.activeHeaderFooterFields.push(newField);
    this.headerFooterUpdatedSubject.next(this.activeHeaderFooterFields);
  }

  onRemoveHeaderFooterField(removeField: HeaderFooterFieldKinds) {
    this.activeHeaderFooterFields = this.activeHeaderFooterFields.filter((field) => field !== removeField);
    this.headerFooterUpdatedSubject.next(this.activeHeaderFooterFields);
  }

  onClearHeaderFooter(fieldsToRemove: HeaderFooterFieldKinds[]) {
    fieldsToRemove.forEach((field) => {
      this.onRemoveHeaderFooterField(field);
    });

    this.activeHeaderFooterFields = this.activeHeaderFooterFields.filter(
      (field) => !fieldsToRemove.includes(field),
    );

    this.headerFooterUpdatedSubject.next(this.activeHeaderFooterFields);
  }

  onRevertToDefaultHeaderFooter(section: HeaderFooterEnum) {
    const fixedHeaderFields = [
      HeaderFooterFieldKinds.TemplateName,
      HeaderFooterFieldKinds.FormCreatorAndCreationTime,
    ];

    const defaultHeaderFooterFields: HeaderFooterFieldKinds[] = HeaderFooterDefaultFields[section]
      .filter((field) => !fixedHeaderFields.includes(field.kind))
      .map((field) => field.kind);

    this.activeHeaderFooterFields = [...this.activeHeaderFooterFields, ...defaultHeaderFooterFields];
    this.headerFooterUpdatedSubject.next(this.activeHeaderFooterFields);
  }

  getActiveHeaderFooterFields() {
    return this.activeHeaderFooterFields;
  }

  clearActiveHeaderFooterFields() {
    this.activeHeaderFooterFields = [];
  }

  setActiveHeaderFooterFields(activeFields: HeaderFooterFieldKinds[]) {
    this.activeHeaderFooterFields = activeFields;
  }

  getOrganisationApps(
    companyId: string,
    state = ItemStates.Active,
    kind = Modules.App,
  ): Observable<Template[]> {
    return this.http.get(`companies/${companyId}/apps?state=${state}&kind=${kind}`);
  }

  getApp(templateId: string): Observable<Template> {
    return this.http.get(`apps/${templateId}`);
  }

  getCompanyTemplatesFormsCount(companyId): Observable<TemplateFormsCount[]> {
    return this.http.get(`companies/${companyId}/templates-forms-count`);
  }

  getTemplatesByTeam(teamId: string, state = ItemStates.Active): Observable<Template[]> {
    return this.http.get(`teams/${teamId}/apps?state=${state}`);
  }

  getTemplatesCountForTeam(teamId: string): Observable<number> {
    return this.http.get(`teams/${teamId}/apps/count`);
  }

  getTeamTemplatesFormsCount(teamId: string): Observable<TemplateFormsCount[]> {
    return this.http.get(`teams/${teamId}/templates-forms-count`);
  }

  getTeamAppsForMakingChart(teamId, chartType) {
    return this.http.get(`teams/${teamId}/apps-for-charts`, { chartType });
  }

  getCompanyAppsForMakingChart(companyId, chartType) {
    return this.http.get(`companies/${companyId}/apps-for-charts`, { chartType });
  }

  getDeployedTemplates(companyId): Observable<Template[]> {
    return this.http.get(`companies/${companyId}/deployed-templates`);
  }

  getComments(templateId: string): Observable<Comment[]> {
    return this.http.get<Comment[]>(`apps/${templateId}/comments`);
  }

  getTemplateVersion(templateId: string, version: number): Observable<Template> {
    return this.http.get<Template>(`apps/${templateId}/versions/${version}`);
  }

  getLibraryTemplates(filters: IBusinessAttribute[] = []): Observable<Template[]> {
    const queryFilters = this.convertBusinessAttributesToQueryFilters(filters);
    return this.http.get(`template-libraries/apps`, queryFilters);
  }

  trackFieldEvent(field: FieldWeb | IField, event: EventTypes) {
    let Context: string;
    if (field.kind === FieldKinds.Photo) {
      const eventContexts = new Map<PhotoThumbnailSizes, string>([
        [PhotoThumbnailSizes.ExtraSmall, 'Photos (XS)'],
        [PhotoThumbnailSizes.Small, 'Photos (S)'],
        [PhotoThumbnailSizes.Medium, 'Photos (M)'],
        [PhotoThumbnailSizes.Large, 'Photos (L)'],
        [PhotoThumbnailSizes.ExtraLarge, 'Photos (XL)'],
      ]);

      Context = eventContexts.get(field.photoThumbnailSize);
    } else {
      Context = field.kind;
    }

    this.eventsService.trackEvent(event, { Context });
  }

  convertBusinessAttributesToQueryFilters(businessAttributes: IBusinessAttribute[]) {
    return businessAttributes.reduce((acc, attr) => {
      switch (attr.kind) {
        case BusinessAttributeKind.Vertical:
          if (!acc.verticals) {
            acc.verticals = [];
          }
          acc.verticals.push(attr._id);
          break;
        case BusinessAttributeKind.Role:
          if (!acc.roles) {
            acc.roles = [];
          }
          acc.roles.push(attr._id);
          break;
        case BusinessAttributeKind.Function:
          if (!acc.functions) {
            acc.functions = [];
          }
          acc.functions.push(attr._id);
          break;
        default:
          break;
      }

      return acc;
    }, {} as LibraryTemplateFilters);
  }

  copyFromLibraryTemplateToTeam(templateIds, teamId) {
    return this.http.post(`teams/${teamId}/apps/copy-from-library`, { templateIds });
  }

  copyFromLibraryTemplateToCompany(templateIds, companyId) {
    return this.http.post(`companies/${companyId}/apps/copy-from-library`, { templateIds });
  }

  create(teamId, app) {
    return this.http.post(`teams/${teamId}/apps`, app);
  }

  createForCompany(companyId, app) {
    return this.http.post(`companies/${companyId}/apps`, app);
  }

  createForTeam(teamId, app) {
    return this.http.post(`teams/${teamId}/apps`, app);
  }

  restore(parentId: string, isCompanyTemplate: boolean, templates: Template[]) {
    return this.updateState(parentId, isCompanyTemplate, templates, ItemStates.Active);
  }

  archive(parentId: string, isCompanyTemplate: boolean, templates: Template[]) {
    return this.updateState(parentId, isCompanyTemplate, templates, ItemStates.Archived);
  }

  private updateState(
    parentId: string,
    isCompanyTemplate: boolean,
    templates: Template[],
    state: ItemStates,
  ) {
    const requestUrl = `${isCompanyTemplate ? 'companies' : 'teams'}/${parentId}/apps/state`;

    return this.http.put(requestUrl, {
      templates: templates.map(({ _id }) => _id),
      state,
    });
  }

  remove(appId) {
    return this.http.remove(`apps/${appId}`);
  }

  addTagToTemplate(appId, tag) {
    return this.http.post(`apps/${appId}/add-template-tag`, { tag });
  }

  removeTagFromTemplate(appId, tagId) {
    return this.http.post(`apps/${appId}/remove-template-tag`, { tagId });
  }

  byId(appId: string, params?: Record<string, unknown>) {
    return this.http.get(`apps/${appId}`, params);
  }

  byIdForCompany(appId, companyId) {
    return this.http.get(`companies/${companyId}/apps/${appId}`);
  }

  updateForCompany(appId, companyId, app) {
    return this.http.put(`companies/${companyId}/apps/${appId}`, app);
  }

  update(appId, app) {
    return this.http.put(`apps/${appId}`, app);
  }

  consolidateFields(fields: FieldWeb[]) {
    return fields.map((field) => {
      const item = {
        kind: field.kind,
        description: field.description,
        id: field.id,
        _id: field._id,
        isRequired: field.isRequired,
        isHiddenInFormPDFExports: field.isHiddenInFormPDFExports,
        isExcludedInFormCloning: field.isExcludedInFormCloning,
        isPhotoDescriptionVisible: field.isPhotoDescriptionVisible,
        isPhotoTagsVisible: field.isPhotoTagsVisible,
        dependsOn: field.dependsOn,
        metadata: field.metadata,
      } as FieldWeb;

      return this.consolidateField(item, field);
    });
  }

  consolidateDefaultColorFields(field: FieldWeb) {
    const legacyColorsToNewValues = {
      info: 'sky',
      success: 'green',
      warning: 'yellow',
      danger: 'red',
    };

    field.defaultColor = legacyColorsToNewValues[field.defaultColor] ?? field.defaultColor;
  }

  consolidateHeaderFooterFields(
    headerFooterFields: HeaderFooterControls,
    templateHeaderItems: HeaderFooterField[],
  ) {
    const { header, footer } = headerFooterFields;
    const headerItems = Object.values(header).filter((item) => Boolean(item));
    const footerItems = Object.values(footer).filter((item) => Boolean(item));
    const fixedPositionFields = templateHeaderItems.filter((field) => this.isFixedPositionField(field));

    return { headerItems: [...headerItems, ...fixedPositionFields], footerItems };
  }

  private isFixedPositionField(field: HeaderFooterField) {
    const fixedPositionFieldsKinds = [
      HeaderFooterFieldKinds.TemplateName,
      HeaderFooterFieldKinds.FormCreatorAndCreationTime,
    ];
    return fixedPositionFieldsKinds.includes(field.kind);
  }

  convertHeaderFooterItemsToControl(headerFooterFields: HeaderFooterField[]) {
    const control = {};
    const convertibleFields = headerFooterFields.filter((field) => {
      const isFixedPositionField = this.isFixedPositionField(field);
      return !isFixedPositionField && field.isVisible !== false;
    });

    convertibleFields.forEach((field) => {
      const fieldWithGridPosition = field.gridPosition ? field : this.setFallbackGridPosition(field);
      const { column, row } = fieldWithGridPosition.gridPosition;

      control[`${column}-${row}`] = {
        ...fieldWithGridPosition,
        ...this.templateDefaultFieldTypeService.getHeaderFooterFieldObject(field.kind),
      };
    });

    return control;
  }

  private setFallbackGridPosition(field: HeaderFooterField) {
    const defaultFields = [...HeaderFooterDefaultFields.header, ...HeaderFooterDefaultFields.footer];
    const defaultFieldData = defaultFields.find((defaultField) => defaultField.kind === field.kind);

    return {
      _id: field._id,
      id: field._id,
      ...defaultFieldData,
    };
  }

  consolidateField(item: FieldWeb, model: FieldWeb) {
    if (model.content) {
      item.content = model.content;
    }

    if (!this.isRequiredField(item)) {
      item.isRequired = false;
    }

    switch (item.kind) {
      case FieldKinds.Date:
        item.isExpiryDate = model.isExpiryDate;
        item.isSingleDate = model.isSingleDate;
        item.startDate = model.startDate;
        item.endDate = model.endDate;
        item.isDateAndTime = model.isDateAndTime;
        break;
      case FieldKinds.YesNoCheckbox:
        item.hasNaButton = model.hasNaButton;
        item.hasTextInput = model.hasTextInput;
        item.textInputLabel = model.textInputLabel;
        item.logicRules = model.logicRules;
        break;
      case FieldKinds.SignonTable:
        item.displayRowsMode = model.displayRowsMode;
        break;
      case FieldKinds.Table:
      case FieldKinds.PrefilledTable:
        this.consolidateTable(item, model);
        break;
      case FieldKinds.Sketch:
        item.background = model.background;
        break;
      case FieldKinds.Signature:
        item.signatureRules = model.signatureRules;
        item.isApprovalSignature = !!model.isApprovalSignature;
        break;
      case FieldKinds.SignatureArray:
        item.isManualSignature = !!model.isManualSignature;
        break;
      case FieldKinds.Category:
        item.categoryType = model.categoryType;
        item.categorySource = model.categorySource;
        item.autoDeployChildList = !!model.autoDeployChildList;
        if (model.categorySource === CategorySourceTypes.List) {
          model.items = [];
        } else {
          this.consolidateDefaultColorFields(model);
          model.reference = undefined;
          item.defaultColor = model.defaultColor;
        }
        item.items = model.items;
        item.reference = model.reference;
        item.logicRules = model.logicRules;
        break;
      case FieldKinds.Photo:
        item.photoThumbnailSize = model.photoThumbnailSize;
        item.photos = [];
        item.isPhotoDescriptionVisible = model.isPhotoDescriptionVisible;
        item.isPhotoTagsVisible = model.isPhotoTagsVisible;
        break;
      default:
        break;
    }
    return item;
  }

  consolidateTable(item: FieldWeb, model: FieldWeb) {
    item.columns = model.columns || [];
    item.rows = model.rows || [];

    // don't save the formulaEditing properly to the db
    item.columns
      .filter((c) => c.kind === TableCellKinds.Formula)
      .forEach((formulaCell) => delete formulaCell.formulaEditing);
    item.rows.forEach((row) => {
      row.columns
        .filter((c) => c.kind === TableCellKinds.Formula)
        .forEach((formulaCell) => delete formulaCell.formulaEditing);
    });

    item.rows.forEach((row) =>
      row.columns
        .filter((column) => column.kind === TableCellKinds.Photo)
        .forEach((photoCell) => {
          photoCell.photos = [];
        }),
    );

    if (model.displayRowsMode) {
      item.displayRowsMode = model.displayRowsMode;
    }

    if (model.formulaRowNumber) {
      item.formulaRowNumber = model.formulaRowNumber;
    }

    if (model.tableReference) {
      item.tableReference = model.tableReference;
    }
  }

  uploadFile(files) {
    const team = this.teamService.getCurrentTeam();
    const orgId = team.id;
    if (team.isCompany) {
      return this.http.upload(`companies/${orgId}/apps/attachments`, files);
    }
    return this.http.upload(`teams/${orgId}/apps/attachments`, files);
  }

  checkUniqueId(id: string) {
    const team = this.teamService.getCurrentTeam();
    if (this.teamService.isTeam(team)) {
      return this.http.get(`teams/${team.id}/apps/validate/unique-app-id/${id}`);
    }
    return this.http.get(`companies/${team.id}/apps/validate/unique-app-id/${id}`);
  }

  removeControl(index: number, fields: FieldWeb[]): FieldWeb[] {
    const itemToRemove = fields.splice(index, 1)[0];
    const logicFields = remove(fields, (field: FieldWeb) => field.dependsOn?.fieldId === itemToRemove._id);
    const removedFields = [itemToRemove, ...logicFields];

    this.templateListPropertyService.decrementTableCellCounter(removedFields);

    return removedFields;
  }

  isRequiredField(field: FieldWeb) {
    return ![
      FieldKinds.SignonTable,
      FieldKinds.PreFilledText,
      FieldKinds.Table,
      FieldKinds.PrefilledTable,
      FieldKinds.Signature,
    ].includes(field.kind);
  }

  private generateNewId = (field: { id: string; _id: string }) => {
    field.id = new ObjectId().toHexString();
    field._id = field.id;
  };

  async cloneFieldWithValidations(fieldToClone: FieldWeb, allFields: FieldWeb[]): Promise<FieldWeb[] | null> {
    const clonedFields = this.cloneField(fieldToClone, allFields);

    const canProceed = await this.templateListPropertyService.enforceListPropertyTableCellLimit(clonedFields);

    return canProceed ? clonedFields : null;
  }

  cloneField(fieldToClone: FieldWeb, allFields: FieldWeb[]) {
    const clonedField = cloneDeep(fieldToClone);
    this.generateNewId(clonedField);

    if (clonedField.kind === FieldKinds.Table || clonedField.kind === FieldKinds.PrefilledTable) {
      delete clonedField.tableReference;
      const columnReferenceMap = new Map<string, { reference: string; index: number }>();

      clonedField.columns.forEach((column, index) => {
        if (column.kind === TableCellKinds.List && column.reference) {
          columnReferenceMap.set(column._id || column.id, { reference: column.reference, index });
        }

        this.generateNewId(column);

        if (column.kind === TableCellKinds.ListProperty) {
          const reference = columnReferenceMap.get(column.metadata.referenceTableColumnId);
          if (reference) {
            column.metadata.referenceTableColumnId = clonedField.columns[reference.index]._id;
          }
        }
      });

      clonedField.rows.forEach((row) => {
        this.generateNewId(row);

        row.columns.forEach((col, index) => {
          this.generateNewId(col);
          col.headerColumnId = clonedField.columns[index].id;
        });
      });
    }

    const clonedFields = [clonedField];

    // Clone dependent fields
    clonedField.logicRules?.forEach((rule) => {
      const oldRuleId = rule.id;
      this.generateNewId(rule);

      const ruleFields = allFields
        .filter((field) => field.dependsOn?.logicRuleId === oldRuleId)
        .map((field) => {
          const [clonedRuleField] = this.cloneField(field, allFields);
          clonedRuleField.dependsOn = { fieldId: clonedField.id, logicRuleId: rule.id };
          return clonedRuleField;
        });

      clonedFields.push(...ruleFields);
    });

    return clonedFields;
  }

  isWorkflow(model: Template) {
    return model.columns.length > 0;
  }

  async validate(
    model: Template,
    initialAppId: string,
    validLists: ILegacyList[],
    nextTemplateView?: TemplateView,
    currentTemplateView?: TemplateView,
  ) {
    const displayErrorMessageUsingKey = (key: string, options?: Partial<IndividualConfig>) => () => {
      this.toastr.errorByKey(key, options);
    };

    const validationRules = [
      {
        matchesRule: (template: Template) =>
          nextTemplateView !== TemplateView.Settings &&
          !new RegExp(doNotAcceptEmptyStringOrSlashes).test(template.uniqueAppId),
        errorAction: displayErrorMessageUsingKey('templateIdPatternError'),
      },
      {
        matchesRule: (template: Template) =>
          nextTemplateView !== TemplateView.Settings &&
          !new RegExp(doNotAcceptEmptyStringOrSlashes).test(template.name),
        errorAction: displayErrorMessageUsingKey('templateNamePatternError'),
      },
      {
        matchesRule: (template: Template) =>
          nextTemplateView !== TemplateView.Settings &&
          !new RegExp(doNotAcceptEmptyStringOrSlashes).test(template.instanceName),
        errorAction: displayErrorMessageUsingKey('templateFormNamePatternError'),
      },
      {
        matchesRule: (template: Template) =>
          this.isWorkflow(template) && this.approvalSignaturesCountIsIncorrect(template),
        errorAction: displayErrorMessageUsingKey('templateInvalidApprovalSignatures'),
      },
      {
        matchesRule: (template: Template) => {
          const items =
            template.items?.filter(
              this.logicRuleService.sharedService.isLogicField.bind(this.logicRuleService.sharedService),
            ) ?? [];
          return items.some((item) => !this.logicRuleService.sharedService.isValid(item, template.items));
        },
        errorAction: displayErrorMessageUsingKey('templateLogicRuleInvalid'),
      },
      {
        matchesRule: (template: Template) => {
          const tables = template.items?.filter((item) => item.kind === FieldKinds.PrefilledTable) ?? [];
          return tables.some((table) => !table?.rows?.length);
        },
        errorAction: displayErrorMessageUsingKey('rowInPrefilledTableRequired'),
      },
      {
        matchesRule: (template: Template) => {
          const tableTypes = [FieldKinds.Table, FieldKinds.PrefilledTable];
          const tables = template.items?.filter((item) => tableTypes.includes(item.kind)) ?? [];
          return tables.some((table) => {
            const listColumns = this.getTableColumns(table).filter(
              (column) => column.kind === TableCellKinds.List,
            );
            return listColumns.some(
              (column) => column.listSource === CategorySourceTypes.List && !column.reference,
            );
          });
        },
        errorAction: displayErrorMessageUsingKey('listItemsInTableRequired'),
      },
      {
        matchesRule: (template: Template) => {
          const tableTypes = [FieldKinds.Table, FieldKinds.PrefilledTable];
          const tables = template.items?.filter((item) => tableTypes.includes(item.kind)) ?? [];
          return tables.some((table) => {
            const listColumns = this.getTableColumns(table).filter(
              (column) => column.kind === TableCellKinds.List,
            );
            return listColumns.some(
              (column) => column.listSource === CategorySourceTypes.Manual && !column.manualListItems?.length,
            );
          });
        },
        errorAction: displayErrorMessageUsingKey('listItemsInTableRequired'),
      },
      {
        matchesRule: (template: Template) => {
          const tableTypes = [FieldKinds.Table, FieldKinds.PrefilledTable];
          const tables = template.items?.filter((item) => tableTypes.includes(item.kind)) ?? [];
          return this.tablesReferenceAnInvalidList(tables, validLists);
        },
        errorAction: displayErrorMessageUsingKey('listNotAccessible'),
      },
      {
        matchesRule: (template: Template) => {
          const tableTypes = [FieldKinds.Table, FieldKinds.PrefilledTable];
          const tables = template.items?.filter((item) => tableTypes.includes(item.kind)) ?? [];
          return tables.some((table) => {
            const tableColumns = this.getTableColumns(table).filter(
              (column) => column.kind === TableCellKinds.Formula,
            );
            return tableColumns.some((column) => !!column.formulaError || !column.formula);
          });
        },
        errorAction: displayErrorMessageUsingKey('formulaError'),
      },
      {
        matchesRule: (template: Template) => {
          const categoryTypes = [FieldKinds.Category];
          const categories = template.items?.filter((item) => categoryTypes.includes(item.kind)) ?? [];
          return categories.some(
            (category) => category.categorySource === CategorySourceTypes.List && !category.reference,
          );
        },
        errorAction: displayErrorMessageUsingKey('listInCategoryRequired'),
      },
      {
        matchesRule: (template: Template) => {
          const categoryTypes = [FieldKinds.Category];
          const categories = template.items?.filter((item) => categoryTypes.includes(item.kind)) ?? [];
          return this.categoriesReferenceAnInvalidList(categories, validLists);
        },
        errorAction: displayErrorMessageUsingKey('listNotAccessible'),
      },
      {
        matchesRule: (template: Template) => {
          const categoryTypes = [FieldKinds.Category];
          const categories = template.items?.filter((item) => categoryTypes.includes(item.kind)) ?? [];
          return categories.some(
            (category) => category.categorySource === CategorySourceTypes.Manual && !category.items?.length,
          );
        },
        errorAction: displayErrorMessageUsingKey('itemInCategoryRequired'),
      },
      {
        matchesRule: (template: Template) => !template.items?.length,
        errorAction: displayErrorMessageUsingKey('templateFieldsRequired', {
          toastClass: 'ngx-toastr toast-wide',
        }),
      },
      {
        matchesRule: (template: Template) => {
          const [firstField] = template.items;
          return firstField.kind === FieldKinds.PageBreak;
        },
        errorAction: displayErrorMessageUsingKey('pageBreakFirstFieldErrorMessage'),
      },
      {
        matchesRule: (template: Template) => {
          const lastField = template.items[template.items.length - 1];
          return lastField.kind === FieldKinds.PageBreak;
        },
        errorAction: displayErrorMessageUsingKey('pageBreakLastFieldErrorMessage'),
      },
      {
        matchesRule: (template: Template) => {
          const { items } = template;
          const hasError = items.some(
            (item, index) =>
              item.kind === FieldKinds.PageBreak && items[index + 1]?.kind === FieldKinds.PageBreak,
          );

          return hasError;
        },
        errorAction: displayErrorMessageUsingKey('pageBreakConsecutiveFieldError'),
      },
      {
        matchesRule: (template: Template) => {
          const { footerItems, headerItems, orientation } = template;
          if (currentTemplateView !== TemplateView.HeaderFooter) {
            return false;
          }

          return (
            this.filterContentLengthByOrientation(footerItems, orientation) ||
            this.filterContentLengthByOrientation(headerItems, orientation)
          );
        },
        errorAction: displayErrorMessageUsingKey('headerFooterLengthError'),
      },
      {
        matchesRule: (template: Template) => {
          const signatures = template.items?.filter((item) => item.kind === FieldKinds.Signature) ?? [];

          return this.isWorkflow(template) && this.approvalSignatureService.hasIncompleteRule(signatures);
        },
        errorAction: displayErrorMessageUsingKey('templateLogicRuleInvalid'),
      },
      {
        matchesRule: (template: Template) => template.displayFormThumbnailBy?.length === 0,
        errorAction: displayErrorMessageUsingKey('settingsThumbnailFieldRequiredError'),
      },
      {
        matchesRule: (template: Template) => !this.validatePersonFields(template.items),
        errorAction: displayErrorMessageUsingKey('personFieldNameError'),
      },
    ];

    const failingValidation = validationRules.find((validation) => validation.matchesRule(model));
    failingValidation?.errorAction();

    const hasLPTCColumnError = this.validateListPropertyTableCell(model.items);

    if (hasLPTCColumnError) {
      displayErrorMessageUsingKey(hasLPTCColumnError.message)();
      return false;
    }

    const isTemplateIdUnique = await this.isTemplateIdUniqueInFolder(model.uniqueAppId, initialAppId);
    if (!isTemplateIdUnique) {
      displayErrorMessageUsingKey('templateIdUsedError')();
      return false;
    }

    return !failingValidation;
  }

  async isTemplateIdUniqueInFolder(newTemplateId: string, oldTemplateId: string): Promise<boolean> {
    const isInitialAppId = newTemplateId === oldTemplateId;
    if (isInitialAppId) {
      return true;
    }

    const response = await this.checkUniqueId(newTemplateId).toPromise();
    return response.isUnique;
  }

  private tablesReferenceAnInvalidList = (tables: FieldWeb[], validLists: ILegacyList[]) => {
    return tables.some((table) => {
      const listColumns = this.getTableColumns(table).filter((column) => column.kind === TableCellKinds.List);
      return listColumns.some(
        (column) =>
          column.listSource === CategorySourceTypes.List &&
          !this.canReferenceList(column.reference, validLists),
      );
    });
  };

  private categoriesReferenceAnInvalidList = (categories: FieldWeb[], validLists: ILegacyList[]) => {
    return categories.some((category) => {
      return (
        category.categorySource === CategorySourceTypes.List &&
        !this.canReferenceList(category.reference, validLists)
      );
    });
  };

  canReferenceList(listId: string, validLists: ILegacyList[]) {
    return validLists.find((list) => list.id === listId);
  }

  getDeploymentStatus(appId) {
    return this.http.get(`apps/${appId}/deployments`);
  }

  push(appId, teamIds: string[]) {
    return this.http.post(`apps/${appId}/push`, { teamIds });
  }

  undeploy(appId, teamIds: string[]) {
    return this.http.post(`apps/${appId}/undeploy`, { teamIds });
  }

  pull(appIds, teamId) {
    return this.http.post(`teams/${teamId}/apps/pull`, { appIds });
  }

  getRegisterViewColumnsSetting(templateId) {
    return this.http.get(`apps/${templateId}/register-view/columns-setting`);
  }

  getLibraryTemplateRegisterViewColumnsSetting(templateId) {
    return this.http.get(`template-libraries/${templateId}/register-view/columns-setting`);
  }

  updateRegisterViewColumnsSetting(appId, registerViewColumnsSetting) {
    return this.http.put(`apps/${appId}/register-view/columns-setting`, registerViewColumnsSetting);
  }

  updateWorkflowNotificationList(appId, columnIndex, notificationList) {
    return this.http.put(`apps/${appId}/columns/${columnIndex}/notification-list`, { notificationList });
  }

  getLibraryTemplateById(templateId) {
    return this.http.get(`template-libraries/templates/${templateId}`);
  }

  decorateEmptyTemplateTags(apps) {
    apps.forEach((app) => {
      if (app.templateTags && app.templateTags.length > 0) {
        app.templateTags = app.templateTags.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        app.templateTags = [{ value: '' }];
      }
    });
  }

  filterTemplatesByKeywordAndTag(
    templatesToFilter: TemplateWeb[],
    keyword: string | undefined,
    selectedTagIds: string[],
  ): TemplateWeb[] {
    if (!templatesToFilter?.length || (!selectedTagIds.length && !keyword)) {
      return templatesToFilter;
    }

    const filteredTemplates = keyword ? this.filterByKeyword(templatesToFilter, keyword) : templatesToFilter;
    return selectedTagIds?.length
      ? this.filterByTagIds(filteredTemplates, selectedTagIds)
      : filteredTemplates;
  }

  toggleAppsVisibilityByKeywordAndTag(apps, keyword, selectedTagIds) {
    if (!apps || !apps.length) {
      return;
    }

    if (selectedTagIds.length === 0 && !keyword) {
      apps.forEach((app) => {
        app.visible = true;
      });
      return;
    }

    if (keyword) {
      apps.forEach((app) => {
        app.visible =
          app.name.toLowerCase().includes(keyword.toLowerCase()) ||
          app.uniqueAppId.toLowerCase().includes(keyword.toLowerCase()) ||
          (app.createdBy && app.createdBy.fullName.toLowerCase().includes(keyword.toLowerCase()));
      });
    } else {
      apps.forEach((app) => {
        app.visible = true;
      });
    }

    if (selectedTagIds.length > 0) {
      apps.forEach((app) => {
        app.visible =
          app.visible &&
          selectedTagIds.every((tagId) => {
            return app.templateTags.map((tag) => tag.value).includes(tagId);
          });
      });
    }
  }

  getSortedTabsFilteredByTemplates(tabs: Tab[], templates: Template[]): Tab[] {
    const templateTabsIds = this.getTabsFromTemplates(templates).map((tab: Tab) => tab.id);
    return [...tabs].sort(this.sortByValue).filter((tab) => templateTabsIds.includes(tab.id));
  }

  getTabsFromTemplates(templates: Template[]): Tab[] {
    const resultTabs = templates.reduce((tabsFromTemplates, template) => {
      const templateTabs = template.templateTags || [];

      return this.reduceTemplateTabs({ tabsFromTemplates, templateTabs });
    }, new Map());

    return Array.from(resultTabs.values());
  }

  getValidDisplayFormThumbnail(displayFormThumbnail: string[]) {
    const defaultText = 'None';

    if (!displayFormThumbnail || displayFormThumbnail.every((item) => item === defaultText)) {
      return [];
    }

    return displayFormThumbnail.filter((item) => item !== defaultText);
  }

  private getDuplicatedPersonFieldNames(fields: FieldWeb[]) {
    const personFields = fields.filter((item) => item.kind === FieldKinds.Person);
    const personFieldNames = personFields.map((field) => field.description);

    return this.findDuplicatesInArray(personFieldNames);
  }

  validatePersonFields(fields: FieldWeb[]) {
    const duplicatedNames = this.getDuplicatedPersonFieldNames(fields);
    const arePersonFieldsValid = !duplicatedNames.length;
    this.updateInvalidPersonFields(fields);

    return arePersonFieldsValid;
  }

  validateListPropertyTableCell(fields: FieldWeb[]) {
    const tables = fields.filter((item) => item.kind === FieldKinds.Table);
    let LPTCCountLimitExceeded = false;
    let missingListListProperty = false;

    let LPTCCounter = 0;

    tables.forEach((table) =>
      table.columns.forEach((column) => {
        if (column.kind === TableCellKinds.ListProperty) {
          LPTCCounter += 1;
          if (LPTCCounter > this.templateListPropertyService.maxListPropertyTableCells) {
            LPTCCountLimitExceeded = true;
            return;
          }

          const { listId, referenceTableColumnId, legacyListId } = column.metadata || {};
          const { itemId, legacyId } = column.data || {};

          const isValidData = ObjectId.isValid(legacyId) && Flake.isValid(itemId);
          const isValidMetadata =
            ObjectId.isValid(legacyListId) &&
            ObjectId.isValid(referenceTableColumnId) &&
            Flake.isValidPath(listId);

          if (!isValidMetadata || !isValidData) {
            missingListListProperty = true;
            this.invalidateColumn(column);
          }
        }
      }),
    );

    if (LPTCCountLimitExceeded) {
      return { message: 'listPropertyTableCellCountLimitExceeded' };
    }
    if (missingListListProperty) {
      return { message: 'templateValidationMissingListProperty' };
    }

    return false;
  }

  invalidateColumn(column) {
    column._invalid = true;
  }

  updateInvalidPersonFields(templateFields: FieldWeb[]) {
    const duplicatedNames = this.getDuplicatedPersonFieldNames(templateFields);

    const updatedTemplateFields = templateFields.map((item) => {
      item._invalid = !!duplicatedNames.includes(item.description);
      return item;
    });

    return updatedTemplateFields;
  }

  private getTableColumns(table: FieldWeb) {
    const output =
      table.kind === FieldKinds.PrefilledTable
        ? (flatten(table.rows?.map((row) => row.columns)) as TableColumn[])
        : table.columns;
    return output ?? [];
  }

  private sortByValue(firstTab, secondTab) {
    return firstTab.sortValue - secondTab.sortValue;
  }

  private approvalSignaturesCountIsIncorrect(model: Template) {
    const approvalSignatureCount = model.items.reduce(
      (signed, item) => (item.kind === FieldKinds.Signature ? signed + 1 : signed),
      0,
    );
    return approvalSignatureCount !== model.columns.length - 1;
  }

  private filterByKeyword(templatesToFilter: TemplateWeb[], keyword: string): TemplateWeb[] {
    return templatesToFilter.filter((template) => {
      return (
        template.name.toLowerCase().includes(keyword.toLowerCase()) ||
        template.uniqueAppId.toLowerCase().includes(keyword.toLowerCase()) ||
        (template.createdBy && template.createdBy.fullName.toLowerCase().includes(keyword.toLowerCase()))
      );
    });
  }

  private filterByTagIds(templatesToFilter: TemplateWeb[], selectedTagIds: string[]): TemplateWeb[] {
    return templatesToFilter.filter((template) => {
      return selectedTagIds.every((tagId) => {
        return template.templateTags.map((tag) => tag.value).includes(tagId);
      });
    });
  }

  private reduceTemplateTabs({ tabsFromTemplates, templateTabs }) {
    return templateTabs.reduce((tabs, tab) => {
      if (!!tab.value && !tabsFromTemplates.has(tab.value)) {
        tabs.set(tab.value, {
          id: tab.value,
          name: tab.name,
          style: tab.style,
        });
      }

      return tabs;
    }, tabsFromTemplates);
  }

  private filterContentLengthByOrientation(items: HeaderFooterField[], orientation: TemplateOrientations) {
    return items?.some((item) => item.content?.length > PrefilledTextFieldCharacterLimit[orientation]);
  }

  private findDuplicatesInArray(array: string[]): string[] {
    const seen = new Set();
    const duplicates = new Set<string>();
    array.forEach((item) => {
      if (seen.has(item)) {
        duplicates.add(item);
      } else {
        seen.add(item);
      }
    });
    return Array.from(duplicates);
  }

  downloadAsCSV(params) {
    const folderMapping = { [AppHierarchy.Organisation]: 'companies', [AppHierarchy.Team]: 'teams' };
    const { hierarchy, parentId, ids } = params;
    const folderLevel = folderMapping[hierarchy];
    const URL = `${folderLevel}/${parentId}/apps/csv`;
    return this.http.post<ExportResponse>(URL, { templateIds: ids });
  }
}
/* eslint-enable max-lines */
