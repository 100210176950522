import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, combineLatest, skip, Subject, takeUntil } from 'rxjs';

import {
  FieldKinds,
  GlobalItemModels,
  IListPropertyTableCell,
  ITableColumn,
} from '@site-mate/dashpivot-shared-library';

import { dayjs } from 'app/shared/dayjs';
import { isEmptyQuery, isFetchedQuery } from 'app/shared/model/query/query-predicates';
import { QueryStates } from 'app/shared/model/query/query-states.enum';
import { FetchedQuery } from 'app/shared/model/query/query.model';
import { UserService } from 'app/user/user.service';

import {
  IListCellQuery,
  IListCellWithoutAttachment,
  ListPropertyFormService,
} from '../list-property-form.service';

@Component({
  selector: 'cc-form-table-list-property',
  templateUrl: './form-table-list-property-cell.component.html',
  styleUrls: ['./form-table-list-property-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormTableListPropertyComponent {
  private readonly listPropertyFormService = inject(ListPropertyFormService);
  private readonly userService = inject(UserService);
  private readonly unSubscribe = new Subject<void>();

  readonly QueryStates = QueryStates;
  readonly cell$ = new BehaviorSubject<IListCellQuery>({ state: QueryStates.Empty });

  @Input() cellData: IListPropertyTableCell;
  @Input() tableKind: FieldKinds;
  @Input() columnDescription: string;
  @Input() headerColumn: ITableColumn;
  @Input() rowId: string;
  @Input() tableId: string;
  @Input() columnIndex: number;
  @Output() onChange = new EventEmitter();
  isRowSelected: boolean;

  ngOnInit() {
    this.cellData.metadata = this.cellData.metadata || {
      referenceTableColumnId: this.headerColumn.metadata.referenceTableColumnId,
      listId: this.headerColumn.metadata.listId,
      legacyListId: this.headerColumn.metadata.legacyListId,
    };

    if (this.cellData.item) {
      this.cell$.next({ state: QueryStates.Fetched, data: this.getParsedCell(this.cellData.item) });
    }

    this.initializeComponent();
    this.listenToCellChanges();
  }

  private listenToCellChanges() {
    this.cell$.pipe(takeUntil(this.unSubscribe)).subscribe(() => this.onChange.emit());
  }

  getParsedCell(cell: IListCellWithoutAttachment | null): IListCellWithoutAttachment {
    if (!cell || !cell.data.value) {
      return { data: { value: '-' } } as IListCellWithoutAttachment;
    }

    const clonedCell = cloneDeep(cell);
    const parseMapper = new Map<GlobalItemModels, (value: string) => string | number>([
      [GlobalItemModels.NumberSimple, (value: string) => Number(value)],
      [GlobalItemModels.TextSimple, (value: string) => String(value)],
      [GlobalItemModels.DateSimple, (value: string) => this.parseDate(value)],
      [GlobalItemModels.DateExpiry, (value: string) => this.parseDate(value)],
    ]);

    const value = String(cell.data.value);
    clonedCell.data.value = parseMapper.get(cell.model)(value);
    return clonedCell;
  }

  private parseDate(value: string): string {
    const timezone = this.userService.getTimezone();

    return dayjs(new Date(value)).tz(timezone).format('DD/MM/YYYY');
  }

  initializeComponent() {
    const { referenceTableColumnId } = this.cellData.metadata;
    const rowAddress = { tableId: this.tableId, rowId: this.rowId, referenceTableColumnId };

    const cell$ = this.listPropertyFormService
      .getCell(rowAddress, this.headerColumn.data.itemId)
      .pipe(skip(this.shouldSkipUpdate()));
    const rowSelection$ = this.listPropertyFormService.isRowSelected(this.tableId, this.rowId);

    combineLatest([cell$, rowSelection$])
      .pipe(takeUntil(this.unSubscribe))
      .subscribe(([cellQuery, isRowSelected]) => {
        this.isRowSelected = isRowSelected;
        if (isFetchedQuery(cellQuery)) {
          return this.handleFetchedQuery(cellQuery);
        }

        if (isEmptyQuery(cellQuery)) {
          return this.handleEmptyQuery(cellQuery);
        }

        return this.cell$.next(cellQuery);
      });
  }

  private handleFetchedQuery(query: FetchedQuery<IListCellWithoutAttachment>) {
    this.cellData.item = query.data;
    return this.cell$.next({ ...query, data: this.getParsedCell(query.data) });
  }

  private handleEmptyQuery(query: IListCellQuery) {
    this.cellData.item = null;
    return this.cell$.next(query);
  }

  private shouldSkipUpdate(): number {
    return this.cellData.item ? 1 : 0;
  }

  ngOnDestroy() {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }
}
