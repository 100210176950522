import { Component, Input } from '@angular/core';

import { FieldWeb } from 'app/shared/model/item.model';

@Component({
  selector: 'cc-form-field-label',
  templateUrl: './form-field-label.component.html',
  styleUrls: ['../../../../form/form-components/form-component.scss', './form-field-label.component.scss'],
})
export class FormFieldLabelComponent {
  @Input() model: FieldWeb;
  @Input() label: string;

  hasFieldSettings = false;

  ngOnInit(): void {
    this.hasFieldSettings =
      this.model.isRequired ||
      this.model.isHiddenInFormPDFExports ||
      this.model.isExcludedInFormCloning ||
      this.model.isPhotoDescriptionVisible ||
      this.model.isPhotoTagsVisible;
  }
}
