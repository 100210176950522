"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThumbnailKinds = void 0;
var MetaDataThumbnailKinds;
(function (MetaDataThumbnailKinds) {
    MetaDataThumbnailKinds["Counter"] = "counter";
    MetaDataThumbnailKinds["CreatedAt"] = "createdAt";
    MetaDataThumbnailKinds["CreatedBy"] = "createdBy";
})(MetaDataThumbnailKinds || (MetaDataThumbnailKinds = {}));
var ThumbnailKinds;
(function (ThumbnailKinds) {
    ThumbnailKinds["Category"] = "category";
    ThumbnailKinds["Date"] = "date";
    ThumbnailKinds["SingleLineInput"] = "singleLineInput";
    ThumbnailKinds["Person"] = "person";
    ThumbnailKinds["Counter"] = "counter";
    ThumbnailKinds["CreatedAt"] = "createdAt";
    ThumbnailKinds["CreatedBy"] = "createdBy";
})(ThumbnailKinds = exports.ThumbnailKinds || (exports.ThumbnailKinds = {}));
