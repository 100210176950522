"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatePlainUtil = void 0;
const temporal_polyfill_1 = require("temporal-polyfill");
class DatePlainUtil {
    /**
     * Gets the current date.
     */
    static getDate() {
        return temporal_polyfill_1.Temporal.Now.plainDateISO().toString();
    }
    /**
     * Gets the current time.
     */
    static getTime() {
        return temporal_polyfill_1.Temporal.Now.plainTimeISO().toString();
    }
    /**
     * Gets the current date & time.
     */
    static getDateTime() {
        return temporal_polyfill_1.Temporal.Now.plainDateTimeISO().toString();
    }
    /**
     * Checks if a value is a valid date time.
     * @param {string} date The value to check.
     * @returns {boolean} Whether the value is a valid date time.
     */
    static isValidDate(date) {
        if (!date)
            return false;
        try {
            // NOTE: if an invalid date time string is passed
            // Temporal.PlainDateTime.from will throw an error
            temporal_polyfill_1.Temporal.PlainDate.from(date);
            return true;
        }
        catch (_) {
            return false;
        }
    }
    /**
     * Checks if a value is a valid date time.
     * @param {string} dateTime The value to check.
     * @returns {boolean} Whether the value is a valid date time.
     */
    static isValidDateTime(dateTime) {
        if (!dateTime)
            return false;
        try {
            // NOTE: if an invalid date time string is passed
            // Temporal.PlainDateTime.from will throw an error
            temporal_polyfill_1.Temporal.PlainDateTime.from(dateTime);
            return true;
        }
        catch (_) {
            return false;
        }
    }
    /**
     * Checks if a value is a valid date time.
     * @param {string} time The value to check.
     * @returns {boolean} Whether the value is a valid date time.
     */
    static isValidTime(time) {
        if (!time)
            return false;
        if (DatePlainUtil.isValidDateTime(time))
            return false;
        try {
            // NOTE: if an invalid date time string is passed
            // Temporal.PlainTime.from will throw an error
            temporal_polyfill_1.Temporal.PlainTime.from(time);
            return true;
        }
        catch (_) {
            return false;
        }
    }
    /**
     * Checks if a date2 is greater than date1.
     * @param {string} date1 the first plain date string
     * @param {string} date2 the first plain date string
     * @returns {boolean} Whether the value is a valid date time.
     */
    static isGreaterThan(date1, date2) {
        try {
            return temporal_polyfill_1.Temporal.PlainDate.compare(date1, date2) > 0;
        }
        catch (_) {
            return false;
        }
    }
}
exports.DatePlainUtil = DatePlainUtil;
