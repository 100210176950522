<div [ngClass]="{ 'full-screen': fullScreen }">
  <cc-photo-modal-tool-bar
    [downloadingPDF]="downloadingPDF"
    (exportPDF)="onExportPDF()"
    (exportOriginal)="onExportOriginal()"
    [photo]="photo"
    (magnifyEvent)="onMagnify($event)"
    [magnify]="magnify"
    (fullScreen)="onFullScreen()"
    (remove)="onRemove()"
    [photo]="photo"
    *ngIf="!fullScreen"
    (close)="dismissModal()"
    [hideDeleteButton]="hideDeleteButton"
  >
  </cc-photo-modal-tool-bar>
  <div class="modal-body photo-modal-body" [ngClass]="{ 'full-screen': fullScreen }">
    <div class="row h-100">
      <div class="col-sm-3 map-wrapper hidden-md-down" *ngIf="!fullScreen">
        <ng-container *ngIf="GPSLocation; else noGPS">
          <google-map
            *ngIf="(isGeoAPILoaded$ | async) && useInteractiveMap"
            [width]="null"
            height="100%"
            [center]="GPSLocation"
            [zoom]="16"
            (mapInitialized)="logGoogleMapsUsage()"
          >
            <map-marker [position]="GPSLocation"></map-marker>
            <map-kml-layer [url]="kmlFile"></map-kml-layer>
          </google-map>
          <div *ngIf="!useInteractiveMap" class="image-wrapper" data-test="static-map-container">
            <img [src]="staticMapUrl" [alt]="photo.filename" class="static-map" />
            <div class="image-backdrop">
              <button type="button" class="btn btn-sm switch-map-button" (click)="switchToInteractiveMap()">
                <span i18n>Switch to interactive map</span>
              </button>
            </div>
          </div>
        </ng-container>
        <ng-template #noGPS>
          <div
            class="px-3 no-gps-wrapper d-flex justify-content-center align-items-center"
            style="background-image: url('./assets/images/no-gps.png')"
          >
            <div class="instruction">
              <p class="mb-0" i18n>No location data is available for this file.</p>
              <p class="default-paragraph" i18n>
                See below for instructions on how to activate on your device:
              </p>
              <p class="mt-5 default-paragraph">
                <i class="fa fa-android fa-2x"></i>
              </p>
              <p class="mb-0" i18n>For Android users,</p>
              <p i18n>activate location tags.</p>
              <p class="mt-5 default-paragraph">
                <i class="fa fa-apple fa-2x"></i>
              </p>
              <p class="mb-0" i18n>For iPad/iPhone users,</p>
              <p i18n>enable location services.</p>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="px-0 col-md-8 col-lg-6" [ngClass]="{ 'col-lg-9': fullScreen }">
        <div class="d-flex justify-content-between align-items-center image-control">
          <div class="px-3" [ngClass]="{ 'sm-cursor-not-allowed': isFirst() }">
            <a class="p-2 btn btn-secondary prev" [ngClass]="{ disabled: isFirst() }" (click)="prev()"
              ><i class="cc-icon cc-icon-arrow-left"></i
            ></a>
          </div>
          <div
            *ngIf="!photo.isVideo"
            (mousemove)="magnify && updateMagnify($event)"
            class="image-wrapper d-flex align-items-center"
            #magnifyContainer
          >
            <div
              class="large"
              [ngStyle]="{
                'background-image': 'url(' + photo.url.compressedFile + ')',
                height: +magnifyHeight + 'px',
                width: +magnifyWidth + 'px'
              }"
              #largeImage
            ></div>
            <!-- Placeholder thumbnail for local images -->
            <div
              class="sm-flex sm-flex-col sm-items-center sm-justify-center sm-gap-2"
              data-test="placeholder-thumbnail"
              *ngIf="photo.localImage"
            >
              <em class="cc-icon cc-icon-photo" aria-hidden="true"></em>
              <span class="sm-max-w-xs sm-text-center" i18n>
                Photo upload incomplete - please open the mobile app to finish uploading.
              </span>
            </div>
            <img
              *ngIf="!photo.localImage"
              [src]="photo.url.compressedFile"
              (error)="onImgLoadError(photo)"
              [alt]="photo.filename"
              class="image"
              #smallImage
              (load)="onImgLoad()"
            />
            <div class="backdrop" *ngIf="isLoading && !photo.localImage">
              <em class="cc-icon cc-icon-spinner-3x" aria-busy="true" aria-live="polite"></em>
            </div>
          </div>
          <!-- Video Wrapper -->
          <div class="video-wrapper" *ngIf="photo.isVideo">
            <video
              *ngIf="!photo.localImage"
              class="video sm-inline-block"
              #videoPlayer
              preload="auto"
              controls
            >
              <source data-test="video-source" [src]="photo.url.originalFile" type="video/mp4" #videoSource />
              <span i18n>Video is not supported in your browser.</span>
            </video>
            <!-- Placeholder thumbnail for local videos -->
            <div
              class="sm-flex sm-flex-col sm-items-center sm-justify-center sm-gap-2"
              data-test="placeholder-thumbnail"
              *ngIf="photo.localImage"
            >
              <em class="cc-icon cc-icon-video" aria-hidden="true"></em>
              <span class="sm-max-w-xs sm-text-center" i18n>
                Video upload incomplete - please open the mobile app to finish uploading.
              </span>
            </div>
          </div>
          <div class="px-3" [ngClass]="{ 'sm-cursor-not-allowed': isLast() }">
            <a class="p-2 btn btn-secondary next" (click)="next()" [ngClass]="{ disabled: isLast() }"
              ><i class="cc-icon cc-icon-arrow-right"></i
            ></a>
          </div>
        </div>
      </div>
      <div class="px-0 col-md-4 col-lg-3 meta-data">
        <div>
          <cc-photo-modal-side-tool
            *ngIf="fullScreen"
            [magnify]="magnify"
            (magnifyEvent)="onMagnify($event)"
            (restore)="onRestore()"
            (close)="dismissModal()"
            [photo]="photo"
          ></cc-photo-modal-side-tool>
        </div>
        <div class="author">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <cc-user-avatar [user]="photo.createdBy"></cc-user-avatar>
              <strong class="float-left ml-1">
                {{ photo.createdBy?.fullName }}
              </strong>
            </div>
            <small class="text-muted">Created: {{ photo.createdAt | amDateFormat: 'lll' }}</small>
          </div>
        </div>

        <div class="category">
          <div class="mb-3 sm-flex sm-items-center sm-space-x-1">
            <i class="cc-icon cc-icon-tag"></i>
            <strong class="d-inline-block" i18n>Photo tags:</strong>
            <button
              type="button"
              class="btn btn-primary btn-sm"
              [ngbPopover]="selectTagTemplate"
              popoverClass="photo-tag-popover"
              #tagSelectPopover="ngbPopover"
              placement="bottom"
              (document:mouseup)="tagSelectPopover.close()"
            >
              <div class="d-flex justify-content-center align-items-center">
                <i class="cc-icon cc-icon-plus"></i>
                <span i18n>Add tag</span>
              </div>
            </button>
          </div>
          <div>
            <div *ngFor="let tagGroup of photo.tagGroups; let i = index">
              <span
                class="photo-tag sm-inline-grid sm-flex-wrap"
                *ngFor="let tag of tagGroup.tags; let j = index"
              >
                <div class="sm-truncate">
                  <span i18n>{{ tag }}</span>
                </div>
                <span class="btn-remove-tag"
                  ><a (click)="removeTag(i, j)"><i class="fa fa-times sm-text-default-red"></i></a
                ></span>
              </span>
            </div>
          </div>
        </div>
        <div>
          <div class="p-3 photo-description-container">
            <div class="photo-description">
              <div class="d-flex justify-content-between">
                <strong i18n>Description</strong>
                <small *ngIf="photo.description">
                  {{ photo.createdAt | amDateFormat: 'lll' }}
                </small>
              </div>

              <div *ngIf="!editingDesc">
                <div class="d-flex justify-content-between">
                  <div>{{ photo.description }}</div>
                  <div class="sm-flex">
                    <a
                      *ngIf="photo.description && canEdit && !editingDesc"
                      class="photo-description__edit-link"
                      (click)="startEditDesc()"
                    >
                      <small class="sm-flex sm-items-center sm-space-x-1">
                        <i class="cc-icon cc-icon-pencil sm-flex sm-items-baseline"></i>
                        <span i18n>Edit</span>
                      </small>
                    </a>
                    <a
                      *ngIf="photo.description && canEdit && !editingDesc"
                      class="ml-2 photo-description__edit-link"
                      (click)="deleteDesc()"
                    >
                      <small class="sm-flex sm-items-center sm-space-x-1 sm-text-default-red">
                        <i class="cc-icon cc-icon-delete sm-flex sm-items-baseline"></i>
                        <span i18n>Delete</span>
                      </small>
                    </a>
                  </div>
                </div>
              </div>

              <div *ngIf="editingDesc">
                <textarea
                  ccFocus
                  rows="3"
                  class="form-control comment-textarea"
                  autocomplete="off"
                  name="description"
                  [(ngModel)]="editDescModel"
                >
                </textarea>
                <div class="text-right">
                  <a class="mr-2 text-link" (click)="saveEditedDesc()">
                    <small> <span i18n>Save</span></small>
                  </a>
                  <a class="text-link" (click)="editingDesc = false">
                    <small> <span i18n>Cancel</span></small>
                  </a>
                </div>
              </div>
            </div>
            <div *ngIf="!photo.description">
              <a *ngIf="canEdit && !editingDesc" class="text-link" (click)="startEditDesc()">
                <small class="sm-flex sm-py-1">
                  <i class="cc-icon cc-icon-pencil"></i>
                  <span i18n>Add description</span>
                </small>
              </a>
            </div>
          </div>
          <div class="px-3 comments">
            <div class="comment" *ngFor="let comment of comments">
              <strong class="float-left d-inline-block">{{
                comment.createdBy && comment.createdBy.fullName
              }}</strong>
              <small class="float-right py-1 text-muted">{{ comment.createdAt | amDateFormat: 'lll' }}</small>
              <span class="clearfix"></span>
              <div class="d-flex justify-content-between">
                <div class="text-muted comment-content" *ngIf="!comment.editing">{{ comment.comment }}</div>
                <div class="sm-flex">
                  <a
                    *ngIf="canEditComment(comment)"
                    class="comment__edit-link"
                    (click)="startEditComment(comment)"
                  >
                    <small class="sm-flex sm-items-center sm-space-x-1">
                      <i class="cc-icon cc-icon-pencil"></i>
                      <span i18n>Edit</span></small
                    >
                  </a>

                  <a
                    *ngIf="canEditComment(comment)"
                    class="ml-2 comment__edit-link"
                    (click)="deleteComment(comment)"
                  >
                    <small class="sm-flex sm-items-center sm-space-x-1 sm-text-default-red">
                      <i class="cc-icon cc-icon-delete"></i>
                      <span i18n>Delete</span>
                    </small>
                  </a>
                </div>
              </div>
              <div *ngIf="comment.editing">
                <textarea
                  ccFocus
                  rows="3"
                  class="form-control comment-textarea"
                  autocomplete="off"
                  name="comment"
                  [(ngModel)]="comment.editModel"
                >
                </textarea>
                <div class="text-right">
                  <a class="mr-2 text-link" (click)="saveEditedComment(comment)">
                    <small> <span i18n>Save</span></small>
                  </a>
                  <a class="text-link" (click)="comment.editing = false">
                    <small> <span i18n>Cancel</span></small>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3 comment-input">
            <div class="mx-4 form-group">
              <textarea class="form-control comment-textarea" rows="3" #comment></textarea>
            </div>
            <div class="mx-4 form-group">
              <button
                type="button"
                class="button-large button-green sm-text-white"
                [disabled]="!comment.value"
                (click)="addComment(comment)"
                i18n
                [ccBusyButton]="addingComment"
              >
                Add Comment
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #selectTagTemplate>
  <div (mouseup)="$event.stopPropagation()" class="tag-selection-popover">
    <label i18n for="tag-selection" class="sm-font-sans sm-text-sm sm-text-default-text">
      Select the photo tag you wish to add to this photo:
    </label>
    <ng-select
      name="tag-selection"
      placeholder="Select or search..."
      i18n-placeholder="Select or search..."
      class="dropdown-selector dropdown-menu-web"
      (change)="onTagSelect($event); tagSelectPopover?.close()"
      [items]="tagsToSelect"
    >
    </ng-select>
  </div>
</ng-template>
