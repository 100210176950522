import { Input, Directive } from '@angular/core';

import { FormFieldValidator } from '@site-mate/dashpivot-shared-library';

import { appControlType } from 'app/apps/app-control-type.model';
import { FormService } from 'app/form/form.service';
import { FieldWeb } from 'app/shared/model/item.model';
import { AppUtilService } from 'app/shared/service/app-util.service';

@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class FormBaseComponent<M = FieldWeb> {
  controlType = appControlType;
  validator: FormFieldValidator<M>;
  @Input() model: M;
  @Input() isPreview: boolean;
  editing: boolean;

  constructor(
    protected readonly appUtilService: AppUtilService,
    protected readonly formService: FormService,
  ) {}

  public get isMobile() {
    return this.appUtilService.isMobile;
  }

  public invalidDescription() {
    return this.validator ? this.validator.invalidDescription(this.model) : '';
  }

  public onFieldChange() {
    this.formService.onFieldChanged.next();
  }

  protected onBlur() {
    if (this.validator) {
      // @ts-expect-error Property '_invalid' does not exist on type 'M'
      this.model._invalid = !this.validator.isValid(this.model);
    }
  }
}
