<ng-container [formGroup]="form">
  <p class="sm-font-bold" i18n>Signature Name</p>
  <input
    type="text"
    i18n-placeholder="Full Name"
    placeholder="Full Name"
    formControlName="name"
    class="form-control form-input"
    maxlength="32"
    [class.error-state]="form.controls.name.invalid"
  />

  <p *ngIf="form.controls.name.valid; else errorText" class="input-helper-text">
    Character limit is 32 characters.
  </p>

  <p class="sm-font-bold" i18n>Preview</p>

  <div class="preview-container {{ previewContainerStyle }}">
    <canvas
      #signatureCanvas
      [width]="options.canvasWidth"
      [height]="options.canvasHeight"
      class="sm-m-auto"
    ></canvas>
    <hr class="hr signature-line" />
  </div>
</ng-container>

<ng-template #errorText>
  <p class="input-error-text" i18n>Input required.</p>
</ng-template>
