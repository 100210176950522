<ul class="nav">
  <li class="nav-item mr-auto float-left">
    <a class="nav-link icon fullscreen-restore-link" (click)="restore.emit()">
      <i class="cc-icon cc-icon-minimise"></i><span i18n>Restore</span>
    </a>
  </li>
  <li class="nav-item" *ngIf="!appUtilService.isMobile">
    <a
      (click)="toggleMagnify()"
      class="nav-link icon"
      [ngClass]="{
        magnify: magnify,
        'text-muted': photo.isVideo,
        disabled: photo.isVideo
      }"
    >
      <i class="cc-icon cc-icon-magnify-plus"></i><span i18n>Magnify</span>
    </a>
  </li>
  <li class="nav-item float-right ml-auto">
    <a class="nav-link icon modal-side-close-button" (click)="close.emit()">
      <span i18n>Close</span><i class="cc-icon cc-icon-close"></i
    ></a>
  </li>
  <span class="clearfix"></span>
</ul>
