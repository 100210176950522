/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import moment from 'moment-timezone';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

import { User, UserTypes, Company } from '@site-mate/dashpivot-shared-library';
import { CompanyPlanTypes } from '@site-mate/sitemate-global-shared';

import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { SSOBanner } from 'app/shared/model/sso-banner.model';
import { CompanyService } from 'app/shared/service/company.service';
import { HttpClientService } from 'app/shared/service/http-client.service';
import { LocalStorageService } from 'app/shared/service/local-storage.service';
import { TeamService } from 'app/shared/service/team.service';

import { SignupResponse } from './sign-up-simplified/model/signup-response.model';

export interface IUserControllerMap {
  isAdmin?: boolean;
  companies?: Record<string, boolean>;
  projects?: Record<string, boolean>;
  teams?: Record<string, boolean>;
}

@Injectable({ providedIn: 'root' })
export class UserService {
  private currentUserSubject = new BehaviorSubject({} as User);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  static getInitial(firstName = '', lastName = '') {
    return [firstName[0], lastName[0]]
      .filter((initials) => !!initials)
      .join('')
      .toUpperCase();
  }

  static getInitialStyle(initial) {
    const styles = ['badge-info', 'badge-danger', 'badge-success', 'badge-primary', 'badge-warning'];
    if (initial && initial.length > 0) {
      const alphaBeta = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const i = alphaBeta.indexOf(initial[0].toUpperCase());
      if (i >= 0 && i < alphaBeta.length) {
        return styles[i % styles.length];
      }
      return 'badge-primary';
    }
    return 'badge-primary';
  }

  constructor(
    private readonly http: HttpClientService,
    private readonly teamService: TeamService,
    private readonly companyService: CompanyService,
    private readonly localStorageService: LocalStorageService,
    private readonly i18nService: TmpI18NService,
  ) {
    const currentUser = this.localStorageService.getItem('currentUser');
    if (currentUser) {
      this.setCurrentUser(JSON.parse(currentUser));
    }
    this.currentUserSubject.subscribe((user) => {
      if (!user || !user._id) {
        this.localStorageService.removeItem('currentUser');
      } else {
        this.localStorageService.setItem('currentUser', JSON.stringify(user));
      }
    });
  }

  updateCurrentUser() {
    return this.getLoginUserDetails().pipe(
      tap((user) => {
        this.setCurrentUser(user);
      }),
    );
  }

  getCurrentUser() {
    return this.currentUserSubject.getValue();
  }

  getTimezone(): string {
    return [this.getCurrentUser().timezone, (moment as any).tz.guess()].find(Boolean);
  }

  setCurrentUser(user = {} as User) {
    this.currentUserSubject.next(user);
  }

  unsetCurrentUser() {
    this.currentUserSubject.next({} as User);
  }

  recover(email) {
    return this.http.post('users/recover', {
      email,
    });
  }

  resetPassword(recoverId, newPassword) {
    return this.http.put(`users/recover/${recoverId}`, {
      newPassword,
      newPasswordConfirmed: newPassword,
    });
  }

  verifyRecoverId(recoverId) {
    return this.http.get(`users/recover/${recoverId}`);
  }

  isAdmin() {
    return this.currentUserSubject.getValue().isGlobalAdmin;
  }

  isTeamControllerOrAbove() {
    return (
      this.isAdmin() || this.isCompanyController() || this.isProjectController() || this.isTeamController()
    );
  }

  isProjectControllerOrAbove() {
    return this.isAdmin() || this.isCompanyController() || this.isProjectController();
  }

  isProjectMemberOrAbove() {
    return this.isProjectControllerOrAbove() || this.isCompanyMember() || this.isProjectMember();
  }

  isProjectControllerOrAbove$(): Observable<boolean> {
    return this.requestControllersObservables(this.isProjectControllerOrAbove.bind(this));
  }

  isProjectMemberOrAbove$(): Observable<boolean> {
    return this.requestControllersObservables(this.isProjectMemberOrAbove.bind(this));
  }

  isCompanyControllerOrAbove$(): Observable<boolean> {
    return this.requestControllersObservables(this.isCompanyControllerOrAbove.bind(this));
  }

  isCompanyMemberOrAbove$(): Observable<boolean> {
    return this.requestControllersObservables(this.isCompanyMemberOrAbove.bind(this));
  }

  isTeamControllerOrAbove$(): Observable<boolean> {
    return this.requestControllersObservables(this.isTeamControllerOrAbove.bind(this));
  }

  private requestControllersObservables(callback): Observable<boolean> {
    return combineLatest([
      this.currentUserSubject.pipe(filter((user) => !!user)),
      this.teamService.currentTeam.pipe(filter((team) => !!team)),
    ]).pipe(switchMap(() => of(callback())));
  }

  isCompanyControllerOf(companyId) {
    const companyIds = this.getCurrentUser().companyControllerOf;
    return this.isAdmin() || companyIds?.includes(companyId);
  }

  isCompanyMemberOf(companyId: string) {
    const companyIds = this.getCurrentUser().companyMemberOf;
    return companyIds?.includes(companyId);
  }

  isProjectMemberOf(projectId: string) {
    const projectIds = this.getCurrentUser().projectMemberOf;
    return projectIds?.includes(projectId);
  }

  isProjectControllerOf(projectId) {
    const projectIds = this.getCurrentUser().projectControllerOf;
    return this.isAdmin() || projectIds?.includes(projectId);
  }

  getUserControllerMap(): IUserControllerMap {
    if (this.isAdmin()) {
      return { isAdmin: true };
    }

    return {
      companies: this.getFolderIds('companyControllerOf'),
      projects: this.getFolderIds('projectControllerOf'),
      teams: this.getFolderIds('teamControllerOf'),
    };
  }

  private getFolderIds(controllerFolder: string) {
    return this.getCurrentUser()[controllerFolder].reduce((folder, id) => {
      folder[id] = true;
      return folder;
    }, {});
  }

  isCompanyControllerOrAbove() {
    return this.isAdmin() || this.isCompanyController();
  }

  isCompanyMemberOrAbove() {
    return this.isCompanyControllerOrAbove() || this.isCompanyMember();
  }

  isCompanyController() {
    const companyIds = this.getCurrentUser().companyControllerOf;
    const currentOrg = this.teamService.getCurrentTeam();

    if (this.teamService.isCompany(currentOrg)) {
      return companyIds?.includes(this.teamService.getCurrentTeam().id);
    }
    return companyIds && companyIds.includes(this.teamService.getCurrentTeam().companyId);
  }

  isCompanyMember() {
    const companyIds = this.getCurrentUser().companyMemberOf;
    const currentOrg = this.teamService.getCurrentTeam();

    if (this.teamService.isCompany(currentOrg)) {
      return companyIds?.includes(this.teamService.getCurrentTeam().id);
    }
    return companyIds && companyIds.includes(this.teamService.getCurrentTeam().companyId);
  }

  isAnyTeamController() {
    return this.getCurrentUser().teamControllerOf && this.getCurrentUser().teamControllerOf.length > 0;
  }

  isAnyProjectController() {
    return this.getCurrentUser().projectControllerOf && this.getCurrentUser().projectControllerOf.length > 0;
  }

  isAnyCompanyController() {
    return this.getCurrentUser().companyControllerOf && this.getCurrentUser().companyControllerOf.length > 0;
  }

  isProjectController() {
    const projectIds = this.getCurrentUser().projectControllerOf;
    const currentOrg = this.teamService.getCurrentTeam();
    if (this.teamService.isTeam(currentOrg)) {
      return projectIds && projectIds.includes(this.teamService.getCurrentTeam().projectId);
    }
    return projectIds && projectIds.includes(this.teamService.getCurrentTeam().id);
  }

  isProjectMember() {
    const projectIds = this.getCurrentUser().projectMemberOf;
    const currentOrg = this.teamService.getCurrentTeam();
    if (this.teamService.isProject(currentOrg)) {
      return projectIds?.includes(this.teamService.getCurrentTeam().id);
    }
    return projectIds && projectIds.includes(this.teamService.getCurrentTeam().projectId);
  }

  isTeamController() {
    const teamIds = this.getCurrentUser().teamControllerOf;
    return teamIds && teamIds.includes(this.teamService.getCurrentTeam().id);
  }

  isCurrentUser(user) {
    if (!user || !user.id) {
      return false;
    }
    return this.currentUserSubject.getValue().id === user.id;
  }

  isCreatedByCurrentUser(element): boolean {
    if (!element || !element.createdBy) {
      return false;
    }

    const createdById = element.createdBy._id || element.createdBy.id;
    return this.currentUserSubject.getValue()._id === createdById;
  }

  verifyActivationId(id) {
    return this.http.get(`users/activation/${id}`);
  }

  activateUser(activationId, user) {
    return this.http.put(`v1/users/activation/${activationId}`, user);
  }

  uploadSignatureFile(formData) {
    return this.http.upload('users/signature', formData);
  }

  getSignatureUrl() {
    return this.http.get('users/signature');
  }

  updateProfile(user) {
    return this.http.put('users/details', user);
  }

  updateUser(userId, user) {
    return this.http.put(`users/${userId}`, user);
  }

  byId(userId) {
    return this.http.get(`users/${userId}`);
  }

  signUp(user): Observable<SignupResponse> {
    return this.http.post(`users/sign-up`, user);
  }

  sendAdBlockStatus(payload: { status: boolean; blockedContext: 'analytics' | null }) {
    return this.http.post('users/ad-block-status', payload);
  }

  getInvitedUserContext(
    userId: string,
    companyId: string,
  ): Observable<{ companyName: string; companyLogo: string; userType: UserTypes }> {
    return this.http.get(`users/sign-up/invited-user-context/user/${userId}/company/${companyId}`);
  }

  private getLoginUserDetails(): Observable<User> {
    return this.http.get('users/details');
  }

  async visitorUserValidation(
    domains: string[],
    email: string,
  ): Promise<{ isPublicDomain: boolean; isWorkplaceDomain: boolean } | { error: Error }> {
    const emailDomain = email.split('@')[1];
    const [emailSecondLevelDomain] = emailDomain.split('.');
    const isWorkplaceDomain = domains.some((domain) => {
      const [secondLevelDomain] = domain.split('.');
      return secondLevelDomain === emailSecondLevelDomain.toLowerCase();
    });

    try {
      await this.getPublicDomainValidation(email);
      return { isPublicDomain: false, isWorkplaceDomain };
    } catch (error) {
      // @ts-expect-error implicitly has an 'any' type
      if (error.status === 400) {
        return { isPublicDomain: true, isWorkplaceDomain };
      }
      // @ts-expect-error implicitly has an 'any' type
      return { error };
    }
  }

  private async getPublicDomainValidation(email: string) {
    const domain = email.split('@')[1];
    return this.companyService.validatePublicDomains([domain]).toPromise();
  }

  getSSOBannerMessage(): Observable<SSOBanner> {
    const message = {
      [CompanyPlanTypes.Legacy]: 'ssoAvailable',
      [CompanyPlanTypes.Standard]: 'ssoAvailable',
      [CompanyPlanTypes.Pro]: 'ssoAvailableImprovedSecurity',
      [CompanyPlanTypes.Enterprise]: 'ssoAvailableImprovedSecurity',
    };

    return combineLatest([
      this.currentUser,
      this.isCompanyControllerOrAbove$(),
      this.teamService.currentTeam,
    ]).pipe(
      filter(([, , company]) => company.companyMetadata),
      map(([user, isCompanyControllerOrAbove, company]) => {
        const companyMetadata = company.companyMetadata as Company;
        const plansToPromptUpgrade = [CompanyPlanTypes.Legacy, CompanyPlanTypes.Standard];
        const showUpgrade = plansToPromptUpgrade.includes(companyMetadata.billing.planType);
        const isVisitorOrNotACompanyController =
          user.visitorOf?.includes(companyMetadata._id) || !isCompanyControllerOrAbove;
        const isFreeTrial =
          (companyMetadata.billing?.planType as CompanyPlanTypes) === CompanyPlanTypes.FreeTrial;

        if (isVisitorOrNotACompanyController || isFreeTrial) {
          return null;
        }

        return {
          message: this.i18nService.getMessage(message[companyMetadata.billing?.planType]),
          showUpgrade,
        };
      }),
    );
  }
}
