import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

@Injectable()
export class IntercomService {
  constructor() {}

  hide() {
    document.body.classList.add('hide-intercom');
  }

  show() {
    document.body.classList.remove('hide-intercom');
  }

  boot() {
    // eslint-disable-next-line no-undef
    Intercom('boot', {
      app_id: environment.intercomAppId,
    });
  }

  open() {
    // eslint-disable-next-line no-undef
    Intercom('show');
  }

  onShow(callback) {
    // eslint-disable-next-line no-undef
    Intercom('onShow', callback);
  }
}
