import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash-es';

import { ExportedCellChange, HyperFormula, HyperFormulaEngine } from '@site-mate/dashpivot-shared-library';

import { FormulaEngineService } from './formula-engine.service';
import { FieldWeb } from '../model/item.model';

@Injectable({ providedIn: 'root' })
export class HyperFormulaEngineService implements FormulaEngineService {
  private formulaEngine: HyperFormulaEngine;
  private hyperFormulaInstance: HyperFormula;

  constructor() {
    this.formulaEngine = new HyperFormulaEngine();
  }

  init(): HyperFormula {
    if (this.isEngineActive()) {
      this.destroy();
    }
    this.hyperFormulaInstance = this.formulaEngine.initializeFormula();
    return this.hyperFormulaInstance;
  }

  isEngineActive() {
    return !isEmpty(this.hyperFormulaInstance);
  }

  clearSheet(tableName: string) {
    const sheet = this.getSheet(tableName);
    if (sheet === undefined) {
      return;
    }

    this.hyperFormulaInstance.clearSheet(sheet);
  }

  setEmptySheetContent(tableName: string) {
    const sheet = this.getSheet(tableName);
    this.formulaEngine.setEmptySheetContent(sheet, this.hyperFormulaInstance);
  }

  destroy() {
    this.formulaEngine.destroyFormula(this.hyperFormulaInstance);
  }

  getEngine(): HyperFormula {
    return this.hyperFormulaInstance;
  }

  initSheetContent({ table, sheet }: { sheet: number; table: FieldWeb }): void {
    this.formulaEngine.initSheetContent(sheet, table, this.hyperFormulaInstance);
  }

  calculateSheetContent({ table, sheet }: { sheet: number; table: FieldWeb }) {
    this.formulaEngine.calculateSheetContent(sheet, table, this.hyperFormulaInstance);
  }

  setValuesUpdateListener(handler: (changes: ExportedCellChange[]) => void) {
    this.formulaEngine.setValuesUpdateListener(this.hyperFormulaInstance, handler);
  }

  addSheet(sheetName: string) {
    return this.formulaEngine.addSheet(sheetName, this.hyperFormulaInstance);
  }

  getSheet(sheetName: string): number {
    return this.formulaEngine.getSheet(sheetName, this.hyperFormulaInstance);
  }

  setCellContent(params: { table: FieldWeb; colIndex: number; rowIndex: number; sheet: number }) {
    if (!this.isEngineActive()) {
      return;
    }
    const { table, colIndex, rowIndex, sheet } = params;
    const field = table.rows[rowIndex].columns[colIndex];

    this.formulaEngine.setCellContent(this.hyperFormulaInstance, field, {
      colIndex,
      rowIndex,
      sheet,
    });
  }
}
