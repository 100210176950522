"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalAdminPermissionLevel = exports.UserPermissionTypeLevels = exports.UserTypePermissionLevels = void 0;
const user_permission_types_enum_1 = require("./user-permission-types.enum");
const user_types_model_1 = require("./user-types.model");
const GlobalAdminPermissionLevel = Infinity;
exports.GlobalAdminPermissionLevel = GlobalAdminPermissionLevel;
// Higher the number, higher the permission level
const UserTypePermissionLevels = {
    [user_types_model_1.UserTypes.Visitor]: 0,
    [user_types_model_1.UserTypes.Contributor]: 1,
    [user_permission_types_enum_1.UserPermissionTypes.GlobalAdmin]: GlobalAdminPermissionLevel,
};
exports.UserTypePermissionLevels = UserTypePermissionLevels;
const UserPermissionTypeLevels = {
    [user_permission_types_enum_1.UserPermissionTypes.TeamMember]: 0,
    [user_permission_types_enum_1.UserPermissionTypes.TeamController]: 1,
    [user_permission_types_enum_1.UserPermissionTypes.ProjectMember]: 2,
    [user_permission_types_enum_1.UserPermissionTypes.ProjectController]: 3,
    [user_permission_types_enum_1.UserPermissionTypes.CompanyMember]: 4,
    [user_permission_types_enum_1.UserPermissionTypes.CompanyController]: 5,
    [user_permission_types_enum_1.UserPermissionTypes.GlobalAdmin]: GlobalAdminPermissionLevel,
};
exports.UserPermissionTypeLevels = UserPermissionTypeLevels;
