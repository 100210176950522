import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import Bugsnag from '@bugsnag/browser';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { JwtService } from './jwt.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  private readonly notFoundPage = '/404';

  constructor(
    private readonly router: Router,
    private readonly jwtService: JwtService,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly authService: AuthService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return combineLatest([this.oidcSecurityService.isAuthenticated$, this.jwtService.getToken()]).pipe(
      map(([{ isAuthenticated }, token]) => {
        if (this.checkAuthentication(isAuthenticated, token)) {
          return true;
        }

        const { url } = state;
        if (url && url !== this.notFoundPage) {
          return this.router.parseUrl(`/login?url=${url}`);
        }

        return this.router.parseUrl('/login');
      }),
    );
  }

  private checkAuthentication(isAuthenticated: boolean, token: string) {
    if (isAuthenticated) {
      return true;
    }

    // It should only raise a notification if there's a token
    const isNotAnEmptyToken = Boolean(token);

    // if token is not an FA token, then assume it is a legacy token, and try to reauthenticate
    if (isNotAnEmptyToken && !this.authService.isFusionAuthToken(token)) {
      Bugsnag.notify(
        new Error(
          'User was not authenticated through FusionAuth; a legacy token has been detected instead, redirecting to login page.',
        ),
      );
    }

    this.jwtService.destroyToken();
    return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}
