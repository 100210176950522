"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegionsService = void 0;
const sitemate_regions_enum_1 = require("../models/regions/sitemate-regions.enum");
class RegionsService {
    /**
     * Returns the associated {@link SitemateRegions} to the Amazon region provided.
     * @param amazonRegion - the region to compare
     * @returns a {@link SitemateRegions} or undefined if none matches
     */
    getSitemateRegion(amazonRegion) {
        const localRegion = amazonRegion;
        return sitemate_regions_enum_1.SitemateRegions[localRegion];
    }
    /**
     * Returns the associated Amazon region for the {@link SitemateRegions}  provided.
     * @param sitemateRegion - the {@link SitemateRegions} to compare
     * @returns an Amazon region or undefined if none matches
     */
    getAmazonRegion(sitemateRegion) {
        const value = Object.entries(sitemate_regions_enum_1.SitemateRegions).find(([, sitemateRegionName]) => sitemateRegionName === sitemateRegion);
        return value === null || value === void 0 ? void 0 : value[0];
    }
}
exports.RegionsService = RegionsService;
