"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormExtractionService = void 0;
const field_kinds_enum_1 = require("../models/fields/field-kinds.enum");
class FormExtractionService {
    extractPhotos(form, options) {
        const output = [];
        const tableTypes = [field_kinds_enum_1.FieldKinds.Table, field_kinds_enum_1.FieldKinds.PrefilledTable];
        form.items.forEach((item) => {
            var _a;
            if ((options === null || options === void 0 ? void 0 : options.exportHiding) && item.isHiddenInFormPDFExports) {
                return;
            }
            if (item.kind === field_kinds_enum_1.FieldKinds.Photo) {
                const itemPhotos = (_a = item.photos) !== null && _a !== void 0 ? _a : [];
                const photos = itemPhotos.map((photo) => (Object.assign(Object.assign({}, photo), { _id: photo._id ? String(photo._id) : photo.id })));
                output.push(...photos);
            }
            else if (tableTypes.includes(item.kind)) {
                const rows = item.rows || [];
                rows.forEach((row) => {
                    const columns = row.columns || [];
                    columns.forEach((column) => {
                        var _a;
                        const columnPhotos = (_a = column.photos) !== null && _a !== void 0 ? _a : [];
                        // some historical forms photos don't have _id
                        const photos = columnPhotos.map((photo) => (Object.assign(Object.assign({}, photo), { _id: photo._id ? String(photo._id) : photo.id })));
                        output.push(...photos);
                    });
                });
            }
        });
        return output;
    }
}
exports.FormExtractionService = FormExtractionService;
