"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormFieldValidatorFactory = void 0;
const date_field_validator_1 = require("./date-field-validator");
const date_plain_field_validator_1 = require("./date-plain-field-validator");
const date_range_plain_field_validator_1 = require("./date-range-plain-field-validator");
const default_field_validator_1 = require("./default-field-validator");
const person_field_validator_1 = require("./person-field-validator");
const prefilled_text_field_validator_1 = require("./prefilled-text-field-validator");
const signature_validator_1 = require("./signature-validator");
const signon_table_field_validator_1 = require("./signon-table-field-validator");
const sketch_field_validator_1 = require("./sketch-field-validator");
const table_field_validator_1 = require("./table/table-field-validator");
const yes_no_field_validator_1 = require("./yes-no-field-validator");
const field_kinds_enum_1 = require("../models/fields/field-kinds.enum");
class FormFieldValidatorFactory {
    static getValidator(field) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const ValidatorClass = (field.kind && this.resolver[field.kind]) || this.defaultValidator;
        return new ValidatorClass(field);
    }
}
exports.FormFieldValidatorFactory = FormFieldValidatorFactory;
FormFieldValidatorFactory.resolver = {
    [field_kinds_enum_1.FieldKinds.Date]: date_field_validator_1.DateFormFieldValidator,
    [field_kinds_enum_1.FieldKinds.DatePlain]: date_plain_field_validator_1.DatePlainFormFieldValidator,
    [field_kinds_enum_1.FieldKinds.DateRangePlain]: date_range_plain_field_validator_1.DateRangePlainFormFieldValidator,
    [field_kinds_enum_1.FieldKinds.Table]: table_field_validator_1.TableFormFieldValidator,
    [field_kinds_enum_1.FieldKinds.PrefilledTable]: table_field_validator_1.TableFormFieldValidator,
    [field_kinds_enum_1.FieldKinds.YesNoCheckbox]: yes_no_field_validator_1.YesNoFormFieldValidator,
    [field_kinds_enum_1.FieldKinds.Sketch]: sketch_field_validator_1.SketchFormFieldValidator,
    [field_kinds_enum_1.FieldKinds.Signature]: signature_validator_1.SignatureFormFieldValidator,
    [field_kinds_enum_1.FieldKinds.SignatureArray]: signature_validator_1.SignatureFormFieldValidator,
    [field_kinds_enum_1.FieldKinds.PreFilledText]: prefilled_text_field_validator_1.PrefilledTextFieldValidator,
    [field_kinds_enum_1.FieldKinds.Person]: person_field_validator_1.PersonFieldValidator,
    [field_kinds_enum_1.FieldKinds.SignonTable]: signon_table_field_validator_1.SignonTableFieldValidator,
};
FormFieldValidatorFactory.defaultValidator = default_field_validator_1.DefaultFieldValidator;
