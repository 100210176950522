"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostmarkBounceResponses = void 0;
var PostmarkBounceResponses;
(function (PostmarkBounceResponses) {
    PostmarkBounceResponses["HardBounce"] = "HardBounce";
    PostmarkBounceResponses["ISP"] = "ISP";
    PostmarkBounceResponses["Sent"] = "Sent";
    PostmarkBounceResponses["MISC"] = "MISC";
})(PostmarkBounceResponses = exports.PostmarkBounceResponses || (exports.PostmarkBounceResponses = {}));
