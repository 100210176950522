import { Pipe, PipeTransform } from '@angular/core';

import { ErrorHandler } from '../service/error-handler.service';

@Pipe({ name: 'parseMarkdownLinks' })
export class ParseMarkdownLinksPipe implements PipeTransform {
  constructor(private errorHandler: ErrorHandler) {}

  private allowedDomains = ['intercom.help', 'dashpivot.com'];

  transform(value: string): string {
    const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
    return value.replace(regex, (match, text, url) => {
      try {
        const urlObj = new URL(url);
        if (this.allowedDomains.includes(urlObj.hostname)) {
          return `<a href="${url}" target="_blank" rel="noopener">${text}</a>`;
        }
      } catch (e) {
        this.errorHandler.handleForDebug(e, {
          value,
          reason: 'Invalid markdown link in comment',
        });
      }
      return text;
    });
  }
}
