<div
  class="main-input-container sm-w-full"
  [class.has-error]="hasError"
  [class.selected]="selected"
  [class.disabled]="disabled"
  [class.with-info-button]="infoLink"
>
  <input
    [(ngModel)]="value"
    class="sm-flex-1 sm-bg-transparent sm-text-white focus:sm-outline-none"
    [placeholder]="placeholder"
    type="text"
    data-test="inner-text-input"
    (ngModelChange)="onInputValueChanged()"
    (focus)="onInputFocus()"
    (blur)="onInputBlur()"
    [disabled]="disabled"
    (keydown)="onInputKeyDown($event)"
    [name]="name"
    [id]="id"
    [readonly]="readonly"
    #textInput
  />
  <div class="right-side-addon-container">
    <ng-content select="[rhsAddon]"></ng-content>
  </div>
</div>
<a
  *ngIf="infoLink"
  class="info-button"
  [class.disabled]="disabled"
  [href]="infoLink"
  target="_blank"
  rel="noopener noreferrer"
>
  <i class="cc-icon cc-icon-question sm-text-default-text"></i>
</a>
