import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

export interface ILabelTemplateContext<T> {
  $implicit: T;
  clear: (option: T) => void;
}

export interface IOptionTemplateContext<T> {
  $implicit: T;
}

export interface ILoadingTemplateContext<T> {
  $implicit: T;
}

@Component({
  selector: 'cc-search-and-select',
  templateUrl: './search-and-select.component.html',
  styleUrls: ['./search-and-select.component.scss'],
})
export class SearchAndSelectComponent {
  @Input() name = 'search-and-select';
  @Input() placeholder = 'Select...';
  @Input() multiple = false;
  @Input() disabled = false;
  @Input() invalid = false;
  @Input() clearable = false;
  @Input() searchable = true;
  @Input() closeOnSelect = true;

  @Input() loading = false;

  @Input() items = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() selected: any;

  @Input() maxSelectedItems: number;

  @Input() notFoundText = 'No items found';
  @Input() groupBy: string | ((item: any) => string);

  @Input() customClass: string;
  @Input() minWidth = 'auto';
  @Input() maxWidth = 'none';

  @Output() change = new EventEmitter();
  @Output() open = new EventEmitter();
  @Output() selectedChange = new EventEmitter();

  /* eslint-disable @typescript-eslint/no-explicit-any */
  // The passed shape will be typed by the implementer of the component
  @ContentChild('customOptionTemplate') customOptionTemplate: TemplateRef<IOptionTemplateContext<any>>;
  @ContentChild('customLabelTemplate') customLabelTemplate: TemplateRef<ILabelTemplateContext<any>>;
  @ContentChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<ILoadingTemplateContext<any>>;
  /* eslint-enable @typescript-eslint/no-explicit-any */

  onChange() {
    this.change.emit(this.getSelected());

    this.selectedChange.emit(this.selected);
    this.disableOptionsWhenMaxSelectedItems();
  }

  onSelectOpen() {
    this.open.emit();
  }

  getSelected() {
    if (this.multiple) {
      return (
        this.selected?.map((item) => {
          const { disabled: _disabled, ...rest } = item;
          return rest;
        }) || []
      );
    }

    return this.selected?.value;
  }

  disableOptionsWhenMaxSelectedItems() {
    if (this.multiple && this.maxSelectedItems) {
      const hasReachedMaxSelectedItems = this.selected?.length >= this.maxSelectedItems;
      const selectedValues = this.getSelected().map((item) => item.value);

      this.items = this.items?.map((item) => {
        if (hasReachedMaxSelectedItems && !selectedValues.includes(item.value)) {
          item.disabled = true;
        } else {
          item.disabled = false;
        }

        return item;
      });
    }
  }
}
