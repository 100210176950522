import { Component, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { PhotoModalToolBaseComponent } from 'app/photo/photo-modal-tool-base.component';
import { AppUtilService } from 'app/shared/service/app-util.service';

@Component({
  selector: 'cc-photo-modal-side-tool',
  templateUrl: 'photo-modal-side-tool.component.html',
  styleUrls: ['photo-modal-tool-bar.component.scss', '../../styles/components/modal-tool-bar.scss'],
})
export class PhotoModalSideToolComponent extends PhotoModalToolBaseComponent {
  @Output()
  restore = new EventEmitter();

  @Output()
  close = new EventEmitter();

  constructor(
    public readonly appUtilService: AppUtilService,
    public readonly activeModal: NgbActiveModal,
  ) {
    super();
  }
}
