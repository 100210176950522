"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormPhotoValidateService = void 0;
const lodash_1 = require("lodash");
const form_extraction_service_1 = require("./form-extraction.service");
const field_kinds_enum_1 = require("../models/fields/field-kinds.enum");
class FormPhotoValidateService {
    constructor() {
        this.formExtractionService = new form_extraction_service_1.FormExtractionService();
    }
    isCompletedPhotoUrl(urlString) {
        return urlString.startsWith('https://');
    }
    getCompletedFormPhotos(form, photoKind) {
        const photos = this.formExtractionService.extractPhotos(form);
        const validPhotos = photos.filter((photo) => this.isCompletedPhotoUrl(photo.url[photoKind]));
        return { validPhotos, hasErrorPhoto: photos.length !== validPhotos.length };
    }
    getIncompleteRowPhotos(rows, photoKind) {
        if (!rows) {
            return rows;
        }
        return rows.map((row) => {
            const columns = row.columns || [];
            columns.forEach((column) => {
                const photos = column.photos || [];
                column.photos = photos.filter((photo) => this.isCompletedPhotoUrl(photo.url[photoKind]));
            });
            row.columns = columns;
            return row;
        });
    }
    filterIncompletePhotoInItem({ item, photoFieldKind, tablePhotoKind, }) {
        var _a;
        if (item.kind === field_kinds_enum_1.FieldKinds.Photo) {
            item.photos = (_a = item.photos) === null || _a === void 0 ? void 0 : _a.filter((photo) => this.isCompletedPhotoUrl(photo.url[photoFieldKind]));
        }
        if (item.kind === field_kinds_enum_1.FieldKinds.Table || item.kind === field_kinds_enum_1.FieldKinds.PrefilledTable) {
            const rows = item.rows || [];
            item.rows = this.getIncompleteRowPhotos(rows, tablePhotoKind);
        }
        return item;
    }
    filterIncompletePhoto({ form, photoFieldKind, tablePhotoKind, }) {
        const clonedForm = (0, lodash_1.cloneDeep)(form);
        clonedForm.items = clonedForm.items.map((item) => this.filterIncompletePhotoInItem({ item, photoFieldKind, tablePhotoKind }));
        return clonedForm;
    }
}
exports.FormPhotoValidateService = FormPhotoValidateService;
