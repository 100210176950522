<cc-register-cell class="selection-cell">
  <label
    class="cell-single-checkbox custom-control custom-checkbox"
    (click)="$event.stopPropagation()"
    *ngIf="displaySelectionCheckbox"
  >
    <input
      type="checkbox"
      class="custom-control-input"
      [(ngModel)]="selected"
      (change)="select.emit(selected)"
    />
    <span class="custom-control-indicator"></span>
  </label>

  <label (click)="onCollapseToggle($event)" *ngIf="collapsable" class="collapse-button">
    <span *ngIf="collapsed" i18n title="Expand" i18n-title="Expand"><i class="fa fa-caret-right"></i></span>
    <span *ngIf="!collapsed" i18n class="text-primary" title="Collapse" i18n-title="Collapse"
      ><i class="fa fa-caret-down"></i
    ></span>
  </label>
</cc-register-cell>
<ng-content></ng-content>
