<div class="d-flex justify-content-center mt-5">
  <div class="card text-center">
    <div class="card-block">
      <h4 class="h4 card-title" i18n>The page you are looking for doesn't exist.</h4>
      <p class="default-paragraph card-text" i18n>
        You may have mistyped the address or the page may have moved.
      </p>
      <a [routerLink]="['/', 'home']" class="btn btn-primary">Click here to go to home</a>
    </div>
  </div>
</div>
