"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableCellClonerFactory = void 0;
const table_cell_attachment_1 = require("./table-cell-attachment");
const table_cell_date_1 = require("./table-cell-date");
const table_cell_date_plain_1 = require("./table-cell-date-plain");
const table_cell_list_1 = require("./table-cell-list");
const table_cell_list_property_1 = require("./table-cell-list-property");
const table_cell_location_1 = require("./table-cell-location");
const table_cell_number_1 = require("./table-cell-number");
const table_cell_photo_1 = require("./table-cell-photo");
const table_cell_prefilled_text_1 = require("./table-cell-prefilled-text");
const table_cell_repository_1 = require("./table-cell-repository");
const table_cell_signature_1 = require("./table-cell-signature");
const table_cell_time_1 = require("./table-cell-time");
const table_cell_time_plain_1 = require("./table-cell-time-plain");
const __1 = require("../../..");
const tableCellCloners = {
    [__1.TableCellKinds.Attachment]: table_cell_attachment_1.TableCellAttachmentCloner,
    [__1.TableCellKinds.Date]: table_cell_date_1.TableCellDateCloner,
    [__1.TableCellKinds.DatePlain]: table_cell_date_plain_1.TableCellDatePlainCloner,
    [__1.TableCellKinds.List]: table_cell_list_1.TableCellListCloner,
    [__1.TableCellKinds.ListProperty]: table_cell_list_property_1.TableCellListPropertyCloner,
    [__1.TableCellKinds.ManualSignature]: table_cell_signature_1.TableCellSignatureCloner,
    [__1.TableCellKinds.Number]: table_cell_number_1.TableCellNumberCloner,
    [__1.TableCellKinds.Photo]: table_cell_photo_1.TableCellPhotoCloner,
    [__1.TableCellKinds.PrefilledText]: table_cell_prefilled_text_1.TableCellPrefilledTextCloner,
    [__1.TableCellKinds.Signature]: table_cell_signature_1.TableCellSignatureCloner,
    [__1.TableCellKinds.Time]: table_cell_time_1.TableCellTimeCloner,
    [__1.TableCellKinds.TimePlain]: table_cell_time_plain_1.TableCellTimePlainCloner,
    [__1.TableCellKinds.Location]: table_cell_location_1.TableCellLocationCloner,
};
class TableCellClonerFactory {
    constructor(getListItems) {
        this.getListItems = getListItems;
    }
    getCloner(kind, formField) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const Cloner = tableCellCloners[kind] || table_cell_repository_1.TableCellGenericCloner;
        return new Cloner(this.getListItems, formField);
    }
}
exports.TableCellClonerFactory = TableCellClonerFactory;
