"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonFieldUtils = void 0;
const isUserSummary = (user) => !('userDoesNotExist' in user);
const personFieldLabel = (user) => {
    if (isUserSummary(user)) {
        return `${user.firstName} ${user.lastName}`;
    }
    return 'Missing User';
};
exports.PersonFieldUtils = {
    isUserSummary,
    personFieldLabel,
};
