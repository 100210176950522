<ng-template #loader>
  <cc-workspace-loader></cc-workspace-loader>
</ng-template>

<ng-container *ngIf="!loading; else loader">
  <header class="modal-header">
    <h1 class="modal-title" i18n>Create Workspace</h1>
    <button type="button" (click)="dismiss()" aria-label="Close modal">
      <i class="cc-icon cc-icon-close sm-align-middle"></i>
    </button>
  </header>
  <form class="account-form" [formGroup]="accountForm" (ngSubmit)="onSubmit()">
    <div
      *ngIf="selectedWorkspaceCreationType !== workspaceCreationTypeValues.CurrentSmsAccount"
      class="form-group"
    >
      <label class="col-form-label" i18n> Where would you like to add this workspace? </label>
      <ng-select
        class="dropdown-selector create-workspace-option"
        [items]="workspaceCreationOptions"
        bindValue="value"
        bindLabel="label"
        [clearable]="false"
        [searchable]="false"
        formControlName="workspaceCreationOption"
      >
      </ng-select>
    </div>
    <div class="form-group">
      <label class="col-form-label" i18n>Workspace Name</label>
      <input
        type="text"
        required
        placeholder="Text..."
        i18n-placeholder="Text..."
        formControlName="workspaceName"
        name="workspaceName"
        class="form-control"
        [class.error]="hasError('workspaceName')"
      />
      <div class="error-message" data-test="workspaceName" i18n *ngIf="hasError('workspaceName')">
        {{ errorMessageContent('workspaceName') }}
      </div>
    </div>

    <div
      *ngIf="selectedWorkspaceCreationType === workspaceCreationTypeValues.CurrentSmsAccount"
      class="sm-text-xs sm-text-secondary-text"
    >
      <p>The new workspace will be linked to the same Sitemate Start Account.</p>
      <p>Sitemate Start Account ID: {{ accountId }}</p>
    </div>

    <div
      *ngIf="selectedWorkspaceCreationType === workspaceCreationTypeValues.ExistingSmsAccount"
      class="form-group"
    >
      <div class="sm-flex sm-items-center sm-gap-1">
        <label class="col-form-label" i18n>Sitemate Start Account ID</label>
        <cc-popover placement="right" containerClass="sm-flex">
          <div content class="sm-flex sm-flex-col sm-gap-4">
            <p>
              You can find the Sitemate Start Account ID of a customer in the workspace settings of any of
              their workspaces.
            </p>
            <p>It will look like this: acc_XXXXXXXX</p>
          </div>

          <em
            trigger
            triggers="mouseenter:mouseleave"
            placement="right"
            class="cc-icon cc-icon-question"
          ></em>
        </cc-popover>
      </div>
      <input
        type="text"
        required
        placeholder="Text..."
        i18n-placeholder="Text..."
        formControlName="accountId"
        name="accountId"
        class="form-control"
        [class.error]="hasError('accountId')"
      />
      <div class="error-message" data-test="accountId" i18n *ngIf="hasError('accountId')">
        {{ errorMessageContent('accountId') }}
      </div>
    </div>

    <div
      class="form-group"
      *ngIf="
        selectedWorkspaceCreationType === workspaceCreationTypeValues.NewSmsAccount ||
        selectedWorkspaceCreationType === workspaceCreationTypeValues.ExistingSmsAccount
      "
    >
      <label class="col-form-label" i18n> Sitemate Start Account Name </label>
      <input
        type="text"
        class="form-control"
        name="accountName"
        id="account-name"
        required
        placeholder="Text..."
        i18n-placeholder="Text..."
        formControlName="accountName"
        class="form-control"
        [class.error]="hasError('accountName')"
      />
      <div
        *ngIf="selectedWorkspaceCreationType === workspaceCreationTypeValues.NewSmsAccount"
        class="sm-pt-1 sm-text-xs sm-text-secondary-text"
        i18n
      >
        The Sitemate Start Account name will match Workspace name.
      </div>
      <div
        *ngIf="selectedWorkspaceCreationType === workspaceCreationTypeValues.ExistingSmsAccount"
        class="sm-pt-1 sm-text-xs sm-text-secondary-text"
        i18n
      >
        The name will appear once you populate the Sitemate Start Account ID.
      </div>
    </div>

    <ng-container *ngIf="selectedWorkspaceCreationType === workspaceCreationTypeValues.NewSmsAccount">
      <div class="form-group">
        <label class="col-form-label" i18n> Sitemate Start Account Region </label>
        <ng-select
          id="activate-region"
          class="activate-region"
          name="region-list"
          [items]="regions"
          bindValue="value"
          bindLabel="name"
          [clearable]="false"
          formControlName="region"
        >
          <ng-template ng-label-tmp let-item="item">
            <div class="country-option f16">
              <i class="flag-icon flag {{ item.ccode | lowercase }}"></i>
              <div>{{ item.name }}</div>
            </div>
          </ng-template>
          <ng-template ng-label-tmp ng-option-tmp let-item="item">
            <div class="country-option f16">
              <i class="flag-icon flag {{ item.ccode | lowercase }}"></i>
              <div>{{ item.name }}</div>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="form-group">
        <label class="col-form-label" i18n> Sitemate Start Account Subdomain </label>
        <div class="sm-flex sm-items-center">
          <input
            type="text"
            class="form-control"
            name="subdomain"
            id="account-subdomain"
            required
            placeholder="Text..."
            i18n-placeholder="Text..."
            formControlName="subdomain"
            class="form-control"
            [class.error]="hasError('subdomain')"
          />
          <p class="sm-pl-1 sm-text-sm">.sitematestart.com</p>
        </div>
        <div class="error-message" data-test="subdomain" i18n *ngIf="hasError('subdomain')">
          {{ errorMessageContent('subdomain') }}
        </div>
      </div>
    </ng-container>
    <button type="submit" class="submit-button sm-w-fit" [class.disabled]="!accountForm.valid || loading">
      {{
        selectedWorkspaceCreationType === workspaceCreationTypeValues.NewSmsAccount
          ? 'Create Sitemate Start Account and Workspace'
          : 'Create Workspace'
      }}
    </button>
  </form>
</ng-container>
