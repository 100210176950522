"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeaderFooterDefaultFields = void 0;
// TODO: move defaults to backend; https://site-mate.slack.com/archives/C03E84QNWCD/p1664333711639499?thread_ts=1664154885.183419&cid=C03E84QNWCD
const header_footer_field_kinds_enum_1 = require("./header-footer-field-kinds.enum");
exports.HeaderFooterDefaultFields = {
    header: [
        {
            kind: header_footer_field_kinds_enum_1.HeaderFooterFieldKinds.Logo,
            gridPosition: {
                row: 1,
                rowSpan: 3,
                column: 1,
                columnSpan: 1,
            },
        },
        {
            kind: header_footer_field_kinds_enum_1.HeaderFooterFieldKinds.Filepath,
            gridPosition: {
                row: 1,
                rowSpan: 3,
                column: 2,
                columnSpan: 1,
            },
        },
        {
            kind: header_footer_field_kinds_enum_1.HeaderFooterFieldKinds.TemplateName,
            isVisible: true,
        },
        {
            kind: header_footer_field_kinds_enum_1.HeaderFooterFieldKinds.TemplateId,
            gridPosition: {
                row: 1,
                rowSpan: 1,
                column: 3,
                columnSpan: 1,
            },
        },
        {
            kind: header_footer_field_kinds_enum_1.HeaderFooterFieldKinds.TemplateVersion,
            gridPosition: {
                row: 2,
                rowSpan: 1,
                column: 3,
                columnSpan: 1,
            },
        },
        {
            kind: header_footer_field_kinds_enum_1.HeaderFooterFieldKinds.FormVersion,
            gridPosition: {
                row: 3,
                rowSpan: 1,
                column: 3,
                columnSpan: 1,
            },
        },
        {
            kind: header_footer_field_kinds_enum_1.HeaderFooterFieldKinds.FormCreatorAndCreationTime,
            isVisible: true,
        },
    ],
    footer: [
        {
            kind: header_footer_field_kinds_enum_1.HeaderFooterFieldKinds.UncontrolledVersionDisclaimer,
            gridPosition: {
                row: 1,
                rowSpan: 1,
                column: 2,
                columnSpan: 1,
            },
        },
        {
            kind: header_footer_field_kinds_enum_1.HeaderFooterFieldKinds.PageNumbers,
            gridPosition: {
                row: 2,
                rowSpan: 1,
                column: 2,
                columnSpan: 1,
            },
        },
        {
            kind: header_footer_field_kinds_enum_1.HeaderFooterFieldKinds.PdfCreationTimeAndDate,
            gridPosition: {
                row: 1,
                rowSpan: 2,
                column: 3,
                columnSpan: 1,
            },
        },
    ],
};
