import { Injectable } from '@angular/core';
import { ActiveToast, IndividualConfig, ToastrService as NgxToastrService } from 'ngx-toastr';

import { TmpI18NService } from 'app/i18n/tmp-i18n.service';

@Injectable({
  providedIn: 'root',
})
export class ToastrService {
  constructor(
    private readonly toastr: NgxToastrService,
    private readonly i18nService: TmpI18NService,
  ) {}

  successByKey(messageKey) {
    this.toastr.success(this.i18nService.getMessage(messageKey));
  }

  error(message: string, title?: string, options: Partial<IndividualConfig> = {}) {
    this.toastr.error(message, title, options);
  }

  errorByKey(messageKey: string, options: Partial<IndividualConfig> = {}) {
    this.error(this.i18nService.getMessage(messageKey), undefined, options);
  }

  warn(message: string, options: Partial<IndividualConfig> = {}): ActiveToast<any> {
    return this.toastr.warning(message, undefined, options);
  }

  warnByKey(messageKey: string, options: Partial<IndividualConfig> = {}): ActiveToast<any> {
    return this.toastr.warning(this.i18nService.getMessage(messageKey), undefined, options);
  }

  clear(toastId?: number) {
    this.toastr.clear(toastId);
  }

  remove(toastId: number) {
    this.toastr.remove(toastId);
  }
}
