<div>
  <ul class="photo-list-container" *ngIf="photos && photos.length > 0">
    <li *ngFor="let photo of photos; let i = index">
      <div
        class="photo-list-item photo-list-item-{{ thumbnailSize }}{{ isLandscapeMode ? '-landscape' : '' }}"
        [class.sm-cursor-not-allowed]="photo.localImage"
        [class.sm-cursor-pointer]="!photo.localImage"
        [hidden]="!showAll && maxDisplay > 0 && i > maxDisplay - 1"
      >
        <!-- Placeholder thumbnail for local images -->
        <cc-tool-tip
          *ngIf="photo.localImage"
          placement="right"
          containerClass="sm-inline-block sm-w-full"
          [contentText]="getTooltipText(photo.isVideo)"
        >
          <div class="thumbnail placeholder" [ngClass]="displayThumbnailSize ? thumbnailSize : ''">
            <i
              class="{{
                photo.isVideo ? 'cc-icon cc-icon-video' : 'cc-icon cc-icon-photo'
              }} sm-text-secondary-text"
              aria-hidden="true"
            >
            </i>
            <span
              *ngIf="item?.photoThumbnailSize !== photoThumbnailSizes.ExtraSmall"
              class="sm-whitespace-pre-wrap sm-text-center sm-text-xs sm-text-secondary-text"
              i18n
              >{{ photo.isVideo ? 'Video' : 'Photo' }} upload incomplete</span
            >
          </div>
        </cc-tool-tip>
        <div class="sm-flex sm-flex-col sm-gap-2">
          <!-- Actual thumbnail -->
          <img
            *ngIf="!photo.localImage"
            [ngClass]="displayThumbnailSize ? thumbnailSize : 'thumbnail'"
            [class.landscape]="isLandscapeMode"
            [src]="isLargeOrExtraLarge && !photo.isVideo ? photo.url?.compressedFile : photo.url?.thumbnail"
            [alt]="photo.filename"
            data-test="photo-thumbnail"
          />
          <div class="sm-flex sm-flex-col sm-gap-1">
            <p
              class="sm-font-size-legacy-2.4 long-text sm-w-full sm-leading-4 sm-text-default-text"
              *ngIf="isLargeOrExtraLarge && isPhotoDescriptionVisible"
              data-test="photo-description"
              i18n
            >
              {{ getPhotoDescription(photo) ?? 'No description attached.' }}
            </p>
            <p
              class="sm-font-size-legacy-2.4 sm-w-full sm-truncate sm-leading-4 sm-text-default-text"
              *ngIf="isLargeOrExtraLarge && isPhotoTagsVisible"
              data-test="photo-tags"
              i18n
            >
              {{ getPhotoTags(photo) ?? 'No photo tags attached.' }}
            </p>
          </div>
        </div>
        <div
          *ngIf="!photo.localImage"
          class="backdrop"
          (click)="$event.stopPropagation(); onClick({ item: item, index: i })"
        >
          <i
            *ngIf="photo.isVideo"
            class="fa fa-play"
            [class.fa-3x]="item?.photoThumbnailSize !== photoThumbnailSizes.ExtraSmall"
            aria-hidden="true"
          ></i>
        </div>
        <a
          *ngIf="!readOnly"
          (click)="remove.emit({ item: item, index: i })"
          class="photo-list-item-delete sm-text-default-red hover:sm-text-secondary-red hover:sm-no-underline"
        >
          <i class="cc-icon cc-icon-close-small"></i>
        </a>
      </div>
    </li>
  </ul>
  <div class="mb-2" *ngIf="photos && photos.length > 0 && maxDisplay > 0 && photos.length > maxDisplay">
    <a *ngIf="!showAll" class="text-link" i18n (mouseup)="$event.stopPropagation(); showAll = true"
      >Show all</a
    >
    <a *ngIf="showAll" class="text-link" i18n (mouseup)="$event.stopPropagation(); showAll = false">Hide</a>
  </div>
</div>
