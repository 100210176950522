"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.YesNoFormFieldValidator = void 0;
class YesNoFormFieldValidator {
    hasAnyData(field) {
        return !!field.checked || !!field.textInputValue;
    }
    hasCompleteData(field) {
        return !!field.checked;
    }
    isValid(field) {
        if (field.isRequired && !this.hasCompleteData(field)) {
            return false;
        }
        return true;
    }
    invalidDescription(field) {
        if (field.isRequired && !this.hasCompleteData(field)) {
            return '';
        }
        if (!this.isValid(field)) {
            return 'invalid';
        }
        return '';
    }
}
exports.YesNoFormFieldValidator = YesNoFormFieldValidator;
