"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TEXT_FORMULAS = exports.TEXT_NOT_SUPPORTED_FORMULAS = exports.TEXT_SUPPORTED_FORMULAS = exports.TEXT_SUPPORTED_FORMULAS_WITH_INFO = void 0;
exports.TEXT_SUPPORTED_FORMULAS_WITH_INFO = new Map([
    [
        'CLEAN',
        {
            description: 'Returns text that has been "cleaned" of line breaks and other non-printable characters.',
            syntax: 'CLEAN("Text")',
        },
    ],
    [
        'CODE',
        {
            description: 'Returns a numeric code for the first character in a text string.',
            syntax: 'CODE("Text")',
        },
    ],
    [
        'CONCATENATE',
        {
            description: 'Combines several text strings into one string.',
            syntax: 'CONCATENATE("Text1", ..., "Text30")',
        },
    ],
    [
        'EXACT',
        {
            description: 'Returns TRUE if both text strings are exactly the same.',
            syntax: 'EXACT(Text, Text)',
        },
    ],
    [
        'FIND',
        {
            description: 'Returns the location of one text string inside another.',
            syntax: 'FIND( "Text1", "Text2"[, Number])',
        },
    ],
    [
        'LEFT',
        {
            description: 'Extracts a given number of characters from the left side of a text string.',
            syntax: 'LEFT("Text", Number)',
        },
    ],
    [
        'LEN',
        {
            description: 'Returns length of a given text.',
            syntax: 'LEN("Text")',
        },
    ],
    [
        'LOWER',
        {
            description: 'Returns text converted to lowercase.',
            syntax: 'LOWER(Text)',
        },
    ],
    [
        'MID',
        {
            description: 'Returns substring of a given length starting from Start_position.',
            syntax: 'MID(Text, Start_position, Length)',
        },
    ],
    [
        'PROPER',
        {
            description: 'Capitalizes words given text string.',
            syntax: 'PROPER("Text")',
        },
    ],
    [
        'REPLACE',
        {
            description: 'Replaces substring of a text of a given length that starts at given position.',
            syntax: 'REPLACE(Text, Start_position, Length, New_text)',
        },
    ],
    [
        'REPT',
        {
            description: 'Repeats text a given number of times.',
            syntax: 'REPT("Text", Number)',
        },
    ],
    [
        'RIGHT',
        {
            description: 'Extracts a given number of characters from the right side of a text string.',
            syntax: 'RIGHT("Text", Number)',
        },
    ],
    [
        'SEARCH',
        {
            description: 'Returns the location of one text string inside another. (Allows the use of wildcards.)',
            syntax: 'SEARCH( "Text1", "Text2"[, Number])',
        },
    ],
    [
        'SPLIT',
        {
            description: 'Divides the provided text using the space character as a separator and returns the substring at the zero-based position specified by the second argument.',
            syntax: 'SPLIT(Text, Index)',
        },
    ],
    [
        'SUBSTITUTE',
        {
            description: 'Returns string where occurrences of Old_text are replaced by New_text. Replaces only specific occurrence if last parameter is provided.',
            syntax: 'SUBSTITUTE(Text, Old_text, New_text, [Occurrence])',
        },
    ],
    [
        'T',
        {
            description: 'Returns text if the given value is text, an empty string otherwise.',
            syntax: 'T(Value)',
        },
    ],
    [
        'TEXT',
        {
            description: 'Converts a number into text according to a given format.',
            syntax: 'TEXT(Number, Format)',
        },
    ],
    [
        'TRIM',
        {
            description: 'Strips extra spaces from text.',
            syntax: 'TRIM("Text")',
        },
    ],
    [
        'UNICHAR',
        {
            description: 'Returns the character created by using provided code point.',
            syntax: 'UNICHAR(Number)',
        },
    ],
    [
        'UNICODE',
        {
            description: 'Returns the Unicode code point of a first character of a text.',
            syntax: 'UNICODE(Text)',
        },
    ],
    [
        'UPPER',
        {
            description: 'Returns text converted to uppercase.',
            syntax: 'UPPER(Text)',
        },
    ],
]);
exports.TEXT_SUPPORTED_FORMULAS = [...exports.TEXT_SUPPORTED_FORMULAS_WITH_INFO.keys()];
exports.TEXT_NOT_SUPPORTED_FORMULAS = ['CHAR'];
exports.TEXT_FORMULAS = exports.TEXT_SUPPORTED_FORMULAS.concat(exports.TEXT_NOT_SUPPORTED_FORMULAS);
