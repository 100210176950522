import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Company, Form, Project, SitemateTaskTypes, Template } from '@site-mate/dashpivot-shared-library';

import { Dashboard } from 'app/analytics/dashboards/dashboard';

import { ChartSettings } from '../model/charts/chart-settings.model';
import { ISitemateTaskDefinitionWeb } from '../model/task-definition.model';
import { TeamWeb } from '../model/team.model';
import { TemplateFormsCount } from '../model/template-forms-count.model';
import { Tab } from '../model/template-tab.model';
import { TemplateWeb } from '../model/template.model';

type Params = {
  company?: Company;
  companyFromTeam?: Company;
  project?: Project;
  team?: TeamWeb;
  charts?: ChartSettings[];
  dashboard?: Dashboard;
  dashboards?: Dashboard[];
  template?: TemplateWeb;
  templates?: Template[];
  [SitemateTaskTypes.FormRecurring]?: ISitemateTaskDefinitionWeb[];
  [SitemateTaskTypes.FormSingle]?: ISitemateTaskDefinitionWeb[];
  templatesFormsCount?: TemplateFormsCount[];
  tabs?: Tab[];
  toolbarTabs?: Tab[];
  form?: Form;
};

type ParamsSubject = {
  [key in keyof Params]: BehaviorSubject<Params[key]>;
};

@Injectable({ providedIn: 'root' })
export class RouteParamsService {
  private params: ParamsSubject = {};

  get<Key extends keyof ParamsSubject>(key: Key): ParamsSubject[Key] {
    if (!this.params[key]) {
      this.params[key] = new BehaviorSubject<any>(null);
    }

    return this.params[key];
  }

  set<Key extends keyof Params>(key: Key, value: Params[Key]) {
    this.get(key).next(value as any);
    return this.params[key];
  }
}
