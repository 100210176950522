import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { startCase } from 'lodash-es';

import {
  DashpivotEvent,
  DashpivotModules,
  EventNotifierService,
  EventTypes,
} from '@site-mate/dashpivot-shared-library';

import { SegmentService } from 'app/segment/segment.service';
import { AccountUpgradeLimitComponent } from 'app/user/account-upgrade/account-upgrade-limit/account-upgrade-limit.component';

@Component({
  selector: 'cc-limits-counter',
  templateUrl: './limits-counter.component.html',
  styleUrls: ['./limits-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LimitsCounterComponent implements OnInit {
  @Input() itemsCount = 0;
  @Input() totalLimit: number;
  @Input() context: DashpivotModules;
  @Input() stylingChangesOnHover = false;
  @Input() isUpgradeAvailable: boolean;

  fallbackLimit: number;
  modalParam: string;
  title: string;

  readonly modalParams = {
    Lists: 'properties',
    Templates: 'fields',
  };

  constructor(
    private readonly ngbModal: NgbModal,
    private readonly segmentService: SegmentService,
  ) {}

  ngOnInit() {
    this.modalParam = this.modalParams[this.context];
    this.title = `Total ${startCase(this.modalParam)}`;
  }

  openUpgradeModal() {
    this.sendEvent();

    const upgradeModal = this.ngbModal.open(AccountUpgradeLimitComponent, {
      windowClass: 'modal-500',
      modalDialogClass: 'modal-dialog-top',
    });

    upgradeModal.componentInstance.title = `Unlock more ${this.modalParam}!`;
    upgradeModal.componentInstance.subtitle = `Contact us to upgrade your subscription plan and increase the number of ${this.modalParam} you can add into your ${this.context}.`;
    upgradeModal.componentInstance.imageSrc = `/assets/images/usage-limits/${this.modalParam}.svg`;
    upgradeModal.componentInstance.imageAlt = 'text and photo fields illustration';
  }

  private sendEvent() {
    const eventContext =
      this.context === DashpivotModules.Lists ? 'List Property Counter' : 'Template Editor Field Counter';
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.UpgradeClicked, {
        Context: eventContext,
      }),
      this.segmentService,
    );
  }
}
