import { Directive, ElementRef, HostListener, Inject, Input } from '@angular/core';
import { defaultTo } from 'lodash-es';
// eslint-disable-next-line import/no-unresolved,import/no-extraneous-dependencies
import { ClipboardEvent } from 'react';
import { timer } from 'rxjs';
import { finalize, map, takeWhile } from 'rxjs/operators';

import { EventTypes, FieldKinds, TableCellData, TableRow } from '@site-mate/dashpivot-shared-library';

import { TableColumnService } from 'app/form-fields/table/service/table-column-service';
import { FieldWeb } from 'app/shared/model/item.model';
import { DefaultTableContentPaste } from 'app/shared/model/utils/default-table-content-paste.model';
import { ToastrService } from 'app/shared/service/toastr.service';

import { WINDOW } from '../factory/window.factory';
import { EventsService } from '../service/events/events.service';

interface INewRowContentParams {
  contentEditableElement: HTMLElement;
  columnOffset: number;
  data: TableRow[];
  rowOffset: number;
  row: TableRow;
}

@Directive({
  selector: '[ccDefaultTableContentPaste]',
})
export class DefaultTableContentPasteDirective {
  @Input('ccDefaultTableContentPaste') target: FieldWeb;
  @Input() dataIndex: { row: number; column: number };

  readonly batchSize = 15;

  constructor(
    @Inject(WINDOW) private readonly window: Window,
    private readonly contentEditableElementRef: ElementRef,
    private readonly toastrService: ToastrService,
    private readonly tableColumnService: TableColumnService,
    private readonly eventsService: EventsService,
  ) {}

  @HostListener('paste', ['$event'])
  onPasteContent(event: ClipboardEvent) {
    if (this.target.kind !== FieldKinds.Table || this.target.readOnly) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    this.toastrService.warn('Pasting data into table');
    let rowOffset = this.dataIndex.row;
    this.eventsService.trackEvent(EventTypes.FormTableDefaultRowCopyPasted);
    const columnOffset = this.dataIndex.column;
    const contentEditableElement = this.getTableTextEditorElement();

    const worker = new Worker(new URL('../worker/content-paste-parser.worker', import.meta.url), {
      type: 'module',
    });
    worker.onmessage = ({ data }: { data: TableRow[] }) => {
      const localData = data.slice();
      const appendInterval = 100; // Interval in millisseconds between each column inserted
      const newRows: TableRow[] = [];
      const rowUpdates: Array<{ rowIndex: number; newData: TableRow }> = [];

      timer(0, appendInterval)
        .pipe(
          map(() => localData.shift()),
          takeWhile((row) => !!row),
          finalize(() => {
            this.processRowBatch({ newRows, rowUpdates, isDone: true });
          }),
        )
        .subscribe((row) => {
          if (this.target.rows[rowOffset]) {
            const newData = this.getNewRowContent({
              data,
              row,
              rowOffset,
              columnOffset,
              contentEditableElement,
            });
            rowUpdates.push({ rowIndex: rowOffset, newData });
          } else {
            newRows.push(row);
          }

          rowOffset += 1;

          const isMultipleOf15thRow = rowOffset % this.batchSize === this.batchSize - 1;
          if (isMultipleOf15thRow) {
            this.processRowBatch({ newRows, rowUpdates, isDone: false });
          }
        });
      worker.terminate();
    };

    worker.onerror = () => {
      this.toastrService.error('An error occurred while processing paste data.');
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const clipboardData = defaultTo<DataTransfer>(event.clipboardData, window.clipboardData);
    const message = {
      content: clipboardData.getData('text'),
      htmlContent: clipboardData.getData('text/html'),
      columns: this.target.columns.map((column) => DefaultTableContentPaste.asSerializableColumn(column)),
      columnOffset: this.dataIndex.column,
    };
    worker.postMessage(message);
  }

  private processRowBatch({
    newRows,
    rowUpdates,
    isDone,
  }: {
    newRows: TableRow[];
    rowUpdates: Array<{ rowIndex: number; newData: TableRow }>;
    isDone: boolean;
  }) {
    this.tableColumnService.batchAddRows({ tableId: this.target.id, newRows, isDone });
    this.tableColumnService.batchUpdateRows({
      tableId: this.target.id,
      updates: rowUpdates,
      isDone,
    });
    newRows.splice(0);
  }

  private getTableTextEditorElement(): HTMLElement {
    const tableTextEditorSelector = '#table-text-editor';
    return this.contentEditableElementRef.nativeElement.querySelector(tableTextEditorSelector);
  }

  private getNewRowContent({
    contentEditableElement,
    data,
    columnOffset,
    rowOffset,
    row,
  }: INewRowContentParams) {
    const newRowData = {
      ...this.target.rows[rowOffset],
      columns: this.target.rows[rowOffset].columns.slice(0, columnOffset).concat(
        // Merges existing data with paste data
        row.columns.slice(columnOffset).map((newDataColumn, newDataColumnIndex) => {
          const currentDataColumn = defaultTo(
            this.target.rows[rowOffset].columns[columnOffset + newDataColumnIndex],
            {} as TableCellData,
          );
          const currentColumn = this.target.columns[columnOffset + newDataColumnIndex];
          const digester = DefaultTableContentPaste.getDigester(currentColumn.kind);
          const value = digester.output(
            newDataColumn.value,
            currentDataColumn.value,
            data,
            contentEditableElement,
            this.window,
          );

          return { ...currentDataColumn, ...newDataColumn, value };
        }),
      ),
    };

    return newRowData;
  }
}
