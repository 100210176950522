<div class="sm-flex sm-items-center sm-gap-x-2 sm-py-1.25">
  <label
    [class.disabled]="isDisabled"
    class="default-label toggle-control sm-mt-0.4 sm-inline sm-h-4"
    data-test="group-billing-toggle"
  >
    <input
      [disabled]="isDisabled"
      name="input-toggle"
      type="checkbox"
      [ngModel]="modelValue"
      (click)="onToggleClicked()"
    />
    <span class="control"></span>
  </label>

  <i
    *ngIf="popoverContent"
    class="cc-icon cc-icon-question"
    [ngbPopover]="popoverContent"
    popoverClass="label-popover"
    placement="right"
    triggers="mouseenter:mouseleave"
    container="body"
    data-test="legacy-restrictions-tooltip"
  ></i>
</div>
