"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatePlainFormFieldValidator = void 0;
const date_plain_util_1 = require("../utils/date-plain-util");
class DatePlainFormFieldValidator {
    hasAnyData(field) {
        return this.isValidDate(field.data.date);
    }
    hasCompleteData(field) {
        var _a;
        return this.isValidDate((_a = field.data) === null || _a === void 0 ? void 0 : _a.date);
    }
    isValid(field) {
        if (field.isRequired && !this.hasCompleteData(field)) {
            return false;
        }
        return true;
    }
    invalidDescription(field) {
        if (field.isRequired && !this.hasCompleteData(field)) {
            return '';
        }
        if (field._invalid) {
            return 'Start date/time should be before end date/time';
        }
        return '';
    }
    isValidDate(value) {
        return date_plain_util_1.DatePlainUtil.isValidDate(value);
    }
}
exports.DatePlainFormFieldValidator = DatePlainFormFieldValidator;
