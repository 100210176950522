import { OnDestroy, Directive } from '@angular/core';

import { ModalBaseComponent } from './modal-base.component';

@Directive()
export class ModalBaseEscComponent extends ModalBaseComponent implements OnDestroy {
  canEsc = true;

  onEsc() {
    if (this.canEsc) {
      this.dismissModal();
    }
  }
}
