import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { SubscriptionStatuses } from '@site-mate/sitemate-global-shared';

import { LocalStorageService } from './local-storage.service';
import { BannerBadgeTypes } from '../model/banner-badge-types.enum';
import { BannerKeys } from '../model/banner-keys.enum';
import { BannerMetadata } from '../model/banner-metadata.model';

export type BannerPriorityListItem = {
  priority: number;
  parentComponent: string;
  bannerKey: BannerKeys;
  isDismissed: boolean;
  isExpired: boolean;
};

@Injectable({ providedIn: 'root' })
export class BannerService {
  private readonly dayInterval = 1000 * 60 * 60 * 24; // 1 day.
  private releaseInterval: number;

  private bannerPriorityListSubject = new BehaviorSubject<BannerPriorityListItem[]>([]);
  bannerPriorityList$ = this.bannerPriorityListSubject.asObservable();

  private priorityKey = new BehaviorSubject<BannerKeys>(null);
  priorityBannerKey$ = this.priorityKey.asObservable();

  private numberOfExportsSubject = new BehaviorSubject<{ csv: number; pdf: number }>({ csv: 0, pdf: 0 });
  numberOfExports$ = this.numberOfExportsSubject.asObservable();

  constructor(private readonly localStorageService: LocalStorageService) {}

  isVisibleBasedOnBannerTypeAndSubscription(
    bannerKey: BannerKeys,
    subscriptionStatus: SubscriptionStatuses,
    isBadgeOnly = false,
  ): boolean {
    if (BannerMetadata[bannerKey].type === BannerBadgeTypes.Tip) {
      return subscriptionStatus === SubscriptionStatuses.FreeTrial;
    }

    const isNewAnnouncement = this.isNewAnnouncement(bannerKey, subscriptionStatus);
    if (BannerMetadata[bannerKey].type === BannerBadgeTypes.New) {
      return isNewAnnouncement && (isBadgeOnly ? true : !this.isBannerDismissed(bannerKey));
    }

    return false;
  }

  isNewAnnouncement(bannerKey: BannerKeys, subscriptionStatus: SubscriptionStatuses): boolean {
    const isPaidSubscription = subscriptionStatus === SubscriptionStatuses.Paid;

    return isPaidSubscription && !this.isExpired(bannerKey);
  }

  isExpired(bannerKey: BannerKeys): boolean {
    if (BannerMetadata[bannerKey].type === BannerBadgeTypes.Tip || !BannerMetadata[bannerKey].releaseDate) {
      return false;
    }

    const standardReleaseIntervalInDays = 45;

    this.releaseInterval =
      this.dayInterval * (BannerMetadata[bannerKey].durationInDays || standardReleaseIntervalInDays);
    const releaseDate = new Date(BannerMetadata[bannerKey].releaseDate);
    const newBannerExpireDate = new Date(releaseDate.getTime() + this.releaseInterval);

    return newBannerExpireDate <= new Date();
  }

  isBannerDismissed(bannerKey: BannerKeys): boolean {
    const fullStorageKey = `${BannerMetadata[bannerKey].localStorageKey}-visible`;
    return this.localStorageService.getItem(fullStorageKey) === 'false';
  }

  /* Priority Directive */

  getPriorityList() {
    return this.bannerPriorityListSubject.value;
  }

  getBannerWithPriority() {
    return this.priorityKey.value;
  }

  addBannerToPriorityList(priority: number, parentComponent: string, bannerKey: BannerKeys) {
    const isBannerInPriorityList = this.bannerPriorityListSubject?.value?.find(
      (banner) => banner.parentComponent === parentComponent && banner.bannerKey === bannerKey,
    );

    if (!isBannerInPriorityList) {
      const isBannerDismissed = this.isBannerDismissed(bannerKey);
      const isBannerExpired = this.isExpired(bannerKey);
      const newBanner = {
        priority,
        parentComponent,
        bannerKey,
        isDismissed: isBannerDismissed,
        isExpired: isBannerExpired,
      };

      this.bannerPriorityListSubject.next([...this.bannerPriorityListSubject.value, newBanner]);
      this.setPriorityKey(parentComponent);
    }
  }

  removeBannerFromPriorityList(bannerKey: BannerKeys, parentComponent: string) {
    const bannerPriorityList = this.bannerPriorityListSubject.value.filter((banner) =>
      banner.bannerKey === bannerKey ? banner.parentComponent !== parentComponent : true,
    );

    this.bannerPriorityListSubject.next(bannerPriorityList);
    this.setPriorityKey(parentComponent);
  }

  setPriorityKey(parentComponent: string) {
    const filterOutOtherParentAndDismissedAndExpiredBanners = (banner: BannerPriorityListItem) =>
      banner.parentComponent === parentComponent && !banner.isDismissed && !banner.isExpired;

    const componentBannerPriorities = this.bannerPriorityListSubject?.value
      ?.filter(filterOutOtherParentAndDismissedAndExpiredBanners)
      .sort((a, b) => a.priority - b.priority);

    const priorityBanner = componentBannerPriorities[0];
    this.priorityKey.next(priorityBanner?.bannerKey ?? null);
  }

  // Exports Flowsite Banner Logic

  getNumberOfExports() {
    return this.numberOfExportsSubject.value;
  }

  setNumberOfExports(exports: { csv?: number; pdf?: number }) {
    const currentExports = this.numberOfExportsSubject.value;
    this.numberOfExportsSubject.next({ ...currentExports, ...exports });
  }
}
