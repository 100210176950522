import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'cc-edit-modal-page',
  templateUrl: './edit-modal-page.component.html',
  styleUrls: ['./edit-modal-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditModalPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
