<div
  [class]="containerClass"
  role="alert"
  [triggers]="trigger"
  [container]="container"
  popoverClass="tooltip-popover {{ popoverCustomClass }}"
  [ngbPopover]="popover"
  [placement]="placement"
  [autoClose]="autoClose"
  #popoverComponent="ngbPopover"
  [disablePopover]="disabled"
>
  <ng-content select="[trigger]"></ng-content>
</div>
<ng-template #popover>
  <ng-content select="[content]"></ng-content>
</ng-template>
