import { Injectable } from '@angular/core';
import { Parser } from 'hot-formula-parser';

import { TableRow } from '@site-mate/dashpivot-shared-library';

interface Cell {
  label: string;
  column: { index: number };
  row: { index: number };
}

@Injectable({ providedIn: 'root' })
export class FormulaVerificationService {
  verify(formula: string, rows: TableRow[], formulaRowNumber = 1, cellCoord: string): string {
    if (!formula) {
      return '';
    }

    if (formula && !formula.trimLeft().startsWith('=')) {
      return 'Start formula with an = sign';
    }

    if (formula && this.validateCircularDependency(formula, cellCoord)) {
      return 'Circular dependency detected';
    }

    const parser = new Parser();
    this.addParserHooks(parser, rows, formulaRowNumber);

    const formulaCleaned =
      formula && formula.trimLeft().startsWith('=') ? formula.substr(formula.indexOf('=') + 1) : formula;

    const { error } = parser.parse(formulaCleaned);

    return error ? this.formatError(error) : '';
  }

  private validateCircularDependency(formula: string, cellCoord: string): boolean {
    const regex = new RegExp(`(${cellCoord})(?![0-9])`, 'i');

    return regex.test(formula);
  }

  private addParserHooks(parser: typeof Parser, rows: TableRow[], formulaRowNumber = 1) {
    parser.on('callCellValue', (cell: Cell, done) => {
      // adjust the row index relative to formulaRowNumber - as formulas are relative
      // to the whole template, but rows are only relative to the current table
      // eg. formula=A5*10, if formulaRowNumber=5, then A5 should be A1 for this table
      const rowIndex = cell.row.index - formulaRowNumber + 1; // rows are 1 based, index is 0 based
      const colIndex = cell.column.index;

      if (rows && rowIndex >= 0 && rowIndex < rows.length) {
        if (colIndex < rows[rowIndex].columns.length) {
          done(1); // cell exists
        } else {
          throw Error('#VALUE!');
        }
      } else {
        throw Error('#VALUE!');
      }
    });

    parser.on('callFunction', (name: string, params: any[], done) => {
      // TODO: can we send another error through if the function is right, but has the wrong number of params?
      if (name.toUpperCase() === 'TIMEDIF' && params.length > 1) {
        done(1);
      } else if (name.toUpperCase() === 'DATEDIF' && params.length > 1) {
        done(1);
      }
    });
  }

  private formatError(error: string): string {
    if (!error) {
      return null;
    }

    // TODO: not sure what error detail we should pass back to users yet...
    // #DIV/0! Divide by zero error;
    // #N/A Indicates that a value is not available to a formula;
    // #NUM! Occurs when formula encounters an invalid number;

    switch (error) {
      case '#NAME?': // #NAME? Not recognised function name or variable name;
        return '#NAME? Unknown formula or cell';
      case '#ERROR!': // #ERROR! General error;
        return '#ERROR!';
      case '#VALUE!': // #VALUE! Occurs when one of formula arguments is of the wrong type.
        // We get this error whenever there isn't a value (which might be nothing in the cell or an invalid cell)
        return '#VALUE! Invalid cell name';
      default:
        return error;
    }
  }
}
