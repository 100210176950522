<div class="formula-input">
  <img
    src="./assets/images/function-variant.svg"
    alt="formula"
    *ngIf="isPrefilledTableColumn"
    [ngbPopover]="getFormulaHelperText"
    triggers="mouseenter:mouseleave"
    container="body"
    class="align-bottom formula-icon"
  />
  <span>{{ cellData.value }}</span>
</div>
