<cc-register-cell class="selection-cell">
  <label
    class="default-label cell-single-checkbox custom-control custom-checkbox"
    (click)="$event.stopPropagation()"
    *ngIf="displaySelectionCheckbox"
  >
    <input
      type="checkbox"
      class="custom-control-input"
      [(ngModel)]="selected"
      (change)="selectAll.emit(selected)"
    />
    <span class="custom-control-indicator"></span>
  </label>
</cc-register-cell>
<ng-content></ng-content>
