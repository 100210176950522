import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cc-account-upgrade-limit-ui',
  templateUrl: './account-upgrade-limit-ui.component.html',
  styleUrls: ['./account-upgrade-limit-ui.component.scss'],
})
export class AccountUpgradeLimitUIComponent {
  @Output() openLiveChat = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();
  @Input() title: string;
  @Input() subtitle: string;
  @Input() imageSrc: string;
  @Input() imageAlt: string;
  @Input() hasInnerHtmlSubtitle: boolean;
  @Input() placeHolderHeight: string;
  @Input() showLoadingSpinner: boolean;

  onOpenLiveChat() {
    this.openLiveChat.emit();
  }

  onClose() {
    this.close.emit();
  }

  placeholderHeightStyle() {
    return this.placeHolderHeight ? this.placeHolderHeight : '';
  }
}
