import { compact, defaultTo, flatten } from 'lodash-es';
import moment from 'moment-timezone';

import { TableCellKinds, TableColumn, TableRow } from '@site-mate/dashpivot-shared-library';

export class DefaultTableContentPaste {
  private static readonly isMydPreferred = Intl.DateTimeFormat()
    // 2019-12-01 is just an arbitrary date that is can be easily distinguished in M/D/Y format
    .format(new Date(2019, 11, 1))
    .startsWith('12');

  private static readonly dateFormatPrecedence = ['YYYY-M-D'].concat(
    ...[
      ['D/M/YYYY', 'M/D/YYYY'],
      ['M/D/YYYY', 'D/M/YYYY'],
    ][Number(DefaultTableContentPaste.isMydPreferred)],
  );

  private static readonly digesters = {
    [TableCellKinds.Date]: {
      input: (input: string | undefined) => {
        const recognizedFormat = moment(input, DefaultTableContentPaste.dateFormatPrecedence);
        if (recognizedFormat.isValid()) {
          return { value: recognizedFormat.toISOString(false) };
        }
        return { value: undefined };
      },
    },
    [TableCellKinds.List]: {
      input: () => ({ value: '' }),
      output: (newValue: string | undefined, oldValue: string | undefined) => {
        const value = compact([newValue, oldValue])[0];
        return defaultTo(value, '');
      },
    },
    [TableCellKinds.Number]: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      input: (input: string, _column: TableColumn) => {
        let numericValue: number;
        const parsedInput = input?.replace(/[^0-9.]/g, '');

        if (['', undefined].includes(parsedInput)) {
          return { value: undefined };
        }

        numericValue = Number(parsedInput);

        if (input.endsWith('%')) {
          numericValue /= 100;
        }

        return { value: Number.isNaN(numericValue) ? undefined : numericValue };
      },
    },
    [TableCellKinds.Text]: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      input: (value: string, _column: TableColumn) => ({ value }),
      output: (
        newValue: string | undefined,
        oldValue: string | undefined,
        data: TableRow[],
        contentEditableElement: HTMLElement,
        window: Window,
      ) => {
        const newCellSelections = data.map((row) => row.columns.filter((column) => column.value));
        const numberOfCellSelections = flatten(newCellSelections).length;

        const isSingleCellSelection = numberOfCellSelections === 1;
        const isCurrentColumnValuesNotEmpty = newValue && oldValue;

        const singleSelectionAndNotEmpty = isSingleCellSelection && isCurrentColumnValuesNotEmpty;

        if (singleSelectionAndNotEmpty) {
          DefaultTableContentPaste.insertTextAtCursorPosition(newValue, window);
          return contentEditableElement.innerText;
        }

        return defaultTo(newValue, oldValue);
      },
    },
    [TableCellKinds.PrefilledText]: {
      input: (_value: string, column: TableColumn) => ({ content: column.content }),
    },
    [TableCellKinds.Time]: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      input: (input: string, _content: TableColumn) => {
        if (!input) {
          return {};
        }
        const targetFormat = 'HH:mm:ss';
        const recognizedFormat = moment(
          input,
          ['H:mm:ss', 'h:mm:ss', 'h:mm:ss a', 'h:mm a', 'h:mm:ssa', 'h:mma', 'H:mm', 'H:mm'],
          true,
        ).format(targetFormat);
        const time = recognizedFormat.split(':');
        if (time.length > 1) {
          const value = { hour: Number(time[0]), minute: Number(time[1]), second: 0 };
          return {
            _time: value,
            value: moment({ h: value.hour, m: value.minute, s: value.second }).toISOString(),
          };
        }
        return {};
      },
    },
    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      input: (_input: string, _content: TableColumn) => ({}),
      output: (
        newValue: string | undefined,
        oldValue: string | undefined,
        /* eslint-disable @typescript-eslint/no-unused-vars */
        _data?: TableRow[],
        _contentEditableElement?: HTMLElement,
        _window?: Window,
        /* eslint-enable @typescript-eslint/no-unused-vars */
      ) => defaultTo(newValue, oldValue),
    },
  };

  private static getCircularReplacer() {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return undefined;
        }
        seen.add(value);
      }
      return value;
    };
  }

  static insertTextAtCursorPosition(newText: string, window: Window): void {
    const selection = window.getSelection();
    const isUserSelectionActive = window.getSelection && selection.rangeCount;

    if (isUserSelectionActive) {
      const range = selection.getRangeAt(0);
      range.deleteContents();
      range.collapse(true);

      const span = document.createElement('span');
      span.id = 'pasted-content';
      span.appendChild(document.createTextNode(newText));
      range.insertNode(span);

      // Move the caret immediately after the inserted span
      range.setStartAfter(span);
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  static asSerializableColumn<T>(input: T) {
    return JSON.parse(JSON.stringify(input, DefaultTableContentPaste.getCircularReplacer()));
  }

  static getDigester(type: TableCellKinds) {
    const defaultDigester = DefaultTableContentPaste.digesters.default;
    const targetDigester = defaultTo<typeof defaultDigester>(this.digesters[type], defaultDigester);
    return {
      input: defaultTo(targetDigester.input, defaultDigester.input),
      output: defaultTo(targetDigester.output, defaultDigester.output),
    };
  }

  constructor(
    private readonly content: string[],
    private readonly columns: TableColumn[],
    private readonly columnOffset: number,
  ) {}

  parsedValue() {
    return {
      columns: this.columns.map((column, index) => {
        const value = this.content[index - this.columnOffset];
        const digester = DefaultTableContentPaste.getDigester(column.kind);
        return { headerColumnId: column.id, content: null, ...digester.input(value, column) };
      }),
    };
  }
}
