import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

import { LocalStorageService } from 'app/shared/service/local-storage.service';

@Component({
  selector: 'cc-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  @Input()
  type: 'info' | 'warning' | 'red-warning' | 'helper' = 'info';

  @Input()
  size: 'sm' | 'md' = 'md';

  @Input()
  alignment: 'start' | 'center' | 'end' = 'center';

  @Input()
  dismissible = false;

  @Input()
  disabled = false;

  @Input()
  showBadge = false;

  @Input()
  badgeText = '';

  @Input()
  badgePosition: 'left' | 'right' = 'left';

  @Input()
  bannerText = '';

  @Input() icon: string;

  @Input()
  width: 'full-width' | 'fit-content' = 'full-width';

  @Output()
  onDismissBanner = new EventEmitter();

  @Input()
  localStorageKey: string;

  @Input()
  customClasses = '';

  @Input()
  @HostBinding('class.sm-self-center')
  isSelfCentered = false;

  isVisible = true;

  private fullStorageKey: string;

  constructor(private readonly localStorageService: LocalStorageService) {}

  @HostBinding('class.sm-hidden')
  get isSmHidden() {
    return !this.isVisible;
  }

  ngOnInit(): void {
    if (this.localStorageKey) {
      this.fullStorageKey = `${this.localStorageKey}-visible`;
      const visible = this.localStorageService.getItem(this.fullStorageKey);

      this.isVisible = visible !== 'false';
    }
  }

  updateVisibilityByBannerLogicDirective(isTipOrNewBannerVisible: boolean) {
    this.isVisible = this.isVisible && isTipOrNewBannerVisible;
  }

  dismissBanner() {
    // Remove from dom any tooltip-popover that might be present when hovering over the banner.
    const popover = document.querySelector('.popover.tooltip-popover');
    if (popover) {
      popover.remove();
    }

    this.isVisible = false;

    // Set key before emit event to avoid null value in other components.
    if (this.fullStorageKey) {
      this.localStorageService.setItem(this.fullStorageKey, 'false');
    }

    this.onDismissBanner.emit();
  }
}
