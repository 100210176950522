import { Component, Input, OnInit } from '@angular/core';

import {
  FieldKinds,
  TableCellData,
  TableColumn,
  TableCellValidator,
  TableCellValidatorFactory,
} from '@site-mate/dashpivot-shared-library';

import { FormTableCellComponent } from 'app/form-fields/table/edit-cells/table-cell.component';

@Component({
  templateUrl: './formula.component.html',
  styleUrls: ['./formula.component.scss'],
  selector: 'cc-table-cell-item-formula',
})
export class FormFormulaCellComponent implements FormTableCellComponent, OnInit {
  @Input() cellData: TableCellData;
  @Input() headerColumn: TableColumn;
  @Input() tableKind: FieldKinds;
  validator: TableCellValidator;

  ngOnInit() {
    this.validator = TableCellValidatorFactory.getValidator(this.cellData, this.headerColumn);
  }

  get isPrefilledTableColumn() {
    return this.tableKind === FieldKinds.PrefilledTable;
  }

  get getFormulaHelperText() {
    return `Formula ${this.cellData.formula}`;
  }
}
