"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanTypeLimits = void 0;
const sitemate_global_shared_1 = require("@site-mate/sitemate-global-shared");
const products_enum_1 = require("../events/products.enum");
const dashpivot_modules_enum_1 = require("../shared/dashpivot-modules.enum");
const user_types_model_1 = require("../users/user-types.model");
const fieldLimits = {
    [sitemate_global_shared_1.CompanyPlanTypes.FreeTrial]: 1000,
    [sitemate_global_shared_1.CompanyPlanTypes.Standard]: 250,
    [sitemate_global_shared_1.CompanyPlanTypes.Pro]: 500,
    [sitemate_global_shared_1.CompanyPlanTypes.Premium]: 750,
    [sitemate_global_shared_1.CompanyPlanTypes.Platinum]: 1000,
    [sitemate_global_shared_1.CompanyPlanTypes.Enterprise]: 1000,
    [sitemate_global_shared_1.CompanyPlanTypes.Legacy]: 500,
};
const visitorLimits = {
    [sitemate_global_shared_1.CompanyPlanTypes.FreeTrial]: Infinity,
    [sitemate_global_shared_1.CompanyPlanTypes.Standard]: 0,
    [sitemate_global_shared_1.CompanyPlanTypes.Pro]: Infinity,
    [sitemate_global_shared_1.CompanyPlanTypes.Premium]: Infinity,
    [sitemate_global_shared_1.CompanyPlanTypes.Platinum]: Infinity,
    [sitemate_global_shared_1.CompanyPlanTypes.Enterprise]: Infinity,
    [sitemate_global_shared_1.CompanyPlanTypes.Legacy]: Infinity,
};
const sitemateUserLimits = {
    [sitemate_global_shared_1.CompanyPlanTypes.FreeTrial]: Infinity,
    [sitemate_global_shared_1.CompanyPlanTypes.Standard]: 0,
    [sitemate_global_shared_1.CompanyPlanTypes.Pro]: Infinity,
    [sitemate_global_shared_1.CompanyPlanTypes.Premium]: Infinity,
    [sitemate_global_shared_1.CompanyPlanTypes.Platinum]: Infinity,
    [sitemate_global_shared_1.CompanyPlanTypes.Enterprise]: Infinity,
    [sitemate_global_shared_1.CompanyPlanTypes.Legacy]: Infinity,
};
const listLimits = {
    [sitemate_global_shared_1.CompanyPlanTypes.FreeTrial]: 100,
    [sitemate_global_shared_1.CompanyPlanTypes.Standard]: 10,
    [sitemate_global_shared_1.CompanyPlanTypes.Pro]: 50,
    [sitemate_global_shared_1.CompanyPlanTypes.Premium]: 75,
    [sitemate_global_shared_1.CompanyPlanTypes.Platinum]: 100,
    [sitemate_global_shared_1.CompanyPlanTypes.Enterprise]: 100,
    [sitemate_global_shared_1.CompanyPlanTypes.Legacy]: 50,
};
const complementaryLogicLimits = {
    [sitemate_global_shared_1.CompanyPlanTypes.Pro]: 3,
    [sitemate_global_shared_1.CompanyPlanTypes.Legacy]: 3,
    [sitemate_global_shared_1.CompanyPlanTypes.Standard]: 3,
    [sitemate_global_shared_1.CompanyPlanTypes.Premium]: 6,
    [sitemate_global_shared_1.CompanyPlanTypes.Platinum]: 12,
    [sitemate_global_shared_1.CompanyPlanTypes.Enterprise]: 12,
    [sitemate_global_shared_1.CompanyPlanTypes.FreeTrial]: 12,
};
// TODO: flatten and improve typings for better reusability
exports.PlanTypeLimits = {
    [dashpivot_modules_enum_1.DashpivotModules.Templates]: {
        items: fieldLimits,
        complementaryLogic: complementaryLogicLimits,
    },
    [dashpivot_modules_enum_1.DashpivotModules.Users]: {
        [products_enum_1.Products.Dashpivot]: {
            [user_types_model_1.UserTypes.Visitor]: visitorLimits,
            sitemateUser: sitemateUserLimits,
        },
    },
    [dashpivot_modules_enum_1.DashpivotModules.Lists]: {
        properties: listLimits,
    },
};
