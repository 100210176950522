import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';

import { EventTypes } from '@site-mate/dashpivot-shared-library';

import { FormService } from 'app/form/form.service';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { ConfirmService, confirmExitOperations } from 'app/shared/service/confirm.service';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { EventsService } from 'app/shared/service/events/events.service';
import { SitemateImageUtils } from 'app/shared/service/sitemate-image-utils';
import { ToastrService } from 'app/shared/service/toastr.service';

@Component({
  selector: 'cc-signon-table-edit',
  templateUrl: './signon-table-edit.component.html',
  styleUrls: ['./signon-table-edit.component.scss', '../../../form/form-components/form-component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignonTableEditComponent implements OnInit {
  @Input() fieldData;
  @Input() canDeleteRows;

  model: any; // TODO: this should be signonTable model
  hiddenFieldLabelClass = '';
  tableHeaderValues = ['Name', 'Company', 'Signature', 'Date', 'Time', 'Map', 'Latitude', 'Longitude'];

  constructor(
    private readonly confirmService: ConfirmService,
    private readonly i18nService: TmpI18NService,
    private readonly formsService: FormService,
    private readonly errorHandler: ErrorHandler,
    private readonly eventsService: EventsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly sitemateImageUtils: SitemateImageUtils,
    protected readonly toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.model = this.fieldData.model;
    this.canDeleteRows = this.canDeleteRows !== undefined ? this.canDeleteRows : !this.model.readOnly;

    if (this.model.isHiddenInFormPDFExports) {
      this.hiddenFieldLabelClass = 'with-hidden-label';
    }
  }

  async onDeleteEntry(entry, index) {
    try {
      await this.confirmService.confirmDelete('deleteSignOnSignature', {
        confirmButtonText: this.i18nService.getMessage('confirmDelete'),
      });
      this.model.entries.splice(index, 1);
      this.changeDetectorRef.detectChanges();
      await this.formsService.deleteItemEntry(entry.form, entry.field, entry.id).toPromise();
      this.formsService.onFieldChanged.next();
      this.toastr.successByKey('formSaved');
      this.eventsService.trackEvent(EventTypes.ScanSitemateIDTableRowDeleted, { Context: 'Form Table Edit' });
    } catch (error) {
      const exitOperations = confirmExitOperations;
      if (!exitOperations.includes(error as string)) {
        this.errorHandler.handle(error);
      }
    }
  }

  getSignatureImage(url) {
    return this.sitemateImageUtils.getImageVariantUrl(url, 'small');
  }

  limitPointerEvents() {
    return false;
  }
}
