"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DATE_AND_TIME_FORMULAS = exports.DATE_AND_TIME_NOT_SUPPORTED_FORMULAS = exports.DATE_AND_TIME_SUPPORTED_FORMULAS = exports.DATE_AND_TIME_SUPPORTED_FORMULAS_WITH_INFO = void 0;
exports.DATE_AND_TIME_SUPPORTED_FORMULAS_WITH_INFO = new Map([
    [
        'DATE',
        {
            syntax: 'DATE(Year, Month, Day)',
            description: 'Returns the specified date as the number of full days since nullDate.',
        },
    ],
    [
        'DATEDIF',
        {
            syntax: 'DATEDIF(Date1, Date2)',
            description: 'Calculates distance between two dates.',
        },
    ],
    [
        'DATEVALUE',
        {
            syntax: 'DATEVALUE(Datestring)',
            description: 'Parses a date string and returns it as the number of full days since nullDate.',
        },
    ],
    [
        'DAY',
        {
            syntax: 'DAY(Number)',
            description: 'Returns the day of the given date value.',
        },
    ],
    [
        'DAYS',
        {
            syntax: 'DAYS(Date2, Date1)',
            description: 'Calculates the difference between two date values.',
        },
    ],
    [
        'DAYS360',
        {
            syntax: 'DAYS360(Date2, Date1[, Format])',
            description: 'Calculates the difference between two date values in days, in 360-day basis.',
        },
    ],
    [
        'EDATE',
        {
            syntax: 'EDATE(Startdate, Months)',
            description: 'Shifts the given startdate by given number of months and returns it as the number of full days since nullDate.',
        },
    ],
    [
        'EOMONTH',
        {
            syntax: 'EOMONTH(Startdate, Months)',
            description: 'Returns the date of the last day of a month which falls months away from the start date. Returns the value in the form of number of full days since nullDate.',
        },
    ],
    [
        'HOUR',
        {
            syntax: 'HOUR(Time)',
            description: 'Returns hour component of given time.',
        },
    ],
    [
        'INTERVAL',
        {
            syntax: 'INTERVAL(Seconds)',
            description: 'Returns interval string from given number of seconds.',
        },
    ],
    [
        'ISOWEEKNUM',
        {
            syntax: 'ISOWEEKNUM(Date)',
            description: 'Returns an ISO week number that corresponds to the week of year.',
        },
    ],
    [
        'MINUTE',
        {
            syntax: 'MINUTE(Time)',
            description: 'Returns minute component of given time.',
        },
    ],
    [
        'MONTH',
        {
            syntax: 'MONTH(Number)',
            description: 'Returns the month for the given date value.',
        },
    ],
    [
        'NETWORKDAYS',
        {
            syntax: 'NETWORKDAYS(Date1, Date2[, Holidays])',
            description: 'Returns the number of working days between two given dates.',
        },
    ],
    [
        'NETWORKDAYS.INTL',
        {
            syntax: 'NETWORKDAYS.INTL(Date1, Date2[, Mode [, Holidays]])',
            description: 'Returns the number of working days between two given dates.',
        },
    ],
    [
        'NOW',
        {
            syntax: 'NOW()',
            description: 'Returns current date + time as a number of days since nullDate.',
        },
    ],
    [
        'SECOND',
        {
            syntax: 'SECOND(Time)',
            description: 'Returns second component of given time.',
        },
    ],
    [
        'TIME',
        {
            syntax: 'TIME(Hour, Minute, Second)',
            description: 'Returns the number that represents a given time as a fraction of full day.',
        },
    ],
    [
        'TIMEDIF',
        {
            syntax: 'TIME(Time1, Time2)',
            description: 'Calculates distance between two times.',
        },
    ],
    [
        'TIMEVALUE',
        {
            syntax: 'TIMEVALUE(Timestring)',
            description: 'Parses a time string and returns a number that represents it as a fraction of full day.',
        },
    ],
    [
        'TODAY',
        {
            syntax: 'TODAY()',
            description: 'Returns an integer representing the current date as the number of full days since nullDate.',
        },
    ],
    [
        'WEEKDAY',
        {
            syntax: 'WEEKDAY(Date, Type)',
            description: 'Computes a number between 1-7 representing the day of week.',
        },
    ],
    [
        'WEEKNUM',
        {
            syntax: 'WEEKNUM(Date, Type)',
            description: 'Returns a week number that corresponds to the week of year.',
        },
    ],
    [
        'WORKDAY',
        {
            syntax: 'WORKDAY(Date, Shift[, Holidays])',
            description: 'Returns the working day number of days from start day.',
        },
    ],
    [
        'YEAR',
        {
            syntax: 'YEAR(Number)',
            description: 'Returns the year as a number according to the internal calculation rules.',
        },
    ],
    [
        'YEARFRAC',
        {
            syntax: 'YEARFRAC(Date2, Date1[, Format])',
            description: 'Computes the difference between two date values, in fraction of years.',
        },
    ],
]);
exports.DATE_AND_TIME_SUPPORTED_FORMULAS = [...exports.DATE_AND_TIME_SUPPORTED_FORMULAS_WITH_INFO.keys()];
exports.DATE_AND_TIME_NOT_SUPPORTED_FORMULAS = ['WORKDAY.INTL'];
exports.DATE_AND_TIME_FORMULAS = exports.DATE_AND_TIME_SUPPORTED_FORMULAS.concat(exports.DATE_AND_TIME_NOT_SUPPORTED_FORMULAS);
