import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import Bugsnag from '@bugsnag/js';
import { noop, upperFirst } from 'lodash-es';
import { SegmentService as NGXSegmentService } from 'ngx-segment-analytics';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { v4 as UUID } from 'uuid';

import { DashpivotEvent, Event, EventTypes, IEventNotifier, User } from '@site-mate/dashpivot-shared-library';

import { IntercomService } from 'app/intercom/intercom.service';
import { SEGMENT_CONFIG } from 'app/segment/segment-config.factory';
import { AppHierarchy } from 'app/shared/model/app-hierarchy.enum';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { TeamService } from 'app/shared/service/team.service';
import { UserService } from 'app/user/user.service';

import { EventDebugMessages } from './segment-debug-messages.enum';

@Injectable({ providedIn: 'root' })
export class SegmentService implements IEventNotifier {
  private userTraits: any = {};
  private navigationContext: any = {};
  private identifiedUser = new BehaviorSubject<User>(null);
  private shouldTrack = true;
  private readonly isSegmentReady = new BehaviorSubject<boolean>(false);
  readonly isSegmentReady$ = this.isSegmentReady.asObservable();

  constructor(
    @Inject(SEGMENT_CONFIG) private readonly configuration,
    private readonly ngxSegment: NGXSegmentService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly teamService: TeamService,
    private readonly intercomService: IntercomService,
    private readonly errorHandlerService: ErrorHandler,
  ) {
    void this.setIntercomListener();
    this.setupVitally();
    this.setUserListener();
    this.setPageListener();
    this.setNavigationContextListener();
  }

  public isSegmentReadyStatus() {
    return this.isSegmentReady.getValue();
  }

  public async send(
    event: Event,
    overrideProperties?: Event['metadata'],
    uniqueEventId = UUID(),
  ): Promise<void> {
    if (!this.shouldTrack) {
      return noop();
    }

    try {
      const {
        eventType,
        metadata: { ...otherData },
      } = event;

      await this.ngxSegment.track(eventType, {
        uniqueEventId,
        ...this.userTraits,
        ...this.navigationContext,
        ...otherData,
        ...overrideProperties,
      });
      // eslint-disable-next-line @typescript-eslint/return-await
      return Promise.resolve();
    } catch (error) {
      this.debugSegmentEvent(EventDebugMessages.Error, event, uniqueEventId, error);

      return noop();
    }
  }

  async alias(id: string, previousId: string) {
    await this.ngxSegment.alias(id, previousId);
  }

  private debugSegmentEvent(stage: EventDebugMessages, event: Event, uniqueEventId: string, error: any) {
    this.errorHandlerService.handleForDebug(stage, {
      event,
      error,
      uniqueEventId,
    });
  }

  public stopTracking() {
    this.shouldTrack = false;
  }

  private async setIntercomListener() {
    await this.ngxSegment.ready();
    this.isSegmentReady.next(true);
    this.intercomService.onShow(() => {
      void this.send(new DashpivotEvent(EventTypes.IntercomOpened));
    });
  }

  private setNavigationContextListener() {
    this.teamService.getCurrentState().subscribe((state) => {
      this.navigationContext = {};

      if (state.company) {
        this.navigationContext.companyId = state.company._id;
        this.navigationContext.companyName = state.company.name;
        this.navigationContext.Hierarchy = upperFirst(AppHierarchy.Organisation);
      }

      if (state.project) {
        this.navigationContext.projectId = state.project._id;
        this.navigationContext.projectName = state.project.name;
        this.navigationContext.Hierarchy = upperFirst(AppHierarchy.Project);
      }

      if (state.team) {
        this.navigationContext.teamId = state.team._id;
        this.navigationContext.teamName = state.team.name;
        this.navigationContext.Hierarchy = upperFirst(AppHierarchy.Team);
      }
    });
  }

  private setUserListener() {
    this.userService.currentUser.subscribe((user) => {
      if (!user || !user.id) {
        this.ngxSegment.reset();
        return;
      }

      this.userTraits.Email = user.email;
      this.userTraits.FullName = user.fullName;
      void this.ngxSegment.identify(
        user.id,
        {},
        { integrations: { Intercom: { user_hash: user.hashedIds.web } } },
      );
      void this.ngxSegment.group(user.standardUserOf.companies[0]);
      this.identifiedUser.next(user);
    });
  }

  private setUserIdentifiedListener() {
    this.identifiedUser.pipe(filter((user) => !!user)).subscribe(() => {
      if (this.configuration.vitally.nps) {
        // eslint-disable-next-line no-undef
        Vitally.nps('survey', {
          productName: this.configuration.vitally.productName,
          autoLoadSegment: true,
        });
      }
    });
  }

  private setPageListener() {
    this.router.events
      .pipe(filter(() => this.configuration.trackPages))
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => this.ngxSegment.page());
  }

  /**
   * This initializes vitally js client.
   *
   * NOTE: Keeping this inside SegmentService, because it is dependent on segment,
   * and there is a great chance of Vitally being served through Segment in the future
   *
   */
  private setupVitally() {
    // NOTE: Vitally library is currently breaking in IE11 and sometimes other browsers
    // due to usage of ES6. This try-catch block is needed for us to keep track of the number
    // of these issues occurring
    try {
      // eslint-disable-next-line no-undef
      Vitally.init(this.configuration.vitally.key);
      this.setUserIdentifiedListener();
    } catch (error) {
      // @ts-expect-error implicitly has an 'any' type.
      Bugsnag.notify(error);
    }
  }
}
