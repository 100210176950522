<cc-account-upgrade-limit-ui
  [imageSrc]="imageSrc"
  [imageAlt]="imageAlt"
  [title]="title"
  [subtitle]="subtitle"
  [hasInnerHtmlSubtitle]="hasInnerHtmlSubtitle"
  [showLoadingSpinner]="showLoadingSpinner"
  [placeHolderHeight]="placeHolderHeight"
  (close)="activeModal.dismiss()"
  (openLiveChat)="openLiveChat()"
></cc-account-upgrade-limit-ui>
