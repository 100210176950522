"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserPermissionTypes = void 0;
var UserPermissionTypes;
(function (UserPermissionTypes) {
    UserPermissionTypes["TeamMember"] = "team-member";
    UserPermissionTypes["TeamController"] = "team-controller";
    UserPermissionTypes["ProjectMember"] = "project-member";
    UserPermissionTypes["ProjectController"] = "project-controller";
    UserPermissionTypes["CompanyMember"] = "company-member";
    UserPermissionTypes["CompanyController"] = "company-controller";
    UserPermissionTypes["GlobalAdmin"] = "global-admin";
})(UserPermissionTypes = exports.UserPermissionTypes || (exports.UserPermissionTypes = {}));
