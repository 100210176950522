import { Component, Input } from '@angular/core';

import { SketchUtils } from '../sketch-utils';

@Component({
  selector: 'cc-sketch-pad-image',
  templateUrl: 'sketch-pad-image.component.html',
  styleUrls: ['sketch-pad-image.component.scss'],
})
export class SketchPadImageComponent {
  @Input()
  model;

  constructor() {}

  getBackgroundUrl() {
    return SketchUtils.getSketchpadBackground(this.model);
  }
}
