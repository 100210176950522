"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PathService = void 0;
class PathService {
    /**
     * Extracts the path pieces using the provided input list.
     * @param path - the path to examine
     * @param definitions - the objects to extract info; all found keys will be read, regardless of the associated value
     * @returns - the result object containing the values as array
     */
    extractResourceIds(path, readKeys) {
        const extractedValues = {};
        const localPieces = Object.keys(readKeys);
        const localPath = (path !== null && path !== void 0 ? path : '').trim();
        localPieces.forEach((piece) => {
            var _a;
            const matcher = new RegExp(`${String(piece)}:([^/]{1,})`, 'gm');
            extractedValues[piece] = (_a = extractedValues[piece]) !== null && _a !== void 0 ? _a : [];
            let occurrence;
            // eslint-disable-next-line no-cond-assign -- cleaner syntax, no branching/while(true)
            while ((occurrence = matcher.exec(localPath))) {
                const [, value] = occurrence;
                extractedValues[piece] = extractedValues[piece].concat(value);
            }
        });
        return extractedValues;
    }
    /**
     * Extracts the folder IDs from a given path.
     * @param path - a string like `/folder:path1/folder:path2`
     * @returns an array of path IDs found
     */
    extractFolderIds(path) {
        const extractedPathPieces = this.extractResourceIds(path, { folder: true });
        return extractedPathPieces.folder;
    }
}
exports.PathService = PathService;
