import { Component, ElementRef, Input, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'cc-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  private readonly updateInterval = 50;
  // eslint-disable-next-line no-undef -- NodeJS.Timeout is a Node.js type
  private intervalId: NodeJS.Timeout | null = null;
  private targetPercentageProgress = 0;
  protected displayedPercentageProgress = 0;

  @ViewChild('percentageTextTop') percentageTextTop: ElementRef<HTMLParagraphElement>;
  @ViewChild('progressBar') progressBar: ElementRef<HTMLDivElement>;

  @Input() updateSpeed = 50;

  @Input() set percentageProgress(progress: number) {
    this.targetPercentageProgress = Math.min(Math.round(progress), 100);
    this.animateProgress();
  }

  private readonly barCompletedSubject = new Subject<void>();
  @Output() barCompleted$ = this.barCompletedSubject.asObservable();

  private animateProgress() {
    if (!this.progressBar || !this.percentageTextTop) {
      return;
    }

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    const updateStep = Math.round(this.updateSpeed * (this.updateInterval / 1000));

    this.intervalId = setInterval(() => {
      const progress = Math.min(this.displayedPercentageProgress + updateStep, this.targetPercentageProgress);
      this.displayedPercentageProgress = progress;
      this.percentageTextTop.nativeElement.style.clipPath = `rect(0 ${progress}% 100% 0)`;
      if (progress === this.targetPercentageProgress) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
      if (progress === 100) {
        this.barCompletedSubject.next();
      }
    }, this.updateInterval);
  }
}
