import { Inject, Injectable } from '@angular/core';

import { WINDOW } from 'app/shared/factory/window.factory';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  private localCache: { [key: string]: string } = {};

  constructor(@Inject(WINDOW) private readonly window: Window) {}

  get localStorage() {
    return this.window.localStorage;
  }

  getItem(key: string) {
    try {
      return this.window.localStorage.getItem(key);
    } catch {
      return this.localCache[key];
    }
  }

  setItem(key: string, value: string) {
    try {
      this.window.localStorage.setItem(key, value);
      return this;
    } catch {
      this.localCache[key] = value;
      return this;
    }
  }

  removeItem(key: string) {
    try {
      this.window.localStorage.removeItem(key);
      return this;
    } catch {
      delete this.localCache[key];
      return this;
    }
  }

  clear() {
    try {
      this.window.localStorage.clear();
      return this;
    } catch {
      this.localCache = {};
      return this;
    }
  }
}
