import { Injectable } from '@angular/core';
import { Subject, debounceTime, ReplaySubject } from 'rxjs';

import { IUserWithSummaryOrWithoutData } from '@site-mate/dashpivot-shared-library';

import { GlobalApiService } from 'app/global-api/global-api.service';

@Injectable({
  providedIn: 'root',
})
export class SitemateUsersService {
  private userDataCache = new Map<string, ReplaySubject<IUserWithSummaryOrWithoutData>>();
  private checkTrigger = new Subject<void>();
  private accumulatedUserIds = [];

  constructor(private globalApiService: GlobalApiService) {
    this.checkTrigger.pipe(debounceTime(60)).subscribe(() => {
      this.getUserDataSubjectsInBatches();
    });
  }

  getUserDataSubjects(userIds: string[]): Map<string, Subject<IUserWithSummaryOrWithoutData>> {
    const resultSubjects = new Map<string, Subject<IUserWithSummaryOrWithoutData>>();

    const filteredUserIds = userIds.filter((userId) => !this.userDataCache.has(userId));
    this.accumulateUserIds(filteredUserIds);

    userIds.forEach((userId) => {
      resultSubjects.set(userId, this.getOrCreateSubjectInCache(userId));
    });

    this.checkTrigger.next();

    return resultSubjects;
  }

  clearUserDataCache() {
    this.userDataCache.clear();
  }

  private getOrCreateSubjectInCache(userId: string): Subject<IUserWithSummaryOrWithoutData> {
    let userSubject = this.userDataCache.get(userId);
    if (!userSubject) {
      userSubject = new ReplaySubject<IUserWithSummaryOrWithoutData>(1);
      this.userDataCache.set(userId, userSubject);
    }
    return userSubject;
  }

  private getUserDataSubjectsInBatches() {
    const MAX_BATCH_SIZE = 50;
    const batchedIds: string[][] = [];

    while (this.accumulatedUserIds.length > 0) {
      batchedIds.push(this.accumulatedUserIds.splice(0, MAX_BATCH_SIZE));
    }

    batchedIds.forEach((batch) => {
      this.populateUserDataMap(batch);
    });
  }

  private populateUserDataMap(batch: string[]) {
    this.globalApiService.getUserInfo(batch).subscribe((usersData) => {
      usersData.forEach((user) => {
        // Cache the result once we receive the response
        this.userDataCache.get(user._id).next(user);
      });
    });
  }

  private accumulateUserIds(userIds: string[]): void {
    this.accumulatedUserIds = Array.from(new Set([...this.accumulatedUserIds, ...userIds]));
  }
}
