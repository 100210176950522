"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ENGINEERING_FORMULAS = exports.ENGINEERING_NOT_SUPPORTED_FORMULAS = exports.ENGINEERING_SUPPORTED_FORMULAS = exports.ENGINEERING_SUPPORTED_FORMULAS_WITH_INFO = void 0;
exports.ENGINEERING_SUPPORTED_FORMULAS_WITH_INFO = new Map([
    [
        'DELTA',
        {
            syntax: 'DELTA(Number_1, Number_2)',
            description: 'Returns TRUE (1) if both numbers are equal, otherwise returns FALSE (0).',
        },
    ],
]);
exports.ENGINEERING_SUPPORTED_FORMULAS = [...exports.ENGINEERING_SUPPORTED_FORMULAS_WITH_INFO.keys()];
exports.ENGINEERING_NOT_SUPPORTED_FORMULAS = [
    'BIN2DEC',
    'BIN2HEX',
    'BIN2OCT',
    'BITAND',
    'BITLSHIFT',
    'BITOR',
    'BITRSHIFT',
    'BITXOR',
    'COMPLEX',
    'DEC2BIN',
    'DEC2HEX',
    'DEC2OCT',
    'ERF',
    'ERFC',
    'HEX2BIN',
    'HEX2DEC',
    'HEX2OCT',
    'IMABS',
    'IMAGINARY',
    'IMARGUMENT',
    'IMCONJUGATE',
    'IMCOS',
    'IMCOSH',
    'IMCOT',
    'IMCSC',
    'IMCSCH',
    'IMDIV',
    'IMEXP',
    'IMLN',
    'IMLOG2',
    'IMLOG10',
    'IMPOWER',
    'IMPRODUCT',
    'IMREAL',
    'IMSEC',
    'IMSECH',
    'IMSIN',
    'IMSINH',
    'IMSQRT',
    'IMSUB',
    'IMSUM',
    'IMTAN',
    'OCT2BIN',
    'OCT2DEC',
    'OCT2HEX',
];
exports.ENGINEERING_FORMULAS = exports.ENGINEERING_SUPPORTED_FORMULAS.concat(exports.ENGINEERING_NOT_SUPPORTED_FORMULAS);
