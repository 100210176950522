import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

type FieldActionType = 'clone' | 'delete' | 'addLogic';

interface IFieldActionConfig {
  class: string;
  icon: string;
  label: string;
}

@Component({
  selector: 'cc-field-action',
  templateUrl: './field-action.component.html',
  styleUrls: ['./field-action.component.scss'],
})
export class FieldActionComponent implements OnInit {
  @Output() onClick = new EventEmitter();
  @Input() disabled: boolean;
  @Input() type: FieldActionType = 'clone';

  fieldConfig: Record<FieldActionType, IFieldActionConfig> = {
    clone: { class: 'clone-control', icon: 'cc-icon-plus sm-text-default-green', label: 'Clone' },
    delete: { class: 'remove-control', icon: 'cc-icon-close sm-text-default-red', label: 'Delete' },
    addLogic: { class: 'logic-control', icon: 'cc-icon-logic', label: 'Add logic' },
  };

  @HostBinding('class') class;

  ngOnInit() {
    this.class = `${this.fieldConfig[this.type].class}`;
  }

  emitAction() {
    if (!this.disabled) {
      this.onClick.emit();
    }
  }
}
