import { Injectable } from '@angular/core';
import { Params, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { EventTypes, ExportOutputFormats } from '@site-mate/dashpivot-shared-library';

import { PercentageBasedDownloaderData } from 'app/shared/model/downloader/percentage-based-downloader-data.model';
import { FormWeb } from 'app/shared/model/form.model';
import { DataCompressionService } from 'app/shared/service/data-compression/data-compression.service';
import { HttpClientService } from 'app/shared/service/http-client.service';
import { LocalStorageService } from 'app/shared/service/local-storage.service';
import { UuidService } from 'app/shared/service/uuid/uuid.service';

@Injectable()
export class DownloaderService {
  downloaderType = 'Downloader';

  constructor(
    private readonly router: Router,
    private readonly http: HttpClientService,
    private readonly uuidService: UuidService,
    private readonly dataCompressionService: DataCompressionService,
    private readonly localStorageService: LocalStorageService,
  ) {}

  getDownloadSingleFormUrl(form: FormWeb): UrlTree {
    return this.router.createUrlTree(['downloader', 'apps', form.parents[0], 'form', form.id, 'download'], {
      queryParams: { id: JSON.stringify([form.id]) },
      queryParamsHandling: 'merge',
    });
  }

  getRegisterOnlyUrl({ templateId, hierarchy, queryParams }): UrlTree {
    return this.router.createUrlTree(['downloader', 'apps', templateId, hierarchy, 'register-only'], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  getDownloadFormsUrl({ templateId, hierarchy, queryParams }): UrlTree {
    return this.router.createUrlTree(['downloader', 'apps', templateId, hierarchy, 'download'], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  getDownloadPhotosUrl({ teamId, queryParams }): UrlTree {
    return this.router.createUrlTree(['downloader', 'teams', teamId, 'photos', 'download'], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  getDownloadSinglePhotosUrl({ teamId, queryParams }): UrlTree {
    return this.router.createUrlTree(['downloader', 'teams', teamId, 'photos', 'download', 'single'], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  getDownloadZipPhotosUrl({ teamId, queryParams }): UrlTree {
    return this.router.createUrlTree(['downloader', 'teams', teamId, 'photos', 'download', 'zip'], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  getDownloadOriginalPhotosUrl({ teamId, queryParams }): UrlTree {
    return this.router.createUrlTree(['downloader', 'teams', teamId, 'photos', 'download', 'original'], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  getExportFormsUrl({
    templateId,
    hierarchy,
    queryParams,
  }: {
    templateId: string;
    hierarchy: string;
    queryParams: Record<string, unknown>;
  }): UrlTree {
    return this.router.createUrlTree(['downloader', 'apps', templateId, hierarchy, 'export'], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  getQrCodePosterUrl({ teamId, taskUrl }: { teamId: string; taskUrl: string }): UrlTree {
    return this.router.createUrlTree(['downloader', 'teams', teamId, 'qr-code-poster', 'download'], {
      queryParams: { taskUrl },
      queryParamsHandling: 'merge',
    });
  }

  getExportCSVUrl({
    templateId,
    hierarchy,
    queryParams,
  }: {
    templateId: string;
    hierarchy: string;
    queryParams: Record<string, unknown>;
  }): UrlTree {
    return this.router.createUrlTree(['downloader', 'apps', templateId, hierarchy, 'export-csv'], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  getExportListCSVUrl({
    listId,
    queryParams,
  }: {
    listId: string;
    queryParams: Record<string, string | string[]>;
  }): UrlTree {
    return this.router.createUrlTree(['downloader', 'lists', listId, 'export-csv'], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  getExportTemplatesCSVUrl({ queryParams }: { queryParams: Record<string, string | string[]> }): UrlTree {
    return this.router.createUrlTree(['downloader', 'apps', queryParams.hierarchy, 'csv'], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  getDownloadRegisterOnlyFormTimingMarkers(hierarchy: string, templateId: string) {
    return {
      label: EventTypes.FormsRegisterActionApplied,
      params: {
        Context: 'Download PDF - Register Only',
        Hierarchy: hierarchy,
        templateId,
      },
    };
  }

  getDownloadFormsAndRegistersTimingMarkers(hierarchy: string, templateId: string) {
    return {
      label: EventTypes.FormsRegisterActionApplied,
      params: {
        Context: 'Download PDF - Register And Forms',
        Hierarchy: hierarchy,
        templateId,
      },
    };
  }

  getDownloadFormsOnlyTimingMarkers(hierarchy: string, templateId: string, originalImages: boolean) {
    const context = originalImages
      ? 'Download PDF - Forms Only With Original Photo Files'
      : 'Download PDF - Forms Only';

    return {
      label: EventTypes.FormsRegisterActionApplied,
      params: {
        Context: context,
        Hierarchy: hierarchy,
        templateId,
      },
    };
  }

  getDownloadSingleFormTimingMarkers(formId: string) {
    return {
      label: EventTypes.FormActionApplied,
      params: {
        formId,
        Context: 'Download As PDF',
      },
    };
  }

  getExportSession(sessionId: string): Observable<PercentageBasedDownloaderData> {
    return this.http.get(`exports/${sessionId}`);
  }

  getExportSessionCountByUser(params: {
    type: ExportOutputFormats;
    periodInDays: number;
  }): Observable<number> {
    return this.http.get('exports/count', params);
  }

  getRandomDownloadId() {
    return this.uuidService.getUuid();
  }

  processDownloaderFormsParams(params: Params) {
    const downloadParams = { ...params };

    if (params.downloadId) {
      downloadParams.id = this.getDecompressedLocalStorageItemIds(params.downloadId);
    }

    return downloadParams;
  }

  processDownloaderPhotosParams(params): string {
    let photoIds: string;

    if (params.downloadId) {
      photoIds = this.getDecompressedLocalStorageItemIds(params.downloadId);
    } else {
      photoIds = params.ids;
    }

    return photoIds;
  }

  processDownloaderItemsParams(params: Params) {
    const downloadParams = { ...params };

    if (params.downloadId) {
      downloadParams.ids = this.getDecompressedLocalStorageItemIds(params.downloadId);
    }

    return downloadParams;
  }

  getDecompressedLocalStorageItemIds(key) {
    return this.dataCompressionService.decompress(JSON.parse(this.localStorageService.getItem(key)).ids);
  }

  cleanDownloaderLocalStorage() {
    Object.keys(this.localStorageService.localStorage)
      .filter(this.filterStorageByDownloaderType.bind(this))
      .filter(this.filterStorageOlderThanFourHours.bind(this))
      .forEach((key) => this.localStorageService.removeItem(key));
  }

  private filterStorageByDownloaderType(key: string): boolean {
    let storageType: string;
    try {
      storageType = JSON.parse(this.localStorageService.getItem(key))?.type;
      return storageType === this.downloaderType;
    } catch (error) {
      return false;
    }
  }

  private filterStorageOlderThanFourHours(key: string): boolean {
    const fourHours = 4 * 60 * 60 * 1000;
    return new Date().getTime() - fourHours > JSON.parse(this.localStorageService.getItem(key))?.timestamp;
  }
}
