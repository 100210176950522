"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormViews = void 0;
var FormViews;
(function (FormViews) {
    FormViews["Workflow"] = "workflow";
    FormViews["Timeline"] = "timeline";
    FormViews["Register"] = "register";
    FormViews["Analytics"] = "analytics";
})(FormViews = exports.FormViews || (exports.FormViews = {}));
