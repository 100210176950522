"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrefilledTableFieldCloner = void 0;
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
const table_cell_repository_factory_1 = require("./cloning-table-cells/table-cell-repository.factory");
const generic_field_1 = require("./generic-field");
class PrefilledTableFieldCloner extends generic_field_1.GenericFieldCloner {
    constructor() {
        super(...arguments);
        this.tableCellCloner = new table_cell_repository_factory_1.TableCellClonerFactory(this.getListItems);
    }
    mergeField(templateField, formField) {
        return __awaiter(this, void 0, void 0, function* () {
            // prefilled tables - loop each row & col looking for any matching cells
            for (const [templateRowIndex, templateRow] of templateField.rows.entries()) {
                // find matching row in the form to clone
                const formRow = formField.rows.find((fRow) => fRow.id === templateRow.id);
                if (formRow) {
                    for (const [templateColIndex, templateCell] of templateRow.columns.entries()) {
                        const mergedCell = yield this.mergeCell(templateField, templateCell, templateColIndex, formField, formRow);
                        if (mergedCell) {
                            templateField.rows[templateRowIndex].columns[templateColIndex] = mergedCell;
                        }
                    }
                }
            }
            return templateField;
        });
    }
    mergeCell(templateField, templateCell, templateColIndex, formField, formRow) {
        return __awaiter(this, void 0, void 0, function* () {
            const formCell = formRow.columns.find((fCell) => String(fCell.id) === String(templateCell.id));
            if (formCell && templateCell.kind && formCell.kind) {
                const formCol = formField.columns[templateColIndex]; // this isn't really used, but it's here for consistency
                const templateCol = templateField.columns[templateColIndex];
                const tableCellMerger = this.tableCellCloner.getCloner(templateCell.kind);
                return tableCellMerger.merge(templateField, templateCol, templateCell, formCol, formCell);
            }
            return null;
        });
    }
}
exports.PrefilledTableFieldCloner = PrefilledTableFieldCloner;
