"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LegacyColumnWidthUnits = exports.TemplateOrientationMaxColumnWidths = exports.TableColumnWidthUnits = void 0;
const table_column_widths_enum_1 = require("./table-column-widths.enum");
const template_orientations_enum_1 = require("../templates/template-orientations.enum");
const TableColumnWidthUnits = {
    [table_column_widths_enum_1.TableColumnWidths.Small]: 1,
    [table_column_widths_enum_1.TableColumnWidths.Medium]: 2,
    [table_column_widths_enum_1.TableColumnWidths.Legacy]: -1,
    [table_column_widths_enum_1.TableColumnWidths.Large]: 3,
    [table_column_widths_enum_1.TableColumnWidths.ExtraLarge]: 4,
};
exports.TableColumnWidthUnits = TableColumnWidthUnits;
const LegacyColumnWidthUnits = {
    [template_orientations_enum_1.TemplateOrientations.Portrait]: 2,
    [template_orientations_enum_1.TemplateOrientations.Landscape]: 1.875,
};
exports.LegacyColumnWidthUnits = LegacyColumnWidthUnits;
const TemplateOrientationMaxColumnWidths = {
    [template_orientations_enum_1.TemplateOrientations.Portrait]: 10,
    [template_orientations_enum_1.TemplateOrientations.Landscape]: 15,
};
exports.TemplateOrientationMaxColumnWidths = TemplateOrientationMaxColumnWidths;
