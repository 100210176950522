"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationDescriptions = void 0;
const notification_periods_enum_1 = require("./notification-periods.enum");
exports.NotificationDescriptions = {
    [notification_periods_enum_1.NotificationPeriods.ThirtyDaysBefore]: '30 days before',
    [notification_periods_enum_1.NotificationPeriods.SevenDaysBefore]: '7 days before',
    [notification_periods_enum_1.NotificationPeriods.OneDayBefore]: '1 day before',
    [notification_periods_enum_1.NotificationPeriods.Overdue]: 'Overdue',
    [notification_periods_enum_1.NotificationPeriods.OverdueSevenDays]: 'Overdue 7 days',
    [notification_periods_enum_1.NotificationPeriods.OverdueThirtyDays]: 'Overdue 30 days',
};
