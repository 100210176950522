import { Component, Input } from '@angular/core';

@Component({
  selector: 'cc-user-redirection-modal',
  templateUrl: './user-redirection-modal.component.html',
  styleUrls: ['./user-redirection-modal.component.scss'],
})
export class UserRedirectionModalComponent {
  @Input()
  message: string;
}
