"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventTypes = void 0;
var EventTypes;
(function (EventTypes) {
    // Account
    EventTypes["AccountDeleteClicked"] = "Delete Account Clicked";
    EventTypes["AccountDeleteRequestSubmitted"] = "Delete Account Request Submitted";
    // Activity Feed
    EventTypes["ActivityFeedLoaded"] = "Activity Feed Loaded";
    EventTypes["ActivityLinkClicked"] = "Activity Link Clicked";
    // Billing
    EventTypes["BillingCycleEdited"] = "Billing Cycle Edited";
    EventTypes["CurrencyEdited"] = "Currency Edited";
    EventTypes["GroupBillingEdited"] = "Group Billing Edited";
    EventTypes["InitialSubscriptionDateEdited"] = "Initial Subscription Date Edited";
    EventTypes["PaymentTermsDayEdited"] = "Payment Terms Day Edited";
    EventTypes["PaymentTermsContextEdited"] = "Payment Terms Context Edited";
    EventTypes["PaymentMethodEdited"] = "Payment Method Edited";
    EventTypes["SubscriptionStatusEdited"] = "Subscription Status Edited";
    EventTypes["SubscriptionTermEdited"] = "Subscription Term Edited";
    EventTypes["LegacyPlanRestrictionsEdited"] = "Legacy Plan Restrictions Edited";
    // Billing Licenses
    EventTypes["DashpivotContributorsPaidEdited"] = "Dashpivot Contributors Paid Edited";
    EventTypes["DashpivotContributorUsersUsedEdited"] = "Dashpivot Contributor Users Used Edited";
    EventTypes["DashpivotVisitorUsersUsedEdited"] = "Dashpivot Visitor Users Used Edited";
    EventTypes["SitematePaidUsersUsedEdited"] = "Sitemate Paid Users Used Edited";
    EventTypes["SitematePaidUsersPaidEdited"] = "Sitemate Paid Users Paid Edited";
    // Charts
    EventTypes["ChartCreated"] = "Chart Created";
    EventTypes["ChartFilterApplied"] = "Chart Filter Applied";
    EventTypes["ChartModalViewed"] = "Chart Modal Viewed";
    EventTypes["ChartRemoved"] = "Chart Removed";
    EventTypes["ChartUpdated"] = "Chart Updated";
    // Companies
    EventTypes["CompanyBillingUpdated"] = "Company Billing Updated";
    EventTypes["PlanTypeEdited"] = "Plan Type Edited";
    EventTypes["JointVenturePropertyEdited"] = "Joint Venture Property Edited";
    EventTypes["DomainPropertyEdited"] = "Domain Property Edited";
    // Workspaces
    EventTypes["WorkspaceCreated"] = "Workspace Created";
    EventTypes["WorkspaceEdited"] = "Workspace Edited";
    EventTypes["WorkspaceDeleted"] = "Workspace Deleted";
    // Dashboard
    EventTypes["DashboardCreated"] = "Dashboard Created";
    EventTypes["DashboardDeleted"] = "Dashboard Deleted";
    EventTypes["DashboardUpdated"] = "Dashboard Updated";
    // Folders
    EventTypes["FolderArchived"] = "Folder Archived";
    EventTypes["FolderCreated"] = "Folder Created";
    EventTypes["FolderRemoved"] = "Folder Removed";
    EventTypes["FolderRestored"] = "Folder Restored";
    EventTypes["FolderSelected"] = "Folder Selected";
    EventTypes["FolderUpdated"] = "Folder Updated";
    // Forms
    EventTypes["FormActionApplied"] = "Form Action Applied";
    EventTypes["FormAttachmentAdded"] = "Attachment Added to Form";
    EventTypes["FormAttachmentFailed"] = "Form Attachment Failed";
    EventTypes["FormCategoryAppliedInForm"] = "Category Applied In Form";
    EventTypes["FormCategoryValueApplied"] = "Category Applied In Form";
    EventTypes["FormCheckboxValueApplied"] = "Checkbox Value Applied to Form";
    EventTypes["FormCloned"] = "Form Cloned";
    EventTypes["FormCreated"] = "Form Created";
    EventTypes["FormDraftSaved"] = "Form Draft Saved";
    EventTypes["FormDateApplied"] = "Date Applied to Form";
    EventTypes["FormEdited"] = "Form Edited";
    EventTypes["FormulaCalculationTriggered"] = "Formula Calculation Triggered";
    EventTypes["FormListViewed"] = "Form List Viewed";
    EventTypes["FormLoaded"] = "Form Loaded";
    EventTypes["FormMultiInputTextTyped"] = "Form Multi Input Text Typed";
    EventTypes["FormPermissionsChanged"] = "Form Permissions Changed";
    EventTypes["FormPersonAssigned"] = "Person Assigned In Form";
    EventTypes["FormPhotoAdded"] = "Photo Added To Form";
    EventTypes["FormRegisterActionApplied"] = "Form Register Action Applied";
    EventTypes["FormRemoved"] = "Form Removed";
    EventTypes["FormSentAsPdf"] = "Form Sent as PDF";
    EventTypes["FormSigned"] = "Form Signed";
    EventTypes["FormSignatureRemoved"] = "Form Signature Removed";
    EventTypes["FormSingleInputTextTyped"] = "Form Single Input Text Typed";
    EventTypes["FormSitemateOngoingCreated"] = "Ongoing Sitemate Form Submitted";
    EventTypes["FormSitemateSingleCreated"] = "Single Sitemate Form Submitted";
    EventTypes["FormsDownloadedAsCsv"] = "Forms Downloaded As CSV";
    EventTypes["FormsDownloadedAsPdf"] = "Forms Downloaded As PDF";
    EventTypes["FormsLoaded"] = "Forms Loaded";
    EventTypes["FormsRegisterActionApplied"] = "Forms Register Action Applied";
    EventTypes["FormsSentAsPdf"] = "Forms Sent As PDF";
    EventTypes["FormsUserRemoved"] = "Forms User Removed";
    EventTypes["FormSketchAdded"] = "Sketch Added to Form";
    EventTypes["FormTableCellDataEntered"] = "Table Cell Data Entered";
    EventTypes["FormTableKeyboardShortcutUsed"] = "Table Keyboard Shortcut Used";
    EventTypes["FormTableDefaultAllRowsCleared"] = "Default Table Clear All Rows Clicked";
    EventTypes["FormTableDefaultAllRowsDeleted"] = "Default Table Delete All Rows Clicked";
    EventTypes["FormTableDefaultRowCloned"] = "Default Table Clone Row Clicked";
    EventTypes["FormTableDefaultRowAdded"] = "Default Table Row Added";
    EventTypes["FormTableDefaultRowDeleted"] = "Default Table Row Deleted";
    EventTypes["FormTableDefaultRowCopyPasted"] = "Default Table Row Copy Pasted";
    EventTypes["FormTableDefaultRowRearranged"] = "Default Table Row Rearranged";
    EventTypes["FormTableDefaultRowCleared"] = "Default Table Clear Single Row Clicked";
    EventTypes["FormTablePrefilledAllRowsCleared"] = "Prefilled Table Clear All Rows Clicked";
    EventTypes["FormTablePrefilledRowCleared"] = "Prefilled Table Clear Single Row Clicked";
    EventTypes["FormTableRowMidwayAdded"] = "Table Row Midway Added";
    EventTypes["FormTemplateLogicExecuted"] = "Template Logic Executed";
    EventTypes["FormTextApplied"] = "Text Applied to Form";
    EventTypes["FormThumbnailClicked"] = "Form Thumbnail Clicked";
    EventTypes["FormUpdated"] = "Form Edited";
    EventTypes["FormNewVersionDisplayed"] = "New form version displayed";
    EventTypes["FormNewVersionAlertClicked"] = "New Form Version Alert Clicked";
    EventTypes["FormWorkflowSwipedAcross"] = "Form Workflow Swiped Across";
    EventTypes["PhotoRemovedFromForm"] = "Photo Removed From Form";
    EventTypes["PhotoUploadFailed"] = "Photo Upload Failed";
    // Lists
    EventTypes["ListActionApplied"] = "List Action Applied";
    EventTypes["ListAutoDeployed"] = "List Auto Deployed";
    EventTypes["ListBulkItemColourApplied"] = "Bulk List Item Colour Applied";
    EventTypes["ListCreated"] = "List Created";
    EventTypes["ListDeployed"] = "List Deployed";
    EventTypes["ListUndeployed"] = "List Undeployed";
    EventTypes["ListDeployedItemAdded"] = "New List Item Added to Child";
    EventTypes["ListDeployItemSelected"] = "Parent List Item Added to Child";
    EventTypes["ListFieldWebDeleted"] = "List FieldWeb Deleted";
    EventTypes["ListItemDeleted"] = "List Item Deleted";
    EventTypes["ListPropertyColumnMoveClicked"] = "Move List Property Column Clicked";
    EventTypes["ListPropertyAppliedToForm"] = "List Property Applied to Form";
    EventTypes["ListPropertyAdded"] = "List Property Added";
    EventTypes["ListPropertyInsertInBetween"] = "Insert List Property Column Clicked";
    EventTypes["ListItemActionApplied"] = "List Items Action Applied";
    EventTypes["ListItemColourEdited"] = "List Item Colour Edited";
    EventTypes["TableListItemColourEdited"] = "Table List Item Colour Edited";
    EventTypes["ListParentItemAddedToChild"] = "Parent List Item Added to Child";
    EventTypes["ListRemoved"] = "List Removed";
    EventTypes["ListUpdated"] = "List Updated";
    EventTypes["ListItemsDownloadedAsCSV"] = "List Items Downloaded as CSV";
    // Miscellaneous events
    EventTypes["ActivationIdVerified"] = "Activation Id Verified";
    EventTypes["ApiRequestReceived"] = "API Request Received";
    EventTypes["AppFeedbackSubmitted"] = "App Feedback Submitted";
    EventTypes["AppFeedbackModalClicked"] = "App Feedback Modal Clicked";
    EventTypes["CtaClicked"] = "CTA Clicked";
    EventTypes["CtaClosed"] = "CTA Closed";
    EventTypes["DashpivotExperienceAlertClicked"] = "Dashpivot Experience Alert Clicked";
    EventTypes["DownloadDashpivotAppButtonClicked"] = "Download Dashpivot App Button Clicked";
    EventTypes["DownloadSitemateAppButtonClicked"] = "Download Sitemate App Button Clicked";
    EventTypes["EmailProductBoardLinkClicked"] = "Email Product Board Link Clicked";
    EventTypes["EmailSubscribeSnapshotReportLinkClicked"] = "Email Subscribe Snapshot Report Link Clicked";
    EventTypes["EmailUnsubscribeSnapshotReportLinkClicked"] = "Email Unsubscribe Snapshot Report Link Clicked";
    EventTypes["ForceDownload"] = "Force Download";
    EventTypes["GoogleMapsRequestFromPhotoModal"] = "Request to Google Maps Sent From Photo Modal";
    EventTypes["GoogleMapsRequestFromSignonTableLocation"] = "Request to Google Maps Sent From Signon Table Location";
    EventTypes["GoolgeMapsRequestFromLocationField"] = "Request To Google Maps Sent From Location Field";
    EventTypes["ViewMapClicked"] = "View Map Clicked";
    EventTypes["IntercomOpened"] = "Intercom Opened";
    EventTypes["OrganisationMonthlySnapshotSent"] = "Organisation Monthly Snapshot Sent";
    EventTypes["PersonalWeeklySnapshotSent"] = "Personal Weekly Snapshot Sent";
    EventTypes["ProjectMonthlySnapshotSent"] = "Project Monthly Snapshot Sent";
    EventTypes["SignonCreated"] = "Signon Created";
    EventTypes["SignonCreatedFromUpdate"] = "Signon Created From Update";
    EventTypes["SignonRemoved"] = "Signon Removed";
    EventTypes["StoreListingTextMsgLinkClicked"] = "Store Listing Text Msg Link Clicked";
    EventTypes["TeamWeeklySnapshotSent"] = "Team Weekly Snapshot Sent";
    // Navigation
    EventTypes["NavigationClicked"] = "Navigation Clicked";
    EventTypes["NavLinkClicked"] = "Nav Link Clicked";
    // Organisations
    EventTypes["OrganisationSettingApplied"] = "Organisation Setting Applied";
    EventTypes["OrganizationBillingStatusSetToFree"] = "Set Org to Free";
    EventTypes["OrganizationBillingStatusSetToPaid"] = "Set Org to Paid";
    // PDFs
    EventTypes["FileUploadLimitExceeded"] = "File Upload limit exceeded";
    EventTypes["PdfEdited"] = "PDF edited";
    EventTypes["PdfOpened"] = "PDF opened";
    EventTypes["PdfSendEmailAdded"] = "Send PDF email added";
    EventTypes["PdfSendEmailDeleted"] = "Send PDF email deleted";
    EventTypes["PdfSendEmailEdited"] = "Send PDF email edited";
    // Photos and videos
    EventTypes["PhotoBulkActionApplied"] = "Photo Bulk Action Applied";
    EventTypes["PhotoCameraOpened"] = "Camera Opened";
    EventTypes["PhotoCommentActionApplied"] = "Photo Comment Action Applied";
    EventTypes["PhotoCommentCreated"] = "Photo Comment Created";
    EventTypes["PhotoCommentUpdated"] = "Photo Comment Updated";
    EventTypes["PhotoCommentRemoved"] = "Photo Comment Removed";
    EventTypes["PhotoClicked"] = "Photo Clicked";
    EventTypes["PhotoCaptured"] = "Photo Captured";
    EventTypes["PhotoDashpivotCamera"] = "Dashpivot Camera";
    EventTypes["PhotoDescriptionUpdated"] = "Photo Description Updated";
    EventTypes["PhotoDescriptionEdited"] = "Photo Description Edited";
    EventTypes["PhotoDescriptionRemoved"] = "Photo Description Removed";
    EventTypes["PhotoDetailMapScrolled"] = "Photo Detail Map Scrolled";
    EventTypes["PhotoFilterApplied"] = "Photo Filter Applied";
    EventTypes["PhotoFilterReset"] = "Photo Filter Reset";
    EventTypes["PhotoMarkupApplied"] = "Photo Markup Applied";
    EventTypes["PhotoMarkupSkipped"] = "Photo Markup Skipped";
    EventTypes["PhotoRemoved"] = "Photo Removed";
    EventTypes["PhotoSaveClicked"] = "Photo Save Clicked";
    EventTypes["PhotoSelected"] = "Photo Selected";
    EventTypes["PhotoSingleActionApplied"] = "Photo Single Action Applied";
    EventTypes["PhotoSingleViewChanged"] = "Photo Single View Changed";
    EventTypes["PhotoSingleViewOpened"] = "Photo Single View Opened";
    EventTypes["PhotoStampApplied"] = "Photo Stamp Applied";
    EventTypes["PhotosCopied"] = "Photos Copied";
    EventTypes["PhotosDeleted"] = "Photos Deleted";
    EventTypes["PhotosDownloadedAsZip"] = "Photos Downloaded as ZIP";
    EventTypes["PhotosDownloadedAsOriginal"] = "Photos Downloaded as Original";
    EventTypes["PhotosExportedAsPdf"] = "Photos Exported As PDF";
    EventTypes["PhotosLoaded"] = "Photos Loaded";
    EventTypes["PhotosLoadedByScroll"] = "Photos Loaded [Scroll]";
    EventTypes["PhotosMoved"] = "Photos Moved";
    EventTypes["PhotosUploaded"] = "Photos Uploaded";
    EventTypes["PhotoTagApplied"] = "Photo Tag Applied";
    EventTypes["PhotoTagGroupUpdated"] = "Photo Tag Group Updated";
    EventTypes["PhotoVideoViewed"] = "Video Viewed";
    EventTypes["PhotoVideoRecorded"] = "Video Recorded";
    EventTypes["PhotoVideoUploaded"] = "Video Uploaded";
    EventTypes["PhotoInstantMarkupSettingEdited"] = "Photo Instant Markup Setting Edited";
    EventTypes["UploadPhotoButtonClicked"] = "Upload Photo Button Clicked";
    EventTypes["InteractiveMapOpened"] = "Interactive Map Opened";
    // Projects
    EventTypes["ProjectKmlDeleted"] = "Project KML Deleted";
    EventTypes["ProjectKmlUploaded"] = "Project KML Uploaded";
    EventTypes["ProjectTagGroupCreated"] = "Project Tag Group Created";
    EventTypes["ProjectTagGroupRemoved"] = "Project Tag Group Removed";
    EventTypes["ProjectTagGroupUpdated"] = "Project Tag Group Updated";
    // Scan and Sign
    EventTypes["ScanSignatureExecuted"] = "Scan Signature executed";
    EventTypes["ScanSitemateIDTableDeleteAllRowsClicked"] = "Scan Sitemate ID Table Delete All Rows Clicked";
    EventTypes["ScanSitemateIDTableRowDeleted"] = "Scan Sitemate ID Table Row Deleted";
    EventTypes["ScanSitemateIDClicked"] = "Scan Sitemate ID Clicked";
    // Sitemate
    EventTypes["SitemateLinkArchived"] = "Sitemate Link Archived";
    EventTypes["SitemateLinkArchivedTemplatesViewed"] = "Sitemate Link Archived Templates Viewed";
    EventTypes["SitemateLinkCopied"] = "Sitemate Link Copied";
    EventTypes["SitemateLinkCreated"] = "Sitemate Link Created";
    EventTypes["SitemateLinkEdited"] = "Sitemate Link Edited";
    EventTypes["SitemateLinkNumberTemplatesViewed"] = "Sitemate Link Number Templates Viewed";
    EventTypes["SitemateLinkPosterDownloaded"] = "Sitemate Link Poster Downloaded";
    EventTypes["SitemateLinkRestored"] = "Sitemate Link Restored";
    EventTypes["SitemateLinksAccessedViewed"] = "Sitemate Links Accessed Viewed";
    EventTypes["SitemateEnterCodeManuallySubmitted"] = "Enter Code Manually Submitted";
    EventTypes["SitemateQRCodeScanned"] = "QR Code Scanned";
    EventTypes["SitemateActivityFeedFormOpened"] = "Activity Feed Read Only Form Opened";
    EventTypes["SSOConnectionAdded"] = "SSO Connection Added";
    EventTypes["SSOConnectionEdited"] = "SSO Connection Edited";
    EventTypes["SSOConnectionDeleted"] = "SSO Connection Deleted";
    // Sign up
    EventTypes["SignupFormError"] = "Signup Form Error";
    EventTypes["SignupFormSubmitted"] = "Signup Form Submitted";
    EventTypes["LogoUploaded"] = "Logo Uploaded";
    EventTypes["SignUpPageVisited"] = "Sign Up Page Visited";
    EventTypes["SignUpPageViewed"] = "Sign Up Page Viewed";
    EventTypes["SignupVerticalManuallyEntered"] = "Vertical Manually Entered";
    EventTypes["SignupVerticalSelected"] = "Vertical Selected";
    EventTypes["SignupRoleManuallyEntered"] = "Role Manually Entered";
    EventTypes["SignupRoleSelected"] = "Role Selected";
    EventTypes["SignUpAverted"] = "Sign Up Averted";
    EventTypes["SignUpHumanVerificationRequired"] = "Signup Human Verification Required";
    EventTypes["SignUpUserEmail"] = "Sign Up User Email";
    // Teams
    EventTypes["TeamEmailLinkClicked"] = "Email Team Link Clicked";
    EventTypes["TeamTagGroupCreated"] = "Team Tag Group Created";
    EventTypes["TeamTagGroupRemoved"] = "Team Tag Group Removed";
    EventTypes["TeamTagGroupUpdated"] = "Team Tag Group Updated";
    // Templates
    EventTypes["TemplateAddButtonClicked"] = "Template Add Button Clicked";
    EventTypes["TemplateAddLogicClicked"] = "Template AddLogic Clicked";
    EventTypes["TemplateBackButtonClicked"] = "Back To Templates Button Clicked";
    EventTypes["TemplateBuilderModalNextButtonClicked"] = "Template Builder Modal Next Button Clicked";
    EventTypes["TemplateBuilderSaveButtonClicked"] = "Template Builder Save Button Clicked";
    EventTypes["TemplateRegisterColumnsSettingsChanged"] = "Template Register Columns Settings Changed";
    EventTypes["TemplateCopiedFromLibraryToTeam"] = "Template Copied From Library To Team";
    EventTypes["TemplateCreated"] = "Template Created";
    EventTypes["TemplateCreationOptionSelected"] = "Template Creation Option Selected";
    EventTypes["TemplateEditorToggleClicked"] = "Template Editor Toggle Clicked";
    EventTypes["TemplateFieldAdded"] = "Template Field Added";
    EventTypes["TemplateFieldCloned"] = "Template Field Cloned";
    EventTypes["TemplateFieldDeleted"] = "Template Field Deleted";
    EventTypes["TemplateFieldDragged"] = "Template Field Dragged";
    EventTypes["TemplateFieldDropdownOptionsClicked"] = "Template Field Dropdown Options Clicked";
    EventTypes["TemplateLinkAccessedViewed"] = "Template Links Accessed Viewed";
    EventTypes["TemplateLinkArchived"] = "Template Link Archived";
    EventTypes["TemplateLinkArchivedTemplatesViewed"] = "Template Link Archived Templates Viewed";
    EventTypes["TemplateLinkCopied"] = "Template Link Copied";
    EventTypes["TemplateLinkCreated"] = "Template Link Created";
    EventTypes["TemplateLinkEdited"] = "Template Link Edited";
    EventTypes["TemplateLinkNumberTemplatesViewed"] = "Template Link Number Templates Viewed";
    EventTypes["TemplateLinkRestored"] = "Template Link Restored";
    EventTypes["TemplateLinkPosterDownloaded"] = "Template Link Poster Downloaded";
    EventTypes["TemplateLoaded"] = "Template Loaded";
    EventTypes["TemplateLogicExecuted"] = "Template Logic Executed";
    EventTypes["TemplatePermissionsChanged"] = "Template Permissions Changed";
    EventTypes["TemplatePreviewOpened"] = "Template Preview Opened";
    EventTypes["TemplatePublicAdded"] = "Public Template Added";
    EventTypes["TemplateRawCodeClicked"] = "Template Raw Code Clicked";
    EventTypes["TemplateRegisterViewFilterApplied"] = "Register View Filter Applied";
    EventTypes["TemplateRegisterViewFilterIconClicked"] = "Register View Filter Icon Clicked";
    EventTypes["TemplateRegisterViewRowToggled"] = "Register View Row Toggled";
    EventTypes["TemplateArchived"] = "Template Archived";
    EventTypes["TemplateRestored"] = "Template Restored";
    EventTypes["TemplateRemoved"] = "Template Deleted";
    EventTypes["TemplateSelected"] = "Template Selected";
    EventTypes["TemplateSelectedInLibrary"] = "Templates Selected In Library";
    EventTypes["TemplateSelectedInOrganizationLibrary"] = "Templates Selected In Org Library";
    EventTypes["TemplateSettingEdited"] = "Template Setting Edited";
    EventTypes["TemplateSignUpSelected"] = "Sign Up Templates Selected";
    EventTypes["TemplateSignUpSelectionSkipped"] = "Sign Up Template Selection Skipped";
    EventTypes["TemplateBulkActionApplied"] = "Template Bulk Action Applied";
    EventTypes["TemplatesDeployed"] = "Templates Deployed";
    EventTypes["TemplatesExported"] = "Templates Exported";
    EventTypes["TemplatesFilterApplied"] = "Templates Filter Applied";
    EventTypes["TemplatesLoaded"] = "Templates Loaded";
    EventTypes["TemplatesUndeployed"] = "Templates Undeployed";
    EventTypes["TemplateTabAdded"] = "Template Tab Added";
    EventTypes["TemplateTabCreated"] = "Template Tab Created";
    EventTypes["TemplateTabEdited"] = "Template Tab Edited";
    EventTypes["TemplateTabDeleted"] = "Template Tab Deleted";
    EventTypes["TemplateTableColumnAdded"] = "Template Table Column Added";
    EventTypes["TemplateTableColumnMidwayAdded"] = "Table Column Midway Added in Editor";
    EventTypes["TemplateTableColumnWidthApplied"] = "Template Table Column Width Applied";
    EventTypes["TemplateTableRowAdded"] = "Template Table Row Added";
    EventTypes["TemplatePrefilledTableRowMidwayAdded"] = "Prefilled Row Midway Added in Editor";
    EventTypes["TemplateTableRowRemoved"] = "Template Table Row Removed";
    EventTypes["TemplateTabRemoved"] = "Template Tab Removed";
    EventTypes["TemplateTabsMoved"] = "Template Tabs Moved";
    EventTypes["TemplateUpdated"] = "Template Edited";
    EventTypes["TemplateWorkflowEmailLinkClicked"] = "Email Workflow Link Clicked";
    EventTypes["TemplateWorkflowNotificationBellClicked"] = "Workflow Notification Bell Clicked";
    EventTypes["TemplateHeaderFooterFieldToggleClicked"] = "Template Header Footer Field Toggle Clicked";
    EventTypes["TemplateHeaderFooterFieldAdded"] = "Template Header Footer Field Added";
    EventTypes["TemplateHeaderFooterFieldDeleted"] = "Template Header Footer Field Deleted";
    EventTypes["TemplateHeaderFooterFieldDragged"] = "Template Header Footer Field Dragged";
    EventTypes["ApprovalSignatureLogicAdded"] = "Approval Signature Logic Added";
    EventTypes["EnableNewFormulaEngineClicked"] = "Enable New Formula Engine Clicked";
    EventTypes["EditTemplateButtonClicked"] = "Edit Template Button Clicked";
    EventTypes["PhotosPerPageSettingEdited"] = "Photos Per Page Setting Edited";
    EventTypes["RequiredFieldToggled"] = "Required Field Toggled";
    EventTypes["HideFieldInPDFToggled"] = "Hide Field In PDF Toggled";
    EventTypes["ExcludeInFormCloningToggled"] = "Exclude In Form Cloning Toggled";
    EventTypes["PhotoFieldSettingsToggled"] = "Photo Field Settings Toggled";
    EventTypes["LocationAdded"] = "Location Added";
    EventTypes["LocationRemoved"] = "Location Removed";
    // Users
    EventTypes["UserAccountActivatedByEmail"] = "Activate Your Account Clicked (Email)";
    EventTypes["UserActivated"] = "User Activated";
    EventTypes["UserAdded"] = "User Added";
    EventTypes["UserAddedToCompany"] = "User Added To Company";
    EventTypes["UserAddedToProject"] = "User Added To Project";
    EventTypes["UserAddedToTeam"] = "User Added To Team";
    EventTypes["UserAssignedCompanyControllerRole"] = "User Assigned Company Controller Role";
    EventTypes["UserAssignedCompanyMemberRole"] = "User Assigned Company Member Role";
    EventTypes["UserAssignedProjectControllerRole"] = "User Assigned Project Controller Role";
    EventTypes["UserAssignedProjectMemberRole"] = "User Assigned Project Member Role";
    EventTypes["UserAssignedTeamControllerRole"] = "User Assigned Team Controller Role";
    EventTypes["UserDeleteAccount"] = "Delete Account";
    EventTypes["UserDeleted"] = "User Deleted";
    EventTypes["UserEmailLoginLinkClicked"] = "Email Login Link Clicked";
    EventTypes["UserInformationLoaded"] = "User Information Loaded";
    EventTypes["UserInvalidCode"] = "Invalid Code";
    EventTypes["UserInvited"] = "User Invited";
    EventTypes["UserPasswordResetButtonClicked"] = "User Password Reset Button Clicked";
    EventTypes["UserPasswordResetEmailLinkClicked"] = "User Reset Password Link Clicked";
    EventTypes["UserPasswordUpdated"] = "User Password Updated";
    EventTypes["UserProfileViewed"] = "User Profile Viewed";
    EventTypes["UserProfileUpdated"] = "User Profile Updated";
    EventTypes["UserLoginClicked"] = "Login Clicked";
    EventTypes["UserLogoutClicked"] = "Logout Clicked";
    EventTypes["UserLoggedIn"] = "User Logged In";
    EventTypes["UserLoggedInForTheFirstTime"] = "User Logged In For The First Time";
    EventTypes["UserLoggedOut"] = "User Logged Out";
    EventTypes["UserReceivedActivationEmail"] = "User Received Activation Email";
    EventTypes["UserReceivedSecondActivationEmail"] = "User Received 2nd Activation Email";
    EventTypes["UserReceivedThirdActivationEmail"] = "User Received 3rd Activation Email";
    EventTypes["UserReceivedWorkflowNotificationEmail"] = "User Received Workflow Notification Email";
    EventTypes["UserRequestedPasswordReset"] = "User Requested Password Reset";
    EventTypes["UserRemoved"] = "User Removed";
    EventTypes["UserRemovedFromCompany"] = "User Removed From Company";
    EventTypes["UserRemovedFromProject"] = "User Removed From Project";
    EventTypes["UserRemovedFromTeam"] = "User Removed From Team";
    EventTypes["UserRequestedCall"] = "Requested Call";
    EventTypes["UserResentCode"] = "Resent Code";
    EventTypes["UserResetPasword"] = "Reset password";
    EventTypes["UserSignedUp"] = "User Signed Up";
    EventTypes["UserTypeViewed"] = "User Type Viewed";
    EventTypes["UpgradeClicked"] = "Upgrade Clicked";
    EventTypes["UserUnassignedCompanyControllerRole"] = "User Unassigned Company Controller Role";
    EventTypes["UserUnassignedCompanyMemberRole"] = "User Unassigned Company Member Role";
    EventTypes["UserUnassignedProjectControllerRole"] = "User Unassigned Project Controller Role";
    EventTypes["UserUnassignedProjectMemberRole"] = "User Unassigned Project Member Role";
    EventTypes["UserUnassignedTeamControllerRole"] = "User Unassigned Team Controller Role";
    EventTypes["PendingUserResendButtonClicked"] = "Pending User Resend Button Clicked";
    EventTypes["PendingUserTryAgainButtonClicked"] = "Pending User Try Again Button Clicked";
    EventTypes["AdBlockStatusDetected"] = "Ad Block Status Detected";
    // Video
    EventTypes["VideoPlayed"] = "Video Played";
    // Sitemate Chef
    EventTypes["SitemateChefTemplatesCopyRequested"] = "Sitemate Chef Templates Copy Requested";
    EventTypes["SitemateChefPhotosCopyRequested"] = "Sitemate Chef Photos Copy Requested";
    EventTypes["SitemateChefListsCopyRequested"] = "Sitemate Chef Lists Copy Requested";
    EventTypes["SitemateChefFormGenerationRequested"] = "Sitemate Chef Form Generation Requested";
    // Flowsite
    EventTypes["FlowsiteReferralClicked"] = "Flowsite Referral Clicked";
    // Network
    EventTypes["FirewallErrorOccurred"] = "Firewall Error Occurred";
    // Survicate
    EventTypes["LaunchSurvicateSurvey"] = "Launch Survicate Survey";
    // Sam
    EventTypes["SamTaskExecuted"] = "Sam Task Executed";
})(EventTypes = exports.EventTypes || (exports.EventTypes = {}));
