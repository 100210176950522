import { FieldSettingsRenderAttributeMap } from './form-field-settings-indicators.model';
import { FieldSettingsNames } from '../../../types/field-settings.type';

export const FieldSettingsIndicatorMap: FieldSettingsRenderAttributeMap = {
  isRequired: {
    icon: 'asterisk',
    tooltipText: (value) => (value ? 'Required Field' : 'Not a Required Field'),
    fieldName: FieldSettingsNames.IsRequired,
  },
  isHiddenInFormPDFExports: {
    icon: 'file-pdf',
    tooltipText: (value) => (value ? 'Hidden in Form PDF Exports' : 'Visible in Form PDF Exports'),
    fieldName: FieldSettingsNames.IsHiddenInFormPDFExports,
  },
  isExcludedInFormCloning: {
    icon: 'clone',
    tooltipText: (value) => (value ? 'Excluded in Form Cloning' : 'Included in Form Cloning'),
    fieldName: FieldSettingsNames.isExcludedInFormCloning,
  },
  isPhotoDescriptionVisible: {
    icon: 'text',
    tooltipText: (value) => (value ? 'Include Photo Description' : 'Do Not Include Photo Description'),
    fieldName: FieldSettingsNames.IsPhotoDescriptionVisible,
  },
  isPhotoTagsVisible: {
    icon: 'tag',
    tooltipText: (value) => (value ? 'Include Photo Tags' : 'Do Not Include Photo Tags'),
    fieldName: FieldSettingsNames.IsPhotoTagsVisible,
  },
};
