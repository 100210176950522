import { Component, Input, Output, EventEmitter, HostBinding } from '@angular/core';

import { Comment } from '@site-mate/dashpivot-shared-library';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { VersionComment } from 'app/shared/model/comments/version-comment.model';

const blankLinkValue = '_blank';
@Component({
  selector: 'cc-activity-bar',
  styleUrls: ['./activity-bar.component.scss'],
  templateUrl: './activity-bar.component.html',
})
export class ActivityBarComponent {
  @Input() comments: Comment[] = [];
  @Input() message: string;
  @Input() learnMoreLink: string;

  @Input()
  @HostBinding('class.show')
  show = false;

  @Output() versionClick: EventEmitter<VersionComment> = new EventEmitter();

  isAnchorTag(element: HTMLElement) {
    return element.tagName === 'A';
  }

  handleVersionClick(event: MouseEvent, entry: VersionComment) {
    const target = event.target as HTMLElement;
    if (this.isAnchorTag(target) && target.getAttribute('target') === blankLinkValue) {
      return; // Link within the comment
    }
    this.versionClick.emit(entry);
  }
}
