<div class="filter-header sm-flex sm-items-center">
  <div class="float-left sm-flex sm-items-center sm-space-x-0.4">
    <i class="cc-icon cc-icon-tag"></i>
    <span i18n>Photo Tags</span>
  </div>
  <div class="sm-ml-auto">
    <a class="sm-flex sm-text-default-text" title="Reset" i18n-title="Reset" (click)="resetSelection()">
      <i class="cc-icon cc-icon-undo"></i>
    </a>
  </div>
  <span class="clearfix"></span>
</div>
<div class="filter-body">
  <div class="tag-category tag-category-project">
    <div class="tag-category-header">
      <span>Project Photo Tags</span>
      <span *ngIf="isProjectControllerOrAbove() && hierarchy">
        <a
          [routerLink]="['/', 'home', 'projects', hierarchy.project._id, 'settings', 'tag-groups']"
          (click)="trackEditProjectTagEvent()"
        >
          <small i18n>Edit</small>
        </a>
      </span>
    </div>
    <div class="tag-category-body">
      <div class="text-center">
        <i *ngIf="loadingProjectTags" class="cc-icon cc-icon-spinner"></i>
      </div>
      <div class="no-tags" *ngIf="!loadingProjectTags && projectTagGroups && projectTagGroups.length === 0">
        <div>
          <small class="text-muted" i18n> No project photo tags have been created yet. </small>
        </div>
        <div *ngIf="isProjectControllerOrAbove() && hierarchy">
          <small>
            <a
              i18n
              (click)="trackEditProjectTagEvent()"
              [routerLink]="['/', 'home', 'projects', hierarchy.project._id, 'settings', 'tag-groups']"
              >Click here to set them up.</a
            >
          </small>
        </div>
      </div>

      <div class="tag-group animate-fade-in" *ngFor="let tagGroup of projectTagGroups">
        <div class="tag-group-header">
          {{ tagGroup.name }}
        </div>
        <div class="tag-group-body">
          <div *ngFor="let tag of tagGroup.tags" class="tag-wrapper">
            <a (click)="toggleTag(tag)"
              ><span class="tag" [class.tag-selected]="tag.selected">{{ tag.name }}</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tag-category tag-category-team">
    <div class="tag-category-header">
      <span>Team Photo Tags</span>
      <span *ngIf="isTeamControllerOrAbove() && hierarchy">
        <a
          [routerLink]="['/', 'home', 'teams', hierarchy.team._id, 'settings', 'tag-groups']"
          (click)="trackEditTeamTagEvent()"
        >
          <small i18n>Edit</small>
        </a>
      </span>
    </div>
    <div class="tag-category-body">
      <div class="text-center">
        <i *ngIf="loadingProjectTags" class="cc-icon cc-icon-spinner"></i>
      </div>
      <div class="no-tags" *ngIf="!loadingTeamTags && teamTagGroups && teamTagGroups.length === 0">
        <div>
          <small class="text-muted" i18n> No team photo tags have been created yet. </small>
          <small *ngIf="!userService.isTeamControllerOrAbove() && team">
            Your team controllers can set this up.
          </small>
        </div>
        <div *ngIf="isTeamControllerOrAbove() && hierarchy">
          <small>
            <a
              i18n
              (click)="trackEditTeamTagEvent()"
              [routerLink]="['/', 'home', 'teams', hierarchy.team._id, 'settings', 'tag-groups']"
              >Click here to set them up.</a
            >
          </small>
        </div>
      </div>

      <div class="tag-group animate-fade-in" *ngFor="let tagGroup of teamTagGroups">
        <div class="tag-group-header">
          {{ tagGroup.name }}
        </div>
        <div class="tag-group-body">
          <div *ngFor="let tag of tagGroup.tags" class="tag-wrapper">
            <a (click)="toggleTag(tag)"
              ><span class="tag" [class.tag-selected]="tag.selected">{{ tag.name }}</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
