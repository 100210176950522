"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChartShowModes = void 0;
var ChartShowModes;
(function (ChartShowModes) {
    ChartShowModes["Area"] = "area";
    ChartShowModes["Bar"] = "bar";
    ChartShowModes["Cumulative"] = "cumulative";
    ChartShowModes["Line"] = "line";
    ChartShowModes["HorizontalBar"] = "horizontal-bar";
    ChartShowModes["PieChart"] = "pie";
    ChartShowModes["VerticalBar"] = "vertical-bar";
})(ChartShowModes = exports.ChartShowModes || (exports.ChartShowModes = {}));
