import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[ccFocus]',
})
export class FocusDirective implements AfterViewInit {
  @Input()
  ccFocus;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit() {
    if (this.ccFocus !== false) {
      setTimeout(() => {
        this.renderer.selectRootElement(this.el.nativeElement).focus();
      }, 0);
    }
  }
}
