import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, debounceTime, takeUntil } from 'rxjs';
/**
 * `DynamicSizeDirective` is an Angular directive that allows you to dynamically
 * set the width and height of an HTML element. By applying this directive to
 * an element and providing a size object with width and height properties, the
 * element's dimensions will be updated accordingly.
 *
 * The directive also listens to the window 'resize' event and updates the
 * element's dimensions when the window is resized. This is done with a
 * debounced function to prevent excessive updates, improving performance.
 *
 * Usage:
 *   `<div ccDynamicSize="{ width: 100, height: 200 }"></div>`
 *
 * The above example will set the element's width to 100px and height to 200px.
 */
@Directive({
  selector: '[ccDynamicSize]',
})
export class DynamicSizeDirective implements OnInit, OnDestroy {
  @Input('ccDynamicSize') size: { width: number; height: number };
  private resizeSubject = new Subject<void>();
  private unSubscribe = new Subject<void>();

  constructor(private readonly elementRef: ElementRef) {
    this.resizeSubject
      .pipe(debounceTime(100), takeUntil(this.unSubscribe))
      .subscribe(() => this.updateSize());
  }

  ngOnInit() {
    this.updateSize();
  }

  ngOnDestroy() {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }

  @HostListener('window:resize')
  onResize() {
    this.resizeSubject.next();
  }

  private updateSize() {
    const element = this.elementRef.nativeElement;
    const { width, height } = this.size || {};

    if (width) {
      element.style.width = `${width}px`;
    }
    if (height) {
      element.style.height = `${height}px`;
    }
  }
}
