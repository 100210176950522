import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import moment from 'moment-timezone';
import { Subject } from 'rxjs';

import { DashpivotEvent, EventNotifierService, EventTypes } from '@site-mate/dashpivot-shared-library';

import { PhotoService } from 'app/photo/photo.service';
import { SegmentService } from 'app/segment/segment.service';
import { UploaderParams } from 'app/shared/model/uploader-params.model';
import { AppUtilService } from 'app/shared/service/app-util.service';
import { FileUploaderModalService } from 'app/shared/service/file-uploader-modal.service';
import { ToastrService } from 'app/shared/service/toastr.service';
import { UuidService } from 'app/shared/service/uuid/uuid.service';
import { SearchInputComponent } from 'app/ui-components/search-input/search-input.component';
import { UserService } from 'app/user/user.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'cc-photo-tool-bar',
  templateUrl: 'photo-tool-bar.component.html',
  styleUrls: ['photo-tool-bar.component.scss', '../../styles/components/tool-bar.component.scss'],
})
export class PhotoToolBarComponent implements OnChanges, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  @Input() selected;
  @Input() users;
  @Input() teamId;
  @Input() isWorking;
  @Input() downloadingPDF;
  @Input() downloadingZip;
  @Input() formRef;
  @Input() allSearchCriteria;
  @Input() isLoadingUsers = false;
  @Input() uploaderLabels: UploaderParams['labels'];
  @Input() acceptedFiles: UploaderParams['acceptedFiles'] =
    'image/jpg, image/bmp, image/jpeg, image/png, image/tiff, video/mp4, .mov';
  @Output() resetAllFilters = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() download = new EventEmitter();
  @Output() exportPhoto = new EventEmitter();
  @Output() searchSiteNote = new EventEmitter();
  @Output() searchUsers = new EventEmitter();
  @Output() searchDate = new EventEmitter();
  @Output() move = new EventEmitter();
  @Output() copy = new EventEmitter();
  @Output() addPhotoTags = new EventEmitter();
  @ViewChild('userFilterPop') userFilterPop;

  @ViewChild('dateFilterPop') dateFilterPop;

  @ViewChild('searchInput') searchInput: SearchInputComponent;

  copiedUsers;
  siteNote;
  selectedUsers = [];
  startDate;
  endDate;
  isUserFilterOn;
  isDateFilterOn;
  isSiteNoteFilterOn;

  constructor(
    private readonly appUtilService: AppUtilService,
    private readonly fileUploaderModalService: FileUploaderModalService,
    private readonly photoService: PhotoService,
    private readonly toastr: ToastrService,
    private readonly userService: UserService,
    private readonly segmentService: SegmentService,
    private readonly uuidService: UuidService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.users && changes.users.currentValue) {
      this.copiedUsers = this.appUtilService.clone(changes.users.currentValue).map((user) => {
        return { label: `${user.firstName} ${user.lastName}`, value: user._id };
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  uploadPhoto() {
    this.fileUploaderModalService.add({
      _id: this.uuidService.getUuid(),
      headers: {
        'Time-Zone': (moment as any).tz.guess(),
      },
      acceptedFiles: this.acceptedFiles,
      url: `${environment.apiUrl}v1/teams/${this.teamId}/photos`,
      labels: this.uploaderLabels,
    });
    this.sendUploadPhotoEvent();
  }

  sendUploadPhotoEvent() {
    const context = this.formRef ? 'Form Modal' : 'Photo Timeline';
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.UploadPhotoButtonClicked, { Context: context }),
      this.segmentService,
    );
  }

  searchBySiteNotes(searchTerm: string) {
    this.siteNote = searchTerm;

    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.PhotoFilterApplied, {
        Context: 'Search Description Filter',
        value: this.siteNote,
      }),
      this.segmentService,
    );
    this.isSiteNoteFilterOn = this.siteNote;
    this.searchSiteNote.emit({ note: this.siteNote });
  }

  searchByUser() {
    if (this.selectedUsers.length > 0) {
      this.searchByUserCore();
    }
  }

  searchByUserCore() {
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.PhotoFilterApplied, {
        Context: 'User Filter',
      }),
      this.segmentService,
    );
    this.isUserFilterOn = this.selectedUsers && this.selectedUsers.length > 0;
    this.searchUsers.emit({ selectedUsers: this.selectedUsers });
    this.userFilterPop.close();
  }

  searchByDateCore() {
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.PhotoFilterApplied, {
        Context: 'Date Filter',
      }),
      this.segmentService,
    );
    this.isDateFilterOn = this.startDate || this.endDate;
    this.searchDate.emit({ startDate: this.startDate, endDate: this.endDate });
    this.dateFilterPop.close();
  }

  searchByDate() {
    if (this.startDate || this.endDate) {
      this.searchByDateCore();
    }
  }

  resetUserFilter() {
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.PhotoFilterReset, {
        Context: 'User Filter',
      }),
      this.segmentService,
    );
    if (this.selectedUsers.length > 0) {
      this.selectedUsers = [];
      this.searchByUserCore();
    }
  }

  resetDateFilter() {
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.PhotoFilterReset, {
        Context: 'Date Filter',
      }),
      this.segmentService,
    );
    if (this.startDate || this.endDate) {
      this.startDate = undefined;
      this.endDate = undefined;
      this.searchByDateCore();
    }
  }

  resetFilterUI() {
    this.startDate = undefined;
    this.endDate = undefined;
    this.siteNote = undefined;
    this.selectedUsers = [];
    this.isSiteNoteFilterOn = false;
    this.isUserFilterOn = false;
    this.isDateFilterOn = false;
  }

  resetFilter() {
    this.resetFilterUI();
    this.resetAllFilters.emit();
  }

  hasSelection() {
    if (this.selected.length === 0) {
      this.toastr.warnByKey('selectPhoto');
      return false;
    }
    return true;
  }

  canDelete() {
    if (this.selected.length === 0) {
      return false;
    }
    return (
      this.userService.isTeamControllerOrAbove() ||
      this.selected.every((s) => this.userService.isCurrentUser(s.createdBy))
    );
  }

  canMove() {
    if (this.selected.length === 0) {
      return false;
    }
    return (
      this.userService.isTeamControllerOrAbove() ||
      this.selected.every((s) => this.userService.isCurrentUser(s.createdBy))
    );
  }

  canCopy() {
    if (this.selected.length === 0) {
      return false;
    }
    return this.userService.isTeamControllerOrAbove();
  }

  onExportToPDF() {
    if (!this.hasSelection() || this.downloadingPDF) {
      return;
    }
    this.exportPhoto.emit();
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.PhotoBulkActionApplied, {
        Context: 'Download As PDF',
      }),
      this.segmentService,
    );
  }

  onExportToZip() {
    if (!this.hasSelection() || this.downloadingZip) {
      return;
    }
    this.download.emit();
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.PhotoBulkActionApplied, {
        Context: 'Download As ZIP',
      }),
      this.segmentService,
    );
  }

  onRemove() {
    if (!this.hasSelection()) {
      return;
    }
    if (this.canDelete()) {
      this.remove.emit();
      void EventNotifierService.notify(
        new DashpivotEvent(EventTypes.PhotoBulkActionApplied, {
          Context: 'Deleted',
        }),
        this.segmentService,
      );
    } else {
      this.toastr.errorByKey('noPermissionDelete');
    }
  }

  get hasSearchCriteria() {
    return !this.photoService.isEmptySearchCriteria(this.allSearchCriteria);
  }
}
