"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeaderFooterService = void 0;
class HeaderFooterService {
    getVisibleFields(headerFooterFields) {
        const visibleFields = [];
        headerFooterFields === null || headerFooterFields === void 0 ? void 0 : headerFooterFields.forEach((item) => {
            if (item.isVisible) {
                visibleFields.push(item.kind);
            }
        });
        return visibleFields;
    }
}
exports.HeaderFooterService = HeaderFooterService;
