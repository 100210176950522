import {
  Component,
  Output,
  Input,
  EventEmitter,
  HostBinding,
  ViewEncapsulation,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';

@Component({
  selector: 'cc-register-row',
  templateUrl: './register-row.component.html',
  styleUrls: ['./register-row.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegisterRowComponent implements AfterViewInit {
  @Input() displaySelectionCheckbox = true;
  @Input() selected = false;
  @Output() select = new EventEmitter();
  @Input()
  @HostBinding('class.collapsible')
  collapsable = false;

  @HostBinding('class.collapsed')
  collapsed = true;

  constructor(
    private readonly element: ElementRef,
    private readonly ref: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.collapsable = this.element.nativeElement.getBoundingClientRect().height > 130;
    });
  }

  onCollapseToggle(event) {
    event.stopPropagation();

    this.collapsed = !this.collapsed;
  }
}
