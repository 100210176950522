<div
  ngbDropdown
  [container]="container"
  [placement]="placement"
  [autoClose]="autoClose"
  [dropdownClass]="dropdownPlacementClass"
  [ngClass]="dropdownClass"
  (openChange)="onStateChange($event)"
>
  <div ngbDropdownMenu [ngClass]="dropdownMenuClass">
    <ng-content select="[dropdownItem]"></ng-content>
  </div>
  <button
    type="button"
    [ngClass]="toggleButtonClass"
    (click)="$event.stopPropagation()"
    (keydown)="onDropdownToggleKeyDown($event)"
    ngbDropdownToggle
    #dropdownToggle
    [disabled]="disabled"
  >
    <ng-content select="[dropdownToggle]"></ng-content>
  </button>
</div>
