import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { FieldKinds } from '@site-mate/dashpivot-shared-library';

interface DropdownOption {
  value: string;
  label: string;
}

@Component({
  selector: 'cc-template-dropdown-radio-input',
  templateUrl: './template-dropdown-radio-input.component.html',
  styleUrls: ['./template-dropdown-radio-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateDropdownRadioInputComponent implements OnInit {
  @Input() options: DropdownOption[] = [];
  @Input() fieldId: string;
  @Input() value: string;
  @Input() hierarchy: string;
  @Input() iconClass: string;
  @Input() identifier: string;
  @Output() selectOption = new EventEmitter<FieldKinds>();
  private selectedFormControl = new FormControl();

  ngOnInit() {
    this.selectedFormControl.setValue(this.value);

    this.selectedFormControl.valueChanges.subscribe((newKind) => {
      this.selectOption.emit(newKind);
    });
  }

  setOption(option: FieldKinds) {
    this.selectedFormControl.setValue(option);
  }
}
