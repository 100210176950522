"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalItemModels = void 0;
var GlobalItemModels;
(function (GlobalItemModels) {
    GlobalItemModels["AttachmentSimple"] = "attachment-simple";
    GlobalItemModels["Container"] = "container";
    GlobalItemModels["DateExpiry"] = "date-expiry";
    GlobalItemModels["DatePlain"] = "date-plain";
    GlobalItemModels["DateRangePlain"] = "date-range-plain";
    GlobalItemModels["DateSimple"] = "date-simple";
    GlobalItemModels["Location"] = "location";
    GlobalItemModels["NumberSimple"] = "number-simple";
    GlobalItemModels["TextSimple"] = "text-simple";
    GlobalItemModels["TimePlain"] = "time-plain";
})(GlobalItemModels = exports.GlobalItemModels || (exports.GlobalItemModels = {}));
