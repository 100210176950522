import { Injectable } from '@angular/core';

import { HttpClientService } from 'app/shared/service/http-client.service';

import { MapLocation, MapSize } from './map-modal.model';

@Injectable({ providedIn: 'root' })
export class MapModalService {
  constructor(private readonly httpClientService: HttpClientService) {}

  getStaticMapUrl(companyId: string, dimension: MapSize, location: MapLocation) {
    const { height, width } = dimension;
    const { lat, lng } = location;
    return this.httpClientService.get(
      `companies/${companyId}/maps/static-map?height=${height}&width=${width}&latitude=${lat}&longitude=${lng}`,
    );
  }
}
