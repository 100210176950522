import { Inject, inject, Injectable } from '@angular/core';
import { UUID } from 'bson';
import { filter, forkJoin, of, switchMap, first, mapTo, race, timer } from 'rxjs';
import { UAParser } from 'ua-parser-js';

import { DashpivotEvent, EventNotifierService, EventTypes, Event } from '@site-mate/dashpivot-shared-library';

import { GlobalApiService } from 'app/global-api/global-api.service';
import { SegmentService } from 'app/segment/segment.service';
import { WINDOW } from 'app/shared/factory/window.factory';
import { TeamWeb } from 'app/shared/model/team.model';

import { CookiesService } from '../cookies.service';
import { HttpClientService } from '../http-client.service';
import { TeamService } from '../team.service';

@Injectable({ providedIn: 'root' })
export class EventsService {
  private readonly SEGMENT_TIMEOUT = 10000;
  private readonly cookieService = inject(CookiesService);
  protected defaultMetadata = {
    sitemateStartAccountId: '',
    sitemateStartAccountName: '',
    workspaceId: '',
    workspaceName: '',
    platform: 'web',
  };

  private anonymousId: string;

  constructor(
    @Inject(WINDOW) private readonly window: Window,
    protected readonly segmentService: SegmentService,
    private readonly teamService: TeamService,
    private readonly globalApiService: GlobalApiService,
    private readonly http: HttpClientService,
  ) {
    this.teamService
      .getCurrentTeam$()
      .pipe(
        filter((team: TeamWeb) => !!team.companyMetadata),
        switchMap((team) =>
          forkJoin([of(team), this.globalApiService.getAccount(team.companyMetadata.accountId)]),
        ),
      )
      .subscribe(([team, account]) => {
        const { name, workspaceId, accountId } = team.companyMetadata;
        this.defaultMetadata.sitemateStartAccountId = accountId;
        this.defaultMetadata.workspaceId = workspaceId;
        this.defaultMetadata.workspaceName = name;
        this.defaultMetadata.sitemateStartAccountName = account.name;
      });
  }

  getAnonymousId(): string {
    return this.anonymousId;
  }

  trackEvent(event: EventTypes, metadata: Record<string, unknown> = {}): void {
    Object.assign(metadata, this.defaultMetadata);

    const newEvent = new DashpivotEvent(event, metadata as Event['metadata']);

    if (metadata.proxyFromServer) {
      const clientEvent = this.populateEventWithClientProperties(newEvent);
      this.postEvent(clientEvent);
    } else {
      void EventNotifierService.notify(newEvent, this.segmentService);
    }
  }

  trackPreSignUpAnonymousEvent(event: EventTypes, metadata: Record<string, unknown> = {}) {
    if (!this.anonymousId) {
      race(
        this.segmentService.isSegmentReady$.pipe(first((value) => value)),
        timer(this.SEGMENT_TIMEOUT).pipe(mapTo(false)),
      ).subscribe(() => {
        this.setAnonymousId();

        metadata.anonymousId = this.anonymousId;
        this.trackEvent(event, metadata);
      });
    } else {
      metadata.anonymousId = this.anonymousId;

      this.trackEvent(event, metadata);
    }
  }

  alias(newId: string) {
    if (!this.anonymousId) {
      this.setAnonymousId();
    }

    void this.segmentService.alias(newId, this.anonymousId);
  }

  private setAnonymousId() {
    const mixpanelCookie = this.cookieService.getCookieByNameOrValue('mixpanel', 'distinct_id');

    this.anonymousId = mixpanelCookie?.distinct_id ?? new UUID().toString();
  }

  private postEvent(newEvent: DashpivotEvent) {
    this.http.post('record-pre-sign-up-events', newEvent).subscribe();
  }

  private populateEventWithClientProperties(event: DashpivotEvent) {
    const parser = new UAParser();
    const clientDetails = parser.getResult();

    event.metadata.referrer = this.window.document.referrer;
    event.metadata.url = this.window.document.location.href;
    event.metadata.screenHeight = this.window.screen.height;
    event.metadata.screenWidth = this.window.screen.width;
    event.metadata.client = clientDetails;

    return event;
  }
}
