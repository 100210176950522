import { Component, Input, OnInit } from '@angular/core';

import { PhotoThumbnailSizes } from '@site-mate/dashpivot-shared-library';

import { FieldSettingsIndicatorMap } from './form-field-settings-indicators.constant';
import { FieldSettings } from './form-field-settings-indicators.model';
import { FieldSettingsNames } from '../../../types/field-settings.type';

@Component({
  selector: 'cc-form-field-settings-indicators',
  templateUrl: './form-field-settings-indicators.component.html',
  styleUrls: ['./form-field-settings-indicators.component.scss'],
})
export class FormFieldSettingsIndicatorsComponent implements OnInit {
  @Input() fieldSettings!: FieldSettings;
  @Input() photoThumbnailSize?: PhotoThumbnailSizes;

  orderedFieldSettingsNames: FieldSettingsNames[] = [
    FieldSettingsNames.IsRequired,
    FieldSettingsNames.IsHiddenInFormPDFExports,
    FieldSettingsNames.isExcludedInFormCloning,
  ];
  fieldSettingsIndicatorMap: typeof FieldSettingsIndicatorMap = FieldSettingsIndicatorMap;
  photoSizesWithDescriptionAndTags = [PhotoThumbnailSizes.Large, PhotoThumbnailSizes.ExtraLarge];

  ngOnInit(): void {
    if (this.photoThumbnailSize && this.photoSizesWithDescriptionAndTags.includes(this.photoThumbnailSize)) {
      this.orderedFieldSettingsNames.push(
        FieldSettingsNames.IsPhotoDescriptionVisible,
        FieldSettingsNames.IsPhotoTagsVisible,
      );
    }
  }

  getTooltipText(fieldSetting: `${FieldSettingsNames}`) {
    return FieldSettingsIndicatorMap[fieldSetting].tooltipText(this.fieldSettings[fieldSetting]);
  }
}
