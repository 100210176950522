import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SEGMENT_CONFIG, segmentConfigFactory } from './segment-config.factory';
import { SegmentCTADirective } from './segment-cta.directive';

@NgModule({
  imports: [CommonModule],
  providers: [{ provide: SEGMENT_CONFIG, useFactory: segmentConfigFactory }],
  declarations: [SegmentCTADirective],
  exports: [SegmentCTADirective],
})
export class SegmentModule {}
