"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountSubscriptionService = void 0;
const sitemate_global_shared_1 = require("@site-mate/sitemate-global-shared");
const plan_type_limits_1 = require("../models/companies/plan-type-limits");
const dashpivot_modules_enum_1 = require("../models/shared/dashpivot-modules.enum");
class AccountSubscriptionService {
    initialize(account) {
        this.account = account;
    }
    static get instance() {
        if (!AccountSubscriptionService._instance) {
            AccountSubscriptionService._instance = new AccountSubscriptionService();
        }
        return AccountSubscriptionService._instance;
    }
    get accountPlanType() {
        var _a, _b;
        const { planType } = ((_b = (_a = this.account) === null || _a === void 0 ? void 0 : _a.billing) === null || _b === void 0 ? void 0 : _b.dashpivot) || {};
        return planType;
    }
    showDashpivotTrialGuide() {
        var _a, _b, _c;
        return ((_c = (_b = (_a = this.account) === null || _a === void 0 ? void 0 : _a.billing) === null || _b === void 0 ? void 0 : _b.dashpivot) === null || _c === void 0 ? void 0 : _c.planType) === sitemate_global_shared_1.CompanyPlanTypes.FreeTrial;
    }
    canUpgradeSubscription() {
        var _a, _b, _c;
        const subscriptionStatus = (_c = (_b = (_a = this.account) === null || _a === void 0 ? void 0 : _a.billing) === null || _b === void 0 ? void 0 : _b.dashpivot) === null || _c === void 0 ? void 0 : _c.subscriptionStatus;
        if (!subscriptionStatus) {
            return false;
        }
        return [
            sitemate_global_shared_1.SubscriptionStatuses.FreeTrial,
            sitemate_global_shared_1.SubscriptionStatuses.ExpiredTrial,
            sitemate_global_shared_1.SubscriptionStatuses.Paused,
            sitemate_global_shared_1.SubscriptionStatuses.Churned,
            sitemate_global_shared_1.SubscriptionStatuses.OverdueInvoice,
        ].includes(subscriptionStatus);
    }
    getDashpivotModuleLimit(planTypeLimit) {
        var _a;
        return (_a = planTypeLimit[this.accountPlanType]) !== null && _a !== void 0 ? _a : planTypeLimit[sitemate_global_shared_1.CompanyPlanTypes.Legacy];
    }
    canUpgradeCurrentPlan() {
        const upgradeablePlans = [
            sitemate_global_shared_1.CompanyPlanTypes.Standard,
            sitemate_global_shared_1.CompanyPlanTypes.Legacy,
            sitemate_global_shared_1.CompanyPlanTypes.Pro,
            sitemate_global_shared_1.CompanyPlanTypes.Premium,
        ];
        return upgradeablePlans.includes(this.accountPlanType);
    }
    canAddApprovalSignatureLogic() {
        const plans = [
            sitemate_global_shared_1.CompanyPlanTypes.FreeTrial,
            sitemate_global_shared_1.CompanyPlanTypes.Pro,
            sitemate_global_shared_1.CompanyPlanTypes.Premium,
            sitemate_global_shared_1.CompanyPlanTypes.Platinum,
            sitemate_global_shared_1.CompanyPlanTypes.Enterprise,
        ];
        return plans.includes(this.accountPlanType);
    }
    canAddVisitors() {
        var _a, _b, _c;
        const isLegacyPlan = this.accountPlanType === sitemate_global_shared_1.CompanyPlanTypes.Legacy;
        const visitorModuleLimit = plan_type_limits_1.PlanTypeLimits[dashpivot_modules_enum_1.DashpivotModules.Users].Dashpivot.visitor;
        const visitorsAllowedInPlan = Boolean(this.getDashpivotModuleLimit(visitorModuleLimit));
        return isLegacyPlan
            ? !((_c = (_b = (_a = this.account) === null || _a === void 0 ? void 0 : _a.billing) === null || _b === void 0 ? void 0 : _b.dashpivot) === null || _c === void 0 ? void 0 : _c.legacyPlanRestrictionsEnabled)
            : visitorsAllowedInPlan;
    }
    canAddSitemateUsers() {
        var _a, _b, _c;
        const isLegacyPlan = this.accountPlanType === sitemate_global_shared_1.CompanyPlanTypes.Legacy;
        const sitemateUserModuleLimit = plan_type_limits_1.PlanTypeLimits[dashpivot_modules_enum_1.DashpivotModules.Users].Dashpivot.sitemateUser;
        const sitemateUsersAllowedInPlan = Boolean(this.getDashpivotModuleLimit(sitemateUserModuleLimit));
        return isLegacyPlan
            ? !((_c = (_b = (_a = this.account) === null || _a === void 0 ? void 0 : _a.billing) === null || _b === void 0 ? void 0 : _b.dashpivot) === null || _c === void 0 ? void 0 : _c.legacyPlanRestrictionsEnabled)
            : sitemateUsersAllowedInPlan;
    }
    canCreateAdditionalWorkspaces() {
        const plans = [
            sitemate_global_shared_1.CompanyPlanTypes.FreeTrial,
            sitemate_global_shared_1.CompanyPlanTypes.Pro,
            sitemate_global_shared_1.CompanyPlanTypes.Legacy,
            sitemate_global_shared_1.CompanyPlanTypes.Premium,
            sitemate_global_shared_1.CompanyPlanTypes.Platinum,
            sitemate_global_shared_1.CompanyPlanTypes.Enterprise,
        ];
        return plans.includes(this.accountPlanType);
    }
}
exports.AccountSubscriptionService = AccountSubscriptionService;
