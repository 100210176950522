"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashpivotApiLimits = void 0;
/**
 * Constants for Dashpivot API limits
 */
var DashpivotApiLimits;
(function (DashpivotApiLimits) {
    DashpivotApiLimits[DashpivotApiLimits["ListForms"] = 50] = "ListForms";
    DashpivotApiLimits[DashpivotApiLimits["ListAnalyticsFormsBytes"] = 157286400] = "ListAnalyticsFormsBytes";
})(DashpivotApiLimits = exports.DashpivotApiLimits || (exports.DashpivotApiLimits = {}));
