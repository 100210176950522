{
  "fusionAuthAuthority": "https://auth.sitemate.com",
  "env:API_BASE_URL": "https://api.dashpivot.com/",
  "env:NODE_ENV": "production",
  "env:UI_BASE_URL": "https://dashpivot.com/",
  "env:FLOWSITE_UI_URL": "https://flowsite.com",
  "env:AWS_REGION": "ap-southeast-2",
  "secret:api-client-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVkM2FkNDNhYWYxNTgwMjM0MThiYmIwNyIsImNyZWF0ZWRBdCI6MTU2NDEzOTI0MzQ5MSwiaWF0IjoxNTY0MTM5MjQzfQ.nWLisfjLYxP-HoxcimAx0MPLfA7h_lzttUzXPHjk5d0",
  "secret:bugsnag-web-key": "a9757d7919676f186967a7e8dd0fbcdf",
  "secret:fusionauth-dp-application-id": "0d19fe48-a334-4dd6-9948-6e4304c6d91e",
  "secret:google-maps-api-key": "AIzaSyAeQBNIa_wq8dKpQnwplfs4xxBOW5qFosc",
  "secret:google-tag-manager-id": "GTM-T46RNQF",
  "secret:intercom-app-id": "mif72555",
  "secret:launch-darkly-client-id": "62bd49ab9d1f2d14708808cd",
  "secret:mixpanel-token": "1fc86a8ca3ac9a39e32b8ada2c4913c3",
  "secret:recaptcha-site-key": "6LePz80mAAAAAGcMDQj9-RULkrPLv3y5DahXktwF",
  "secret:recaptcha-site-key-v3": "6LfCr64mAAAAAMMS56ierU_apdGtEM1RPae9o6cd",
  "secret:segment-web-key": "PbDoBWo3rLovkgrpxluRsrTjw7gxrmEb",
  "secret:sitemate-api-url": "https://api.sitemate.com/",
  "secret:global-api-url": "https://api-global.sitemate.com",
  "secret:vitally-key": "ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmhkV1FpT2lKbFltSmhNakE0T0MxbU5tSTJMVFExWkRZdE9UYzJNUzA1WlRkak1qbGlZVGd5TVRjaUxDSmhjR2tpT2lKaGNHa2lMQ0p6WldNaU9pSmpZbVptTjJZNVl5SjkuUDhZNlpvLVZUR2diWlNJVnYtcUR6Z1dDeWhSR3JhaFpZQmhzaDJZZjExczo=",
  "secret:survicate-key": "646efe2d442bb157472a642809d2b676"
}
