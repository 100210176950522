import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter, of, switchMap } from 'rxjs';

import { AccountSubscriptionService } from '@site-mate/dashpivot-shared-library';
import { IAccount, IWorkspace as ISitemateStartWorkspace } from '@site-mate/sitemate-global-shared/lib';

import { GlobalApiService } from 'app/global-api/global-api.service';
import { HttpClientService } from 'app/shared/service/http-client.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private currentAccountSubject = new BehaviorSubject<IAccount>({} as IAccount);
  public currentAccount = this.currentAccountSubject.asObservable().pipe(
    filter((account) => account && Object.keys(account).length > 0), // Filter out the initial empty value
  );

  constructor(
    private http: HttpClientService,
    private readonly accountSubscriptionService: AccountSubscriptionService,
    private readonly globalApiService: GlobalApiService,
  ) {}

  getFlowsiteWorkspaces(accountId: string): Observable<ISitemateStartWorkspace[]> {
    return this.http.get(`account/${accountId}/flowsite-workspaces`);
  }

  private setCurrentAccount(account: IAccount) {
    this.currentAccountSubject.next(account);
  }

  getCurrentAccount() {
    return this.currentAccountSubject.getValue();
  }

  getAccountPlanType() {
    return this.getCurrentAccount().billing?.dashpivot?.planType;
  }

  initializeAccount(accountId: string) {
    return this.globalApiService.getAccount(accountId).pipe(
      switchMap((account) => {
        this.accountSubscriptionService.initialize(account);
        this.setCurrentAccount(account);
        return of(account);
      }),
    );
  }
}
