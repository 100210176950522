/* eslint-disable max-lines */
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { get } from 'lodash-es';
import { Subject } from 'rxjs';

import {
  CategorySourceTypes,
  DetailedCellError,
  ExportedCellChange,
  FieldKinds,
  ITemplateWithHierarchy,
  StyleVariants,
  TableCellData,
  TableCellKinds,
  TableColumnWidths,
} from '@site-mate/dashpivot-shared-library';

import { FormBaseComponent } from 'app/form/form-components/form-base.component';
import { FormEventsService } from 'app/form/form-events.service';
import { FormService } from 'app/form/form.service';
import { FieldWeb } from 'app/shared/model/item.model';
import { AppUtilService } from 'app/shared/service/app-util.service';
import { ConfirmService } from 'app/shared/service/confirm.service';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { EventsService } from 'app/shared/service/events/events.service';
import { FormulaEngineService, FormulasVersion } from 'app/shared/service/formula-engine.service';
import { HotFormulaParserEngineService } from 'app/shared/service/hot-formula-parser-engine.service';
import { HyperFormulaEngineService } from 'app/shared/service/hyper-formula-engine.service';

import { FormTableActionResolver } from './form-table-action-resolver';
import { ListPropertyFormService } from '../edit-cells/list-property-form.service';
import { TableColumnService } from '../service/table-column-service';

@Component({
  selector: 'cc-form-table-core',
  templateUrl: 'form-table-core.component.html',
  styleUrls: [
    '../../../form/form-components/form-component.scss',
    'form-table.component.scss',
    'form-table-core.component.scss',
  ],
})
export class FormTableCoreComponent extends FormBaseComponent<FieldWeb> implements OnInit, OnDestroy {
  private readonly listPropertyFormService = inject(ListPropertyFormService);
  readonly tableColumnWidths = TableColumnWidths;
  readonly TableCellKinds = TableCellKinds;

  @Input() model: FieldWeb;
  @Input() template: ITemplateWithHierarchy;
  @Input() formPath: string;
  @Input() isReadOnly: boolean;
  @Input() readonlyFormulas: boolean;
  @Input() formId: string;
  @Input() parentId: string;
  @Input() isLandscapeMode: boolean;
  @Input() hasLeftActions = true;

  @Output() invalid = new EventEmitter();
  @Output() onChangeField = new EventEmitter();

  hasFormulas = false;
  hoverRow = null;
  hoverButton = false;
  isPrefilledTable = false;
  unsubscribe = new Subject<void>();
  sheetName: string;
  sheet: number;
  formulaEngineService: FormulaEngineService;
  hasListProperty: boolean;

  public actionResolver: FormTableActionResolver;

  constructor(
    protected readonly appUtilService: AppUtilService,
    protected readonly confirmService: ConfirmService,
    protected readonly formService: FormService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly eventsService: EventsService,
    private readonly tableColumnService: TableColumnService,
    private readonly errorHandler: ErrorHandler,
    private readonly injector: Injector,
    private readonly formEventsService: FormEventsService,
  ) {
    super(appUtilService, formService);
  }

  ngOnInit() {
    this.hasListProperty = this.model.columns.some((column) => column.kind === TableCellKinds.ListProperty);
    this.formulaEngineService = this.getFormulasServiceEngine();
    this.actionResolver = new FormTableActionResolver(
      this.model,
      this.eventsService,
      this.confirmService,
      this.tableColumnService,
      this.unsubscribe,
      this.errorHandler,
      this.listPropertyFormService,
    );
    this.actionResolver.onTableAction.subscribe(({ isDone } = {}) => {
      this.onFieldChange();
      this.handleTableAction.bind(this)({ isDone });
    });
    this.isPrefilledTable = this.model.kind === FieldKinds.PrefilledTable;
    this.hasFormulas = this.tableCoreHasFormulas();
    this.initTable();
    this.initFormulas();
  }

  initFormulas() {
    if (this.readonlyFormulas || this.hasHyperFormulaBeenDestroyed()) {
      return;
    }

    this.sheetName = this.model.tableReference;
    this.sheet = this.formulaEngineService.getSheet(this.sheetName);
    this.formulaEngineService.setValuesUpdateListener(this.formulaChangeHandler.bind(this));
    this.formulaEngineService.initSheetContent({
      sheet: this.sheet,
      table: this.model,
      tableHasFormulas: this.hasFormulas,
    });
  }

  private hasHyperFormulaBeenDestroyed() {
    return this.isFormulaV2 && !(this.formulaEngineService as HyperFormulaEngineService).isEngineActive();
  }

  formulaChangeHandler(exportedCellChanges: ExportedCellChange[]) {
    exportedCellChanges
      .filter(
        ({ address }) =>
          address.sheet === this.sheet &&
          this.model.rows[address.row]?.columns[address.col]?.kind === TableCellKinds.Formula,
      )
      .forEach(({ address, newValue }) => {
        let parsedValue = Number.isFinite(newValue) ? Number(newValue).toFixed(2) : newValue;

        if (newValue instanceof DetailedCellError) {
          parsedValue = newValue.value;
        }

        const column = this.model.rows[address.row].columns[address.col];
        this.model.rows[address.row].columns[address.col] = { ...column, value: parsedValue };
      });
    this.changeDetectorRef.markForCheck();
  }

  initTable() {
    if (this.isEmptyRegularTable()) {
      this.actionResolver.addRow();
    }

    this.actionResolver.updateActionFlags();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  handleFieldUpdate(cellAddress?: { colIndex: number; rowIndex: number }) {
    if (this.isFormulaV2) {
      this.formEventsService.enableFormulaEvent();
    }

    this.formulaEngineService.setCellContent({
      table: this.model,
      colIndex: cellAddress.colIndex,
      rowIndex: cellAddress.rowIndex,
      sheet: this.sheet,
      tableHasFormulas: this.hasFormulas,
    });
  }

  handleTableAction({ isDone }: { isDone?: boolean } = {}) {
    if (isDone === false) {
      return;
    }

    try {
      this.formulaEngineService.calculateSheetContent({
        sheet: this.sheet,
        table: this.model,
        tableHasFormulas: this.hasFormulas,
      });
      this.changeDetectorRef.detectChanges();
    } catch (err) {
      this.errorHandler.handle(err);
    }
  }

  isEmptyRegularTable() {
    return !this.isPrefilledTable && !this.model.rows?.length;
  }

  getColumnKind(rowIndex: number, columnIndex: number) {
    return this.getColumnAttribute(rowIndex, columnIndex, 'kind');
  }

  getColumnDesc(rowIndex: number, columnIndex: number) {
    return this.getColumnAttribute(rowIndex, columnIndex, 'description');
  }

  setHoverOnRow(rowIndex: number) {
    this.hoverRow = rowIndex;
  }

  hoverOnButton() {
    this.hoverButton = !this.hoverButton;
  }

  onFieldChange(cell?: TableCellData, rowIndex?: number) {
    super.onFieldChange();
    this.actionResolver.updateClearFlagBasedOnCell(cell, rowIndex);
  }

  getColumn(rowIndex, columnIndex) {
    if (this.isPrefilledTable) {
      return get(this.model, `rows.${rowIndex}.columns.${columnIndex}`, null);
    }
    return get(this.model, `columns.${columnIndex}`, null);
  }

  private getColumnAttribute(rowIndex, columnIndex, key) {
    const column = this.getColumn(rowIndex, columnIndex);
    return get(column, key, null);
  }

  handleFieldChange() {
    this.onChangeField.emit();
  }

  private tableCoreHasFormulas() {
    return this.isPrefilledTable ? this.prefilledTableHasFormula() : this.tableHasFormula();
  }

  private tableHasFormula() {
    return (this.model.columns || []).some((col) => col.kind === TableCellKinds.Formula);
  }

  private prefilledTableHasFormula() {
    return (this.model.rows || [])
      .reduce((acc, curr) => [...acc, ...curr.columns], [])
      .some((column) => column.kind === TableCellKinds.Formula);
  }

  private getFormulasServiceEngine(): FormulaEngineService {
    return this.isFormulaV2
      ? this.injector.get(HyperFormulaEngineService)
      : this.injector.get<FormulaEngineService>(HotFormulaParserEngineService);
  }

  private get isFormulaV2() {
    return (this.template?.formulasVersion as FormulasVersion) === FormulasVersion.v2;
  }

  getListItemColor(rowIndex: number, columnIndex: number) {
    const cell = this.model?.rows[rowIndex].columns[columnIndex];

    let style = cell.style || StyleVariants.None;

    if (cell.listSource === CategorySourceTypes.Manual && cell.manualListItems) {
      const { value, manualListItems } = cell;
      style = manualListItems.find((item) => item.value === value)?.style;
    }

    return `list-item-${style}`;
  }
}
