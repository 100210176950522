import { InjectionToken } from '@angular/core';

export interface IModalRef<Component = unknown, Result = unknown, DismissReason = unknown> {
  readonly componentInstance: Component;
  close(result?: Result): void;
  dismiss(reason?: DismissReason): void;
}

export interface IModalService<
  Component = unknown,
  OpenContent = unknown,
  OpenOptions = unknown,
  CloseResult = unknown,
  DismissReason = unknown,
> {
  open(content: OpenContent, options?: OpenOptions): IModalRef<Component, CloseResult, DismissReason>;
  dismissAll(): void;
}

export const IModalServiceToken = new InjectionToken<IModalService>('IModalService');
