export enum TemplateFieldTypes {
  // Fields
  Text = 'Text',
  Date = 'Date',
  YesNo = 'Yes/No',
  List = 'List',
  Table = 'Table',
  SitemateIntegration = 'Sitemate Integration',
  Signature = 'Signature',
  Photos = 'Photos',
  Person = 'Person',
  Sketch = 'Sketch',
  PageBreak = 'Page Break',
  // Header Footer Fields
  Branding = 'Branding',
  FormMetaData = 'Form Meta Data',
  PDFExportMetaData = 'PDF Export Meta Data',
  Other = 'Other',
}
