<div>
  <ng-select
    class="time-input"
    placeholder="Select"
    [(ngModel)]="time"
    (ngModelChange)="onChange($event)"
    [class.has-danger]="invalid"
    [addTag]="isSearchTermInsertable"
    [selectOnTab]="true"
    [virtualScroll]="true"
    notFoundText="Invalid value"
    [appendTo]="container"
    (blur)="closeDropDown()"
  >
    <ng-option *ngIf="time" [value]="clearOption">
      <span class="time-input-clear-option">{{ clearOption }}</span>
    </ng-option>
    <ng-option *ngFor="let time of times" [value]="time">{{ time }}</ng-option>
  </ng-select>
</div>
