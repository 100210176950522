import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { LegacyStyleVariants, StyleVariants } from '@site-mate/dashpivot-shared-library';

type StyleOptionMappings = {
  [K in keyof typeof StyleVariants]: {
    value: (typeof StyleVariants)[K];
    label: K;
  };
};

type LegacyStyleOptionMappings = {
  [K in keyof typeof LegacyStyleVariants]: {
    value: (typeof LegacyStyleVariants)[K];
    label: K;
  };
};

type StyleOption =
  | StyleOptionMappings[keyof typeof StyleVariants]
  | LegacyStyleOptionMappings[keyof typeof LegacyStyleVariants];

@Component({
  selector: 'cc-style-color-picker',
  templateUrl: './style-color-picker.component.html',
  styleUrls: [
    './style-color-picker.component.scss',
    '../../form-fields/category/edit/form-category.component.scss',
    '../../form-fields/category/edit/form-category-general-style.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StyleColorPicker {
  @Input() style: StyleOption['value'];
  @Output() styleChange = new EventEmitter<StyleOption['value']>();

  // We need the reverse dictionary since the option is bound just to the "value" and not the actual option containing label and value
  private styleLookup: {
    [K in StyleVariants | LegacyStyleVariants]: keyof typeof StyleVariants | keyof typeof LegacyStyleVariants;
  } = {
    ...this.enumToLookup(StyleVariants),
    ...this.enumToLookup(LegacyStyleVariants),
  };

  colorPickerOptions: any[];
  selectedOption: StyleOption;

  @Input() mode: 'minimal' | 'select' = 'minimal';

  @Input() disabled = false;

  @Input() defaultStyle = StyleVariants.Sky;

  ngOnInit(): void {
    this.colorPickerOptions = Object.entries(StyleVariants).map(([label, value]) => {
      return {
        value,
        label,
      };
    });

    const label = this.styleLookup[this.style];

    this.selectedOption = this.style
      ? ({
          value: this.style,
          label,
        } as StyleOption)
      : null;
  }

  setStyle(style: StyleOption) {
    this.selectedOption = style;
    this.style = style?.value;

    this.styleChange.emit(this.style);
  }

  // This function returns a "reverse enum" dictionary of either StyleVariants or LegacyStyleVariants
  private enumToLookup(enumObject: typeof StyleVariants | typeof LegacyStyleVariants): {
    [K in StyleVariants | LegacyStyleVariants]: keyof typeof StyleVariants | keyof typeof LegacyStyleVariants;
  } {
    return Object.entries(enumObject).reduce((lookup, [key, value]) => {
      lookup[value as StyleVariants | LegacyStyleVariants] = key as
        | keyof typeof StyleVariants
        | keyof typeof LegacyStyleVariants;
      return lookup;
    }, {} as any);
  }
}
