@if (showTooltip) {
  <button
    type="button"
    gw-button
    text="Capture Location"
    rightIcon="map"
    size="medium"
    color="green"
    class="sm-flex-wrap sm-text-left"
    disabled
    [gwTooltip]="locationTooltipContent"
    gwTooltipIndicatorPosition="top"
    [gwTooltipHideDelay]="800"
    data-test="tooltip-button"
  ></button>
  <ng-template #locationTooltipContent>
    <span i18n
      >Location can only be captured via the Dashpivot Mobile app.
      <a [href]="locationTableCellHelpUrl" class="sm-underline" target="_blank">Learn more</a> about
      Location.</span
    >
  </ng-template>
} @else {
  <button
    type="button"
    gw-button
    text="Capture Location"
    rightIcon="map"
    size="medium"
    color="green"
    class="sm-flex-wrap sm-text-left"
    disabled
    data-test="simple-button"
  ></button>
}
