import { inject, Injectable } from '@angular/core';
import { isEmpty } from 'lodash-es';
import { BehaviorSubject, filter, Observable, switchMap, tap } from 'rxjs';

import {
  IAttachmentSimple,
  ILegacyList,
  IList,
  IListCell,
  IListProperty,
  IListRow,
  ItemStates,
} from '@site-mate/dashpivot-shared-library';
import { Flake } from '@site-mate/sitemate-global-shared/lib';

import { ListQueryCacheService } from 'app/lists/list-query-cache.service';
import { isFetchedQuery } from 'app/shared/model/query/query-predicates';
import { QueryStates } from 'app/shared/model/query/query-states.enum';
import { Query } from 'app/shared/model/query/query.model';
import { TeamWeb } from 'app/shared/model/team.model';
import { TeamService } from 'app/shared/service/team.service';

type ExcludeType<T, U> = T extends U ? never : T;
export type IListCellWithoutAttachment = ExcludeType<IListCell, IAttachmentSimple>;
type IRowId = string;
type IPropertyId = string;

type ITableRowMap = Map<IList['_id'], IRowColumnMap>;
type IRowColumnMap = Map<IRowId, IColumnPropertyMap>;
type IColumnPropertyMap = Map<IPropertyId, IPropertyCellMap>;
type IPropertyCellMap = Map<IListProperty['_id'], ICellSubject>;
type ICellSubject = BehaviorSubject<IListCellQuery>;
export type IListCellQuery = Query<IListCellWithoutAttachment>;

export type ICellAddress = {
  tableId: string;
  rowId: string;
  referenceTableColumnId: string;
};

@Injectable()
export class ListPropertyFormService {
  private readonly listCacheService = inject(ListQueryCacheService);
  private readonly listRowsMap = new Map<IList['_id'], BehaviorSubject<Query<IListRow[]>>>();
  private readonly rowCellsMap = new Map<IListRow['_id'], BehaviorSubject<Query<IListCell[]>>>();
  private readonly legacyIdToListMap = new Map<ILegacyList['_id'], BehaviorSubject<Query<IList>>>();
  private listPropertyCellMap: ITableRowMap = new Map();
  private projectItemId: string | null = null;

  private readonly tableRowSelectionMap = new Map<string, BehaviorSubject<boolean>>();

  constructor(private readonly teamService: TeamService) {
    this.teamService
      .getCurrentTeam$()
      .pipe(filter((team) => !isEmpty(team)))
      .subscribe((team: TeamWeb) => {
        this.projectItemId = team.projectMetadata?.itemId || null;
      });
  }

  getCell(rowAddress: ICellAddress, propertyId: string): Observable<IListCellQuery> {
    return this.getCellSubject(rowAddress, propertyId).asObservable();
  }

  isRowSelected(tableId: string, rowId: string): Observable<boolean> {
    if (!this.tableRowSelectionMap.has(`${tableId}-${rowId}`)) {
      this.tableRowSelectionMap.set(`${tableId}-${rowId}`, new BehaviorSubject(false));
    }

    return this.tableRowSelectionMap.get(`${tableId}-${rowId}`).asObservable();
  }

  setSelectedRow(tableId: string, rowId: string, value: boolean) {
    const currentMap = this.tableRowSelectionMap.get(`${tableId}-${rowId}`);
    if (currentMap?.value === value) {
      return;
    }

    if (!currentMap) {
      this.tableRowSelectionMap.set(`${tableId}-${rowId}`, new BehaviorSubject(value));
      return;
    }

    currentMap.next(value);
  }

  private getCellSubject(rowAddress: ICellAddress, propertyId: string): ICellSubject {
    const columnMap = this.getColumnPropertyMap(rowAddress);
    if (!columnMap.has(propertyId)) {
      const cellSubject = new BehaviorSubject<IListCellQuery>({ state: QueryStates.Empty });
      columnMap.set(propertyId, cellSubject);
    }

    return columnMap.get(propertyId);
  }

  private getTableRowMap(tableId: string, rowId: string): IColumnPropertyMap {
    if (!this.listPropertyCellMap.has(tableId)) {
      this.listPropertyCellMap.set(tableId, new Map());
    }

    const rowMap = this.listPropertyCellMap.get(tableId);

    if (!rowMap.has(rowId)) {
      rowMap.set(rowId, new Map());
    }

    return rowMap.get(rowId);
  }

  private getColumnPropertyMap(cellAddress: ICellAddress): IPropertyCellMap {
    const { tableId, rowId, referenceTableColumnId } = cellAddress;

    const columnMap = this.getTableRowMap(tableId, rowId);

    if (!columnMap.has(referenceTableColumnId)) {
      columnMap.set(referenceTableColumnId, new Map());
    }

    return columnMap.get(referenceTableColumnId);
  }

  getListRows(listId: string): Observable<Query<IListRow[]>> {
    return this.getList(listId).pipe(
      filter(isFetchedQuery),
      switchMap(({ data }) => {
        const url = `gi/${encodeURIComponent(data._id)}/children?states=${ItemStates.Active}&states=${ItemStates.Archived}`;
        const isDeployed = this.projectItemId && data.metadata.deployedParents.includes(this.projectItemId);

        return this.listCacheService
          .getCachedData(data._id, url, this.listRowsMap)
          .pipe(tap((query) => this.filterDeployedItems(query, isDeployed)));
      }),
    );
  }

  private filterDeployedItems = (query: Query<IListRow[]>, isDeployed: boolean): void => {
    if (isFetchedQuery(query) && isDeployed) {
      query.data = query.data.filter((row) => row.metadata?.deployedParents.includes(this.projectItemId));
    }
  };

  private checkForEmptyLists(pendingListIds: Set<string>) {
    if (pendingListIds.size > 0) {
      pendingListIds.forEach((listId) => {
        this.listRowsMap.get(listId).next({ state: QueryStates.Empty });
      });
    }
  }

  private getList(listId: string): Observable<Query<IList>> {
    return this.listCacheService.getCachedData(listId, `gi/legacy/${listId}`, this.legacyIdToListMap);
  }

  fetchRowCells(listRowId: string, cellAddress: ICellAddress): Observable<Query<IListCell[]>> {
    const URL = `gi/${encodeURIComponent(listRowId)}/children?depth=2&states=${ItemStates.Active}&states=${ItemStates.Archived}`;
    return this.listCacheService.getCachedData<IListCell[]>(listRowId, URL, this.rowCellsMap).pipe(
      tap((query) => {
        if (isFetchedQuery(query)) {
          this.updateMappedListCell(cellAddress, query.data);
        } else {
          const cellsOnRowColumn = this.getColumnPropertyMap(cellAddress);
          cellsOnRowColumn.forEach((cellQuery) => cellQuery.next(query));
        }
      }),
    );
  }

  clearRowCells(tableId: string, rowId: string) {
    if (this.listPropertyCellMap.has(tableId) && this.listPropertyCellMap.get(tableId).has(rowId)) {
      const tableRowMap = this.listPropertyCellMap.get(tableId).get(rowId);
      this.setSelectedRow(tableId, rowId, false);

      tableRowMap.forEach((columnMap) =>
        columnMap.forEach((cellQuery) => cellQuery.next({ state: QueryStates.Empty })),
      );
    }
  }

  private updateMappedListCell(rowAddress: ICellAddress, cells: IListCell[]) {
    const cellsMap = new Map(
      cells.map((cell) => {
        const propertyId = cell._id.split('/').find((flake) => Flake.parse(flake).type === 'ListProperty');
        return [propertyId, cell];
      }),
    );

    const listRow = this.getColumnPropertyMap(rowAddress);

    listRow.forEach((cellQuery, propertyId) => {
      const data = cellsMap.get(propertyId) as IListCellWithoutAttachment;

      if (data) {
        cellQuery.next({ state: QueryStates.Fetched, data });
      } else {
        cellQuery.next({ state: QueryStates.Empty });
      }
    });
  }

  clearListPropertyCellMap() {
    this.listRowsMap.clear();
    this.rowCellsMap.clear();
    this.listPropertyCellMap.clear();
    this.legacyIdToListMap.clear();
    this.tableRowSelectionMap.clear();
  }
}
