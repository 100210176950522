"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateRangePlainFieldCloner = void 0;
class DateRangePlainFieldCloner {
    mergeField(templateField, formField) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                _id: formField._id || templateField._id,
                id: formField.id || templateField.id,
                description: templateField.description || '',
                isRequired: templateField.isRequired,
                kind: templateField.kind,
                model: templateField.model,
                state: formField.state || templateField.state,
                data: {
                    date: formField.data.date || templateField.data.date,
                    dateEnd: formField.data.dateEnd || templateField.data.dateEnd,
                },
                metadata: Object.assign(Object.assign({}, templateField.metadata), formField.metadata),
            };
        });
    }
}
exports.DateRangePlainFieldCloner = DateRangePlainFieldCloner;
