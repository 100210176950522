import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { User, Project, Company, UserTypes } from '@site-mate/dashpivot-shared-library';

import { HttpClientService } from 'app/shared/service/http-client.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(private readonly http: HttpClientService) {}

  getProject(id) {
    return this.http.get<Project>(`projects/${id}`);
  }

  getCompanyByProjectId(id) {
    return this.http.get<Company>(`projects/${id}/company`);
  }

  getCompanyProjects(companyId: string) {
    return this.http.get<Project[]>(`companies/${companyId}/projects`);
  }

  getUsers(projectId: string, userType?: UserTypes) {
    const userTypeQueryParam = userType ? `?userType=${userType}` : '';

    return this.http
      .get<User[]>(`projects/${projectId}/users${userTypeQueryParam}`)
      .pipe(map((users) => users.filter((user) => !user.isTestUser)));
  }

  addMember(
    projectId: string,
    email: string,
    optionalParams: {
      isTestUser?: boolean;
      isProjectController?: boolean;
      userType?: UserTypes;
    },
  ) {
    return this.http.post<User[]>(`projects/${projectId}/users`, {
      email,
      isTestUser: optionalParams.isTestUser,
      isProjectController: optionalParams.isProjectController,
      userType: optionalParams.userType,
    });
  }

  removeProjectUser(projectId: string, userId: string) {
    return this.http.remove<void>(`projects/${projectId}/users/${userId}`);
  }

  updateProjectUserPermissions(
    projectId: string,
    email: string,
    upgradeToProjectController: boolean,
    userType: UserTypes,
  ) {
    return this.http.post(`projects/${projectId}/users/permission`, {
      email,
      upgradeToProjectController,
      userType,
    });
  }
}
