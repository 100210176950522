import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import MappedTimeZones from '../user/shared/mappedTimezones.json';

dayjs.extend(utc);
dayjs.extend(tz);

export const timezones = Object.keys(MappedTimeZones);

// This prevents the dayjs library plugins from modifying the global object multiple times.
export { dayjs };
