"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormSignatureService = void 0;
const path_service_1 = require("./path.service");
const plan_type_limits_1 = require("../models/companies/plan-type-limits");
const complementary_logic_operators_enum_1 = require("../models/fields/complementary-logic-operators.enum");
const field_kinds_enum_1 = require("../models/fields/field-kinds.enum");
const logic_rules_model_1 = require("../models/fields/logic-rules.model");
const signature_field_actions_enum_1 = require("../models/fields/signature-field-actions.enum");
const signature_logic_actions_enum_1 = require("../models/fields/signature-logic-actions.enum");
const signature_logic_types_enum_1 = require("../models/fields/signature-logic-types.enum");
const signature_logic_user_types_1 = require("../models/fields/signature-logic-user-types");
const dashpivot_modules_enum_1 = require("../models/shared/dashpivot-modules.enum");
const form_roles_enum_1 = require("../models/users/form-roles.enum");
const user_permission_types_enum_1 = require("../models/users/user-permission-types.enum");
const user_signature_permission_levels_model_1 = require("../models/users/user-signature-permission-levels.model");
const user_types_model_1 = require("../models/users/user-types.model");
class FormSignatureService {
    constructor() {
        this.proPlanMaxRules = 3;
    }
    calculateWorkflowColumn(fields, template) {
        let calculation = fields.reduce((signed, item) => (this.isFieldSigned(item) ? signed + 1 : signed), 1);
        // Cap to maximum number of columns
        if (template && template.columns && calculation > template.columns.length) {
            calculation = template.columns.length;
        }
        return calculation;
    }
    isFieldSigned(field) {
        const { kind, signedOn, signatureUrl } = field;
        return kind === field_kinds_enum_1.FieldKinds.Signature && !!signedOn && !!signatureUrl;
    }
    signForm(form, index, { userId, fullName, signatureUrl, signedOn }, template) {
        const fields = form.items && index < form.items.length ? form.items : [];
        // if we're signing this form, set items to readonly
        if (userId) {
            fields.forEach((field, i) => {
                field.readOnly = i <= index ? true : field.readOnly;
            });
        }
        this.setSignatureOnField(fields[index], { userId, fullName, signatureUrl, signedOn });
        form.isInColumn = this.calculateWorkflowColumn(fields, template);
    }
    resetSignatures(form, columnIdToReset = '') {
        var _a;
        const FIRST_COLUMN = 1;
        const COLUMN_NUMBER_OFFSET = 1;
        const INDEX_NOT_FOUND = -1;
        const columnToResetIndex = (_a = form.columns) === null || _a === void 0 ? void 0 : _a.findIndex((column) => String(column._id) === columnIdToReset);
        const columnToReset = columnToResetIndex === INDEX_NOT_FOUND ? FIRST_COLUMN : columnToResetIndex + COLUMN_NUMBER_OFFSET;
        form.isInColumn = columnToReset;
        if (columnToReset === FIRST_COLUMN) {
            this.resetSignatureFields(form.items);
            return;
        }
        const approvalSignatureFieldIndex = this.getPreviousApprovalSignatureFieldIndex(form, columnToResetIndex);
        const indexAfterApprovalSignatureField = approvalSignatureFieldIndex + 1;
        const fieldsAfterApprovalSignature = form.items.slice(indexAfterApprovalSignatureField);
        this.resetSignatureFields(fieldsAfterApprovalSignature);
    }
    getPreviousApprovalSignatureFieldIndex(form, columnToResetIndex) {
        var _a;
        const previousColumnIndex = columnToResetIndex - 1;
        const approvalSignatureFields = (_a = form.items) === null || _a === void 0 ? void 0 : _a.filter((item) => item.kind === field_kinds_enum_1.FieldKinds.Signature);
        const approvalSignatureField = approvalSignatureFields[previousColumnIndex];
        const approvalSignatureFieldIndex = form.items.findIndex((item) => item._id === approvalSignatureField._id);
        return approvalSignatureFieldIndex;
    }
    resetSignatureFields(fieldsAfterApprovalSignature) {
        fieldsAfterApprovalSignature.forEach((field) => {
            field.readOnly = false;
            if (field.kind === field_kinds_enum_1.FieldKinds.Signature) {
                this.setSignatureOnField(field, {});
            }
            if (field.kind === field_kinds_enum_1.FieldKinds.SignatureArray) {
                field.signatures = [];
            }
            if (field.kind === field_kinds_enum_1.FieldKinds.Table || field.kind === field_kinds_enum_1.FieldKinds.PrefilledTable) {
                this.resetTableSignatures(field);
            }
        });
    }
    setSignatureOnField(field, { userId, fullName, signatureUrl, signedOn }) {
        field.userId = userId;
        field.fullName = fullName;
        field.signatureUrl = signatureUrl;
        field.signedOn = userId ? signedOn || new Date() : signedOn;
    }
    resetTableSignatures(field) {
        const rows = field.rows || [];
        rows.forEach((row) => {
            const columns = row.columns || [];
            columns.forEach((cell) => {
                if (cell.signatures) {
                    cell.signatures = [];
                }
            });
        });
    }
    validateResetWorkflowAction(form, columnIdToReset, user) {
        var _a;
        const signatureRules = this.getResetWorkflowRules(form, columnIdToReset);
        const teamPath = (_a = form.path) !== null && _a !== void 0 ? _a : '';
        if (!signatureRules.length) {
            return true;
        }
        const shouldApplyLogic = signatureRules.map((rules) => this.shouldApplyLogicForProPlan(rules));
        if (shouldApplyLogic.every((result) => result)) {
            const hasPermission = signatureRules.map((logic) => {
                var _a, _b;
                return this.canPerformFieldAction({
                    user,
                    teamPath,
                    fieldAction: signature_field_actions_enum_1.SignatureFieldActions.ResetWorkflow,
                    rules: logic,
                    formCreatorId: (_b = (_a = form.createdBy) === null || _a === void 0 ? void 0 : _a._id) !== null && _b !== void 0 ? _b : '',
                    fields: form.items,
                });
            });
            return hasPermission.every((permission) => permission);
        }
        return true;
    }
    canPerformFieldAction({ user, teamPath, fieldAction, rules, formCreatorId, fields, }) {
        const ruleCombinations = [signature_logic_actions_enum_1.SignatureLogicActions.ApprovalSignatureAndResetWorkflow, fieldAction];
        const matchingSignatureRules = rules === null || rules === void 0 ? void 0 : rules.filter((rule) => ruleCombinations.includes(rule.action));
        if (!(matchingSignatureRules === null || matchingSignatureRules === void 0 ? void 0 : matchingSignatureRules.length) || user.isGlobalAdmin) {
            return true;
        }
        const hasPermission = matchingSignatureRules.map((matchingSignatureRule) => this.evaluateSignatureRule(matchingSignatureRule, user, formCreatorId, teamPath, fields));
        return hasPermission.every((permission) => permission);
    }
    getUserType(user, teamPath) {
        if (user.isGlobalAdmin) {
            return user_permission_types_enum_1.UserPermissionTypes.GlobalAdmin;
        }
        const pathService = new path_service_1.PathService();
        const [companyId] = pathService.extractFolderIds(teamPath);
        const isVisitor = user.visitorOf && user.visitorOf.some((visitorId) => visitorId.toString() === companyId);
        return isVisitor ? user_types_model_1.UserTypes.Visitor : user_types_model_1.UserTypes.Contributor;
    }
    getPermissionType(user, path) {
        var _a, _b;
        const pathService = new path_service_1.PathService();
        const [companyId, projectId, teamId] = pathService.extractFolderIds(path);
        const permissionMapping = [
            { type: user_permission_types_enum_1.UserPermissionTypes.GlobalAdmin, condition: user.isGlobalAdmin },
            {
                type: user_permission_types_enum_1.UserPermissionTypes.CompanyController,
                condition: user.companyControllerOf.includes(companyId),
            },
            {
                type: user_permission_types_enum_1.UserPermissionTypes.CompanyMember,
                condition: (_a = user.companyMemberOf) === null || _a === void 0 ? void 0 : _a.includes(companyId),
            },
            {
                type: user_permission_types_enum_1.UserPermissionTypes.ProjectController,
                condition: user.projectControllerOf.includes(projectId),
            },
            {
                type: user_permission_types_enum_1.UserPermissionTypes.ProjectMember,
                condition: (_b = user.projectMemberOf) === null || _b === void 0 ? void 0 : _b.includes(projectId),
            },
            { type: user_permission_types_enum_1.UserPermissionTypes.TeamController, condition: user.teamControllerOf.includes(teamId) },
            { type: user_permission_types_enum_1.UserPermissionTypes.TeamMember, condition: user.standardUserOf.teams.includes(teamId) },
        ];
        const allowedPermission = permissionMapping.find((item) => item.condition);
        return allowedPermission === null || allowedPermission === void 0 ? void 0 : allowedPermission.type;
    }
    isPermissionSufficient(permissionLevels, userType, signatureRule) {
        if (!userType) {
            return false;
        }
        const userLevel = permissionLevels[userType];
        return signatureRule.values.some((targetLevel) => {
            if (typeof targetLevel === 'object') {
                return false;
            }
            if (targetLevel === signature_logic_user_types_1.SignatureLogicUserTypes.Any) {
                return true;
            }
            const targetPermissionLevel = permissionLevels[targetLevel];
            const logicOperator = signatureRule.operator;
            if (logicOperator === logic_rules_model_1.LogicOperators.Equals) {
                return userLevel === targetPermissionLevel || userLevel === user_signature_permission_levels_model_1.GlobalAdminPermissionLevel;
            }
            if (logicOperator === logic_rules_model_1.LogicOperators.EqualsToOrHigherThan) {
                return userLevel >= targetPermissionLevel;
            }
            return false;
        });
    }
    isPermissionSufficientForPersonType(signatureRule, currentUser, fields = [], formCreatorId = '') {
        const userId = currentUser._id ? currentUser._id.toString() : '';
        const isCreatorPermission = signatureRule.values.some((value) => value === form_roles_enum_1.FormRoles.Creator);
        if (isCreatorPermission && signatureRule.operator === logic_rules_model_1.LogicOperators.Equals) {
            if (userId.toString() === formCreatorId.toString()) {
                return true;
            }
        }
        const personSignatureRuleValues = signatureRule.values;
        const isUsersFormRoleSufficient = this.checkIfUsersFormRoleAllowsSigning(personSignatureRuleValues, currentUser, formCreatorId);
        const isUserSelectedInPersonField = currentUser.sitemateUserId
            ? this.checkIfUserIsSelectedInPersonField(personSignatureRuleValues, fields, currentUser.sitemateUserId)
            : false;
        // TODO in DPW-7109 is implement the checkIfUserSelected function
        // const isUserSelected = checkIfUserSelected();
        return isUsersFormRoleSufficient || isUserSelectedInPersonField;
    }
    checkIfUsersFormRoleAllowsSigning(values, currentUser, formCreatorId) {
        const userId = currentUser._id ? currentUser._id.toString() : '';
        const valuesWithFormRole = values.filter((value) => typeof value === 'object' && 'formRole' in value);
        const userIsCreator = valuesWithFormRole.some((value) => value.formRole === form_roles_enum_1.FormRoles.Creator && userId.toString() === formCreatorId.toString());
        return userIsCreator;
    }
    checkIfUserIsSelectedInPersonField(values, fields, sitemateUserId) {
        const personFieldIds = values.map((value) => {
            if (typeof value === 'object' && 'fieldId' in value) {
                return value.fieldId;
            }
            return null;
        });
        return fields.some((field) => {
            const fieldId = field._id ? field._id.toString() : '';
            const isFieldSelectedInValues = personFieldIds.includes(fieldId);
            const isUserSelectedInPersonField = field.kind === field_kinds_enum_1.FieldKinds.Person && field.data.userIds && field.data.userIds.includes(sitemateUserId);
            return isUserSelectedInPersonField && isFieldSelectedInValues;
        });
    }
    /**
     * @deprecated use shouldApplyLogicForUpgradablePlan instead
     */
    shouldApplyLogicForProPlan(signatureRules) {
        let shouldApplyLogic = true;
        signatureRules.forEach((rule) => {
            var _a, _b;
            if ((rule === null || rule === void 0 ? void 0 : rule.complementaryRules) && ((_a = rule.complementaryRules) === null || _a === void 0 ? void 0 : _a.length) > this.proPlanMaxRules) {
                shouldApplyLogic = false;
            }
            (_b = rule.complementaryRules) === null || _b === void 0 ? void 0 : _b.forEach((nestedRule) => {
                var _a;
                if ((nestedRule === null || nestedRule === void 0 ? void 0 : nestedRule.complementaryRules) &&
                    ((_a = nestedRule.complementaryRules) === null || _a === void 0 ? void 0 : _a.length) > this.proPlanMaxRules - 1) {
                    shouldApplyLogic = false;
                }
            });
        });
        return shouldApplyLogic;
    }
    shouldApplyLogicForUpgradablePlan(signatureRules, upgradablePlanType) {
        const maxRules = plan_type_limits_1.PlanTypeLimits[dashpivot_modules_enum_1.DashpivotModules.Templates].complementaryLogic[upgradablePlanType];
        let shouldApplyLogic = true;
        signatureRules.forEach((rule) => {
            var _a, _b;
            if ((rule === null || rule === void 0 ? void 0 : rule.complementaryRules) && ((_a = rule.complementaryRules) === null || _a === void 0 ? void 0 : _a.length) > maxRules) {
                shouldApplyLogic = false;
            }
            (_b = rule.complementaryRules) === null || _b === void 0 ? void 0 : _b.forEach((nestedRule) => {
                var _a;
                if ((nestedRule === null || nestedRule === void 0 ? void 0 : nestedRule.complementaryRules) && ((_a = nestedRule.complementaryRules) === null || _a === void 0 ? void 0 : _a.length) > maxRules - 1) {
                    shouldApplyLogic = false;
                }
            });
        });
        return shouldApplyLogic;
    }
    rulesExceedMax(signatureRules, maxRules) {
        const maxNestedRules = maxRules - 1;
        return signatureRules === null || signatureRules === void 0 ? void 0 : signatureRules.some((rule) => {
            var _a, _b;
            const numberOfComplementaryRules = ((_a = rule.complementaryRules) === null || _a === void 0 ? void 0 : _a.length) || 0;
            const exceedsMaxRules = numberOfComplementaryRules > maxRules;
            const exceedsMaxNestedRules = (_b = rule.complementaryRules) === null || _b === void 0 ? void 0 : _b.some((complementaryRule) => {
                var _a;
                const numberOfNestedComplementaryRules = ((_a = complementaryRule.complementaryRules) === null || _a === void 0 ? void 0 : _a.length) || 0;
                return numberOfNestedComplementaryRules > maxNestedRules;
            });
            return exceedsMaxRules || Boolean(exceedsMaxNestedRules);
        });
    }
    getResetWorkflowRules(form, columnIdToReset) {
        var _a;
        const columnToResetIndex = (_a = form.columns) === null || _a === void 0 ? void 0 : _a.findIndex((column) => String(column._id) === columnIdToReset);
        const resetSignatureRules = this.getAllRulesToReset(form, columnToResetIndex);
        return resetSignatureRules.filter((resetRules) => resetRules.length > 0);
    }
    getAllRulesToReset(form, columnToResetIndex) {
        /**
         * When workflow is in column 3 (for example) can reset signature from column 2 or 1.
         * The offset is to get the correct index of the column in the form.columns array.
         * -1 to get the index of the last signature field and -1 to correct from position to index.
         */
        const columnOffset = 2;
        const currentSignatureIndex = form.isInColumn ? form.isInColumn - columnOffset : columnToResetIndex;
        return Array.from({ length: currentSignatureIndex - columnToResetIndex + 1 }, (_, index) => this.getResetSignatureRulesForColumn(form, currentSignatureIndex - index));
    }
    getResetSignatureRulesForColumn(form, columnIndex) {
        var _a, _b;
        const approvalSignatureFields = (_a = form.items) === null || _a === void 0 ? void 0 : _a.filter((item) => item.kind === field_kinds_enum_1.FieldKinds.Signature);
        const signatureRules = ((_b = approvalSignatureFields[columnIndex]) === null || _b === void 0 ? void 0 : _b.signatureRules) || [];
        const resetWorkflowActions = [
            signature_logic_actions_enum_1.SignatureLogicActions.ResetWorkflow,
            signature_logic_actions_enum_1.SignatureLogicActions.ApprovalSignatureAndResetWorkflow,
        ];
        return signatureRules.filter((rule) => resetWorkflowActions.includes(rule.action));
    }
    evaluateSignatureRule(rule, user, formCreatorId, teamPath, fields = []) {
        const { complementaryOperator, complementaryRules } = rule;
        const currentRuleResult = this.evaluateBaseRule(rule, user, formCreatorId, teamPath, fields);
        if (complementaryOperator && (complementaryRules === null || complementaryRules === void 0 ? void 0 : complementaryRules.length)) {
            const complementaryRuleResults = complementaryRules.map((complementaryRule) => this.evaluateSignatureRule(complementaryRule, user, formCreatorId, teamPath, fields));
            return complementaryOperator === complementary_logic_operators_enum_1.ComplementaryLogicOperators.AND
                ? currentRuleResult && complementaryRuleResults.every((result) => result)
                : currentRuleResult || complementaryRuleResults.some((result) => result);
        }
        return currentRuleResult;
    }
    evaluateBaseRule(rule, user, formCreatorId, teamPath, fields = []) {
        const { type } = rule;
        if (type === signature_logic_types_enum_1.SignatureLogicTypes.User) {
            const userType = this.getUserType(user, teamPath);
            return this.isPermissionSufficient(user_signature_permission_levels_model_1.UserTypePermissionLevels, userType, rule);
        }
        if (type === signature_logic_types_enum_1.SignatureLogicTypes.Permission) {
            const permissionType = this.getPermissionType(user, teamPath);
            return this.isPermissionSufficient(user_signature_permission_levels_model_1.UserPermissionTypeLevels, permissionType, rule);
        }
        if (type === signature_logic_types_enum_1.SignatureLogicTypes.Person) {
            return this.isPermissionSufficientForPersonType(rule, user, fields, formCreatorId);
        }
        return false;
    }
}
exports.FormSignatureService = FormSignatureService;
