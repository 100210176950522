"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignatureFormFieldValidator = void 0;
const lodash_1 = require("lodash");
const field_kinds_enum_1 = require("../models/fields/field-kinds.enum");
class SignatureFormFieldValidator {
    fieldSigned(field) {
        const { kind, signedOn, userId, fullName, signatureUrl } = field;
        return kind === field_kinds_enum_1.FieldKinds.Signature && !!signedOn && !!signatureUrl && !!userId && !!fullName;
    }
    hasAnyData(field) {
        return this.hasCompleteData(field);
    }
    hasCompleteData(field) {
        return (this.fieldSigned(field) || (field.kind === field_kinds_enum_1.FieldKinds.SignatureArray && !(0, lodash_1.isEmpty)(field.signatures)));
    }
    isValid(field) {
        if (field.isRequired && !this.hasCompleteData(field)) {
            return false;
        }
        return true;
    }
    invalidDescription(field) {
        if (field.isRequired && !this.hasCompleteData(field)) {
            return '';
        }
        if (!this.isValid(field)) {
            return 'invalid';
        }
        return '';
    }
}
exports.SignatureFormFieldValidator = SignatureFormFieldValidator;
