import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IWorkspaceCreate } from '@site-mate/dashpivot-shared-library';

import { ICompanyHomeSettings } from 'app/home/company-home-settings.model';
import { CompanyWeb } from 'app/shared/model/company.model';
import { ProjectWeb } from 'app/shared/model/project.model';
import { TeamWeb } from 'app/shared/model/team.model';
import { HttpClientService } from 'app/shared/service/http-client.service';

@Injectable()
export class OrgService {
  constructor(private readonly http: HttpClientService) {}

  addProject(companyId: string, name: string): Observable<ProjectWeb> {
    return this.http.post(`companies/${companyId}/projects`, { name });
  }

  updateProject(id: string, name: string): Observable<ProjectWeb> {
    return this.http.put(`projects/${id}`, { name });
  }

  removeProject(projectId: string): Observable<void> {
    return this.http.remove(`projects/${projectId}`);
  }

  addWorkspace(workspaceCreation: IWorkspaceCreate): Observable<CompanyWeb> {
    return this.http.post(`v1/companies`, workspaceCreation);
  }

  addWorkspaceOrgController(workspaceCreation: IWorkspaceCreate, companyId: string): Observable<CompanyWeb> {
    return this.http.post(`v1/companies/${companyId}/workspaces`, workspaceCreation);
  }

  updateCompany({
    id,
    name,
    accountId,
    businessAttributes,
    domains,
    jointVentureDomains,
    jointVenture,
    smcDemo,
  }: Partial<ICompanyHomeSettings>): Observable<CompanyWeb> {
    return this.http.put(`companies/${id}`, {
      name,
      accountId,
      businessAttributes,
      domains,
      jointVenture,
      jointVentureDomains,
      smcDemo,
    });
  }

  removeCompany(id: string): Observable<void> {
    return this.http.remove(`companies/${id}`);
  }

  addTeam(projectId: string, name: string): Observable<TeamWeb> {
    return this.http.post(`v1/projects/${projectId}/teams`, { name });
  }

  updateTeam(id: string, name: string): Observable<TeamWeb> {
    return this.http.put(`teams/${id}`, { name });
  }

  removeTeam(id: string): Observable<void> {
    return this.http.remove(`teams/${id}`);
  }
}
