import { Component, Input } from '@angular/core';

@Component({
  selector: 'cc-panel-with-links',
  templateUrl: './panel-with-links.component.html',
  styleUrls: ['./panel-with-links.component.scss'],
})
export class PanelWithLinksComponent {
  @Input() displayInfoPanel = false;
  @Input() displayTabsPanel = false;
}
