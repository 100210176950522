<ul class="nav">
  <li class="nav-item float-left sm-flex">
    <a
      *ngIf="canDelete() && !hideDeleteButton"
      [class.first-action-link]="canDelete() && !hideDeleteButton"
      class="nav-link delete-link action-button icon"
      (click)="onRemove()"
      ngbPopover="Delete"
      placement="bottom"
      triggers="mouseenter:mouseleave"
    >
      <i class="cc-icon cc-icon-delete"> </i>
    </a>
    <a
      class="nav-link other-link action-button icon"
      [class.first-action-link]="!canDelete() || hideDeleteButton"
      (click)="fullScreen.emit()"
      ngbPopover="Fullscreen"
      placement="bottom"
      triggers="mouseenter:mouseleave"
    >
      <i class="cc-icon cc-icon-expand"></i>
    </a>
    <a
      class="nav-link action-button icon"
      (click)="toggleMagnify()"
      *ngIf="!appUtilService.isMobile && !photo.isVideo"
      [ngClass]="{ magnify: magnify }"
      ngbPopover="Magnify"
      placement="bottom"
      triggers="mouseenter:mouseleave"
      data-test="magnify-button"
    >
      <i class="cc-icon cc-icon-magnify-plus"></i>
    </a>
    <a
      class="nav-link download-link icon"
      (click)="onExportOriginal()"
      target="_blank"
      ngbPopover="Download Original"
      placement="bottom"
      triggers="mouseenter:mouseleave"
    >
      <i class="cc-icon cc-icon-download"></i>
    </a>
    <a
      class="nav-link export-link icon"
      (click)="onExportPDF()"
      ngbPopover="Download PDF"
      placement="bottom"
      triggers="mouseenter:mouseleave"
    >
      <i *ngIf="downloadingPDF" class="cc-icon cc-icon-spinner"></i>
      <i class="cc-icon cc-icon-download-pdf sm-text-dark-3"></i>
    </a>
  </li>
  <li class="nav-item ml-auto">
    <a class="nav-link modal-close-button action-button icon" (click)="close.emit()">
      <i class="cc-icon cc-icon-close"></i
    ></a>
  </li>
</ul>
