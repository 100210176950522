"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateRangePlainFormFieldValidator = void 0;
const date_plain_util_1 = require("../utils/date-plain-util");
class DateRangePlainFormFieldValidator {
    hasAnyData(field) {
        var _a, _b;
        return this.isValidDate((_a = field.data) === null || _a === void 0 ? void 0 : _a.date) || this.isValidDate((_b = field.data) === null || _b === void 0 ? void 0 : _b.dateEnd);
    }
    hasCompleteData(field) {
        var _a, _b;
        return this.isValidDate((_a = field.data) === null || _a === void 0 ? void 0 : _a.date) && this.isValidDate((_b = field.data) === null || _b === void 0 ? void 0 : _b.dateEnd);
    }
    isValid(field) {
        var _a, _b;
        if (field.isRequired && !this.hasCompleteData(field)) {
            return false;
        }
        return this.isDateRangeValid((_a = field.data) === null || _a === void 0 ? void 0 : _a.date, (_b = field.data) === null || _b === void 0 ? void 0 : _b.dateEnd);
    }
    invalidDescription(field) {
        if (field.isRequired && !this.hasCompleteData(field)) {
            return '';
        }
        if (field._invalid) {
            return 'Start date/time should be before end date/time';
        }
        return '';
    }
    isDateRangeValid(date, dateEnd) {
        if (date && dateEnd) {
            return date_plain_util_1.DatePlainUtil.isGreaterThan(dateEnd, date);
        }
        return true;
    }
    isValidDate(value) {
        return date_plain_util_1.DatePlainUtil.isValidDate(value);
    }
}
exports.DateRangePlainFormFieldValidator = DateRangePlainFormFieldValidator;
