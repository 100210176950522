/* eslint-disable max-lines */
import { EventEmitter } from '@angular/core';
import { ObjectId } from 'bson';
import { cloneDeep } from 'lodash-es';
import { of, Observable } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

import {
  EventTypes,
  FieldKinds,
  TableCellData,
  TableCellKinds,
  TableRow,
} from '@site-mate/dashpivot-shared-library';

import { FieldWeb } from 'app/shared/model/item.model';
import { ConfirmService } from 'app/shared/service/confirm.service';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { EventsService } from 'app/shared/service/events/events.service';

import { ListPropertyFormService } from '../edit-cells/list-property-form.service';
import { TableColumnService } from '../service/table-column-service';

type ActionFlag = 'moveUp' | 'moveDown' | 'clone' | 'clear' | 'delete';

export class FormTableActionResolver {
  canClearAll: boolean;
  canDeleteAll: boolean;
  rowActionFlags: Set<ActionFlag>[];
  public readonly onTableAction = new EventEmitter<{ isDone?: boolean }>();
  private readonly isPrefilledTable: boolean = false;

  constructor(
    private readonly model: FieldWeb,
    private readonly eventsService: EventsService,
    private readonly confirmService: ConfirmService,
    private readonly tableColumnService: TableColumnService,
    private readonly unsubscribe: Observable<void>,
    private readonly errorHandler: ErrorHandler,
    private readonly listPropertyFormService: ListPropertyFormService,
  ) {
    this.isPrefilledTable = this.model.kind === FieldKinds.PrefilledTable;
    this.rowActionFlags = [];

    this.listenToServiceEvent(
      this.tableColumnService.addRowEvent.pipe(switchMap((tableId) => of({ tableId }))),
      () => {
        this.userAddRow();
      },
    );

    this.listenToServiceEvent(this.tableColumnService.onBatchAddRows, ({ newRows, isDone }) => {
      this.batchAddRows(newRows);
      this.onTableAction.emit({ isDone });
    });

    this.listenToServiceEvent(this.tableColumnService.onBatchUpdateRows, ({ updates, isDone }) => {
      this.batchUpdateRows(updates);
      this.onTableAction.emit({ isDone });
    });
  }

  private listenToServiceEvent<T extends { tableId: string; isDone?: boolean }>(
    event: Observable<T>,
    callback: (params: T) => void,
  ) {
    return event
      .pipe(
        filter(({ tableId }) => tableId === this.model.id),
        // Do not remove the unsubscribe as it creates a memory leak and issues
        takeUntil(this.unsubscribe),
      )
      .subscribe(
        (args) => {
          callback(args);
        },
        (err) => {
          this.errorHandler.handle(err);
        },
      );
  }

  updateActionFlags() {
    this.rowActionFlags = Array.from({ length: this.model.rows.length }, () => new Set());
    this.model.rows.forEach((_row, index) => this.updateActionFlagsForRow(index));
  }

  updateActionFlagsForRow(rowIndex: number) {
    if (!this.isPrefilledTable) {
      this.updateFlagsForDefaultTableRow(rowIndex);
    }

    this.updateClearFlagForRow(rowIndex);
    this.updateClearAllFlag(rowIndex);
  }

  private updateClearAllFlag(rowIndex: number) {
    this.canClearAll = this.canClearAll || this.rowActionFlags[rowIndex].has('clear');
  }

  private updateFlagsForDefaultTableRow(rowIndex: number) {
    this.updateDeleteFlagForRow(rowIndex);
    this.updateMoveUpFlagForRow(rowIndex);
    this.updateMoveDownFlagForRow(rowIndex);
    this.updateCloneFlagForRow(rowIndex);

    this.updateDeleteAllFlag();
  }

  private updateDeleteAllFlag() {
    this.canDeleteAll = !this.isPrefilledTable && this.model.rows.length > 1;
  }

  private updateMoveDownFlagForRow(rowIndex: number) {
    this.toggleFlagForRow('moveDown', rowIndex, rowIndex < this.model.rows.length - 1);
  }

  private updateMoveUpFlagForRow(rowIndex: number) {
    this.toggleFlagForRow('moveUp', rowIndex, rowIndex > 0);
  }

  private updateCloneFlagForRow(rowIndex: number) {
    this.toggleFlagForRow('clone', rowIndex, this.model.rows.length >= 1);
  }

  private updateDeleteFlagForRow(rowIndex: number) {
    this.toggleFlagForRow('delete', rowIndex, this.model.rows.length > 1);
  }

  private updateClearFlagForRow(rowIndex: number) {
    const rowCells = this.model.rows[rowIndex].columns;
    this.toggleFlagForRow('clear', rowIndex, rowCells.some(this.isTableCellNonEmpty));
  }

  public updateClearFlagBasedOnCell(cell: TableCellData, rowIndex: number) {
    if (!cell || rowIndex === undefined) {
      return;
    }

    if (this.isTableCellNonEmpty(cell)) {
      this.rowActionFlags[rowIndex].add('clear');
      this.canClearAll = true;
    } else {
      this.rowActionFlags[rowIndex].delete('clear');
      this.canClearAll = this.rowActionFlags.some((actionFlags) => actionFlags.has('clear'));
    }
  }

  public userAddRow() {
    this.addRow();
    this.eventsService.trackEvent(EventTypes.FormTableDefaultRowAdded);
    this.onTableAction.emit();
  }

  batchAddRows(rows: TableRow[]) {
    rows
      .map((row) => ({
        ...row,
        columns: row.columns.map((column, index) => ({
          ...column,
          kind: this.model.columns[index].kind,
        })),
      }))
      .forEach((row) => this.addRow(row));
  }

  addRow(row?: TableRow) {
    this.rowActionFlags.push(new Set());
    this.model.rows.push(row || this.generateNewRow());
    const lastRow = this.model.rows.length - 1;
    this.updateActionFlagsForRow(lastRow);

    if (this.model.rows.length >= 1) {
      this.rowActionFlags[this.model.rows.length - 1].add('clone');
    }

    if (this.model.rows.length > 1) {
      this.rowActionFlags[this.model.rows.length - 2].add('moveDown');
      this.rowActionFlags[this.model.rows.length - 2].add('delete');
    }
  }

  batchUpdateRows(rowUpdates: Array<{ rowIndex: number; newData: TableRow }>) {
    rowUpdates.forEach((rowUpdate) => this.updateRow(rowUpdate));
  }

  updateRow({ rowIndex, newData }: { rowIndex: number; newData: TableRow }) {
    this.model.rows[rowIndex] = newData;
    this.updateActionFlagsForRow(rowIndex);
  }

  addRowMidway(rowIndex: number) {
    this.model.rows.splice(rowIndex, 0, this.generateNewRow());
    this.rowActionFlags.splice(rowIndex, 0, new Set());
    this.updateActionFlagsForRow(rowIndex);

    if (rowIndex > 0) {
      this.rowActionFlags[rowIndex - 1].add('moveDown');
      this.rowActionFlags[rowIndex - 1].add('clone');
      this.rowActionFlags[rowIndex - 1].add('delete');
    }
    this.rowActionFlags[rowIndex + 1].add('moveUp');
    this.rowActionFlags[rowIndex + 1].add('clone');
    this.rowActionFlags[rowIndex + 1].add('delete');

    this.eventsService.trackEvent(EventTypes.FormTableRowMidwayAdded);
    this.onTableAction.emit();
  }

  private generateNewRow() {
    const _id = new ObjectId().toHexString();
    return {
      _id,
      id: _id,
      columns: this.model.columns.map((column) => ({
        value: null,
        content: cloneDeep(column.content ?? null),
        headerColumnId: column.id,
        kind: column.kind,
        ...(column.kind === TableCellKinds.Photo && { photos: [] }),
      })),
    };
  }

  private isTableCellNonEmpty({ value, photos, attachments, signatures, item }: TableCellData) {
    const emptyFormulaValue = '0.00';
    const cellHasValue = value || signatures?.length || photos?.length || attachments?.length || item;

    return cellHasValue && value !== emptyFormulaValue;
  }

  public async clearAllRows() {
    if (!this.canClearAll) {
      return;
    }
    try {
      await this.confirmService.confirmDelete('formTableClearAllRows', { confirmButtonText: 'Clear' });
    } catch {
      return;
    }
    this.model.rows = this.model.rows.map((row) => this.emptyTableRow(row));

    const eventType = this.isPrefilledTable
      ? EventTypes.FormTablePrefilledAllRowsCleared
      : EventTypes.FormTableDefaultAllRowsCleared;

    this.canClearAll = false;
    this.rowActionFlags.forEach((actionFlags) => actionFlags.delete('clear'));
    this.onTableAction.emit();
    this.eventsService.trackEvent(eventType);
  }

  public async clearRow(index: number) {
    if (!this.rowActionFlags[index].has('clear')) {
      return;
    }

    try {
      await this.confirmService.confirmDelete('formTableClearSingleRow', { confirmButtonText: 'Clear' });
    } catch {
      return;
    }

    this.model.rows[index] = this.emptyTableRow(this.model.rows[index]);

    this.rowActionFlags[index].delete('clear');
    this.canClearAll = this.rowActionFlags.some((actionFlags) => actionFlags.has('clear'));

    this.onTableAction.emit();

    const eventType = this.isPrefilledTable
      ? EventTypes.FormTablePrefilledRowCleared
      : EventTypes.FormTableDefaultRowCleared;

    this.eventsService.trackEvent(eventType);
  }

  private emptyTableRow = ({ columns, ...row }: TableRow) => {
    this.listPropertyFormService.clearRowCells(this.model._id, row._id);

    return {
      ...row,
      columns: columns.map(this.emptyTableCell),
    };
  };

  private emptyTableCell = (cellData: TableCellData) => {
    const emptyCell = {
      ...cellData,
      ...(cellData.value && { value: null }),
      ...(cellData.photos && { photos: [] }),
      ...(cellData.signatures && { signatures: [] }),
      ...(cellData.attachments && { attachments: [] }),
      ...(cellData._time && { _time: null }),
      ...(cellData._date && { _date: null }),
      ...(cellData.item && { item: null }),
    };

    const cellKindMap = new Map<Partial<TableCellKinds>, (cellData: TableCellData) => void>([
      [TableCellKinds.ListProperty, this.clearListPropertyCell],
    ]);

    if (cellKindMap.has(emptyCell.kind)) {
      cellKindMap.get(emptyCell.kind)(emptyCell);
    }

    return emptyCell;
  };

  private clearListPropertyCell = (cellData: TableCellData) => {
    delete cellData.item;
  };

  public async deleteAllRows() {
    if (!this.canDeleteAll) {
      return;
    }

    try {
      await this.confirmService.confirmDelete('formTableDeleteAllRows');
    } catch {
      return;
    }

    this.model.rows.splice(0);

    this.canClearAll = false;
    this.rowActionFlags = [new Set()];
    this.canDeleteAll = false;

    this.addRow();
    this.onTableAction.emit();
    this.eventsService.trackEvent(EventTypes.FormTableDefaultAllRowsDeleted);
  }

  public async removeRow(rowIndex: number) {
    if (!this.rowActionFlags[rowIndex].has('delete')) {
      return;
    }

    try {
      await this.confirmService.confirmDelete('deleteRow');
    } catch {
      return;
    }

    this.model.rows.splice(rowIndex, 1);
    this.updateActionFlags();

    this.onTableAction.emit();
    this.eventsService.trackEvent(EventTypes.FormTableDefaultRowDeleted);
  }

  public moveRowUp(rowIndex: number) {
    if (!this.rowActionFlags[rowIndex].has('moveUp')) {
      return;
    }

    const rowMovingUp = this.model.rows.splice(rowIndex, 1)[0];
    const indexOfPreviousRow = rowIndex - 1;
    this.model.rows.splice(indexOfPreviousRow, 0, rowMovingUp);

    this.swapRowsActionFlags(rowIndex, indexOfPreviousRow);

    this.onTableAction.emit();
    this.eventsService.trackEvent(EventTypes.FormTableDefaultRowRearranged);
  }

  public moveRowDown(rowIndex: number) {
    if (!this.rowActionFlags[rowIndex].has('moveDown')) {
      return;
    }

    const rowMovingDown = this.model.rows.splice(rowIndex, 1)[0];
    const indexOfNextRow = rowIndex + 1;
    this.model.rows.splice(indexOfNextRow, 0, rowMovingDown);

    this.swapRowsActionFlags(rowIndex, indexOfNextRow);

    this.onTableAction.emit();
    this.eventsService.trackEvent(EventTypes.FormTableDefaultRowRearranged);
  }

  cloneRow(rowIndex: number) {
    if (!this.rowActionFlags[rowIndex].has('clone')) {
      return;
    }

    this.updateClonedRowProperties(rowIndex);

    this.rowActionFlags.splice(rowIndex, 0, new Set());
    this.updateActionFlagsForRow(rowIndex);

    this.rowActionFlags[rowIndex + 1].add('clone');
    this.rowActionFlags[rowIndex + 1].add('delete');

    if (rowIndex > 0) {
      this.rowActionFlags[rowIndex - 1].add('moveDown');
    } else {
      this.rowActionFlags[rowIndex + 1].add('moveUp');
    }

    this.onTableAction.emit();
    this.eventsService.trackEvent(EventTypes.FormTableDefaultRowCloned);
  }

  private updateClonedRowProperties(rowIndex: number) {
    const currentRow = this.model.rows[rowIndex];
    const clonedRow = cloneDeep(currentRow);
    const indexOfNextRow = rowIndex + 1;

    clonedRow.id = new ObjectId().toHexString();
    clonedRow._id = clonedRow.id;

    clonedRow.columns.forEach((clonedColumn) => {
      clonedColumn.id = new ObjectId().toHexString();
      clonedColumn._id = clonedColumn.id;

      if (
        clonedColumn.kind === TableCellKinds.Signature ||
        clonedColumn.kind === TableCellKinds.ManualSignature
      ) {
        clonedColumn.signatures = undefined;
      }
    });

    this.model.rows.splice(indexOfNextRow, 0, clonedRow);
  }

  private swapRowsActionFlags(firstRow: number, secondRow: number) {
    if (this.rowActionFlags[firstRow].has('clear') && this.rowActionFlags[secondRow].has('clear')) {
      return;
    }

    if (this.rowActionFlags[firstRow].has('clear')) {
      this.rowActionFlags[firstRow].delete('clear');
      this.rowActionFlags[secondRow].add('clear');
    } else {
      this.rowActionFlags[secondRow].delete('clear');
      this.rowActionFlags[firstRow].add('clear');
    }
  }

  private toggleFlagForRow(flag: ActionFlag, rowIndex: number, conditionForAdding: boolean) {
    if (conditionForAdding) {
      this.rowActionFlags[rowIndex].add(flag);
    } else {
      this.rowActionFlags[rowIndex].delete(flag);
    }
  }
}
