import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';

import { EventTypes } from '@site-mate/dashpivot-shared-library';

import { EventsService } from 'app/shared/service/events/events.service';
import { TeamService } from 'app/shared/service/team.service';

import { MapModalService } from './map-modal.service';

type MapModalData = {
  disableClose: boolean;
  GPSLocation: { lat: number; lng: number } | null;
  fullName: string;
  date: string;
  event: {
    type: EventTypes;
    metadata?: Record<string, unknown>;
  };
};

@Component({
  selector: 'cc-map-modal',
  templateUrl: './map-modal.component.html',
  styleUrls: ['./map-modal.component.scss'],
})
export class MapModalComponent {
  private readonly defaultMapSize = { height: 600, width: 940 };
  useInteractiveMap = false;
  staticMapUrl: string | null = null;
  mapImageIsLoaded: boolean = false;

  constructor(
    public dialogRef: DialogRef,
    private readonly eventsService: EventsService,
    private readonly mapModalService: MapModalService,
    private readonly teamService: TeamService,
    @Inject(DIALOG_DATA)
    public data: MapModalData,
  ) {}

  ngOnInit() {
    const folder = this.teamService.getCurrentTeam();
    const companyId = folder.isCompany ? folder._id : folder.companyId;

    this.mapModalService
      .getStaticMapUrl(companyId, this.defaultMapSize, {
        lat: this.data.GPSLocation.lat.toString(),
        lng: this.data.GPSLocation.lng.toString(),
      })
      .subscribe((url) => {
        this.staticMapUrl = url;
      });
  }

  onImageLoaded() {
    this.mapImageIsLoaded = true;
  }

  closeModal() {
    this.dialogRef.close();
  }

  switchToInteractiveMap() {
    this.useInteractiveMap = true;
  }

  logGoogleMapsUsage() {
    const { type, metadata } = this.data.event;
    this.eventsService.trackEvent(type, metadata);
  }
}
