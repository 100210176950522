"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormValidationService = void 0;
const logic_rules_service_1 = require("./logic-rules.service");
const field_kinds_enum_1 = require("../models/fields/field-kinds.enum");
const table_cell_kinds_enum_1 = require("../models/tables/table-cell-kinds.enum");
class FormValidationService {
    constructor() {
        this.logicRulesService = new logic_rules_service_1.LogicRulesService();
    }
    isWorkflow(form) {
        return form.columns.length > 0;
    }
    allItemsAreValid(form) {
        const hasInvalidFields = form.items.some(this.isInvalidField.bind(this));
        const hasHiddenFormulaValues = form.items.some(this.isAHiddenPrefilledTableWithFormulaValues.bind(this));
        const hasHiddenTableRows = form.items.some(this.isAHiddenDefaultTableWithRows.bind(this));
        return !hasInvalidFields && !hasHiddenFormulaValues && !hasHiddenTableRows;
    }
    isValid(form) {
        if (this.isWorkflow(form)) {
            // workflow form:
            // - If in last column: everything needs to be valid
            const isInLastColumn = form.isInColumn === form.columns.length;
            if (isInLastColumn) {
                return this.allItemsAreValid(form);
            }
            // else can save workflow forms
            return true;
        }
        // timeline form: all field need to be valid
        return this.allItemsAreValid(form);
    }
    isValidForSigning(form, itemId) {
        const itemIndex = form.items.findIndex((item) => item.id === itemId);
        const hasInvalidFields = form.items.some((item, index) => this.isInvalidField(item) && index < itemIndex);
        const hasHiddenFormulas = form.items.some((item, index, items) => this.isAHiddenPrefilledTableWithFormulaValues(item, index, items) && index < itemIndex);
        const hasHiddenTableRows = form.items.some((item, index, items) => this.isAHiddenDefaultTableWithRows(item, index, items) && index < itemIndex);
        // all prior fields need to be valid to Sign
        return !hasInvalidFields && !hasHiddenFormulas && !hasHiddenTableRows;
    }
    isInvalidField(field) {
        return !!field._invalid;
    }
    isAHiddenDefaultTableWithRows(table, _index, fields) {
        if (table.kind === field_kinds_enum_1.FieldKinds.Table && this.logicRulesService.isDependentField(table, fields)) {
            const [parentField] = fields.filter((entry) => entry.id === table.dependsOn.fieldId);
            const isHidden = !this.logicRulesService.evaluate(parentField).some((rule) => rule.isTrue);
            return isHidden && !!table.rows.length;
        }
        return false;
    }
    isAHiddenPrefilledTableWithFormulaValues(table, _index, fields) {
        if (table.kind === field_kinds_enum_1.FieldKinds.PrefilledTable && this.logicRulesService.isDependentField(table, fields)) {
            const [parentField] = fields.filter((entry) => entry.id === table.dependsOn.fieldId);
            const isHidden = !this.logicRulesService.evaluate(parentField).some((rule) => rule.isTrue);
            return (isHidden &&
                table.rows.some((row) => {
                    const rowWithFormulaColumnWithValue = row.columns.some((column) => column.kind === table_cell_kinds_enum_1.TableCellKinds.Formula && !!column.value);
                    return rowWithFormulaColumnWithValue;
                }));
        }
        return false;
    }
}
exports.FormValidationService = FormValidationService;
