import { defaultTo, uniq } from 'lodash-es';

export class MimeMapper {
  private static readonly knownTypes = {
    'application/pdf': 'PDF',
    'image/jpeg': 'JPEG',
    'image/jpg': 'JPEG',
    'image/bmp': 'BMP',
    'image/png': 'PNG',
    'image/tiff': 'TIFF',
    'video/mp4': 'MP4',
    '.mov': 'MOV',
  };

  public static map(input: null | undefined | string | string[], delimiter = ',') {
    const formattedTypes = Array.isArray(input) ? input : defaultTo(input, '').split(delimiter);
    return uniq<string>(
      formattedTypes.map((type) => {
        const formattedType = String(type).trim();
        return defaultTo(this.knownTypes[formattedType], formattedType);
      }),
    );
  }
}
