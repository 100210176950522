<div class="add-edit-modal-toolbar">
  <!-- use a hidden input for setting a default focus -->
  <div class="invisible-input">
    <input ngbAutofocus />
  </div>
  <ul class="nav">
    <ng-content select="[slot=toolbar]"></ng-content>
    <li class="nav-item hidden-xs-down ml-auto mt-auto mb-auto">
      <button type="button" class="btn btn-light" (click)="close.emit()">
        <i class="cc-icon cc-icon-close"></i>
      </button>
    </li>
  </ul>
</div>

<div class="add-edit-modal-body">
  <ng-content select="[slot=body]"></ng-content>
</div>
