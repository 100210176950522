<gw-modal [dismissable]="true" (dismissEvent)="closeModal()" class="sm-w-[1000px]" [title]="'View Location'">
  <div class="sm-py-2">
    <div
      *ngIf="!useInteractiveMap && staticMapUrl"
      [hidden]="!mapImageIsLoaded"
      class="image-wrapper"
      data-test="static-map-container"
    >
      <img [src]="staticMapUrl" alt="locationMap" class="static-map" (load)="onImageLoaded()" />
      <div class="image-backdrop">
        <button
          gw-button
          type="button"
          class="switch-map-button"
          (click)="switchToInteractiveMap()"
          text="Switch to interactive map"
          color="white"
          size="medium"
        ></button>
      </div>
    </div>
    <google-map
      *ngIf="useInteractiveMap"
      width="100%"
      height="600px"
      [center]="data.GPSLocation"
      [zoom]="16"
      (mapInitialized)="logGoogleMapsUsage()"
    >
      <map-marker [position]="data.GPSLocation"></map-marker>
    </google-map>

    <div *ngIf="!mapImageIsLoaded" class="loading-container">
      <em class="cc-icon cc-icon-spinner-3x" aria-busy="true" aria-live="polite"></em>
    </div>
  </div>
  <div class="sm-flex sm-flex-col sm-text-xs sm-font-normal sm-text-secondary-text">
    <span class="sm-text-default-text"> {{ data.fullName }}</span>
    <span> {{ data.date }}</span>
    <span>Lat: {{ data.GPSLocation.lat }}</span>
    <span>Long: {{ data.GPSLocation.lng }}</span>
  </div>
</gw-modal>
