import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, forkJoin, merge } from 'rxjs';
import { finalize, map, shareReplay, tap } from 'rxjs/operators';

import { IUser, IUserWithSummaryOrWithoutData, UserTypes } from '@site-mate/dashpivot-shared-library';

import { IFormPersonOption } from 'app/shared/model/form-person.option.model';
import { CompanyService } from 'app/shared/service/company.service';
import { TeamService } from 'app/shared/service/team.service';
import { SitemateUsersService } from 'app/users/sitemate-users.service';

@Injectable({ providedIn: 'root' })
export class FormPersonService {
  private unSubscribe = new Subject<void>();
  private openedFormTeamId = new BehaviorSubject<string | null>(null);
  private teamUsersInOpenedForm = new BehaviorSubject<{
    contributors: IFormPersonOption[];
    visitors: IFormPersonOption[];
  }>({
    contributors: [],
    visitors: [],
  });
  private inflightRequests = new Map<
    string,
    Observable<{ contributors: IFormPersonOption[]; visitors: IFormPersonOption[] }>
  >();

  constructor(
    private readonly teamService: TeamService,
    private readonly companyService: CompanyService,
    private readonly sitemateUsersService: SitemateUsersService,
  ) {
    merge(this.teamService.dataChanged$, this.companyService.dataChanged$)
      .pipe(tap(() => this.clearCache()))
      .subscribe();
  }

  private mapUserToOption(user: IUser, role: UserTypes): IFormPersonOption {
    return {
      _id: user.sitemateUserId,
      label: user.fullName || user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      fullName: user.fullName,
      email: user.email,
      avatar: user.avatar,
      role,
    };
  }

  getPersonItems(
    folderId: string,
    { isTeam }: { isTeam: boolean },
  ): Observable<{ contributors: IFormPersonOption[]; visitors: IFormPersonOption[] }> {
    const service = isTeam ? this.teamService : this.companyService;
    if (this.openedFormTeamId.value === folderId) {
      return this.teamUsersInOpenedForm.asObservable();
    }

    if (this.inflightRequests.has(folderId)) {
      return this.inflightRequests.get(folderId);
    }

    const request = forkJoin([
      service.getUsers(folderId, UserTypes.Contributor),
      service.getUsers(folderId, UserTypes.Visitor),
    ]).pipe(
      map(([contributors, visitors]) => [
        contributors.filter((user: IUser) => user.isActivated),
        visitors.filter((user: IUser) => user.isActivated),
      ]),
      map(([contributors, visitors]) => ({
        contributors: contributors.map((user) => this.mapUserToOption(user, UserTypes.Contributor)),
        visitors: visitors.map((user) => this.mapUserToOption(user, UserTypes.Visitor)),
      })),
      tap((mappedOptions) => {
        this.openedFormTeamId.next(folderId);
        this.teamUsersInOpenedForm.next(mappedOptions);
      }),
      shareReplay(1),
      finalize(() => {
        this.inflightRequests.delete(folderId);
      }),
    );

    this.inflightRequests.set(folderId, request);

    return request;
  }

  searchUser(userId: string): Subject<IUserWithSummaryOrWithoutData> {
    const users = this.sitemateUsersService.getUserDataSubjects([userId]);
    return users.get(userId);
  }

  getTeamUsersInOpenedForm() {
    return this.teamUsersInOpenedForm.asObservable();
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }

  clearCache() {
    this.openedFormTeamId.next(null);
    this.teamUsersInOpenedForm.next({
      contributors: [],
      visitors: [],
    });
  }
}
