import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { NgbPopover, Placement } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cc-popover',
  templateUrl: './popover.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopoverComponent {
  @Input() placement: Placement = 'right';
  @Input() trigger = 'mouseenter:mouseleave';
  @Input() autoClose: boolean | 'inside' | 'outside' = true;
  @Input() containerClass = '';
  @Input() popoverCustomClass = '';
  @Input() container = undefined;
  @Input() disabled = undefined;

  @ViewChild('popoverComponent') public popover: NgbPopover;

  open(): void {
    this.popover.open();
  }

  close(): void {
    this.popover.close();
  }
}
