import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CookiesService {
  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  getCookieByNameOrValue(cookieName: string, cookieValue: string): { [key: string]: string } | null {
    const cookieString = this.document.cookie;
    let decodedValue = null;

    if (cookieString.length > 0) {
      const cookies = cookieString.split('; ');
      cookies.forEach((cookie) => {
        const [name, value] = cookie.split('=');
        if (name.includes(cookieName) || value.includes(cookieValue)) {
          decodedValue = decodeURIComponent(value);
        }
      });
    }

    return decodedValue ? JSON.parse(decodedValue) : null;
  }
}
