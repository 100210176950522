import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Placement } from '@ng-bootstrap/ng-bootstrap';
import { Options } from '@popperjs/core';

import { TmpI18NService } from '../../i18n/tmp-i18n.service';

export type TooltipVariant = 'default' | 'compact';

const defaultTooltipPopoverClass = 'tooltip-popover';
const compactTooltipPopoverClass = 'tooltip-popover tooltip-compact';
const containerClass = 'sm-inline-block';

@Component({
  selector: 'cc-tool-tip',
  templateUrl: './tool-tip.component.html',
  styleUrls: ['./tool-tip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolTipComponent implements OnInit {
  @Input() contentText: string;
  @Input() contentKey: string;
  @Input() placement: Placement = 'right';
  @Input() containerClass = containerClass;
  @Input() variant: TooltipVariant = 'default';

  popoverClass: string = defaultTooltipPopoverClass;
  popperOptions = (options: Options): Options => options;

  constructor(private readonly i18n: TmpI18NService) {}

  ngOnInit() {
    if (this.contentKey) {
      this.contentText = this.i18n.getMessage(this.contentKey);
    }

    if (this.variant === 'compact') {
      this.popoverClass = compactTooltipPopoverClass;
    }
  }
}
