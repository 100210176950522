import { InjectionToken } from '@angular/core';

import { environment } from 'environments/environment';

export function segmentConfigFactory() {
  return {
    apiKey: environment.segment.key || undefined,
    debug: !environment.production,
    trackPages: environment.segment.trackPages,
    vitally: environment.vitally,
  };
}

export const SEGMENT_CONFIG = new InjectionToken<{
  apiKey: string;
  debug: boolean;
  trackPages: boolean;
  vitally: any;
}>('segmentConfig');
