{
  "Europe/Andorra": { "countries": [{ "name": "Andorra", "code": "AD" }], "mcode": "376" },
  "Asia/Dubai": {
    "countries": [
      { "name": "United Arab Emirates", "code": "AE" },
      { "name": "Oman", "code": "OM" }
    ],
    "mcode": "971"
  },
  "Asia/Kabul": { "countries": [{ "name": "Afghanistan", "code": "AF" }], "mcode": "93" },
  "America/Port_of_Spain": {
    "countries": [
      { "name": "Antigua & Barbuda", "code": "AG" },
      { "name": "Anguilla", "code": "AI" },
      { "name": "St Barthelemy", "code": "BL" },
      { "name": "Dominica", "code": "DM" },
      { "name": "Grenada", "code": "GD" },
      { "name": "Guadeloupe", "code": "GP" },
      { "name": "St Kitts & Nevis", "code": "KN" },
      { "name": "St Lucia", "code": "LC" },
      { "name": "St Martin (French)", "code": "MF" },
      { "name": "Montserrat", "code": "MS" },
      { "name": "Trinidad & Tobago", "code": "TT" },
      { "name": "St Vincent", "code": "VC" },
      { "name": "Virgin Islands (UK)", "code": "VG" },
      { "name": "Virgin Islands (US)", "code": "VI" }
    ],
    "mcode": "1268"
  },
  "America/Antigua": { "countries": [{ "name": "Antigua & Barbuda", "code": "AG" }], "mcode": "1268" },
  "America/Anguilla": { "countries": [{ "name": "Anguilla", "code": "AI" }], "mcode": "1264" },
  "Europe/Tirane": { "countries": [{ "name": "Albania", "code": "AL" }], "mcode": "355" },
  "Asia/Yerevan": { "countries": [{ "name": "Armenia", "code": "AM" }], "mcode": "374" },
  "Africa/Lagos": {
    "countries": [
      { "name": "Angola", "code": "AO" },
      { "name": "Benin", "code": "BJ" },
      { "name": "Congo (Dem. Rep.)", "code": "CD" },
      { "name": "Central African Rep.", "code": "CF" },
      { "name": "Congo (Rep.)", "code": "CG" },
      { "name": "Cameroon", "code": "CM" },
      { "name": "Gabon", "code": "GA" },
      { "name": "Equatorial Guinea", "code": "GQ" },
      { "name": "Niger", "code": "NE" },
      { "name": "Nigeria", "code": "NG" }
    ],
    "mcode": "244"
  },
  "Africa/Luanda": { "countries": [{ "name": "Angola", "code": "AO" }], "mcode": "244" },
  "Antarctica/Casey": { "countries": [{ "name": "Antarctica", "code": "AQ" }], "mcode": "61" },
  "Antarctica/Davis": { "countries": [{ "name": "Antarctica", "code": "AQ" }], "mcode": "61" },
  "Antarctica/DumontDUrville": { "countries": [{ "name": "Antarctica", "code": "AQ" }], "mcode": "61" },
  "Antarctica/Mawson": { "countries": [{ "name": "Antarctica", "code": "AQ" }], "mcode": "61" },
  "Antarctica/Palmer": { "countries": [{ "name": "Antarctica", "code": "AQ" }], "mcode": "61" },
  "Antarctica/Rothera": { "countries": [{ "name": "Antarctica", "code": "AQ" }], "mcode": "61" },
  "Antarctica/Syowa": { "countries": [{ "name": "Antarctica", "code": "AQ" }], "mcode": "61" },
  "Antarctica/Troll": { "countries": [{ "name": "Antarctica", "code": "AQ" }], "mcode": "61" },
  "Antarctica/Vostok": { "countries": [{ "name": "Antarctica", "code": "AQ" }], "mcode": "61" },
  "Pacific/Auckland": {
    "countries": [
      { "name": "Antarctica", "code": "AQ" },
      { "name": "New Zealand", "code": "NZ" }
    ],
    "mcode": "64"
  },
  "Antarctica/McMurdo": { "countries": [{ "name": "Antarctica", "code": "AQ" }], "mcode": "61" },
  "America/Argentina/Buenos_Aires": { "countries": [{ "name": "Argentina", "code": "AR" }], "mcode": "54" },
  "America/Argentina/Cordoba": { "countries": [{ "name": "Argentina", "code": "AR" }], "mcode": "54" },
  "America/Argentina/Salta": { "countries": [{ "name": "Argentina", "code": "AR" }], "mcode": "54" },
  "America/Argentina/Jujuy": { "countries": [{ "name": "Argentina", "code": "AR" }], "mcode": "54" },
  "America/Argentina/Tucuman": { "countries": [{ "name": "Argentina", "code": "AR" }], "mcode": "54" },
  "America/Argentina/Catamarca": { "countries": [{ "name": "Argentina", "code": "AR" }], "mcode": "54" },
  "America/Argentina/La_Rioja": { "countries": [{ "name": "Argentina", "code": "AR" }], "mcode": "54" },
  "America/Argentina/San_Juan": { "countries": [{ "name": "Argentina", "code": "AR" }], "mcode": "54" },
  "America/Argentina/Mendoza": { "countries": [{ "name": "Argentina", "code": "AR" }], "mcode": "54" },
  "America/Argentina/San_Luis": { "countries": [{ "name": "Argentina", "code": "AR" }], "mcode": "54" },
  "America/Argentina/Rio_Gallegos": { "countries": [{ "name": "Argentina", "code": "AR" }], "mcode": "54" },
  "America/Argentina/Ushuaia": { "countries": [{ "name": "Argentina", "code": "AR" }], "mcode": "54" },
  "Pacific/Pago_Pago": {
    "countries": [
      { "name": "Samoa (American)", "code": "AS" },
      { "name": "US minor outlying islands", "code": "UM" }
    ],
    "mcode": "61"
  },
  "Europe/Vienna": { "countries": [{ "name": "Austria", "code": "AT" }], "mcode": "43" },
  "Australia/Lord_Howe": { "countries": [{ "name": "Australia", "code": "AU" }], "mcode": "61" },
  "Antarctica/Macquarie": { "countries": [{ "name": "Australia", "code": "AU" }], "mcode": "61" },
  "Australia/Hobart": { "countries": [{ "name": "Australia", "code": "AU" }], "mcode": "61" },
  "Australia/Melbourne": { "countries": [{ "name": "Australia", "code": "AU" }], "mcode": "61" },
  "Australia/Sydney": { "countries": [{ "name": "Australia", "code": "AU" }], "mcode": "61" },
  "Australia/Broken_Hill": { "countries": [{ "name": "Australia", "code": "AU" }], "mcode": "61" },
  "Australia/Brisbane": { "countries": [{ "name": "Australia", "code": "AU" }], "mcode": "61" },
  "Australia/Lindeman": { "countries": [{ "name": "Australia", "code": "AU" }], "mcode": "61" },
  "Australia/Adelaide": { "countries": [{ "name": "Australia", "code": "AU" }], "mcode": "61" },
  "Australia/Darwin": { "countries": [{ "name": "Australia", "code": "AU" }], "mcode": "61" },
  "Australia/Perth": { "countries": [{ "name": "Australia", "code": "AU" }], "mcode": "61" },
  "Australia/Eucla": { "countries": [{ "name": "Australia", "code": "AU" }], "mcode": "61" },
  "America/Curacao": {
    "countries": [
      { "name": "Aruba", "code": "AW" },
      { "name": "Caribbean NL", "code": "BQ" },
      { "name": "Curaçao", "code": "CW" },
      { "name": "St Maarten (Dutch)", "code": "SX" }
    ],
    "mcode": "297"
  },
  "America/Aruba": { "countries": [{ "name": "Aruba", "code": "AW" }], "mcode": "297" },
  "Europe/Helsinki": {
    "countries": [
      { "name": "Åland Islands", "code": "AX" },
      { "name": "Finland", "code": "FI" }
    ],
    "mcode": "61"
  },
  "Europe/Mariehamn": { "countries": [{ "name": "Åland Islands", "code": "AX" }], "mcode": "61" },
  "Asia/Baku": { "countries": [{ "name": "Azerbaijan", "code": "AZ" }], "mcode": "994" },
  "Europe/Belgrade": {
    "countries": [
      { "name": "Bosnia & Herzegovina", "code": "BA" },
      { "name": "Croatia", "code": "HR" },
      { "name": "Montenegro", "code": "ME" },
      { "name": "North Macedonia", "code": "MK" },
      { "name": "Serbia", "code": "RS" },
      { "name": "Slovenia", "code": "SI" }
    ],
    "mcode": "387"
  },
  "Europe/Sarajevo": { "countries": [{ "name": "Bosnia & Herzegovina", "code": "BA" }], "mcode": "387" },
  "America/Barbados": { "countries": [{ "name": "Barbados", "code": "BB" }], "mcode": "1246" },
  "Asia/Dhaka": { "countries": [{ "name": "Bangladesh", "code": "BD" }], "mcode": "880" },
  "Europe/Brussels": { "countries": [{ "name": "Belgium", "code": "BE" }], "mcode": "32" },
  "Africa/Abidjan": {
    "countries": [
      { "name": "Burkina Faso", "code": "BF" },
      { "name": "Côte d'Ivoire", "code": "CI" },
      { "name": "Gambia", "code": "GM" },
      { "name": "Guinea", "code": "GN" },
      { "name": "Mali", "code": "ML" },
      { "name": "Mauritania", "code": "MR" },
      { "name": "St Helena", "code": "SH" },
      { "name": "Sierra Leone", "code": "SL" },
      { "name": "Senegal", "code": "SN" },
      { "name": "Togo", "code": "TG" }
    ],
    "mcode": "226"
  },
  "Africa/Ouagadougou": { "countries": [{ "name": "Burkina Faso", "code": "BF" }], "mcode": "226" },
  "Europe/Sofia": { "countries": [{ "name": "Bulgaria", "code": "BG" }], "mcode": "359" },
  "Asia/Qatar": {
    "countries": [
      { "name": "Bahrain", "code": "BH" },
      { "name": "Qatar", "code": "QA" }
    ],
    "mcode": "973"
  },
  "Asia/Bahrain": { "countries": [{ "name": "Bahrain", "code": "BH" }], "mcode": "973" },
  "Africa/Maputo": {
    "countries": [
      { "name": "Burundi", "code": "BI" },
      { "name": "Botswana", "code": "BW" },
      { "name": "Congo (Dem. Rep.)", "code": "CD" },
      { "name": "Malawi", "code": "MW" },
      { "name": "Mozambique", "code": "MZ" },
      { "name": "Rwanda", "code": "RW" },
      { "name": "Zambia", "code": "ZM" },
      { "name": "Zimbabwe", "code": "ZW" }
    ],
    "mcode": "257"
  },
  "Africa/Bujumbura": { "countries": [{ "name": "Burundi", "code": "BI" }], "mcode": "257" },
  "Africa/Porto-Novo": { "countries": [{ "name": "Benin", "code": "BJ" }], "mcode": "229" },
  "America/St_Barthelemy": { "countries": [{ "name": "St Barthelemy", "code": "BL" }], "mcode": "61" },
  "Atlantic/Bermuda": { "countries": [{ "name": "Bermuda", "code": "BM" }], "mcode": "1441" },
  "Asia/Brunei": { "countries": [{ "name": "Brunei", "code": "BN" }], "mcode": "673" },
  "America/La_Paz": { "countries": [{ "name": "Bolivia", "code": "BO" }], "mcode": "591" },
  "America/Kralendijk": { "countries": [{ "name": "Caribbean NL", "code": "BQ" }], "mcode": "61" },
  "America/Noronha": { "countries": [{ "name": "Brazil", "code": "BR" }], "mcode": "55" },
  "America/Belem": { "countries": [{ "name": "Brazil", "code": "BR" }], "mcode": "55" },
  "America/Fortaleza": { "countries": [{ "name": "Brazil", "code": "BR" }], "mcode": "55" },
  "America/Recife": { "countries": [{ "name": "Brazil", "code": "BR" }], "mcode": "55" },
  "America/Araguaina": { "countries": [{ "name": "Brazil", "code": "BR" }], "mcode": "55" },
  "America/Maceio": { "countries": [{ "name": "Brazil", "code": "BR" }], "mcode": "55" },
  "America/Bahia": { "countries": [{ "name": "Brazil", "code": "BR" }], "mcode": "55" },
  "America/Sao_Paulo": { "countries": [{ "name": "Brazil", "code": "BR" }], "mcode": "55" },
  "America/Campo_Grande": { "countries": [{ "name": "Brazil", "code": "BR" }], "mcode": "55" },
  "America/Cuiaba": { "countries": [{ "name": "Brazil", "code": "BR" }], "mcode": "55" },
  "America/Santarem": { "countries": [{ "name": "Brazil", "code": "BR" }], "mcode": "55" },
  "America/Porto_Velho": { "countries": [{ "name": "Brazil", "code": "BR" }], "mcode": "55" },
  "America/Boa_Vista": { "countries": [{ "name": "Brazil", "code": "BR" }], "mcode": "55" },
  "America/Manaus": { "countries": [{ "name": "Brazil", "code": "BR" }], "mcode": "55" },
  "America/Eirunepe": { "countries": [{ "name": "Brazil", "code": "BR" }], "mcode": "55" },
  "America/Rio_Branco": { "countries": [{ "name": "Brazil", "code": "BR" }], "mcode": "55" },
  "America/Nassau": { "countries": [{ "name": "Bahamas", "code": "BS" }], "mcode": "1242" },
  "Asia/Thimphu": { "countries": [{ "name": "Bhutan", "code": "BT" }], "mcode": "975" },
  "Africa/Gaborone": { "countries": [{ "name": "Botswana", "code": "BW" }], "mcode": "267" },
  "Europe/Minsk": { "countries": [{ "name": "Belarus", "code": "BY" }], "mcode": "375" },
  "America/Belize": { "countries": [{ "name": "Belize", "code": "BZ" }], "mcode": "501" },
  "America/St_Johns": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Halifax": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Glace_Bay": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Moncton": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Goose_Bay": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Blanc-Sablon": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Toronto": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Nipigon": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Thunder_Bay": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Iqaluit": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Pangnirtung": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Atikokan": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Winnipeg": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Rainy_River": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Resolute": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Rankin_Inlet": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Regina": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Swift_Current": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Edmonton": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Cambridge_Bay": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Yellowknife": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Inuvik": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Creston": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Dawson_Creek": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Fort_Nelson": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Whitehorse": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Dawson": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "America/Vancouver": { "countries": [{ "name": "Canada", "code": "CA" }], "mcode": "1" },
  "Indian/Cocos": { "countries": [{ "name": "Cocos (Keeling) Islands", "code": "CC" }], "mcode": "61" },
  "Africa/Kinshasa": { "countries": [{ "name": "Congo (Dem. Rep.)", "code": "CD" }], "mcode": "243" },
  "Africa/Lubumbashi": { "countries": [{ "name": "Congo (Dem. Rep.)", "code": "CD" }], "mcode": "243" },
  "Africa/Bangui": { "countries": [{ "name": "Central African Rep.", "code": "CF" }], "mcode": "236" },
  "Africa/Brazzaville": { "countries": [{ "name": "Congo (Rep.)", "code": "CG" }], "mcode": "242" },
  "Europe/Zurich": {
    "countries": [
      { "name": "Switzerland", "code": "CH" },
      { "name": "Germany", "code": "DE" },
      { "name": "Liechtenstein", "code": "LI" }
    ],
    "mcode": "41"
  },
  "Pacific/Rarotonga": { "countries": [{ "name": "Cook Islands", "code": "CK" }], "mcode": "682" },
  "America/Santiago": { "countries": [{ "name": "Chile", "code": "CL" }], "mcode": "56" },
  "America/Punta_Arenas": { "countries": [{ "name": "Chile", "code": "CL" }], "mcode": "56" },
  "Pacific/Easter": { "countries": [{ "name": "Chile", "code": "CL" }], "mcode": "56" },
  "Africa/Douala": { "countries": [{ "name": "Cameroon", "code": "CM" }], "mcode": "237" },
  "Asia/Shanghai": { "countries": [{ "name": "China", "code": "CN" }], "mcode": "86" },
  "Asia/Urumqi": { "countries": [{ "name": "China", "code": "CN" }], "mcode": "86" },
  "America/Bogota": { "countries": [{ "name": "Colombia", "code": "CO" }], "mcode": "57" },
  "America/Costa_Rica": { "countries": [{ "name": "Costa Rica", "code": "CR" }], "mcode": "506" },
  "America/Havana": { "countries": [{ "name": "Cuba", "code": "CU" }], "mcode": "53" },
  "Atlantic/Cape_Verde": { "countries": [{ "name": "Cape Verde", "code": "CV" }], "mcode": "238" },
  "Indian/Christmas": { "countries": [{ "name": "Christmas Island", "code": "CX" }], "mcode": "61" },
  "Asia/Nicosia": { "countries": [{ "name": "Cyprus", "code": "CY" }], "mcode": "90" },
  "Asia/Famagusta": { "countries": [{ "name": "Cyprus", "code": "CY" }], "mcode": "90" },
  "Europe/Prague": {
    "countries": [
      { "name": "Czech Republic", "code": "CZ" },
      { "name": "Slovakia", "code": "SK" }
    ],
    "mcode": "420"
  },
  "Europe/Berlin": { "countries": [{ "name": "Germany", "code": "DE" }], "mcode": "49" },
  "Europe/Busingen": { "countries": [{ "name": "Germany", "code": "DE" }], "mcode": "49" },
  "Africa/Nairobi": {
    "countries": [
      { "name": "Djibouti", "code": "DJ" },
      { "name": "Eritrea", "code": "ER" },
      { "name": "Ethiopia", "code": "ET" },
      { "name": "Kenya", "code": "KE" },
      { "name": "Comoros", "code": "KM" },
      { "name": "Madagascar", "code": "MG" },
      { "name": "Somalia", "code": "SO" },
      { "name": "Tanzania", "code": "TZ" },
      { "name": "Uganda", "code": "UG" },
      { "name": "Mayotte", "code": "YT" }
    ],
    "mcode": "253"
  },
  "Africa/Djibouti": { "countries": [{ "name": "Djibouti", "code": "DJ" }], "mcode": "253" },
  "Europe/Copenhagen": { "countries": [{ "name": "Denmark", "code": "DK" }], "mcode": "45" },
  "America/Dominica": { "countries": [{ "name": "Dominica", "code": "DM" }], "mcode": "1809" },
  "America/Santo_Domingo": {
    "countries": [{ "name": "Dominican Republic", "code": "DO" }],
    "mcode": "1809"
  },
  "Africa/Algiers": { "countries": [{ "name": "Algeria", "code": "DZ" }], "mcode": "213" },
  "America/Guayaquil": { "countries": [{ "name": "Ecuador", "code": "EC" }], "mcode": "593" },
  "Pacific/Galapagos": { "countries": [{ "name": "Ecuador", "code": "EC" }], "mcode": "593" },
  "Europe/Tallinn": { "countries": [{ "name": "Estonia", "code": "EE" }], "mcode": "372" },
  "Africa/Cairo": { "countries": [{ "name": "Egypt", "code": "EG" }], "mcode": "20" },
  "Africa/El_Aaiun": { "countries": [{ "name": "Western Sahara", "code": "EH" }], "mcode": "61" },
  "Africa/Asmara": { "countries": [{ "name": "Eritrea", "code": "ER" }], "mcode": "291" },
  "Europe/Madrid": { "countries": [{ "name": "Spain", "code": "ES" }], "mcode": "34" },
  "Africa/Ceuta": { "countries": [{ "name": "Spain", "code": "ES" }], "mcode": "34" },
  "Atlantic/Canary": { "countries": [{ "name": "Spain", "code": "ES" }], "mcode": "34" },
  "Africa/Addis_Ababa": { "countries": [{ "name": "Ethiopia", "code": "ET" }], "mcode": "251" },
  "Pacific/Fiji": { "countries": [{ "name": "Fiji", "code": "FJ" }], "mcode": "679" },
  "Atlantic/Stanley": { "countries": [{ "name": "Falkland Islands", "code": "FK" }], "mcode": "500" },
  "Pacific/Chuuk": { "countries": [{ "name": "Micronesia", "code": "FM" }], "mcode": "691" },
  "Pacific/Pohnpei": { "countries": [{ "name": "Micronesia", "code": "FM" }], "mcode": "691" },
  "Pacific/Kosrae": { "countries": [{ "name": "Micronesia", "code": "FM" }], "mcode": "691" },
  "Atlantic/Faroe": { "countries": [{ "name": "Faroe Islands", "code": "FO" }], "mcode": "298" },
  "Europe/Paris": { "countries": [{ "name": "France", "code": "FR" }], "mcode": "33" },
  "Africa/Libreville": { "countries": [{ "name": "Gabon", "code": "GA" }], "mcode": "241" },
  "Europe/London": {
    "countries": [
      { "name": "United Kingdom (UK)", "code": "GB" },
      { "name": "Guernsey", "code": "GG" },
      { "name": "Isle of Man", "code": "IM" },
      { "name": "Jersey", "code": "JE" }
    ],
    "mcode": "44"
  },
  "America/Grenada": { "countries": [{ "name": "Grenada", "code": "GD" }], "mcode": "1473" },
  "Asia/Tbilisi": { "countries": [{ "name": "Georgia", "code": "GE" }], "mcode": "7880" },
  "America/Cayenne": { "countries": [{ "name": "French Guiana", "code": "GF" }], "mcode": "594" },
  "Europe/Guernsey": { "countries": [{ "name": "Guernsey", "code": "GG" }], "mcode": "61" },
  "Africa/Accra": { "countries": [{ "name": "Ghana", "code": "GH" }], "mcode": "233" },
  "Europe/Gibraltar": { "countries": [{ "name": "Gibraltar", "code": "GI" }], "mcode": "350" },
  "America/Nuuk": { "countries": [{ "name": "Greenland", "code": "GL" }], "mcode": "299" },
  "America/Danmarkshavn": { "countries": [{ "name": "Greenland", "code": "GL" }], "mcode": "299" },
  "America/Scoresbysund": { "countries": [{ "name": "Greenland", "code": "GL" }], "mcode": "299" },
  "America/Thule": { "countries": [{ "name": "Greenland", "code": "GL" }], "mcode": "299" },
  "Africa/Banjul": { "countries": [{ "name": "Gambia", "code": "GM" }], "mcode": "220" },
  "Africa/Conakry": { "countries": [{ "name": "Guinea", "code": "GN" }], "mcode": "224" },
  "America/Guadeloupe": { "countries": [{ "name": "Guadeloupe", "code": "GP" }], "mcode": "590" },
  "Africa/Malabo": { "countries": [{ "name": "Equatorial Guinea", "code": "GQ" }], "mcode": "240" },
  "Europe/Athens": { "countries": [{ "name": "Greece", "code": "GR" }], "mcode": "30" },
  "Atlantic/South_Georgia": {
    "countries": [{ "name": "South Georgia & the South Sandwich Islands", "code": "GS" }],
    "mcode": "61"
  },
  "America/Guatemala": { "countries": [{ "name": "Guatemala", "code": "GT" }], "mcode": "502" },
  "Pacific/Guam": {
    "countries": [
      { "name": "Guam", "code": "GU" },
      { "name": "Northern Mariana Islands", "code": "MP" }
    ],
    "mcode": "671"
  },
  "Africa/Bissau": { "countries": [{ "name": "Guinea-Bissau", "code": "GW" }], "mcode": "245" },
  "America/Guyana": { "countries": [{ "name": "Guyana", "code": "GY" }], "mcode": "592" },
  "Asia/Hong_Kong": { "countries": [{ "name": "Hong Kong", "code": "HK" }], "mcode": "852" },
  "America/Tegucigalpa": { "countries": [{ "name": "Honduras", "code": "HN" }], "mcode": "504" },
  "Europe/Zagreb": { "countries": [{ "name": "Croatia", "code": "HR" }], "mcode": "385" },
  "America/Port-au-Prince": { "countries": [{ "name": "Haiti", "code": "HT" }], "mcode": "509" },
  "Europe/Budapest": { "countries": [{ "name": "Hungary", "code": "HU" }], "mcode": "36" },
  "Asia/Jakarta": { "countries": [{ "name": "Indonesia", "code": "ID" }], "mcode": "62" },
  "Asia/Pontianak": { "countries": [{ "name": "Indonesia", "code": "ID" }], "mcode": "62" },
  "Asia/Makassar": { "countries": [{ "name": "Indonesia", "code": "ID" }], "mcode": "62" },
  "Asia/Jayapura": { "countries": [{ "name": "Indonesia", "code": "ID" }], "mcode": "62" },
  "Europe/Dublin": { "countries": [{ "name": "Ireland", "code": "IE" }], "mcode": "353" },
  "Asia/Jerusalem": { "countries": [{ "name": "Israel", "code": "IL" }], "mcode": "972" },
  "Europe/Isle_of_Man": { "countries": [{ "name": "Isle of Man", "code": "IM" }], "mcode": "61" },
  "Asia/Kolkata": { "countries": [{ "name": "India", "code": "IN" }], "mcode": "91" },
  "Indian/Chagos": {
    "countries": [{ "name": "British Indian Ocean Territory", "code": "IO" }],
    "mcode": "61"
  },
  "Asia/Baghdad": { "countries": [{ "name": "Iraq", "code": "IQ" }], "mcode": "964" },
  "Asia/Tehran": { "countries": [{ "name": "Iran", "code": "IR" }], "mcode": "98" },
  "Atlantic/Reykjavik": { "countries": [{ "name": "Iceland", "code": "IS" }], "mcode": "354" },
  "Europe/Rome": {
    "countries": [
      { "name": "Italy", "code": "IT" },
      { "name": "San Marino", "code": "SM" },
      { "name": "Vatican City", "code": "VA" }
    ],
    "mcode": "39"
  },
  "Europe/Jersey": { "countries": [{ "name": "Jersey", "code": "JE" }], "mcode": "61" },
  "America/Jamaica": { "countries": [{ "name": "Jamaica", "code": "JM" }], "mcode": "1876" },
  "Asia/Amman": { "countries": [{ "name": "Jordan", "code": "JO" }], "mcode": "962" },
  "Asia/Tokyo": { "countries": [{ "name": "Japan", "code": "JP" }], "mcode": "81" },
  "Asia/Bishkek": { "countries": [{ "name": "Kyrgyzstan", "code": "KG" }], "mcode": "996" },
  "Asia/Bangkok": {
    "countries": [
      { "name": "Cambodia", "code": "KH" },
      { "name": "Laos", "code": "LA" },
      { "name": "Thailand", "code": "TH" },
      { "name": "Vietnam", "code": "VN" }
    ],
    "mcode": "855"
  },
  "Asia/Phnom_Penh": { "countries": [{ "name": "Cambodia", "code": "KH" }], "mcode": "855" },
  "Pacific/Tarawa": { "countries": [{ "name": "Kiribati", "code": "KI" }], "mcode": "686" },
  "Pacific/Enderbury": { "countries": [{ "name": "Kiribati", "code": "KI" }], "mcode": "686" },
  "Pacific/Kiritimati": { "countries": [{ "name": "Kiribati", "code": "KI" }], "mcode": "686" },
  "Indian/Comoro": { "countries": [{ "name": "Comoros", "code": "KM" }], "mcode": "269" },
  "America/St_Kitts": { "countries": [{ "name": "St Kitts & Nevis", "code": "KN" }], "mcode": "1869" },
  "Asia/Pyongyang": { "countries": [{ "name": "Korea (North)", "code": "KP" }], "mcode": "850" },
  "Asia/Seoul": { "countries": [{ "name": "Korea (South)", "code": "KR" }], "mcode": "82" },
  "Asia/Riyadh": {
    "countries": [
      { "name": "Kuwait", "code": "KW" },
      { "name": "Saudi Arabia", "code": "SA" },
      { "name": "Yemen", "code": "YE" }
    ],
    "mcode": "965"
  },
  "Asia/Kuwait": { "countries": [{ "name": "Kuwait", "code": "KW" }], "mcode": "965" },
  "America/Panama": {
    "countries": [
      { "name": "Cayman Islands", "code": "KY" },
      { "name": "Panama", "code": "PA" }
    ],
    "mcode": "1345"
  },
  "America/Cayman": { "countries": [{ "name": "Cayman Islands", "code": "KY" }], "mcode": "1345" },
  "Asia/Almaty": { "countries": [{ "name": "Kazakhstan", "code": "KZ" }], "mcode": "7" },
  "Asia/Qyzylorda": { "countries": [{ "name": "Kazakhstan", "code": "KZ" }], "mcode": "7" },
  "Asia/Qostanay": { "countries": [{ "name": "Kazakhstan", "code": "KZ" }], "mcode": "7" },
  "Asia/Aqtobe": { "countries": [{ "name": "Kazakhstan", "code": "KZ" }], "mcode": "7" },
  "Asia/Aqtau": { "countries": [{ "name": "Kazakhstan", "code": "KZ" }], "mcode": "7" },
  "Asia/Atyrau": { "countries": [{ "name": "Kazakhstan", "code": "KZ" }], "mcode": "7" },
  "Asia/Oral": { "countries": [{ "name": "Kazakhstan", "code": "KZ" }], "mcode": "7" },
  "Asia/Vientiane": { "countries": [{ "name": "Laos", "code": "LA" }], "mcode": "856" },
  "Asia/Beirut": { "countries": [{ "name": "Lebanon", "code": "LB" }], "mcode": "961" },
  "America/St_Lucia": { "countries": [{ "name": "St Lucia", "code": "LC" }], "mcode": "61" },
  "Europe/Vaduz": { "countries": [{ "name": "Liechtenstein", "code": "LI" }], "mcode": "417" },
  "Asia/Colombo": { "countries": [{ "name": "Sri Lanka", "code": "LK" }], "mcode": "94" },
  "Africa/Monrovia": { "countries": [{ "name": "Liberia", "code": "LR" }], "mcode": "231" },
  "Africa/Johannesburg": {
    "countries": [
      { "name": "Lesotho", "code": "LS" },
      { "name": "Eswatini (Swaziland)", "code": "SZ" },
      { "name": "South Africa", "code": "ZA" }
    ],
    "mcode": "266"
  },
  "Africa/Maseru": { "countries": [{ "name": "Lesotho", "code": "LS" }], "mcode": "266" },
  "Europe/Vilnius": { "countries": [{ "name": "Lithuania", "code": "LT" }], "mcode": "370" },
  "Europe/Luxembourg": { "countries": [{ "name": "Luxembourg", "code": "LU" }], "mcode": "352" },
  "Europe/Riga": { "countries": [{ "name": "Latvia", "code": "LV" }], "mcode": "371" },
  "Africa/Tripoli": { "countries": [{ "name": "Libya", "code": "LY" }], "mcode": "218" },
  "Africa/Casablanca": { "countries": [{ "name": "Morocco", "code": "MA" }], "mcode": "212" },
  "Europe/Monaco": { "countries": [{ "name": "Monaco", "code": "MC" }], "mcode": "377" },
  "Europe/Chisinau": { "countries": [{ "name": "Moldova", "code": "MD" }], "mcode": "373" },
  "Europe/Podgorica": { "countries": [{ "name": "Montenegro", "code": "ME" }], "mcode": "382" },
  "America/Marigot": { "countries": [{ "name": "St Martin (French)", "code": "MF" }], "mcode": "61" },
  "Indian/Antananarivo": { "countries": [{ "name": "Madagascar", "code": "MG" }], "mcode": "261" },
  "Pacific/Majuro": { "countries": [{ "name": "Marshall Islands", "code": "MH" }], "mcode": "692" },
  "Pacific/Kwajalein": { "countries": [{ "name": "Marshall Islands", "code": "MH" }], "mcode": "692" },
  "Europe/Skopje": { "countries": [{ "name": "North Macedonia", "code": "MK" }], "mcode": "389" },
  "Africa/Bamako": { "countries": [{ "name": "Mali", "code": "ML" }], "mcode": "223" },
  "Asia/Yangon": { "countries": [{ "name": "Myanmar (Burma)", "code": "MM" }], "mcode": "61" },
  "Asia/Ulaanbaatar": { "countries": [{ "name": "Mongolia", "code": "MN" }], "mcode": "976" },
  "Asia/Hovd": { "countries": [{ "name": "Mongolia", "code": "MN" }], "mcode": "976" },
  "Asia/Choibalsan": { "countries": [{ "name": "Mongolia", "code": "MN" }], "mcode": "976" },
  "Asia/Macau": { "countries": [{ "name": "Macau", "code": "MO" }], "mcode": "853" },
  "Pacific/Saipan": { "countries": [{ "name": "Northern Mariana Islands", "code": "MP" }], "mcode": "61" },
  "America/Martinique": { "countries": [{ "name": "Martinique", "code": "MQ" }], "mcode": "596" },
  "Africa/Nouakchott": { "countries": [{ "name": "Mauritania", "code": "MR" }], "mcode": "222" },
  "America/Montserrat": { "countries": [{ "name": "Montserrat", "code": "MS" }], "mcode": "1664" },
  "Europe/Malta": { "countries": [{ "name": "Malta", "code": "MT" }], "mcode": "356" },
  "Indian/Mauritius": { "countries": [{ "name": "Mauritius", "code": "MU" }], "mcode": "230" },
  "Indian/Maldives": { "countries": [{ "name": "Maldives", "code": "MV" }], "mcode": "960" },
  "Africa/Blantyre": { "countries": [{ "name": "Malawi", "code": "MW" }], "mcode": "265" },
  "America/Mexico_City": { "countries": [{ "name": "Mexico", "code": "MX" }], "mcode": "52" },
  "America/Cancun": { "countries": [{ "name": "Mexico", "code": "MX" }], "mcode": "52" },
  "America/Merida": { "countries": [{ "name": "Mexico", "code": "MX" }], "mcode": "52" },
  "America/Monterrey": { "countries": [{ "name": "Mexico", "code": "MX" }], "mcode": "52" },
  "America/Matamoros": { "countries": [{ "name": "Mexico", "code": "MX" }], "mcode": "52" },
  "America/Mazatlan": { "countries": [{ "name": "Mexico", "code": "MX" }], "mcode": "52" },
  "America/Chihuahua": { "countries": [{ "name": "Mexico", "code": "MX" }], "mcode": "52" },
  "America/Ojinaga": { "countries": [{ "name": "Mexico", "code": "MX" }], "mcode": "52" },
  "America/Hermosillo": { "countries": [{ "name": "Mexico", "code": "MX" }], "mcode": "52" },
  "America/Tijuana": { "countries": [{ "name": "Mexico", "code": "MX" }], "mcode": "52" },
  "America/Bahia_Banderas": { "countries": [{ "name": "Mexico", "code": "MX" }], "mcode": "52" },
  "Asia/Kuala_Lumpur": { "countries": [{ "name": "Malaysia", "code": "MY" }], "mcode": "60" },
  "Asia/Kuching": { "countries": [{ "name": "Malaysia", "code": "MY" }], "mcode": "60" },
  "Africa/Windhoek": { "countries": [{ "name": "Namibia", "code": "NA" }], "mcode": "264" },
  "Pacific/Noumea": { "countries": [{ "name": "New Caledonia", "code": "NC" }], "mcode": "687" },
  "Africa/Niamey": { "countries": [{ "name": "Niger", "code": "NE" }], "mcode": "227" },
  "Pacific/Norfolk": { "countries": [{ "name": "Norfolk Island", "code": "NF" }], "mcode": "672" },
  "America/Managua": { "countries": [{ "name": "Nicaragua", "code": "NI" }], "mcode": "505" },
  "Europe/Amsterdam": { "countries": [{ "name": "Netherlands", "code": "NL" }], "mcode": "31" },
  "Europe/Oslo": {
    "countries": [
      { "name": "Norway", "code": "NO" },
      { "name": "Svalbard & Jan Mayen", "code": "SJ" }
    ],
    "mcode": "47"
  },
  "Asia/Kathmandu": { "countries": [{ "name": "Nepal", "code": "NP" }], "mcode": "977" },
  "Pacific/Nauru": { "countries": [{ "name": "Nauru", "code": "NR" }], "mcode": "674" },
  "Pacific/Niue": { "countries": [{ "name": "Niue", "code": "NU" }], "mcode": "683" },
  "Pacific/Chatham": { "countries": [{ "name": "New Zealand", "code": "NZ" }], "mcode": "64" },
  "Asia/Muscat": { "countries": [{ "name": "Oman", "code": "OM" }], "mcode": "968" },
  "America/Lima": { "countries": [{ "name": "Peru", "code": "PE" }], "mcode": "51" },
  "Pacific/Tahiti": { "countries": [{ "name": "French Polynesia", "code": "PF" }], "mcode": "689" },
  "Pacific/Marquesas": { "countries": [{ "name": "French Polynesia", "code": "PF" }], "mcode": "689" },
  "Pacific/Gambier": { "countries": [{ "name": "French Polynesia", "code": "PF" }], "mcode": "689" },
  "Pacific/Port_Moresby": { "countries": [{ "name": "Papua New Guinea", "code": "PG" }], "mcode": "675" },
  "Pacific/Bougainville": { "countries": [{ "name": "Papua New Guinea", "code": "PG" }], "mcode": "675" },
  "Asia/Manila": { "countries": [{ "name": "Philippines", "code": "PH" }], "mcode": "63" },
  "Asia/Karachi": { "countries": [{ "name": "Pakistan", "code": "PK" }], "mcode": "92" },
  "Europe/Warsaw": { "countries": [{ "name": "Poland", "code": "PL" }], "mcode": "48" },
  "America/Miquelon": { "countries": [{ "name": "St Pierre & Miquelon", "code": "PM" }], "mcode": "61" },
  "Pacific/Pitcairn": { "countries": [{ "name": "Pitcairn", "code": "PN" }], "mcode": "61" },
  "America/Puerto_Rico": { "countries": [{ "name": "Puerto Rico", "code": "PR" }], "mcode": "1787" },
  "Asia/Gaza": { "countries": [{ "name": "Palestine", "code": "PS" }], "mcode": "61" },
  "Asia/Hebron": { "countries": [{ "name": "Palestine", "code": "PS" }], "mcode": "61" },
  "Europe/Lisbon": { "countries": [{ "name": "Portugal", "code": "PT" }], "mcode": "351" },
  "Atlantic/Madeira": { "countries": [{ "name": "Portugal", "code": "PT" }], "mcode": "351" },
  "Atlantic/Azores": { "countries": [{ "name": "Portugal", "code": "PT" }], "mcode": "351" },
  "Pacific/Palau": { "countries": [{ "name": "Palau", "code": "PW" }], "mcode": "680" },
  "America/Asuncion": { "countries": [{ "name": "Paraguay", "code": "PY" }], "mcode": "595" },
  "Indian/Reunion": {
    "countries": [
      { "name": "Réunion", "code": "RE" },
      { "name": "French Southern & Antarctic Lands", "code": "TF" }
    ],
    "mcode": "262"
  },
  "Europe/Bucharest": { "countries": [{ "name": "Romania", "code": "RO" }], "mcode": "40" },
  "Europe/Kaliningrad": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Europe/Moscow": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Europe/Simferopol": {
    "countries": [
      { "name": "Russia", "code": "RU" },
      { "name": "Ukraine", "code": "UA" }
    ],
    "mcode": "7"
  },
  "Europe/Kirov": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Europe/Volgograd": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Europe/Astrakhan": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Europe/Saratov": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Europe/Ulyanovsk": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Europe/Samara": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Asia/Yekaterinburg": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Asia/Omsk": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Asia/Novosibirsk": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Asia/Barnaul": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Asia/Tomsk": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Asia/Novokuznetsk": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Asia/Krasnoyarsk": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Asia/Irkutsk": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Asia/Chita": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Asia/Yakutsk": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Asia/Khandyga": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Asia/Vladivostok": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Asia/Ust-Nera": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Asia/Magadan": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Asia/Sakhalin": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Asia/Srednekolymsk": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Asia/Kamchatka": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Asia/Anadyr": { "countries": [{ "name": "Russia", "code": "RU" }], "mcode": "7" },
  "Africa/Kigali": { "countries": [{ "name": "Rwanda", "code": "RW" }], "mcode": "250" },
  "Pacific/Guadalcanal": { "countries": [{ "name": "Solomon Islands", "code": "SB" }], "mcode": "677" },
  "Indian/Mahe": { "countries": [{ "name": "Seychelles", "code": "SC" }], "mcode": "248" },
  "Africa/Khartoum": { "countries": [{ "name": "Sudan", "code": "SD" }], "mcode": "249" },
  "Europe/Stockholm": { "countries": [{ "name": "Sweden", "code": "SE" }], "mcode": "46" },
  "Asia/Singapore": { "countries": [{ "name": "Singapore", "code": "SG" }], "mcode": "65" },
  "Atlantic/St_Helena": { "countries": [{ "name": "St Helena", "code": "SH" }], "mcode": "290" },
  "Europe/Ljubljana": { "countries": [{ "name": "Slovenia", "code": "SI" }], "mcode": "386" },
  "Arctic/Longyearbyen": { "countries": [{ "name": "Svalbard & Jan Mayen", "code": "SJ" }], "mcode": "61" },
  "Europe/Bratislava": { "countries": [{ "name": "Slovakia", "code": "SK" }], "mcode": "421" },
  "Africa/Freetown": { "countries": [{ "name": "Sierra Leone", "code": "SL" }], "mcode": "232" },
  "Europe/San_Marino": { "countries": [{ "name": "San Marino", "code": "SM" }], "mcode": "378" },
  "Africa/Dakar": { "countries": [{ "name": "Senegal", "code": "SN" }], "mcode": "221" },
  "Africa/Mogadishu": { "countries": [{ "name": "Somalia", "code": "SO" }], "mcode": "252" },
  "America/Paramaribo": { "countries": [{ "name": "Suriname", "code": "SR" }], "mcode": "597" },
  "Africa/Juba": { "countries": [{ "name": "South Sudan", "code": "SS" }], "mcode": "211" },
  "Africa/Sao_Tome": { "countries": [{ "name": "Sao Tome & Principe", "code": "ST" }], "mcode": "239" },
  "America/El_Salvador": { "countries": [{ "name": "El Salvador", "code": "SV" }], "mcode": "503" },
  "America/Lower_Princes": { "countries": [{ "name": "St Maarten (Dutch)", "code": "SX" }], "mcode": "61" },
  "Asia/Damascus": { "countries": [{ "name": "Syria", "code": "SY" }], "mcode": "963" },
  "Africa/Mbabane": { "countries": [{ "name": "Eswatini (Swaziland)", "code": "SZ" }], "mcode": "268" },
  "America/Grand_Turk": { "countries": [{ "name": "Turks & Caicos Is", "code": "TC" }], "mcode": "1649" },
  "Africa/Ndjamena": { "countries": [{ "name": "Chad", "code": "TD" }], "mcode": "235" },
  "Indian/Kerguelen": {
    "countries": [{ "name": "French Southern & Antarctic Lands", "code": "TF" }],
    "mcode": "61"
  },
  "Africa/Lome": { "countries": [{ "name": "Togo", "code": "TG" }], "mcode": "228" },
  "Asia/Dushanbe": { "countries": [{ "name": "Tajikistan", "code": "TJ" }], "mcode": "992" },
  "Pacific/Fakaofo": { "countries": [{ "name": "Tokelau", "code": "TK" }], "mcode": "61" },
  "Asia/Dili": { "countries": [{ "name": "East Timor", "code": "TL" }], "mcode": "670" },
  "Asia/Ashgabat": { "countries": [{ "name": "Turkmenistan", "code": "TM" }], "mcode": "993" },
  "Africa/Tunis": { "countries": [{ "name": "Tunisia", "code": "TN" }], "mcode": "216" },
  "Pacific/Tongatapu": { "countries": [{ "name": "Tonga", "code": "TO" }], "mcode": "676" },
  "Europe/Istanbul": { "countries": [{ "name": "Turkey", "code": "TR" }], "mcode": "90" },
  "Pacific/Funafuti": { "countries": [{ "name": "Tuvalu", "code": "TV" }], "mcode": "688" },
  "Asia/Taipei": { "countries": [{ "name": "Taiwan", "code": "TW" }], "mcode": "886" },
  "Africa/Dar_es_Salaam": { "countries": [{ "name": "Tanzania", "code": "TZ" }], "mcode": "61" },
  "Europe/Kiev": { "countries": [{ "name": "Ukraine", "code": "UA" }], "mcode": "380" },
  "Europe/Uzhgorod": { "countries": [{ "name": "Ukraine", "code": "UA" }], "mcode": "380" },
  "Europe/Zaporozhye": { "countries": [{ "name": "Ukraine", "code": "UA" }], "mcode": "380" },
  "Africa/Kampala": { "countries": [{ "name": "Uganda", "code": "UG" }], "mcode": "256" },
  "Pacific/Wake": { "countries": [{ "name": "US minor outlying islands", "code": "UM" }], "mcode": "61" },
  "Pacific/Honolulu": {
    "countries": [
      { "name": "US minor outlying islands", "code": "UM" },
      { "name": "United States", "code": "US" }
    ],
    "mcode": "61"
  },
  "Pacific/Midway": { "countries": [{ "name": "US minor outlying islands", "code": "UM" }], "mcode": "61" },
  "America/New_York": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/Detroit": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/Kentucky/Louisville": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/Kentucky/Monticello": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/Indiana/Indianapolis": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/Indiana/Vincennes": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/Indiana/Winamac": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/Indiana/Marengo": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/Indiana/Petersburg": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/Indiana/Vevay": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/Chicago": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/Indiana/Tell_City": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/Indiana/Knox": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/Menominee": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/North_Dakota/Center": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/North_Dakota/New_Salem": {
    "countries": [{ "name": "United States", "code": "US" }],
    "mcode": "1"
  },
  "America/North_Dakota/Beulah": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/Denver": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/Boise": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/Phoenix": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/Los_Angeles": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/Anchorage": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/Juneau": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/Sitka": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/Metlakatla": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/Yakutat": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/Nome": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/Adak": { "countries": [{ "name": "United States", "code": "US" }], "mcode": "1" },
  "America/Montevideo": { "countries": [{ "name": "Uruguay", "code": "UY" }], "mcode": "598" },
  "Asia/Samarkand": { "countries": [{ "name": "Uzbekistan", "code": "UZ" }], "mcode": "998" },
  "Asia/Tashkent": { "countries": [{ "name": "Uzbekistan", "code": "UZ" }], "mcode": "998" },
  "Europe/Vatican": { "countries": [{ "name": "Vatican City", "code": "VA" }], "mcode": "379" },
  "America/St_Vincent": { "countries": [{ "name": "St Vincent", "code": "VC" }], "mcode": "784" },
  "America/Caracas": { "countries": [{ "name": "Venezuela", "code": "VE" }], "mcode": "58" },
  "America/Tortola": { "countries": [{ "name": "Virgin Islands (UK)", "code": "VG" }], "mcode": "1" },
  "America/St_Thomas": { "countries": [{ "name": "Virgin Islands (US)", "code": "VI" }], "mcode": "1" },
  "Asia/Ho_Chi_Minh": { "countries": [{ "name": "Vietnam", "code": "VN" }], "mcode": "84" },
  "Pacific/Efate": { "countries": [{ "name": "Vanuatu", "code": "VU" }], "mcode": "678" },
  "Pacific/Wallis": { "countries": [{ "name": "Wallis & Futuna", "code": "WF" }], "mcode": "681" },
  "Pacific/Apia": { "countries": [{ "name": "Samoa (western)", "code": "WS" }], "mcode": "685" },
  "Asia/Aden": { "countries": [{ "name": "Yemen", "code": "YE" }], "mcode": "969" },
  "Indian/Mayotte": { "countries": [{ "name": "Mayotte", "code": "YT" }], "mcode": "269" },
  "Africa/Lusaka": { "countries": [{ "name": "Zambia", "code": "ZM" }], "mcode": "260" },
  "Africa/Harare": { "countries": [{ "name": "Zimbabwe", "code": "ZW" }], "mcode": "263" }
}
