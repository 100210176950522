"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateFormFieldValidator = void 0;
class DateFormFieldValidator {
    hasAnyData(field) {
        return this.isValidDate(field.startDate) || this.isValidDate(field.endDate);
    }
    hasCompleteData(field) {
        if (field.isSingleDate) {
            return this.isValidDate(field.startDate);
        }
        return this.isValidDate(field.startDate) && this.isValidDate(field.endDate);
    }
    isValid(field) {
        if (field.isRequired && !this.hasCompleteData(field)) {
            return false;
        }
        if (!field.isSingleDate && !this.isDateRangeValid(field.startDate, field.endDate)) {
            return false;
        }
        return true;
    }
    invalidDescription(field) {
        if (field.isRequired && !this.hasCompleteData(field)) {
            return '';
        }
        if (field._invalid) {
            return 'Start date/time should be before end date/time';
        }
        return '';
    }
    isDateRangeValid(startDate, endDate) {
        if (startDate && endDate) {
            return new Date(endDate) > new Date(startDate);
        }
        return true;
    }
    isValidDate(value) {
        if (!value) {
            return false;
        }
        const date = new Date(value);
        return !Number.isNaN(Number(date.getTime()));
    }
}
exports.DateFormFieldValidator = DateFormFieldValidator;
