import { Injectable } from '@angular/core';

import {
  ComplementarySignatureRuleLogic,
  Field,
  SignatureLogicActions,
  SignatureRuleLogic,
} from '@site-mate/dashpivot-shared-library';

import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { AccountService } from 'app/shared/service/account.service';

import { flattenRuleTree, getRuleKey } from './approval-signature.helper';
import {
  DropdownNames,
  ComplementaryDropdownNames,
  PlanMaxComplementaryLogicRules,
  LimitUpgradablePlans,
} from './logic-rule/signature-logic-rule/signature-logic-rule-helpers';

@Injectable({
  providedIn: 'root',
})
export class ApprovalSignatureService {
  readonly andMaxRules = 3;

  constructor(
    private readonly i18nService: TmpI18NService,
    private readonly accountService: AccountService,
  ) {}

  hasIncompleteRule(approvalSignatures: Field[]) {
    const signaturesWithLogic =
      approvalSignatures.filter((signature) => signature.signatureRules?.length) ?? [];

    return signaturesWithLogic
      .map((signature) => this.validateRuleFields(signature.signatureRules))
      .some((isValid) => isValid);
  }

  isValidRule(rule: SignatureRuleLogic): boolean {
    return !!rule.type && !!rule.operator && !!rule.values?.length && !!rule.action;
  }

  validateRuleFields(rules: SignatureRuleLogic[]) {
    rules.forEach((rule) => {
      this.validateAllRequiredInputs(rule, Object.values(DropdownNames));

      if (rule.complementaryRules?.length) {
        this.validateMaxRules(rule);
        this.setInvalidDuplicatedComplementaryRules(rule);
      }
    });

    this.isActionsInvalid(rules);

    return rules.some((rule) => this.hasInvalidInputs(rule));
  }

  private validateMaxRules(rule: SignatureRuleLogic | ComplementarySignatureRuleLogic, maxRules?: number) {
    const accountPlanType = this.accountService.getAccountPlanType();
    const isLimitUpgradable = LimitUpgradablePlans.includes(accountPlanType);
    const maxRulesFinal = maxRules ?? PlanMaxComplementaryLogicRules[accountPlanType];
    const maxNestedRules = maxRulesFinal - 1;

    if (isLimitUpgradable && rule.complementaryRules?.length > maxRulesFinal) {
      rule.complementaryRules.forEach((complementaryRule) => {
        this.setInvalidStateToComplementaryRules(rule, complementaryRule, maxNestedRules);
      });
      // We need this for when AND rules do not exceed max rules, but we still need to check the OR rules
    } else if (isLimitUpgradable) {
      rule.complementaryRules?.forEach((complementaryRule) => {
        this.validateMaxNestedRules(complementaryRule, maxNestedRules);
      });
    }
  }

  private validateMaxNestedRules(complementaryRule: ComplementarySignatureRuleLogic, maxNestedRules: number) {
    const nestedLength = complementaryRule.complementaryRules?.length;
    if (nestedLength && nestedLength > maxNestedRules) {
      complementaryRule.complementaryRules.forEach((nestedComplementaryRule) => {
        this.setInvalidStateToComplementaryRules(complementaryRule, nestedComplementaryRule, maxNestedRules);
      });
      ['type', 'operator', 'values'].forEach((input) =>
        complementaryRule._invalidInputs.push(`${input}-${complementaryRule._id}`),
      );
      complementaryRule._invalid = true;
      complementaryRule._invalidMessage = this.i18nService.getMessage('planExceededLogicRules');
    }
  }

  private setInvalidStateToComplementaryRules(
    rule: SignatureRuleLogic | ComplementarySignatureRuleLogic,
    complementaryRule: SignatureRuleLogic | ComplementarySignatureRuleLogic,
    _maxNestedRules: number,
  ) {
    const inputs = ['type', 'operator', 'values'];

    inputs.forEach((input) => complementaryRule._invalidInputs.push(`${input}-${complementaryRule._id}`));
    complementaryRule._invalid = true;
    complementaryRule._invalidMessage = this.i18nService.getMessage('planExceededLogicRules');

    complementaryRule.complementaryRules?.forEach((nestedComplementaryRule) => {
      inputs.forEach((input) =>
        nestedComplementaryRule._invalidInputs.push(`${input}-${nestedComplementaryRule._id}`),
      );
      nestedComplementaryRule._invalid = true;
      nestedComplementaryRule._invalidMessage = this.i18nService.getMessage('planExceededLogicRules');
    });
  }

  private validateAllRequiredInputs(
    rule: SignatureRuleLogic | ComplementarySignatureRuleLogic,
    requiredInputNames: (`${DropdownNames}` | `${ComplementaryDropdownNames}`)[],
  ) {
    rule._invalidInputs = [];

    requiredInputNames.forEach((inputName) => {
      this.validateRequiredInput(rule, inputName);
    });

    rule._invalid = !!rule._invalidInputs?.length;
    rule._invalidMessage = rule._invalid ? this.i18nService.getMessage('inputRequiredError') : '';

    if (rule.complementaryRules?.length) {
      rule.complementaryRules.forEach((complementaryRule) => {
        this.validateAllRequiredInputs(complementaryRule, Object.values(ComplementaryDropdownNames));
      });
    }
  }

  private validateRequiredInput(
    rule: SignatureRuleLogic | ComplementarySignatureRuleLogic,
    property: string,
  ): void {
    const isMissingProperty = !rule[property];
    const isArrayEmpty = Array.isArray(rule[property]) && !rule[property].length;

    if (isMissingProperty || isArrayEmpty) {
      rule._invalidInputs.push(`${property}-${rule._id}`);
    }
  }

  private hasInvalidInputs(rule: SignatureRuleLogic | ComplementarySignatureRuleLogic): boolean {
    return (
      !!rule._invalidInputs?.length ||
      !!rule.complementaryRules?.some((complementaryRule) => this.hasInvalidInputs(complementaryRule))
    );
  }

  isActionsInvalid(rules: SignatureRuleLogic[]): void {
    const actions = rules?.map((rule) => rule.action);
    const isNotUniqueActions = new Set(actions).size !== actions.length;
    const hasGroupedAction = actions.find(
      (action) => action === SignatureLogicActions.ApprovalSignatureAndResetWorkflow,
    );

    if ((actions.length > 1 && hasGroupedAction) || isNotUniqueActions) {
      rules.forEach((rule) => {
        rule._invalidInputs.push(`action-${rule._id}`);
        rule._invalid = true;
        rule._invalidMessage = this.i18nService.getMessage('templateDuplicatedLogicActions');
      });
    }
  }

  setInvalidDuplicatedComplementaryRules(parentRule: SignatureRuleLogic): void {
    const allRules = flattenRuleTree(parentRule);

    const ruleCounts = allRules.reduce((acc, rule) => {
      if (this.isValidBaseRule(rule)) {
        const key = getRuleKey(rule);
        acc[key] = acc[key] ? acc[key] + 1 : 1;
      }
      return acc;
    }, {});

    allRules.forEach((rule) => {
      if (!this.isValidBaseRule(rule)) {
        return;
      }

      const key = getRuleKey(rule);
      const isDuplicated = ruleCounts[key] > 1;

      if (isDuplicated && rule._id !== parentRule._id) {
        const inputs = ['type', 'operator', 'values'];
        inputs.forEach((input) => rule._invalidInputs.push(`${input}-${rule._id}`));

        rule._invalid = true;
        rule._invalidMessage = this.i18nService.getMessage('templateDuplicatedComplementaryRule');
      }
    });
  }

  private isValidBaseRule(rule: ComplementarySignatureRuleLogic): boolean {
    return !!rule.type && !!rule.operator && !!rule.values?.length;
  }
}
