"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.YesNoFieldCloner = void 0;
const generic_field_1 = require("./generic-field");
const yes_no_model_1 = require("../../models/fields/yes-no.model");
class YesNoFieldCloner extends generic_field_1.GenericFieldCloner {
    mergeField(templateField, formField, clearLogicFields = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const mergedField = Object.assign(Object.assign(Object.assign({}, templateField), formField), { description: templateField.description, content: templateField.content, isRequired: templateField.isRequired, dependsOn: templateField.dependsOn, logicRules: this.mergeLogicRules(templateField.logicRules, formField.logicRules, clearLogicFields), checked: formField.checked, hasNaButton: templateField.hasNaButton, hasTextInput: templateField.hasTextInput });
            if (!mergedField.hasTextInput) {
                delete mergedField.textInputValue;
            }
            if (!mergedField.hasNaButton && mergedField.checked === yes_no_model_1.YesNoValues.NA) {
                delete mergedField.checked;
            }
            return mergedField;
        });
    }
}
exports.YesNoFieldCloner = YesNoFieldCloner;
