<!-- Default Button -->
<button
  type="button"
  *ngIf="!isPopover"
  class="counter button-regular-w-icon-l button-white sm-border-2 sm-border-grey-3"
  [ngClass]="stylingChangesOnHover ? 'hover-' + counterClass : counterClass"
  (mouseenter)="triggerPercentageWarnings(true)"
  (mouseleave)="triggerPercentageWarnings(false)"
>
  <ng-container *ngTemplateOutlet="counterContent"></ng-container>
</button>
<!-- Popover -->
<span (mouseleave)="counterButton.close()" *ngIf="isPopover">
  <ng-template #counterTemplate>
    <ng-content></ng-content>
  </ng-template>
  <button
    type="button"
    class="counter button-regular-w-icon-l button-white"
    [ngClass]="stylingChangesOnHover ? 'hover-' + counterClass : counterClass"
    tooltipClass="tip-container initial-white-space editor-tooltip limits-counter"
    triggers="manual"
    [placement]="popoverPlacement"
    #counterButton="ngbTooltip"
    id="counterButton"
    [ngbTooltip]="counterTemplate"
    (mouseenter)="counterButton.open(); triggerPercentageWarnings(true)"
    (mouseleave)="triggerPercentageWarnings(false)"
    autoClose="inside"
  >
    <ng-container *ngTemplateOutlet="counterContent"></ng-container>
  </button>
</span>

<ng-template #counterContent>
  <i class="cc-icon cc-icon-question sm-text-default-text" aria-hidden="true" *ngIf="!displayWarningIcon"></i>
  <i class="cc-icon cc-icon-warning sm-text-default-red" aria-hidden="true" *ngIf="displayWarningIcon"></i>
  <span class="sm-whitespace-pre">{{ title }}:&nbsp;</span>
  <p class="counter-block sm-inline-block sm-font-bold" data-test="counter">
    {{ counter }}
  </p>
</ng-template>
