import {
  Component,
  Output,
  EventEmitter,
  HostListener,
  ViewEncapsulation,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'cc-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentComponent {
  @Input() displaySelectionCheckbox = true;
  @Output() onSelectionToggle = new EventEmitter();
  @Output() onClick = new EventEmitter();

  @Input()
  @HostBinding('class.selected')
  selected = false;

  @HostListener('click', ['$event'])
  onDocumentClick(event) {
    if (event.ctrlKey || event.metaKey) {
      this.toggleSelection();
    } else {
      this.onClick.emit();
    }
  }

  toggleSelection() {
    this.selected = !this.selected;
    this.onSelectionToggle.emit(this.selected);
  }
}
