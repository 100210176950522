<div class="avatar" [ngClass]="{ 'sm-grayscale': user?.archived }">
  <img
    [src]="getAvatarUrl(user.avatar)"
    data-test="avatar-img"
    alt="User Avatar"
    *ngIf="user?.avatar; else initials"
    class="rounded-circle sm-inline-block"
  />
</div>

<ng-template #initials>
  <div class="badge team-member-badge" [ngClass]="getInitialStyle" [title]="getFullNameOrEmail">
    {{ getInitial }}
  </div>
</ng-template>
