"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenericFieldCloner = void 0;
class GenericFieldCloner {
    constructor(getListItems) {
        this.getListItems = getListItems;
    }
    mergeLogicRules(templateLogicRules, formFieldLogicRules, clearLogicFields) {
        return clearLogicFields
            ? templateLogicRules
            : templateLogicRules === null || templateLogicRules === void 0 ? void 0 : templateLogicRules.map((rule) => (Object.assign(Object.assign({}, rule), { isTrue: formFieldLogicRules === null || formFieldLogicRules === void 0 ? void 0 : formFieldLogicRules.some((fRule) => fRule.id === rule.id && fRule.isTrue) })));
    }
    mergeField(templateField, formField) {
        return __awaiter(this, void 0, void 0, function* () {
            // merge the template + form field together, then update the field's kind & template logic dependsOn to match the template
            return Object.assign(Object.assign(Object.assign({}, templateField), formField), { description: templateField.description, isRequired: templateField.isRequired, isPhotoDescriptionVisible: templateField.isPhotoDescriptionVisible, isPhotoTagsVisible: templateField.isPhotoTagsVisible, dependsOn: templateField.dependsOn, logicRules: templateField.logicRules, kind: templateField.kind });
        });
    }
}
exports.GenericFieldCloner = GenericFieldCloner;
