"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableCellKinds = void 0;
var TableCellKinds;
(function (TableCellKinds) {
    TableCellKinds["Attachment"] = "attachment";
    TableCellKinds["AvatarAndName"] = "avatarAndName";
    TableCellKinds["Date"] = "date";
    TableCellKinds["DatePlain"] = "datePlain";
    TableCellKinds["Formula"] = "formula";
    TableCellKinds["Image"] = "image";
    TableCellKinds["Latitude"] = "latitude";
    TableCellKinds["List"] = "list";
    TableCellKinds["ListProperty"] = "listProperty";
    TableCellKinds["Location"] = "location";
    TableCellKinds["Longitude"] = "longitude";
    TableCellKinds["ManualSignature"] = "manualSignature";
    TableCellKinds["Map"] = "map";
    TableCellKinds["Number"] = "number";
    TableCellKinds["Photo"] = "photo";
    TableCellKinds["PrefilledText"] = "prefilledText";
    TableCellKinds["Signature"] = "signature";
    TableCellKinds["Text"] = "text";
    TableCellKinds["Time"] = "time";
    TableCellKinds["TimePlain"] = "timePlain";
})(TableCellKinds = exports.TableCellKinds || (exports.TableCellKinds = {}));
