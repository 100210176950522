import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { PhotoModalToolBaseComponent } from 'app/photo/photo-modal-tool-base.component';
import { AppUtilService } from 'app/shared/service/app-util.service';
import { ToastrService } from 'app/shared/service/toastr.service';
import { UserService } from 'app/user/user.service';

@Component({
  selector: 'cc-photo-modal-tool-bar',
  templateUrl: 'photo-modal-tool-bar.component.html',
  styleUrls: ['photo-modal-tool-bar.component.scss', '../../styles/components/modal-tool-bar.scss'],
})
export class PhotoModalToolBarComponent extends PhotoModalToolBaseComponent {
  @Input()
  downloadingPDF;

  @Input()
  photo;

  @Output()
  exportPDF = new EventEmitter<void>();

  @Output()
  exportOriginal = new EventEmitter<void>();

  @Output()
  remove = new EventEmitter<void>();

  @Output()
  fullScreen = new EventEmitter<void>();

  @Output()
  close = new EventEmitter<void>();

  @Input()
  hideDeleteButton;

  constructor(
    public readonly activeModal: NgbActiveModal,
    public readonly appUtilService: AppUtilService,
    private readonly userService: UserService,
    private readonly toastr: ToastrService,
  ) {
    super();
  }

  onRemove() {
    if (this.canDelete()) {
      this.remove.emit();
    } else {
      this.toastr.errorByKey('noPermissionDelete');
    }
  }

  canDelete() {
    return this.userService.isTeamControllerOrAbove() || this.userService.isCurrentUser(this.photo.createdBy);
  }

  onExportOriginal() {
    this.exportOriginal.emit();
  }

  onExportPDF() {
    this.exportPDF.emit();
  }
}
