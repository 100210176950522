<div class="links-container">
  <div class="panel-with-links">
    <div class="panel-tabs" *ngIf="displayTabsPanel">
      <ng-content select="[tabsPanel]"></ng-content>
    </div>

    <div class="panel-content">
      <div class="panel-info animate-fade-in" *ngIf="displayInfoPanel">
        <ng-content select="[infoPanel]"></ng-content>
      </div>

      <div class="panel-container animate-fade-in">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
