import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { CanvasOptions } from './signature-canvas-default-options.model';

type UserInfo = { firstName: string; lastName: string };

@Component({
  selector: 'cc-digital-font-signature',
  templateUrl: 'digital-font-signature.component.html',
  styleUrls: ['./digital-font-signature.component.scss'],
})
export class DigitalFontSignatureComponent {
  private readonly canvasOffset = 20;
  private readonly fontSizes = { small: 18, medium: 24, large: 32 };

  @Input({ required: true }) form!: FormGroup;
  @Input({ required: true }) userInfo!: UserInfo;
  @Input({ required: true }) options!: CanvasOptions;
  @Input() previewContainerStyle: 'xs' | 'hug' | 'full' = 'hug';

  @Output() signatureChange = new EventEmitter<string>();

  @ViewChild('signatureCanvas') signatureCanvas: ElementRef<HTMLCanvasElement>;

  ngOnInit() {
    this.form.controls.name.valueChanges.subscribe(() => this.convertToImage());

    this.form.patchValue({ name: this.getDefaultSignatureName() });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userInfo) {
      this.form.patchValue({ name: this.getDefaultSignatureName() });
    }
  }

  ngAfterViewInit() {
    if (!this.signatureCanvas) {
      return;
    }

    this.convertToImage();
  }

  private getDefaultSignatureName(): string {
    const { firstName, lastName } = this.userInfo;

    if (!firstName?.trim() || !lastName?.trim()) {
      return '';
    }

    const fullName = `${firstName} ${lastName}`;
    if (fullName.length <= 32) {
      return fullName;
    }

    const firstNameShortened = `${firstName.charAt(0)} ${lastName}`;
    const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`;

    return firstNameShortened.length <= 32 ? firstNameShortened : initials;
  }

  private convertToImage(): void {
    const canvasContext = this.signatureCanvas?.nativeElement.getContext('2d');
    this.setCanvasSizeAndStyle(this.options.canvasWidth, this.options.canvasHeight);

    const { controls } = this.form;
    const isNameEmpty = !controls.name || controls.name.value.trim() === '';

    if (isNameEmpty) {
      this.form.patchValue({ signature: '' });
      this.signatureChange.emit('');
      return;
    }

    if (canvasContext?.canvas) {
      const widthOffset = this.options.canvasWidth / 2;
      const heightOffset = this.options.canvasHeight / 2;
      const maxWidth = this.options.canvasWidth - this.canvasOffset;

      canvasContext.fillText(controls.name.value, widthOffset, heightOffset, maxWidth);

      const imageSrc = canvasContext.canvas?.toDataURL('image/png');

      this.form.patchValue({ signature: imageSrc });

      this.signatureChange.emit(imageSrc);
    }
  }

  private setCanvasSizeAndStyle(width: number, height: number): void {
    const canvas = this.signatureCanvas?.nativeElement;

    if (canvas) {
      canvas.width = width;
      canvas.height = height;

      const canvasContext = canvas.getContext('2d');

      const textSize = this.form.controls.name.value.length;
      const fontSize = this.getFontSize(textSize);

      canvasContext.font = `${fontSize}px 'Zeyada', cursive`;
      canvasContext.textAlign = 'center';
      canvasContext.textBaseline = 'middle';

      canvasContext.clearRect(0, 0, this.options.canvasWidth, this.options.canvasHeight);
    }
  }

  private getFontSize(nameSize: number): number {
    switch (true) {
      case nameSize < 15:
        return this.fontSizes.large;
      case nameSize > 32:
        return this.fontSizes.small;
      default:
        return this.fontSizes.medium;
    }
  }
}
