<div class="form-photo-modal-container">
  <div class="modal-header">
    <h4 class="h4 modal-title" i18n>Select photos</h4>
    <button type="button" aria-label="Close" (click)="close()">
      <em class="cc-icon cc-icon-close"></em>
    </button>
  </div>
  <div class="form-photo-modal-body sm-overflow-hidden">
    <cc-photo
      [hierarchy]="hierarchy"
      [formRef]="formRef"
      [uploaderLabels]="uploaderLabels"
      (selectedPhoto)="onPhotoTick($event)"
      [acceptedFiles]="acceptedFiles"
    ></cc-photo>
  </div>
  <div class="modal-footer justify-content-center">
    <button type="button" (click)="select()" class="btn-save btn btn-success">
      <span i18n>Select</span>
      <small *ngIf="selected.length > 0">({{ selected.length }})</small>
    </button>
    <div class="clearfix"></div>
  </div>
</div>
