<cc-state-toggle
  [defaultValue]="editSignatureType"
  [theme]="stateToggleTheme"
  (change)="handleSignatureEditModeChange($event)"
>
  <cc-state-toggle-item [value]="editSignatureTypes.Default">
    <i class="cc-icon cc-icon-signature" aria-hidden="true"></i>
    <span i18n>Default</span>
  </cc-state-toggle-item>

  <cc-state-toggle-item [value]="editSignatureTypes.Draw">
    <i class="cc-icon cc-icon-line-width-m" aria-hidden="true"></i>
    <span i18n>Draw</span>
  </cc-state-toggle-item>

  <cc-state-toggle-item [value]="editSignatureTypes.Upload">
    <i class="cc-icon cc-icon-upload" aria-hidden="true"></i>
    <span i18n>Upload</span>
  </cc-state-toggle-item>
</cc-state-toggle>

<div
  data-test="default-signature-container"
  [hidden]="editSignatureType !== editSignatureTypes.Default"
  class="edit-signature-body"
>
  <p i18n>Confirm your name. You'll use your signature to sign-off and approve forms in Dashpivot.</p>

  <cc-digital-font-signature
    previewContainerStyle="xs"
    [form]="digitalFontSignature"
    [options]="signaturePadOptions"
    [userInfo]="{ firstName: form.controls.firstName?.value, lastName: form.controls.lastName?.value }"
    (signatureChange)="digitalSignatureChange()"
  ></cc-digital-font-signature>
</div>

<div
  data-test="draw-signature-container"
  [hidden]="editSignatureType !== editSignatureTypes.Draw"
  class="edit-signature-body"
>
  <p i18n>Draw your signature below.</p>

  <div class="sm-flex sm-gap-4">
    <div>
      <cc-signature-pad
        [options]="signaturePadOptions"
        (end)="drawComplete($event)"
        [hasError]="
          drawSignatureData.hasEdited && form.controls.signature.invalid && !form.controls.signature.value
        "
      ></cc-signature-pad>
      <div
        *ngIf="
          drawSignatureData.hasEdited && form.controls.signature.invalid && !form.controls.signature.value
        "
        class="sm-text-default-red"
        data-test="signature-invalid"
        i18n
      >
        Signature is required.
      </div>
    </div>

    <div>
      <button
        type="button"
        class="btn btn-secondary btn-sm"
        title="Clear"
        i18n-title="Clear"
        (click)="clearSignature()"
        [disabled]="!drawSignatureData.value"
      >
        <i class="cc-icon cc-icon-undo"></i>
      </button>
    </div>
  </div>
</div>

<div
  data-test="upload-signature-container"
  [hidden]="editSignatureType !== editSignatureTypes.Upload"
  class="edit-signature-body"
>
  <p i18n>Upload your signature.</p>

  <div *ngIf="!uploadSignatureData.value; else showFileToUpload">
    <label class="default-label btn btn-primary">
      <span i18n>Upload signature</span>
      <span class="loading-spinner" *ngIf="uploading">
        <i class="cc-icon cc-icon-spinner-fw"></i>
      </span>
      <input type="file" name="file" class="d-none" (change)="uploadSignature($event)" accept="image/*" />
    </label>

    <div
      *ngIf="
        !uploading &&
        uploadSignatureData.hasEdited &&
        form.controls.signature.invalid &&
        !form.controls.signature.value
      "
      class="sm-text-default-red"
      data-test="signature-invalid"
      i18n
    >
      Signature is required.
    </div>
  </div>
</div>

<ng-template #showFileToUpload>
  <div class="sm-flex sm-gap-4">
    <cc-signature-pad
      [src]="uploadSignatureData.value"
      [height]="signaturePadOptions.canvasHeight"
      [width]="signaturePadOptions.canvasWidth"
    ></cc-signature-pad>

    <div>
      <button
        type="button"
        class="btn btn-secondary btn-sm"
        title="Remove"
        i18n-title="Remove"
        (click)="removeSignature()"
      >
        <i class="cc-icon cc-icon-delete sm-text-default-red"></i>
      </button>
    </div>
  </div>
</ng-template>
