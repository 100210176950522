<section class="top-section" [ngClass]="placeholderHeightStyle()">
  <button class="close-button" type="button" aria-label="Close" (click)="onClose()">
    <i class="cc-icon cc-icon-close" aria-hidden="true"></i>
  </button>
  <div *ngIf="showLoadingSpinner" class="cc-icon cc-icon-spinner-3x"></div>
  <img [src]="imageSrc" [alt]="imageAlt" />
</section>
<h1 class="sm-text-xl sm-font-medium" data-test="title" i18n>{{ title }}</h1>
<p *ngIf="!hasInnerHtmlSubtitle" class="sm-text-center sm-text-sm" i18n data-test="subtitle">
  {{ subtitle }}
</p>
<p *ngIf="hasInnerHtmlSubtitle" class="sm-text-center" i18n data-test="subtitle" [innerHTML]="subtitle"></p>

<section class="sm-flex sm-space-x-2">
  <button type="button" class="cta-button intercom-button" (click)="onOpenLiveChat()">
    <i class="cc-icon cc-icon-chat" aria-hidden="true"></i>
    <span class="sm-text-sm sm-font-medium" i18n>Chat with us</span>
  </button>
  <a
    class="cta-button email-button"
    href="mailto:info@sitemate.com?subject=Upgrade%20Subscription - {{ title }}"
  >
    <i class="cc-icon cc-icon-send" aria-hidden="true"></i>
    <span class="sm-text-sm sm-font-medium" i18n>Email us</span>
  </a>
</section>
