<ng-container *ngIf="listRows$ | async as listRows" [formGroup]="form">
  <ng-select
    *ngIf="listRows.state === QueryStates.Loading || listRows.state === QueryStates.Fetched"
    formControlName="selectedItem"
    class="form-field-list-cell"
    [items]="listRows.data"
    [virtualScroll]="
      listRows.state === QueryStates.Fetched && listRows.data.length > virtualScrollerThreshold
    "
    i18n-placeholder="Select"
    placeholder="Select"
    appendTo="body"
    (open)="onOpen()"
    (blur)="select.close()"
    [loading]="listRows.state === QueryStates.Loading"
    loadingText="Loading..."
    bindLabel="data.value"
    #select
  >
    <ng-template ng-header-tmp>
      <button
        type="button"
        data-test="clear-list-selection"
        (click)="clearSelection(); select.close()"
        class="sm-flex sm-w-full sm-items-center"
      >
        <i class="cc-icon cc-icon-close -sm-ml-0.4"></i>
        <span class="list-item-label sm-text-default-text">Clear Selection</span>
      </button>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <div class="sm-flex" data-test="list-item">
        <div class="sm-text-transparent rounded-square {{ item.data.style }}">.</div>
        <span class="list-item-label">{{ item.data.value }}</span>
      </div>
    </ng-template>
  </ng-select>

  <div *ngIf="listRows.state === QueryStates.Empty" data-test="no-selection" i18n>No selection</div>
</ng-container>
