import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthWellKnownEndpoints, OidcSecurityService } from 'angular-auth-oidc-client';
import { lastValueFrom } from 'rxjs';

import { JwtService } from 'app/auth/jwt.service';
import { FeatureFlagService } from 'app/shared/service/feature-flag.service';
import { LocalStorageService } from 'app/shared/service/local-storage.service';
import { SignupResponse } from 'app/user/sign-up-simplified/model/signup-response.model';
import { UserRedirectionModalComponent } from 'app/user/user-redirection-modal/user-redirection-modal.component';
import { UserService } from 'app/user/user.service';
import { environment } from 'environments/environment';

@Injectable()
export class AuthService {
  private readonly forceLogoutSSOFlag = 'ssoUserForceLogout';
  private readonly customAuthRedirectUrl = 'customAuthRedirectUrl';

  constructor(
    private readonly jwtService: JwtService,
    private readonly userService: UserService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly httpClient: HttpClient,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
    private readonly modal: NgbModal,
  ) {}

  async logout(redirectAfterLogout = true) {
    this.userService.unsetCurrentUser();
    this.featureFlagService.resetFeatureFlag();
    const token = await this.jwtService.getToken();

    if (this.isFusionAuthToken(token)) {
      await lastValueFrom(this.oidcSecurityService.logoff());
      return;
    }

    this.jwtService.destroyToken();
    if (redirectAfterLogout) {
      void this.router.navigate(['/', 'login']);
    }
  }

  async storeAuthTokens(loginData: SignupResponse['loginData']) {
    const configuration = await this.oidcSecurityService.getConfiguration().toPromise();
    const { configId } = configuration;

    if (!configId) {
      return;
    }

    const authWellKnownEndpoints = await this.oidcSecurityService.preloadAuthWellKnownDocument().toPromise();

    const jwtKeys = await this.getFusionAuthPublicKeys(authWellKnownEndpoints);

    const authStorage = this.generateAuthStorage(loginData, authWellKnownEndpoints, jwtKeys);

    this.localStorageService.setItem(configId, JSON.stringify(authStorage));

    await this.userService.updateCurrentUser().toPromise();
  }

  isFusionAuthToken(token?: string): boolean {
    if (!token) {
      return false;
    }

    const payload = this.getTokenPayload(token);

    return payload?.applicationId === environment.fusionAuth.clientId;
  }

  private getTokenPayload(token: string) {
    const stringPayload = token && token.split('.').length === 3 && atob(token.split('.')[1]);

    return stringPayload ? JSON.parse(stringPayload) : null;
  }

  showRedirectionModal(message: string, redirectAfterMsTime = 3000) {
    const { email } = this.userService.getCurrentUser();

    setTimeout(() => {
      if (email) {
        this.logoutAndRedirectToSSO(email);
        return;
      }

      void this.router.navigate(['/', 'login']);
    }, redirectAfterMsTime);

    this.clearForceLogoutFlagForSSOSignUp();

    const modalRef = this.modal.open(UserRedirectionModalComponent, {
      windowClass: 'modal-400',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.message = message;
  }

  setForceLogoutFlagForSSOSignUp() {
    this.localStorageService.setItem(this.forceLogoutSSOFlag, 'true');
  }

  getForceLogoutFlagForSSOSignUp() {
    return this.localStorageService.getItem(this.forceLogoutSSOFlag);
  }

  setCustomAuthRedirectURL(templateId: string) {
    this.localStorageService.setItem(this.customAuthRedirectUrl, templateId);
  }

  getCustomAuthRedirectURL() {
    return this.localStorageService.getItem(this.customAuthRedirectUrl);
  }

  clearCustomAuthRedirectURL() {
    this.localStorageService.removeItem(this.customAuthRedirectUrl);
  }

  private clearForceLogoutFlagForSSOSignUp() {
    this.localStorageService.removeItem(this.forceLogoutSSOFlag);
  }

  private logoutAndRedirectToSSO(email: string) {
    void this.logout(false);
    this.oidcSecurityService.authorize(null, { customParams: { username: email } });
  }

  private getFusionAuthPublicKeys(authWellknownEndpoints: AuthWellKnownEndpoints) {
    if (!authWellknownEndpoints.jwksUri) {
      throw new Error('Cannot find public keys');
    }

    return this.httpClient.get(authWellknownEndpoints.jwksUri).toPromise();
  }

  private generateAuthStorage(
    loginData: SignupResponse['loginData'],
    authWellKnownEndpoints: AuthWellKnownEndpoints,
    jwtKeys: any,
  ) {
    const accessTokenPayload = this.getTokenPayload(loginData.accessToken);

    // calculate time left to token expiration
    const expiration = Math.floor((loginData.expiration - new Date().getTime()) / 1000);

    const authStorage = {
      authWellKnownEndPoints: authWellKnownEndpoints,
      authStateControl: '',
      authNonce: null,
      codeVerifier: '',
      authnResult: {
        access_token: loginData.accessToken,
        expires_in: expiration,
        id_token: loginData.accessToken,
        refresh_token: loginData.refreshToken,
        refresh_token_id: loginData.refreshTokenId,
        token_type: 'Bearer',
        userId: loginData.userId,
        state: '',
        session_state: '',
      },
      jwtKeys,
      authzData: loginData.accessToken,
      access_token_expires_at: loginData.accessTokenExpiration,
      userData: accessTokenPayload,
      session_state: '',
      storageSilentRenewRunning: '',
    };

    return authStorage;
  }
}
