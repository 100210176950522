import { Directive, HostListener, Input } from '@angular/core';

import { DashpivotEvent, EventNotifierService, EventTypes, Event } from '@site-mate/dashpivot-shared-library';

import { SegmentService } from './segment.service';

@Directive({
  selector: '[ccSegmentCTA]',
})
export class SegmentCTADirective {
  @Input('ccSegmentCTA')
  ctaName: string;

  @Input()
  ctaContext: string;

  @Input()
  ctaHierarchy: string;

  readonly defaultName = 'CTA';

  constructor(private readonly segmentService?: SegmentService) {}

  @HostListener('click')
  onClick() {
    // TODO: use the EventTypes enumeration directly
    const eventName = `${this.ctaName || this.defaultName} Clicked` as EventTypes;
    const metadata: Omit<Event['metadata'], 'Product'> = {};

    if (this.ctaContext) {
      metadata.Context = this.ctaContext;
    }
    if (this.ctaHierarchy) {
      metadata.Hierarchy = this.ctaHierarchy;
    }
    void EventNotifierService.notify(new DashpivotEvent(eventName, metadata), this.segmentService);
  }
}
