/* eslint-disable no-restricted-globals */
import { PlatformLocation } from '@angular/common';
import { OnDestroy, Directive } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class ModalBaseComponent implements OnDestroy {
  isListening;

  private unSubscribe = new Subject<void>();

  handler;

  constructor(
    private lo: PlatformLocation,
    private am: NgbActiveModal,
  ) {
    this.handler = this.getHandler();
    history.pushState({ isModal: true }, null, `${this.lo.pathname}?modal`);
    window.onpopstate = this.handler;
    this.isListening = true;
    this.setupKeyboardListener();
  }

  abstract onEsc();

  setupKeyboardListener() {
    fromEvent(document, 'keyup')
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((event: KeyboardEvent) => {
        if (event.keyCode === 27 && this.isListening) {
          this.onEsc();
        }
      });
  }

  disableListener() {
    this.isListening = false;
  }

  enableListener() {
    this.isListening = true;
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }

  getHandler() {
    return () => {
      this.closeSwalIfExists();
      this.removePopstateHandler();
      this.am.dismiss();
    };
  }

  closeSwalIfExists() {
    if (Swal.isVisible()) {
      Swal.close();
    }
  }

  removePopstateHandler() {
    window.onpopstate = null;
  }

  goBack() {
    this.removePopstateHandler();
    history.back();
  }

  closeModal(param?) {
    this.goBack();
    this.am.close(param);
  }

  dismissModal(param?) {
    this.goBack();
    this.am.dismiss(param);
  }

  directCloseModal() {
    this.am.dismiss();
  }
}
