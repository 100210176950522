import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SitemateImageUtils } from 'app/shared/service/sitemate-image-utils';

@Component({
  selector: 'cc-user-avatar',
  templateUrl: 'user-avatar.component.html',
  styleUrls: ['user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent {
  @Input() user?: {
    firstName?: string;
    lastName?: string;
    fullName?: string;
    email?: string;
    avatar?: string;
    avatarUrl?: string;
    archived?: boolean;
  };

  @Input() includesOutline = false;
  @Input() parseAvatarUrl: boolean = true;

  constructor(private readonly sitemateImageUtils: SitemateImageUtils) {}

  ngOnInit() {
    if (this.user) {
      this.user.avatar = this.user.avatar ?? this.user.avatarUrl;
    }
  }

  get getInitialStyle() {
    if (this.user?.archived) {
      return 'badge-info';
    }
    const initial = this.getInitial;
    const styles = ['badge-info', 'badge-danger', 'badge-success', 'badge-primary', 'badge-warning'];
    if (initial !== '?') {
      const alphaBeta = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const i = alphaBeta.indexOf(initial[0].toUpperCase());
      if (i >= 0 && i < alphaBeta.length) {
        return styles[i % styles.length];
      }
      return 'badge-primary';
    }
    return 'badge-default';
  }

  get getInitial() {
    let initial = '?';
    if (this.user) {
      const { firstName, lastName } = this.user;
      if (firstName && firstName.length > 0) {
        initial = firstName[0];
      }
      if (lastName && lastName.length > 0) {
        initial += lastName[0];
      }
    }
    return initial.toUpperCase();
  }

  get getFullNameOrEmail() {
    if (!this.user || (!this.user.fullName && !this.user.email)) {
      return '';
    }
    return this.user.fullName ? this.user.fullName : this.user.email;
  }

  getAvatarUrl(avatar: string): string {
    return this.parseAvatarUrl ? this.sitemateImageUtils.getImageVariantUrl(avatar, 'small') : avatar;
  }
}
