"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HyperFormulaEngineService = void 0;
const hyperformula_1 = require("hyperformula");
const lodash_1 = require("lodash");
const formula_engine_1 = require("./hyperformula/formula-engine");
const table_cell_kinds_enum_1 = require("../../models/tables/table-cell-kinds.enum");
class HyperFormulaEngineService {
    constructor() {
        this.formulaEngine = new formula_engine_1.HyperFormulaEngine();
        this.hyperFormulaInstance = this.formulaEngine.initializeFormula();
    }
    /**
     * Sets the content of a sheet to an empty value.
     *
     * @param {number} sheet - The index of the sheet to set the content for.
     * @returns {void}
     */
    setEmptySheetContent(sheetName) {
        const sheet = this.getSheet(sheetName);
        if (!sheet) {
            return;
        }
        this.formulaEngine.setEmptySheetContent(sheet, this.hyperFormulaInstance);
    }
    /**
     * Initializes the contents of a sheet in a HyperFormula instance with data from a table.
     *
     * @param {number} sheet - The ID of the sheet to initialize the contents for.
     * @param {Field} table - The table data to use for initializing the sheet.
     * @returns {void}
     */
    initSheetContent({ table, sheet }) {
        this.formulaEngine.initSheetContent(sheet, table, this.hyperFormulaInstance);
    }
    /**
     * Calculates the contents of a sheet in a HyperFormula instance with data from a table.
     * Applies changes to the table.
     *
     * @param {number} sheet - The ID of the sheet to calculate the contents for.
     * @param {Field} table - The table data to use for calculating the sheet contents.
     */
    calculateSheetContent({ table, sheet }) {
        const changes = this.formulaEngine.calculateSheetContent(sheet, table, this.hyperFormulaInstance);
        this.applyChanges(table, changes);
    }
    /**
     * Adds a new sheet with the specified name to a HyperFormula instance.
     * @param sheetName - The name of the sheet to be added.
     * @returns {Object} - An object containing the name and ID of the newly added sheet.
     * @property {string} sheetName - The name of the newly added sheet.
     * @property {number|undefined} sheet - The ID of the newly added sheet, or undefined if the sheet could not be found.
     */
    addSheet(sheetName) {
        return this.formulaEngine.addSheet(sheetName, this.hyperFormulaInstance);
    }
    /**
     * Retrieves the ID of a sheet in a HyperFormula instance with the specified name.
     *
     * @param sheetName - The name of the sheet
     * @returns {number} - The ID of the sheet.
     */
    getSheet(sheetName) {
        return this.formulaEngine.getSheet(sheetName, this.hyperFormulaInstance);
    }
    /**
     * Calculates formulas for a table.
     *
     * @param table - The table to calculate formulas for.
     * @returns {Field} - The table with formulas calculated.
     */
    calculateFormulasForTable(table) {
        const clonedTable = (0, lodash_1.cloneDeep)(table);
        const hasFormulas = this.tableHasFormulas(clonedTable);
        if (!hasFormulas) {
            return clonedTable;
        }
        const sheetName = this.generateRandomSheetName();
        const { sheet } = this.addSheet(sheetName);
        this.setEmptySheetContent(sheetName);
        if (sheet === undefined) {
            return clonedTable;
        }
        this.initSheetContent({
            sheet,
            table: clonedTable,
        });
        this.calculateSheetContent({
            sheet,
            table: clonedTable,
        });
        this.removeSheet(sheetName);
        return clonedTable;
    }
    /**
     * Removes a sheet from the HyperFormula instance.
     *
     * @param sheetName - The name of the sheet to remove.
     * @returns {ExportedChange[]} - The changes made to the sheet.
     */
    removeSheet(sheetName) {
        return this.formulaEngine.removeSheet(sheetName, this.hyperFormulaInstance);
    }
    /**
     * Checks whether a table has formulas.
     *
     * @param table - The table to check for formulas.
     * @returns {boolean} - Whether the table has formulas.
     */
    tableHasFormulas(table) {
        switch (table.kind) {
            case 'table':
                return table.columns
                    ? table.columns.some((col) => col.kind === table_cell_kinds_enum_1.TableCellKinds.Formula)
                    : false;
            case 'prefilledTable':
                return table.rows
                    ? table.rows.some((row) => row.columns.some((col) => col.kind === table_cell_kinds_enum_1.TableCellKinds.Formula))
                    : false;
            default:
                return false;
        }
    }
    /**
     * Applies the exported changes to the table if the updated cell is of kind `formula`.
     *
     * @param table - The table to apply the changes to.
     * @param changes - The changes to apply to the table.
     */
    applyChanges(table, changes) {
        changes.forEach((change) => {
            var _a;
            if (!(change instanceof hyperformula_1.ExportedCellChange)) {
                return;
            }
            const rowIndex = change.address.row;
            const columnIndex = change.address.col;
            const cell = (_a = table.rows) === null || _a === void 0 ? void 0 : _a[rowIndex].columns[columnIndex];
            if (!cell) {
                return;
            }
            let { newValue } = change;
            if (change.newValue instanceof hyperformula_1.DetailedCellError) {
                const { value } = change.newValue;
                newValue = value;
            }
            if (cell.kind === table_cell_kinds_enum_1.TableCellKinds.Formula) {
                cell.value = newValue;
            }
        });
    }
    /**
     *
     * @returns {string} - A random string to be used as a sheet name.
     */
    generateRandomSheetName() {
        return Date.now().toString(36) + Math.random().toString(36).substring(2);
    }
}
exports.HyperFormulaEngineService = HyperFormulaEngineService;
