"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyleVariantsToHexCode = void 0;
const style_variants_enum_1 = require("../models/fields/style-variants.enum");
exports.StyleVariantsToHexCode = {
    [style_variants_enum_1.StyleVariants.Orange]: '#F99226',
    [style_variants_enum_1.StyleVariants.Red]: '#E3514D',
    [style_variants_enum_1.StyleVariants.Yellow]: '#F6BB2D',
    [style_variants_enum_1.StyleVariants.Lime]: '#C8D548',
    [style_variants_enum_1.StyleVariants.Green]: '#5FC360',
    [style_variants_enum_1.StyleVariants.Sky]: '#3EC5D6',
    [style_variants_enum_1.StyleVariants.Blue]: '#2E81DA',
    [style_variants_enum_1.StyleVariants.Violet]: '#6575BD',
    [style_variants_enum_1.StyleVariants.Pink]: '#FC65F1',
    [style_variants_enum_1.StyleVariants.Grey]: '#8299AE',
    [style_variants_enum_1.StyleVariants.Black]: '#2E3940',
    [style_variants_enum_1.StyleVariants.None]: '######',
};
