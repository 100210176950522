<ng-container *ngIf="!model.readOnly" [ngSwitch]="kind">
  <ng-template [ngSwitchCase]="TableCellKinds.List">
    <cc-table-cell-item-list
      [tableType]="model.kind"
      [cellData]="{ model: column }"
      [hasListProperty]="hasListProperty"
      [rowId]="rowId"
      [headerColumn]="headerColumn"
      [tableId]="model._id"
      (onChange)="onChangeFormulaField(); onChangeField()"
    >
    </cc-table-cell-item-list>
  </ng-template>
  <ng-template [ngSwitchCase]="TableCellKinds.ListProperty">
    <cc-form-table-list-property
      [cellData]="column"
      [tableKind]="model.kind"
      [headerColumn]="headerColumn"
      [columnDescription]="columnDescription"
      [columnIndex]="columnIndex"
      [rowId]="rowId"
      [tableId]="model._id"
      (onChange)="onChangeFormulaField(); onChangeField()"
    >
    </cc-form-table-list-property>
  </ng-template>
  <ng-template [ngSwitchCase]="TableCellKinds.Date">
    <cc-table-cell-item-date
      [cellData]="column"
      [headerColumn]="headerColumn"
      (onChange)="onChangeFormulaField(); onChangeField()"
    >
    </cc-table-cell-item-date>
  </ng-template>
  <ng-template [ngSwitchCase]="TableCellKinds.Time">
    <cc-table-cell-item-time
      [cellData]="column"
      [headerColumn]="headerColumn"
      (onChange)="onChangeFormulaField(); onChangeField()"
    >
    </cc-table-cell-item-time>
  </ng-template>
  <ng-template [ngSwitchCase]="TableCellKinds.Formula">
    <cc-table-cell-item-formula [cellData]="column" [headerColumn]="headerColumn" [tableKind]="model.kind">
    </cc-table-cell-item-formula>
  </ng-template>
  <ng-template [ngSwitchCase]="TableCellKinds.Text">
    <cc-table-cell-item-text
      [cellData]="column"
      [headerColumn]="headerColumn"
      [tableKind]="model.kind"
      [columnDescription]="columnDescription"
      [cellName]="cellName"
      (onChange)="onChangeFormulaField(); onChangeField()"
    >
    </cc-table-cell-item-text>
  </ng-template>
  <ng-template [ngSwitchCase]="TableCellKinds.Number">
    <cc-table-cell-item-number
      [cellData]="column"
      [headerColumn]="headerColumn"
      [tableKind]="model.kind"
      [columnDescription]="columnDescription"
      [cellName]="cellName"
      (onChange)="onChangeFormulaField(); onChangeField()"
    >
    </cc-table-cell-item-number>
  </ng-template>
</ng-container>

<div class="text-field" *ngIf="model.readOnly && kind !== TableCellKinds.Location">{{ readonlyData }}</div>

<ng-container *ngIf="kind === TableCellKinds.Photo">
  <cc-table-cell-item-photo
    [cellData]="column"
    [template]="template"
    [headerColumn]="headerColumn"
    [tableKind]="model.kind"
    [readOnly]="model.readOnly"
    [columnDescription]="columnDescription"
    [isPreview]="isPreview"
    (onChange)="onChangeField()"
  >
  </cc-table-cell-item-photo>
</ng-container>

<div
  *ngIf="kind === TableCellKinds.PrefilledText"
  class="table-column-prefilled-text text-field rich-generated-content"
  [innerHTML]="column.content | safeHtml"
  [ngbPopover]="popoverMessage"
  popoverClass="popover-prefilled-text"
  [placement]="['right', 'left']"
  container="body"
  triggers="mouseenter:mouseleave"
></div>

<!-- TODO: this needs to be moved into the signature field -->
<ng-container *ngIf="kind === TableCellKinds.Signature">
  <cc-form-signature-list
    *ngIf="column.signatures"
    [formPath]="formPath"
    [isReadOnly]="model.readOnly"
    [kind]="column.kind"
    [signatures]="column.signatures"
  ></cc-form-signature-list>
  <button
    type="button"
    *ngIf="!model.readOnly"
    class="btn btn-success btn-signature"
    i18n
    (click)="isPreview ? onPreviewAction('formPreviewWarningSignature') : onSign(column)"
  >
    Sign <i class="cc-icon cc-icon-signature"></i>
  </button>
</ng-container>

<!-- TODO: this needs to be moved into the signature field -->
<div
  *ngIf="kind === TableCellKinds.ManualSignature"
  class="field-w-{{ headerColumn?.width || TableColumnWidths.Legacy }}"
>
  <cc-form-signature-list
    *ngIf="column.signatures"
    [formPath]="formPath"
    [isManualSignature]="column.isManualSignature"
    [isReadOnly]="model.readOnly"
    [kind]="column.kind"
    [signatures]="column.signatures"
  ></cc-form-signature-list>
  <button
    type="button"
    *ngIf="!model.readOnly"
    class="btn btn-success btn-manual-signature"
    i18n
    (click)="isPreview ? onPreviewAction('formPreviewWarningSignature') : onManualSign(column)"
  >
    Sign manually <i class="cc-icon cc-icon-signature"></i>
  </button>
</div>

<ng-container *ngIf="kind === TableCellKinds.Attachment">
  <div *ngIf="uploading.length > 0">
    <span class="loading-spinner">
      <i class="cc-icon cc-icon-spinner-fw"></i>
    </span>
    <span i18n>Uploading</span>
    <span *ngFor="let u of uploading" class="ml-2">{{ u.name }}</span>
  </div>
  <div *ngFor="let attachment of column.attachments">
    <div class="attachment-row">
      <div class="sm-flex">
        <i class="cc-icon cc-icon-file-pdf" aria-hidden="true"></i>
        <div class="attachment-filename-container">
          <a [href]="attachment.urlDownload" target="_blank" rel="noopener noreferrer" class="my-1 text-main">
            {{ attachment.filename }}
          </a>
        </div>
      </div>
      <div class="delete-attachment">
        <a (click)="deleteAttachment(attachment)" *ngIf="!model.readOnly" title="Delete" i18n-title="Delete">
          <i class="cc-icon cc-icon-delete sm-text-default-red" aria-hidden="true"></i
        ></a>
      </div>
    </div>
    <hr class="hr" />
  </div>

  <button
    type="button"
    *ngIf="!model.readOnly"
    class="btn btn-primary add-pdf-button"
    (click)="fileInput.click()"
  >
    <div class="d-flex align-items-center">
      <span i18n>Add PDF</span>
      <span *ngIf="headerColumn?.width !== TableColumnWidths.Small" class="cc-icon cc-icon-attachment"></span>
    </div>
    <input
      type="file"
      name="files"
      multiple
      class="d-none"
      (change)="isPreview ? onPreviewAction('formPreviewWarningUploadAttachment') : onUpload($event)"
      accept="application/pdf"
      #fileInput
    />
  </button>
</ng-container>

<ng-container *ngIf="kind === TableCellKinds.Location">
  <cc-form-table-location-cell
    [cellData]="column"
    [width]="headerColumn.width"
    (onChange)="onChangeField()"
    [readOnly]="model.readOnly"
  ></cc-form-table-location-cell>
</ng-container>
