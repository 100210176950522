"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableCellValidatorFactory = void 0;
const number_cell_validator_1 = require("./number-cell-validator");
const table_cell_validator_1 = require("./table-cell.validator");
const table_cell_kinds_enum_1 = require("../../models/tables/table-cell-kinds.enum");
class TableCellValidatorFactory {
    static getValidator(cellData, headerColumn) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const ValidatorClass = (cellData.kind && this.resolver[cellData.kind]) || this.defaultValidator;
        return new ValidatorClass(cellData, headerColumn);
    }
}
exports.TableCellValidatorFactory = TableCellValidatorFactory;
TableCellValidatorFactory.resolver = {
    [table_cell_kinds_enum_1.TableCellKinds.Number]: number_cell_validator_1.NumberTableCellValidator,
};
TableCellValidatorFactory.defaultValidator = table_cell_validator_1.TableCellValidator;
