import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'cc-signature-pad',
  templateUrl: 'signature-pad.component.html',
  styleUrls: ['signature-pad.component.scss'],
})
export class CCSignaturePadComponent {
  @ViewChild('signaturePadCanvas') signaturePadCanvas: ElementRef<HTMLCanvasElement>;
  @Input() src: string;
  @Input() height: string;
  @Input() width: string;
  @Input() options: { canvasWidth: number; canvasHeight: number };
  @Input() hasError = false;
  @Output() end = new EventEmitter();
  @Output() start = new EventEmitter();

  signaturePad: SignaturePad;

  ngAfterViewInit() {
    this.setSignaturePad();
  }

  ngOnChanges(changes: SimpleChanges) {
    const newSrc = !!changes.src?.currentValue;
    const oldSrc = !!changes.src?.previousValue;
    if (oldSrc !== newSrc) {
      this.setSignaturePad();
    }
  }

  private setSignaturePad() {
    if (!this.signaturePadCanvas) {
      return;
    }

    if (this.src) {
      this.signaturePad?.off();
    } else {
      this.initializeSignaturePad();
    }
  }

  private initializeSignaturePad() {
    this.setCanvas(this.options.canvasWidth, this.options.canvasHeight);

    if (!this.signaturePad) {
      this.signaturePad = new SignaturePad(this.signaturePadCanvas.nativeElement);
      this.signaturePad.addEventListener('beginStroke', () => this.start.emit());
      this.signaturePad.addEventListener('endStroke', () => this.end.emit(this.signaturePad));
    }
  }

  ngOnDestroy() {
    this.setCanvas(0, 0);
    this.signaturePad?.off();
  }

  private setCanvas(width: number, height: number) {
    const canvas = this.signaturePadCanvas?.nativeElement;
    if (canvas) {
      canvas.width = width;
      canvas.height = height;
    }
  }
}
