import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  DashpivotEvent,
  EventNotifierService,
  EventTypes,
  TableColumn,
  TableRow,
} from '@site-mate/dashpivot-shared-library';

import { FormManualSignatureComponent } from 'app/form/form-components/form-manual-signature.component';
import { FormService } from 'app/form/form.service';
import { SegmentService } from 'app/segment/segment.service';
import { ConfirmService } from 'app/shared/service/confirm.service';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { ProfileService } from 'app/user/profile.service';
import { UserService } from 'app/user/user.service';

@Injectable()
export class TableColumnService {
  private readonly uploadBatchSize = 2;
  private readonly errorToastLife = 5 * 60 * 1000;

  private addRowSubject = new Subject<string>();
  public addRowEvent = this.addRowSubject.asObservable();

  private batchAddRowsSubject = new Subject<{
    tableId: string;
    newRows: TableRow[];
    isDone?: boolean;
  }>();

  public onBatchAddRows = this.batchAddRowsSubject.asObservable();

  private batchUpdateRowsSubject = new Subject<{
    tableId: string;
    updates: Array<{ rowIndex: number; newData: TableRow }>;
    isDone?: boolean;
  }>();

  public onBatchUpdateRows = this.batchUpdateRowsSubject.asObservable();

  constructor(
    protected readonly modal: NgbModal,
    private readonly userService: UserService,
    private readonly confirmService: ConfirmService,
    private readonly formService: FormService,
    private readonly errorHandler: ErrorHandler,
    private readonly profileService: ProfileService,
    private readonly segmentService: SegmentService,
  ) {}

  batchAddRows({ tableId, newRows, isDone }: { tableId: string; newRows: TableRow[]; isDone?: boolean }) {
    this.batchAddRowsSubject.next({ tableId, newRows, isDone });
  }

  addRowToTable(id: string) {
    this.addRowSubject.next(id);
  }

  batchUpdateRows({
    tableId,
    updates,
    isDone,
  }: {
    tableId: string;
    updates: Array<{ rowIndex: number; newData: TableRow }>;
    isDone?: boolean;
  }) {
    this.batchUpdateRowsSubject.next({ tableId, updates, isDone });
  }

  onSignColumn({
    formId,
    itemId,
    column,
    onSigned,
  }: {
    formId: string;
    itemId: string;
    column: TableColumn;
    onSigned: () => void;
  }) {
    if (!this.profileService.hasProfileSignature()) {
      return;
    }
    this.confirmService
      .confirmSign('signForm')
      .then(() => {
        return this.formService.generateSignature({ formId, itemId }).subscribe(
          (res) => {
            this.pushSignatureToColumn(res, column);
            onSigned();
          },
          (error) => this.errorHandler.handle(error),
        );
      })
      .catch(() => {});
  }

  onManualSignColumn({
    formId,
    itemId,
    column,
    onSigned,
  }: {
    formId: string;
    itemId: string;
    column: TableColumn;
    onSigned: () => void;
  }) {
    const modalRef = this.modal.open(FormManualSignatureComponent, { keyboard: false });
    void modalRef.result.then(
      (signature) => {
        return this.formService.manualSignColumn(formId, itemId, signature).subscribe(
          (res) => {
            this.pushSignatureToColumn(res, column);
            onSigned();
          },
          (error) => this.errorHandler.handle(error),
        );
      },
      () => {},
    );
  }

  private pushSignatureToColumn(res, column) {
    const userId = this.userService.getCurrentUser().id;
    const { fullName, signatureUrl, signedOn, companyName } = res;
    const newSignature = { fullName, signatureUrl, signedOn, companyName, userId };

    column.signatures = column.signatures || [];
    column.signatures.push(newSignature);
  }

  async handleAttachmentUploadToColumn({
    event,
    column,
    uploadingAttachments,
  }: {
    event: Record<string, any>;
    column: Record<string, any>;
    uploadingAttachments: any[];
  }) {
    await this.formService.handleAttachmentUpload({
      uploadBatchSize: this.uploadBatchSize,
      target: event.target,
      uploader: (fakeId, formData) => {
        return this.formService.uploadFile(formData).pipe(
          map((resultColumn) => {
            column.attachments = column.attachments || [];
            column.attachments.push(resultColumn);
            const index = uploadingAttachments.findIndex((u) => u.id === fakeId);
            uploadingAttachments.splice(index, 1);
            void EventNotifierService.notify(
              new DashpivotEvent(EventTypes.FormAttachmentAdded, { Context: 'PDF' }),
              this.segmentService,
            );
            return column;
          }),
          catchError((error) => {
            this.errorHandler.handle(error, null, null, {
              enableHTML: true,
              toastLife: this.errorToastLife,
            });
            const index = uploadingAttachments.findIndex((u) => u.id === fakeId);
            uploadingAttachments.splice(index, 1);
            void EventNotifierService.notify(
              new DashpivotEvent(EventTypes.FormAttachmentFailed, { Context: 'PDF' }),
              this.segmentService,
            );
            return of(null);
          }),
        );
      },
    });
  }

  onRemoveTableColumnAttachment({
    attachment,
    attachments,
    onDelete,
  }: {
    attachment: Record<string, any>;
    attachments: any[];
    onDelete: () => void;
  }) {
    this.confirmService
      .confirmDelete('deleteAttachment')
      .then(() => {
        const index = attachments.findIndex((a) => a.id === attachment.id);
        attachments.splice(index, 1);
        onDelete();
        return true;
      })
      .catch(() => {});
  }
}
