"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportUtils = void 0;
const lodash_1 = require("lodash");
const getExportBaseFolderName = ({ company, project, team, formName, counter, }) => {
    const formPath = `${company.name}/${project.name}/${team.name}`;
    return (0, lodash_1.kebabCase)(`${formName} ${formPath} ${counter}`);
};
exports.ExportUtils = {
    getExportBaseFolderName,
};
