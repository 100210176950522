/* eslint-disable promise/no-promise-in-callback */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { get } from 'lodash-es';
import { filter } from 'rxjs/operators';

import { User } from '@site-mate/dashpivot-shared-library';

import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { ToastrService } from 'app/shared/service/toastr.service';
import { UserService } from 'app/user/user.service';
import { environment } from 'environments/environment';

import { DebugErrorAngular } from '../model/debug-error-angular.model';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandler {
  private readonly httpResponseStatus = {
    noConnection: 0,
    internalServerError: 500,
  };

  constructor(
    private readonly toastr: ToastrService,
    private readonly i18nService: TmpI18NService,
    private readonly userService: UserService,
    private readonly httpClient: HttpClient,
  ) {
    this.userService.currentUser.pipe(filter((user: User) => !!(user && user.id))).subscribe((user) => {
      Bugsnag.setUser(user.id, user.email, user.fullName);
    });
  }

  handle(errorObject, context?, title?, opts?): void {
    this.notifyBugsnag(errorObject, context);
    const { status, error, hideToastr, toastKey } = errorObject;

    if (hideToastr) {
      return;
    }

    if (toastKey) {
      this.toastr.errorByKey(toastKey);
      return;
    }

    if (status === this.httpResponseStatus.noConnection) {
      this.showDashpivotApiUnreachableToastr(errorObject);
      return;
    }

    const message =
      this.getStatusMessage(status) ||
      this.extractErrorMessage(error) ||
      this.i18nService.getMessage('generalErr');

    this.toastr.error(message, title, opts);
  }

  handleForDebug(errorObject, context?): void {
    this.notifyBugsnag(errorObject, context);
  }

  showDashpivotApiUnreachableToastr(error: any) {
    const errorMessage = this.i18nService.getMessage('noConnectionErr');
    this.toastr.error(errorMessage, undefined, { enableHtml: true, disableTimeOut: true });
    const context = { 'Dashpivot API Status': DebugErrorAngular.ApiUnreachable };
    Bugsnag.leaveBreadcrumb('Error on Dashpivot API Service', { context });
    Bugsnag.notify(error, (event) => {
      event.addMetadata('metadata', context);
      event.addMetadata('extraMetadata', { context });
    });
  }

  private notifyBugsnag(errorObject, context) {
    const UNKNOWN_ERROR = 0;
    const contextParam = context ?? {};
    contextParam.progressEvent = errorObject?.error;

    if (errorObject?.status === UNKNOWN_ERROR && context?.isRequestingGlobalApi) {
      return;
    }

    if (errorObject?.status === UNKNOWN_ERROR) {
      this.logExtraInfoForUnknownError(errorObject, contextParam);
      // eslint-disable-next-line no-console
      console.error(errorObject);
    } else if (errorObject) {
      // TODO: test how this logs the meta data (we might need to adjust/manipulate the context object)
      Bugsnag.notify(errorObject, (event) => event.addMetadata('metadata', contextParam));
      // eslint-disable-next-line no-console
      console.error(errorObject);
    }
  }

  private logExtraInfoForUnknownError(error, context) {
    this.httpClient
      .get(`${environment.apiUrl}server-ping`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .subscribe({
        next: () => this.addMetadataToBugsnag(error, context),
        error: (rxjsError) => this.addMetadataToBugsnagForRxjsError(error, rxjsError, context),
      });
  }

  addMetadataToBugsnag(error, context) {
    const reachable = DebugErrorAngular.ApiReachable;
    const debugErrorAngular = context?.isRequestingGlobalApi
      ? { 'Sitemate Global API Status': reachable }
      : { 'Dashpivot API Status': reachable };

    Bugsnag.notify(error, (event) => {
      event.addMetadata('metadata', context);
      event.addMetadata('extraMetadata', { ...debugErrorAngular });
    });
  }

  addMetadataToBugsnagForRxjsError(error, rxjsError, context) {
    const unreachable = DebugErrorAngular.ApiUnreachable;
    const debugErrorAngular = context?.isRequestingGlobalApi
      ? { 'Sitemate Global API Status': unreachable }
      : { 'Dashpivot API Status': unreachable };

    Bugsnag.notify(error, (event) => {
      event.addMetadata('metadata', context);
      event.addMetadata('extraMetadata', {
        ...debugErrorAngular,
        debugErrorAngularErrorMessage: rxjsError,
      });
    });
  }

  private getStatusMessage(status: number): string {
    if (status === this.httpResponseStatus.internalServerError) {
      return this.i18nService.getMessage('generalErr');
    }

    return null;
  }

  private extractErrorMessage(error) {
    if (!error) {
      return null;
    }

    return get(error, 'message.message') || error.message || error;
  }
}
