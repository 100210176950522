<div
  class="banner-container"
  [class.sm-opacity-50]="disabled"
  [ngClass]="type"
  role="alert"
  class="{{ size }} {{ alignment }} {{ width }} {{ customClasses }}"
  *ngIf="isVisible"
>
  <i *ngIf="icon" [ngClass]="'cc-icon cc-icon-' + icon" aria-hidden="true"></i>

  <div class="!sm-ml-0 sm-flex sm-w-full sm-items-center sm-justify-start">
    <div
      *ngIf="showBadge && badgePosition === 'left'"
      class="cc-btn-subtle-blue-helper banner-badge sm-mr-1 sm-px-1"
      data-test="badge-left"
      i18n
    >
      {{ badgeText }}
    </div>

    <div data-test="banner-text" i18n>
      {{ bannerText }}
      <ng-content></ng-content>
    </div>

    <div
      *ngIf="showBadge && badgePosition === 'right'"
      class="cc-btn-subtle-blue-helper banner-badge sm-mx-2 sm-px-1"
      data-test="badge-right"
      i18n
    >
      {{ badgeText }}
    </div>
  </div>

  <button
    type="button"
    *ngIf="dismissible"
    aria-label="Close"
    data-test="dismiss-tip-banner"
    (click)="dismissBanner()"
    class="sm-flex sm-h-4 sm-items-center"
  >
    <i class="cc-icon cc-icon-close" aria-hidden="true"></i>
  </button>
</div>
