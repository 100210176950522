"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeaderFooterFieldKinds = void 0;
var HeaderFooterFieldKinds;
(function (HeaderFooterFieldKinds) {
    HeaderFooterFieldKinds["Logo"] = "logo";
    HeaderFooterFieldKinds["Filepath"] = "filepath";
    HeaderFooterFieldKinds["TemplateName"] = "templateName";
    HeaderFooterFieldKinds["TemplateId"] = "templateId";
    HeaderFooterFieldKinds["TemplateVersion"] = "templateVersion";
    HeaderFooterFieldKinds["FormVersion"] = "formVersion";
    HeaderFooterFieldKinds["FormCreatorAndCreationTime"] = "formCreatorAndCreationTime";
    HeaderFooterFieldKinds["UncontrolledVersionDisclaimer"] = "uncontrolledVersionDisclaimer";
    HeaderFooterFieldKinds["PageNumbers"] = "pageNumbers";
    HeaderFooterFieldKinds["PdfCreationTimeAndDate"] = "pdfCreationTimeAndDate";
    HeaderFooterFieldKinds["PrefilledText"] = "prefilledText";
})(HeaderFooterFieldKinds = exports.HeaderFooterFieldKinds || (exports.HeaderFooterFieldKinds = {}));
