"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MATRIX_FORMULAS = exports.MATRIX_NOT_SUPPORTED_FORMULAS = exports.MATRIX_SUPPORTED_FORMULAS = exports.MATRIX_SUPPORTED_FORMULAS_WITH_INFO = void 0;
exports.MATRIX_SUPPORTED_FORMULAS_WITH_INFO = new Map([
    [
        'MEDIANPOOL',
        {
            syntax: 'MEDIANPOOL(Range, Window_size, Stride)',
            description: 'Calculates a smaller range which is a median of a Window_size, in a given Range, for every Stride element.',
        },
    ],
    [
        'MAXPOOL',
        {
            syntax: 'MAXPOOL(Range, Window_size, Stride)',
            description: 'Calculates a smaller range which is a maximum of a Window_size, in a given Range, for every Stride element.',
        },
    ],
    [
        'TRANSPOSE',
        {
            syntax: 'TRANSPOSE(Array)',
            description: 'Transposes the rows and columns of an array.',
        },
    ],
]);
exports.MATRIX_SUPPORTED_FORMULAS = [...exports.MATRIX_SUPPORTED_FORMULAS_WITH_INFO.keys()];
exports.MATRIX_NOT_SUPPORTED_FORMULAS = ['MMULT'];
exports.MATRIX_FORMULAS = exports.MATRIX_SUPPORTED_FORMULAS.concat(exports.MATRIX_NOT_SUPPORTED_FORMULAS);
