<div *ngIf="flowsiteBannerPopoverMetadata[flowsiteReferenceType]" class="popover-container">
  <img
    [src]="flowsiteBannerPopoverMetadata[flowsiteReferenceType].imgSrc"
    [alt]="flowsiteBannerPopoverMetadata[flowsiteReferenceType].imgAlt"
  />
  <p
    i18n
    data-test="popover-text"
    class="sm-pt-2"
    [innerHTML]="flowsiteBannerPopoverMetadata[flowsiteReferenceType].text | safeHtml"
  ></p>

  <div data-test="use-cases" *ngIf="flowsiteBannerPopoverMetadata[flowsiteReferenceType].useCases?.length">
    <p i18n class="sm-pt-2 sm-font-bold">Common use cases:</p>
    <ul class="sm-list-disc sm-pl-4">
      <li
        i18n
        *ngFor="let useCase of flowsiteBannerPopoverMetadata[flowsiteReferenceType].useCases"
        [innerHTML]="useCase | safeHtml"
      ></li>
    </ul>
  </div>

  <p i18n data-test="free-trial-text" class="sm-w-full sm-text-left">Get started with a 30-day free trial.</p>
</div>
