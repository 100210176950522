<header>
  <h1 class="sm-text-xl sm-font-medium sm-text-default-text">{{ title }}</h1>
  <button type="button" (click)="modal.dismiss()" class="close-modal-button" aria-label="Close modal">
    <i class="cc-icon cc-icon-close"></i>
  </button>
</header>
<div class="sm-flex sm-flex-col sm-gap-4">
  <p class="sm-text-center sm-text-default-text">{{ message }}</p>

  <cc-search-and-select
    *ngIf="hasSearchSelector"
    name="modal-search-and-select"
    placeholder="Select..."
    customClass="multiple-line"
    [items]="items"
    [multiple]="true"
    [clearable]="true"
    [maxSelectedItems]="maxSelectedItems"
    (change)="onItemSelect($event)"
  ></cc-search-and-select>

  <div *ngIf="action" class="sm-flex sm-justify-center">
    <button
      type="button"
      data-test="cancel-button"
      class="button-regular button-grey-3 button-web sm-mr-2"
      (click)="onCancel()"
    >
      Cancel
    </button>
    <button
      type="button"
      data-test="action-button"
      class="button-regular button-green button-web"
      (click)="onAction()"
      [disabled]="isActionDisabled"
    >
      {{ actionTitle }}
    </button>
  </div>
</div>
