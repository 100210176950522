import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

import {
  DashpivotEvent,
  EventNotifierService,
  EventTypes,
  TableCellData,
  TableCellValidator,
  TableCellValidatorFactory,
  TableColumn,
} from '@site-mate/dashpivot-shared-library';

import { FormDateUtil } from 'app/form/form-date-util.service';
import { FormTableCellComponent } from 'app/form-fields/table/edit-cells/table-cell.component';
import { SegmentService } from 'app/segment/segment.service';

@Component({
  templateUrl: './time.component.html',
  styleUrls: ['../table-cell.component.scss'],
  selector: 'cc-table-cell-item-time',
})
export class FormTableTimeCellComponent implements FormTableCellComponent, OnInit {
  @Input() cellData: TableCellData;
  @Input() headerColumn: TableColumn;
  @Output() onChange = new EventEmitter();
  validator: TableCellValidator;

  constructor(private readonly segmentService: SegmentService) {}

  ngOnInit() {
    this.validator = TableCellValidatorFactory.getValidator(this.cellData, this.headerColumn);

    if (this.cellData.value) {
      this.cellData._time = FormDateUtil.convertToTimeStruct(this.cellData.value);
    }
  }

  handleOnChange(value) {
    this.cellData._time = value;
    this.cellData.value = FormDateUtil.convertTimeToUTCString(value);
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.FormTableCellDataEntered),
      this.segmentService,
    );
    this.onChange.emit();
  }
}
