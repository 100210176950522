import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cc-modal-with-action',
  templateUrl: './modal-with-action.component.html',
  styleUrls: ['./modal-with-action.component.scss'],
})
export class ModalWithActionComponent {
  @Input() title: string;
  @Input() message: string;
  @Input() hasSearchSelector = false;
  @Input() items = [];
  @Input() actionTitle = 'Apply';
  @Input() actionDisabled = false;
  @Input() action: () => void;
  @Input() cancel: () => void;

  selectedItems = [];
  maxSelectedItems = 10;

  constructor(public modal: NgbActiveModal) {}

  onCancel() {
    if (this.cancel) {
      this.cancel();
    }

    this.modal.dismiss();
  }

  onAction() {
    if (this.action) {
      this.action();
      this.modal.close();
    }
  }

  onItemSelect(items) {
    this.selectedItems = items;
  }

  get isActionDisabled() {
    if (this.hasSearchSelector) {
      return this.actionDisabled || this.selectedItems.length === 0;
    }

    return this.actionDisabled;
  }
}
