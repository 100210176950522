<div class="field-w-{{ headerColumn.width }}">
  <cc-form-photo-list
    class="table-photo-field"
    [maxDisplay]="4"
    [item]="cellData"
    [readOnly]="readOnly"
    [photos]="cellData.photos"
    (remove)="onRemovePhoto($event)"
    (viewPhoto)="onViewPhoto($event)"
  ></cc-form-photo-list>
  <button
    *ngIf="!readOnly"
    type="button"
    class="btn btn-primary"
    data-test="btn-add-photos"
    i18n
    (click)="onAddPhotoClick()"
    [disabled]="isInsideLibraryApps"
  >
    Add photos <i class="cc-icon cc-icon-photo"></i>
  </button>
</div>
