"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormulaFormService = void 0;
/* eslint-disable no-param-reassign */
const formula_service_1 = require("./formula.service");
const field_kinds_enum_1 = require("../../models/fields/field-kinds.enum");
const table_cell_kinds_enum_1 = require("../../models/tables/table-cell-kinds.enum");
class FormulaFormService {
    constructor() {
        this.formulaService = new formula_service_1.FormulaService();
    }
    isFormulaKind(kind) {
        return (kind === table_cell_kinds_enum_1.TableCellKinds.Time ||
            kind === table_cell_kinds_enum_1.TableCellKinds.Date ||
            kind === table_cell_kinds_enum_1.TableCellKinds.Number ||
            kind === table_cell_kinds_enum_1.TableCellKinds.Formula ||
            kind === table_cell_kinds_enum_1.TableCellKinds.List ||
            kind === field_kinds_enum_1.FieldKinds.Table ||
            kind === field_kinds_enum_1.FieldKinds.PrefilledTable);
    }
    hasFormulaColumn(model, isPrefilledTable = false) {
        return this.columnIncludes(model, isPrefilledTable, (col) => col.kind === table_cell_kinds_enum_1.TableCellKinds.Formula);
    }
    hasFormulaInputColumn(model, isPrefilledTable = false) {
        return this.columnIncludes(model, isPrefilledTable, (col) => this.isFormulaKind(col.kind));
    }
    generateFieldLabels(fields) {
        let formulaRowNumber = 1;
        fields.forEach((field) => {
            if (field.kind &&
                this.isFormulaKind(field.kind) &&
                this.hasFormulaColumn(field, field.kind === field_kinds_enum_1.FieldKinds.PrefilledTable)) {
                field.formulaRowNumber = formulaRowNumber;
                if (field.kind === field_kinds_enum_1.FieldKinds.PrefilledTable && field.rows && field.rows.length > 0) {
                    field.rows.forEach((row) => {
                        row.formulaRowNumber = formulaRowNumber;
                        formulaRowNumber += 1;
                    });
                }
                else {
                    formulaRowNumber += 1;
                }
            }
            else {
                delete field.formulaRowNumber;
            }
        });
    }
    calculateTable(model) {
        let formulasExecuted = 0;
        // default tables - formula definition are on table column header
        model
            .columns.filter((column) => column.kind === table_cell_kinds_enum_1.TableCellKinds.Formula)
            .forEach((formulaColumn) => {
            model.rows.forEach((row) => {
                const formulaCell = row.columns.find((r) => r.headerColumnId === formulaColumn.id);
                if (formulaCell) {
                    const { value, hasExecuted } = this.calculateFormula(formulaColumn.formula || '', formulaCell.value, [row], model.formulaRowNumber);
                    formulaCell.value = value;
                    if (hasExecuted) {
                        formulasExecuted += 1;
                    }
                }
            });
        });
        return formulasExecuted;
    }
    calculatePrefilledTable(model) {
        let formulasExecuted = 0;
        // prefilled tables - formula definitions are on each row's columns
        model.rows.forEach((row) => {
            row.columns
                .filter((column) => column.kind === table_cell_kinds_enum_1.TableCellKinds.Formula)
                .forEach((formulaColumn) => {
                const { value, hasExecuted } = this.calculateFormula(formulaColumn.formula || '', formulaColumn.value, model.rows, model.formulaRowNumber);
                formulaColumn.value = value;
                if (hasExecuted) {
                    formulasExecuted += 1;
                }
            });
        });
        return formulasExecuted;
    }
    // return true if any column in this model passes the testCallback
    columnIncludes(model, isPrefilledTable = false, testCallback = () => true) {
        if (isPrefilledTable) {
            return !!model.rows && model.rows.some((row) => row.columns.find((col) => testCallback(col)));
        }
        return !!model.columns && model.columns.some((col) => testCallback(col));
    }
    calculateFormula(formula, formulaValue, rows, formulaRowNumber) {
        if (!formula) {
            return { value: '', hasExecuted: false };
        }
        const { result, error } = this.formulaService.parseRows(formula, rows, formulaRowNumber);
        const value = result || error || ''; // TODO: check value is always the right type
        return { value, hasExecuted: value !== formulaValue };
    }
}
exports.FormulaFormService = FormulaFormService;
