export enum FlowsiteTemplateReference {
  DashpivotXeroTimesheets = 'dashpivot-xero-timesheets',
  DashpivotXeroDockets = 'dashpivot-xero-dockets',
  DashpivotPowerBiExcel = 'dashpivot-power-bi-excel',
  DashpivotPowerBiExcelSiteDiary = 'dashpivot-power-bi-excel-site-diary',
  DashpivotPowerBiExcelIncidentReport = 'dashpivot-power-bi-excel-incident-report',
  DashpivotPowerBiExcelAuditForm = 'dashpivot-power-bi-excel-audit-form',
  DashpivotPowerBiExcelSwmsRams = 'dashpivot-power-bi-excel-swms-rams',
  DashpivotPowerBiExcelJsa = 'dashpivot-power-bi-excel-jsa',
  DashpivotPowerBiExcelMachinePrestart = 'dashpivot-power-bi-excel-machinery-prestart',
  DashpivotPowerBiExcelAttendanceRecord = 'dashpivot-power-bi-excel-attendance-record',
  DashpivotPowerBiExcelNonConformanceDefectReport = 'dashpivot-power-bi-excel-non-conformance-defect-report',
  DashpivotPowerBiExcelCostDayworkReport = 'dashpivot-power-bi-excel-cost-daywork-report',
  DashpivotQuickBooksDockets = 'dashpivot-quickbooks-dockets',
  DashpivotCSVExports = 'dashpivot-power-bi-excel-csv-exports',
  DashpivotPDFExports = 'dashpivot-sharepoint-pdf-exports',
}
