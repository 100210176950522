import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cc-form-manual-signature',
  templateUrl: 'form-manual-signature.component.html',
  styleUrls: ['form-manual-signature.component.scss'],
})
export class FormManualSignatureComponent {
  firstName;
  lastName;
  companyName;
  signaturePadOptions;
  signatureData;
  signaturePad;

  constructor(public modal: NgbActiveModal) {
    this.signaturePadOptions = this.getOptions();
  }

  getOptions() {
    const canvasWidth = 206;
    const canvasHeight = 90;
    return { canvasWidth, canvasHeight };
  }

  drawComplete(event) {
    this.signaturePad = event;
    this.signatureData = this.signaturePad.toDataURL();
  }

  clearSignature() {
    if (this.signaturePad) {
      this.signaturePad.clear();
    }
    this.signatureData = '';
  }

  onSubmit() {
    this.modal.close({
      firstName: this.firstName,
      lastName: this.lastName,
      companyName: this.companyName,
      signatureData: this.signatureData,
    });
  }
}
