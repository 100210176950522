import { Injectable } from '@angular/core';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { TmpI18NService } from '../../i18n/tmp-i18n.service';

export const confirmExitOperations = ['cancel', 'close', 'backdrop'];
@Injectable({
  providedIn: 'root',
})
export class ConfirmService {
  private readonly dangerColor = '#dc3545';
  private readonly successColor = '#28a745';
  private readonly grayColor = '#aaaaaa';

  constructor(private i18nService: TmpI18NService) {}

  confirmDelete(messageKey: string, options: SweetAlertOptions = {}): Promise<boolean> {
    return this.confirm(messageKey, {
      confirmButtonText: options?.confirmButtonText ?? this.i18nService.getMessage('confirmDelete'),
      confirmButtonColor: this.dangerColor,
    });
  }

  confirmSign(messageKey: string): Promise<boolean> {
    return this.confirm(messageKey, {
      confirmButtonText: this.i18nService.getMessage('confirmSign'),
      cancelButtonText: this.i18nService.getMessage('confirmCancel'),
    });
  }

  confirmSave(messageKey: string): Promise<boolean> {
    return this.confirm(messageKey, {
      confirmButtonText: this.i18nService.getMessage('confirmSave'),
      cancelButtonText: this.i18nService.getMessage('confirmDiscard'),
      cancelButtonColor: this.dangerColor,
    });
  }

  async confirm(messageKey: string, options: SweetAlertOptions): Promise<boolean> {
    const config = this.getDefaultConfig();

    const { isConfirmed, dismiss, value } = await Swal.fire({
      html: options.html ?? this.i18nService.getMessage(messageKey),
      ...config,
      showCancelButton: options.showCancelButton ?? config.showCancelButton,
      confirmButtonText: options.confirmButtonText,
      cancelButtonText: options.cancelButtonText ?? this.i18nService.getMessage('confirmCancel'),
      confirmButtonColor: options.confirmButtonColor ?? this.successColor,
      cancelButtonColor: options.cancelButtonColor ?? this.grayColor,
      icon: options.icon ?? config.icon,
    });

    if (isConfirmed) {
      return Promise.resolve(value);
    }
    return Promise.reject(dismiss);
  }

  closeSwalIfExists() {
    if (Swal.isVisible()) {
      Swal.close();
    }
  }

  private getDefaultConfig(): SweetAlertOptions {
    return {
      showCloseButton: true,
      showCancelButton: true,
      allowEscapeKey: false,
      focusCancel: true,
      icon: 'question',
      showClass: {
        backdrop: 'swal2-noanimation', // disable backdrop animation
        icon: '', // disable icon animation
        popup: '', // disable popup animation
      },
      hideClass: {
        backdrop: 'swal2-noanimation', // disable backdrop animation
        icon: '', // disable icon animation
        popup: '', // disable popup animation
      },
      customClass: {
        confirmButton: 'sm-px-6 !sm-rounded focus:!sm-shadow-none',
        cancelButton: 'sm-px-6 !sm-rounded focus:!sm-shadow-none',
        closeButton: 'focus:!sm-shadow-none',
        htmlContainer: '!sm-text-default-text !sm-font-light',
      },
    };
  }
}
