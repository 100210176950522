<p class="sm-block sm-pt-4 sm-text-xs sm-text-secondary-text" *ngIf="passwordValidations.length">
  Passwords must contain:
</p>
<ng-container *ngFor="let validation of passwordValidations">
  <p
    class="sm-flex sm-gap-1"
    [ngClass]="validation.valid ? 'sm-text-default-green' : 'sm-text-secondary-text'"
  >
    <i *ngIf="!validation.valid" class="cc-icon cc-icon-close sm-text-xl"></i>
    <i *ngIf="validation.valid" class="cc-icon cc-icon-check sm-text-xl"></i>

    <span class="sm-text-xs" i18n>{{ validation.message }}</span>
  </p>
</ng-container>
