"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Modules = void 0;
var Modules;
(function (Modules) {
    Modules["Action"] = "action";
    Modules["App"] = "app";
    Modules["List"] = "list";
    Modules["Form"] = "form";
})(Modules = exports.Modules || (exports.Modules = {}));
