"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignatureLogicUserTypes = void 0;
var SignatureLogicUserTypes;
(function (SignatureLogicUserTypes) {
    SignatureLogicUserTypes["Contributor"] = "contributor";
    SignatureLogicUserTypes["Viewer"] = "viewer";
    SignatureLogicUserTypes["Visitor"] = "visitor";
    SignatureLogicUserTypes["Any"] = "any";
})(SignatureLogicUserTypes = exports.SignatureLogicUserTypes || (exports.SignatureLogicUserTypes = {}));
