<div class="form-group row main-container">
  <cc-form-field-label [model]="model" class="form-label" [label]="model.description">
    <span class="sm-text-secondary-text" data-test="scan-signon-entries">
      {{ model.entries?.length || 0 }} <span i18n>{{ model.entries?.length === 1 ? 'row' : 'rows' }}</span>
    </span>
  </cc-form-field-label>
  <div class="sm-flex sm-w-auto sm-flex-col sm-space-y-2">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>
            <div class="sm-flex sm-items-center"><i class="cc-icon cc-icon-ellipsis-horizontal"></i></div>
          </th>
          <th *ngFor="let header of tableHeaderValues" i18n>{{ header }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="!model.entries || !model.entries.length">
          <td colspan="9">
            <div class="sm-mt-4 sm-flex sm-justify-center">
              <img
                src="./assets/images/user/sitemate-users/sitemate-id-mockup.svg"
                alt="sitemate-id-mockup"
                class="sitemate-id-mockup sm-w-legacy-22.4"
              />
            </div>
            <div class="sm-m-4" i18n>
              Users of the
              <img
                title="Sitemate"
                src="assets/icons/multi-colored-icons/sitemate-square.svg"
                alt="sitemate-logo"
                class="sm-inline-block sm-align-middle"
              />
              <a
                id="sitemate-app"
                class="sm-underline"
                href="https://intercom.help/dashpivot/en/articles/5401392-sitemate-app-overview"
                target="_blank"
              >
                Sitemate App</a
              >
              can be scanned on by a
              <img
                src="assets/icons/multi-colored-icons/dashpivot-square.svg"
                alt="dashpivot-logo"
                class="sm-inline-block sm-align-middle"
              />
              <a
                id="dashpivot-app"
                class="sm-underline"
                href="https://intercom.help/dashpivot/en/articles/2563239-dashpivot-app-overview"
                target="_blank"
              >
                Dashpivot App</a
              >
              user to sign on.
            </div>

            <div class="text-center sm-mb-4">
              <a
                id="learn-more"
                href="https://intercom.help/dashpivot/en/articles/3901878-setting-up-a-contactless-sign-on-process"
                target="_blank"
                class="px-2 py-1 rounded btn btn-sm cc-btn-subtle-gray help-text"
              >
                <i class="mr-1 cc-icon cc-icon-question" aria-hidden="true"></i><span i18n>Learn more</span>
              </a>
            </div>
          </td>
        </tr>
        <tr *ngFor="let entry of model.entries; let i = index">
          <td>
            <cc-dropdown
              *ngIf="canDeleteRows"
              placement="bottom-left"
              size="lg"
              data-test="action-dropdown"
              autoClose="outside"
            >
              <button type="button" class="action-dropdown-trigger" dropdownToggle>
                <i class="cc-icon cc-icon-ellipsis-horizontal"></i>
              </button>

              <cc-dropdown-item (click)="onDeleteEntry(entry, i)" dropdownItem data-test="delete-property">
                <div class="action-dropdown-item">
                  <em class="cc-icon cc-icon-delete sm-text-default-red" aria-hidden="true"></em>
                  <span i18n>Delete Row</span>
                </div>
              </cc-dropdown-item>
            </cc-dropdown>
            <div class="mt-2">
              <i
                class="cc-icon cc-icon-warning"
                *ngIf="entry.error"
                [ngbPopover]="entry.error"
                popoverClass="no-max-width"
                triggers="hover"
                aria-hidden="true"
              ></i>
            </div>
          </td>
          <td><cc-signon-table-user [user]="entry.user"></cc-signon-table-user></td>
          <td>{{ entry.user.companyName }}</td>
          <td>
            <img
              (contextmenu)="limitPointerEvents()"
              (dragstart)="limitPointerEvents()"
              class="signature"
              [src]="getSignatureImage(entry.user.signatureUrl)"
              alt="Signature of {{ entry.user.firstName }} {{ entry.user.lastName }}"
            />
          </td>
          <td>{{ entry.date | date: 'mediumDate' }}</td>
          <td>{{ entry.date | date: 'shortTime' }}</td>
          <td>
            <cc-signon-table-location
              [point]="entry.location"
              [fullName]="entry.user.firstName + ' ' + entry.user.lastName"
              [date]="(entry.date | date: 'mediumDate') + ' ' + (entry.date | date: 'shortTime')"
            ></cc-signon-table-location>
          </td>
          <td class="sm-break-all">{{ entry.location?.coordinates[0] }}</td>
          <td class="sm-break-all">{{ entry.location?.coordinates[1] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
