import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import DOMPurify from 'dompurify';

interface SafeHtmlConfig {
  forceTargetBlank?: boolean;
}

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value = '', config: SafeHtmlConfig = {}): SafeHtml {
    const purifierConfig: DOMPurify.Config = {};

    if (config.forceTargetBlank) {
      DOMPurify.addHook('afterSanitizeAttributes', (node) => {
        if ('target' in node) {
          node.setAttribute('target', '_blank');
          node.setAttribute('rel', 'noopener');
        }
        if (!node.hasAttribute('target') && (node.hasAttribute('xlink:href') || node.hasAttribute('href'))) {
          node.setAttribute('xlink:show', 'new');
        }
      });
    }

    const purifiedHtml = DOMPurify.sanitize(value, purifierConfig);
    return this.sanitizer.bypassSecurityTrustHtml(purifiedHtml);
  }
}
