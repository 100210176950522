"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableColumnWidths = void 0;
var TableColumnWidths;
(function (TableColumnWidths) {
    TableColumnWidths["Legacy"] = "legacy";
    TableColumnWidths["Small"] = "small";
    TableColumnWidths["Medium"] = "medium";
    TableColumnWidths["Large"] = "large";
    TableColumnWidths["ExtraLarge"] = "extra-large";
})(TableColumnWidths = exports.TableColumnWidths || (exports.TableColumnWidths = {}));
