"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportProgressStatus = void 0;
var ExportProgressStatus;
(function (ExportProgressStatus) {
    ExportProgressStatus["Processing"] = "processing";
    ExportProgressStatus["Merging"] = "merging";
    ExportProgressStatus["Rendering"] = "rendering";
    ExportProgressStatus["CreatingZip"] = "creating zip";
    ExportProgressStatus["SavingFile"] = "saving file";
    ExportProgressStatus["Finished"] = "finished";
    ExportProgressStatus["Error"] = "error";
})(ExportProgressStatus = exports.ExportProgressStatus || (exports.ExportProgressStatus = {}));
