<quill-editor
  [(ngModel)]="model.content"
  [modules]="{ toolbar: editorToolbarOption }"
  [styles]="{ background: '#ffffff', 'min-width': '100%' }"
  [formats]="formats"
  [placeholder]="placeholder"
  (onEditorCreated)="onEditorCreated($event)"
  (onContentChanged)="onChange()"
  (click)="onClick()"
  (onBlur)="onBlur()"
></quill-editor>
