export class TimeFormatter {
  // eslint-disable-next-line no-useless-escape
  private static readonly matcher = /^([0-9]{2})\:([0-9]{2})$/;
  static format(input: string | null | undefined) {
    const match = String(input).match(this.matcher);
    if (!match) {
      return null;
    }
    const [hour, minute] = [Number(match[1]), Number(match[2])];
    if (hour > 23 || minute > 59) {
      return null;
    }
    const [pad, length] = ['0', 2];
    return `${hour.toString().padStart(length, pad)}:${minute.toString().padStart(length, pad)}`;
  }
}
