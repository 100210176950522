import { Directive, ElementRef, HostListener, Renderer2, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[ccContentEditableModel]',
  providers: [
    // eslint-disable-next-line no-use-before-define
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ContenteditableModelDirective), multi: true },
  ],
})
export class ContenteditableModelDirective implements ControlValueAccessor {
  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  @HostListener('input') onInput(): void {
    this.onChange(this.processInput(this.elementRef.nativeElement.innerText));
  }

  @HostListener('blur') onBlur(): void {
    this.onTouched();
  }

  writeValue(value: string): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'innerText', this.processInput(value));
  }

  registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  private processInput(value: string | null): string {
    return value ? value.trim() : '';
  }
}
