"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailRegex = exports.SubdomainRegex = exports.DomainRegex = void 0;
/**
 * Regular expression pattern for validating a domain.
 *
 * The pattern matches either an IP address or a domain name that contains at least one period (.) and
 * ends with a two-letter top-level domain (TLD).
 * The domain name can contain letters, numbers, and hyphens (-).
 * The domain cannot start, end or contain consecutive hyphens.
 * The domain cannot contain special characters or spaces.
 */
exports.DomainRegex = /^(?:\[[0-9]{1,3}(?:\.[0-9]{1,3}){3}\]|(?:[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,})$/;
/**
 * Regular expression pattern for validating a subdomain.
 *
 * The pattern matches a subdomain name that contains letters, numbers, and hyphens (-).
 * The subdomain cannot start, end or contain consecutive hyphens.
 * The subdomain cannot contain special characters or spaces.
 */
exports.SubdomainRegex = /^[a-z0-9]+(-[a-z0-9]+)*$/;
const localPart = '(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))';
const ipDomain = '(\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])';
const standardDomain = exports.DomainRegex.source.substring(1, exports.DomainRegex.source.length - 1);
exports.EmailRegex = new RegExp(`^${localPart}@(${ipDomain}|${standardDomain})$`);
