"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableCellListCloner = void 0;
const table_cell_repository_1 = require("./table-cell-repository");
const __1 = require("../../..");
class TableCellListCloner extends table_cell_repository_1.TableCellGenericCloner {
    getListItemsForCell(field, templateCol, updatedCell) {
        return __awaiter(this, void 0, void 0, function* () {
            if (field.kind === __1.FieldKinds.Table) {
                return templateCol.listSource === 'list' && templateCol.reference
                    ? this.getListItems(templateCol.reference)
                    : templateCol.manualListItems || [];
            }
            return updatedCell.listSource === 'list' && updatedCell.reference
                ? this.getListItems(updatedCell.reference)
                : updatedCell.manualListItems || [];
        });
    }
    listValueExists(list, value) {
        return list.some((item) => item.value === value);
    }
    merge(field, templateCol, templateCell, formCol, formCell) {
        const _super = Object.create(null, {
            merge: { get: () => super.merge }
        });
        return __awaiter(this, void 0, void 0, function* () {
            const updatedCell = yield _super.merge.call(this, field, templateCol, templateCell, formCol, formCell);
            updatedCell.style = formCell.style;
            if (field.kind === __1.FieldKinds.PrefilledTable && (templateCell === null || templateCell === void 0 ? void 0 : templateCell.manualListItems)) {
                updatedCell.manualListItems = templateCell.manualListItems;
            }
            const updatedList = yield this.getListItemsForCell(field, templateCol, updatedCell);
            if (!this.listValueExists(updatedList, updatedCell.value)) {
                updatedCell.value = '';
            }
            return updatedCell;
        });
    }
}
exports.TableCellListCloner = TableCellListCloner;
