import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map, retry, switchMap } from 'rxjs/operators';

import { JwtService } from 'app/auth/jwt.service';
import { environment } from 'environments/environment';

import { dayjs } from '../dayjs';

@Injectable({
  providedIn: 'root',
})
export class HttpClientService {
  private readonly window: Window;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly jwtService: JwtService,
    @Inject(DOCUMENT) document: Document,
  ) {
    this.window = document.defaultView;
  }

  public get<T = any>(path: string, fromObject?: Record<string, any>): Observable<T>;
  public get<T = Blob>(path: string, fromObject: Record<string, any>, response: 'blob'): Observable<T>;
  public get<T = Blob>(
    path: string,
    fromObject: Record<string, any>,
    response: 'blob',
    observe: 'response',
  ): Observable<HttpResponse<T>>;

  public get<T = any>(
    path: string,
    fromObject: Record<string, any> = {},
    responseType?: 'arraybuffer' | 'json' | 'blob',
    observe?: 'response' | 'events' | 'body',
  ) {
    const params = new HttpParams({ fromObject });
    const headers$ = from(this.getHeaders());
    return headers$.pipe(
      switchMap((headers) =>
        this.httpClient
          .get<T>(`${environment.apiUrl}${path}`, {
            headers,
            params,
            ...(responseType && { responseType: responseType as any }),
            ...(observe && { observe: observe as any }),
          })
          .pipe(retry({ count: 2, delay: 500 })),
      ),
    );
  }

  public put<T = any>(path: string, body: Record<string, any> = {}): Observable<T> {
    const headers$ = from(this.getHeaders());

    return headers$.pipe(
      switchMap((headers) => this.httpClient.put<T>(`${environment.apiUrl}${path}`, body, { headers })),
    );
  }

  public post<T = any>(
    path: string,
    body: Record<string, any> = {},
    fromObject: Record<string, any> = {},
  ): Observable<T> {
    const params = new HttpParams({ fromObject });
    const headers$ = from(this.getHeaders());

    return headers$.pipe(
      switchMap((headers) =>
        this.httpClient.post<T>(`${environment.apiUrl}${path}`, body, { headers, params }),
      ),
    );
  }

  public upload<T = any>(path: string, formData: FormData): Observable<T> {
    const headers$ = from(this.getFileTransferHeaders());
    return headers$.pipe(
      switchMap((headers) => this.httpClient.post<T>(`${environment.apiUrl}${path}`, formData, { headers })),
    );
  }

  public putUpload<T = any>(path: string, formData: FormData): Observable<T> {
    const headers$ = from(this.getFileTransferHeaders());
    return headers$.pipe(
      switchMap((headers) => this.httpClient.put<T>(`${environment.apiUrl}${path}`, formData, { headers })),
    );
  }

  public getFileNameFromResponseContentDisposition(res: HttpResponse<any>) {
    const contentDisposition = res.headers.get('content-disposition') || '';
    const matches = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/i.exec(contentDisposition);
    const fileName = matches ? matches[1].trim() : null;
    return fileName;
  }

  public directDownload(path: string, fromObject: Record<string, any> = {}, headers?) {
    const observe = 'response';
    const params = new HttpParams({ fromObject });
    const responseType = 'blob' as 'json';
    return this.httpClient
      .get<Blob>(path, {
        headers,
        observe,
        params,
        responseType,
      })
      .pipe(
        map((response) => ({
          blob: response.body,
          name: this.getFileNameFromResponseContentDisposition(response),
        })),
      );
  }

  public download(path: string, fromObject: Record<string, any> = {}) {
    const headers$ = from(this.getFileTransferHeaders());

    return headers$.pipe(
      switchMap((headers) => this.directDownload(`${environment.apiUrl}${path}`, fromObject, headers)),
    );
  }

  public downloadPost(path: string, body) {
    const observe = 'response';
    const responseType = 'blob';
    const headers$ = from(this.getFileTransferHeaders());

    return headers$.pipe(
      switchMap((headers) =>
        this.httpClient
          .post(`${environment.apiUrl}${path}`, body, {
            headers,
            observe,
            responseType,
          })
          .pipe(
            map((response) => ({
              blob: response.body,
              name: this.getFileNameFromResponseContentDisposition(response),
            })),
          ),
      ),
    );
  }

  public remove<T = any>(path, params = {}) {
    const headers$ = from(this.getHeaders());

    return headers$.pipe(
      switchMap((headers) => this.httpClient.delete<T>(`${environment.apiUrl}${path}`, { headers, params })),
    );
  }

  public head<T = any>(url = environment.apiUrl, path, params = {}) {
    return this.httpClient.head<T>(`${url}${path}`, params);
  }

  private get shouldUsePlaceHolderToken() {
    const isInsideAnIframe = this.window !== this.window.parent;
    const isLibraryApp = this.window.location.pathname.includes('/library-apps');

    return isInsideAnIframe || isLibraryApp;
  }

  private async getUserToken() {
    if (this.shouldUsePlaceHolderToken) {
      return environment.apiClientToken;
    }

    return this.jwtService.getToken();
  }

  private async getHeaders() {
    const token = await this.getUserToken();

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Time-Zone': dayjs.tz.guess(), // Custom header supplying user timezone with every call
      Authorization: `JWT ${token}`,
      ...this.getOriginInfoHeader(),
    };

    if (!token) {
      headers['X-Debug-Empty-Token'] = 'true';
    }

    return headers;
  }

  private async getFileTransferHeaders() {
    const token = await this.getUserToken();

    const headers = {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      'Time-Zone': dayjs.tz.guess(), // Custom header supplying user timezone with every call
      Authorization: `JWT ${token}`,
      ...this.getOriginInfoHeader(),
    };

    if (!token) {
      headers['X-Debug-Empty-Token'] = 'true';
    }

    return headers;
  }

  private getOriginInfoHeader() {
    const isRemoteHost = this.window.location.hostname.includes('.');
    return isRemoteHost ? { 'x-origin-info': this.window.location.origin } : {};
  }
}
