/* eslint-disable max-lines */
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isEmpty, noop } from 'lodash-es';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { EventTypes, IHierarchy, User } from '@site-mate/dashpivot-shared-library';

import { FormDateUtil } from 'app/form/form-date-util.service';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { PhotoModalService } from 'app/photo/photo-modal.service';
import { PhotoService } from 'app/photo/photo.service';
import { TeamSelectComponent } from 'app/photo/team-select.component';
import { dayjs } from 'app/shared/dayjs';
import { WINDOW } from 'app/shared/factory/window.factory';
import { PhotoTagGroupWithDecoratedTags } from 'app/shared/model/photo-tag-group.model';
import { PhotoTag } from 'app/shared/model/photo-tag.model';
import { PhotoTimeLine } from 'app/shared/model/photo-timeline.model';
import { PhotoWeb } from 'app/shared/model/photo-web.model';
import { UploaderParams } from 'app/shared/model/uploader-params.model';
import { AppUtilService } from 'app/shared/service/app-util.service';
import { ConfirmService } from 'app/shared/service/confirm.service';
import { DataCompressionService } from 'app/shared/service/data-compression/data-compression.service';
import { DownloaderService } from 'app/shared/service/downloader.service';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { EventsService } from 'app/shared/service/events/events.service';
import { FileUploaderModalService } from 'app/shared/service/file-uploader-modal.service';
import { LocalStorageService } from 'app/shared/service/local-storage.service';
import { ToastrService } from 'app/shared/service/toastr.service';
import { UuidService } from 'app/shared/service/uuid/uuid.service';
import { ModalWithActionComponent } from 'app/ui-components/modal-with-action/modal-with-action.component';
import { environment } from 'environments/environment';

import { PhotoTagsService } from './photo-tags.service';

@Component({
  selector: 'cc-photo',
  templateUrl: 'photo.component.html',
  styleUrls: ['photo.component.scss'],
})
export class PhotoComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  @Input() hierarchy: IHierarchy;
  @Input() formRef = false;
  @Input() uploaderLabels: UploaderParams['labels'];
  @Input() acceptedFiles: UploaderParams['acceptedFiles'] =
    'image/jpg, image/bmp, image/jpeg, image/png, image/tiff, video/mp4, .mov';

  @Output() selectedPhoto = new EventEmitter<PhotoWeb[]>();

  timeLines: PhotoTimeLine[] = [];
  selected = [];
  tagsToSelect: PhotoTag[] = [];
  kmlFile: string;
  users: User[];
  downloadingPDF: boolean;
  downloadingZip: boolean;
  loading: boolean;
  teamId: string;
  private limit = 60;
  private offset = 0;
  private end;
  searchCriteria = {
    fullText: '',
    date: { startDate: undefined, endDate: undefined },
    userIds: [],
    tags: undefined,
  };

  resetTagFilter = false;
  isLoadingUsers = false;

  constructor(
    private readonly appUtilService: AppUtilService,
    public readonly photoService: PhotoService,
    private readonly i18nService: TmpI18NService,
    private readonly confirmService: ConfirmService,
    private readonly errorHandler: ErrorHandler,
    private readonly toastr: ToastrService,
    private readonly modal: NgbModal,
    private readonly photoModal: PhotoModalService,
    private readonly eventsService: EventsService,
    private readonly route: ActivatedRoute,
    private readonly downloaderService: DownloaderService,
    private readonly dataCompressionService: DataCompressionService,
    @Inject(WINDOW) private readonly window: Window,
    private readonly localStorageService: LocalStorageService,
    private readonly fileUploaderModalService: FileUploaderModalService,
    private readonly photoTagsService: PhotoTagsService,
    private readonly eventService: EventsService,
    private readonly uuidService: UuidService,
  ) {}

  ngOnInit(): void {
    this.teamId = String(this.hierarchy.team._id);
    this.loadPhotos(this.teamId);
    this.photoService
      .getKml(this.teamId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (kml) => {
          this.kmlFile = kml.kmlFile;
        },
        error: (error) => this.errorHandler.handle(error),
      });

    this.getPhotoUsers(this.teamId);

    this.photoService.deletePhotoEvent.pipe(takeUntil(this.destroy$)).subscribe((id) => {
      if (id) {
        this.timeLines.forEach((t) => {
          t.photos = t.photos.filter((p) => p.id !== id);
        });
        this.selected = this.selected.filter((s) => s.id !== id);
        this.timeLines = this.timeLines.filter((t) => t.photos.length > 0);
      }
    });
    this.photoService.reloadEvent.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.getPhotoUsers(this.teamId);
      this.loadPhotos(this.teamId);
    });

    this.setTagsToSelect();
  }

  private getPhotoUsers(teamId: string) {
    this.isLoadingUsers = true;

    this.photoService
      .getPhotoUsersForTeam(teamId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (users) => {
          this.users = users;
          this.isLoadingUsers = false;
        },
        error: (error) => {
          this.errorHandler.handle(error);
          this.isLoadingUsers = false;
        },
      });
  }

  ngOnDestroy() {
    this.timeLines = [];
    this.selected = [];
    this.kmlFile = undefined;
    this.users = [];
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadPhotos(teamId: string) {
    this.selected = [];
    this.selectedPhoto.emit([]);
    this.timeLines = [];
    this.offset = 0;
    this.end = false;
    this.loading = true;
    this.photoService.getPhotosByTeam(teamId, this.limit * 2, this.offset, this.searchCriteria).subscribe({
      next: (photos) => {
        this.loading = false;
        this.offset = this.limit;
        if (photos.length < this.limit) {
          this.end = true;
        }
        this.timeLines = this.sortByDate(photos);
      },
      error: (error) => {
        this.loading = false;
        this.errorHandler.handle(error);
      },
    });
  }

  isEmptySearchCriteria() {
    return this.photoService.isEmptySearchCriteria(this.searchCriteria);
  }

  sortByDate(photos) {
    // remove duplicated photos;
    const uniquePhotos = [];
    photos.forEach((p) => {
      const existed = uniquePhotos.filter((u) => u.filename === p.filename);
      if (existed.length === 0) {
        uniquePhotos.push(p);
      }
    });

    const tmp = {}; // this stores data like {time:[photos]}
    uniquePhotos.forEach((p) => {
      const d = new Date(p.createdAt);
      const ymd = new Date(d.getFullYear(), d.getMonth(), d.getDate()).getTime();
      if (tmp[ymd]) {
        tmp[ymd].push(p);
      } else {
        tmp[ymd] = [p];
      }
    });

    // to array
    const ret = [];
    Object.keys(tmp).forEach((k) => {
      const index = Number(k);
      ret.push({ time: index, photos: tmp[index] });
    });
    return ret;
  }

  appendPhotos(photos: PhotoWeb[]) {
    photos.forEach((photo: PhotoWeb) => {
      const d = new Date(photo.createdAt);
      const ymd = new Date(d.getFullYear(), d.getMonth(), d.getDate()).getTime();

      const matched = this.timeLines.filter((t) => t.time === ymd);
      if (matched.length > 0) {
        matched[0].photos.push(photo);
      } else {
        this.timeLines.push({ time: ymd, photos: [photo], selected: false });
      }
    });
  }

  onRemove() {
    if (this.selected.length === 0) {
      return;
    }
    this.eventsService.trackEvent(EventTypes.PhotosDeleted, { photoIds: this.selected.map((p) => p.id) });

    this.confirmService
      .confirmDelete('deletePhotos')
      .then(() => {
        this.clearTimeLine();
        this.toastr.successByKey('photosRemoved');
        // eslint-disable-next-line promise/always-return
        while (this.selected.length > 0) {
          const p = this.selected.pop();
          this.photoService.remove(p.id).subscribe({ error: (error) => this.errorHandler.handle(error) });
        }
        this.getPhotoUsers(String(this.hierarchy.team._id));
      })
      .catch((error) => this.errorHandler.handle(error));
  }

  clearTimeLine() {
    this.timeLines.forEach((t) => {
      t.photos = t.photos.filter((p) => this.selected.filter((s) => s.id === p.id).length === 0);
    });

    this.timeLines = this.timeLines.filter((t) => t.photos.length > 0);
  }

  clearSelection() {
    this.selected.forEach((e) => {
      e.selected = false;
    });
    this.selected = [];
    this.timeLines.forEach((t) => {
      t.selected = false;
    });
  }

  onDownload() {
    this.openDownloadTab('getDownloadZipPhotosUrl');
  }

  onExport() {
    this.openDownloadTab('getDownloadPhotosUrl');
  }

  private openDownloadTab(getUrlFn) {
    if (!this.selected.length) {
      return;
    }

    const keyStorage = this.downloaderService.getRandomDownloadId();
    this.localStorageService.setItem(
      keyStorage,
      JSON.stringify({
        ids: this.dataCompressionService.compress(this.getStringifiedSelectedPhotoIds()),
        timestamp: Date.now(),
        type: this.downloaderService.downloaderType,
      }),
    );
    const url = this.downloaderService[getUrlFn]({
      teamId: String(this.hierarchy.team._id),
      queryParams: { ...this.route.snapshot.queryParams, downloadId: keyStorage },
    });

    this.window.open(`${url}`, '_blank');
  }

  private getStringifiedSelectedPhotoIds() {
    return JSON.stringify(this.selected.map((selectedPhoto) => selectedPhoto.id));
  }

  toggle(photo) {
    if (photo.selected) {
      this.eventsService.trackEvent(EventTypes.PhotoSelected);
      this.selected.push(photo);
    } else {
      this.selected = this.selected.filter((s) => s.id !== photo.id);
    }
    this.selectedPhoto.emit(this.selected);
  }

  onThumbnailClick(event, photo, timeLineIndex, photoIndex) {
    if ((this.formRef && this.appUtilService.isMobile) || event.ctrlKey || event.metaKey) {
      photo.selected = !photo.selected;
      this.toggle(photo);
      return;
    }

    this.eventsService.trackEvent(EventTypes.PhotoClicked, { photoId: photo.id });

    this.photoModal.open({
      timeLines: this.timeLines,
      timeLineIndex,
      photoIndex,
      hideDeleteButton: this.formRef,
      kmlFile: this.kmlFile,
      teamId: String(this.hierarchy.team._id),
      projectId: String(this.hierarchy.project._id),
    });
  }

  onScroll() {
    if (!this.end) {
      this.eventsService.trackEvent(EventTypes.PhotosLoaded);
      this.offset += this.limit;
      this.photoService
        .getPhotosByTeam(String(this.hierarchy.team._id), this.limit, this.offset, this.searchCriteria)
        .subscribe((photos) => {
          if (photos.length < this.limit) {
            this.end = true;
          }
          if (photos.length) {
            this.appendPhotos(photos);
          }
        });
    }
  }

  onSearchBySiteNote(event) {
    this.searchCriteria.fullText = '';
    if (event.note) {
      this.searchCriteria.fullText = event.note;
    }
    this.loadPhotos(String(this.hierarchy.team._id));
  }

  onSearchByDate(event: { startDate?: NgbDateStruct; endDate?: NgbDateStruct }) {
    this.searchCriteria.date = { startDate: undefined, endDate: undefined };

    if (!isEmpty(event.startDate)) {
      const { year, month, day } = event.startDate;
      const startOfDay = { hour: 0, minute: 0, second: 0 };
      this.searchCriteria.date.startDate = FormDateUtil.convertDateToUTCString(
        { year, month, day },
        startOfDay,
      );
    }

    if (!isEmpty(event.endDate)) {
      const { year, month, day } = event.endDate;
      const endOfDay = { hour: 23, minute: 59, second: 59 };
      this.searchCriteria.date.endDate = FormDateUtil.convertDateToUTCString({ year, month, day }, endOfDay);
    }

    this.loadPhotos(String(this.hierarchy.team._id));
  }

  onAddPhotoTags() {
    const modalRef = this.modal.open(ModalWithActionComponent, {
      windowClass: 'modal-500 modal-overlay',
      modalDialogClass: 'modal-dialog-centered',
    });
    modalRef.componentInstance.title = this.i18nService.getMessage('addTagsModalTitle');
    modalRef.componentInstance.message = this.i18nService.getMessage('addTagsModalMessage');
    modalRef.componentInstance.actionTitle = this.i18nService.getMessage('addTagsModalActionTitle');
    modalRef.componentInstance.hasSearchSelector = true;
    modalRef.componentInstance.items = this.tagsToSelect;
    modalRef.componentInstance.action = () => this.onTagsAdded(modalRef.componentInstance.selectedItems);
  }

  onTagsAdded(selectedTags: PhotoTag[]) {
    const photosIds = this.selected.map((photo) => photo.id);

    this.photoService.updateTeamPhotosTags(this.teamId, photosIds, selectedTags).subscribe({
      next: (updatedPhotos) => {
        this.updateTimelineWithUpdatedPhotos(updatedPhotos);
        this.clearSelection();
        this.eventService.trackEvent(EventTypes.PhotoBulkActionApplied, { Context: 'Bulk Tags' });
        this.toastr.successByKey('tagsAdded');
      },
      error: (error) => {
        error.hideToastr = true;
        this.errorHandler.handle(error);
        this.toastr.errorByKey('tagsNotAdded');
      },
    });
  }

  private updateTimelineWithUpdatedPhotos(photos: PhotoWeb[]) {
    this.timeLines = this.timeLines.map((timeLine: PhotoTimeLine) => {
      timeLine.photos = timeLine.photos.map((photo) => {
        const updatedPhoto = photos.find((p) => p.id === photo.id);
        if (!updatedPhoto) {
          return photo;
        }
        updatedPhoto.createdBy = photo.createdBy;
        return updatedPhoto;
      });
      return timeLine;
    });
  }

  onSearchByUsers(event) {
    this.searchCriteria.userIds = event.selectedUsers;
    this.loadPhotos(String(this.hierarchy.team._id));
  }

  clearSearchCriteria() {
    this.searchCriteria.fullText = '';
    this.searchCriteria.date = { startDate: undefined, endDate: undefined };
    this.searchCriteria.userIds = [];
    this.searchCriteria.tags = undefined;
  }

  isScrollWindow() {
    return !this.formRef && this.appUtilService.isSmallWindow;
  }

  onFilterTags(tagGroups: PhotoTagGroupWithDecoratedTags[]) {
    this.resetTagFilter = false;

    const searchTerm = tagGroups
      .filter((tagGroup) => tagGroup.tags.some((tag) => tag.selected))
      .map((tagGroup) => {
        return `${tagGroup.id}:${tagGroup.tags
          .filter((tag) => tag.selected)
          .map((tag) => tag.name)
          .join(',')}`;
      })
      .join(';');
    this.searchCriteria.tags = searchTerm;

    this.eventsService.trackEvent(EventTypes.PhotoFilterApplied, {
      Context: 'Photo Tag',
      teamId: String(this.hierarchy.team._id),
    });
    this.loadPhotos(String(this.hierarchy.team._id));
  }

  onResetAllFilters() {
    this.eventsService.trackEvent(EventTypes.PhotoFilterReset, { Context: 'All' });
    this.resetTagFilter = true;
    this.clearSearchCriteria();
    this.loadPhotos(String(this.hierarchy.team._id));
  }

  onMove() {
    const modalRef = this.modal.open(TeamSelectComponent);
    modalRef.componentInstance.actionTitle = this.i18nService.getMessage('actionMove');
    void modalRef.result
      // eslint-disable-next-line promise/always-return
      .then((team) => {
        this.photoService
          .move(
            this.selected.map((item) => item._id),
            team._id,
          )
          .subscribe({
            next: () => {
              this.toastr.successByKey('movePhoto');
              this.eventsService.trackEvent(EventTypes.PhotoBulkActionApplied, { Context: 'Moved' });
              this.clearTimeLine();
              this.clearSelection();
              this.getPhotoUsers(String(this.hierarchy.team._id));
            },
            error: (error) => this.errorHandler.handle(error),
          });
      })
      .catch(noop);
  }

  onCopy() {
    const modalRef = this.modal.open(TeamSelectComponent);
    modalRef.componentInstance.actionTitle = this.i18nService.getMessage('actionCopy');
    void modalRef.result
      // eslint-disable-next-line promise/always-return
      .then((team) => {
        this.photoService
          .copy(
            this.selected.map((item) => item._id),
            team._id,
          )
          .subscribe({
            next: () => {
              this.eventsService.trackEvent(EventTypes.PhotoBulkActionApplied, { Context: 'Copied' });
              this.toastr.successByKey('copyPhoto');
              this.clearSelection();
            },
            error: (error) => this.errorHandler.handle(error),
          });
      })
      .catch(noop);
  }

  toggleSelectTimeLine(timeLine) {
    timeLine.photos.forEach((photo) => {
      photo.selected = timeLine.selected;
    });

    const ids = timeLine.photos.map((photo) => photo.id);
    // remove then re-add to avoid duplication
    this.selected = this.selected.filter((s) => !ids.includes(s.id));

    if (timeLine.selected) {
      this.selected.push(...timeLine.photos);
    }
    this.selectedPhoto.emit(this.selected);
  }

  uploadPhoto() {
    this.fileUploaderModalService.add({
      _id: this.uuidService.getUuid(),
      headers: {
        'Time-Zone': dayjs.tz.guess(),
      },
      acceptedFiles: this.acceptedFiles,
      url: `${environment.apiUrl}v1/teams/${this.teamId}/photos`,
      labels: this.uploaderLabels,
    });
    this.sendUploadPhotoEvent();
  }

  sendUploadPhotoEvent() {
    const context = this.formRef ? 'Form Modal' : 'Photo Timeline';
    this.eventService.trackEvent(EventTypes.UploadPhotoButtonClicked, { Context: context });
  }

  private setTagsToSelect() {
    forkJoin([
      this.photoTagsService.getTeamTagGroups(this.hierarchy.team._id),
      this.photoTagsService.getProjectTagGroups(this.hierarchy.project._id),
    ]).subscribe((tagGroups) => {
      const [teamTagGroups, projectTagGroups] = tagGroups;
      this.tagsToSelect = this.photoTagsService.getTagsFormattedForSelection([
        ...teamTagGroups,
        ...projectTagGroups,
      ]);
    });
  }
}
