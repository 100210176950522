"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableFormFieldValidator = void 0;
const table_cell_validator_factory_1 = require("./table-cell-validator-factory");
class TableFormFieldValidator {
    tableIsEmpty(field) {
        return !field.rows || field.rows.length === 0 || !field.columns || field.columns.length === 0;
    }
    tableHasInvalidCell(field) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return !!field.rows && field.rows.some((row) => row.columns && row.columns.some((cell) => cell._invalid));
    }
    tableIsRequired(field) {
        return !!field.columns && field.columns.some((column) => column.isRequired);
    }
    hasAnyData(field) {
        var _a;
        if (this.tableIsEmpty(field)) {
            return false; // empty table has no data
        }
        const hasAnyData = !!((_a = field.rows) === null || _a === void 0 ? void 0 : _a.some((row) => row.columns.some((cell, colIndex) => {
            const headerColumn = field.columns[colIndex];
            const validator = table_cell_validator_factory_1.TableCellValidatorFactory.getValidator(cell, headerColumn);
            return validator.hasAnyData();
        })));
        return hasAnyData;
    }
    /**
     * hasCompleteData() is different for tables (vs other form-fields)
     *  - it does have data if it's empty or doesn't have any required fields
     *  - it doesn't have data if it has an empty required field
     */
    hasCompleteData(field) {
        if (this.tableIsEmpty(field)) {
            return true; // table with empty structure has data
        }
        if (!this.tableIsRequired(field)) {
            return true; // table without a required column has data
        }
        field.rows.forEach((row) => {
            row.columns.forEach((cell, colIndex) => {
                const headerColumn = field.columns[colIndex];
                if (headerColumn.isRequired) {
                    const validator = table_cell_validator_factory_1.TableCellValidatorFactory.getValidator(cell, headerColumn);
                    cell._invalid = !validator.hasCompleteData();
                }
            });
        });
        return !this.tableHasInvalidCell(field);
    }
    tableHasOneEmptyRow(field) {
        if (field.rows && field.rows.length === 1) {
            let cellIsEmpty = true;
            field.columns.forEach((headerColumn, colIndex) => {
                const row = field.rows[0];
                const cell = row.columns[colIndex];
                const validator = table_cell_validator_factory_1.TableCellValidatorFactory.getValidator(cell, headerColumn);
                if (validator.hasAnyData()) {
                    cellIsEmpty = false;
                }
            });
            return cellIsEmpty;
        }
        return false;
    }
    isValid(field) {
        if (!this.tableIsEmpty(field)) {
            // check each cell is valid, set validity on each column
            field.columns.forEach((headerColumn, colIndex) => {
                let colInvalid = false;
                field.rows.forEach((row) => {
                    const cell = row.columns[colIndex];
                    const validator = table_cell_validator_factory_1.TableCellValidatorFactory.getValidator(cell, headerColumn);
                    cell._invalid = !validator.isValid();
                    if (cell._invalid) {
                        colInvalid = true;
                    }
                });
                headerColumn._invalid = colInvalid;
            });
        }
        // if this doesn't have data then it's not valid
        if (this.tableIsRequired(field) && !this.hasCompleteData(field)) {
            return false;
        }
        return !this.tableHasInvalidCell(field);
    }
    invalidDescription(field) {
        if (this.tableIsRequired(field) && !this.hasCompleteData(field)) {
            return '';
        }
        if (!this.isValid(field)) {
            return 'cell(s) have invalid data';
        }
        return '';
    }
}
exports.TableFormFieldValidator = TableFormFieldValidator;
