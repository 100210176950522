"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashpivotModules = void 0;
var DashpivotModules;
(function (DashpivotModules) {
    DashpivotModules["Templates"] = "Templates";
    DashpivotModules["Lists"] = "Lists";
    DashpivotModules["Photos"] = "Photos";
    DashpivotModules["Users"] = "Users";
    DashpivotModules["Analytics"] = "Analytics";
})(DashpivotModules = exports.DashpivotModules || (exports.DashpivotModules = {}));
