"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SitemateTaskDefinitionStatuses = void 0;
var SitemateTaskDefinitionStatuses;
(function (SitemateTaskDefinitionStatuses) {
    SitemateTaskDefinitionStatuses["Open"] = "open";
    SitemateTaskDefinitionStatuses["Complete"] = "complete";
    SitemateTaskDefinitionStatuses["Archived"] = "archived";
    SitemateTaskDefinitionStatuses["Expired"] = "expired";
})(SitemateTaskDefinitionStatuses = exports.SitemateTaskDefinitionStatuses || (exports.SitemateTaskDefinitionStatuses = {}));
