"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorTypes = void 0;
/**
 * Standard error types to share between Dashpivot services
 */
var ErrorTypes;
(function (ErrorTypes) {
    ErrorTypes["LimitExceeded"] = "LimitExceeded";
})(ErrorTypes = exports.ErrorTypes || (exports.ErrorTypes = {}));
