<div
  ngbDropdown
  #dropdown="ngbDropdown"
  class="sm-relative sm-mr-2 sm-block"
  (mouseenter)="dropdown.open()"
  (mouseleave)="dropdown.close()"
>
  <div class="btn-group">
    <button
      type="button"
      class="btn btn-secondary dropdown-toggle-split app-control-dropdown"
      (click)="$event.preventDefault(); dropdown.open()"
      ngbDropdownAnchor
    >
      <i class="cc-icon" [class]="iconClass"></i>
    </button>
  </div>
  <div ngbDropdownMenu class="dropdown-menu px-3" (click)="$event.stopPropagation()">
    <div
      *ngFor="let option of options"
      class="form-check justify-content-start sm-flex sm-flex-row sm-gap-x-1 sm-py-1"
      ccSegmentCTA="Template Field Dropdown Options"
      ctaContext="Text"
      [ctaHierarchy]="hierarchy"
    >
      <label i18n class="default-label form-check-label">
        <input
          class="form-check-input sm-p-1"
          type="radio"
          [formControl]="selectedFormControl"
          [value]="option.value"
          [name]="fieldId + '-' + value"
        />
        {{ option.label }}
      </label>
    </div>
  </div>
</div>
