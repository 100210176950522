import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

const CLASS_NAME = 'cc-icon cc-icon-spinner-lg sm-align-middle';
@Directive({
  selector: '[ccBusyButton]',
})
export class BusyButtonDirective implements OnChanges {
  @Input()
  ccBusyButton;

  @Input()
  shouldDisable = true;

  spinner;

  constructor(private element: ElementRef) {
    this.spinner = document.createElement('i');
    this.spinner.className = CLASS_NAME;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ccBusyButton) {
      const isBusy = changes.ccBusyButton.currentValue;
      if (isBusy === true) {
        this.toggleDisable(true);
        this.element.nativeElement.appendChild(this.spinner);
      } else if (isBusy === false) {
        this.toggleDisable(false);
        const children = this.element.nativeElement.childNodes;
        for (let i = 0; i < children.length; i++) {
          if (children[i].className === CLASS_NAME) {
            this.element.nativeElement.removeChild(children[i]);
            break;
          }
        }
      }
    }
  }

  toggleDisable(disabled: boolean) {
    if (this.shouldDisable) {
      this.element.nativeElement.disabled = disabled;
    }
  }
}
