<ul class="animate-fade-in nav-pills">
  <li class="nav-tab" *ngFor="let tab of panelTabs">
    <a
      routerLinkActive="active"
      [routerLink]="tab.routerLink"
      class="btn cc-btn-subtle-gray-tab nav-link"
      i18n
    >
      {{ i18nTitles[tab.titleCode] }}

      <span
        data-test="tab-badge"
        class="sm-rounded sm-bg-default-blue sm-p-1 sm-align-baseline sm-text-xs sm-uppercase sm-text-white"
        *ngIf="tab.badge"
        >{{ tab.badge }}</span
      >
    </a>
  </li>
</ul>
