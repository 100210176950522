"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListService = void 0;
class ListService {
    filterDeployedListItems(list, parentId) {
        var _a, _b;
        const localList = Object.assign(Object.assign({}, list), { items: ((_a = list === null || list === void 0 ? void 0 : list.items) === null || _a === void 0 ? void 0 : _a.slice()) || [] });
        const localParentId = parentId;
        const isDeployedList = (_b = localList.deployedParents) === null || _b === void 0 ? void 0 : _b.includes(localParentId);
        localList.items = localList.items.filter((item) => { var _a; return !isDeployedList || ((_a = item.deployedParents) === null || _a === void 0 ? void 0 : _a.includes(localParentId)); });
        return localList;
    }
}
exports.ListService = ListService;
