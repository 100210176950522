"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableCellLocationCloner = void 0;
const lodash_1 = require("lodash");
const table_cell_repository_1 = require("./table-cell-repository");
class TableCellLocationCloner extends table_cell_repository_1.TableCellGenericCloner {
    merge(field, templateCol, templateCell, formCol, formCell) {
        const _super = Object.create(null, {
            merge: { get: () => super.merge }
        });
        return __awaiter(this, void 0, void 0, function* () {
            const defaultUpdatedCell = yield _super.merge.call(this, field, templateCol, templateCell, formCol, formCell);
            return Object.assign(Object.assign({}, defaultUpdatedCell), { item: (0, lodash_1.cloneDeep)(formCell.item) });
        });
    }
    getBlankCell(templateCol) {
        return Object.assign(Object.assign({}, super.getBlankCell(templateCol)), { item: null });
    }
}
exports.TableCellLocationCloner = TableCellLocationCloner;
