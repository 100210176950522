import { BannerKeys } from 'app/shared/model/banner-keys.enum';

import { FlowsiteTemplateReference } from './flowsite-template-reference.model';

export const FlowsiteBannerKeyByTemplateReference: Readonly<{
  [key in FlowsiteTemplateReference]: BannerKeys;
}> = {
  [FlowsiteTemplateReference.DashpivotXeroTimesheets]: BannerKeys.FlowsiteXeroTimesheets,
  [FlowsiteTemplateReference.DashpivotXeroDockets]: BannerKeys.FlowsiteXeroDockets,
  [FlowsiteTemplateReference.DashpivotPowerBiExcel]: BannerKeys.FlowsitePowerBiExcel,
  [FlowsiteTemplateReference.DashpivotPowerBiExcelSiteDiary]: BannerKeys.FlowsitePowerBiExcelTemplates,
  [FlowsiteTemplateReference.DashpivotPowerBiExcelIncidentReport]: BannerKeys.FlowsitePowerBiExcelTemplates,
  [FlowsiteTemplateReference.DashpivotPowerBiExcelAuditForm]: BannerKeys.FlowsitePowerBiExcelTemplates,
  [FlowsiteTemplateReference.DashpivotPowerBiExcelSwmsRams]: BannerKeys.FlowsitePowerBiExcelTemplates,
  [FlowsiteTemplateReference.DashpivotPowerBiExcelJsa]: BannerKeys.FlowsitePowerBiExcelTemplates,
  [FlowsiteTemplateReference.DashpivotPowerBiExcelMachinePrestart]: BannerKeys.FlowsitePowerBiExcelTemplates,
  [FlowsiteTemplateReference.DashpivotPowerBiExcelAttendanceRecord]: BannerKeys.FlowsitePowerBiExcelTemplates,
  [FlowsiteTemplateReference.DashpivotPowerBiExcelNonConformanceDefectReport]:
    BannerKeys.FlowsitePowerBiExcelTemplates,
  [FlowsiteTemplateReference.DashpivotPowerBiExcelCostDayworkReport]:
    BannerKeys.FlowsitePowerBiExcelTemplates,
  [FlowsiteTemplateReference.DashpivotQuickBooksDockets]: BannerKeys.FlowsiteQuickBooksDockets,
  [FlowsiteTemplateReference.DashpivotCSVExports]: BannerKeys.FlowsiteCSVExports,
  [FlowsiteTemplateReference.DashpivotPDFExports]: BannerKeys.FlowsitePDFExports,
};
