<button
  type="button"
  [class.disabled]="disabled"
  [class.capture-child-pointer-events]="actionButtonData !== ''"
  ngbDropdownItem
  #button
  [attr.data-action-button]="actionButtonData"
>
  <ng-content></ng-content>
</button>
