"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableCellListPropertyCloner = void 0;
const lodash_1 = require("lodash");
const table_cell_list_1 = require("./table-cell-list");
const table_cell_repository_1 = require("./table-cell-repository");
class TableCellListPropertyCloner extends table_cell_repository_1.TableCellGenericCloner {
    merge(templateField, templateCol, templateCell, formCol, formCell) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const metadataChanged = !(0, lodash_1.isEqual)(templateCol.metadata, formCol.metadata);
            if (metadataChanged) {
                return Object.assign(Object.assign({}, formCell), { item: null, metadata: templateCol.metadata });
            }
            if (((_a = formCol.data) === null || _a === void 0 ? void 0 : _a.itemId) !== ((_b = templateCol.data) === null || _b === void 0 ? void 0 : _b.itemId)) {
                return Object.assign(Object.assign({}, formCell), { item: null });
            }
            if (formCol.model !== templateCol.model) {
                return Object.assign(Object.assign({}, formCell), { item: null, model: templateCol.model });
            }
            if (yield this.hasListValueChanged({ templateField, formCol, formCell })) {
                return Object.assign(Object.assign({}, formCell), { item: null });
            }
            return formCell;
        });
    }
    getBlankCell(templateCol) {
        return {
            kind: templateCol.kind,
            metadata: templateCol.metadata,
            headerColumnId: templateCol._id,
            item: null,
        };
    }
    hasListValueChanged({ templateField, formCol, formCell, }) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            if (!((_a = this.formField) === null || _a === void 0 ? void 0 : _a.columns) || !this.formField.rows) {
                return true;
            }
            const templateReferenceColumn = (_b = templateField.columns) === null || _b === void 0 ? void 0 : _b.find(({ _id, id }) => { var _a; return [_id, id].includes((_a = formCol.metadata) === null || _a === void 0 ? void 0 : _a.referenceTableColumnId); });
            const formReferenceColumnIndex = this.formField.columns.findIndex(({ _id, id }) => { var _a; return [_id, id].includes((_a = formCol.metadata) === null || _a === void 0 ? void 0 : _a.referenceTableColumnId); });
            if (!templateReferenceColumn || formReferenceColumnIndex === -1) {
                return true;
            }
            const formReferenceColumn = this.formField.columns[formReferenceColumnIndex];
            const rowIndex = (_d = (_c = this.formField) === null || _c === void 0 ? void 0 : _c.rows) === null || _d === void 0 ? void 0 : _d.findIndex((row) => row.columns.find((cell) => cell._id === formCell._id));
            const listTemplateCell = null;
            const listFormCell = this.formField.rows[rowIndex].columns[formReferenceColumnIndex];
            const listCellCloner = new table_cell_list_1.TableCellListCloner(this.getListItems);
            const mergedListCell = yield listCellCloner.merge(templateField, templateReferenceColumn, listTemplateCell, // We don't have a table cell for default tables
            formReferenceColumn, listFormCell);
            return Boolean(mergedListCell.value) === false;
        });
    }
}
exports.TableCellListPropertyCloner = TableCellListPropertyCloner;
