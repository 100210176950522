import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';

import { PhotoModalComponent } from 'app/photo/photo-modal.component';
import { PhotoTagsService } from 'app/photo/photo-tags.service';

@Injectable({
  providedIn: 'root',
})
export class PhotoModalService {
  tagsToSelect = [];

  constructor(
    private readonly modal: NgbModal,
    private readonly photoTags: PhotoTagsService,
  ) {}

  open(options) {
    const modalRef = this.modal.open(PhotoModalComponent, {
      backdrop: 'static',
      keyboard: false,
      windowClass: 'large-size-modal-photo',
    });

    const { componentInstance }: { componentInstance: PhotoModalComponent } = modalRef;

    componentInstance.timeLines = options.timeLines;
    componentInstance.timeLineIndex = options.timeLineIndex;
    componentInstance.photoIndex = options.photoIndex;
    componentInstance.hideDeleteButton = options.hideDeleteButton;
    componentInstance.kmlFile = options.kmlFile;
    componentInstance.teamId = options.teamId;
    componentInstance.onDescriptionTagsChanged = options.onDescriptionTagsChanged;

    forkJoin([
      this.photoTags.getTeamTagGroups(options.teamId),
      this.photoTags.getProjectTagGroups(options.projectId),
    ]).subscribe((tagGroups) => {
      // @ts-expect-error implicitly has an 'any' type.
      // eslint-disable-next-line prefer-spread
      const flattenedTagGroups = [].concat.apply([], tagGroups); // flatten the tag groups into a single array
      componentInstance.tagsToSelect = this.getTagsForSelection(flattenedTagGroups);
    });

    return modalRef;
  }

  getTagsForSelection(tagGroups) {
    const tags = [];
    tagGroups.forEach((group) => {
      const decorated = group.tags.map((tag) => {
        return {
          label: `${group.name}: ${tag.name}`,
          value: tag.name,
          groupId: group.id,
          groupName: group.name,
        };
      });
      tags.push(...decorated);
    });
    return tags;
  }
}
