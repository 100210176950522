<div *ngFor="let signature of signatures; let i = index" class="sm-mb-2">
  <div class="signature">
    <img
      (contextmenu)="limitPointerEvents()"
      (dragstart)="limitPointerEvents()"
      [src]="signature.signatureUrl"
      [alt]="signature.fullName"
      class="signature-image"
    />
    <button
      type="button"
      *ngIf="shouldHaveDeleteButton(signature) | async"
      class="delete-signature-button"
      (click)="onDeleteEntry(signature, i)"
    >
      <img class="sm-w-2" src="/assets/icons/toast/close.svg" alt="delete-icon" />
    </button>
  </div>
  <div class="sm-break-words sm-text-secondary-text" [attr.data-test]="'signature-list-full-name-' + i">
    <small>{{ signature.fullName }}</small>
  </div>
  <div class="sm-break-words sm-text-secondary-text" [attr.data-test]="'signature-list-company-name-' + i">
    <small>{{ signature.companyName }}</small>
  </div>
  <div class="sm-break-words sm-text-secondary-text" [attr.data-test]="'signature-list-signed-on-' + i">
    <small>
      {{ signature.signedOn | amDateFormat: 'lll' }}
    </small>
  </div>
</div>
