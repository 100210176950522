"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportNotificationTypes = void 0;
var ExportNotificationTypes;
(function (ExportNotificationTypes) {
    ExportNotificationTypes["Csv"] = "export-csv";
    ExportNotificationTypes["Pdf"] = "export-pdf";
    ExportNotificationTypes["PdfCover"] = "export-pdf-cover";
    ExportNotificationTypes["PdfPhoto"] = "export-pdf-photo";
    ExportNotificationTypes["PdfThumbnail"] = "export-pdf-thumbnail";
    ExportNotificationTypes["Photo"] = "export-photos";
    ExportNotificationTypes["ListCSV"] = "export-list-csv";
    ExportNotificationTypes["TemplatesCSV"] = "export-templates-csv";
})(ExportNotificationTypes = exports.ExportNotificationTypes || (exports.ExportNotificationTypes = {}));
