<div>
  <small class="text-muted column-label" *ngIf="columnDescription">{{ columnDescription }}</small>
  <div
    ccContentEditableModel
    contenteditable="true"
    placeholder="Type text..."
    i18n-placeholder="Type text..."
    id="table-text-editor"
    class="form-control text-number-input table-input text-field"
    [(ngModel)]="cellData.value"
    (blur)="onBlur()"
    (ngModelChange)="handleOnChange()"
  ></div>
</div>
