"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormUtils = void 0;
const getBlankForm = (template, creator) => {
    const templateId = template._id || template.id || '';
    return {
        createdBy: creator,
        appVersion: template.appVersion,
        name: template.name,
        parents: [templateId],
        template: templateId,
        columns: template.columns || [],
        isInColumn: 1,
        expiresOn: template.expiresOn || new Date(),
        dynamicAttachments: template.dynamicAttachments || [],
        staticAttachments: template.staticAttachments || [],
        counterIsActivated: template.counterIsActivated || false,
        items: template.items || [],
        formulasVersion: template.formulasVersion || 1,
        comments: [],
        kind: template.kind,
    };
};
exports.FormUtils = {
    getBlankForm,
};
