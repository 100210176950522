import { PlatformLocation } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import esmDropzone, { Dropzone } from 'dropzone/dist/dropzone';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { JwtService } from 'app/auth/jwt.service';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { PhotoService } from 'app/photo/photo.service';
import { UploaderParams } from 'app/shared/model/uploader-params.model';
import { DropzoneFileWithTeamId } from 'app/shared/model/utils/dropzone-with-team-id.model';
import { MimeMapper } from 'app/shared/model/utils/mime-mapper.model';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { FileUploaderModalService } from 'app/shared/service/file-uploader-modal.service';
import { TeamService } from 'app/shared/service/team.service';

@Component({
  selector: 'cc-file-uploader-modal',
  templateUrl: 'file-uploader-modal.component.html',
  styleUrls: ['file-uploader-modal.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FileUploaderModalComponent implements AfterViewInit, OnDestroy, OnInit {
  private static instancesIndex = 0;
  private readonly destroy$ = new Subject<void>();
  private dropzone: esmDropzone.Dropzone;
  @Input() params: UploaderParams;
  @ViewChild('chooseFiles') public chooseFiles: ElementRef;
  @ViewChild('droppableArea') public droppableArea: ElementRef;
  uniqueIdentifier: number;
  teamId: string;

  constructor(
    private readonly i18nService: TmpI18NService,
    private readonly fileUploaderModalService: FileUploaderModalService,
    private readonly photoService: PhotoService,
    private readonly teamService: TeamService,
    private readonly jwtService: JwtService,
    private readonly location: PlatformLocation,
    private readonly errorHandler: ErrorHandler,
  ) {}

  get areAllUploadsFinished() {
    return this.params.state.uploaded + this.params.state.failed === this.params.state.total;
  }

  get validTypes() {
    const acceptedFiles = this.params ? this.params.acceptedFiles : null;
    return MimeMapper.map(acceptedFiles).join(', ');
  }

  ngOnInit() {
    // eslint-disable-next-line no-plusplus
    this.uniqueIdentifier = FileUploaderModalComponent.instancesIndex++;
    this.teamService.currentTeam.pipe(takeUntil(this.destroy$)).subscribe((team) => {
      this.teamId = team.id;
    });
    this.pushState();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.dropzone.files.forEach((file) => {
      this.dropzone.cancelUpload(file);
    });
  }

  async ngAfterViewInit() {
    const token = await this.jwtService.getToken();
    const Authorization = `JWT ${token}`;
    this.fileUploaderModalService.update(this.params, {
      headers: { Authorization },
      clickable: [this.chooseFiles.nativeElement, this.droppableArea.nativeElement],
    });
    this.dropzone = new Dropzone(`.dropzone-${this.uniqueIdentifier}`, this.params);
    this.dropzone.on('success', (_file, response) => {
      const currentInstance = this.fileUploaderModalService.get(this.params._id);
      this.fileUploaderModalService.update(this.params, {
        state: { uploaded: this.params.state.uploaded + 1 },
        uploadedFiles: [...(currentInstance.uploadedFiles ?? []), response],
      });
      if (this.areAllUploadsFinished) {
        this.fileUploaderModalService.allUploadsFinished$.next({
          url: this.params.url,
          data: response,
        });
      }
    });

    this.dropzone.on('addedfile', (providedFile) => {
      const file = providedFile as DropzoneFileWithTeamId;
      file.teamId = this.teamId;
      this.fileUploaderModalService.update(this.params, { state: { total: this.params.state.total + 1 } });
    });

    this.dropzone.on('error', (providedFile, response: { message: string; statusCode: string }) => {
      this.fileUploaderModalService.update(this.params, { state: { failed: this.params.state.failed + 1 } });
      if (response && response.message) {
        this.errorHandler.handle({ status: response.statusCode, error: response.message }, null, null, {
          enableHTML: true,
          toastLife: 5 * 60 * 1000,
        });
      }
    });
  }

  pushState() {
    window.history.pushState(null, null, `${this.location.pathname}?modal`);
  }

  showModal() {
    this.fileUploaderModalService.show(this.params);
    this.pushState();
  }

  closeOrMinimize() {
    if (this.areAllUploadsFinished) {
      if (this.params.state.uploaded > 0) {
        this.photoService.reload();
        this.fileUploaderModalService.notifyUploaderClosed(this.params._id);
      }
      this.fileUploaderModalService.remove(this.params);
    } else {
      this.fileUploaderModalService.minimizeAll();
    }
  }

  @HostListener('document:keyup.esc', ['$event'])
  onKeyup(event): void {
    event.stopPropagation();
    if (this.params.state.visible) {
      this.closeOrMinimize();
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  confirm(providedEvent) {
    const $event = providedEvent;
    if (!this.areAllUploadsFinished) {
      const message = this.i18nService.getMessage('fileBeingUploaded');
      $event.returnValue = message;
    }
  }

  modalClick(event) {
    if (event.target.classList.contains('fade')) {
      this.closeOrMinimize();
    }
  }
}
