import { Component, EventEmitter, inject, Input, OnDestroy, Output } from '@angular/core';
import { debounceTime, Subject, takeUntil } from 'rxjs';

import {
  GlobalItemModels,
  ITemplateWithHierarchy,
  TableCellData,
  TableCellKinds,
  TableColumn,
  TableColumnWidths,
} from '@site-mate/dashpivot-shared-library';

import { FormBaseComponent } from 'app/form/form-components/form-base.component';
import { FormService } from 'app/form/form.service';
import { FormTableCell } from 'app/form-fields/table/edit-cells/table-cell';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { dayjs } from 'app/shared/dayjs';
import { FieldWeb } from 'app/shared/model/item.model';
import { AppUtilService } from 'app/shared/service/app-util.service';
import { ToastrService } from 'app/shared/service/toastr.service';
import { UserService } from 'app/user/user.service';

import { TableColumnService } from '../service/table-column-service';

@Component({
  selector: 'cc-form-table-column',
  templateUrl: 'form-table-column.component.html',
  styleUrls: ['../../../form/form-components/form-component.scss', 'form-table.component.scss'],
})
export class FormTableColumnComponent extends FormBaseComponent<FieldWeb> implements OnDestroy {
  private readonly userService = inject(UserService);
  private formulaFieldSubject = new Subject<void>();
  readonly TableCellKinds = TableCellKinds;
  readonly GlobalItemModels = GlobalItemModels;
  private readonly unsubscribe = new Subject<void>();
  @Input() formId: string;
  @Input() formPath: string;
  @Input() parentId: string;
  @Input() kind: TableCellKinds;
  @Input() column: TableCellData;
  @Input() rowIndex: number;
  @Input() columnIndex: number;
  @Input() hasListProperty: boolean;
  @Input() columnDescription: string;
  @Input() model: FieldWeb;
  @Input() template: ITemplateWithHierarchy;

  @Output() readonly onChangeTableField = new EventEmitter();
  @Output() readonly onChangePotentialFormulaField = new EventEmitter();
  readonlyData: string;

  uploading = [];
  tableCell: FormTableCell;
  headerColumn: TableColumn;
  cellName: string;
  popoverMessage: string;
  rowId: string;
  readonly TableColumnWidths = TableColumnWidths;

  constructor(
    protected readonly appUtilService: AppUtilService,
    protected readonly formService: FormService,
    protected readonly toastrService: ToastrService,
    private readonly tableColumnService: TableColumnService,
    private readonly i18nService: TmpI18NService,
  ) {
    super(appUtilService, formService);
  }

  ngOnInit() {
    this.headerColumn = this.model?.columns?.[this.columnIndex];
    this.cellName = `column-${this.rowIndex}-${this.columnIndex}`;
    this.popoverMessage = this.i18nService.getMessage('prefilledCellPopover');
    const row = this.model.rows[this.rowIndex];
    this.rowId = row._id || row.id;

    if (this.model.readOnly) {
      this.readonlyData = this.getReadonlyFieldValue();
    }

    this.formulaFieldSubject
      .pipe(takeUntil(this.unsubscribe), debounceTime(200))
      .subscribe(() =>
        this.onChangePotentialFormulaField.emit({ colIndex: this.columnIndex, rowIndex: this.rowIndex }),
      );
  }

  getReadonlyFieldValue(): string {
    const defaultValue = this.column.value || '';

    const fieldMap = new Map<TableCellKinds, () => string>([
      [TableCellKinds.Date, () => (this.column.value ? dayjs(this.column.value).format('MMM D, YYYY') : '')],
      [TableCellKinds.ListProperty, () => this.handleListPropertyReadonly()],
      [TableCellKinds.Time, () => (this.column.value ? dayjs(this.column.value).format('h:mm A') : '')],
    ]);

    return fieldMap.has(this.kind) ? fieldMap.get(this.kind)() : defaultValue;
  }

  private handleListPropertyReadonly = (): string => {
    if (this.column.item) {
      const { data } = this.column.item;

      if ('value' in data) {
        const timezone = this.userService.getTimezone();
        const dateFormat = () => dayjs(data.value).tz(timezone).format('MMM D, YYYY');

        const modelsMap = new Map<GlobalItemModels, () => string>([
          [GlobalItemModels.TextSimple, () => String(data.value)],
          [GlobalItemModels.NumberSimple, () => String(data.value)],
          [GlobalItemModels.DateSimple, () => dateFormat()],
          [GlobalItemModels.DateExpiry, () => dateFormat()],
        ]);

        return modelsMap.get(this.column.item.model)();
      }
    }

    return 'No data found.';
  };

  onPreviewAction(warningKey: string) {
    this.toastrService.warnByKey(warningKey);
  }

  onSign(column) {
    this.tableColumnService.onSignColumn({
      column,
      itemId: this.model.id,
      formId: this.formId,
      onSigned: this.onChangeField.bind(this),
    });
  }

  onManualSign(column) {
    this.tableColumnService.onManualSignColumn({
      column,
      itemId: this.model.id,
      formId: this.formId,
      onSigned: this.onChangeField.bind(this),
    });
  }

  onChangeField() {
    this.onChangeTableField.emit();
  }

  onChangeFormulaField() {
    this.formulaFieldSubject.next();
  }

  async onUpload(event) {
    await this.tableColumnService.handleAttachmentUploadToColumn({
      event,
      column: this.column,
      uploadingAttachments: this.uploading,
    });

    this.onChangeField();
  }

  deleteAttachment(attachment) {
    this.tableColumnService.onRemoveTableColumnAttachment({
      attachment,
      attachments: this.column.attachments,
      onDelete: this.onChangeField.bind(this),
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
