import { Injectable } from '@angular/core';
import { debounce } from 'lodash-es';

import { EventTypes, ExportedCellChange } from '@site-mate/dashpivot-shared-library';

import { EventsService } from 'app/shared/service/events/events.service';

@Injectable({ providedIn: 'root' })
export class FormEventsService {
  private fieldDebounceTime = 500;
  canTriggerFormulaEvents = false;
  emitFormulaFieldChangedEvent: (changes: ExportedCellChange[]) => void;

  constructor(private readonly eventsService: EventsService) {
    this.emitFormulaFieldChangedEvent = debounce(
      (changes: ExportedCellChange[]) => this.sendFormulaCalculationEvent(changes),
      this.fieldDebounceTime,
    );
  }

  private sendFormulaCalculationEvent(changes: ExportedCellChange[]) {
    if (this.canTriggerFormulaEvents && changes.length > 1) {
      const metaData = { Context: 'Hyperformula Engine' };
      this.eventsService.trackEvent(EventTypes.FormulaCalculationTriggered, metaData);
    }
    this.disableFormulaEvent();
  }

  enableFormulaEvent() {
    this.canTriggerFormulaEvents = true;
  }

  disableFormulaEvent() {
    this.canTriggerFormulaEvents = false;
  }
}
