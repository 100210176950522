"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormFieldCloneFactory = void 0;
const category_field_1 = require("./category-field");
const date_field_1 = require("./date-field");
const date_plain_field_1 = require("./date-plain-field");
const date_range_plain_field_1 = require("./date-range-plain-field");
const generic_field_1 = require("./generic-field");
const prefilled_table_field_1 = require("./prefilled-table-field");
const prefilled_text_field_1 = require("./prefilled-text-field");
const signature_array_field_1 = require("./signature-array-field");
const table_field_1 = require("./table-field");
const yes_no_field_1 = require("./yes-no-field");
const field_kinds_enum_1 = require("../../models/fields/field-kinds.enum");
const fieldCloners = {
    [field_kinds_enum_1.FieldKinds.Category]: category_field_1.CategoryFieldCloner,
    [field_kinds_enum_1.FieldKinds.Date]: date_field_1.DateFieldCloner,
    [field_kinds_enum_1.FieldKinds.DatePlain]: date_plain_field_1.DatePlainFieldCloner,
    [field_kinds_enum_1.FieldKinds.DateRangePlain]: date_range_plain_field_1.DateRangePlainFieldCloner,
    [field_kinds_enum_1.FieldKinds.PrefilledTable]: prefilled_table_field_1.PrefilledTableFieldCloner,
    [field_kinds_enum_1.FieldKinds.PreFilledText]: prefilled_text_field_1.PrefilledTextFieldCloner,
    [field_kinds_enum_1.FieldKinds.SignatureArray]: signature_array_field_1.SignatureArrayFieldCloner,
    [field_kinds_enum_1.FieldKinds.Table]: table_field_1.TableFieldCloner,
    [field_kinds_enum_1.FieldKinds.YesNoCheckbox]: yes_no_field_1.YesNoFieldCloner,
};
class FormFieldCloneFactory {
    constructor(getListItems) {
        this.getListItems = getListItems;
    }
    getFieldCloner(kind) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const FieldCloner = fieldCloners[kind] || generic_field_1.GenericFieldCloner;
        return new FieldCloner(this.getListItems);
    }
}
exports.FormFieldCloneFactory = FormFieldCloneFactory;
