import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'cc-dropdown-item',
  templateUrl: './dropdown-item.component.html',
  styleUrls: ['./dropdown-item.component.scss'],
})
export class DropdownItemComponent {
  focusPreviousSibling = new EventEmitter();
  focusNextSibling = new EventEmitter();

  @Output() select = new EventEmitter();
  @Input() disabled = false;
  @Input() actionButtonData = '';

  @ViewChild('button') button: ElementRef<HTMLButtonElement>;

  @HostBinding('class.disabled') get isDisabled() {
    return this.disabled;
  }

  @HostListener('click') onClick() {
    if (!this.disabled) {
      this.select.emit();
    }
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const keyboardEventHandlers: Record<KeyboardEvent['key'], () => void> = {
      Enter: () => {
        if (!this.disabled) {
          event.preventDefault();
          event.stopImmediatePropagation();
          this.select.emit();
        }
      },
      ArrowDown: () => {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.focusNextSibling.emit();
      },
      ArrowUp: () => {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.focusPreviousSibling.emit();
      },
    };
    const defaultEventHandler = () => {};

    const eventHandler = keyboardEventHandlers[event.key] || defaultEventHandler;

    eventHandler.call(this);
  }

  focus() {
    this.button.nativeElement.focus();
  }
}
