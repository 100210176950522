import { Component, Input } from '@angular/core';

@Component({
  selector: 'cc-panel-with-links-header',
  templateUrl: './panel-with-links-header.component.html',
  styleUrls: ['./panel-with-links-header.component.scss'],
})
export class PanelWithLinksHeaderComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() learnMoreLink: string;
  @Input() linksCount: number;
}
